import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import NumberFormat from 'react-number-format';

import {
    Grid,
    TextField,
    InputAdornment,
    Typography,
    Divider,
    MenuItem,
    Button,
    FormControlLabel,
    Switch,
    Tooltip,
    IconButton
} from '@material-ui/core';

import { Adresse, ListHolders, ListPlaces, Indicators } from './components';

import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import BuildIcon from '@material-ui/icons/Build';
import StorefrontIcon from '@material-ui/icons/Storefront';
import PeopleIcon from '@material-ui/icons/People';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
    head: {
        background: 'rgb(192,226,251,0.35)',
        borderBottom: '1px solid #c0e2fb'
    },
    cellKabis: {
        borderLeft: '7px solid #c0e2fb'
    }
}));

function CustomTextField(props) {
    const { inputRef, label, onChange, name, helperText, startIcon, ...other } = props;

    return (
        <TextField
            {...other}
            onChange={onChange}
            variant="outlined"
            fullWidth
            label={label}
            helperText={helperText}
            InputProps={{
                startAdornment: <InputAdornment position="start">{startIcon}</InputAdornment>,
                endAdornment: (
                    <InputAdornment position="end">
                        <EuroSymbolIcon />
                    </InputAdornment>
                )
            }}
        />
    );
}

const types = [
    {
        id: '1',
        label: 'Sectorisé'
    },
    {
        id: '0',
        label: 'Non sectorisé'
    }
];

const placementsMode = [
    {
        id: '1',
        label: 'Tirage au sort'
    },
    {
        id: '0',
        label: 'Assiduité'
    }
];

const Validation = props => {
    const { className, days, updateData, data, steps, activities, raz, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    const handleData = ({ target: { name, node, value } }) => {
        updateData(node, { ...data[node], [name]: value });
    };

    const handleInterval = ({ target: { name, value } }, nodeid) => {
        data.billing.intervals.map(val => {
            if (val.id === nodeid) {
                if (name === 'min' || name === 'max') {
                    const re = /^[0-9\b]+$/;
                    if (value === '' || re.test(value)) {
                        if (parseInt(value) > 0 && parseInt(value) < 41) {
                            value = value.slice(0, 2);
                        } else {
                            value = value.slice(0, -1);
                        }
                    } else {
                        value = value.slice(0, -1);
                    }
                }

                val[name] = value;
            }
        });

        updateData('billing', {
            ...data.billing,
            intervals: data.billing.intervals
        });
    };

    const addInterval = () => {
        updateData('billing', {
            ...data.billing,
            intervals: [
                ...data.billing.intervals,
                {
                    id:
                        data.billing.intervals.length > 0
                            ? Math.max(...data.billing.intervals.map(o => o.id)) + 1
                            : 1,
                    min: 1,
                    max: 40,
                    amount: 2,
                    electricity: 0,
                    water: 0
                }
            ]
        });
    };

    const removeInterval = _id => {
        data.billing.intervals.length > 1 &&
            updateData('billing', {
                ...data.billing,
                intervals: data.billing.intervals.filter(val => val.id !== _id)
            });
    };

    const printList = name => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data[name]));
        formData.append('name', name);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/print-list`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                window.open(data.link, '_blank');
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    return (
        <Grid container direction="column" spacing={8} style={{ padding: 48 }}>
            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    style={{
                                        color: steps.caracteristic.completed
                                            ? 'green'
                                            : steps.caracteristic.error
                                            ? 'red'
                                            : theme.palette.primary.main
                                    }}>
                                    Caractéristiques du marché <BuildIcon />
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={data.caracteristic.active}
                                            onChange={event => {
                                                handleData({
                                                    target: {
                                                        name: event.target.name,
                                                        node: 'caracteristic',
                                                        value: event.target.checked
                                                    }
                                                });
                                            }}
                                            name="active"
                                            color="primary"
                                        />
                                    }
                                    label={`Marché ${
                                        data.caracteristic.active ? 'activé' : 'désactivé'
                                    }`}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    name="label"
                                    type="text"
                                    label="Libellé du marché"
                                    onChange={event => {
                                        handleData({
                                            target: {
                                                name: event.target.name,
                                                node: 'caracteristic',
                                                value: event.target.value
                                            }
                                        });
                                    }}
                                    value={data.caracteristic.label}
                                    variant="outlined"
                                    helperText="Saisir un libellé"
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Adresse
                                    selectedAdresse={data.caracteristic.adresse}
                                    node="caracteristic"
                                    setAdresse={handleData}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    name="day"
                                    label="Journée de marché"
                                    select
                                    onChange={event => {
                                        handleData({
                                            target: {
                                                name: event.target.name,
                                                node: 'caracteristic',
                                                value: event.target.value
                                            }
                                        });
                                    }}
                                    value={data.caracteristic.day}
                                    variant="outlined"
                                    helperText="Choisir une journée de marché">
                                    {days.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {/*<Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    name="type"
                                    label="Type du marché"
                                    select
                                    onChange={event => {
                                        handleData({
                                            target: {
                                                name: event.target.name,
                                                node: 'caracteristic',
                                                value: event.target.value
                                            }
                                        });
                                    }}
                                    value={data.caracteristic.type}
                                    variant="outlined"
                                    helperText="Choisir le type de marché">
                                    {types.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                    </Grid>*/}
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Mode de placement"
                                    name="placementMode"
                                    select
                                    onChange={event => {
                                        handleData({
                                            target: {
                                                name: event.target.name,
                                                node: 'caracteristic',
                                                value: event.target.value
                                            }
                                        });
                                    }}
                                    value={data.caracteristic.placementMode}
                                    variant="outlined"
                                    helperText="Choisir le mode de placement du marché">
                                    {placementsMode.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {/*<Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Placement de commerçants bloqués"
                                    name="allowBlocked"
                                    select
                                    onChange={(event) => {
                                        handleData({
                                            target: {
                                                name: event.target.name,
                                                node: "caracteristic",
                                                value: event.target.value,
                                            },
                                        });
                                    }}
                                    value={data.caracteristic.allowBlocked}
                                    variant="outlined"
                                //helperText="Choisir le mode de placement du marché"
                                >
                                    <MenuItem key={1} value={1}>
                                        Autorisé
                                    </MenuItem>
                                    <MenuItem key={0} value={0}>
                                        Non autorisé
                                    </MenuItem>
                                </TextField>
                                </Grid>*/}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {data.billing.billPackage ? (
                <>
                    <Grid item>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            justifyContent="space-between"
                                            spacing={2}>
                                            <Grid item>
                                                <Typography
                                                    variant="h4"
                                                    color="primary"
                                                    style={{
                                                        color: steps.billing.completed
                                                            ? 'green'
                                                            : steps.billing.error
                                                            ? 'red'
                                                            : theme.palette.primary.main
                                                    }}>
                                                    Forfait abonné <SquareFootIcon />
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    onClick={addInterval}
                                                    endIcon={<AddIcon />}>
                                                    Ajouter un forfait
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={2}>
                                    {data.billing.intervals.map((row, index) => (
                                        <>
                                            <Grid item key={row.id}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    spacing={2}
                                                    alignItems="center">
                                                    <Grid item xs={11}>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="flex-start"
                                                            justify="flex-start"
                                                            spacing={2}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    name="min"
                                                                    nodeid={row.id}
                                                                    onChange={e =>
                                                                        handleInterval(e, row.id)
                                                                    }
                                                                    value={row.min}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label={'Minimum'}
                                                                    //helperText={'Minimum 1 mètre'}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    name="max"
                                                                    nodeid={row.id}
                                                                    onChange={e =>
                                                                        handleInterval(e, row.id)
                                                                    }
                                                                    value={row.max}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label={'Maximum'}
                                                                    //helperText={'Maximum 40 mètres'}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <NumberFormat
                                                                    value={row.electricity}
                                                                    startIcon={
                                                                        <FlashOnIcon
                                                                            style={{
                                                                                color: 'gold'
                                                                            }}
                                                                        />
                                                                    }
                                                                    name="electricity"
                                                                    label="Electricité"
                                                                    //helperText="Saisir un forfait électricité"
                                                                    customInput={CustomTextField}
                                                                    onValueChange={values => {
                                                                        handleInterval(
                                                                            {
                                                                                target: {
                                                                                    name:
                                                                                        'electricity',
                                                                                    value:
                                                                                        values.value
                                                                                }
                                                                            },
                                                                            row.id
                                                                        );
                                                                    }}
                                                                    thousandsGroupStyle="fr"
                                                                    thousandSeparator={' '}
                                                                    decimalSeparator={','}
                                                                    type={'text'}
                                                                    allowedDecimalSeparators={[
                                                                        ',',
                                                                        '.',
                                                                        '?',
                                                                        ';'
                                                                    ]}
                                                                    allowNegative={false}
                                                                    decimalScale={2}
                                                                    isNumericString
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <NumberFormat
                                                                    value={row.amount}
                                                                    startIcon={
                                                                        <PersonOutlineIcon />
                                                                    }
                                                                    name="amount"
                                                                    label="Forfait"
                                                                    //helperText="Saisir un forfait"
                                                                    customInput={CustomTextField}
                                                                    onValueChange={values => {
                                                                        handleInterval(
                                                                            {
                                                                                target: {
                                                                                    name: 'amount',
                                                                                    value:
                                                                                        values.value
                                                                                }
                                                                            },
                                                                            row.id
                                                                        );
                                                                    }}
                                                                    thousandsGroupStyle="fr"
                                                                    thousandSeparator={' '}
                                                                    decimalSeparator={','}
                                                                    type={'text'}
                                                                    allowedDecimalSeparators={[
                                                                        ',',
                                                                        '.',
                                                                        '?',
                                                                        ';'
                                                                    ]}
                                                                    allowNegative={false}
                                                                    decimalScale={2}
                                                                    isNumericString
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Tooltip
                                                            disableHoverListener={
                                                                data.billing.intervals.length === 1
                                                            }
                                                            title="Supprimer le forfait"
                                                            onClick={() => removeInterval(row.id)}>
                                                            <IconButton
                                                                disabled={
                                                                    data.billing.intervals
                                                                        .length === 1
                                                                }>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {data.billing.intervals?.length > 1 &&
                                            index + 1 !== data.billing.intervals?.length ? (
                                                <Grid item>
                                                    <Divider />
                                                </Grid>
                                            ) : null}
                                        </>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography
                                            variant="h4"
                                            style={{
                                                color: steps.billing.completed
                                                    ? 'green'
                                                    : steps.billing.error
                                                    ? 'red'
                                                    : theme.palette.primary.main
                                            }}>
                                            Facturation du mètre linéaire non abonné{' '}
                                            <SquareFootIcon />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <NumberFormat
                                            value={data.billing.noneSubscriber}
                                            startIcon={<PersonOutlineIcon />}
                                            name="noneSubscriber"
                                            label="Prix du mètres linéaire pour les non abonnés"
                                            helperText="Saisir un prix unitaire pour le mètre linéaire"
                                            customInput={CustomTextField}
                                            onValueChange={values => {
                                                handleData({
                                                    target: {
                                                        name: 'noneSubscriber',
                                                        node: 'billing',
                                                        value: values.value
                                                    }
                                                });
                                            }}
                                            thousandsGroupStyle="fr"
                                            thousandSeparator={' '}
                                            decimalSeparator={','}
                                            type={'text'}
                                            allowedDecimalSeparators={[',', '.', '?', ';']}
                                            allowNegative={false}
                                            decimalScale={2}
                                            isNumericString
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid item>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography
                                        variant="h4"
                                        style={{
                                            color: steps.billing.completed
                                                ? 'green'
                                                : steps.billing.error
                                                ? 'red'
                                                : theme.palette.primary.main
                                        }}>
                                        Facturation du mètre linéaire <SquareFootIcon />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={3}>
                                <Grid item sm={6} xs={12}>
                                    <NumberFormat
                                        value={data.billing.subscriber}
                                        startIcon={<PersonIcon />}
                                        name="subscriber"
                                        label="Prix du mètres linéaire pour les abonnés"
                                        helperText="Saisir un prix unitaire pour le mètre linéaire"
                                        customInput={CustomTextField}
                                        onValueChange={values => {
                                            handleData({
                                                target: {
                                                    name: 'subscriber',
                                                    node: 'billing',
                                                    value: values.value
                                                }
                                            });
                                        }}
                                        thousandsGroupStyle="fr"
                                        thousandSeparator={' '}
                                        decimalSeparator={','}
                                        type={'text'}
                                        allowedDecimalSeparators={[',', '.', '?', ';']}
                                        allowNegative={false}
                                        decimalScale={2}
                                        isNumericString
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <NumberFormat
                                        value={data.billing.noneSubscriber}
                                        startIcon={<PersonOutlineIcon />}
                                        name="noneSubscriber"
                                        label="Prix du mètres linéaire pour les non abonnés"
                                        helperText="Saisir un prix unitaire pour le mètre linéaire"
                                        customInput={CustomTextField}
                                        onValueChange={values => {
                                            handleData({
                                                target: {
                                                    name: 'noneSubscriber',
                                                    node: 'billing',
                                                    value: values.value
                                                }
                                            });
                                        }}
                                        thousandsGroupStyle="fr"
                                        thousandSeparator={' '}
                                        decimalSeparator={','}
                                        type={'text'}
                                        allowedDecimalSeparators={[',', '.', '?', ';']}
                                        allowNegative={false}
                                        decimalScale={2}
                                        isNumericString
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {!data.billing.billPackage ? (
                <Grid item>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        alignItems="flex-end">
                                        <Grid item>
                                            <Typography variant="h4" color="primary">
                                                Facturation complémentaire abonné{' '}
                                                <PlaylistAddIcon />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1" color="primary">
                                                (facultatif)
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={3}>
                                <Grid item sm={6} xs={12}>
                                    <NumberFormat
                                        value={data.billing.water}
                                        startIcon={
                                            <InvertColorsIcon style={{ color: 'dodgerblue' }} />
                                        }
                                        name="water"
                                        label="Tarif eau"
                                        helperText="Saisir un tarif pour l'eau"
                                        customInput={CustomTextField}
                                        onValueChange={values => {
                                            handleData({
                                                target: {
                                                    name: 'water',
                                                    node: 'billing',
                                                    value: values.value
                                                }
                                            });
                                        }}
                                        thousandsGroupStyle="fr"
                                        thousandSeparator={' '}
                                        decimalSeparator={','}
                                        type={'text'}
                                        allowedDecimalSeparators={[',', '.', '?', ';']}
                                        allowNegative={false}
                                        decimalScale={2}
                                        isNumericString
                                    />
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <NumberFormat
                                        value={data.billing.electricity}
                                        startIcon={<FlashOnIcon style={{ color: '#f4f40b' }} />}
                                        name="electricity"
                                        label="Tarif électricité"
                                        helperText="Saisir un tarif pour l'électricité"
                                        customInput={CustomTextField}
                                        onValueChange={values => {
                                            handleData({
                                                target: {
                                                    name: 'electricity',
                                                    node: 'billing',
                                                    value: values.value
                                                }
                                            });
                                        }}
                                        thousandsGroupStyle="fr"
                                        thousandSeparator={' '}
                                        decimalSeparator={','}
                                        type={'text'}
                                        allowedDecimalSeparators={[',', '.', '?', ';']}
                                        allowNegative={false}
                                        decimalScale={2}
                                        isNumericString
                                    />
                                </Grid>
                                {/*<Grid item sm={4} xs={12}>
                            <NumberFormat
                                value={data.billing.truck}
                                startIcon={<LocalShippingIcon />}
                                name="truck"
                                label="Tarif camion"
                                helperText="Saisir un tarif pour le camion"
                                customInput={CustomTextField}
                                onValueChange={(values) => {
                                    handleData({
                                        target: {
                                            name: "truck",
                                            node: "billing",
                                            value: values.value,
                                        },
                                    });
                                }}
                                thousandsGroupStyle="fr"
                                thousandSeparator={" "}
                                decimalSeparator={","}
                                type={'text'}
                                allowedDecimalSeparators={[",", ".", "?", ";"]}
                                allowNegative={false}
                                decimalScale={2}
                                isNumericString
                            />
                            </Grid>*/}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}

            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container spacing={1} direction="row" alignItems="flex-end">
                                    <Grid item>
                                        <Typography variant="h4" color="primary">
                                            Facturation complémentaire non abonné{' '}
                                            <PlaylistAddIcon />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" color="primary">
                                            (facultatif)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                                <NumberFormat
                                    value={data.billing.noneSubscriberWater}
                                    startIcon={<InvertColorsIcon style={{ color: 'dodgerblue' }} />}
                                    name="water"
                                    label="Tarif eau"
                                    helperText="Saisir un tarif pour l'eau"
                                    customInput={CustomTextField}
                                    onValueChange={values => {
                                        handleData({
                                            target: {
                                                name: 'noneSubscriberWater',
                                                node: 'billing',
                                                value: values.value
                                            }
                                        });
                                    }}
                                    thousandsGroupStyle="fr"
                                    thousandSeparator={' '}
                                    decimalSeparator={','}
                                    type={'text'}
                                    allowedDecimalSeparators={[',', '.', '?', ';']}
                                    allowNegative={false}
                                    decimalScale={2}
                                    isNumericString
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <NumberFormat
                                    value={data.billing.noneSubscriberElectricity}
                                    startIcon={<FlashOnIcon style={{ color: '#f4f40b' }} />}
                                    name="electricity"
                                    label="Tarif électricité"
                                    helperText="Saisir un tarif pour l'électricité"
                                    customInput={CustomTextField}
                                    onValueChange={values => {
                                        handleData({
                                            target: {
                                                name: 'noneSubscriberElectricity',
                                                node: 'billing',
                                                value: values.value
                                            }
                                        });
                                    }}
                                    thousandsGroupStyle="fr"
                                    thousandSeparator={' '}
                                    decimalSeparator={','}
                                    type={'text'}
                                    allowedDecimalSeparators={[',', '.', '?', ';']}
                                    allowNegative={false}
                                    decimalScale={2}
                                    isNumericString
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container direction="row" justify="space-between">
                                    <Grid item>
                                        <Typography
                                            variant="h4"
                                            style={{
                                                color: steps.plan.completed
                                                    ? 'green'
                                                    : steps.plan.error
                                                    ? 'red'
                                                    : theme.palette.primary.main
                                            }}>
                                            Places du marché <StorefrontIcon />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            endIcon={<PrintIcon />}
                                            onClick={() => printList('places')}
                                            color="primary">
                                            Imprimer
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <ListPlaces raz={raz} activities={activities} places={data.places} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container direction="row" justify="space-between">
                                    <Grid item>
                                        <Typography variant="h4" color="primary">
                                            Titulaires <PeopleIcon />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            endIcon={<PrintIcon />}
                                            onClick={() => printList('merchants')}
                                            color="primary">
                                            Imprimer
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <ListHolders raz={raz} activities={activities} merchants={data.merchants} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography variant="h4" color="primary">
                                    Indicateurs <AssessmentIcon />
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Indicators
                            raz={raz}
                            billing={data.billing}
                            merchants={data.merchants}
                            places={data.places}
                            caracteristic={data.caracteristic}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Validation;
