import React from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
    Dialog,
    DialogContent,
    Button,
    DialogActions,
    Typography,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableRow,
    Link,
    TableHead,
    ListItemText,
    Radio,
    Grid
} from '@material-ui/core';

import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';

import {
    DialogTitle
} from './components'

const useStyles = makeStyles(theme => ({
    head: {
        background: "rgb(192,226,251,0.35)",
        borderBottom: "1px solid #c0e2fb"
    },
    cellKabis: {
        borderLeft: "7px solid #c0e2fb"
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(0, 0, 0, 0.02)",
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#e1f0fb",
        color: "#3f51b5",
    },
}))(TableCell);


const columns = [
    {
        id: "raisonSociale",
        label: "Raison sociale",
        minWidth: "30%",
        align: 'left',
    },
    {
        id: 'enseigne',
        label: 'Enseigne',
        minWidth: "20%",
        align: 'left',
    },
    {
        id: 'naf',
        label: 'Activité',
        minWidth: "30%",
        align: 'left',
    },
    {
        id: 'status',
        label: 'Statut',
        minWidth: "20%",
        align: 'left',
    },
];



const ChooseSiteDialog = props => {
    const {
        className,
        data,
        close,
        setKabis,
        setChoosenKey,
        nextStep,
        ...rest
    } = props;

    const prepareData = () => {
        if (data.selectedKabis == "") {
            alert("Veuillez sélectionner un établissement actif et non enregistré.");
            return;
        }

        nextStep(1);
        close();
    }

    const handleClose = () => {
        close();
    }

    const handleRadio = (registrationId, key) => {
        setKabis(registrationId, key)
    }

    return (
        <Dialog
            fullWidth
            maxWidth={"md"}
            onClose={handleClose} open={true}>
            <DialogTitle onClose={handleClose}>
                Choisir un établissement ({data.kabis})
            </DialogTitle>
            <DialogContent dividers>
                <Grid container direction="column" spacing={2}>
                    {
                        data.hasOpposed ?
                            <Grid item>
                                <Grid container direction="row" justify="flex-start" alignItems="center">
                                    <Grid item xs={1}>
                                        <ErrorIcon style={{ color: "red" }} fontSize={"large"} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography>
                                            {data.mandataire.civilite} {data.mandataire.name} {data.mandataire.firstname} ({data.mandataire.fonction.label.toLowerCase()}) a exercé son droit d'opppostion auprès de l'INSEE. {data.totalSites} établissement(s) au total.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :

                            <Grid item>
                                {
                                    data.availabality.mandataire ?
                                        data.availabality.identity ?

                                            <Typography>
                                                {data.mandataire.civilite} {data.mandataire.name} {data.mandataire.firstname} est {data.mandataire.fonction.label.toLowerCase()} de {data.totalSites == 1 ? "cet établissement" : "ces établissements"} ({data.totalSites} au total).
                                            </Typography>
                                            :
                                            <Grid container direction="row" justify="flex-start" alignItems="center">
                                                <Grid item xs={1}>
                                                    <WarningIcon style={{ color: "orange" }} fontSize={"large"} />
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Typography>
                                                        {data.mandataire.civilite} {data.mandataire.name} {data.mandataire.firstname} ({data.mandataire.fonction.label.toLowerCase()}) a exercé son droit d'opposition auprès de l'INSEE. {data.totalSites} établissement(s) au total.
                                        </Typography>
                                                </Grid>
                                            </Grid>
                                        :
                                        <Grid container direction="row" justify="flex-start" alignItems="center">
                                            <Grid item xs={1}>
                                                <WarningIcon style={{ color: "orange" }} fontSize={"large"} />
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography>
                                                    Ce commerçant ne possède pas d'informations concernant le mandataire. {data.totalSites} établissement(s) au total.
                                        </Typography>
                                            </Grid>
                                        </Grid>
                                }
                            </Grid>
                    }
                    <Grid item>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {
                                            columns.map(column => (
                                                <TableCell
                                                    colSpan={(column.id == "raisonSociale" ? 2 : 1)}
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.sites.map((site, index) => (
                                            <StyledTableRow key={site.registrationId}>
                                                <StyledTableCell align="left" padding="checkbox">
                                                    <Radio
                                                        onChange={() => handleRadio(site.registrationId, index)}
                                                        key={index}
                                                        disabled={!site.status || site.isIn} size="small"
                                                        checked={Boolean(site.registrationId == data.selectedKabis && !site.isIn && site.status)}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="left" component="th" scope="row" padding="none">
                                                    <ListItemText
                                                        primary={
                                                            site.isIn ?
                                                                <Link component={RouterLink} to={`/merchants/get/${site.registrationId}/profil`}>
                                                                    {site.name}
                                                                </Link>
                                                                :
                                                                site.name
                                                        }
                                                        secondary={
                                                            <Typography variant="body2">
                                                                {site.registrationId}
                                                            </Typography>
                                                        }
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {site.commercialName}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {
                                                        site.activity.code == "0000Z" ?
                                                            data.company.identity.activity.label + " (" + data.company.identity.activity.code + ")"
                                                            :
                                                            site.activity.label + " (" + site.activity.code + ")"
                                                    }

                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="button" style={{ color: site.status ? "green" : "red" }}>
                                                                {
                                                                    site.status ?
                                                                        "Actif" :
                                                                        `Fermé ${site.closingDate ? site.closingDate : ""}`
                                                                }
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Typography variant="body2">
                                                                {
                                                                    site.isIn ?
                                                                        "Déjà enregistré" :
                                                                        "Non enregistré"
                                                                }
                                                            </Typography>
                                                        }
                                                    />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler la création
                </Button>
                <Button onClick={prepareData} variant="contained" color="primary">
                    Poursuivre la création
                </Button>
            </DialogActions>
        </Dialog >
    );
};

export default ChooseSiteDialog;