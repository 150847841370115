import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Grid,
    Button,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
    FormGroup,
    TextField,
    DialogActions,
    DialogContent,
    MenuItem
} from '@material-ui/core';

import { FileInput } from './components';

import Autocomplete from '@material-ui/lab/Autocomplete';
import SendIcon from '@material-ui/icons/Send';

const styles = theme => ({
    root: {
        flexGrow: 1
    }
});

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            isLoading: true,
            open: props.open,
            //type: "",
            list: [],
            selectedMails: [],
            templates: [],
            optionText: '',
            optionLabel: '',
            helperText: '',
            template_id: '',
            disabled: false,
            init: true
            //objet: "",
            //message: "",
            //file: null
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.type != this.state.type) {
            this.getData();
        }

        if (this.state.template_id != prevState.template_id) {
            this.getTemplateData();
        }

        if (
            !this.state.isLoading &&
            this.state.isLoading != prevState.isLoading &&
            this.state.hasToImport
        ) {
            this.setState({
                hasToImport: false
            });

            this.handleMailList(null, this.state.importedMails);

            if (this.state.bill) {
                this.getBill();
            }
        }
    };

    prepareData = () => {
        const { message, objet, type, selectedMails, helperText, file } = this.state;

        if (!objet.replace(/\s/g, '').length) {
            alert("Aucun objet n'a été spécifié");
            return;
        }

        if (!message.replace(/\s/g, '').length) {
            alert('Le corps de votre mail est vide');
            return;
        }

        if (type != 'all' && selectedMails.length == 0) {
            alert(helperText);
            return;
        }

        this.props.sendData({
            type: type,
            selectedMails: selectedMails,
            objet: objet,
            message: message,
            file: file
        });
    };

    getTemplateData = () => {
        const { template_id } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/email/get-template/${template_id}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    getBill = () => {
        const { bill } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/get-file-bill/${bill}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.blob() : false;
            })
            .then(blob => {
                const file = new File([blob], `${bill}.pdf`, { type: 'application/pdf' });
                this.handleFile(file);
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération de la facture.');
                } // Handle the error response object
            );
    };

    getData = () => {
        const { type, notify, init, bill } = this.state;

        const formData = new FormData();
        formData.append('type', type);
        formData.append('notify', notify);
        formData.append('init', init);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-emails`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    init: false,
                    isLoading: false,
                    selectedMails:
                        this.state.type == this.props.type
                            ? this.props.previousMails
                            : data.selectedMails
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleEmailTemplate = ({ target: { value } }) => {
        this.setState({
            template_id: value
        });
    };

    handleTypeFilter = ({ target: { name } }) => {
        this.setState(prevState => ({
            isLoading: true,
            type: prevState.type == name ? null : name
        }));
    };

    handleMailList = (event, value) => {
        this.setState({
            isLoading: true,
            selectedMails: value
        });
    };

    handleForm = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    handleFile = file => {
        this.setState({
            file: file
        });
    };

    render() {
        const {
            classes,
            type,
            list,
            optionText,
            optionLabel,
            selectedMails,
            helperText,
            disabled,
            objet,
            message,
            file,
            templates,
            template_id
        } = this.state;

        const { notify, selectedBillToNotify } = this.props;

        return (
            <React.Fragment>
                <DialogContent dividers>
                    <Grid
                        container
                        direction="column"
                        spacing={1}
                        justify="center"
                        alignItems="stretch">
                        {notify && (
                            <React.Fragment>
                                <Grid item>
                                    <FormControl component="fieldset">
                                        <FormLabel color="primary">Modèle du message</FormLabel>
                                    </FormControl>
                                </Grid>
                                <Grid item style={{ marginBottom: 20 }}>
                                    <TextField
                                        disabled
                                        fullWidth
                                        select
                                        //helperText="Sélectionner un modèle"
                                        variant="outlined"
                                        value={template_id}
                                        onChange={this.handleEmailTemplate}>
                                        {templates.map((row, index) => (
                                            <MenuItem key={index} value={row.id}>
                                                {row.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </React.Fragment>
                        )}

                        <Grid item>
                            <FormControl required component="fieldset">
                                <FormLabel color="primary">
                                    {notify
                                        ? 'Commerçant à notifier'
                                        : 'Sélectionnez vos commerçants'}
                                </FormLabel>
                                {!notify && (
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={Boolean(type == 'all')}
                                                    onChange={this.handleTypeFilter}
                                                    name="all"
                                                />
                                            }
                                            label="Tous"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={Boolean(type == 'titulaire')}
                                                    onChange={this.handleTypeFilter}
                                                    name="titulaire"
                                                />
                                            }
                                            label="Titulaire"
                                        />
                                    </FormGroup>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={list}
                                name="listOfMail"
                                multiple
                                autoHighlight
                                disabled={disabled}
                                autoHighlight
                                value={selectedMails}
                                onChange={this.handleMailList}
                                noOptionsText={optionText}
                                disabled={notify}
                                getOptionLabel={option => option[optionLabel]}
                                getOptionSelected={(option, value) => option.id == value.id}
                                renderInput={params => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        helperText={helperText}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item style={{ marginTop: 20 }}>
                            <FormControl required component="fieldset">
                                <FormLabel color="primary">Objet et corps du message</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            container
                            direction="column"
                            spacing={1}
                            justify="center"
                            alignItems="stretch">
                            <Grid item>
                                <TextField
                                    placeholder="Votre objet"
                                    variant="outlined"
                                    fullWidth
                                    name="objet"
                                    value={objet}
                                    onChange={this.handleForm}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    placeholder="Votre message"
                                    variant="outlined"
                                    name="message"
                                    multiline
                                    fullWidth
                                    value={message}
                                    rows={7}
                                    rowsMax={15}
                                    onChange={this.handleForm}
                                />
                            </Grid>
                            <Grid item>
                                <FileInput file={file} setFile={this.handleFile} />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.close} color="primary">
                        Annuler
                    </Button>
                    <Button
                        onClick={this.prepareData}
                        variant="contained"
                        color="primary"
                        type="submit">
                        Envoyer <SendIcon style={{ marginLeft: 7 }} />
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Form);
