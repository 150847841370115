import React from 'react';
import { withStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import {
    Grid,
    Paper,
    Divider,
    IconButton,
    Typography,
    TextField,
    Link,
    Button,
    Radio,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    InputAdornment
} from '@material-ui/core';

import { FileInput } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        margin: theme.spacing(3),
        flexGrow: 1
    }
});

class City extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            getter: false,
            ville: '',
            commune: '',
            donneur: '',
            complementDonneur: '',
            logo: null,
            cp: '',
            mail: '',
            adresse: '',
            complementAdresse1: '',
            complementAdresse2: '',
            receiveCopyBill: 'n',
            receiveCopyCom: 'n',
            mailValidated: false,
            billingMethod: 'j'
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/city/get`,
            {
                // Your POST endpoint
                method: 'GET'
                //body: formData // This is your file object
            }
        )
            .then(response => {
                // Obligé de retouner la réponse JSON pour qu'elle soit contenu dans la v.a data
                return response.ok ? response.json() : false;
            })
            .then(
                data => {
                    this.setState({
                        ...data,
                        getter: true
                    });
                } // if the response is a JSON object
            )
            .then(
                success => console.log(success) // Handle the success response object
            )
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleForm = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    update = () => {
        const {
            commune,
            donneur,
            complementDonneur,
            ville,
            complementAdresse1,
            complementAdresse2,
            cp,
            logo,
            mail,
            adresse,
            receiveCopyBill,
            receiveCopyCom,
            billingMethod
        } = this.state;

        /*
    if (!commune || commune.length == 0 || !commune.replace(/\s/g, '').length) {
      alert("Veuillez saisir un nom de ville.");
      return;
    }

    if (!adresse || adresse.length == 0 || !adresse.replace(/\s/g, '').length) {
      alert("Veuillez saisir une adresse.");
      return;
    }

    if (!cp || cp.length == 0 || !cp.replace(/\s/g, '').length) {
      alert("Veuillez saisir un code postal.");
      return;
    }

    if (!mail || mail.length == 0 || !mail.replace(/\s/g, '').length) {
      alert("Veuillez saisir une adresse mail valide.");
      return;
    }*/

        const formData = new FormData();
        formData.append('ville', ville);
        formData.append('commune', commune);
        formData.append('donneur', donneur);
        formData.append('complementDonneur', complementDonneur);
        formData.append('adresse', adresse);
        formData.append('complementAdresse1', complementAdresse1);
        formData.append('complementAdresse2', complementAdresse2);
        formData.append('cp', cp);
        formData.append('mail', mail);
        formData.append('receiveCopyBill', receiveCopyBill);
        formData.append('receiveCopyCom', receiveCopyCom);
        formData.append('billingMethod', billingMethod);
        logo && formData.append('logo', logo);

        this.sendData(formData);
    };

    sendData = formData => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/city/update`,
            {
                // Your POST endpoint
                method: 'POST',
                body: formData // This is your file object
            }
        )
            .then(response => {
                // Obligé de retouner la réponse JSON pour qu'elle soit contenu dans la v.a data
                return response.ok ? response.json() : false;
            })
            .then(
                data => {
                    if (data) {
                        alert('Mise à jour effectuée');
                        let goToSetting = window.confirm("Retourner sur l'écran des paramètres ?");
                        goToSetting && this.state.history.push('/settings');
                        this.getData();
                    }
                } // if the response is a JSON object
            )
            .then(
                success => console.log(success) // Handle the success response object
            )
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la mise à jour.');
                } // Handle the error response object
            );
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleLogo = file => {
        this.setState({
            logo: file
        });
    };

    handleCopy = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    checkEmail = () => {
        const { mail, commune } = this.state;

        if (!commune || commune.length == 0 || !commune.replace(/\s/g, '').length) {
            alert('Veuillez saisir un nom de commune.');
            return;
        }

        if (!mail || mail.length == 0 || !mail.replace(/\s/g, '').length) {
            alert('Veuillez saisir une adresse mail valide.');
            return;
        }

        const formData = new FormData();
        formData.append('senderEmail', mail);
        formData.append('senderName', commune);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/city/check-sender`,
            {
                // Your POST endpoint
                method: 'POST',
                body: formData // This is your file object
            }
        )
            .then(response => {
                // Obligé de retouner la réponse JSON pour qu'elle soit contenu dans la v.a data
                return response.ok ? response.json() : false;
            })
            .then(
                data => {
                    alert(data);
                } // if the response is a JSON object
            )
            .then(
                success => console.log(success) // Handle the success response object
            )
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la vérification.');
                } // Handle the error response object
            );
    };

    render() {
        const {
            classes,
            ville,
            commune,
            donneur,
            complementDonneur,
            cp,
            mail,
            adresse,
            complementAdresse1,
            complementAdresse2,
            logo,
            getter,
            receiveCopyBill,
            receiveCopyCom,
            mailValidated,
            billingMethod
        } = this.state;

        return (
            <Paper className={classes.root}>
                <Grid container direction="column" spacing={2} justify="center">
                    <Grid item>
                        <Grid container direction="row" alignItems="baseline">
                            <Grid item>
                                <IconButton onClick={this.getBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <Link component={RouterLink} to="/settings">
                                        Les paramètres
                                    </Link>{' '}
                                    / Configurer ma ville
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={6}>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    spacing={3}
                                    justify="center"
                                    alignItems="stretch">
                                    <Grid item>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Typography variant="h4" color="primary">
                                                    Information sur ma ville
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            name="commune"
                                            label="Nom de la commune"
                                            onChange={this.handleForm}
                                            fullWidth
                                            value={commune}
                                            variant="outlined"
                                            helperText="Saisir un nom de commune"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    name="donneur"
                                                    label="Donneur de l'ordre"
                                                    onChange={this.handleForm}
                                                    fullWidth
                                                    value={donneur}
                                                    variant="outlined"
                                                    helperText="Saisir un donneur d'ordre"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    name="complementDonneur"
                                                    label="Complément donneur de l'ordre"
                                                    onChange={this.handleForm}
                                                    fullWidth
                                                    value={complementDonneur}
                                                    variant="outlined"
                                                    helperText="Saisir un complément de donneur d'ordre"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    name="adresse"
                                                    label="Adresse principale"
                                                    onChange={this.handleForm}
                                                    fullWidth
                                                    value={adresse}
                                                    variant="outlined"
                                                    helperText="Saisir une adresse principale"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    name="complementAdresse1"
                                                    label="Complément d'adresse 1"
                                                    onChange={this.handleForm}
                                                    fullWidth
                                                    value={complementAdresse1}
                                                    variant="outlined"
                                                    helperText="Saisir une adresse complémentaire"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    name="complementAdresse2"
                                                    label="Complément d'adresse 2"
                                                    onChange={this.handleForm}
                                                    fullWidth
                                                    value={complementAdresse2}
                                                    variant="outlined"
                                                    helperText="Saisir une adresse complémentaire"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    name="cp"
                                                    label="Code postal"
                                                    onChange={this.handleForm}
                                                    fullWidth
                                                    value={cp}
                                                    variant="outlined"
                                                    helperText="Saisir un code postal"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                                <TextField
                                                    name="ville"
                                                    label="Ville"
                                                    onChange={this.handleForm}
                                                    fullWidth
                                                    value={ville}
                                                    variant="outlined"
                                                    helperText="Saisir une ville"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            name="mail"
                                            label="Adresse mail"
                                            onChange={this.handleForm}
                                            fullWidth
                                            value={mail}
                                            variant="outlined"
                                            helperText="Saisir une adresse mail"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        {mailValidated ? (
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                spacing={1}
                                                                alignItems="center">
                                                                <Grid item>
                                                                    <Typography
                                                                        style={{ color: 'green' }}>
                                                                        Verifiée
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <CheckIcon
                                                                        style={{ color: 'green' }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        ) : (
                                                            <Button
                                                                color="primary"
                                                                onClick={this.checkEmail}
                                                                variant="contained">
                                                                Vérifier l'adresse mail
                                                            </Button>
                                                        )}
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    {getter && (
                                        <Grid item>
                                            <FileInput file={logo} setFile={this.handleLogo} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    spacing={3}
                                    justify="center"
                                    alignItems="stretch">
                                    <Grid item>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Typography variant="h4" color="primary">
                                                    Communication avec les commerçants
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">
                                                Copie des communications effectuées via{' '}
                                                <strong>CONTACTER LES COMMERÇANTS</strong>
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                name="receiveCopyCom"
                                                value={receiveCopyCom}
                                                onChange={this.handleCopy}>
                                                <FormControlLabel
                                                    value="n"
                                                    control={<Radio />}
                                                    label="Ne rien recevoir"
                                                />
                                                <FormControlLabel
                                                    value="cc"
                                                    control={<Radio />}
                                                    label="Recevoir en copie carbone (CC)"
                                                />
                                                <FormControlLabel
                                                    value="bcc"
                                                    control={<Radio />}
                                                    label="Recevoir en copie carbone invisible (CCI)"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">
                                                Copie des communications effectuées via l'envoi des
                                                factures
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                name="receiveCopyBill"
                                                value={receiveCopyBill}
                                                onChange={this.handleCopy}>
                                                <FormControlLabel
                                                    value="n"
                                                    control={<Radio />}
                                                    label="Ne rien recevoir"
                                                />
                                                <FormControlLabel
                                                    value="cc"
                                                    control={<Radio />}
                                                    label="Recevoir en copie carbone (CC)"
                                                />
                                                <FormControlLabel
                                                    value="bcc"
                                                    control={<Radio />}
                                                    label="Recevoir en copie carbone invisible (CCI)"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    spacing={3}
                                    justify="center"
                                    alignItems="stretch">
                                    <Grid item>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <Typography variant="h4" color="primary">
                                                    Mode de facturation des titulaires abonnés
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <FormControl component="fieldset">
                                            <RadioGroup
                                                row
                                                name="billingMethod"
                                                value={billingMethod}
                                                onChange={this.handleCopy}>
                                                <FormControlLabel
                                                    value="j"
                                                    control={<Radio />}
                                                    label="Afficher un tarif journalier"
                                                />
                                                <FormControlLabel
                                                    value="m"
                                                    control={<Radio />}
                                                    label="Afficher un tarif mensuel"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column" spacing={2} alignItems="center">
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.update}
                            style={{ marginTop: 20 }}>
                            Mettre à jour
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(City);
