import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

import { Grid, Button, IconButton, Paper, Link, Divider, Typography } from '@material-ui/core';

import { MarketMap, MarketCaracteristics, Indicators, DialogDetail } from './components';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paperContent: {
        padding: theme.spacing(4)
        //padding: 10
    },
    item: {
        flexGrow: 1
    }
});

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            init: true,
            market: [],
            activities: [],
            meansOfPayment: [],
            places: [],
            merchants: [],
            stats: []
        };
    }

    componentDidMount = () => {
        this.init();
    };

    init = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/dashboard/${this.props.args.args1}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    init: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const {
            classes,
            market,
            places,
            merchants,
            activities,
            meansOfPayment,
            stats,
            init
        } = this.state;

        const { args1, args2 } = this.props.args;

        return (
            <div>
                {args2 && (
                    <DialogDetail
                        history={this.props.history}
                        journalMarketId={args1}
                        link={args2}
                    />
                )}
                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <Paper className={classes.paperContent}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                spacing={2}>
                                <Grid item>
                                    <Grid container direction="row" alignItems="baseline">
                                        <Grid item>
                                            <IconButton onClick={this.getBack}>
                                                <ArrowBackIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                <Link component={RouterLink} to="/markets/all">
                                                    Les marchés
                                                </Link>{' '}
                                                / Journée de marché en date du {market.date}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <MarketCaracteristics
                                        data={market}
                                        init={init}
                                        id={this.props.args.args1}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper className={classes.paperContent}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                spacing={2}>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="flex-start"
                                        spacing={2}>
                                        <Grid item className={classes.item}>
                                            <Indicators
                                                init={init}
                                                data={stats.statement}
                                                journalMarketId={args1}
                                                link={'finance'}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Divider
                                                orientation="vertical"
                                                style={{ height: 200 }}
                                                variant="fullWidth"
                                                flexItem
                                            />
                                        </Grid>
                                        <Grid item className={classes.item}>
                                            <Indicators
                                                init={init}
                                                data={stats.occupiedPlaces}
                                                journalMarketId={args1}
                                                link={'occupied-places'}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Divider
                                                orientation="vertical"
                                                style={{ height: 200 }}
                                                variant="fullWidth"
                                                flexItem
                                            />
                                        </Grid>
                                        <Grid item className={classes.item}>
                                            <Indicators
                                                init={init}
                                                data={stats.meters}
                                                journalMarketId={args1}
                                                link={'occupied-places'}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Divider
                                                orientation="vertical"
                                                style={{ height: 200 }}
                                                variant="fullWidth"
                                                flexItem
                                            />
                                        </Grid>
                                        <Grid item className={classes.item}>
                                            <Indicators
                                                init={init}
                                                data={stats.newMerchants}
                                                journalMarketId={args1}
                                                link={'new-merchants'}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <MarketMap
                                        init={init}
                                        marketData={market}
                                        activities={activities}
                                        meansOfPayment={meansOfPayment}
                                        places={places}
                                        merchants={merchants}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Dashboard);
