import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Paper,
    Grid,
    Divider,
    Typography,
    IconButton,
    Link,
    Tab,
    Tabs,
    Button
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FaceTwoToneIcon from '@material-ui/icons/FaceTwoTone';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ClearIcon from '@material-ui/icons/Clear';

import TabControl from './TabControl';
import {
    Profil,
    Titularisation,
    History,
    Facture,
    Blocage,
    MissedData,
    DialogBlocage,
    DialogComment,
    Comment
} from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    icon: {
        height: 100,
        width: '100%'
    },
    bloque: {
        color: 'red',
        display: 'inline-block',
        width: '100%',
        textAlign: 'center',
        marginTop: 14
    },
    raisonSociale: {
        display: 'inline-block',
        width: '100%',
        textAlign: 'center'
    },
    siret: {
        display: 'inline-block',
        width: '100%',
        textAlign: 'center'
    }
});

class Merchant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            tab: 'profil',
            marchand_id: '',
            siret: this.props.params.siret,
            raisonSociale: '',
            raison: '',
            bloque: 0,
            missed_data: 0,
            openBlocageDialog: false,
            openCommentDialog: false
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        this.props.params.tab !== this.state.tab &&
            this.setState({
                tab: this.props.params.tab
            });
    };

    getData = () => {
        const { siret } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-profil/${siret}?expand=less&domain=1`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleTab = (event, value) => {
        this.setState({
            tab: value
        });
    };

    updateNbrMissedData = value => {
        this.setState({
            missed_data: value
        });
    };

    handleDialogBlocage = () => {
        this.setState(prevState => ({
            openBlocageDialog: !prevState.openBlocageDialog
        }));
    };

    handleDialogComment = () => {
        this.setState(prevState => ({
            openCommentDialog: !prevState.openCommentDialog
        }));
    };

    render() {
        const {
            classes,
            tab,
            raisonSociale,
            siret,
            bloque,
            raison,
            marchand_id,
            missed_data,
            openBlocageDialog,
            openCommentDialog
        } = this.state;

        return (
            <div>
                <DialogBlocage
                    data={{
                        marchand_id: marchand_id,
                        raisonSociale: raisonSociale,
                        bloque: bloque
                    }}
                    open={openBlocageDialog}
                    update={this.getData}
                    openDialog={this.handleDialogBlocage}
                />

                <DialogComment
                    data={{
                        marchand_id: marchand_id,
                        raisonSociale: raisonSociale
                    }}
                    open={openCommentDialog}
                    update={this.getData}
                    openDialog={this.handleDialogComment}
                />

                <Paper className={classes.paperContent}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Grid container direction="row" alignItems="baseline">
                                <Grid item>
                                    <IconButton onClick={this.getBack}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        <Link component={RouterLink} to="/domain-merchants/all">
                                            Les commerçants du domaine
                                        </Link>{' '}
                                        / {raisonSociale}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Tabs
                                value={tab}
                                style={{ width: 'calc(100vw - 112px)' }}
                                onChange={this.handleTab}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto">
                                <Tab
                                    value={'profil'}
                                    component={RouterLink}
                                    to={`/domain-merchants/get/${siret}/profil`}
                                    label="Profil"
                                />
                                {missed_data > 0 && (
                                    <Tab
                                        value={'missed-data'}
                                        component={RouterLink}
                                        to={`/domain-merchants/get/${siret}/missed-data`}
                                        style={{ color: 'red' }}
                                        label={'Données manquantes (' + missed_data + ')'}
                                    />
                                )}
                                {/*<Tab value={"titularisation"} component={RouterLink} to={`/merchants/get/${siret}/titularisation`} label="Titularisation" />
                                <Tab value={"history"} component={RouterLink} to={`/domain-merchants/get/${siret}/history`} label="Historique des placements" />
                            <Tab value={"facture"} component={RouterLink} to={`/domain-merchants/get/${siret}/facture`} label="Factures" />*/}

                                <Tab
                                    value={'observation'}
                                    component={RouterLink}
                                    to={`/domain-merchants/get/${siret}/observation`}
                                    label="Observations"
                                />
                                <Tab
                                    value={'blocage'}
                                    component={RouterLink}
                                    to={`/domain-merchants/get/${siret}/blocage`}
                                    label="Historique des blocages"
                                />
                            </Tabs>
                        </Grid>
                        <Grid
                            item
                            container
                            style={{ marginTop: 20, marginBottom: 30 }}
                            direction="column"
                            justify="center"
                            spacing={3}>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="stretch"
                                    spacing={1}>
                                    <Grid item>
                                        <Button
                                            onClick={this.handleDialogBlocage}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            style={{
                                                backgroundColor: bloque === '1' ? 'red' : '#3f51b5',
                                                float: 'right'
                                            }}>
                                            {bloque === '0' ? 'Bloquer' : 'Débloquer'} ce commerçant{' '}
                                            <ClearIcon style={{ marginLeft: 7 }} />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={this.handleDialogComment}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            style={{ float: 'right' }}>
                                            Créer une observation{' '}
                                            <VisibilityIcon style={{ marginLeft: 7 }} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <FaceTwoToneIcon
                                    style={{ color: '#3f51b5' }}
                                    className={classes.icon}
                                />
                                <Typography
                                    className={classes.raisonSociale}
                                    color="primary"
                                    variant="h4">
                                    {raisonSociale}
                                </Typography>
                                <Typography className={classes.siret} color="primary" variant="h5">
                                    {siret}
                                </Typography>
                                {bloque === '1' && (
                                    <Typography className={classes.bloque} variant="body1">
                                        Attention commerçant bloqué <br />" {raison} "
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        {marchand_id != '' && (
                            <Grid item xs={12}>
                                <TabControl value={tab} index={'profil'}>
                                    <Profil
                                        updateCompteur={this.props.updateCompteur}
                                        updateMerchant={this.getData}
                                        siret={siret}
                                    />
                                </TabControl>
                                <TabControl value={tab} index={'missed-data'}>
                                    <MissedData
                                        updateCompteur={this.props.updateCompteur}
                                        updateMissedData={this.updateNbrMissedData}
                                        history={this.props.history}
                                        siret={siret}
                                        marchandId={marchand_id}
                                    />
                                </TabControl>
                                <TabControl value={tab} index={'titularisation'}>
                                    <Titularisation marchandId={marchand_id} />
                                </TabControl>
                                <TabControl value={tab} index={'history'}>
                                    <History marchandId={marchand_id} />
                                </TabControl>
                                <TabControl value={tab} index={'facture'}>
                                    <Facture marchandId={marchand_id} />
                                </TabControl>
                                <TabControl value={tab} index={'blocage'}>
                                    <Blocage marchandId={marchand_id} />
                                </TabControl>
                                <TabControl value={tab} index={'observation'}>
                                    <Comment marchandId={marchand_id} />
                                </TabControl>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(Merchant);
