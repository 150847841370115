import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    TextField,
    Grid,
    Table,
    TableRow,
    TableCell,
    TableContainer,
    Divider,
    Typography,
    CircularProgress,
    IconButton,
    TableBody,
    TableHead,
    InputAdornment,
    ListItemText,
    Link
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

import { Pagination, RadioFilter, DateInput, SelectedButton } from './components';

const styles = theme => ({
    root: {
        //padding: theme.spacing(4),
        flexGrow: 1
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 15
    },
    paperContent: {
        padding: theme.spacing(3)
    }
});

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    },
    hover: {
        '&$hover:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.08)'
            //cursor: "pointer",
        }
    },
    selected: {
        '&$selected, &$selected:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.15)',
            cursor: 'auto'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

const WAIT_INTERVAL = 475;
const ENTER_KEY = 13;

class TableDialogContent extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {
            ...props,
            searchValue: '',
            helperText: '',
            inputLabel: '',
            hasDate: false,
            filters: false,
            columns: [],
            rows: [],
            page: 1,
            numberOfPages: 0,
            records: 0,
            recordsWithoutEmail: 0,
            totalChosenRecords: 0,
            isLoading: true,
            init: true
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { isLoading, init, choosenFilter, start, end } = this.state;

        if (
            choosenFilter != prevState.choosenFilter ||
            start != prevState.start ||
            end != prevState.end
        ) {
            this.props.setData('choosenFilter', choosenFilter);
            this.props.setData('start', start);
            this.props.setData('end', end);
        }

        if (isLoading && prevState.isLoading != isLoading) {
            this.getData('POST');
        }

        if (!init && prevState.init != init) {
            this.getData('POST');
        }
    };

    handleSearch = ({ target: { value } }) => {
        clearTimeout(this.timer);

        this.timer = setTimeout(this.refresh, WAIT_INTERVAL);

        this.setState({
            searchValue: value,
            isLoading: false,
            page: 1
        });
    };

    handleKeyDown = e => {
        if (e.keyCode === ENTER_KEY) {
            this.refresh();
        }
    };

    refresh = () => {
        this.setState({
            isLoading: true
        });
    };

    getData = (_method = 'GET') => {
        const { reminder, page, searchValue, choosenFilter, type, start, end } = this.state;

        const args = {
            method: _method
        };

        if (_method == 'POST') {
            const formData = new FormData();
            formData.append('page', page);
            formData.append('search', searchValue);
            formData.append('choosenFilter', choosenFilter);
            formData.append('start', start);
            formData.append('end', end);
            args.body = formData;
        }

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/email/reminder/${reminder}?type=${type}`,
            args
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    isLoading: false,
                    init: false,
                    ...data
                });
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    handleDate = (name, value) => {
        this.setState({
            [name]: value,
            page: 1
        });
    };

    handlePagination = value => {
        this.setState({
            page: value,
            isLoading: true
        });
    };

    handleFilter = value => {
        this.setState({
            choosenFilter: value,
            page: 1,
            isLoading: true
        });
    };

    handleCount = value => {
        this.setState(prevState => ({
            totalChosenRecords: prevState.totalChosenRecords + value
        }));
    };

    render() {
        const {
            classes,
            filters,
            columns,
            rows,
            page,
            numberOfPages,
            records,
            recordsWithoutEmail,
            totalChosenRecords,
            isLoading,
            searchValue,
            start,
            end,
            helperText,
            inputLabel,
            choosenFilter,
            hasDate,
            reminder
        } = this.state;

        return (
            <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
                {hasDate && (
                    <React.Fragment>
                        <Grid item>
                            <DateInput
                                start={start}
                                end={end}
                                fncs={{
                                    handleDate: this.handleDate,
                                    search: this.refresh
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                    </React.Fragment>
                )}
                <Grid item>
                    <TextField
                        label={inputLabel}
                        helperText={helperText}
                        type="text"
                        fullWidth
                        value={searchValue}
                        onChange={this.handleSearch}
                        variant="outlined"
                        InputProps={{
                            endAdornment: isLoading ? (
                                <CircularProgress size={25} />
                            ) : (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => this.handleSearch({ target: { value: '' } })}
                                        edge="end">
                                        <ClearIcon style={{ color: 'red' }} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                {filters && (
                    <Grid item>
                        <RadioFilter
                            handleFilter={this.handleFilter}
                            data={{
                                filters: filters,
                                value: choosenFilter
                            }}
                        />
                    </Grid>
                )}
                <Grid item>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Grid container direction="row" justify="space-between" spacing={1}>
                                <Grid item>
                                    <Typography color="primary">
                                        Nombre de résultats : {records}{' '}
                                        {recordsWithoutEmail > 0 &&
                                            `(${recordsWithoutEmail} sans mail)`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography color="primary">
                                        Total sélectionné : {totalChosenRecords}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TableContainer>
                                <Table stickyHeader size="small" className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map(column => (
                                                <StyledTableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    //style={{ width: column.minWidth }}
                                                >
                                                    {column.label}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, index) => {
                                            return (
                                                <StyledTableRow hover key={index}>
                                                    {columns.map((column, subindex) => (
                                                        <TableCell
                                                            key={`${column.id}_${index}`}
                                                            align={column.align}>
                                                            {column.id == 'selected' ? (
                                                                <SelectedButton
                                                                    key={`${column.id}_${index}_${row.siret}`}
                                                                    setCount={this.handleCount}
                                                                    data={{
                                                                        reminder: reminder,
                                                                        id: row.id,
                                                                        isSelected: Boolean(
                                                                            row.isSelected == 'y'
                                                                        ),
                                                                        isActive: Boolean(
                                                                            row.isActive == 'y'
                                                                        )
                                                                    }}
                                                                />
                                                            ) : column.subkey ? (
                                                                <ListItemText
                                                                    key={`row_${subindex}_${
                                                                        row[column.id]
                                                                    }`}
                                                                    primary={
                                                                        column.id ==
                                                                        'socialeReason' ? (
                                                                            <Link
                                                                                component={
                                                                                    RouterLink
                                                                                }
                                                                                to={`/merchants/get/${row.siret}/profil`}>
                                                                                <Typography
                                                                                    color="primary"
                                                                                    variant="body1">
                                                                                    {row[column.id]}
                                                                                </Typography>
                                                                            </Link>
                                                                        ) : (
                                                                            <Typography variant="body1">
                                                                                {row[column.id]}
                                                                            </Typography>
                                                                        )
                                                                    }
                                                                    secondary={
                                                                        <Typography
                                                                            variant="body2"
                                                                            style={{
                                                                                color: Boolean(
                                                                                    row.isActive ==
                                                                                        'y'
                                                                                )
                                                                                    ? 'default'
                                                                                    : Boolean(
                                                                                          column.id ==
                                                                                              'socialeReason'
                                                                                      )
                                                                                    ? 'red'
                                                                                    : 'default'
                                                                            }}>
                                                                            {row[column.subkey]}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            ) : (
                                                                <Typography
                                                                    key={`row_${subindex}_${
                                                                        row[column.id]
                                                                    }`}
                                                                    variant="body1">
                                                                    {row[column.id]}
                                                                </Typography>
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item>
                            <Pagination
                                numberOfPages={numberOfPages}
                                currentPage={page}
                                handlePagination={this.handlePagination}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(TableDialogContent);
