// @flow

import React from 'react';
import L from 'leaflet';
import env from 'common/env';
import { Map, TileLayer } from 'react-leaflet';

import { empty } from 'common/validators';

import { withStyles } from '@material-ui/styles';

import { Control } from './components';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const styles = theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            height: '33vh'
        },
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 110px)'
        }
    }
});

class MarketMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            mounting: true,
            mapConfiguration: {
                center: [0, 0],
                zoom: 19,
                maxZoom: 19,
                minZoom: 17,
                scrollWheelZoom: false,
                zoomControl: false,
                useFlyTo: false,
                doubleClickZoom: false
            }
        };
    }

    componentDidMount = () => {
        //window.addEventListener("resize", this.setScreenOrientation);
        this.getData();
    };

    componentDidUpdate = prevProps => {
        if (
            prevProps.args.page != this.props.args.page ||
            prevProps.args.searchValue != this.props.args.searchValue
        ) {
            this.getData();
        }
    };

    getData = () => {
        const formData = new FormData();

        formData.append('page', this.props.args.page);
        formData.append('search', this.props.args.searchValue);
        formData.append('load', false);

        fetch(`${env}/placement/get-map-places-journalmarket/${this.props.id}`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                const { lastPlace } = this.props;
                const center =
                    this.state.mounting && lastPlace
                        ? [lastPlace.a_lat, lastPlace.a_lng]
                        : [data.firstRow?.a_lat, data.firstRow?.a_lng];
                if (center[0] !== undefined) {
                    this.setState(prevState => ({
                        mapConfiguration: {
                            ...prevState.mapConfiguration,
                            center
                        },
                        mounting: false
                    }));
                }
            })
            .catch(
                error => {
                    console.error(error);
                    //alert("Echec de la récupération des données")
                } // Handle the error response object
            );
    };
    /*
  setScreenOrientation = () => {
    this.setState(prevState => ({
      mapConfiguration: {
        ...prevState.mapConfiguration,
        //style: {
          //height: SmallScreen ? "33vh" : "calc(100vh - 110px)",
        //}
      }
    }));
  }
*/
    render() {
        const { mapConfiguration, classes } = this.state;

        return (
            <Map {...mapConfiguration} className={classes.root}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; Digit'Marché"
                />

                <Control
                    doPlacement={this.props.doPlacement}
                    args={this.props.args}
                    id={this.props.id}
                    filter={this.props.filter}
                />
            </Map>
        );
    }
}

export default withStyles(styles)(MarketMap);
