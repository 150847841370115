import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    IconButton,
    ClickAwayListener,
    Grow,
    Popper,
    MenuItem,
    MenuList,
    Paper,
    Grid,
    Tooltip
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import SendIcon from '@material-ui/icons/Send';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles(theme => ({
    popper: {
        zIndex: 9
    },
    root: {
        display: 'inline-flex'
    }
}));

const ActionButton = props => {
    const { className, bill, view, billSender, ...rest } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
        //billSender();
    };

    return (
        <div className={classes.root}>
            <Tooltip title="Afficher les factures">
                <IconButton onClick={() => view()}>
                    <PictureAsPdfIcon size="small" />
                </IconButton>
            </Tooltip>
            <IconButton ref={anchorRef} size="small" onClick={handleToggle}>
                <MoreVertIcon size="small" />
            </IconButton>
            <Popper
                className={classes.popper}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <MenuItem
                                        key={1}
                                        onClick={event => {
                                            handleClose(event);
                                            billSender();
                                        }}>
                                        Envoyer par mail <SendIcon style={{ paddingLeft: 7 }} />
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default ActionButton;
