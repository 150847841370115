import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import CheckIcon from '@material-ui/icons/Check';

import {
    Paper,
    Grid,
    TextField,
    Typography,
    Divider,
    Button,
    FormControlLabel,
    RadioGroup,
    Radio,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';

import { DialogTitle } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
}));

const Comment = props => {
    const { className, data, cat, id, history, ...rest } = props;

    const [comment, setComment] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [choice, setChoice] = React.useState('');

    const handleComment = ({ target: { value } }) => {
        setComment(value);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const handleChoice = ({ target: { value } }) => {
        if (value == 'ras') {
            setComment('Rien à signaler');
        } else {
            setComment('');
        }
        setChoice(value);
    };

    const openDialogBeforeCloture = () => {
        if (!comment || comment.length == 0 || !comment.replace(/\s/g, '').length) {
            alert('Veuillez laisser un commentaire ou bien cochez la case "Rien à signaler"');
            return;
        }

        setOpen(true);
    };

    const sendData = () => {
        const formData = new FormData();
        formData.append('c', comment);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/synthesis/update/${id}/commentaire`,
            {
                // Your POST endpoint
                method: 'POST',
                body: formData // This is your file object
            }
        )
            .then(response => {
                // Obligé de retouner la réponse JSON pour qu'elle soit contenu dans la v.a data
                return response.ok ? response.json() : false;
            })
            .then(data => {
                if (data) {
                    alert('Marché clôturé avec succés.');
                    history.push('/home');
                }
            })
            .then(
                success => console.log(success) // Handle the success response object
            )
            .catch(
                error => {
                    console.error(error);
                } // Handle the error response object
            );
    };

    const classes = useStyles();

    return (
        <div>
            <Dialog open={open} onClose={closeDialog} fullWidth maxWidth={'sm'}>
                <DialogTitle onClose={closeDialog}>{'Clôture de marché'}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Etes-vous sûr de vouloir clôturer le marché ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="secondary">
                        Ne pas clôturer
                    </Button>
                    <Button onClick={sendData} variant="contained" color="primary" autoFocus>
                        Clôturer
                    </Button>
                </DialogActions>
            </Dialog>

            <Paper className={classes.paperContent}>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            justify="space-between"
                            alignItems="baseline">
                            <Grid item>
                                <Typography variant="h4" color="primary">
                                    Clôture de marché
                                </Typography>
                            </Grid>
                            {data.etat == '2' && (
                                <Grid item>
                                    <Grid container direction="row" spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="h4" style={{ color: 'green' }}>
                                                Marché clôturé
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <CheckIcon style={{ color: 'green' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    {data.etat != '2' && (
                        <Grid item>
                            <RadioGroup value={choice} onChange={handleChoice}>
                                <FormControlLabel
                                    value="ras"
                                    control={<Radio />}
                                    label="Rien à signaler"
                                />
                                <FormControlLabel
                                    value="com"
                                    control={<Radio />}
                                    label="Laisser un commentaire"
                                />
                            </RadioGroup>
                        </Grid>
                    )}
                    <Grid item>
                        <TextField
                            multiline
                            disabled={Boolean(choice == '')}
                            variant="outlined"
                            fullWidth
                            rows="5"
                            value={data.etat == '2' ? data.comment : comment}
                            onChange={handleComment}
                        />
                    </Grid>
                    {data.etat != '2' && (
                        <Grid item>
                            <Button
                                /*disabled={Boolean(etat == 2)}*/ onClick={openDialogBeforeCloture}
                                fullWidth
                                color="primary"
                                variant="contained">
                                Clôturer le marché
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </div>
    );
};

export default Comment;
