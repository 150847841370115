import React, { Component } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { combineStyles } from 'common/styles';
import { SearchDateCard, SearchTextField, MuiTable } from 'components';
import {
    getBills,
    sendGroupedBills,
    sendGroupedBillsByDate,
    sendGroupedBillsByBillNumber
} from './action';
import {
    printGroupedBillsByDate,
    printGroupedBillsByBillNumber,
    printGroupedBills
} from 'common/pdf';

import { Grid, Paper, Divider, TableRow, TableCell, Typography } from '@material-ui/core';

import { StatusButton, BillBadge, ActionButton, GroupedBillsForm } from './components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { empty } from 'common/validators';

const styles = theme => ({
    root: {
        //padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    }
});

/**
 *
 * @param {*} props
 * @returns
 */
const DEFAULT_STATE = props => ({
    ...props,
    filter: 'all',
    state: 'invoiced',
    searchValue: '',
    chart: [],
    order: 'asc',
    orderBy: headers[0][0].id,
    page: 0,
    record: 0,
    rows: [],
    loading: true,
    openDialog: '',
    id: null
});

const headers = [
    [
        {
            id: 'socialeReason',
            align: 'left',
            label: 'Raison sociale',
            sortable: true,
            style: { width: '25%' }
        },
        { id: 'status', align: 'center', label: 'Statut', sortable: false },
        { id: 'raw_amount', align: 'right', label: 'Montant', sortable: true },
        { id: 'action', align: 'center', label: 'Actions', sortable: false }
    ],
    [
        {
            id: 'socialeReason',
            align: 'left',
            label: 'Raison sociale',
            sortable: true,
            style: { width: '25%' }
        },
        { id: 'bill', align: 'left', label: 'Facture', sortable: false },
        { id: 'status', align: 'center', label: 'Statut', sortable: false },
        { id: 'raw_amount', align: 'right', label: 'Montant', sortable: true },
        { id: 'action', align: 'center', label: 'Actions', sortable: false }
    ]
];

/**
 *
 * @param {*} classes
 * @returns
 */
const filters = classes => [
    [
        {
            label: 'facturée',
            value: 'invoiced',
            className: classes.unpaid
        },
        {
            label: 'payée',
            value: 'paid',
            className: classes.paid
        }
    ],
    [
        { label: 'tous', value: 'all' },
        {
            label: 'Titulaire abonné',
            value: 'ta',
            className: classes.ta
        },
        {
            label: 'Titulaire non abonné',
            value: 'tna',
            className: classes.tna
        },
        {
            label: 'Passager',
            value: 'p',
            className: classes.p
        }
    ]
];

export class ViewGroupingBills extends Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE(props);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            this.state.page != prevState.page ||
            this.state.order != prevState.order ||
            this.state.orderBy != prevState.orderBy ||
            this.state.filter != prevState.filter ||
            this.state.state != prevState.state ||
            this.state.searchValue != prevState.searchValue
        ) {
            this.setState({
                loading: true
            });
        }

        this.state.loading && this.state.loading != prevState.loading && this.getData();
    };

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const { searchValue, page, order, orderBy, filter, start, end, state } = this.state;

        getBills(page, order, orderBy, searchValue, filter, state, start, end)
            .then(res =>
                this.setState({
                    loading: false,
                    ...res
                })
            )
            .catch(err => {
                alert(err);
                this.setState({
                    loading: false
                });
            });
    };

    handleDialog = (openDialog, id = null) => {
        this.setState({ openDialog, id });
    };

    handleForm = () => {
        const { type, action, start, end } = this.state;

        this.setState({
            loading: true
        });

        this.props.history.push(`/bills/${type}/${action}/${start}/${end}`);
    };

    render() {
        const {
            classes,
            searchValue,
            disableSearch,
            chart,
            order,
            orderBy,
            filter,
            state,
            page,
            record,
            rows,
            loading,
            id,
            openDialog,
            start,
            end
        } = this.state;

        return (
            <div className={classes.root}>
                <GroupedBillsForm
                    close={() => {
                        this.handleDialog('', null);
                        this.setState({ loading: true });
                    }}
                    start={start}
                    end={end}
                    id={openDialog === 'payment' && id}
                />

                <Grid
                    spacing={3}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch">
                    <Grid item>
                        <Grid container direction="row" spacing={3} alignItems="stretch">
                            <Grid item xs md>
                                <SearchDateCard
                                    inline
                                    title="Recherche par date d'émission de facture"
                                    submit={this.handleForm}
                                    start={{
                                        set: val => this.setState({ start: val }),
                                        data: start
                                    }}
                                    end={{
                                        set: val => this.setState({ end: val }),
                                        data: end
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Paper className={classes.paperContent}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                spacing={3}>
                                <Grid item>
                                    <SearchTextField
                                        label={`Rechercher un commerçant`}
                                        value={searchValue}
                                        disabled={disableSearch}
                                        onChange={value => {
                                            this.setState({
                                                searchValue: value,
                                                page: 0
                                            });
                                        }}
                                        helperText={
                                            'Saisir une raison sociale, un nom, un prénom ou une enseigne'
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <MuiTable
                                        // disabled
                                        refresh={() => this.setState({ loading: true })}
                                        //print={}
                                        loading={loading}
                                        headers={headers[state === 'invoiced' ? 0 : 1]}
                                        page={{
                                            set: val => this.setState({ page: val }),
                                            data: page,
                                            count: record
                                        }}
                                        order={{
                                            set: val => this.setState({ order: val }),
                                            data: order
                                        }}
                                        orderBy={{
                                            set: val => this.setState({ orderBy: val }),
                                            data: orderBy
                                        }}
                                        filter={[
                                            {
                                                set: a => this.setState({ state: a, page: 0 }),
                                                sortLabel: 'statut',
                                                default: 'invoiced',
                                                current: state,
                                                rows: filters(classes)[0]
                                            },
                                            {
                                                set: a => this.setState({ filter: a, page: 0 }),
                                                sortLabel: 'type',
                                                default: 'all',
                                                current: filter,
                                                rows: filters(classes)[1]
                                            }
                                        ]}>
                                        {rows.map((row, index) => (
                                            <TableRow hover key={`${index}-${row.merchantId}`}>
                                                <TableCell
                                                    align="left"
                                                    key={`socialReason-${index}-${row.billNumber}`}>
                                                    {row.socialeReason}
                                                </TableCell>
                                                {state === 'paid' ? (
                                                    <TableCell
                                                        align={state === 'paid' ? 'left' : 'center'}
                                                        key={`bill-${index}-${row.merchantId}`}>
                                                        {row.billState === 'n' ? (
                                                            <BillBadge
                                                                record={parseInt(row.record)}
                                                                bills={row.bills}
                                                                className={classes.unpaid}
                                                            />
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    display: 'inline-flex',
                                                                    marginTop: 5
                                                                }}>
                                                                <CheckCircleIcon
                                                                    fontSize="small"
                                                                    className={classes.paid}
                                                                    style={{ marginRight: 4 }}
                                                                />
                                                                {row.billNumber}
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                ) : null}
                                                <TableCell
                                                    align="center"
                                                    key={`status-${index}-${row.merchantId}`}>
                                                    <StatusButton
                                                        className={clsx({
                                                            [classes.paid]: row.billState === 'y',
                                                            [classes.unpaid]: row.billState === 'n'
                                                        })}
                                                        paid={row.billState === 'y'}
                                                        id={row.merchantId}
                                                        pay={value =>
                                                            this.handleDialog('payment', value)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    key={`amount-${index}-${row.merchantId}`}>
                                                    <Typography
                                                        variant="button"
                                                        className={clsx({
                                                            [classes.paid]: row.billState === 'y',
                                                            [classes.unpaid]: row.billState === 'n'
                                                        })}>
                                                        {row.amount} €
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    key={`action-${index}-${row.merchantId}`}>
                                                    <ActionButton
                                                        view={() =>
                                                            row.billState === 'y'
                                                                ? printGroupedBillsByBillNumber(
                                                                      row.billNumber
                                                                  )
                                                                : empty(start) && empty(end)
                                                                ? printGroupedBills(row.merchantId)
                                                                : printGroupedBillsByDate(
                                                                      row.merchantId,
                                                                      start,
                                                                      end
                                                                  )
                                                        }
                                                        billSender={() =>
                                                            row.billState === 'y'
                                                                ? sendGroupedBillsByBillNumber(
                                                                      row.billNumber
                                                                  )
                                                                : empty(start) && empty(end)
                                                                ? sendGroupedBills(row.merchantId)
                                                                : sendGroupedBillsByDate(
                                                                      row.merchantId,
                                                                      start,
                                                                      end
                                                                  )
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </MuiTable>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(combineStyles(styles))(ViewGroupingBills);
