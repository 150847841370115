import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
    Dialog,
    DialogContent,
    Typography,
    Button,
    DialogTitle,
    TextField,
    DialogActions
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {}
}));

const DialogBlocage = props => {
    const { className, open, data, openDialog, update, ...rest } = props;

    const classes = useStyles();

    const [motifBlocage, setMotifBlocage] = React.useState('');

    const handleBlocage = () => {
        const deblocage = Boolean(data.bloque == '1');

        if (
            (!motifBlocage ||
                motifBlocage.length == 0 ||
                !motifBlocage.replace(/\s/g, '').length) &&
            deblocage == false
        ) {
            alert('Motif non valide');
            return;
        }

        const formData = new FormData();
        formData.append('motif', motifBlocage);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/blocage-merchant/${data.marchand_id}`,
            {
                method: 'POST',
                //headers: { 'Content-Type': 'application/json' },
                body: formData
            }
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                if (data) {
                    openDialog();
                    update();
                }
            })
            .catch(console.log);
    };

    const handleMotif = ({ target: { value } }) => {
        setMotifBlocage(value);
    };

    return (
        <Dialog open={open} onClose={openDialog} maxWidth={'sm'} fullWidth={'sm'}>
            <DialogTitle>
                <Typography color="primary" variant="button">
                    {data.bloque == '1' ? 'Débloquer' : 'Bloquer'} {data.raisonSociale} ?
                </Typography>
            </DialogTitle>
            <DialogContent>
                {data.bloque == '0' && (
                    <TextField
                        label="Motif du blocage"
                        variant="outlined"
                        onChange={handleMotif}
                        InputLabelProps={{
                            shrink: true
                        }}
                        multiline
                        fullWidth
                        rows={4}
                        helperText="Saisir un motif"
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={openDialog} color="primary">
                    Annuler
                </Button>
                <Button variant="contained" color="primary" onClick={handleBlocage}>
                    {data.bloque == '1' ? 'Débloquer' : 'Bloquer'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogBlocage;
