import React, { useEffect } from 'react';
//import { Router } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
//import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import UserProfile from './UserProfile';
import JournalMarche from './JournalMarche';

//
//const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
    draw: chartjs.draw
});

validate.validators = {
    ...validate.validators,
    ...validators
};

class App extends React.Component {
    componentDidMount = () => {
        if (this.props.location.pathname == '/logout' || !sessionStorage.getItem('token')) {
            this.clearAllUserData();
        }

        if (
            this.props.location.pathname != '/' &&
            this.props.location.pathname != '/sign-in' &&
            UserProfile.getRoleId() == 0
        ) {
            this.hasProfile();
        }

        if (
            (this.props.location.pathname == '/' || this.props.location.pathname == '/sign-in') &&
            UserProfile.getRoleId() > 0
        ) {
            this.props.history.push('/home');
        }
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.location !== nextProps.location) {
            this.onRouteChanged(nextProps.location);
            this.hasProfile(nextProps.location);
        }

        return true;
    };

    onRouteChanged = location => {
        if (location.pathname == '/home') {
            this.clearJournalMarket();
        }

        if (this.props.location.pathname == '/logout') {
            this.clearAllUserData();
        }

        /*window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });*/
    };

    clearJournalMarket = () => {
        JournalMarche.clearSession();
        JournalMarche.setId(0);
        JournalMarche.setLabel(null);
        JournalMarche.setDate(null);
    };

    clearUserProfile = () => {
        UserProfile.clearSession();
        UserProfile.setName(null);
        UserProfile.setId(0);
        UserProfile.setProfile(null);
        UserProfile.setDomaine(null);
        UserProfile.setRoleId(0);
        UserProfile.setContributor(false);
    };

    clearAllUserData = () => {
        this.clearUserProfile();
        this.clearJournalMarket();
    };

    hasProfile = (requestedLocation = false) => {
        if (requestedLocation) {
            const _requested = requestedLocation.pathname;
            const _location = this.props.location.pathname;

            if (
                UserProfile.getRoleId() == 0 &&
                _location != '/' &&
                _requested != '/' &&
                _requested != '/sign-in'
            ) {
                alert("Vous n'êtes plus connecté.");
                this.clearAllUserData();
                this.props.history.push('/sign-in');
            }
        } else {
            if (sessionStorage.getItem('token')) {
                alert('Veuillez vous connecter.');
            }
            this.clearAllUserData();
            this.props.history.push('/sign-in');
        }
    };

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Routes userProfile={UserProfile} journalMarket={JournalMarche} />
            </ThemeProvider>
        );
    }
}

export default withRouter(App);
