import React, { useEffect, useRef, useState } from 'react';
import { getPdfPreview } from '../services/http';
import { BillSenderLoader as GenericLoader } from 'views/BillSender/components';
import { CircularProgress } from '@material-ui/core';

const PdfFrame = ({ id, details }) => {
    const [loading, setLoading] = useState(true);
    const fileUrl = getPdfPreview(id, details);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    return loading ? (
        <GenericLoader icon={<CircularProgress />} describe="Chargement de l'aperçu en cours" />
    ) : (
        <object data={fileUrl} width="100%" height="820px" type="application/pdf">
            <p style={{ textAlign: 'center' }}>
                Impossible d'afficher le pdf. Mais vous pouvez le{' '}
                <a href={fileUrl} target="_blank">
                    télécharger
                </a>
                .
            </p>
        </object>
    );
};

export default PdfFrame;
