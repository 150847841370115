import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Paper,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    FormGroup,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    Radio,
    Divider,
    Button
} from '@material-ui/core';

import { MerchantButton, Pagination } from './components';

import ClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

class All extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            searchValue: '',
            alim: false,
            prim: false,
            manu: false,
            dempos: false,
            all: true,
            type: 'all',
            list: [],
            page: 1,
            number_of_pages: 0
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        (this.state.alim != prevState.alim ||
            this.state.prim != prevState.prim ||
            this.state.manu != prevState.manu ||
            this.state.dempos != prevState.dempos ||
            this.state.all != prevState.all ||
            this.state.type != prevState.type ||
            this.state.searchValue != prevState.searchValue ||
            this.state.page != prevState.page) &&
            this.getData();
    };

    getData = () => {
        const { alim, prim, manu, dempos, all, type, searchValue, page } = this.state;

        const formData = new FormData();
        if (!alim && !prim && !manu && !dempos && !all) {
            this.setState({
                all: true
            });
            formData.append('alim', true);
            formData.append('prim', true);
            formData.append('manu', true);
            formData.append('dempos', true);
        } else {
            formData.append('alim', all ? true : alim);
            formData.append('prim', all ? true : prim);
            formData.append('manu', all ? true : manu);
            formData.append('dempos', all ? true : dempos);
        }

        formData.append('type', type);
        formData.append('page', page);
        formData.append('search', searchValue);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-by`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    list: data.list,
                    number_of_pages: data.count
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handleTypeFilter = ({ target: { value } }) => {
        this.setState({
            type: value,
            page: 1
        });
    };

    handleActivitieFilter = ({ target: { name } }) => {
        const { all, alim, prim, manu, dempos } = this.state;

        if (name === 'all') {
            !all &&
                this.setState({
                    alim: false,
                    prim: false,
                    manu: false,
                    dempos: false
                });
        } else {
            this.setState({
                all: false
            });
        }

        this.setState(prevState => ({
            [name]: !prevState[name],
            page: 1
        }));
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    getPdf = () => {
        const { alim, prim, manu, dempos, type } = this.state;

        let _confirm = window.confirm('Imprimer la sélection ?');
        _confirm &&
            window.open(
                `https://www.${
                    window.location.hostname.split('.')[1]
                        ? window.location.hostname.split('.')[0] === 'www'
                            ? window.location.hostname.split('.')[1]
                            : window.location.hostname.split('.')[0]
                        : 'dev'
                }.api.digitmarche.fr/api/merchant/print-get-by?alim=${alim}&prim=${prim}&manu=${manu}&dempos=${dempos}&type=${type}`,
                '_blank'
            );
    };

    render() {
        const {
            classes,
            searchValue,
            alim,
            prim,
            manu,
            dempos,
            all,
            type,
            list,
            page,
            number_of_pages
        } = this.state;

        return (
            <Paper className={classes.paperContent}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                    spacing={3}>
                    <Grid item>
                        <TextField
                            label={'Rechercher un commerçant'}
                            type="text"
                            fullWidth
                            value={searchValue}
                            onChange={this.handleSearch}
                            variant="outlined"
                            helperText="Saisir un SIRET/SIREN, une raison sociale, une enseigne, un nom ou un prénom"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={this.clearSearch} edge="end">
                                            <ClearIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.labelFilter}>
                                Type de commerçant
                            </FormLabel>
                            <RadioGroup
                                row
                                name="type"
                                value={type}
                                onChange={this.handleTypeFilter}>
                                <FormControlLabel value="all" control={<Radio />} label="Tous" />
                                <FormControlLabel
                                    value="titulaire"
                                    control={<Radio />}
                                    label="Titulaire"
                                />
                                <FormControlLabel
                                    value="passager"
                                    control={<Radio />}
                                    label="Passager"
                                />
                                <FormControlLabel
                                    value="blocked"
                                    control={<Radio style={{ color: 'red' }} />}
                                    label="Bloqué"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            justify="space-between"
                            alignItems="center">
                            <Grid item>
                                <FormControl component="fieldset">
                                    <FormLabel className={classes.labelFilter} component="label">
                                        Activité du commerçant
                                    </FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={all}
                                                    onChange={this.handleActivitieFilter}
                                                    name="all"
                                                />
                                            }
                                            label="Tous"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    style={{ color: 'gold' }}
                                                    checked={alim}
                                                    onChange={this.handleActivitieFilter}
                                                    name="alim"
                                                />
                                            }
                                            label="Alimentaire"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    style={{ color: '#32CD32' }}
                                                    checked={prim}
                                                    onChange={this.handleActivitieFilter}
                                                    name="prim"
                                                />
                                            }
                                            label="Primeur"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    style={{ color: '#87CEFA' }}
                                                    checked={manu}
                                                    onChange={this.handleActivitieFilter}
                                                    name="manu"
                                                />
                                            }
                                            label="Manufacturé"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    style={{ color: '#BD33A4' }}
                                                    checked={dempos}
                                                    onChange={this.handleActivitieFilter}
                                                    name="dempos"
                                                />
                                            }
                                            label="Démonstrateur/Posticheur"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Button
                                    endIcon={<PrintIcon />}
                                    onClick={this.getPdf}
                                    color="primary">
                                    Imprimer
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={4}>
                            {list &&
                                list.map(merchant => {
                                    return <MerchantButton data={merchant} />;
                                })}
                        </Grid>
                        <Grid item style={{ marginTop: 35 }}>
                            <Pagination
                                numberOfPages={number_of_pages}
                                currentPage={page}
                                handlePagination={this.handlePagination}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(All);
