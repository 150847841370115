import env from 'common/env';

/**
 *
 * @param {*} id
 * @param {*} payload
 * @returns
 */
export async function getUnits(payload) {
    const formData = new FormData();
    formData.append('search', payload.searchValue ?? '');
    formData.append('order', payload.order ?? 'asc');
    formData.append('orderBy', payload.orderBy ?? 'unite.libelle');
    formData.append('page', payload.page);

    const result = await fetch(`${env}/unit/`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @returns
 */
export async function getLastUnit() {
    const result = await fetch(`${env}/unit/last`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @param {*} id
 * @returns
 */
export async function getUnit(id) {
    const result = await fetch(`${env}/unit/${id}`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @param {*} payload
 * @returns
 */
export async function create(payload) {
    const formData = new FormData();
    formData.append('libelle', payload.libelle);
    formData.append('amount', payload.amount);

    const result = await fetch(`${env}/unit/create`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @param {*} id
 * @param {*} payload
 * @returns
 */
export async function update(id, payload) {
    const formData = new FormData();
    formData.append('libelle', payload.libelle);
    formData.append('amount', payload.amount);

    const result = await fetch(`${env}/unit/${id}/update`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}
