import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
    Stepper,
    Step,
    StepButton,
    Card,
    Grid,
    Button,
    ButtonGroup,
    StepLabel,
    StepConnector
} from '@material-ui/core';

import { Registration, Placement, Finance, Synthesis, FormCashDirectly } from './components';

import TabControl from './TabControl';
import EuroIcon from '@material-ui/icons/Euro';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import EqualizerIcon from '@material-ui/icons/Equalizer';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    buttonAction: {
        padding: 14
    }
});

const hostname = window.location.hostname;
const isForcalquier =
    hostname === 'forcalquier.digitmarche.fr' ||
    hostname === 'www.forcalquier.digitmarche.fr' ||
    hostname === 'www.forcalquier-formation.digitmarche.fr' ||
    hostname === 'forcalquier-formation.digitmarche.fr';

const steps = [
    {
        step: 0,
        link: 'registration',
        label: 'Présence'
    },
    {
        step: 1,
        link: 'placement',
        label: 'Placement'
    },
    {
        step: 2,
        link: 'finance',
        label: 'Encaissement'
    },
    {
        step: 3,
        link: 'synthesis',
        label: 'Synthèse du marché'
    },
    {
        step: 4,
        link: 'facturation',
        label: 'Facturer un commerçant'
    }
];

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22
    },
    line: {
        height: 2,
        border: 0,
        backgroundColor: '#2979ff',
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        backgroundColor: '#2979ff',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    },
    notActive: {
        backgroundColor: 'white',
        color: '#2979ff',
        border: '1px solid #2979ff'
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, icon } = props;

    const icons = {
        1: <AssignmentTurnedInIcon />,
        2: <PersonPinCircleIcon />,
        3: <EuroIcon />,
        4: <EqualizerIcon />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.notActive]: !active
            })}>
            {icons[String(props.icon)]}
        </div>
    );
}

class Management extends React.Component {
    constructor(props) {
        super(props);
        const { activeStep, filter } = this.props.match.params;

        this.state = {
            ...props,
            openCashDirectly: false,
            activeStep: this.getStep(activeStep),
            filter: filter
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { activeStep, filter } = this.props.match.params;

        (prevState.activeStep != this.getStep(activeStep) || prevState.filter != filter) &&
            this.setState({
                activeStep: this.getStep(activeStep),
                filter: filter
            });
    }

    getStep = value => {
        let tostep = 0;

        steps.map(step => {
            if (step.link === value) {
                tostep = step.step;
            }

            if (step.step === value) {
                tostep = step.link;
            }
        });

        return tostep;
    };

    // Handle Step
    handleStep = tostep => {
        const { history } = this.props;
        const { id, filter } = this.props.match.params;
        //const { filter } = this.state

        this.setState({
            activeStep: this.getStep(tostep)
        });

        //history.push(`/management/${id}/${tostep}/${filter}`);
    };

    handleFilter = filter => {
        const { id } = this.props.match.params;
        const { history } = this.props;
        const { activeStep } = this.state;

        this.setState({
            filter: filter
        });

        //alert(`Vous êtes en mode ${filter == "t" ? "titulaire" : "passager"}`)

        //history.push(`/management/${id}/${this.getStep(activeStep)}/${filter}`);
    };

    handleOpenCashDirectly = () => {
        this.props.history.go(-1);
    };

    render() {
        const { classes, activeStep, filter, openCashDirectly } = this.state;
        const { id } = this.props.match.params;

        return (
            <div className={classes.root}>
                <FormCashDirectly
                    close={this.handleOpenCashDirectly}
                    open={Boolean(this.getStep(activeStep) == 'facturation')}
                    id={id}
                />

                <Card>
                    <Grid container direction="column" justify="center" alignItems="stretch">
                        <Grid item>
                            <Stepper
                                nonLinear
                                alternativeLabel
                                activeStep={activeStep}
                                connector={<ColorlibConnector />}>
                                {steps.map(
                                    (step, index) =>
                                        index < 4 && (
                                            <Step key={index}>
                                                <StepButton
                                                    component={RouterLink}
                                                    to={`/management/${id}/${step.link}/${filter}`}
                                                    onClick={() => this.handleStep(step.link)}>
                                                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                                                        {step.label}
                                                    </StepLabel>
                                                </StepButton>
                                            </Step>
                                        )
                                )}
                            </Stepper>
                        </Grid>
                        {/*    (this.getStep(activeStep) != "synthesis" && this.getStep(activeStep) != "finance" && this.getStep(activeStep) != "placement") &&
                            <Grid item>
                                <ButtonGroup fullWidth color="primary" style={{ padding: "10px 24px 24px 24px" }}>
                                    <Button fullWidth component={RouterLink} to={`/management/${id}/${this.getStep(activeStep)}/t`} variant={filter == "t" ? "contained" : "outlined"} onClick={() => this.handleFilter("t")}>Titulaires</Button>
                                    <Button fullWidth component={RouterLink} to={`/management/${id}/${this.getStep(activeStep)}/p`} onClick={() => this.handleFilter("p")} color="primary" variant={filter == "p" ? "contained" : "outlined"}>Passagers</Button>
                                </ButtonGroup>
                            </Grid>
                        */}
                    </Grid>
                    <ButtonGroup fullWidth variant="text" size="large" color="primary">
                        <Button
                            className={classes.buttonAction}
                            color="secondary"
                            fullWidth
                            startIcon={<GroupAddIcon />}
                            component={RouterLink}
                            to={'/merchants/creation'}>
                            Créer un commerçant
                        </Button>
                        {!isForcalquier && (
                            <Button
                                className={classes.buttonAction}
                                style={{ color: 'green' }}
                                color="primary"
                                fullWidth
                                //variant="contained"
                                endIcon={<EuroIcon />}
                                component={RouterLink}
                                to={`/management/${id}/facturation/${filter}`}>
                                Facturer un commerçant
                            </Button>
                        )}
                    </ButtonGroup>
                </Card>
                <TabControl value={activeStep} index={0}>
                    <Registration filter={filter} />
                </TabControl>
                <TabControl value={activeStep} index={1}>
                    <Placement id={id} filter={filter} />
                </TabControl>
                <TabControl value={activeStep} index={2}>
                    <Finance id={id} filter={filter} />
                </TabControl>
                <TabControl value={activeStep} index={3}>
                    <Synthesis id={id} history={this.props.history} filter={filter} />
                </TabControl>
            </div>
        );
    }
}

export default withStyles(styles)(Management);
