import React, { useState, useEffect } from 'react';

import { Dialog } from 'components';
import { Button, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import env from 'common/env';

async function getMarkets(id) {
    const result = await fetch(`${env}/merchant/get-all-neutral-markets/${id}`, {
        method: 'GET'
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw "Une erreur est survenue lors de l'affichage des marchés.";
        });

    return result;
}

async function setNewPoint(id, points, marketId, com) {
    const formData = new FormData();
    formData.append('points', points);
    formData.append('marketId', marketId);
    formData.append('com', com);

    const result = await fetch(`${env}/merchant/set-points-after-review/${id}`, {
        method: 'POST',
        body: formData
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw 'Une erreur est survenue lors de la mise à jour.';
        });

    return result;
}

const EditPointForm = ({ open, onClose, pts, id }) => {
    const [points, setPoints] = useState('');
    const [com, setCom] = useState('');
    const [market, setMarket] = useState('');
    const [inputMarket, setInputMarket] = useState('');
    const [markets, setMarkets] = useState([{ label: '' }]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setPoints(pts);
        if (open) {
            getMarkets(id)
                .then(data => setMarkets(data))
                .catch(err => console.error(err));
        } else {
            setInputMarket('');
            setCom('');
            setMarket('');
        }
    }, [open]);

    const handlePoint = ({ target: { value } }) => {
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            setPoints(value === '' ? '' : parseInt(value));
        }
    };

    const submit = () => {
        if (points === '' || points === 'NaN') {
            alert('Vous devez saisir un nombre de points.');
            return;
        }

        if (!market) {
            alert('Vous devez sélectionner une journée de marché.');
            return;
        }

        if (com === '' || com === null) {
            alert('Vous devez saisir un commentaire.');
            return;
        }

        setLoading(true);
        setNewPoint(id, points, market.id, com)
            .then(res => {
                alert('Changement effectué avec succès.');
                setInputMarket('');
                setCom('');
                setMarket('');
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false));
    };

    return (
        <Dialog
            loading={loading}
            open={open}
            onClose={onClose}
            title="Edition du nombre de points"
            action={
                <Button disabled={loading} onClick={submit} variant="contained" color="primary">
                    Valider
                </Button>
            }>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <TextField
                        value={points}
                        type="number"
                        onChange={handlePoint}
                        variant="outlined"
                        fullWidth
                        label="Nombre de points"
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        value={market}
                        onChange={(event, newValue) => {
                            setMarket(newValue);
                        }}
                        inputValue={inputMarket}
                        onInputChange={(event, newInputValue) => {
                            setInputMarket(newInputValue);
                        }}
                        getOptionLabel={option => option.label ?? ''}
                        getOptionSelected={(option, value) => option.id == value.id}
                        renderOption={(option, value) => option.label}
                        options={markets}
                        fullWidth
                        renderInput={params => (
                            <TextField {...params} label="Marché" variant="outlined" />
                        )}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        onChange={e => setCom(e.target.value)}
                        label="Commentaire"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={5}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default EditPointForm;
