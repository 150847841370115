var JournalMarche = (function () {


  var getId = function () {
    return localStorage.getItem('jm_id')
  };


  var setId = function (id) {
    localStorage.setItem('jm_id', id);
  };


  var getLabel = function () {
    return localStorage.getItem('label')
  };


  var setLabel = function (label) {
    localStorage.setItem('label', label);
  };


  var getDate = function () {
    return localStorage.getItem('date')
  };


  var setDate = function (date) {
    localStorage.setItem('date', date);
  };


  var setSession = function () {
    sessionStorage.setItem('jm_id', getId());
    sessionStorage.setItem('label', getLabel());
    sessionStorage.setItem('date', getDate());
  };

  var clearSession = function () {
    sessionStorage.setItem('jm_id', 0);
    sessionStorage.setItem('label', null);
    sessionStorage.setItem('date', null);
  };


  return {
    getId: getId,
    setId: setId,

    getLabel: getLabel,
    setLabel: setLabel,

    getDate: getDate,
    setDate: setDate,

    setSession: setSession,
    clearSession: clearSession
  }

})();

export default JournalMarche;
