import env from 'common/env';

/**
 *
 * @param {*} start
 * @param {*} end
 * @param {*} orderBy
 * @param {*} order
 * @param {*} page
 * @returns
 */
export async function getSendedBill(start, end, orderBy, order, page) {
    const params = `start=${start}&end=${end}&orderBy=${orderBy}&order=${order}&page=${page}`;

    const result = await fetch(`${env}/regie/get-sended-bills?${params}`, {
        method: 'GET'
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            console.error(err);
            throw `Echec de l'affichage des factures.`;
        });

    return result;
}
