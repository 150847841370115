import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
    Grid,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';

import {
    DialogTitle,
    MerchantInput,
    MarketInput, 
    CommentInput
} from './components'


const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
});


class Report extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            ...props,
            merchants: [],
            market: [],
            comment: ""
        }
    }

    handleMerchants = (value) => {
        this.setState({
            merchants: value
        })
    }

    handleMarket = (value) => {
        this.setState({
            merchants: value
        })
    }

    handleComment = (value) => {
        this.setState({
            comment: value
        })
    }

    handleClose = () => {
        this.props.history.goBack();
    }

    prepareData = () => {
        const {
            merchants, 
            market, 
            comment
        } = this.state

        if(merchants.length == 0){
            alert("Veuillez sélectionner au mois un commerçant.");
            return;
        }
        
    }

    render() {

        const {
            classes,
        } = this.state;

        return (
            <Dialog
                fullWidth
                maxWidth={"sm"}
                onClose={this.handleClose} open={true}>
                <DialogTitle onClose={this.handleClose}>
                    Déclarer une observation
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <MarketInput journalMarket={this.props.journalMarket} setMarket={this.handleMarket} />
                        </Grid>
                        <Grid item>
                            <MerchantInput setMerchant={this.handleMerchants} />
                        </Grid>
                        <Grid item>
                            <CommentInput setComment={this.handleComment} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Annuler la déclaration
                    </Button>
                    <Button onClick={this.prepareData} variant="contained" color="primary">
                        Déclarer l'observation
                    </Button>
                </DialogActions>
            </Dialog >
        );

    }

}

export default withStyles(styles)(Report);
