import env from './env';
import { empty } from './validators';

/**
 *
 * @param {*} start
 * @param {*} end
 * @param {*} searchValue
 * @param {*} order
 * @param {*} orderBy
 * @param {*} filter
 * @param {*} type
 */
export function printBills(start, end, searchValue, order, orderBy, filter, type) {
    if (window.confirm('Imprimer la sélection ?')) {
        let args = '';
        args += `start=${start}&`;
        args += `end=${end}&`;
        args += `order=${order}&`;
        args += `orderBy=${orderBy}&`;
        args += `search=${searchValue}&`;
        window.open(`${env}/regie/get-bills/${type}/${filter}/print?${args}`, '_blank');
    }
}

/**
 *
 * @param {*} billNumber
 */
export function printBill(billNumber) {
    window.open(`${env}/regie/print/A/${billNumber}`, '_blank');
}

/**
 *
 * @param {*} merchantId
 */
export function printGroupedBills(merchantId) {
    fetch(`${env}/regie/print-grouped-bills/${merchantId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => window.open(data, '_blank'))
        .catch(err => {
            console.error(err);
            throw `Echec de l'affichage de la facture.`;
        });
}

/**
 *
 * @param {*} merchantId
 * @param {*} start
 * @param {*} end
 */
export function printGroupedBillsByDate(merchantId, start, end) {
    fetch(`${env}/regie/print-grouped-bills/${merchantId}?start=${start}&end=${end}`, {
        method: 'GET'
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => window.open(data, '_blank'))
        .catch(err => {
            console.error(err);
            throw `Echec de l'affichage de la facture.`;
        });
}

/**
 *
 * @param {*} billNumber
 */
export function printGroupedBillsByBillNumber(billNumber) {
    window.open(`${env}/regie/print-grouped-bills-by-bill-number/${billNumber}`, '_blank');
}

/**
 *
 * @param {*} start
 * @param {*} end
 * @param {*} merchantId
 * @returns
 */
export function printAttendanceSUmmary(start, end, merchantId) {
    if (empty(start)) {
        alert('Veuillez saisir une date de début');
        return;
    } else if (empty(end)) {
        alert('Veuillez saisir une date de fin');
        return;
    }

    window.open(
        `${env}/placement/print-attendance-summary/${merchantId}?start=${start}&end=${end}`,
        '_blank'
    );
}
