import React from 'react';
import { withStyles } from '@material-ui/styles';
import env from 'common/env';
import { Grid, Typography, Divider, ListItemText, TableRow, TableCell } from '@material-ui/core';
import { SearchTextField, MuiTable } from 'components';

import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

const headers = [
    { id: 'placeNumber', align: 'left', label: 'Numéro', sortable: false },
    {
        id: 'socialReason',
        align: 'left',
        label: 'Raison sociale',
        sortable: false
    },
    { id: 'options', align: 'center', label: 'Options', sortable: false }
];

const styles = theme => ({
    searchInput: {
        //paddingRight: theme.spacing(2),
        //paddingLeft: theme.spacing(2)
    },
    container: {
        //maxHeight: "calc(100vh - 456px)"
    },
    containerList: {
        //position: "absolute",
        //width: "100%"
    },
    content: {
        //position: "absolute",
        //height: "calc(100vh - 427px)"
    },
    filter: {
        //paddingLeft: theme.spacing(2)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

class ListHolders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            searchValue: '',
            list: [],
            numberOfPages: 0,
            selectedActivities: [],
            page: 1,
            isFree: false,
            isNotFree: false,
            filterHasChanged: false,
            init: true,
            selectedSectors: [],
            sectors: [],
            loading: true,
            order: 'asc',
            orderBy: headers[0].id,
            count: 0,
            filter: 'all'
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            this.state.page != prevState.page ||
            this.state.order != prevState.order ||
            this.state.orderBy != prevState.orderBy ||
            this.state.filter != prevState.filter ||
            this.state.searchValue != prevState.searchValue
        ) {
            this.setState({
                loading: true
            });
        }

        if (this.state.loading && this.state.loading != prevState.loading) {
            this.getData();
        }
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        const { places } = this.props;

        const {
            searchValue,
            page,
            isFree,
            isNotFree,
            selectedActivities,
            selectedSectors,
            init
        } = this.state;

        const formData = new FormData();
        formData.append('init', init);
        formData.append('search', searchValue);
        formData.append('places', JSON.stringify(places));
        formData.append('page', page);
        formData.append('isNotFree', isNotFree);
        formData.append('isFree', isFree);
        formData.append('from', 'table');
        selectedActivities.length > 0 &&
            formData.append('activities', JSON.stringify(selectedActivities));
        selectedSectors.length > 0 && formData.append('sectors', JSON.stringify(selectedSectors));

        fetch(`${env}/market/get-holders-by-for-creation`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    init: false,
                    loading: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value
        });
    };

    handleActivitieFilter = id => {
        const { selectedActivities } = this.state;

        const index = selectedActivities.indexOf(id);

        if (index > -1) {
            selectedActivities.splice(index, 1);
        } else {
            selectedActivities.push(id);
        }

        this.setState({
            selectedActivities: selectedActivities,
            filterHasChanged: true
        });
    };

    handleSectorFilter = id => {
        const { selectedSectors } = this.state;

        const index = selectedSectors.indexOf(id);

        if (index > -1) {
            selectedSectors.splice(index, 1);
        } else {
            selectedSectors.push(id);
        }

        this.setState({
            selectedSectors: selectedSectors,
            filterHasChanged: true
        });
    };

    handleStateOfPlace = ({ target: { name, checked } }) => {
        this.setState({
            [name]: checked
        });
    };

    render() {
        const {
            classes,
            searchValue,
            list,
            selectedActivities,
            isFree,
            isNotFree,
            numberOfPages,
            page,
            selectedSectors,
            sectors,
            loading,
            count,
            order,
            orderBy,
            filter
        } = this.state;

        const { activities } = this.props;

        return (
            <Grid container direction="column" spacing={2}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                    spacing={3}>
                    <Grid item>
                        <SearchTextField
                            label={`Rechercher un commerçant`}
                            value={searchValue}
                            onChange={value => {
                                this.setState({
                                    searchValue: value,
                                    page: 0
                                });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <MuiTable
                            // disabled
                            refresh={() => this.setState({ loading: true })}
                            loading={loading}
                            headers={headers}
                            page={{
                                set: val => this.setState({ page: val }),
                                data: page,
                                count: count
                            }}
                            order={{
                                set: val => this.setState({ order: val }),
                                data: order
                            }}
                            orderBy={{
                                set: val => this.setState({ orderBy: val }),
                                data: orderBy
                            }}>
                            {list.map((place, index) => (
                                <TableRow hover key={index}>
                                    <TableCell
                                        align="left"
                                        key={`${place.numero}-${place.options.sector}-numero`}>
                                        <ListItemText
                                            primary={`${
                                                place.options.sector !== ''
                                                    ? `${place.options.sector}`
                                                    : 'N°'
                                            }${place.numero}`}
                                            secondary={
                                                <Typography variant="body2">
                                                    {place.meters} m
                                                </Typography>
                                            }
                                        />
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        key={`${place.numero}-${place.options.sector}-${place.merchant.raisonSociale}`}>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    noWrap
                                                    style={{
                                                        color:
                                                            place.merchant.id == '1'
                                                                ? 'green'
                                                                : 'inherit'
                                                    }}>
                                                    {place.merchant.id == '1'
                                                        ? 'Disponible'
                                                        : place.merchant.raisonSociale}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography variant="body2" noWrap>
                                                    {place.merchant.id != '1'
                                                        ? place.merchant.metierLabel
                                                        : place.activitie.label}
                                                </Typography>
                                            }
                                        />
                                    </TableCell>
                                    <TableCell
                                        key={`${place.numero}-${place.options.sector}-options`}>
                                        <Grid
                                            container
                                            spacing={2}
                                            direction="row"
                                            justifyContent="center">
                                            {place.options.water && (
                                                <Grid item>
                                                    <Grid container alignItems="center" spacing={1}>
                                                        <Grid item>Eau</Grid>
                                                        <Grid item>
                                                            <InvertColorsIcon
                                                                style={{
                                                                    color: 'dodgerblue'
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {place.options.electricity && (
                                                <Grid item>
                                                    <Grid container alignItems="center" spacing={1}>
                                                        <Grid item>Electricité</Grid>
                                                        <Grid item>
                                                            <FlashOnOutlinedIcon
                                                                style={{
                                                                    color: 'gold'
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {place.options.car && (
                                                <Grid item>
                                                    <Grid container alignItems="center" spacing={1}>
                                                        <Grid item>Véhicule</Grid>
                                                        <Grid item>
                                                            <LocalShippingIcon />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {!place.options.car &&
                                                !place.options.water &&
                                                !place.options.electricity && (
                                                    <Grid item>
                                                        <Typography>Sans option</Typography>
                                                    </Grid>
                                                )}
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </MuiTable>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ListHolders);
