import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Paper,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    FormGroup,
    FormControl,
    FormLabel,
    FormControlLabel,
    Checkbox,
    Divider,
    List
} from '@material-ui/core';

import { MarketButton, Pagination } from './components';

import ClearIcon from '@material-ui/icons/Clear';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

class All extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            searchValue: '',
            lun: false,
            mar: false,
            mer: false,
            jeu: false,
            ven: false,
            sam: false,
            dim: false,
            all: true,
            list: [],
            page: 1,
            number_of_pages: 0
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        (this.state.all != prevState.all ||
            this.state.lun != prevState.lun ||
            this.state.mar != prevState.mar ||
            this.state.mer != prevState.mer ||
            this.state.jeu != prevState.jeu ||
            this.state.ven != prevState.ven ||
            this.state.sam != prevState.sam ||
            this.state.dim != prevState.dim ||
            this.state.searchValue != prevState.searchValue ||
            this.state.page != prevState.page) &&
            this.getData();
    };

    getData = () => {
        const { all, lun, mar, mer, jeu, ven, sam, dim, page, searchValue } = this.state;

        const formData = new FormData();
        if (!lun && !mar && !mer && !jeu && !ven && !sam && !dim) {
            this.setState({
                all: true
            });
            formData.append('lun', true);
            formData.append('mar', true);
            formData.append('mer', true);
            formData.append('jeu', true);
            formData.append('ven', true);
            formData.append('sam', true);
            formData.append('dim', true);
        } else {
            formData.append('lun', all ? true : lun);
            formData.append('mar', all ? true : mar);
            formData.append('mer', all ? true : mer);
            formData.append('jeu', all ? true : jeu);
            formData.append('ven', all ? true : ven);
            formData.append('sam', all ? true : sam);
            formData.append('dim', all ? true : dim);
        }

        formData.append('page', page);
        formData.append('search', searchValue);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/get-by`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handleTypeFilter = ({ target: { value } }) => {
        this.setState({
            type: value,
            page: 1
        });
    };

    handleDayFilter = ({ target: { name } }) => {
        const { all, lun, mar, mer, jeu, ven, sam, dim } = this.state;

        if (name === 'all') {
            !all &&
                this.setState({
                    lun: false,
                    mar: false,
                    mer: false,
                    jeu: false,
                    ven: false,
                    sam: false,
                    dim: false
                });
        } else {
            this.setState({
                all: false
            });
        }

        this.setState(prevState => ({
            [name]: !prevState[name],
            page: 1
        }));
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    render() {
        const {
            classes,
            searchValue,
            all,
            lun,
            mar,
            mer,
            jeu,
            ven,
            sam,
            dim,
            list,
            page,
            number_of_pages
        } = this.state;

        return (
            <Paper className={classes.paperContent}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                    spacing={3}>
                    <Grid item>
                        <TextField
                            label={'Rechercher un marché'}
                            type="text"
                            fullWidth
                            value={searchValue}
                            onChange={this.handleSearch}
                            variant="outlined"
                            helperText="Saisir le libellé du marché ou son adresse"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={this.clearSearch} edge="end">
                                            <ClearIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel className={classes.labelFilter} component="label">
                                Journée de marché
                            </FormLabel>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={all}
                                            onChange={this.handleDayFilter}
                                            name="all"
                                        />
                                    }
                                    label="Toutes"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={lun}
                                            onChange={this.handleDayFilter}
                                            name="lun"
                                        />
                                    }
                                    label="Lundi"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={mar}
                                            onChange={this.handleDayFilter}
                                            name="mar"
                                        />
                                    }
                                    label="Mardi"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={mer}
                                            onChange={this.handleDayFilter}
                                            name="mer"
                                        />
                                    }
                                    label="Mercredi"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={jeu}
                                            onChange={this.handleDayFilter}
                                            name="jeu"
                                        />
                                    }
                                    label="Jeudi"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={ven}
                                            onChange={this.handleDayFilter}
                                            name="ven"
                                        />
                                    }
                                    label="Vendredi"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sam}
                                            onChange={this.handleDayFilter}
                                            name="sam"
                                        />
                                    }
                                    label="Samedi"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={dim}
                                            onChange={this.handleDayFilter}
                                            name="dim"
                                        />
                                    }
                                    label="Dimanche"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <List>
                            {list &&
                                list.map(market => {
                                    return <MarketButton data={market} />;
                                })}
                        </List>
                    </Grid>
                    <Grid item>
                        <Pagination
                            numberOfPages={number_of_pages}
                            currentPage={page}
                            handlePagination={this.handlePagination}
                        />
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(All);
