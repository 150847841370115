import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Divider,
    Typography,
    Grid,
    TableRow,
    TableCell,
    TableBody,
    Table,
    TableContainer
} from '@material-ui/core';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { DialogTitle } from './components';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    content: {
        paddingRight: '20px !important',
        paddingLeft: '20px !important'
    }
});

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.01)'
        }
    },
    hover: {
        '&$hover:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.08)'
        }
    },
    selected: {
        '&$selected, &$selected:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.15)'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            subject: '',
            contact: [],
            number: '',
            content: '',
            sentAt: '',
            option: '',
            carbon: false
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        const { customId } = this.props;

        const formData = new FormData();
        formData.append('customId', customId);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/email/get-one-message-from-mailbox`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    render() {
        const { classes, sentAt, subject, number, contact, content, carbon } = this.state;

        return (
            <Dialog fullWidth maxWidth={'sm'} onClose={this.handleClose} open={true}>
                <DialogTitle onClose={this.handleClose}>Détail de l'envoi</DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="column" spacing={5}>
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                spacing={2}
                                justify="space-between"
                                alignItems="flex-end">
                                <Grid item>
                                    <Typography variant="h4">{subject}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1">Envoyé le {sentAt}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        Destinaire(s) - {number} au total {carbon && `(${carbon})`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <TableContainer>
                                        <Table stickyHeader size="small">
                                            <TableBody>
                                                {contact.map((row, index) => {
                                                    return (
                                                        <StyledTableRow hover key={index}>
                                                            <StyledTableCell
                                                                key={`cell_${index}`}
                                                                align="left">
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    alignItems="flex-end"
                                                                    justify="space-between">
                                                                    <Grid item>
                                                                        <Typography
                                                                            variant="body2"
                                                                            key={`contact_${index}`}>
                                                                            {row.contact}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography
                                                                            variant="body2"
                                                                            key={`last_update_${index}`}>
                                                                            <FiberManualRecordIcon
                                                                                style={{
                                                                                    width: 10,
                                                                                    height: 10,
                                                                                    color:
                                                                                        row.status
                                                                                            .color
                                                                                }}
                                                                            />{' '}
                                                                            {row.status.label}{' '}
                                                                            {row.update_at}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography variant="subtitle1">Message</Typography>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item className={classes.content} style={{ marginTop: 10 }}>
                                    <Typography variant="body2">
                                        <div dangerouslySetInnerHTML={{ __html: content }} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        component={RouterLink}
                        to={'/mailbox'}
                        color="primary"
                        variant="contained">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Detail);
