import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import { Link as RouterLink } from 'react-router-dom';

import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableCell,
    Card,
    CardHeader,
    Avatar,
    CardContent,
    TableRow,
    TableBody,
    IconButton,
    Button,
    ListItemText
} from '@material-ui/core';

const columns = [
    {
        id: "raisonSociale",
        label: "Raison sociale",
        minWidth: "70%",
        align: 'left',
    },
    {
        id: 'date',
        label: 'Date',
        minWidth: "10%",
        align: 'center',
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: "20%",
        align: 'center',
    },
];

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    avatar: {
        backgroundColor: "red",
    },
    title: {
        color: theme.palette.primary.main,
    },
    isBlockedRow: {
        background: "rgba(255, 0, 0, 0.1)"
    },
    isBlockedCell: {
        color: "red"
    },
    container: {
        maxHeight: "45vh"
    }
}))

const StopedActivity = props => {
    const { className, data, ...rest } = props;


    const classes = useStyles();

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        <NotInterestedIcon />
                    </Avatar>
                }
                title={
                    <Typography style={{ color: "red" }} variant="subtitle2">
                        Les cessations d'activité
                    </Typography>
                }
                subheader={
                    `${data.count.today} nouvelles cessations aujourd'hui (${data.count.total} au total)`
                }
            />
            <CardContent>
                <TableContainer className={classes.container}>
                    <Table size="small" stickyHeader>
                        <TableBody>
                            {
                                data.list.map((blocage, index) => {
                                    return (
                                        <TableRow hover key={index}
                                            className={clsx({ [classes.isBlockedRow]: Boolean(blocage.today == "1") })}
                                        >
                                            {
                                                columns.map(column => {

                                                    if (column.id == "action") {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${blocage[column.id]}`} align={column.align}>
                                                                <IconButton component={RouterLink} to={`/${blocage.domaine == "1" ? "domain-merchants" : "merchants"}/get/${blocage.siret}/profil`}>
                                                                    <ArrowForwardIcon className={clsx({ [classes.isBlockedCell]: Boolean(blocage.today == "1") })} />
                                                                </IconButton>
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (column.id == "raisonSociale") {
                                                        return (
                                                            <TableCell
                                                                className={clsx({ [classes.isBlockedCell]: Boolean(blocage.today == "1") })}
                                                                key={`${column.id}_${blocage[column.id]}`} align={column.align}
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        <Typography className={clsx({ [classes.isBlockedCell]: Boolean(blocage.today == "1") })}>
                                                                            {blocage.raisonSociale}
                                                                        </Typography>}
                                                                    secondary={
                                                                        <Typography variant="body2" className={clsx({ [classes.isBlockedCell]: Boolean(blocage.today == "1") })}>
                                                                            {
                                                                                blocage.domaine == "1" ? "Module domaine" : "Module marché"
                                                                            }
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </TableCell>
                                                        );
                                                    }

                                                    return (
                                                        <TableCell
                                                            className={clsx({ [classes.isBlockedCell]: Boolean(blocage.today == "1") })}
                                                            key={`${column.id}_${blocage[column.id]}`} align={column.align}
                                                        >
                                                            {blocage[column.id]}
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    );
                                })
                            }
                            {
                                parseInt(data.count.total) > 10 &&
                                <TableRow hover={false} key={"seemore"}>
                                    <TableCell key={`see_more`} align={"center"} colSpan={3}>
                                        <Button color="primary" component={RouterLink} to={`/merchants/cessation`}>
                                            Voir plus de cessations d'activité...
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}

export default StopedActivity;


