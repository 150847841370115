import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import {
    Grid,
    Paper,
    Divider,
    IconButton,
    Typography,
    TextField,
    Button,
    MenuItem,
    CircularProgress
} from '@material-ui/core';

import { StateBillCard } from './components';

/**
 * env
 */
const env = `https://www.${
    window.location.hostname.split('.')[1]
        ? window.location.hostname.split('.')[0] === 'www'
            ? window.location.hostname.split('.')[1]
            : window.location.hostname.split('.')[0]
        : 'dev'
}.api.digitmarche.fr/api`;

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        margin: theme.spacing(3),
        flexGrow: 1
    },
    cssLabel: {
        color: theme.palette.warning.main
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: theme.palette.warning.main
        }
    },
    cssHover: {
        borderColor: theme.palette.warning.main
    },
    cssFocused: {},
    notchedOutline: {
        borderWidth: '2px',
        borderColor: theme.palette.warning.main
    }
});

const WAIT_INTERVAL = 1000;

class Bill extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {
            ...props,
            isGenerating: true,
            isPayingByCheck: false,
            isLoading: false,
            bill: null,
            mainFields: [],
            fields: [],
            billNumber: this.props.match.params.id,
            regexp: /^[0-9\b]+$/
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.isLoading && this.state.isLoading != prevState.isLoading) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.getData('calcul');
            }, WAIT_INTERVAL);
        }
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = (method = 'get') => {
        const { bill, billNumber } = this.state;

        let args = `?method=get`;

        if (method == 'calcul') {
            args += `&qte=${bill?.qte}&unitPrice=${bill?.unitPrice}`;
        }

        fetch(`${env}/regie/get-bill/${billNumber}${args}`, {
            method: 'GET'
        })
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                if (method == 'calcul') {
                    this.setState(prevState => ({
                        bill: {
                            ...prevState.bill,
                            unitPrice: data.bill.unitPrice,
                            totalAmount: data.bill.totalAmount
                        }
                    }));
                } else {
                    this.setState({
                        ...data
                    });
                }

                this.setState({
                    isGenerating: false,
                    isLoading: false
                });
            })
            .catch(error => {
                console.error(error);

                this.setState({
                    isGenerating: false,
                    isLoading: false
                });
            });
    };

    update = () => {
        const { bill, billNumber, billState, isPayingByCheck } = this.state;

        const formData = new FormData();
        formData.append('qte', bill.qte);
        formData.append('unitPrice', bill.unitPrice);
        bill.state.paid && formData.append('paymentMethod', bill.paymentMethod);
        formData.append('state', billState);

        if (isPayingByCheck) {
            formData.append('bank', bill.bank);
            formData.append('checkNumber', bill.checkNumber);
        }

        fetch(`${env}/regie/new-update/${billNumber}`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(() => alert('Facture mise à jour avec succès.'))
            .catch(error => {
                console.error(error);
                alert('Echec de la mise à jour.');
            });
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleForm = (target, name) => {
        const { fields, bill } = this.state;

        const values = {
            isLoading: !(name == 'paymentMethod' || name == 'bank' || name == 'checkNumber'),
            bill: {
                ...bill,
                [name]: target.value
            }
        };

        if (name == 'paymentMethod') {
            values.isPayingByCheck = false;

            fields[Object.keys(fields).reverse()[0]].items.map(item => {
                if (item.id == target.value && item.label == 'Chèque') {
                    values.isPayingByCheck = true;
                }
            });
        }

        this.setState(values);
    };

    render() {
        const {
            classes,
            billNumber,
            fields,
            bill,
            isGenerating,
            isLoading,
            isPayingByCheck,
            regexp
        } = this.state;

        return (
            <Paper className={classes.root}>
                <Grid container direction="column" spacing={2} justify="center">
                    <Grid item>
                        <Grid container direction="row" alignItems="baseline">
                            <Grid item>
                                <IconButton onClick={this.getBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography>Modification de la facture N°{billNumber}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3} justify={'flex-start'} alignItems={'stretch'}>
                            {isGenerating ? (
                                <Grid item>
                                    <CircularProgress color="primary" size={35} />
                                </Grid>
                            ) : (
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={3}
                                            justify={'center'}
                                            alignItems={'flex-start'}>
                                            <Grid item xs={9}>
                                                <Grid
                                                    container
                                                    spacing={3}
                                                    justify={'center'}
                                                    alignItems={'center'}>
                                                    {Object.keys(fields).map(key => {
                                                        if (fields[key].main) {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    xs={fields[key].xs ?? 12}>
                                                                    <TextField
                                                                        fullWidth
                                                                        label={fields[key].label}
                                                                        name={fields[key].value}
                                                                        type={
                                                                            fields[key].type ??
                                                                            'text'
                                                                        }
                                                                        fullWidth
                                                                        select={Boolean(
                                                                            fields[key].items
                                                                        )}
                                                                        helperText={
                                                                            fields[key].helperText
                                                                        }
                                                                        value={
                                                                            bill[fields[key].value]
                                                                        }
                                                                        variant={'outlined'}
                                                                        error={
                                                                            !(
                                                                                fields[key]
                                                                                    .readOnly ??
                                                                                true
                                                                            )
                                                                        }
                                                                        onChange={event =>
                                                                            this.handleForm(
                                                                                event.target,
                                                                                fields[key].value
                                                                            )
                                                                        }
                                                                        InputProps={{
                                                                            inputProps: {
                                                                                readOnly:
                                                                                    fields[key]
                                                                                        .readOnly ??
                                                                                    true,
                                                                                ...fields[key]
                                                                                    .inputProps
                                                                            }
                                                                        }}>
                                                                        {fields[key].items?.map(
                                                                            item => {
                                                                                return (
                                                                                    <MenuItem
                                                                                        key={
                                                                                            item.id
                                                                                        }
                                                                                        value={
                                                                                            item.id
                                                                                        }>
                                                                                        {item.label}
                                                                                    </MenuItem>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </TextField>
                                                                </Grid>
                                                            );
                                                        }
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <StateBillCard
                                                    update={this.getData}
                                                    billNumber={billNumber}
                                                    state={bill?.state}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    {Object.keys(fields).map(key => {
                                        if (!fields[key].main) {
                                            console.log(fields[key].readOnly);
                                            return (
                                                <Grid item xs={fields[key].xs ?? 12}>
                                                    <TextField
                                                        fullWidth
                                                        label={
                                                            fields[key].label +
                                                            (!Boolean(
                                                                fields[key].readOnly == undefined ||
                                                                    fields[key].readOnly == true
                                                            ) && ' *')
                                                        }
                                                        name={fields[key].value}
                                                        type={fields[key].type ?? 'text'}
                                                        fullWidth
                                                        select={Boolean(fields[key].items)}
                                                        helperText={fields[key].helperText}
                                                        value={bill[fields[key].value]}
                                                        variant={'outlined'}
                                                        //error={!(fields[key].readOnly ?? true)}
                                                        onChange={event =>
                                                            this.handleForm(
                                                                event.target,
                                                                fields[key].value
                                                            )
                                                        }
                                                        InputProps={{
                                                            classes: Boolean(
                                                                fields[key].readOnly == undefined ||
                                                                    fields[key].readOnly == true
                                                            )
                                                                ? {}
                                                                : {
                                                                      root:
                                                                          classes.cssOutlinedInput,
                                                                      focused: classes.cssFocused,
                                                                      notchedOutline:
                                                                          classes.notchedOutline
                                                                  },
                                                            inputProps: {
                                                                readOnly:
                                                                    fields[key].readOnly ?? true,
                                                                ...fields[key].inputProps
                                                            }
                                                        }}>
                                                        {fields[key].items?.map(item => {
                                                            return (
                                                                <MenuItem
                                                                    key={item.id}
                                                                    value={item.id}>
                                                                    {item.label}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </TextField>
                                                </Grid>
                                            );
                                        }
                                    })}
                                </React.Fragment>
                            )}

                            {isPayingByCheck && (
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={6}>
                                            <TextField
                                                className={classes.authorizedInput}
                                                fullWidth
                                                label={'Banque *'}
                                                name={'bank'}
                                                type={'text'}
                                                fullWidth
                                                value={bill.bank}
                                                variant={'outlined'}
                                                onChange={event =>
                                                    this.handleForm(event.target, event.target.name)
                                                }
                                                InputProps={{
                                                    classes: {
                                                        root: classes.cssOutlinedInput,
                                                        focused: classes.cssFocused,
                                                        notchedOutline: classes.notchedOutline
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label={'Numéro du chèque *'}
                                                name={'checkNumber'}
                                                type={'text'}
                                                fullWidth
                                                value={bill.checkNumber}
                                                variant={'outlined'}
                                                onChange={event => {
                                                    event.target.value = event.target.value
                                                        .replace(/\s/g, '')
                                                        .slice(0, 7);
                                                    regexp.test(event.target.value) &&
                                                        this.handleForm(
                                                            event.target,
                                                            event.target.name
                                                        );
                                                }}
                                                InputProps={{
                                                    classes: {
                                                        root: classes.cssOutlinedInput,
                                                        focused: classes.cssFocused,
                                                        notchedOutline: classes.notchedOutline
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item>
                                <Typography variant="body2" color="warning" align="left">
                                    (*) Tous les champs modifiables sont encadrés en orange.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <Button
                                    disabled={
                                        Boolean(bill?.state.state == 'del') ||
                                        isLoading ||
                                        isGenerating
                                    }
                                    color="primary"
                                    variant="contained"
                                    onClick={this.update}>
                                    Mettre à jour
                                </Button>
                            </Grid>
                            <Grid item>
                                {isLoading && <CircularProgress color="primary" size={25} />}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(Bill);
