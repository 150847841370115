import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    Button,
    TextField,
    Typography
} from '@material-ui/core';

import { FileInput } from './components';

const styles = theme => ({
    root: {
        flexGrow: 1
    }
});

const columns = [
    {
        id: 'label',
        label: 'Libellé',
        minWidth: '30%',
        align: 'left'
    },
    {
        id: 'field',
        label: 'Nouvelle donnée',
        minWidth: '50%',
        align: 'left'
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: '20%',
        align: 'center'
    }
];

class MissedData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            marchand_id: this.props.marchandId,
            siret: this.props.siret,
            donnes_manquante: {
                nbr: 0
            },
            alias: '',
            dateFinAssurance: '',
            unvalidatedDateFinAssurance: '',
            mailMarchand: '',
            telephoneMarchand: ''
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        const { marchand_id, siret } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-missed-data/${marchand_id}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.props.updateMissedData(data.donnes_manquante.nbr);
                this.setState({
                    ...data
                });
                data.donnes_manquante.nbr == 0 &&
                    this.props.history.push(`/merchants/get/${siret}/profil`);
                this.props.updateCompteur();
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleMissedData = ({ target: { value } }, key) => {
        this.setState({
            [key]: value
        });
    };

    updateMissedData = fieldName => {
        let data = this.state[fieldName];
        const { marchand_id, siret } = this.state;

        if (fieldName != 'assuranceMarchand') {
            if (!data || data.length == 0 || !data.replace(/\s/g, '').length) {
                alert('Donnée incorrect');
                return;
            }
        } else {
            if (!data) {
                alert('Donnée incorrect');
                return;
            }
        }

        const formData = new FormData();
        formData.append('field', fieldName);
        formData.append('data', data);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/update-fields-missed/${marchand_id}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(res => res.json())
            .then(data => {
                if (data) {
                    alert('Mise à jour effectuée');
                    this.getData();
                }
            })
            .catch(console.log);
    };

    render() {
        const { classes, donnes_manquante, unvalidatedDateFinAssurance } = this.state;

        return (
            <div>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map(column => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {donnes_manquante.nbr > 0 &&
                                Object.keys(donnes_manquante).map(key => {
                                    if (
                                        donnes_manquante[key] &&
                                        key != 'nbr' &&
                                        key != 'assurancePerimee'
                                    ) {
                                        return (
                                            <TableRow hover key={key}>
                                                {columns.map(column => {
                                                    if (column.id == 'label' && key == 'alias') {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${key}`}
                                                                align={column.align}>
                                                                Enseigne
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (
                                                        column.id == 'label' &&
                                                        key == 'dateFinAssurance'
                                                    ) {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${key}`}
                                                                align={column.align}>
                                                                Date de fin d'assurance
                                                                {unvalidatedDateFinAssurance && (
                                                                    <Typography
                                                                        variant="body2"
                                                                        style={{ color: 'red' }}>
                                                                        {
                                                                            unvalidatedDateFinAssurance
                                                                        }{' '}
                                                                        date de fin de validité
                                                                        dépassée
                                                                    </Typography>
                                                                )}
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (
                                                        column.id == 'label' &&
                                                        key == 'assuranceMarchand'
                                                    ) {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${key}`}
                                                                align={column.align}>
                                                                Document assurance
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (
                                                        column.id == 'label' &&
                                                        key == 'telephoneMarchand'
                                                    ) {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${key}`}
                                                                align={column.align}>
                                                                Téléphone
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (
                                                        column.id == 'label' &&
                                                        key == 'mailMarchand'
                                                    ) {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${key}`}
                                                                align={column.align}>
                                                                Mail
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (
                                                        column.id == 'field' &&
                                                        key == 'dateFinAssurance'
                                                    ) {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${key}`}
                                                                align={column.align}>
                                                                <TextField
                                                                    type="date"
                                                                    fullWidth
                                                                    id={key}
                                                                    name={key}
                                                                    onChange={event =>
                                                                        this.handleMissedData(
                                                                            event,
                                                                            key
                                                                        )
                                                                    }
                                                                    variant="outlined"
                                                                />
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (
                                                        column.id == 'field' &&
                                                        key == 'assuranceMarchand'
                                                    ) {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${key}`}
                                                                align={column.align}>
                                                                <FileInput
                                                                    id={key}
                                                                    name={key}
                                                                    onChange={event =>
                                                                        this.handleMissedData(
                                                                            event,
                                                                            key
                                                                        )
                                                                    }
                                                                    //sendData={this.sendData} data={merchant}
                                                                />
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (
                                                        column.id == 'field' &&
                                                        key != 'dateFinAssurance'
                                                    ) {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${key}`}
                                                                align={column.align}>
                                                                <TextField
                                                                    type="text"
                                                                    id={key}
                                                                    fullWidth
                                                                    name={key}
                                                                    placeholder="Saisir ici"
                                                                    onChange={event =>
                                                                        this.handleMissedData(
                                                                            event,
                                                                            key
                                                                        )
                                                                    }
                                                                    variant="outlined"
                                                                />
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (column.id == 'action') {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${key}`}
                                                                align={column.align}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        this.updateMissedData(key)
                                                                    }>
                                                                    Mettre à jour
                                                                </Button>
                                                            </TableCell>
                                                        );
                                                    }
                                                })}
                                            </TableRow>
                                        );
                                    }
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default withStyles(styles)(MissedData);
