import React, { useEffect, useState } from 'react';
import { Dialog, MuiTable } from 'components';
import {
    Button,
    Grid,
    InputAdornment,
    TextField,
    IconButton,
    Tabs,
    Tab,
    TableRow,
    TableCell,
    MenuItem,
    Typography,
    Divider,
    ListItemText,
    CircularProgress
} from '@material-ui/core';
// import { toUpper } from 'ramda';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import NumberFormat from 'react-number-format';
import { formatAsBankCheck } from 'common/validators';
import {
    addRecipeBill,
    deleteBillRecipe,
    getBillRecipes,
    getPaymentMethods
} from '../services/http';
import banks from 'common/banks';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const DEFAULT_STATE = {
    label: '',
    payment: '',
    bank: '',
    bankCheck: '',
    amount: 1
};

const headers = [
    { id: 'recipe.payment', align: 'left', label: 'Paiement', sortable: true },
    { id: 'recipe.amount', align: 'left', label: 'Montant', sortable: true },
    { id: 'recipe.created_at', align: 'left', label: 'Facturée le', sortable: true },
    { id: 'action', align: 'center', label: 'Action', sortable: false }
];

const filter = createFilterOptions();

function CustomTextField(props) {
    const { inputRef, onChange, allowedToChangePrice, noIcon, ...other } = props;

    return (
        <TextField
            {...other}
            onChange={onChange}
            variant="outlined"
            fullWidth
            //helperText={props.name != 'total' && 'Saisir un prix unitaire'}
            InputProps={{
                readOnly: !allowedToChangePrice,
                endAdornment: noIcon ? null : (
                    <InputAdornment position="end">
                        <EuroSymbolIcon />
                    </InputAdornment>
                )
            }}
        />
    );
}

const BillRecipeDialog = ({ open, id }) => {
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(0);
    const [recipes, setRecipes] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(headers[0].id);
    const [state, setState] = useState({ ...DEFAULT_STATE });
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [paying, setPaying] = useState(false);
    const [amountLeft, setAmountLeft] = useState(0);
    const [amountPaid, setAmountPaid] = useState(0);

    const isPayingByCheck = paymentMethods.find(
        item => item.id === state.payment && item.label === 'Chèque'
    );

    useEffect(() => {
        getPaymentMethods()
            .then(data => setPaymentMethods(data))
            .catch(err => alert(err));
    }, []);

    useEffect(() => {
        loading && getData();
    }, [loading]);

    useEffect(() => {
        open.value && setLoading(true);

        if (!open.value) {
            setAmountLeft(0);
            setAmountPaid(0);
        }
    }, [open.value]);

    const getData = () => {
        getBillRecipes(id, { order, orderBy, page })
            .then(res => {
                setRecipes(res.recipes);
                setCount(res.count);
                setAmountLeft(res.amountLeft);
                setAmountPaid(res.amountPaid);
            })
            .catch(err => alert(err))
            .finally(() => setLoading(false));
    };

    const handleTabs = (_, v) => {
        setTab(v);
        setState({ ...DEFAULT_STATE });
    };

    const handleState = (k, v) => {
        setState({ ...state, [k]: v });
    };

    const handleOnChangeAutocomplete = (fnc, key, newValue) => {
        if (typeof newValue === 'string') {
            handleState('bank', newValue);
            // fnc(null, { [key]: newValue });
        } else if (newValue && newValue.inputValue) {
            // fnc(null, { [key]: newValue.inputValue });
            handleState('bank', newValue.inputValue);
        } else {
            handleState('bank', newValue);
            // fnc(null, newValue);
        }
    };

    const handleFilterOptionAutocomplete = (options, params, key) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
            filtered.push({
                inputValue: params.inputValue,
                [key]: `Ajouter "${params.inputValue}"`
            });
        }

        return filtered;
    };

    const handleOptionLabelAutocomplement = (option, key) => {
        if (typeof option === 'string') {
            return option;
        }
        if (option.inputValue) {
            return option.inputValue;
        }

        return option[key];
    };

    const addRecipe = () => {
        setPaying(true);
        addRecipeBill(id, state)
            .then(() => {
                setTab(0);
                setLoading(true);
            })
            .catch(err => alert(err))
            .finally(() => setPaying(false));
    };

    const handleDeleteRecipe = id => {
        if (window.confirm('Confirmer la suppresion ? ')) {
            deleteBillRecipe(id)
                .then(res => setLoading(true))
                .catch(err => alert(err))
                .finally(() => setLoading(false));
        }
    };

    return (
        <Dialog
            loading={paying}
            open={open.value}
            title={'Les recettes'}
            onClose={() => !paying && open.func()}
            action={null}>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Tabs
                        fullWidth
                        disabled={paying}
                        value={tab}
                        onChange={handleTabs}
                        indicatorColor="primary"
                        textColor="primary"
                        centered>
                        <Tab fullWidth style={{ width: '100%' }} label="Liste des recettes" />
                        <Tab fullWidth style={{ width: '100%' }} label="Ajouter une recette" />
                    </Tabs>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        justify="center"
                        alignContent="flex-end"
                        alignItems="flex-end">
                        <Grid item>
                            <Typography align="right" variant="caption">
                                Total restant
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h2" style={{ color: 'red' }}>
                                {amountLeft} €
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">/</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h2" style={{ color: 'green' }}>
                                {amountPaid} €
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography align="left" variant="caption">
                                Total réglé
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                {tab === 0 ? (
                    <Grid item>
                        <MuiTable
                            refresh={() => setLoading(true)}
                            loading={loading}
                            headers={headers}
                            page={{
                                set: val => setPage(val),
                                data: page,
                                count: count
                            }}
                            order={{
                                set: val => setOrder(val),
                                data: order
                            }}
                            orderBy={{
                                set: val => setOrderBy(val),
                                data: orderBy
                            }}>
                            {recipes.map(row => (
                                <TableRow hover key={row.id}>
                                    <TableCell align="left">
                                        <ListItemText
                                            primary={row.payment}
                                            secondary={`Par ${row.user}`}
                                        />
                                    </TableCell>
                                    <TableCell align="right">{row.amount} €</TableCell>
                                    <TableCell align="left">{row.created_at}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleDeleteRecipe(row.id)}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </MuiTable>
                    </Grid>
                ) : (
                    <>
                        <Grid item>
                            <TextField
                                label="Libellé"
                                name="nature"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={state.label}
                                onChange={e => handleState('label', e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                name="paymentMethods"
                                type="text"
                                fullWidth
                                select
                                variant="outlined"
                                value={state.payment}
                                onChange={({ target: { value } }) => handleState('payment', value)}>
                                {paymentMethods.map(item => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                direction="row"
                                spacing={2}
                                justifyContent="center"
                                justify="center"
                                alignItems="stretch">
                                <Grid item xs={7}>
                                    <Autocomplete
                                        disabled={!isPayingByCheck}
                                        options={banks}
                                        value={state.bank}
                                        freeSolo
                                        autoHighlight
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        onChange={(event, newValue) =>
                                            handleOnChangeAutocomplete(
                                                handleState,
                                                'banque',
                                                newValue
                                            )
                                        }
                                        filterOptions={(options, params) =>
                                            handleFilterOptionAutocomplete(options, params, 'title')
                                        }
                                        getOptionLabel={option =>
                                            handleOptionLabelAutocomplement(option, 'title')
                                        }
                                        renderOption={option => option.title}
                                        renderInput={params => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                variant="outlined"
                                                name="banque"
                                                placeholder="Banque"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        placeholder="XXXXXXX"
                                        disabled={!isPayingByCheck}
                                        name="bankCheck"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={state.bankCheck}
                                        onChange={({ target: { value } }) =>
                                            handleState('bankCheck', formatAsBankCheck(value))
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        disabled={!isPayingByCheck}
                                                        onClick={() => handleState('bankCheck', '')}
                                                        edge="end"
                                                        size="small">
                                                        <ClearIcon fontSize="small" />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                label="Montant de cette recette"
                                value={state.amount}
                                customInput={CustomTextField}
                                allowedToChangePrice={true}
                                onValueChange={v => handleState('amount', v.value)}
                                thousandsGroupStyle="fr"
                                thousandSeparator={' '}
                                decimalSeparator={','}
                                type={'text'}
                                allowedDecimalSeparators={[',', '.', '?', ';']}
                                allowNegative={false}
                                decimalScale={2}
                                isNumericString
                            />
                        </Grid>
                        <Grid item style={{ textAlign: 'right' }}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                disabled={paying}
                                onClick={addRecipe}>
                                Ajouter une recette
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </Dialog>
    );
};

export default BillRecipeDialog;
