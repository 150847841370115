import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Doughnut } from 'react-chartjs-2';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 10
    },
    chartContainer: {
        position: 'relative',
        //height: "30vh",
        width: '250px'
    },
    chartDispo: {
        width: 70,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    chartDispoText: {
        color: 'green'
    }
}));

const AmountStatChart = props => {
    const { chart, filter, type } = props;
    const classes = useStyles();
    const theme = useTheme();
    const backgrounds = [];

    Object.keys(chart).map(
        key => key !== 'datasets' && backgrounds.push(theme.palette[key].normal)
    );

    const data = {
        labels: chart.datasets ? chart.datasets.label : [],
        datasets: [
            {
                borderColor: ['white', 'white', 'white', 'white'],
                backgroundColor: backgrounds,
                data: chart.datasets?.[filter]
            }
        ]
    };

    const options = {
        responsive: true,
        onHover: (e, element) => {
            if (element.length > 0) {
                e.target.style.cursor = 'pointer';
            } else {
                e.target.style.cursor = 'auto';
            }
        },
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        cutoutPercentage: 75,
        tooltips: {
            caretSize: 0,
            //displayColors: false,
            callbacks: {
                label: function(tooltipItem, data) {
                    return `${chart.datasets?.count[tooltipItem.index]} ${
                        chart.datasets?.label[tooltipItem.index]
                    }`;
                },
                footer: function(tooltipItem, data) {
                    return `Total ${chart.datasets?.sublabel[tooltipItem[0].index]} : ${
                        chart.datasets?.subamount[tooltipItem[0].index]
                    }`;
                }
            }
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0 // animation duration after a resize
    };

    return (
        <div className={classes.chartContainer}>
            {type !== 'canceled' &&
                type !== 'late' && (
                    <div className={classes.chartDispo}>
                        <Typography
                            align="center"
                            className={classes.chartDispoText}
                            variant="body2">
                            {chart.paid ? chart.paid.rate : '0'}% payées
                        </Typography>
                    </div>
                )}
            <Doughnut data={data} options={options} />
        </div>
    );
};

export default AmountStatChart;
