import env from 'common/env';

/**
 *
 * @returns
 */
export async function getUnpaidDashboard() {
    const result = await fetch(`${env}/regie/get-data-unpaid`, {
        method: 'GET'
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw `Echec de l'affichage des factures non réglées.`;
        });

    return result;
}

/**
 *
 * @returns
 */
export async function getGroupingDashboard() {
    const result = await fetch(`${env}/regie/get-data-grouping`, {
        method: 'GET'
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw `Echec de l'affichage des factures groupeés.`;
        });

    return result;
}

/**
 *
 * @returns
 */
export async function getSubscriberDashboard() {
    const result = await fetch(`${env}/regie/get-data-subscribers`, {
        method: 'GET'
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => data)
        .catch(error => {
            console.error(error);
            throw `Echec de l'affichage des abonnés.`;
        });

    return result;
}

/**
 *
 * @param {*} type
 * @returns
 */
export async function getLateBillsDashboard(type) {
    const result = await fetch(`${env}/regie/get-data-late-bills/${type}`, {
        method: 'GET'
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => data)
        .catch(error => {
            console.error(error);
            throw `Echec de l'affichage du total des factures en retard.`;
        });

    return result;
}
