import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    FormControl,
    FormLabel,
    Slider
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    slider: {
        maxWidth: 500,
        minWidth: 300,
        margin: 20,
        marginTop: 10
    },
}));

const marks = [
    {
        value: 0,
        label: 'Illimité',
    },
    {
        value: 1,
        label: '1H',
    },
    {
        value: 2,
        label: '2H',
    },
    {
        value: 3,
        label: '3H',
    },
    {
        value: 4,
        label: '4H',
    },
    {
        value: 5,
        label: '5H',
    },
    {
        value: 6,
        label: '6H',
    },
];

const ConnectionTime = props => {
    const { className, data, index, handleForm, ...rest } = props;

    const classes = useStyles();

    const handleSlider = (e, value) => {
        handleForm({ target: { name: 'maxConnectionTime', value: value, index: index } })
    }

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">
                Temps d'inactivité avant déconnexion
            </FormLabel>
            <Slider
                value={data}
                className={classes.slider}
                defaultValue={0}
                onChange={handleSlider}
                valueLabelFormat={(value) => {
                    return (value == 0 ? 'illimité' : (value + 'H'))
                }}
                step={1}
                min={0}
                max={6}
                valueLabelDisplay="auto"
                marks={marks}
            />
        </FormControl>

    )
}

export default ConnectionTime;