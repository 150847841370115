import React from 'react';
import { withStyles } from '@material-ui/styles';
import { TextField, Grid, MenuItem, Typography, FormControl, FormLabel } from '@material-ui/core';

const styles = theme => ({
    root: {
        flexGrow: 1
    }
});

class CashMethod extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            list: [],
            regexp: /^[0-9\b]+$/
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/finance/get-way-of-payment-form`,
            {
                // Your POST endpoint
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    list: data
                });
            })
            .catch(error => {
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    handleClose = () => {};

    handleList = ({ target: { value } }) => {
        if (value != '2') {
            this.props.setMoyen('banque', '');
            this.props.setMoyen('cheque', '');
        }

        this.props.setMoyen('moyen', value);
    };

    handleCheque = ({ target: { value } }) => {
        (value === '' || this.state.regexp.test(value)) &&
            this.props.setMoyen('cheque', value.replace(/\s/g, '').slice(0, 7));
    };

    handleBanque = ({ target: { value } }) => {
        this.props.setMoyen('banque', value);
    };

    render() {
        const { list } = this.state;

        const { moyen, banque, cheque } = this.props;

        return (
            <Grid container spacing={1} direction="column" alignItems="stretch">
                <Grid item>
                    <FormControl required component="fieldset">
                        <FormLabel color="primary">Sélectionnez un moyen de paiement</FormLabel>
                    </FormControl>
                </Grid>
                <Grid item>
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        value={moyen}
                        helperText={'Choisir un moyen de paiement'}
                        onChange={this.handleList}>
                        {list.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                <Typography variant="body1" color="primary">
                                    {option.label}
                                </Typography>
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {moyen == '2' && (
                    <React.Fragment>
                        <Grid item>
                            <TextField
                                //size="small"
                                name="banque"
                                variant="outlined"
                                fullWidth
                                helperText="Saisir une banque"
                                onChange={this.handleBanque}
                                placeholder="Ma banque"
                                value={banque}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                //size="small"
                                variant="outlined"
                                name="cheque"
                                helperText="Saisir un numéro de chèque"
                                fullWidth
                                value={cheque}
                                onChange={this.handleCheque}
                                placeholder="1234567"
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

export default withStyles(styles)(CashMethod);
