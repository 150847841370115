import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Avatar,
    Button,
    CardActions
} from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const useStyles = makeStyles(theme => ({
    root: {
        height: "100%"
    },
    card: {
        height: "100%"
    },
    title: {
        fontWeight: 700
    },
    icon: {
        height: 32,
        width: 32
    },
    avatar: {
        float: 'right',
        backgroundColor: theme.palette.primary.main,
        height: 56,
        width: 56
    },
    cardFooter: {
        padding: "0 15px 15px 15px",
        position: "absolute",
        bottom: 0,
        width: "100%",
    }
}));

const EmailTemplateCard = props => {
    const { className, method, data, ...rest } = props;

    const classes = useStyles();

    return (

        <CardActionArea className={classes.root} component={RouterLink} to={"/settings/email-template/add"}>
            <Card
                {...rest}
                elevation={(Boolean(method == "add") ? 4 : 1)}
                className={classes.card}
            >
                <CardContent>
                    <Grid container direction="row" wrap="nowrap" justify="space-between" spacing={2}>
                        <Grid item xs={10}>
                            <Grid container direction="column"
                                justify="flex-start"
                                alignItems="stretch"
                                spacing={2}>
                                <Grid item>
                                    <Typography className={classes.title} color='textSecondary' variant="body1">
                                        Modèle d'email
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography color='primary' variant="h3">
                                       {data}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Avatar className={classes.avatar}>
                                <MailOutlineIcon className={classes.icon} />
                            </Avatar>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button color="primary" fullWidth>
                        Ajouter un nouveau modèle
                    </Button>
                </CardActions>
            </Card>
        </CardActionArea >
    );
};

export default EmailTemplateCard;
