// @flow

import React from 'react'
//import L from 'leaflet';
import {
    Map,
    TileLayer,
} from 'react-leaflet'

import { withStyles } from '@material-ui/styles';

import {
    Control
} from './components'

const styles = theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            //height: "33vh",
        },
        [theme.breakpoints.up('sm')]: {
            height: "calc(100vh - 100px)",
        }
    },
});

class MarketMap extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props,
            mapConfiguration: {
                center: [0, 0],
                zoom: 19,
                maxZoom: 19,
                minZoom: 17,
                scrollWheelZoom: false,
                zoomControl: false,
                useFlyTo: false,
                doubleClickZoom: false,
            },
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            mapConfiguration,
        } = this.state

        if (!this.props.init && this.props.init != prevProps.init) {
            this.setState({
                mapConfiguration: {
                    ...mapConfiguration,
                    center: [this.props.marketData.adresse.coordinates.lat, this.props.marketData.adresse.coordinates.lng]
                }
            })
        }
    }

    render() {

        const {
            mapConfiguration,
            showPos,
            classes
        } = this.state

        return (
            <Map {...mapConfiguration} className={classes.root}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; Digit'Marché"
                />
                {
                    !this.props.init &&
                    <Control
                        meansOfPayment={this.props.meansOfPayment}
                        activities={this.props.activities}
                        marketData={this.props.marketData}
                        merchants={this.props.merchants}
                        places={this.props.places}
                    //updateData={this.props.updateData}
                    //showPos={showPos}
                    //updateShowPos={this.updateShowPos}
                    //rebuildList={this.props.rebuildList}
                    />
                }
            </Map>
        )

    }

}

export default withStyles(styles)(MarketMap);
