import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
    CardActionArea,
    Button
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

const styles = (theme) => ({
    img: {
        maxWidth: 250,
        minWidth: 100,
    }
});

const ClientFile = withStyles(styles)((props) => {
    const { children, classes, src, isPicture, ...other } = props;

    const goto = () => {
        window.open(src, "_blank");
    }

    return (
        <div>
            {
                isPicture ?
                    <CardActionArea onClick={goto}>
                        <img src={src} className={classes.img} />
                    </CardActionArea>
                    :
                    <Button size="small" onClick={goto} endIcon={<GetAppIcon />} variant="outlined" color="secondary">
                        Ouvrir le fichier
                    </Button>

            }
        </div>
    );
});


export default ClientFile;