// @flow

import React from 'react'
import { Marker as LeafletMarker, Popup } from 'react-leaflet'

export default class Marker extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...props
    }
  }


  render() {

    const { position, text } = this.state;

    return (
      <LeafletMarker position={position}>
        <Popup>
          {text}
        </Popup>
      </LeafletMarker>
    )

  }
}
