import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Typography,
  Dialog,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  DialogTitle
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import UserProfile from '../../../../../../UserProfile';

const useStyles = makeStyles(theme => ({
  root: {
    //backgroundColor: "#3C3B54",
    //flexDirection: 'column',
    //alignItems: 'center',
    //minHeight: 'fit-content',
    //margin: "-15px -15px 0px -15px",
    //padding: 15
  },
  signOut: {
    color: "red",
    background: "rgb(255,0,0,0.3)",
    "& span": {
      color: "darkred"
    },
    "& svg": {
      color: "darkred"
    }
  },
  info: {
    display: 'inline-flex',
    '& > *': {
      margin: theme.spacing(1),
    },
    //cursor: "pointer",
  },
  avatar: {
    width: 40,
    height: 40
  },
  name: {
    margin: "auto",
    display: "inline-flex",
    marginRight: 4,
    color: "#546e7a",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
  },
  profil: {
    //marginTop: theme.spacing(1),
    color: "#A5A4BF",
    //fontWeight: 400
  },
  settings: {
    //width:"100%"
    //marginTop: theme.spacing(1),
    //color: "#A5A4BF",
    //fontWeight: 400
  },
  more: {
    color: "#BCBCCB"
  },
  icon: {
    color: "#A5A4BF", /*theme.palette.icon*/
    width: 22,
    height: 22,
    //marginRight: theme.spacing(1)
  },
  buttonIcon: {
    display: "flex",
    position: "relative",
    left: 80,
    padding: 7
  },
}));

const Profile = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const user = {
    name: "Utilisateur",
    profile: 'Administrateur',
    alias: "U"
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.info}>
        <Typography className={classes.name}>
          {UserProfile.getName()}
        </Typography>

        <IconButton onClick={handleClickOpen} >
          <MoreVertIcon className={classes.more}/>
        </IconButton>
      </div>

      <Dialog onClose={handleClose} aria-labelledby="dialog-settings" open={open}>
        <DialogTitle id="dialog-settings">Connecté en tant que {UserProfile.getProfile()}</DialogTitle>
        <List>
          {/*
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Modifier mon compte" />
            </ListItem>
          */}
          <ListItem button component={RouterLink} to="/logout" className={classes.signOut}>
            <ListItemIcon>
              <PowerSettingsNewIcon />
            </ListItemIcon>
            <ListItemText primary="Se déconnecter" />
          </ListItem>
        </List>
      </Dialog>

    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
