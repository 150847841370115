import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Grid,
    Paper,
    TextField,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Button,
    ButtonGroup,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    InputAdornment,
    IconButton,
    Typography,
    ListItemText,
    Divider,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';

import JournalMarche from './../../../../JournalMarche';
import ClearIcon from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/Warning';
import DoneIcon from '@material-ui/icons/Done';

import { InfoCard, Pagination, Alert } from './components';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const styles = theme => ({
    root: {
        paddingTop: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    yesButton: {
        borderColor: 'green',
        backgroundColor: 'green',
        color: 'white'
    },
    noButton: {
        borderColor: 'red',
        backgroundColor: 'red',
        color: 'white'
    }
});

const columns = [
    {
        id: 'place',
        label: 'N°',
        minWidth: '15%',
        align: 'left'
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: '30%',
        align: 'left'
    },
    {
        id: 'metier',
        label: 'Métier',
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'etat',
        label: 'Statut',
        minWidth: '15%',
        align: 'left'
    },

    {
        id: 'present',
        label: 'Présence',
        minWidth: '20%',
        align: 'left'
    }
];

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

class Registration extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            allowBlocked: false,
            openConfirmation: false,
            anchorAlert: null,
            activities: [],
            assuranceExpiree: '0',
            assuranceManquante: '0',
            bapRetard: '0',
            bloque: '0',
            choice: true,
            searchValue: '',
            siret: '',
            data: {
                nbr_present: 0,
                nbr_all: 0,
                list: []
            },
            page: 1,
            number_of_pages: 0,
            selectedActivities: []
        };
    }

    getActivities = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/registration/get-activities`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(error => {
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    componentDidUpdate = (nextProps, nextState) => {
        if (this.props.filter != nextProps.filter) {
            this.update();
        }
    };

    update = (searchValue = '', page = 1) => {
        const { filter } = this.props;

        this.setState({
            searchValue: searchValue,
            page: page
            //filterHasChanged: false
        });

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/registration/${JournalMarche.getId()}/new-get/${filter}?search=${searchValue}&page=${page}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                this.setState({
                    data: {
                        nbr_present: data.nbr_present,
                        nbr_all: data.nbr_all,
                        list: data.list
                    },
                    allowBlocked: data.allowBlocked,
                    number_of_pages: data.count
                });
            })
            .catch(console.log);
    };

    editRegistration = (presence_id, status, isBlocked = false) => {
        const { allowBlocked, selectedActivities } = this.state;

        if (isBlocked && allowBlocked) {
            const _confirm = window.confirm(
                `Ce commerçant est bloqué pour la raison suivante : \n  - ${isBlocked}.\n\nEtes-vous sûr(e) de vouloir poursuivre cette action ?`
            );
            if (!_confirm) {
                return;
            }
        }

        const { searchValue, page } = this.state;
        const { filter } = this.props;
        const formData = new FormData();
        formData.append('presence_id', presence_id);
        formData.append('type', filter);
        formData.append('status', status);

        if (presence_id == 'all') {
            if (selectedActivities.length == 0) {
                alert('Veuillez sélectionner au moins une activité.');
                return;
            }
            formData.append('selectedActivities', JSON.stringify(selectedActivities));
        }

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/registration/${JournalMarche.getId()}/update`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                if (data) {
                    this.update(searchValue, page);
                    this.setState({
                        openConfirmation: false
                    });
                }
            })
            .catch(console.log);
    };

    componentDidMount = () => {
        this.update();
        this.getActivities();
    };

    handleConfirmation = (choice = false) => {
        this.setState(prevState => ({
            choice: choice,
            selectedActivities: [],
            openConfirmation: !prevState.openConfirmation
        }));
    };

    handleSearch = ({ target: { value } }) => {
        this.update(value);
    };

    clearSearch = () => {
        this.update();
    };

    handleAlert = (
        event = null,
        assuranceExpiree = '0',
        assuranceManquante = '0',
        bapRetard = '0',
        bloque = '0',
        siret
    ) => {
        this.setState({
            anchorAlert: event ? event.currentTarget : null,
            assuranceExpiree: assuranceExpiree,
            assuranceManquante: assuranceManquante,
            bapRetard: bapRetard,
            bloque: bloque,
            siret: siret
        });
    };

    handleSelectedActivities = value => {
        const { selectedActivities } = this.state;

        const index = selectedActivities.indexOf(value);

        if (index > -1) {
            selectedActivities.splice(index, 1);
        } else {
            selectedActivities.push(value);
        }

        this.setState({
            selectedActivities: selectedActivities
        });
    };

    alertMerchant = merchant => {
        let alert = 0;
        merchant.assuranceExpiree != '0' && ++alert;
        merchant.assuranceManquante == '1' && ++alert;
        merchant.bapRetard != '0' && ++alert;
        merchant.bloque != '0' && ++alert;
        const style = merchant.bloque != '0' ? { style: { color: 'red', fontWeight: 'bold' } } : {};

        return (
            <Grid spacing={1} container direction="row" justify="flex-start" alignItems="center">
                {alert > 0 && (
                    <Grid item>
                        <Button
                            onClick={event =>
                                this.handleAlert(
                                    event,
                                    merchant.assuranceExpiree,
                                    merchant.assuranceManquante,
                                    merchant.bapRetard,
                                    merchant.bloque,
                                    merchant.siret
                                )
                            }
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{
                                background: '#eb5a46',
                                minWidth: 'fit-content',
                                padding: '4px 6px'
                            }}>
                            <WarningIcon fontSize="small" />{' '}
                            <Typography style={{ color: 'white', marginLeft: 5, fontWeight: 500 }}>
                                {alert}
                            </Typography>
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <ListItemText
                        primary={<Typography {...style}>{merchant.raisonSociale}</Typography>}
                        secondary={
                            merchant._type == 'ta'
                                ? 'Titulaire abonné'
                                : merchant._type == 'tna'
                                ? 'Titulaire non abonné'
                                : 'Passager'
                        }
                    />
                </Grid>
            </Grid>
        );
    };

    handlePagination = selectedPage => {
        const { searchValue } = this.state;
        this.update(searchValue, selectedPage);
    };

    render() {
        const {
            classes,
            data,
            choice,
            openConfirmation,
            searchValue,
            anchorAlert,
            assuranceExpiree,
            assuranceManquante,
            bloque,
            siret,
            bapRetard,
            number_of_pages,
            page,
            allowBlocked,
            activities,
            selectedActivities
        } = this.state;

        const { filter } = this.props;

        return (
            <div className={classes.root}>
                <Alert
                    anchorAlert={anchorAlert}
                    data={{
                        assuranceExpiree: assuranceExpiree,
                        assuranceManquante: assuranceManquante,
                        bloque: bloque,
                        bapRetard: bapRetard
                    }}
                    siret={siret}
                    handleAlert={this.handleAlert}
                />

                <Dialog
                    open={openConfirmation}
                    onClose={this.handleConfirmation}
                    maxWidth={'sm'}
                    fullWidth>
                    <DialogTitle>
                        <Typography variant="button">
                            Sélectionner les titulaires qui sont{' '}
                            {choice == '1' ? 'présents' : 'absents'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText>
                            <Grid container direction="column">
                                {activities.map(row => {
                                    return (
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={Boolean(
                                                            selectedActivities.indexOf(row.id) > -1
                                                        )}
                                                        onChange={() =>
                                                            this.handleSelectedActivities(row.id)
                                                        }
                                                        name={row.id + row.label}
                                                        style={{ color: row.color }}
                                                    />
                                                }
                                                label={row.label}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirmation} color="primary">
                            Non
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => this.editRegistration('all', choice)}
                            color="primary">
                            Oui ils sont tous {choice == '1' ? 'présents' : 'absents'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <InfoCard
                            filter={filter}
                            data={{ present: data.nbr_present, all: data.nbr_all }}
                        />
                    </Grid>
                    <Grid item>
                        <Paper className={classes.paperContent}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                spacing={2}>
                                <Grid item>
                                    <Grid container justify="flex-end" alignItems="center">
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                component={RouterLink}
                                                endIcon={<ArrowForwardIcon />}
                                                to={
                                                    '/management/' +
                                                    JournalMarche.getId() +
                                                    '/registration/' +
                                                    (filter == 'p' ? 't' : 'p')
                                                }>
                                                Aller à l'inscription des{' '}
                                                {filter == 'p' ? 'titulaires' : 'passagers'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/*<Grid item>
                                    <Typography variant="body1">
                                        <IconButton type="button" component={RouterLink} to={"/dashboard/" + JournalMarche.getId()}>
                                            <ArrowBackIcon />
                                        </IconButton >
                                    Retour à mon marché
                                    </Typography>
                                </Grid>*/}
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={
                                            'Rechercher un ' +
                                            (filter == 't' ? 'titulaire' : 'passager')
                                        }
                                        type="text"
                                        fullWidth
                                        value={searchValue}
                                        onChange={this.handleSearch}
                                        variant="outlined"
                                        helperText="Saisir une raison sociale, une enseigne, un nom ou un prénom"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={this.clearSearch}
                                                        edge="end">
                                                        <ClearIcon style={{ color: 'red' }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                {filter == 't' && (
                                    <Grid item>
                                        <ButtonGroup variant="contained" color="primary" fullWidth>
                                            <Button
                                                onClick={() => this.handleConfirmation('1')}
                                                fullWidth
                                                style={{ color: 'white', background: 'green' }}>
                                                Tous les titulaires sont présents
                                            </Button>
                                            <Button
                                                onClick={() => this.handleConfirmation('0')}
                                                fullWidth
                                                style={{ color: 'white', background: 'red' }}>
                                                Tous les titulaires sont absents
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                )}
                                <Grid item>
                                    <TableContainer>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map(column => (
                                                        <StyledTableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}>
                                                            {column.label}
                                                        </StyledTableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.list.map((merchant, index) => {
                                                    return (
                                                        <StyledTableRow hover key={index}>
                                                            {columns.map(column => {
                                                                if (column.id == 'present') {
                                                                    return (
                                                                        <TableCell
                                                                            key={`${column.id}_${
                                                                                merchant[column.id]
                                                                            }`}
                                                                            align={column.align}>
                                                                            <Grid
                                                                                container
                                                                                direction="column"
                                                                                spacing={1}
                                                                                alignItems="center">
                                                                                <Grid item>
                                                                                    {Boolean(
                                                                                        merchant.bloque !=
                                                                                            '0' &&
                                                                                            !allowBlocked
                                                                                    ) ? (
                                                                                        <Grid
                                                                                            container
                                                                                            direction="row"
                                                                                            justify="center"
                                                                                            spacing={
                                                                                                2
                                                                                            }>
                                                                                            <Grid
                                                                                                item>
                                                                                                <Button
                                                                                                    disabled
                                                                                                    variant="outlined"
                                                                                                    color="primary">
                                                                                                    Oui
                                                                                                </Button>
                                                                                            </Grid>
                                                                                            <Grid
                                                                                                item>
                                                                                                <Button
                                                                                                    disabled
                                                                                                    variant="outlined"
                                                                                                    color="primary">
                                                                                                    Non
                                                                                                </Button>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    ) : (
                                                                                        <Grid
                                                                                            container
                                                                                            direction="row"
                                                                                            justify="center"
                                                                                            spacing={
                                                                                                2
                                                                                            }>
                                                                                            <Grid
                                                                                                item>
                                                                                                {Boolean(
                                                                                                    merchant._type !=
                                                                                                        'ta' &&
                                                                                                        merchant.encaisse ==
                                                                                                            '1'
                                                                                                ) ? (
                                                                                                    <Button
                                                                                                        disabled
                                                                                                        variant="outlined">
                                                                                                        Oui
                                                                                                    </Button>
                                                                                                ) : (
                                                                                                    <Button
                                                                                                        onClick={() =>
                                                                                                            this.editRegistration(
                                                                                                                merchant.id,
                                                                                                                '1',
                                                                                                                merchant.bloque ==
                                                                                                                    '0'
                                                                                                                    ? false
                                                                                                                    : merchant.bloque
                                                                                                            )
                                                                                                        }
                                                                                                        variant={
                                                                                                            merchant.presence ==
                                                                                                            '1'
                                                                                                                ? 'contained'
                                                                                                                : 'outlined'
                                                                                                        }
                                                                                                        style={{
                                                                                                            color:
                                                                                                                merchant.presence ==
                                                                                                                '1'
                                                                                                                    ? 'white'
                                                                                                                    : 'green',
                                                                                                            borderColor:
                                                                                                                'green',
                                                                                                            background:
                                                                                                                merchant.presence ==
                                                                                                                '1'
                                                                                                                    ? 'green'
                                                                                                                    : 'white'
                                                                                                        }}>
                                                                                                        Oui
                                                                                                    </Button>
                                                                                                )}
                                                                                            </Grid>
                                                                                            <Grid
                                                                                                item>
                                                                                                {Boolean(
                                                                                                    merchant._type !=
                                                                                                        'ta' &&
                                                                                                        merchant.encaisse ==
                                                                                                            '1'
                                                                                                ) ? (
                                                                                                    <Button
                                                                                                        disabled
                                                                                                        variant="outlined">
                                                                                                        Non
                                                                                                    </Button>
                                                                                                ) : (
                                                                                                    <Button
                                                                                                        onClick={() =>
                                                                                                            this.editRegistration(
                                                                                                                merchant.id,
                                                                                                                '0'
                                                                                                            )
                                                                                                        }
                                                                                                        variant={
                                                                                                            merchant.presence ==
                                                                                                            '0'
                                                                                                                ? 'contained'
                                                                                                                : 'outlined'
                                                                                                        }
                                                                                                        style={{
                                                                                                            color:
                                                                                                                merchant.presence ==
                                                                                                                '0'
                                                                                                                    ? 'white'
                                                                                                                    : 'red',
                                                                                                            borderColor:
                                                                                                                'red',
                                                                                                            background:
                                                                                                                merchant.presence ==
                                                                                                                '0'
                                                                                                                    ? 'red'
                                                                                                                    : 'white'
                                                                                                        }}>
                                                                                                        Non
                                                                                                    </Button>
                                                                                                )}
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    )}
                                                                                </Grid>
                                                                            </Grid>
                                                                        </TableCell>
                                                                    );
                                                                }

                                                                if (column.id == 'raisonSociale') {
                                                                    return (
                                                                        <TableCell
                                                                            key={`${column.id}_${
                                                                                merchant[column.id]
                                                                            }`}
                                                                            align="left">
                                                                            {this.alertMerchant(
                                                                                merchant
                                                                            )}
                                                                        </TableCell>
                                                                    );
                                                                }

                                                                if (column.id == 'place') {
                                                                    return (
                                                                        <TableCell
                                                                            key={`${column.id}_${
                                                                                merchant[column.id]
                                                                            }`}
                                                                            align="left">
                                                                            <ListItemText
                                                                                primary={
                                                                                    merchant.numeroPlace
                                                                                }
                                                                                secondary={
                                                                                    merchant.meters
                                                                                }
                                                                            />
                                                                        </TableCell>
                                                                    );
                                                                }

                                                                if (column.id == 'etat') {
                                                                    return (
                                                                        <TableCell
                                                                            key={`${column.id}_${
                                                                                merchant[column.id]
                                                                            }`}
                                                                            align="left">
                                                                            {merchant.encaisse ==
                                                                            '1' ? (
                                                                                <Grid
                                                                                    container
                                                                                    direction="row"
                                                                                    alignItems="center">
                                                                                    <Grid item>
                                                                                        Réglé
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <DoneIcon
                                                                                            fontSize="small"
                                                                                            style={{
                                                                                                color:
                                                                                                    'green'
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ) : merchant._order !=
                                                                                  '999' &&
                                                                              merchant.numeroPlace !=
                                                                                  '-' ? (
                                                                                <Grid
                                                                                    container
                                                                                    direction="row"
                                                                                    alignItems="center">
                                                                                    <Grid item>
                                                                                        Non réglé
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <ClearIcon
                                                                                            fontSize="small"
                                                                                            style={{
                                                                                                color:
                                                                                                    'red'
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            ) : null}
                                                                        </TableCell>
                                                                    );
                                                                }

                                                                return (
                                                                    <TableCell
                                                                        key={`${column.id}_${
                                                                            merchant[column.id]
                                                                        }`}
                                                                        align={column.align}>
                                                                        {merchant[column.id]}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </StyledTableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item>
                                    <Pagination
                                        numberOfPages={number_of_pages}
                                        currentPage={page}
                                        handlePagination={this.handlePagination}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Registration);
