import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Dialog,
    Tabs,
    Tab,
    DialogContent,
    Button,
    AppBar,
    CircularProgress,
    Grid,
    DialogActions
} from '@material-ui/core';

import EqualizerIcon from '@material-ui/icons/Equalizer';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';

import { DialogTitle, TabControl, Overview, ListDetail } from './components';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    tabs: {
        boxShadow: 'none',
        borderTop: '1px solid #eee'
    },
    contentOverview: {
        marginTop: theme.spacing(4)
    },
    contentList: {
        marginTop: theme.spacing(2)
    }
});

class DialogDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            step: 0,
            label: 'Chargement en cours...',
            overview: [],
            stats: [],
            open: true,
            init: true
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.step == 0 && this.state.step != prevState.step) {
            this.getData();
        }
    };

    getData = () => {
        const { journalMarketId, link } = this.props;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/get-detail-for-dashboard/${link}/${journalMarketId}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    init: false
                });
            })
            .catch(error => {
                this.setState({
                    init: false
                });
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    handleStep = (event, value) => {
        this.setState({
            step: value
        });
    };

    handleClose = () => {
        this.props.history.goBack();
    };

    render() {
        const { classes, step, init, label, overview, stats } = this.state;

        const { journalMarketId, link } = this.props;

        return (
            <Dialog fullWidth maxWidth={'sm'} onClose={this.handleClose} open>
                <DialogTitle onClose={this.handleClose} step={step}>
                    {label}
                </DialogTitle>
                <AppBar position="sticky" color="inherit" className={classes.tabs}>
                    <Tabs
                        value={step}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        variant="fullWidth"
                        selectionFollowsFocus
                        onChange={this.handleStep}>
                        <Tab
                            label="Vue d'ensemble"
                            icon={<EqualizerIcon />}
                            disabled={init}
                            component={Button}
                            fullWidth
                        />
                        <Tab
                            label="Liste détaillée"
                            icon={<SortByAlphaIcon />}
                            disabled={init}
                            component={Button}
                            fullWidth
                        />
                    </Tabs>
                </AppBar>
                <DialogContent dividers>
                    <div
                        className={clsx({
                            [classes.contentOverview]: Boolean(step == 0),
                            [classes.contentList]: Boolean(step == 1)
                        })}>
                        {!init ? (
                            <React.Fragment>
                                <TabControl value={step} index={0}>
                                    <Overview
                                        data={{
                                            overview: overview,
                                            stats: stats
                                        }}
                                    />
                                </TabControl>
                                <TabControl value={step} index={1}>
                                    <ListDetail journalMarketId={journalMarketId} link={link} />
                                </TabControl>
                            </React.Fragment>
                        ) : (
                            <Grid container direction="row" alignItems="center" justify="center">
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(DialogDetail);
