import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { combineStyles } from 'common/styles';
import { Dialog, SearchTextField, MuiTable } from 'components';
import {
    Button,
    Divider,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    TableCell,
    TableRow
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import SearchIcon from '@material-ui/icons/Search';
import { getUnpaidBillsByMerchant, sendBills } from './action';
import { empty } from 'common/validators';

const styles = theme => ({
    form: {
        display: 'contents'
    }
});

/**
 *
 */
const headers = [
    { id: 'socialeReason', align: 'left', label: 'Raison sociale', sortable: true },
    { id: 'action', align: 'center', label: 'Envoyer', sortable: false }
];

/**
 *
 * @param {*} classes
 * @returns
 */
const filters = classes => [
    { label: 'tous', value: 'all' },
    // {
    //     label: 'Titulaire abonné',
    //     value: 'ta',
    //     className: classes.ta
    // },
    {
        label: 'Titulaire non abonné',
        value: 'tna',
        className: classes.tna
    },
    {
        label: 'Passager',
        value: 'p',
        className: classes.p
    }
];

class SendBulkBillsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            submited: false,
            error: false,
            searchValue: '',
            disableSearch: true,
            withSummary: false,
            rows: [],
            record: 0,
            page: 0,
            start: '',
            end: '',
            order: 'asc',
            orderBy: headers[0].id,
            filter: 'all',
            filterHasChanged: true,
            selectedMerchantIds: [],
            send: false
        };
    }

    componentDidMount() {
        !empty(this.state.start) && !empty(this.state.end) && this.getData();
    }

    componentDidUpdate(_, prevState) {
        if (
            this.state.orderBy !== prevState.orderBy ||
            this.state.order !== prevState.order ||
            this.state.searchValue !== prevState.searchValue ||
            this.state.page !== prevState.page ||
            this.state.filter !== prevState.filter
        ) {
            this.setState({ loading: true });
        }

        if (this.state.filter !== prevState.filter) {
            this.setState({ filterHasChanged: true });
        }

        if (this.state.submited && prevState.submited !== this.state.submited) {
            if (!this.state.error) {
                if (new Date(this.state.start).getTime() > new Date(this.state.end).getTime()) {
                    if (
                        window.confirm(
                            'La date de début est supérieur à la date de fin.\nInverser les dates ?'
                        )
                    ) {
                        this.setState({
                            submited: false,
                            start: this.state.end,
                            end: this.state.start
                        });
                    } else {
                        this.setState({ submited: false });
                        return;
                    }
                }

                this.setState({
                    disableSearch: false,
                    filterHasChanged: true,
                    loading: true
                });
            }

            this.setState({
                submited: false
            });
        }

        if (this.state.send && prevState.send !== this.state.send) {
            sendBills(this.state.selectedMerchantIds, this.state.start, this.state.end)
                .then(data => {
                    alert(
                        `Envoi des factures effectué avec succès. (${data.success}/${data.total})`
                    );
                    this.props.close();
                })
                .catch(err => {
                    alert(err);
                    this.setState({
                        send: false,
                        loading: false
                    });
                });
        }

        if (this.state.loading && prevState.loading !== this.state.loading) {
            this.getData();
        }
    }

    getData() {
        const {
            start,
            end,
            page,
            order,
            orderBy,
            filter,
            filterHasChanged,
            searchValue
        } = this.state;

        getUnpaidBillsByMerchant(
            start,
            end,
            page,
            order,
            orderBy,
            filter,
            filterHasChanged,
            searchValue
        )
            .then(data => this.setState({ ...data, loading: false, filterHasChanged: false }))
            .catch(err => {
                alert(err);
                this.setState({
                    loading: false
                });
            });
    }

    submitHandler = event => {
        event.preventDefault();
        const { start, end } = this.state;

        let error = false;

        if (!start || start == '') {
            error = 'start';
        }

        if (!end || end == '') {
            error = 'end';
        }

        if ((!start && !end) || (start == '' && end == '')) {
            error = 'all';
        }

        this.setState({
            submited: true,
            error: error
        });
    };

    isIn = merchantId => {
        const { selectedMerchantIds } = this.state;
        return selectedMerchantIds.includes(parseInt(merchantId));
    };

    updateList = v => {
        const { selectedMerchantIds } = this.state;
        const bf = [...selectedMerchantIds];

        const index = bf.indexOf(v);

        if (index > -1) {
            bf.splice(index, 1);
        } else {
            bf.push(v);
        }

        this.setState({ selectedMerchantIds: bf });
    };

    sendBillsWrapper = () => {
        const { selectedMerchantIds } = this.state;
        if (empty(selectedMerchantIds)) {
            alert('Veuillez sélectionner au moins un commerçant');
            return;
        }

        this.setState({ send: true });
    };

    render() {
        const {
            start,
            end,
            error,
            searchValue,
            disableSearch,
            withSummary,
            loading,
            order,
            orderBy,
            page,
            rows,
            record,
            filter,
            send
        } = this.state;

        const { open, close, classes } = this.props;

        return (
            <Dialog
                title={'Envoi de facture en masse'}
                action={
                    <Button
                        onClick={this.sendBillsWrapper}
                        color="primary"
                        disabled={send}
                        variant="contained"
                        endIcon={<SendIcon />}>
                        Envoyer
                    </Button>
                }
                open={open}
                onClose={close}>
                <Grid container direction="column" spacing={3}>
                    {/* <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={withSummary}
                                    onChange={() => this.setState({ withSummary: !withSummary })}
                                    name="withSummary"
                                    color="primary"
                                />
                            }
                            label="Envoyer un récapitulatif"
                        />
                    </Grid> */}
                    <Grid item>
                        <form onSubmit={this.submitHandler} className={classes.form}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={5}>
                                    <TextField
                                        label="Date de début"
                                        type="date"
                                        fullWidth
                                        size="small"
                                        name="start"
                                        helperText={
                                            (error == 'start' || error == 'all') &&
                                            'Veuillez saisir une date de début'
                                        }
                                        error={error == 'start' || error == 'all'}
                                        value={start}
                                        onChange={event =>
                                            this.setState({ start: event.target.value })
                                        }
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        label="Date de fin"
                                        type="date"
                                        fullWidth
                                        size="small"
                                        name="end"
                                        helperText={
                                            (error == 'end' || error == 'all') &&
                                            'Veuillez saisir une date de fin'
                                        }
                                        error={error == 'end' || error == 'all'}
                                        value={end}
                                        onChange={event =>
                                            this.setState({ end: event.target.value })
                                        }
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary">
                                        <SearchIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <SearchTextField
                            label={`Rechercher dans la période sélectionnée`}
                            value={searchValue}
                            disabled={disableSearch}
                            onChange={v => {
                                this.setState({ searchValue: v });
                            }}
                            helperText={
                                'Saisir un numéro de facture, un SIREN/SIRET, une raison sociale ou une enseigne'
                            }
                        />
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item style={{ marginTop: 10 }}>
                        <MuiTable
                            // disabled
                            refresh={() => this.setState({ loading: true })}
                            loading={loading}
                            headers={headers}
                            page={{
                                set: v => this.setState({ page: v }),
                                data: page,
                                count: record
                            }}
                            order={{
                                set: v => this.setState({ order: v }),
                                data: order
                            }}
                            orderBy={{
                                set: v => this.setState({ orderBy: v }),
                                data: orderBy
                            }}
                            filter={[
                                {
                                    set: a => this.setState({ filter: a, page: 0 }),
                                    sortLabel: 'type',
                                    default: 'all',
                                    current: filter,
                                    rows: filters(classes)
                                }
                            ]}>
                            {rows.map((row, index) => (
                                <TableRow hover key={`${index}`}>
                                    <TableCell align="left" key={`socialeReason-${index}`}>
                                        {row.socialeReason}
                                    </TableCell>
                                    <TableCell align="center" key={`status-${index}`}>
                                        <Checkbox
                                            checked={this.isIn(row.merchantId)}
                                            onChange={() =>
                                                this.updateList(parseInt(row.merchantId))
                                            }
                                            color="primary"
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </MuiTable>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

export default withStyles(combineStyles(styles))(SendBulkBillsForm);
