/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  List,
  ListItem,
  ListSubheader,
  Typography,
  Button,
  Grid,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: -15,
    marginLeft: -15,
    marginBottom: 10,
    maxHeight: "calc(100vh - 115px)",
    minHeight: "calc(100vh - 115px)",
    //height: "calc(100vh - 102px)",
    overflowY: "auto",
    overflowX: "hidden"
  },
  subheader: {
    color: theme.palette.white,
    background: "#43425D",
    paddingRight: 0
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.white, /*colors.blueGrey[800]*/
    padding: 20,
    borderRadius: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    fontWeight: 400/*theme.typography.fontWeightMedium*/
  },
  divider: {
    width: "65%",
    background: "#a5a4bf45",
    transform: "translateX(55%)",
  },
  active: {
    //color: theme.palette.primary.main,
    borderLeft: "4px solid #007ab7",
    backgroundColor: '#3C3B54',
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.white
    }
  },
  icon: {
    color: "#A5A4BF",
    position: "relative",
    top: 8,
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, onClose, roleId, defaultPages, disconnected, ...rest } = props;
  const classes = useStyles();

  const sections = [
    {
      moduleId: 0,
      sectionName: "Module marché",
    },
    {
      moduleId: 1,
      sectionName: "Module domaine",
    },
  ];

  return (
    <List className={classes.root} subheader={<li />}>
      {
        (roleId > 0) &&
        defaultPages[roleId - 1].map((page) => (
          <ListItem
            className={classes.item}
            disableGutters
            key={`link-${page.title}`}
          >
            <Button
              startIcon={page.icon}
              activeClassName={classes.active}
              onClick={onClose}
              fullWidth
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
            >
              {page.title}
            </Button>
          </ListItem>
        ))
      }
      {
        (roleId > 0) &&
        sections.map(({ sectionName, moduleId }) => (
          <li key={`section-${sectionName}`}>
            <ul>
              <ListSubheader className={classes.subheader}>
                <Grid container justify="flex-end" alignItems="center" direction="row">
                  <Grid item>
                    <Typography style={{ color: "#a5a4bf", paddingRight: 16 }} variant="overline">
                      {sectionName}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
              </ListSubheader>
              {
                pages[moduleId][roleId - 1].map((page) => (
                  <ListItem
                    className={classes.item}
                    disableGutters
                    key={`link-${page.title}-${moduleId}`}
                  >
                    <Button
                      startIcon={page.icon}
                      activeClassName={classes.active}
                      onClick={onClose}
                      fullWidth
                      className={classes.button}
                      disabled={page.disable}
                      component={CustomRouterLink}
                      to={page.href}
                    >
                      {page.title}
                    </Button>
                  </ListItem>
                ))
              }
            </ul>
          </li>
        ))
      }
      {
        (roleId == 0) &&
        <ListItem
          className={classes.item}
          disableGutters
          key={`link-${disconnected.title}`}
        >
          <Button
            startIcon={disconnected.icon}
            activeClassName={classes.active}
            onClick={onClose}
            fullWidth
            className={classes.button}
            component={CustomRouterLink}
            to={disconnected.href}
          >
            {disconnected.title}
          </Button>
        </ListItem>
      }
    </List>
  );
};

export default SidebarNav;
