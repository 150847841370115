import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableRow,
    Typography,
    TextField,
    MenuItem,
    Button,
    CircularProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FileInput } from './components';

const useStyles = makeStyles(theme => ({
    head: {
        background: 'rgb(192,226,251,0.35)',
        borderBottom: '1px solid #c0e2fb'
    },
    cellKabis: {
        borderLeft: '7px solid #c0e2fb'
    }
}));

const activities = [
    {
        id: 0,
        key: 'none',
        label: '',
        color: 'black'
    },
    {
        id: 1,
        key: 'alim',
        label: 'Alimentaire',
        color: 'yellow'
    },
    {
        id: 2,
        key: 'prim',
        label: 'Primeur',
        color: 'green'
    },
    {
        id: 3,
        key: 'manu',
        label: 'Manufacturé',
        color: 'red'
    },
    {
        id: 4,
        key: 'dempos',
        label: 'Démonstrateur/Posticheur',
        color: 'purple'
    }
];

const Profil = props => {
    const { className, data, sendData, setIsLoading, isLoading, ...rest } = props;

    const classes = useStyles();

    const [state, setState] = React.useState({
        enseigne: data.sites[data.choosenKey].commercialName,
        activite_id: 0,
        metier: null,
        metres: '',
        telephone: data.company.identity.contact.phone,
        mail: data.company.identity.contact.email,
        internet: data.company.identity.contact.webSite,
        assurance: '',
        numeroPoliceAssurance: '',
        dateFinAssurance: '',
        arrivedAt: '',
        holderDate: '',
        merchantCardDate: '',
        aot: '',
        aotFile: null,
        assuranceFile: null,
        points: 0
    });

    const handleData = ({ target: { name, value } }) => {
        if (name == 'activite_id') {
            setState({
                ...state,
                metier: null,
                [name]: value
            });
        } else if (name != 'points') {
            setState({ ...state, [name]: value });
        } else if (value > -1) {
            setState({ ...state, [name]: value });
        }
    };

    const handleMetier = (event, value) => {
        setState({ ...state, metier: value });
    };

    const handleFileAot = (name, file) => {
        setState({ ...state, [name]: file });
    };

    const handleFileAssurance = (name, file) => {
        setState({ ...state, [name]: file });
    };

    const prepareData = () => {
        setIsLoading(true);
        sendData(state);
    };

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow hover key="infokabis">
                                <TableCell className={classes.head} colSpan={2} align="left">
                                    <Typography color="secondary" variant="subtitle1">
                                        Informations administratives
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow hover key="siren">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Siren
                                </TableCell>
                                <TableCell align="left">{data.company.identity.id}</TableCell>
                            </TableRow>
                            <TableRow hover key="siret">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Siret
                                </TableCell>
                                <TableCell align="left">{data.sites[data.choosenKey].id}</TableCell>
                            </TableRow>
                            <TableRow hover key="rs">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Raison sociale
                                </TableCell>
                                <TableCell align="left">
                                    {data.sites[data.choosenKey].name}
                                </TableCell>
                            </TableRow>
                            <TableRow hover key="nom">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Nom du dirigeant
                                </TableCell>
                                <TableCell align="left">{data.mandataire.name}</TableCell>
                            </TableRow>

                            <TableRow hover key="prenom">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Prénom du dirigeant
                                </TableCell>
                                <TableCell align="left">{data.mandataire.firstname}</TableCell>
                            </TableRow>

                            <TableRow hover key="adresse">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Adresse
                                </TableCell>
                                <TableCell align="left">
                                    {data.sites[data.choosenKey].address.street}
                                </TableCell>
                            </TableRow>

                            <TableRow hover key="codePostal">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Code postal
                                </TableCell>
                                <TableCell align="left">
                                    {data.sites[data.choosenKey].address.postalCode}
                                </TableCell>
                            </TableRow>

                            <TableRow hover key="ville">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Ville
                                </TableCell>
                                <TableCell align="left">
                                    {data.sites[data.choosenKey].address.locality}
                                </TableCell>
                            </TableRow>

                            <TableRow hover key="tva">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    TVA Intra.
                                </TableCell>
                                <TableCell align="left">
                                    {data.company.identity.vatnumber}
                                </TableCell>
                            </TableRow>

                            <TableRow hover key="dateCreationSiren">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Date de création du siren
                                </TableCell>
                                <TableCell align="left">
                                    {data.company.identity.creationDate}
                                </TableCell>
                            </TableRow>

                            <TableRow hover key="dateCreationSiret">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Date de création du siret
                                </TableCell>
                                <TableCell align="left">
                                    {data.sites[data.choosenKey].creationDate}
                                </TableCell>
                            </TableRow>

                            <TableRow hover key="codeNaf">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Code NAF
                                </TableCell>
                                <TableCell align="left">
                                    {data.sites[data.choosenKey].activity.code}
                                </TableCell>
                            </TableRow>

                            <TableRow hover key="labelNaf">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Libellé NAF
                                </TableCell>
                                <TableCell align="left">
                                    {data.sites[data.choosenKey].activity.label}
                                </TableCell>
                            </TableRow>

                            <TableRow hover key="formeJuridique">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Forme juridique
                                </TableCell>
                                <TableCell align="left">
                                    {data.company.identity.legalForm.label} (INSEE{' '}
                                    {data.company.identity.legalForm.codeINSEE})
                                </TableCell>
                            </TableRow>

                            <TableRow hover key="arrivedAt">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Date d'arrivée sur le marché
                                </TableCell>
                                <TableCell align="left">
                                    <TextField
                                        fullWidth
                                        name="arrivedAt"
                                        type="date"
                                        onChange={handleData}
                                        value={state.arrivedAt}
                                        variant="outlined"
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow hover key="merchantCardDate">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Date de la carte du commerçant
                                </TableCell>
                                <TableCell align="left">
                                    <TextField
                                        fullWidth
                                        name="merchantCardDate"
                                        type="date"
                                        onChange={handleData}
                                        value={state.merchantCardDate ? state.merchantCardDate : ''}
                                        variant="outlined"
                                    />
                                </TableCell>
                            </TableRow>

                            <TableRow hover key="holderDate">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Date de titularisation
                                </TableCell>
                                <TableCell align="left">
                                    <TextField
                                        fullWidth
                                        name="holderDate"
                                        type="date"
                                        onChange={handleData}
                                        value={state.holderDate}
                                        variant="outlined"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow hover key="infokabis">
                                <TableCell className={classes.head} colSpan={2} align="left">
                                    <Typography color="secondary" variant="subtitle1">
                                        Caractéristiques du commerçant
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow hover key="aot">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    AOT
                                </TableCell>
                                <TableCell align="left">
                                    <Grid container spacing={2} direction="row">
                                        <Grid item md={4} xs={12}>
                                            <TextField
                                                fullWidth
                                                name="aot"
                                                type="text"
                                                onChange={handleData}
                                                value={state.aot}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item md={8} xs={12}>
                                            <FileInput
                                                setFile={handleFileAot}
                                                name="aotFile"
                                                file={state.aotFile}
                                            />
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow hover key="enseigne">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Enseigne
                                </TableCell>
                                <TableCell align="left">
                                    <TextField
                                        fullWidth
                                        name="enseigne"
                                        type="text"
                                        onChange={handleData}
                                        value={state.enseigne}
                                        variant="outlined"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover key="activite">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    <Grid container direction="column">
                                        <Grid item>Activité</Grid>
                                        <Grid item>
                                            <Typography variant="body2" style={{ color: 'red' }}>
                                                Champ obligatoire
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell align="left">
                                    <TextField
                                        fullWidth
                                        name="activite_id"
                                        select
                                        value={state.activite_id}
                                        onChange={handleData}
                                        variant="outlined">
                                        {activities.map((act, index) => (
                                            <MenuItem key={act.id} value={act.id}>
                                                {act.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </TableCell>
                            </TableRow>
                            <TableRow hover key="metier">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    <Grid container direction="column">
                                        <Grid item>Métier</Grid>
                                        <Grid item>
                                            <Typography variant="body2" style={{ color: 'red' }}>
                                                Champ obligatoire
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell align="left">
                                    <Autocomplete
                                        options={
                                            Boolean(state.activite_id == 0)
                                                ? null
                                                : data.metiers[state.activite_id]
                                        }
                                        name="metier"
                                        disabled={Boolean(state.activite_id == 0)}
                                        autoHighlight
                                        value={state.metier}
                                        onChange={handleMetier}
                                        noOptionsText="Métier non référencé"
                                        getOptionLabel={option => option.libelle}
                                        getOptionSelected={(option, value) => option.id == value.id}
                                        renderInput={params => (
                                            <TextField fullWidth {...params} variant="outlined" />
                                        )}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover key="metres">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Métrage souhaité
                                </TableCell>
                                <TableCell align="left">
                                    <TextField
                                        fullWidth
                                        name="metres"
                                        select
                                        value={state.metres}
                                        onChange={handleData}
                                        variant="outlined">
                                        <MenuItem key={0} value={0}>
                                            Pas de préférence
                                        </MenuItem>
                                        {[...Array(40).keys()].map(
                                            nbr =>
                                                nbr + 1 > 1 && (
                                                    <MenuItem key={nbr + 1} value={nbr + 1}>
                                                        {nbr + 1}
                                                    </MenuItem>
                                                )
                                        )}
                                    </TextField>
                                </TableCell>
                            </TableRow>
                            {props.userProfile.getRoleId() == 1 && (
                                <TableRow hover key="points">
                                    <TableCell
                                        className={classes.cellKabis}
                                        component="th"
                                        scope="row">
                                        Nombre de points
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            fullWidth
                                            name="points"
                                            type="number"
                                            onChange={handleData}
                                            value={state.points}
                                            variant="outlined"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow hover key="contact">
                                <TableCell className={classes.head} colSpan={2} align="left">
                                    <Typography color="secondary" variant="subtitle1">
                                        Contact du commerçant
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow hover key="telephone">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Téléphone
                                </TableCell>
                                <TableCell align="left">
                                    <TextField
                                        fullWidth
                                        name="telephone"
                                        type="text"
                                        onChange={handleData}
                                        value={state.telephone}
                                        variant="outlined"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover key="mail">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Mail
                                </TableCell>
                                <TableCell align="left">
                                    <TextField
                                        fullWidth
                                        name="mail"
                                        type="text"
                                        onChange={handleData}
                                        value={state.mail}
                                        variant="outlined"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover key="internet">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Lien internet
                                </TableCell>
                                <TableCell align="left">
                                    <TextField
                                        fullWidth
                                        name="internet"
                                        type="text"
                                        onChange={handleData}
                                        value={state.internet}
                                        variant="outlined"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow hover key="assureur">
                                <TableCell className={classes.head} colSpan={2} align="left">
                                    <Typography color="secondary" variant="subtitle1">
                                        Données assurance du commerçant
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow hover key="dateFinAssurance">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Date de fin de validité
                                </TableCell>
                                <TableCell align="left">
                                    <TextField
                                        fullWidth
                                        name="dateFinAssurance"
                                        type="date"
                                        onChange={handleData}
                                        value={state.dateFinAssurance}
                                        variant="outlined"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover key="telephone">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Document assurance
                                </TableCell>
                                <TableCell align="left">
                                    <FileInput
                                        setFile={handleFileAssurance}
                                        name="assuranceFile"
                                        file={state.assuranceFile}
                                    />
                                    {/*<TextField
                                        fullWidth
                                        name="assurance"
                                        type="text"
                                        onChange={handleData}
                                        value={state.assurance}
                                        variant="outlined"
                                    />*/}
                                </TableCell>
                            </TableRow>
                            {/*<TableRow hover key="numero">
                                <TableCell className={classes.cellKabis} component="th" scope="row">
                                    Numéro de police
                                </TableCell>
                                <TableCell align="left">
                                    <TextField
                                        fullWidth
                                        name="numeroPoliceAssurance"
                                        type="text"
                                        onChange={handleData}
                                        value={state.numeroPoliceAssurance}
                                        variant="outlined"
                                    />
                                </TableCell>
                                    </TableRow>*/}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item style={{ textAlign: 'center', marginTop: 20 }}>
                <Grid container justify="center" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            onClick={prepareData}
                            disabled={isLoading}
                            variant="contained"
                            color="primary">
                            Créer ce commerçant
                        </Button>
                    </Grid>
                    {isLoading && (
                        <Grid item>
                            <CircularProgress color="primary" size={25} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Profil;
