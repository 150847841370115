import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: 150,
  },
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}>
        <Grid item lg={6} xs={12}>
          <Typography variant="h2" style={{ textAlign: "center" }}>
            Oups ! La page que vous recherchez n'existe pas.
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography variant="subtitle2" style={{ textAlign: "center" }}>
            Il semblerait que l'adresse a mal été saisi ou que la page a peut-être été déplacée.
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography variant="subtitle2" color="primary" style={{ textAlign: "center" }}>
            Si cette erreur persiste veuillez contacter le support technique : <font style={{ textDecoration: "underline" }}>support@digitmarche.com</font>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
