import React from 'react';
import clsx from 'clsx';
import { combineStyles } from 'common/styles';
import { makeStyles } from '@material-ui/core/styles';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { AmountStatChart, MuiPopper } from './components';

import {
    Card,
    CardContent,
    Typography,
    CardHeader,
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    Grid,
    IconButton,
    CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles(
    combineStyles(theme => ({
        root: {
            height: '100%'
        },
        content: {
            display: 'inline-flex',
            width: '100%',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'column'
        },
        cardAvatar: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            backgroundColor: theme.palette.primary.main
        },
        legendAvatar: {
            width: 36,
            height: 36
        },
        paidAvatar: {
            backgroundColor: theme.palette.paid.normal,
            '& *': {
                color: 'white !important'
            }
        },
        unpaidAvatar: {
            backgroundColor: theme.palette.unpaid.normal,
            '& *': {
                color: 'white !important'
            }
        },
        canceledAvatar: {
            backgroundColor: theme.palette.canceled.normal,
            '& *': {
                color: 'white !important'
            }
        },
        lateAvatar: {
            backgroundColor: theme.palette.late.normal,
            '& *': {
                color: 'white !important'
            }
        },
        taAvatar: {
            backgroundColor: theme.palette.ta.normal,
            '& *': {
                color: 'white !important'
            }
        },
        tnaAvatar: {
            backgroundColor: theme.palette.tna.normal,
            '& *': {
                color: 'white !important'
            }
        },
        pAvatar: {
            backgroundColor: theme.palette.p.normal,
            '& *': {
                color: 'white !important'
            }
        },
        listItem: {
            paddingLeft: 0
        },
        row: {
            '& *': {
                borderColor: '#eeeeee'
            },
            '&:last-child': {
                '& td': {
                    border: 0
                }
            }
        },
        listItemAvatar: {
            minWidth: 50
        }
    }))
);

const DetailBillCard = props => {
    const { chart, type, loading } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [filter, setFilter] = React.useState('amount');
    const anchorRef = React.useRef(null);

    return (
        <React.Fragment>
            <Card className={classes.root}>
                <CardHeader
                    avatar={
                        <Avatar className={classes.cardAvatar} variant="rounded" color="primary">
                            <EqualizerIcon />
                        </Avatar>
                    }
                    action={
                        <IconButton ref={anchorRef} onClick={() => setOpen(prevOpen => !prevOpen)}>
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={
                        <Typography color="primary" variant="h5">
                            Synthèse des factures de la période sélectionnée
                        </Typography>
                    }
                />
                <CardContent className={classes.content}>
                    <Grid container direction={loading ? 'column' : 'row'} alignItems="center">
                        {loading ? (
                            <Grid item>
                                <CircularProgress size={35} />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={5}>
                                    <AmountStatChart chart={chart} filter={filter} type={type} />
                                </Grid>
                                <Grid item xs>
                                    <TableContainer>
                                        <Table size="small" padding="none">
                                            <TableBody>
                                                {Object.keys(chart).map(
                                                    key =>
                                                        key !== 'datasets' && (
                                                            <TableRow
                                                                className={clsx(
                                                                    classes.row,
                                                                    classes[key]
                                                                )}>
                                                                <TableCell align="left">
                                                                    <ListItem
                                                                        className={
                                                                            classes.listItem
                                                                        }>
                                                                        <ListItemAvatar
                                                                            className={
                                                                                classes.listItemAvatar
                                                                            }>
                                                                            <Avatar
                                                                                className={clsx(
                                                                                    classes[
                                                                                        `${key}Avatar`
                                                                                    ],
                                                                                    classes.legendAvatar
                                                                                )}>
                                                                                <CheckIcon />
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={
                                                                                <Typography variant="h5">
                                                                                    {chart[key]
                                                                                        ? chart[key]
                                                                                              .count
                                                                                        : 0}
                                                                                </Typography>
                                                                            }
                                                                            secondary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    style={{
                                                                                        color:
                                                                                            '#546e7a'
                                                                                    }}>
                                                                                    {
                                                                                        chart[key]
                                                                                            .sublabel
                                                                                    }
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <Typography variant="h5">
                                                                        {chart[key]
                                                                            ? chart[key].amount.v
                                                                            : '0,00'}{' '}
                                                                        €
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </CardContent>
            </Card>

            <MuiPopper
                setOpen={() => setOpen(prevOpen => !prevOpen)}
                open={open}
                anchorRef={anchorRef}
                filter={filter}
                setFilter={setFilter}
            />
        </React.Fragment>
    );
};

export default DetailBillCard;
