import React, { useState, useEffect } from 'react';
import { Button, Grid, CircularProgress, Typography } from '@material-ui/core';
import { Dialog } from 'components';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

/**
 * url
 */
const url = `https://www.${
    window.location.hostname.split('.')[1]
        ? window.location.hostname.split('.')[0] === 'www'
            ? window.location.hostname.split('.')[1]
            : window.location.hostname.split('.')[0]
        : 'dev'
}.api.digitmarche.fr/api`;

/**
 *
 * @param {*} drawId
 */
function show(drawId) {
    window.open(`${url}/placement/print-draw/${drawId}`, '_blank');
}

/**
 *
 * @param {*} journalMarketId
 * @returns
 */
async function getLaunchedDraw(journalMarketId) {
    const result = await fetch(`${url}/placement/get-launched-drawing/${journalMarketId}`, {
        method: 'GET'
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw "Une erreur est survenue lors de l'affichage des tirages.";
        });

    return result;
}

/**
 *
 */
const LoadingIcon = <CircularProgress fontSize={25} />;
const ErrorIcon = <HighlightOffIcon fontSize={'large'} color="error" />;

export default props => {
    const { open, close, id } = props;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [icon, setIcon] = useState(LoadingIcon);
    const [describe, setDescribe] = useState('Chargement en cours...');
    const [draws, setDraws] = useState([]);

    useEffect(() => {
        setLoading(false);
        setIcon(LoadingIcon);
        setError(false);
        setDescribe('Chargement en cours...');

        if (open) {
            setLoading(true);

            getLaunchedDraw(id)
                .then(data => {
                    setLoading(false);
                    setDraws(data);
                })
                .catch(err => {
                    setLoading(false);
                    setIcon(ErrorIcon);
                    setError(true);
                    setDescribe(err);
                });
        }
    }, [open]);

    return (
        <Dialog open={open} title="Résultat des tirages au sort" onClose={close}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    {loading || error ? (
                        <Grid
                            container
                            direction="column"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center">
                            <Grid item>{icon}</Grid>
                            <Grid item>
                                <Typography variant="body1">{describe}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container direction="column" spacing={2}>
                            {draws.map(row => (
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="baseline">
                                        <Grid item>
                                            <Typography variant="button" color="primary">
                                                {row.label}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                size="small"
                                                onClick={() => show(row.draw_id)}
                                                variant="outlined">
                                                Visualiser
                                            </Button>{' '}
                                            <Button size="small" color="primary" variant="outlined">
                                                Envoyer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Dialog>
    );
};
