import React from 'react'
import {
    Grid,
    TextField,
} from '@material-ui/core'

const BillSenderForm = (props) => {
    const { onChange } = props

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <TextField
                    onChange={onChange}
                    label="Adresse mail"
                    helperText="Saisir une adresse mail"
                    fullWidth
                    variant="outlined"
                />
            </Grid>
        </Grid>
    )

}

export default BillSenderForm;