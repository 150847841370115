import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
    Paper,
    Grid,
    Divider,
    Typography,
    IconButton,
    Link,
    TextField,
    InputAdornment,
    Button,
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Radio,
    FormGroup,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    CircularProgress
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';
import { Pagination } from './components';

/**
 *
 */
const env = `https://www.${
    window.location.hostname.split('.')[1]
        ? window.location.hostname.split('.')[0] === 'www'
            ? window.location.hostname.split('.')[1]
            : window.location.hostname.split('.')[0]
        : 'dev'
}.api.digitmarche.fr/api`;

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    },
    hover: {
        '&$hover:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.08)'
        }
    },
    selected: {
        '&$selected, &$selected:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.15)',
            cursor: 'auto'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

const styles = theme => ({
    root: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

class AttendanceTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            searchValue: '',
            page: 1,
            isLoading: true,
            filterType: 't',
            type: 't',
            numberOfPages: 0,
            records: 0,
            limit: '',
            rows: []
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        this.state.isLoading && prevState.isLoading != this.state.isLoading && this.getData();
    };

    getData = () => {
        const { page, searchValue, filterType, limit } = this.state;

        const { date, market } = this.props;

        const formData = new FormData();
        formData.append('search', searchValue);
        formData.append('type', filterType);
        formData.append('page', page);
        formData.append('limit', limit);
        formData.append('market_id', market.market_id);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/attendance/${date.start}/${date.end}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    isLoading: false
                });
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            isLoading: true,
            page: 1
        });
    };

    handleTypeFilter = ({ target: { value } }) => {
        this.setState({
            filterType: value,
            page: 1,
            isLoading: true
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage,
            isLoading: true
        });
    };

    print = () => {
        const { filterType, limit } = this.state;
        const {
            market: { market_id },
            date: { start, end }
        } = this.props;

        const formData = new FormData();
        formData.append('type', filterType);
        formData.append('market_id', market_id);
        formData.append('print', 'y');
        formData.append('limit', limit);

        window.confirm('Imprimer la sélection ?') &&
            fetch(`${env}/merchant/attendance/${start}/${end}`, {
                method: 'POST',
                body: formData
            })
                .then(res => res.blob())
                .then(blob => {
                    const anchor = document.createElement('a');
                    anchor.href = window.URL.createObjectURL(blob);
                    anchor.target = '_blank';
                    anchor.click();
                })
                .catch(error => {
                    console.error(error);
                    alert("Echec de l'impression");
                });
    };

    setLimit = ({ target: { value } }) => {
        const re = /^[0-9\b]+$/;

        if (value === '' || re.test(value)) {
            this.setState({ limit: value.slice(0, 2), page: 1, isLoading: true });
        }
    };

    render() {
        const {
            classes,
            searchValue,
            rows,
            type,
            filterType,
            isLoading,
            records,
            numberOfPages,
            page,
            limit
        } = this.state;

        const { market } = this.props;

        return (
            <Paper className={classes.root}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container justify="space-between" spacing={2} alignItems="center">
                            <Grid item>
                                <Typography variant="h4" color="primary">
                                    {market.label} ({market.total} journées)
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={this.print}
                                    endIcon={<PrintIcon />}
                                    color="primary">
                                    Imprimer
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={'Rechercher un commerçant'}
                            type="text"
                            fullWidth
                            value={searchValue}
                            onChange={this.handleSearch}
                            variant="outlined"
                            helperText="Saisir un SIRET/SIREN, une raison sociale, une enseigne, un nom ou un prénom"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                this.handleSearch({ target: { value: '' } })
                                            }
                                            edge="end">
                                            <ClearIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            justifyContent="space-between"
                            spacing={2}>
                            <Grid item>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" className={classes.labelFilter}>
                                        Type de commerçant
                                    </FormLabel>
                                    <Grid container direction="row" spacing={2} alignItems="center">
                                        <Grid item>
                                            <RadioGroup
                                                row
                                                name="type"
                                                value={filterType}
                                                onChange={this.handleTypeFilter}>
                                                <FormControlLabel
                                                    value="t"
                                                    control={<Radio />}
                                                    label="Titulaire"
                                                />
                                                <FormControlLabel
                                                    value="p"
                                                    control={<Radio />}
                                                    label="Passager"
                                                />
                                            </RadioGroup>
                                        </Grid>
                                        <Grid item>
                                            {isLoading && (
                                                <CircularProgress color="primary" size={25} />
                                            )}
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" className={classes.labelFilter}>
                                        Nombre minimum{' '}
                                        {filterType === 't' ? "d'absence" : 'de présence'}
                                    </FormLabel>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="flex-end">
                                        <Grid item>
                                            <TextField
                                                onChange={this.setLimit}
                                                value={limit}
                                                placeholder="-"
                                                margin="dense"
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Button
                                                                size="small"
                                                                color="primary"
                                                                onClick={() =>
                                                                    this.setLimit({
                                                                        target: { value: '' }
                                                                    })
                                                                }>
                                                                Effacer
                                                            </Button>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" color="primary">
                            Nombre de résultats : {records}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table stickyHeader size="small" className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align={'left'}>
                                            Raison sociale
                                        </StyledTableCell>
                                        <StyledTableCell align={'center'}>
                                            Nombre de présence
                                        </StyledTableCell>
                                        <StyledTableCell align={'center'}>
                                            % de présence
                                        </StyledTableCell>
                                        <StyledTableCell align={'center'}>
                                            Nombre{' '}
                                            {type == 'p'
                                                ? 'de placement par présence'
                                                : "d'absence non justifiée"}
                                        </StyledTableCell>
                                        <StyledTableCell align={'center'}>
                                            %{' '}
                                            {type == 'p'
                                                ? 'de placement par présence'
                                                : "d'absence non justifiée"}
                                        </StyledTableCell>
                                        <StyledTableCell align={'center'}>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => {
                                        return (
                                            <StyledTableRow hover key={index}>
                                                <TableCell key={`${index}_label`} align="left">
                                                    {row.merchant.label}
                                                </TableCell>
                                                <TableCell
                                                    key={`${index}_present_absolute`}
                                                    align="right">
                                                    {row.present.absolute}
                                                </TableCell>
                                                <TableCell
                                                    key={`${index}_present_percentage`}
                                                    align="right">
                                                    {row.present.percentage} %
                                                </TableCell>
                                                <TableCell
                                                    key={`${index}_placement_absolute`}
                                                    align="right">
                                                    {type == 'p'
                                                        ? row.placement.absolute
                                                        : row.absent.absolute}
                                                </TableCell>
                                                <TableCell
                                                    key={`${index}_placement_percentage`}
                                                    align="right">
                                                    {type == 'p'
                                                        ? row.placement.percentage
                                                        : row.absent.percentage}{' '}
                                                    %
                                                </TableCell>
                                                <TableCell key={`${index}_action`} align="center">
                                                    <Button
                                                        component={RouterLink}
                                                        to={`/merchants/attendance/${row.merchant.siret}-${this.props.market.market_id}`}
                                                        color="primary"
                                                        variant="outlined"
                                                        size="small">
                                                        Détail
                                                    </Button>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item>
                        <Pagination
                            numberOfPages={numberOfPages}
                            currentPage={page}
                            handlePagination={this.handlePagination}
                        />
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(AttendanceTable);
