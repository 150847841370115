import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Button,
    TextField
} from '@material-ui/core';

import {
    FileInput
} from './components'

const useStyles = makeStyles(theme => ({
    root: {

    },
}));

const Field = props => {
    const { className, data, sendData, ...rest } = props;

    const classes = useStyles();
    const [value, setValue] = React.useState(data.dateFinAssurance);
    const [file, setFile] = React.useState("");

    const handleData = () => {

        if ((!value || value.length == 0 || !value.replace(/\s/g, '').length) && !file) {
            alert("Donnée incorrect.");
            return;
        }

        sendData(value, file, data.id);

    }

    const handleValue = ({ target: { value } }) => {
        setValue(value);
    }

    const handleFile = (name, file) => {
        setFile(file);
    }


    return (
        <Grid container direction="column" spacing={1} justify="flex-start" alignItems="flex-start">
            <Grid item>
                <TextField
                    //error={Boolean(data.dateFinAssurance)}
                    value={value}
                    type={data._typeFirst}
                    fullWidth
                    onChange={handleValue}
                    size="small"
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <FileInput setFile={handleFile} file={file} />
            </Grid>
            <Grid item>
                <Button onClick={handleData}
                    variant="contained" color="primary">
                    Mettre à jour
                </Button>
            </Grid>
        </Grid>
    );
};

export default Field;
