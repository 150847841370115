import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';

import { Sidebar, Topbar, Footer } from './components';


const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  topbar: {
    //right: "inherit", 
    //padding:20
  },
  content: {
    height: '100%',
    paddingBottom: 100
  },
  image: {
    width: "100%",
    maxWidth: 300,
    filter: "opacity(0.7)",
  },
  containerImageMobile: {
    position: "fixed",
    zIndex: -1,
    left: "50%",
    bottom: -5,
    transform: "translateX(-50%)"
  },
  containerImage: {
    position: "fixed",
    zIndex: -1,
    left: "50vw",
    bottom: -5,
    transform: "translateX(-10%)"
  },
}));

const Main = props => {
  const { children, userProfile, journalMarket } = props;
  
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Topbar className={classes.topbar} onSidebarOpen={handleSidebarOpen} />

      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        roleId={userProfile.getRoleId()}
        journalId={journalMarket.getId()}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />

      <main className={classes.content}>
        {children}
        <Footer />
      </main>

      <div className={clsx({
        [classes.containerImage]: isDesktop,
        [classes.containerImageMobile]: !isDesktop
      })}
      >
        <img src="/images/home.png" className={classes.image} />
      </div>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
