import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
    Grid,
    Typography,
    Button,
    CircularProgress,
    Divider
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        //flexGrow: 1
    },
}));

const Overview = props => {
    const {
        className,
        data,
    } = props;

    const classes = useStyles();

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item>
                <Grid container direction="row" spacing={1} justify="center" alignItems="flex-end">
                    <Grid item>
                        <Grid container direction="row" spacing={1} alignItems="flex-end">
                            {
                                data.overview.subfirst &&
                                <Grid item>
                                    <Typography variant="body1" align="center" gutterBottom>
                                        ({data.overview.subfirst.value})
                                    </Typography>
                                </Grid>
                            }
                            <Grid item>
                                <Typography variant="h2" align="center" gutterBottom>
                                    {data.overview.first.value}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        data.overview.second &&
                        <React.Fragment>
                            <Grid item>
                                <Typography variant="body2" gutterBottom>
                                    /
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <Typography variant="h2" align="center" gutterBottom>
                                            {data.overview.second.value}
                                        </Typography>
                                    </Grid>
                                    {
                                        data.overview.subsecond &&
                                        <Grid item>
                                            <Typography variant="body1" align="center" gutterBottom>
                                                ({data.overview.subsecond.value})
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={5}>
                    {
                        data.stats.keys.map(key => {
                            return (
                                <Grid item>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Grid container spacing={1} direction="column">
                                                <Grid item>
                                                    <Typography variant="button">
                                                        {key.label}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            {
                                                key.subkeys.map((subkey, index) => {

                                                    return (
                                                        <Grid container direction="column"
                                                            justify="center"
                                                            alignItems="stretch" spacing={1}>
                                                            <Grid item>
                                                                {
                                                                    data.stats[key.key][subkey].map(stat => {
                                                                        return (
                                                                            <Grid item>
                                                                                <Grid container direction="row" justify="space-between" spacing={1}>
                                                                                    <Grid item>
                                                                                        <Typography variant="body1" style={{ color: stat.color }}>
                                                                                            {stat.label}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Grid container direction="row" spacing={1} alignItems="baseline">
                                                                                            <Grid item>
                                                                                                <Typography variant="body1" style={{ color: stat.first.color }}>
                                                                                                    {stat.first.value}
                                                                                                </Typography>
                                                                                            </Grid>
                                                                                            {
                                                                                                stat.second &&
                                                                                                <Grid item>
                                                                                                    <Typography variant="body2" style={{ color: stat.second.color }}>
                                                                                                        ({stat.second.value})
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                            }
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )
                                                                    })
                                                                }
                                                            </Grid>
                                                            {
                                                                (key.subkeys.length > 0 && (index < key.subkeys.length - 1)) &&
                                                                <Grid item>
                                                                    <Divider />
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Overview;
