import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
    Paper,
    Grid,
    Divider,
    Typography,
    IconButton,
    Link,
    TextField,
    Button,
    TableCell,
    TableRow,
    FormControl,
    FormLabel
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { AttendanceTable, DialogDetail } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

class Attendance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            isLoading: false,
            markets: [],
            start: '',
            end: '',
            approvedDate: {
                start: '',
                end: ''
            }
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.isLoading && this.state.isLoading != prevState.isLoading) {
            this.getData();
        }
    };

    componentDidMount = () => {
        //this.getData();
    };

    handleDate = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    handleForm = () => {
        const { start, end } = this.state;

        if (start == '') {
            alert('Veuillez saisir une date de début');
            return;
        }

        if (end == '') {
            alert('Veuillez saisir une date de fin');
            return;
        }

        const _start = new Date(start);
        const _end = new Date(end);

        if (_start.getTime() > _end.getTime()) {
            let inverse = window.confirm(
                'La date de début est supérieur à la date de fin\nInverser les dates ?'
            );
            inverse &&
                this.setState({
                    start: end,
                    end: start
                });
            return;
        }

        this.setState({
            isLoading: true,
            approvedDate: {
                start: _start,
                end: _end
            }
        });
    };

    getData = () => {
        const { start, end } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/attendance/${start}/${end}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    isLoading: false
                });
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { classes, markets, start, end } = this.state;

        const { siret, tab } = this.props.params;

        return (
            <Grid container direction="column" spacing={3}>
                {siret && (
                    <DialogDetail
                        date={{
                            start: start,
                            end: end
                        }}
                        params={this.props.params}
                        history={this.props.history}
                    />
                )}

                <Grid item>
                    <Paper className={classes.root}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container direction="row" alignItems="baseline">
                                    <Grid item>
                                        <IconButton onClick={this.getBack}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            <Link component={RouterLink} to="/merchants/all">
                                                Les commerçants
                                            </Link>{' '}
                                            / Assiduité des commerçants
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset" required>
                                    <FormLabel
                                        className={classes.labelFilter}
                                        style={{ marginBottom: 15 }}
                                        component="label">
                                        Rechercher par date
                                    </FormLabel>
                                </FormControl>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    spacing={2}>
                                    <Grid item xs={12} md={5}>
                                        <TextField
                                            type="date"
                                            fullWidth
                                            name="start"
                                            value={start}
                                            onChange={this.handleDate}
                                            size="small"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <TextField
                                            type="date"
                                            fullWidth
                                            size="small"
                                            name="end"
                                            value={end}
                                            onChange={this.handleDate}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            onClick={this.handleForm}>
                                            Rechercher
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item>
                    <Grid container direction="column" spacing={4} alignItems="stretch">
                        {markets.map((row, index) => {
                            return (
                                <Grid item key={`${index}_item`}>
                                    <AttendanceTable
                                        date={{
                                            start: start,
                                            end: end
                                        }}
                                        key={index}
                                        market={row}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Attendance);
