import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    DialogContent,
    Grid,
    Typography
} from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 10,
        marginBottom: 10
    }
}));

const Loader = props => {
    const { className, ...rest } = props;

    const classes = useStyles();

    return (
        <Grid
            className={classes.root}
            container
            direction="column" spacing={3}
            justify="center"
            alignItems="center"
        >
            <Grid item>
                <CircularProgress color="primary" size={60} />
            </Grid>
            <Grid item>
                <Typography>
                    Envoi en cours de progression...
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Loader;
