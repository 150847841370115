import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import {
    IconButton,
    MenuItem,
    Menu
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {

    },

}));


const Option = props => {
    const { className, id, ...rest } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton edge="end" aria-label="edit" aria-haspopup="true" onClick={handleClick}>
                <MoreHorizIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem component={RouterLink} to={"/events/event/" + id + "/info"}>Modifier</MenuItem>
            </Menu>
        </div>
    );
};

export default Option;