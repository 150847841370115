import React from 'react';
import { withStyles } from '@material-ui/styles';
import PrintIcon from '@material-ui/icons/Print';
import { MuiTable, SearchDateCard } from 'components';
import env from 'common/env';
import {
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    Grid,
    FormControl,
    FormLabel,
    TextField,
    Button,
    Typography,
    Divider
} from '@material-ui/core';

import { printAttendanceSUmmary } from 'common/pdf';
import { ComInput } from './components';

/**
 *
 * @param {*} marchand_id
 * @param {*} start
 * @param {*} end
 * @param {*} page
 * @param {*} order
 * @param {*} orderBy
 * @param {*} filterByAttendance
 * @param {*} filterByPlacement
 * @returns
 */
async function getPlacements(
    marchand_id,
    start,
    end,
    page,
    order,
    orderBy,
    filterByAttendance,
    filterByPlacement
) {
    const formData = new FormData();
    formData.append('start', start);
    formData.append('end', end);
    formData.append('page', page);
    formData.append('order', order);
    formData.append('orderBy', orderBy);
    formData.append('filterByPlacement', filterByPlacement);
    formData.append('filterByAttendance', filterByAttendance);

    const result = await fetch(`${env}/merchant/get-placements/${marchand_id}`, {
        method: 'POST',
        body: formData
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => data)
        .catch(error => {
            console.error(error);
            throw `Echec de l'affichage des placements.`;
        });

    return result;
}

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 15
    }
});

/**
 *
 */
const headers = [
    { id: 'rawPlacementDate', align: 'left', label: 'Date', sortable: true },
    { id: 'market', align: 'left', label: 'Marché', sortable: false },
    { id: 'placeNumber', align: 'right', label: 'Numéro', sortable: false },
    { id: 'meters', align: 'right', label: 'Mètres', sortable: false },
    // { id: 'attendance', align: 'left', label: 'Présent', sortable: false },
    { id: 'placed', align: 'left', label: 'Placé', sortable: false },
    // { id: 'points', align: 'right', label: 'Points', sortable: false },
    {
        id: 'comment',
        align: 'left',
        label: 'Justificatif',
        sortable: false,
        style: { width: '25%' }
    }
];

/**
 *
 * @returns
 */
const filters = [
    [
        {
            label: 'Tous',
            value: 'all'
        },
        {
            label: 'Présent',
            value: 'y'
        },
        {
            label: 'Non Présent',
            value: 'n'
        }
    ],
    [
        {
            label: 'Tous',
            value: 'all'
        },
        {
            label: 'Placé',
            value: 'y'
        },
        {
            label: 'Non placé',
            value: 'n'
        }
    ]
];

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            filterByAttendance: 'all',
            filterByPlacement: 'all',
            order: 'desc',
            orderBy: headers[0].id,
            marchand_id: this.props.marchandId,
            list: [],
            start: '',
            end: '',
            page: 0,
            count: 0,
            numberOfPoints: 0,
            loading: true
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.loading && this.state.loading !== prevState.loading) {
            this.getData();
        }
    };

    getData = () => {
        const {
            marchand_id,
            start,
            end,
            page,
            order,
            orderBy,
            filterByAttendance,
            filterByPlacement
        } = this.state;

        getPlacements(
            marchand_id,
            start,
            end,
            page,
            order,
            orderBy,
            filterByAttendance,
            filterByPlacement
        )
            .then(data => this.setState({ ...data }))
            .catch(err => alert(err))
            .finally(() => this.setState({ loading: false }));
    };

    render() {
        const {
            list,
            start,
            end,
            count,
            page,
            numberOfPoints,
            marchand_id,
            loading,
            order,
            orderBy,
            filterByAttendance,
            filterByPlacement
        } = this.state;

        return (
            <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
                <Grid item>
                    <SearchDateCard
                        inline
                        noPaper
                        title="Recherche par date d'émission de facture"
                        submit={() =>
                            this.setState({
                                loading: true
                            })
                        }
                        start={{
                            set: val => this.setState({ start: val }),
                            data: start
                        }}
                        end={{
                            set: val => this.setState({ end: val }),
                            data: end
                        }}
                    />
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <Typography color="primary" variant="button">
                        Nombre de points{' '}
                        {start === '' || end === ''
                            ? 'toute période confondu'
                            : 'sur la période sélectionnée'}{' '}
                        : {numberOfPoints}
                    </Typography>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <MuiTable
                        refresh={() => this.setState({ loading: true })}
                        print={() => printAttendanceSUmmary(start, end, marchand_id)}
                        loading={loading}
                        headers={headers}
                        page={{
                            set: page => this.setState({ page, loading: true }),
                            data: page,
                            count: count
                        }}
                        order={{
                            set: order => this.setState({ order, loading: true }),
                            data: order
                        }}
                        orderBy={{
                            set: orderBy => this.setState({ orderBy, loading: true }),
                            data: orderBy
                        }}
                        filter={
                            this.props.holder
                                ? [
                                      {
                                          set: filterByPlacement =>
                                              this.setState({ filterByPlacement, loading: true }),
                                          sortLabel: 'Placement',
                                          default: 'all',
                                          current: filterByPlacement,
                                          rows: filters[1]
                                      }
                                  ]
                                : null
                        }>
                        {list.map((row, index) => (
                            <TableRow hover key={index}>
                                <TableCell
                                    align="left"
                                    key={`placementDate-${index}-${row.billNumber}`}>
                                    {row.placementDate}
                                </TableCell>
                                <TableCell align="left" key={`market-${index}-${row.market}`}>
                                    {row.market}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    key={`placeNumber-${index}-${row.placeNumber}`}>
                                    {row.placeNumber}
                                </TableCell>
                                <TableCell align="right" key={`meters-${index}-${row.meters}`}>
                                    {row.meters}
                                </TableCell>
                                {/* <TableCell align="left" key={`attendance-${index}-${row.present}`}>
                                    {row.present}
                                </TableCell> */}
                                <TableCell align="left" key={`placed-${index}-${row.placed}`}>
                                    {row.placed}
                                </TableCell>
                                {/* <TableCell align="right" key={`points-${index}-${row.points}`}>
                                    {row.points}
                                </TableCell> */}
                                <TableCell align="left" key={`comment-${index}-${row.comment}`}>
                                    <ComInput
                                        updateRows={() => this.getData()}
                                        marketId={row.marketId}
                                        id={marchand_id}
                                        points={row.points}
                                        text={row.comment}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </MuiTable>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(History);
