import React from 'react';
import { withStyles } from '@material-ui/styles';

import { Link as RouterLink } from 'react-router-dom';
import JournalMarche from '../../../../JournalMarche';

import { Grid, Typography, Link, Divider } from '@material-ui/core';

import { TodayNews, UpcomingMarkets, StopedActivity, LateBills, Overview } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    }
});

class Placier extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            markets: {
                total: 0,
                list: []
            },
            upcomingMarkets: {
                city: '',
                list: {
                    today: [],
                    upcoming: []
                }
            },
            stopedActivity: {
                count: {
                    today: 0,
                    total: 0
                },
                list: []
            },
            lateBills: {
                count: {
                    //today: 0,
                    total: 0
                },
                lastDayOfPreviosMonth: '',
                list: []
            },
            todayNews: {
                totalCashedOfToday: 0,
                totalNotCashedOfToday: 0,
                totalPlacedMerchant: 0,
                totalNewMerchant: 0
            }
        };

        JournalMarche.setId(0);
        JournalMarche.setLabel(null);
        JournalMarche.setDate(null);
        JournalMarche.setSession();
    }

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/home/welcome/admin`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    render() {
        const {
            classes,
            upcomingMarkets,
            stopedActivity,
            lateBills,
            todayNews,
            markets
        } = this.state;

        return (
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    <Grid container direction="row" justify="space-between" spacing={4}>
                        <Grid item xs={12} sm={12} md={6}>
                            <UpcomingMarkets data={upcomingMarkets} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TodayNews data={todayNews} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="row" justify="space-between" spacing={4}>
                        <Grid item xs={12} sm={12} md={6}>
                            <StopedActivity data={stopedActivity} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <LateBills data={lateBills} />
                        </Grid>
                    </Grid>
                </Grid>
                {markets.count > 0 && (
                    <React.Fragment>
                        <Grid item style={{ marginTop: 40 }}>
                            <Typography gutterBottom color="primary" variant="h3">
                                Les marchés du jour ({markets.count})
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                spacing={4}
                                justify="center"
                                alignItems="center"
                                direction="row">
                                {markets.list.map(journalmarche => (
                                    <Grid item>
                                        {
                                            <Overview
                                                jmid={journalmarche.marche.id}
                                                data={journalmarche}
                                            />
                                        }
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        );
    }
}

export default withStyles(styles)(Placier);
