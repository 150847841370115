export default [
    { title: 'Banque Populaire' },
    { title: 'BNP Paribas' },
    { title: 'Boursorama Banque' },
    { title: "Caisse d'Epargne" },
    { title: 'CIC' },
    { title: 'Crédit Agricole' },
    { title: 'Crédit du Nord' },
    { title: 'Crédit Mutuel' },
    { title: 'Crédit Maritime' },
    { title: 'Crédit Mutuel Arkéa' },
    { title: 'La Banque Postale' },
    { title: 'Le Crédit Lyonnais' },
    { title: 'Natixis' },
    { title: 'Société Générale' },
    { title: 'Autre' }
];
