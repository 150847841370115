import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import NumberFormat from 'react-number-format';
import {
    checkIntervalsBillPackage,
    checkOneIntervalsBillPackage,
    isIntervalsEqualToOneBillPackage
} from 'common/validators';

import {
    Grid,
    TextField,
    InputAdornment,
    Typography,
    Divider,
    IconButton,
    Tooltip,
    Button,
    Avatar
} from '@material-ui/core';

import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
    head: {
        background: 'rgb(192,226,251,0.35)',
        borderBottom: '1px solid #c0e2fb'
    },
    cellKabis: {
        borderLeft: '7px solid #c0e2fb'
    }
}));

function CustomTextField(props) {
    const { inputRef, label, onChange, name, helperText, startIcon, ...other } = props;

    return (
        <TextField
            {...other}
            onChange={onChange}
            variant="outlined"
            fullWidth
            label={label}
            helperText={helperText}
            InputProps={{
                startAdornment: <InputAdornment position="start">{startIcon}</InputAdornment>,
                endAdornment: (
                    <InputAdornment position="end">
                        <EuroSymbolIcon />
                    </InputAdornment>
                )
            }}
        />
    );
}

const Billing = props => {
    const { className, days, updateData, data, noPadding, titleColor, node, ...rest } = props;

    const classes = useStyles();

    const handleData = ({ target: { name, value } }) => {
        updateData(node, { ...data, [name]: value });
    };

    const handleInterval = ({ target: { name, value } }, nodeid) => {
        data.intervals.map(val => {
            if (val.id === nodeid) {
                if (name === 'min' || name === 'max') {
                    const re = /^[0-9\b]+$/;
                    if (value === '' || re.test(value)) {
                        if (parseInt(value) > 0 && parseInt(value) < 41) {
                            value = value.slice(0, 2);
                        } else {
                            value = value.slice(0, -1);
                        }
                    } else {
                        value = value.slice(0, -1);
                    }
                }

                val[name] = value;
            }
        });

        updateData(node, {
            ...data,
            intervals: data.intervals
        });
    };

    const addInterval = () => {
        const rows = [...data.intervals];
        let last = rows.slice(-1);

        if (last.length > 0) {
            last = last[0];
        } else {
            last = {
                min: 1,
                max: 40,
                amount: 2,
                electricity: 0,
                water: 0,
                isValid: true
            };
        }

        rows.push({
            id: Math.max(...data.intervals.map(o => parseInt(o.id))) + 1,
            min: parseInt(last.max === 40 ? 39 : last.max) + 1,
            max: parseInt(last.max === 40 ? 39 : last.max) + 1,
            amount: last.amount,
            electricity: last.electricity,
            water: last.water,
            isValid: true
        });

        updateData(node, {
            ...data,
            intervals: rows
        });
    };

    const removeInterval = _id => {
        if (data.intervals.length > 1) {
            updateData(node, {
                ...data,
                intervals: data.intervals
                    .filter(val => val.id !== _id)
                    .sort((a, b) => parseInt(a.min) - parseInt(b.min))
            });
        }
    };

    const intervalsAreValids = () => {
        const rows = [...data.intervals];
        rows.sort((a, b) => parseInt(a.min) - parseInt(b.min));

        if (rows.length > 1) {
            rows.map((r, index) => {
                if (index + 1 < rows.length) {
                    r.isValid =
                        checkOneIntervalsBillPackage(r, rows[index + 1]) &&
                        isIntervalsEqualToOneBillPackage(r, rows[index + 1]);
                }

                if (index + 1 === rows.length) {
                    r.isValid =
                        checkOneIntervalsBillPackage(rows[index - 1], r) &&
                        isIntervalsEqualToOneBillPackage(rows[index - 1], r) &&
                        !(parseInt(r.min) > parseInt(r.max));
                }
            });
        }

        alert(
            checkIntervalsBillPackage(rows) === false && rows.some(r => r.isValid === false)
                ? 'Il y a des erreurs dans les forfaits.'
                : 'Tous les forfaits sont corrects.'
        );

        updateData(node, {
            ...data,
            intervals: rows
        });
    };

    return (
        <Grid container direction="column" spacing={6} style={noPadding ? {} : { padding: 48 }}>
            {data.billPackage ? (
                <>
                    <Grid item>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            justifyContent="space-between"
                                            spacing={2}>
                                            <Grid item>
                                                <Typography
                                                    variant="h4"
                                                    color="primary"
                                                    style={titleColor ? { color: titleColor } : {}}>
                                                    Forfait abonné <SquareFootIcon />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" spacing={2}>
                                    {data.intervals.map((row, index) => (
                                        <>
                                            <Grid item key={row.id}>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    justify="space-between"
                                                    spacing={2}
                                                    alignItems="center">
                                                    <Grid item xs={1}>
                                                        <Avatar
                                                            size="small"
                                                            color="primary"
                                                            style={{
                                                                width: 35,
                                                                height: 35,
                                                                backgroundColor: !row.isValid
                                                                    ? 'red'
                                                                    : '#3f51b5'
                                                            }}>
                                                            <Typography
                                                                variant="body1"
                                                                style={{ color: 'white' }}>
                                                                {index + 1}
                                                            </Typography>
                                                        </Avatar>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="flex-start"
                                                            justify="flex-start"
                                                            spacing={2}>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    name="min"
                                                                    nodeid={row.id}
                                                                    onChange={e =>
                                                                        handleInterval(e, row.id)
                                                                    }
                                                                    value={row.min}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    error={!row.isValid}
                                                                    label={'Minimum'}
                                                                    //helperText={'Minimum 1 mètre'}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <TextField
                                                                    name="max"
                                                                    nodeid={row.id}
                                                                    error={!row.isValid}
                                                                    onChange={e =>
                                                                        handleInterval(e, row.id)
                                                                    }
                                                                    value={row.max}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    label={'Maximum'}
                                                                    //helperText={'Maximum 40 mètres'}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <NumberFormat
                                                                    error={!row.isValid}
                                                                    value={row.electricity}
                                                                    startIcon={
                                                                        <FlashOnIcon
                                                                            style={{
                                                                                color: 'gold'
                                                                            }}
                                                                        />
                                                                    }
                                                                    name="electricity"
                                                                    label="Electricité"
                                                                    //helperText="Saisir un forfait électricité"
                                                                    customInput={CustomTextField}
                                                                    onValueChange={values => {
                                                                        handleInterval(
                                                                            {
                                                                                target: {
                                                                                    name:
                                                                                        'electricity',
                                                                                    value:
                                                                                        values.value
                                                                                }
                                                                            },
                                                                            row.id
                                                                        );
                                                                    }}
                                                                    thousandsGroupStyle="fr"
                                                                    thousandSeparator={' '}
                                                                    decimalSeparator={','}
                                                                    type={'text'}
                                                                    allowedDecimalSeparators={[
                                                                        ',',
                                                                        '.',
                                                                        '?',
                                                                        ';'
                                                                    ]}
                                                                    allowNegative={false}
                                                                    decimalScale={2}
                                                                    isNumericString
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <NumberFormat
                                                                    value={row.amount}
                                                                    error={!row.isValid}
                                                                    startIcon={
                                                                        <PersonOutlineIcon />
                                                                    }
                                                                    name="amount"
                                                                    label="Forfait"
                                                                    //helperText="Saisir un forfait"
                                                                    customInput={CustomTextField}
                                                                    onValueChange={values => {
                                                                        handleInterval(
                                                                            {
                                                                                target: {
                                                                                    name: 'amount',
                                                                                    value:
                                                                                        values.value
                                                                                }
                                                                            },
                                                                            row.id
                                                                        );
                                                                    }}
                                                                    thousandsGroupStyle="fr"
                                                                    thousandSeparator={' '}
                                                                    decimalSeparator={','}
                                                                    type={'text'}
                                                                    allowedDecimalSeparators={[
                                                                        ',',
                                                                        '.',
                                                                        '?',
                                                                        ';'
                                                                    ]}
                                                                    allowNegative={false}
                                                                    decimalScale={2}
                                                                    isNumericString
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Tooltip
                                                            disableHoverListener={
                                                                data.intervals.length === 1
                                                            }
                                                            title="Supprimer le forfait"
                                                            onClick={() => removeInterval(row.id)}>
                                                            <IconButton
                                                                disabled={
                                                                    data.intervals.length === 1
                                                                }>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {data.intervals?.length > 1 &&
                                            index + 1 !== data.intervals?.length ? (
                                                <Grid item>
                                                    <Divider />
                                                </Grid>
                                            ) : null}
                                        </>
                                    ))}

                                    <Grid item style={{ paddingTop: 30 }}>
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={1}
                                            justifyContent="flex-end"
                                            justify="flex-end">
                                            <Grid item>
                                                <Button
                                                    size="small"
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={() => intervalsAreValids()}>
                                                    Vérifier et ordonnner les forfaits
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    size="small"
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={() => addInterval()}
                                                    endIcon={<AddIcon />}>
                                                    Ajouter un forfait
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item>
                                        <Typography
                                            variant="h4"
                                            color="primary"
                                            style={titleColor ? { color: titleColor } : {}}>
                                            Facturation du mètre linéaire non abonné{' '}
                                            <SquareFootIcon />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <NumberFormat
                                            value={data.noneSubscriber}
                                            startIcon={<PersonOutlineIcon />}
                                            name="noneSubscriber"
                                            label="Prix du mètre linéaire pour les non abonnés"
                                            helperText="Saisir un prix unitaire pour le mètre linéaire"
                                            customInput={CustomTextField}
                                            onValueChange={values => {
                                                handleData({
                                                    target: {
                                                        name: 'noneSubscriber',
                                                        value: values.value
                                                    }
                                                });
                                            }}
                                            thousandsGroupStyle="fr"
                                            thousandSeparator={' '}
                                            decimalSeparator={','}
                                            type={'text'}
                                            allowedDecimalSeparators={[',', '.', '?', ';']}
                                            allowNegative={false}
                                            decimalScale={2}
                                            isNumericString
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid item>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography
                                        variant="h4"
                                        color="primary"
                                        style={titleColor ? { color: titleColor } : {}}>
                                        Facturation du mètre linéaire <SquareFootIcon />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={3}>
                                <Grid item sm={6} xs={12}>
                                    <NumberFormat
                                        value={data.subscriber}
                                        startIcon={<PersonIcon />}
                                        name="subscriber"
                                        label="Prix du mètre linéaire pour les abonnés"
                                        helperText="Saisir un prix unitaire pour le mètre linéaire"
                                        customInput={CustomTextField}
                                        onValueChange={values => {
                                            handleData({
                                                target: {
                                                    name: 'subscriber',
                                                    value: values.value
                                                }
                                            });
                                        }}
                                        thousandsGroupStyle="fr"
                                        thousandSeparator={' '}
                                        decimalSeparator={','}
                                        type={'text'}
                                        allowedDecimalSeparators={[',', '.', '?', ';']}
                                        allowNegative={false}
                                        decimalScale={2}
                                        isNumericString
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <NumberFormat
                                        value={data.noneSubscriber}
                                        startIcon={<PersonOutlineIcon />}
                                        name="noneSubscriber"
                                        label="Prix du mètre linéaire pour les non abonnés"
                                        helperText="Saisir un prix unitaire pour le mètre linéaire"
                                        customInput={CustomTextField}
                                        onValueChange={values => {
                                            handleData({
                                                target: {
                                                    name: 'noneSubscriber',
                                                    value: values.value
                                                }
                                            });
                                        }}
                                        thousandsGroupStyle="fr"
                                        thousandSeparator={' '}
                                        decimalSeparator={','}
                                        type={'text'}
                                        allowedDecimalSeparators={[',', '.', '?', ';']}
                                        allowNegative={false}
                                        decimalScale={2}
                                        isNumericString
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {!data.billPackage ? (
                <Grid item>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        alignItems="flex-end">
                                        <Grid item>
                                            <Typography variant="h4" color="primary">
                                                Facturation complémentaire abonné{' '}
                                                <PlaylistAddIcon />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1" color="primary">
                                                (facultatif)
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={3}>
                                <Grid item sm={6} xs={12}>
                                    <NumberFormat
                                        value={data.water}
                                        startIcon={
                                            <InvertColorsIcon style={{ color: 'dodgerblue' }} />
                                        }
                                        name="water"
                                        label="Tarif eau"
                                        helperText="Saisir un tarif pour l'eau"
                                        customInput={CustomTextField}
                                        onValueChange={values => {
                                            handleData({
                                                target: {
                                                    name: 'water',
                                                    value: values.value
                                                }
                                            });
                                        }}
                                        thousandsGroupStyle="fr"
                                        thousandSeparator={' '}
                                        decimalSeparator={','}
                                        type={'text'}
                                        allowedDecimalSeparators={[',', '.', '?', ';']}
                                        allowNegative={false}
                                        decimalScale={2}
                                        isNumericString
                                    />
                                </Grid>

                                <Grid item sm={6} xs={12}>
                                    <NumberFormat
                                        value={data.electricity}
                                        startIcon={<FlashOnIcon style={{ color: 'gold' }} />}
                                        name="electricity"
                                        label="Tarif électricité"
                                        helperText="Saisir un tarif pour l'électricité"
                                        customInput={CustomTextField}
                                        onValueChange={values => {
                                            handleData({
                                                target: {
                                                    name: 'electricity',
                                                    value: values.value
                                                }
                                            });
                                        }}
                                        thousandsGroupStyle="fr"
                                        thousandSeparator={' '}
                                        decimalSeparator={','}
                                        type={'text'}
                                        allowedDecimalSeparators={[',', '.', '?', ';']}
                                        allowNegative={false}
                                        decimalScale={2}
                                        isNumericString
                                    />
                                </Grid>
                                {/*<Grid item sm={4} xs={12}>
                                <NumberFormat
                                    value={data.truck}
                                    startIcon={<LocalShippingIcon />}
                                    name="truck"
                                    label="Tarif véhicule"
                                    helperText="Saisir un tarif pour le véhicule"
                                    customInput={CustomTextField}
                                    onValueChange={(values) => {
                                        handleData({
                                            target: {
                                                name: "truck",
                                                value: values.value,
                                            },
                                        });
                                    }}
                                    thousandsGroupStyle="fr"
                                    thousandSeparator={" "}
                                    decimalSeparator={","}
                                    type={'text'}
                                    allowedDecimalSeparators={[",", ".", "?", ";"]}
                                    allowNegative={false}
                                    decimalScale={2}
                                    isNumericString
                                />
                                </Grid>*/}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}

            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container spacing={1} direction="row" alignItems="flex-end">
                                    <Grid item>
                                        <Typography variant="h4" color="primary">
                                            Facturation complémentaire non abonné{' '}
                                            <PlaylistAddIcon />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" color="primary">
                                            (facultatif)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3}>
                            <Grid item sm={6} xs={12}>
                                <NumberFormat
                                    value={data.noneSubscriberWater}
                                    startIcon={<InvertColorsIcon style={{ color: 'dodgerblue' }} />}
                                    name="noneSubscriberWater"
                                    label="Tarif eau"
                                    helperText="Saisir un tarif pour l'eau"
                                    customInput={CustomTextField}
                                    onValueChange={values => {
                                        handleData({
                                            target: {
                                                name: 'noneSubscriberWater',
                                                value: values.value
                                            }
                                        });
                                    }}
                                    thousandsGroupStyle="fr"
                                    thousandSeparator={' '}
                                    decimalSeparator={','}
                                    type={'text'}
                                    allowedDecimalSeparators={[',', '.', '?', ';']}
                                    allowNegative={false}
                                    decimalScale={2}
                                    isNumericString
                                />
                            </Grid>

                            <Grid item sm={6} xs={12}>
                                <NumberFormat
                                    value={data.noneSubscriberElectricity}
                                    startIcon={<FlashOnIcon style={{ color: 'gold' }} />}
                                    name="noneSubscriberElectricity"
                                    label="Tarif électricité"
                                    helperText="Saisir un tarif pour l'électricité"
                                    customInput={CustomTextField}
                                    onValueChange={values => {
                                        handleData({
                                            target: {
                                                name: 'noneSubscriberElectricity',
                                                value: values.value
                                            }
                                        });
                                    }}
                                    thousandsGroupStyle="fr"
                                    thousandSeparator={' '}
                                    decimalSeparator={','}
                                    type={'text'}
                                    allowedDecimalSeparators={[',', '.', '?', ';']}
                                    allowNegative={false}
                                    decimalScale={2}
                                    isNumericString
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Billing;
