import env from 'common/env';
import UserProfile from 'UserProfile';

/**
 *
 * @param {Array} payload
 * @returns
 */
export async function createTerrace(payload) {
    const formData = new FormData();
    formData.append('ref', payload.ref);
    formData.append('enseigne', payload.enseigne);
    formData.append('activite', payload.activite);
    formData.append('adresse', payload.adresse);
    formData.append('prixUnitaire', payload.prixUnitaire);
    formData.append('qte', payload.qte);
    formData.append('total', payload.total);
    formData.append('info', payload.info);

    const result = await fetch(`${env}/terrace`, {
        method: 'PUT',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la mise à jour';
        });

    return result;
}

/**
 *
 * @returns
 */
export async function getTerraces(payload) {
    const formData = new FormData();
    formData.append('search', payload.searchValue);
    formData.append('order', payload.order);
    formData.append('orderBy', payload.orderBy);
    formData.append('page', payload.page);

    const result = await fetch(`${env}/terrace/`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @returns
 */
export async function getTerrace(id) {
    const result = await fetch(`${env}/terrace/${id}`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @returns
 */
export async function getLastTerrace() {
    const result = await fetch(`${env}/terrace/last`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @param {*} payload
 * @returns
 */
export async function printTerrace(id) {}

/**
 *
 * @param {*} id
 * @param {*} payload
 * @returns
 */
export async function getBills(id, payload) {
    const formData = new FormData();
    formData.append('search', payload.searchValue);
    formData.append('order', payload.order);
    formData.append('orderBy', payload.orderBy);
    formData.append('page', payload.page);

    const result = await fetch(`${env}/terrace/${id}/bills`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @param {*} id
 * @param {*} payload
 * @returns
 */
export function getPdfPreview(id, payload) {
    const token = UserProfile.getToken();
    return `${env}/terrace/${id}/preview?token=${token}&payload=${btoa(JSON.stringify(payload))}`;
}

/**
 *
 * @param {*} id
 * @param {*} payload
 * @returns
 */
export async function createBill(id, payload) {
    const formData = new FormData();
    formData.append('payload', btoa(JSON.stringify(payload)));

    const result = await fetch(`${env}/terrace/${id}/create-bill`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la création de la facture';
        });

    return result;
}

/**
 *
 * @param {*} id
 * @param {*} billid
 * @returns
 */
export async function getBill(id, billId) {
    const result = await fetch(`${env}/terrace/${id}/bill/${billId}`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @param {*} id
 * @param {*} payload
 * @returns
 */
export async function editBill(id, billId, payload) {
    const formData = new FormData();
    formData.append('payload', btoa(JSON.stringify(payload)));

    const result = await fetch(`${env}/terrace/${id}/edit-bill/${billId}`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la modification de la facture';
        });

    return result;
}

/**
 *
 * @param {*} id
 * @param {*} billid
 * @returns
 */
export async function deleteBill(id, billId) {
    const result = await fetch(`${env}/terrace/${id}/delete-bill/${billId}`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la suppression des données';
        });

    return result;
}

/**
 *
 * @param {*} id
 * @param {*} billId
 * @returns
 */
export function getBillPdf(id, billId) {
    const token = UserProfile.getToken();
    return `${env}/terrace/${id}/print-bill/${billId}?token=${token}`;
}

/**
 *
 * @param {*} id
 * @returns
 */
export function getTerracePdf(id, billId) {
    const token = UserProfile.getToken();
    return `${env}/terrace/${id}/print?token=${token}`;
}

/**
 *
 * @returns
 */
export async function getPaymentMethods() {
    const result = await fetch(`${env}/regie/get-means-of-payment`, {
        method: 'GET'
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw "Une erreur est survenue lors de l'affichage des moyens de paiement.";
        });

    return result;
}

/**
 *
 * @param {*} recipeId
 * @param {*} payload
 * @returns
 */
export async function addRecipeBill(recipeId, payload) {
    const formData = new FormData();
    formData.append('label', payload.label);
    formData.append('payment', payload.payment);
    formData.append('bank', payload.bank?.title);
    formData.append('bankCheck', payload.bankCheck);
    formData.append('amount', payload.amount);

    const result = await fetch(`${env}/terrace/${recipeId}/add-recipe`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la modification de la facture';
        });

    return result;
}

/**
 *
 * @param {*} id
 * @param {*} payload
 * @returns
 */
export async function getBillRecipes(id, payload) {
    const formData = new FormData();
    formData.append('search', '');
    formData.append('order', payload.order);
    formData.append('orderBy', payload.orderBy);
    formData.append('page', payload.page);

    const result = await fetch(`${env}/terrace/${id}/recipes`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @param {*} billRecipeId
 * @returns
 */
export async function deleteBillRecipe(billRecipeId) {
    const result = await fetch(`${env}/terrace/${billRecipeId}/delete-recipe`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la suppression des données';
        });

    return result;
}
