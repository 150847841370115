import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    TextField,
    MenuItem,
    FormControl,
    FormControlLabel,
    Switch
} from '@material-ui/core';

import { Adresse } from './components';

const useStyles = makeStyles(theme => ({
    head: {
        background: 'rgb(192,226,251,0.35)',
        borderBottom: '1px solid #c0e2fb'
    },
    cellKabis: {
        borderLeft: '7px solid #c0e2fb'
    }
}));

const types = [
    {
        id: 1,
        label: 'Sectorisé'
    },
    {
        id: 0,
        label: 'Non sectorisé'
    }
];

const placementsMode = [
    {
        id: 0,
        label: 'Assiduité'
    },
    {
        id: 1,
        label: 'Tirage au sort'
    }
];

const Caracteristic = props => {
    const { className, edit, days, updateData, data, node, noPadding, ...rest } = props;

    const classes = useStyles();

    const handleData = ({ target: { name, value } }) => {
        updateData(node, { ...data, [name]: value });
    };

    return (
        <Grid container direction="row" spacing={3} style={noPadding ? {} : { padding: 48 }}>
            {edit ? (
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={data.active}
                                onChange={event => {
                                    handleData({
                                        target: {
                                            name: event.target.name,
                                            value: event.target.checked
                                        }
                                    });
                                }}
                                name="active"
                                color="primary"
                            />
                        }
                        label={`Marché ${data.active ? 'activé' : 'désactivé'}`}
                    />
                </Grid>
            ) : null}
            <Grid item sm={6} xs={12}>
                <TextField
                    fullWidth
                    name="label"
                    type="text"
                    label="Libellé du marché"
                    onChange={handleData}
                    value={data.label}
                    variant="outlined"
                    helperText="Saisir un libellé"
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <Adresse selectedAdresse={data.adresse} setAdresse={handleData} />
            </Grid>
            <Grid item sm={6} xs={12}>
                <TextField
                    fullWidth
                    name="day"
                    label="Journée de marché"
                    select
                    onChange={handleData}
                    value={data.day}
                    variant="outlined"
                    helperText="Choisir une journée de marché">
                    {days.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            {/*<Grid item sm={6} xs={12}>
                <TextField
                    fullWidth
                    name="type"
                    label="Type du marché"
                    select
                    onChange={handleData}
                    value={data.type}
                    variant="outlined"
                    helperText="Choisir le type de marché">
                    {types.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                    </Grid>*/}
            <Grid item sm={6} xs={12}>
                <TextField
                    fullWidth
                    label="Mode de placement"
                    name="placementMode"
                    select
                    onChange={handleData}
                    value={data.placementMode}
                    variant="outlined"
                    helperText="Choisir le mode de placement du marché">
                    {placementsMode.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            {/*<Grid item sm={6} xs={12}>
                <TextField
                    fullWidth
                    label="Placement de commerçants bloqués"
                    name="allowBlocked"
                    select
                    onChange={handleData}
                    value={data.allowBlocked}
                    variant="outlined"
                //helperText="Choisir le mode de placement du marché"
                >
                    <MenuItem key={1} value={1}>
                        Autorisé
                    </MenuItem>
                    <MenuItem key={0} value={0}>
                        Non autorisé
                    </MenuItem>
                </TextField>
                </Grid>*/}
        </Grid>
    );
};

export default Caracteristic;
