import React from 'react';
import { withStyles } from '@material-ui/styles';
import { TotalUsers } from './components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';

import {
    TableCell,
    Table,
    TableHead,
    TableBody,
    Button,
    TableRow,
    TableContainer,
    Paper,
    Grid,
    Divider,
    IconButton,
    Typography
} from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    container: {
        width: '100%',
        padding: theme.spacing(0.5)
    },
    paper: {
        padding: '32px 48px 32px 48px'
    },
    paperHeader: {
        padding: 10
    }
});

const columns = [
    {
        id: 'username',
        label: "Nom d'utilisateur",
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'role',
        label: 'Rôle',
        minWidth: '30%',
        align: 'left'
    },
    {
        id: 'status',
        label: 'Statut',
        minWidth: '30%',
        align: 'center'
    },
    {
        id: 'last_login',
        label: 'Dernière connexion',
        minWidth: '30%',
        align: 'center'
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: '30%',
        align: 'center'
    }
];

class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            list: [],
            config: {
                nombre: 0,
                last: ''
            }
        };
    }

    componentDidMount = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/user`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                this.setState({
                    list: data
                });
            })
            .catch(console.log);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/administrateur/settings`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                this.setState({
                    config: {
                        nombre:
                            data.administrateur_settings.les_utilisateurs
                                .nombre_utilisateurs_enregistres,
                        last:
                            data.administrateur_settings.les_utilisateurs
                                .dernier_utilisateur_enregistre
                    }
                });
            })
            .catch(console.log);
    };

    render() {
        const { classes, list } = this.state;

        return (
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <TotalUsers data={this.state.config} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <div className={classes.paperHeader}>
                                <Typography variant="h5">
                                    <IconButton
                                        component={RouterLink}
                                        to="/settings/"
                                        type="button">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    Liste des utilisateurs
                                </Typography>
                            </div>
                            <Divider />

                            <div className={classes.paper}>
                                <TableContainer className={classes.container}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map(column => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}>
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {list.map(user => {
                                                return (
                                                    <TableRow hover key={user.id}>
                                                        {columns.map(column => {
                                                            if (column.id != 'action') {
                                                                return (
                                                                    <TableCell
                                                                        key={user.id + column.id}
                                                                        align={column.align}>
                                                                        {user[column.id]}
                                                                    </TableCell>
                                                                );
                                                            }
                                                        })}
                                                        <TableCell key={user.id} align={'center'}>
                                                            <Button
                                                                component={RouterLink}
                                                                to={'users/edit/' + user.id}
                                                                variant="outlined"
                                                                color="primary">
                                                                Mettre à jour
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(User);
