import React from 'react';
import { withStyles } from '@material-ui/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import GroupIcon from '@material-ui/icons/Group';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { Link as RouterLink } from 'react-router-dom';
import JournalMarche from '../../JournalMarche';

import {
    Avatar,
    Paper,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Typography,
    ListItemText,
    Button,
    Menu,
    MenuItem,
    Card,
    CardContent,
    CardActionArea,
    TextField
} from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    table: {
        maxHeight: 300,
        borderRight: '1px solid #eee',
        borderLeft: '1px solid #eee'
    },
    avatar: {
        backgroundColor: 'green',
        height: 48,
        width: 48
    },
    title: {
        //flex: '1 1 100%',
    },
    content: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    subtitle: {
        padding: '32px 0 0 16px'
    }
});

class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            search: '',
            filterLabel: 't',
            anchorEl: null,
            titulaire: {
                all: 0,
                present: 0
            },
            passager: {
                all: 0,
                present: 0
            },
            list: {
                present: [],
                absent: []
            }
        };
    }

    componentDidMount() {
        this.update('t');
    }

    update = type => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/registration/${JournalMarche.getId()}/get/${type}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data

                this.setState({
                    titulaire: {
                        all: data.titulaire.total_all,
                        present: data.titulaire.total_present
                    },
                    passager: {
                        all: data.passager.total_all,
                        present: data.passager.total_present
                    },
                    list: {
                        present: data.marchand.present,
                        absent: data.marchand.absent
                    }
                });
            })
            .catch(console.log);
    };

    handleFilterOpen = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleFilterClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    handleFilter = label => {
        this.setState({
            filterLabel: label,
            anchorEl: null
        });

        this.update(label);
    };

    handleSearch = ({ target: { value } }) => {
        const { filterLabel } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/registration/${JournalMarche.getId()}/get/${filterLabel}?search=${value}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data

                this.setState({
                    search: value,
                    list: {
                        present: data.marchand.present,
                        absent: data.marchand.absent
                    }
                });
            })
            .catch(console.log);
    };

    setInscription = (status, id) => {
        const { filterLabel } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/registration/${JournalMarche.getId()}/update?status=${status}&id=${id}&type=${filterLabel}`
        ).catch(console.log);

        this.update(filterLabel);
    };

    formatText(text, end) {
        if (text == '' || text == null) {
            return '';
        }

        let length = text.length;
        let textFormated = text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);

        if (length > end) {
            textFormated = textFormated.substring(0, end) + '...';
        }

        return textFormated;
    }

    render() {
        const { classes, search, filterLabel, anchorEl, passager, titulaire, list } = this.state;

        return (
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item xs={6} sm={6} lg={6}>
                        <CardActionArea onClick={() => this.handleFilter('t')}>
                            <Card elevation={filterLabel == 't' ? 3 : 1}>
                                <CardContent style={{ paddingBottom: 16 }}>
                                    <Grid container justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                //className={classes.title}
                                                gutterBottom
                                                variant="body2">
                                                Titulaires présents
                                            </Typography>
                                            <Grid
                                                container
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="flex-end"
                                                spacing={1}>
                                                <Grid item>
                                                    <Typography variant="h3">
                                                        {titulaire.present}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        / {titulaire.all}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Avatar className={classes.avatar}>
                                                <AssignmentTurnedInIcon className={classes.icon} />
                                            </Avatar>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={6}>
                        <CardActionArea onClick={() => this.handleFilter('p')}>
                            <Card elevation={filterLabel == 'p' ? 3 : 1}>
                                <CardContent style={{ paddingBottom: 16 }}>
                                    <Grid container justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                //className={classes.title}
                                                gutterBottom
                                                variant="body2">
                                                Passagers présents
                                            </Typography>
                                            <Grid
                                                container
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="flex-end"
                                                spacing={1}>
                                                <Grid item>
                                                    <Typography variant="h3">
                                                        {passager.present}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Avatar className={classes.avatar}>
                                                <GroupIcon className={classes.icon} />
                                            </Avatar>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.content}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} style={{ padding: '0 0 10px 0' }}>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center">
                                        <Grid item>
                                            <Typography
                                                style={{ textTransform: 'uppercase' }}
                                                variant="h6">
                                                Total{' '}
                                                {parseInt(titulaire.present) +
                                                    parseInt(passager.present)}{' '}
                                                commerçants inscrits
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                component={RouterLink}
                                                color="primary"
                                                to={
                                                    '/dashboard/' +
                                                    JournalMarche.getId() +
                                                    '/placement'
                                                }>
                                                Placer les commerçants <ArrowForwardIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container justify="space-between" alignItems="flex-end">
                                        <Grid item>
                                            <Typography variant="h6" className={classes.title}>
                                                Présence{' '}
                                                {filterLabel == 't' ? 'titulaires' : 'passagers'}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                style={{ visibility: 'hidden' }}
                                                label="Rechercher un commerçant"
                                                type="text"
                                                value={search}
                                                onChange={this.handleSearch}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container justify="space-between" alignItems="flex-end">
                                        <Grid item>
                                            <Typography variant="h6" className={classes.title}>
                                                Absence{' '}
                                                {filterLabel == 't' ? 'titulaires' : 'passagers'}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label="Rechercher un commerçant"
                                                type="text"
                                                value={search}
                                                onChange={this.handleSearch}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TableContainer style={{ maxHeight: '60vh' }}>
                                        <Table stickyHeader className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            disabled={Boolean(filterLabel != 't')}
                                                            onClick={() =>
                                                                this.setInscription('0', 'all')
                                                            }
                                                            checked
                                                        />
                                                    </TableCell>
                                                    <TableCell padding="none" align="left">
                                                        Raison Sociale
                                                    </TableCell>
                                                    <TableCell align="left">Métier</TableCell>
                                                    <TableCell align="right">
                                                        Score / Inscrit à
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {list.present.map((marchand, index) => (
                                                    <TableRow
                                                        key={marchand.id}
                                                        style={{
                                                            backgroundColor:
                                                                index % 2 == 0
                                                                    ? 'rgb(0,0,0, 0.02)'
                                                                    : 'none'
                                                        }}>
                                                        <TableCell
                                                            alignt="center"
                                                            padding="checkbox">
                                                            <Checkbox
                                                                checked
                                                                onClick={() =>
                                                                    this.setInscription(
                                                                        '0',
                                                                        marchand.id
                                                                    )
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            padding="none"
                                                            align="left">
                                                            <ListItemText
                                                                primary={this.formatText(
                                                                    marchand.raisonSociale,
                                                                    13
                                                                )}
                                                                secondary={this.formatText(
                                                                    marchand.alias,
                                                                    15
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {this.formatText(
                                                                marchand.libelleMetier,
                                                                20
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            padding="none"
                                                            style={{ paddingRight: 16 }}
                                                            align="right">
                                                            <ListItemText
                                                                primary={
                                                                    <Typography variant="body1">
                                                                        {marchand.score}{' '}
                                                                        <sup>
                                                                            +
                                                                            {filterLabel != 't'
                                                                                ? 1
                                                                                : 2}
                                                                        </sup>
                                                                    </Typography>
                                                                }
                                                                secondary={marchand.dateMaj}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item xs={6}>
                                    <TableContainer style={{ maxHeight: '60vh' }}>
                                        <Table stickyHeader className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            onClick={() =>
                                                                this.setInscription('1', 'all')
                                                            }
                                                            checked={false}
                                                            disabled={Boolean(filterLabel != 't')}
                                                        />
                                                    </TableCell>
                                                    <TableCell padding="none" align="left">
                                                        Raison Sociale
                                                    </TableCell>
                                                    <TableCell align="left">Métier</TableCell>
                                                    <TableCell align="right">Score</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {list.absent.map((marchand, index) => (
                                                    <TableRow
                                                        key={marchand.raisonSociale}
                                                        style={{
                                                            backgroundColor:
                                                                index % 2 == 0
                                                                    ? 'rgb(0,0,0, 0.02)'
                                                                    : 'none'
                                                        }}>
                                                        <TableCell
                                                            alignt="center"
                                                            padding="checkbox">
                                                            <Checkbox
                                                                checked={false}
                                                                onClick={() =>
                                                                    this.setInscription(
                                                                        '1',
                                                                        marchand.id
                                                                    )
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            padding="none"
                                                            align="left">
                                                            <ListItemText
                                                                primary={this.formatText(
                                                                    marchand.raisonSociale,
                                                                    13
                                                                )}
                                                                secondary={this.formatText(
                                                                    marchand.alias,
                                                                    15
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {this.formatText(
                                                                marchand.libelleMetier,
                                                                20
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {marchand.score}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Registration);
