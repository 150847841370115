import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { combineStyles } from 'common/styles';
import { printBill, printBills } from 'common/pdf';
import { BillSender, BillCanceller, BillForm } from 'views';
import { SearchDateCard, SearchTextField, MuiTable } from 'components';
import { Email } from '../../../Merchants/components';

import { Grid, Paper, Divider, TableRow, TableCell, Typography } from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

import { StatusButton, ActionButton, DetailBillCard } from './components';

/**
 *
 */
const env = `https://www.${
    window.location.hostname.split('.')[1]
        ? window.location.hostname.split('.')[0] === 'www'
            ? window.location.hostname.split('.')[1]
            : window.location.hostname.split('.')[0]
        : 'dev'
}.api.digitmarche.fr/api`;

/**
 *
 * @param {*} start
 * @param {*} end
 * @param {*} searchValue
 * @param {*} page
 * @param {*} order
 * @param {*} orderBy
 * @param {*} filter
 * @param {*} type
 * @returns
 */
async function getBills(start, end, searchValue, page, order, orderBy, filter, type) {
    const formData = new FormData();
    formData.append('start', start);
    formData.append('end', end);
    formData.append('search', searchValue);
    formData.append('page', page);
    formData.append('order', order);
    formData.append('orderBy', orderBy);

    const result = await fetch(`${env}/regie/get-bills/${type}/${filter}`, {
        method: 'POST',
        body: formData
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => data)
        .catch(error => {
            console.error(error);
            throw `Echec de l'affichage des factures.`;
        });

    return result;
}

const styles = theme => ({
    root: {
        //padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    }
});

const headers = [
    { id: 'issueDate', align: 'left', label: 'Date', sortable: true },
    { id: 'bill', align: 'left', label: 'Facture', sortable: true },
    {
        id: 'socialReason',
        align: 'left',
        label: 'Raison sociale',
        sortable: true,
        style: { width: '25%' }
    },
    { id: 'status', align: 'center', label: 'Statut', sortable: false },
    { id: 'amount', align: 'right', label: 'Montant', sortable: true },
    { id: 'action', align: 'center', label: 'Actions', sortable: false }
];

/**
 *
 * @param {*} props
 * @returns
 */
const DEFAULT_STATE = props => ({
    ...props,
    type: props.type,
    filter: 'all',
    start: props.start || '',
    end: props.end || '',
    searchValue: '',
    isSubmited: Boolean(props.start),
    disableSearch: !Boolean(props.start),
    chart: [],
    order: 'asc',
    orderBy: headers[0].id,
    page: 0,
    count: 0,
    rows: [],
    loading: Boolean(props.start),
    billNumber: null,
    openDialog: '',
    importedMails: [],
    notify: ''
});

/**
 *
 * @param {*} classes
 * @returns
 */
const filters = classes => ({
    subscribers: [
        { label: 'tous', value: 'all' },
        {
            label: 'payée',
            value: 'paid',
            className: classes.paid
        },
        {
            label: 'facturée',
            value: 'invoiced',
            className: classes.unpaid
        },
        {
            label: 'en retard',
            value: 'late',
            className: classes.late
        } /*
        {
            label: 'annulée',
            value: 'canceled',
            className: classes.canceled
        }*/
    ],
    'non-subscribers': [
        { label: 'tous', value: 'all' },
        {
            label: 'payée',
            value: 'paid',
            className: classes.paid
        },
        {
            label: 'facturée',
            value: 'invoiced',
            className: classes.unpaid
        },
        {
            label: 'en retard',
            value: 'late',
            className: classes.late
        } /*
        {
            label: 'annulée',
            value: 'canceled',
            className: classes.canceled
        }*/
    ],
    late: [
        { label: 'tous', value: 'all' },
        {
            label: 'Titulaire abonné',
            value: 'ta',
            className: classes.ta
        },
        {
            label: 'Titulaire non abonné',
            value: 'tna',
            className: classes.tna
        },
        {
            label: 'Passager',
            value: 'p',
            className: classes.p
        }
    ],
    canceled: [
        { label: 'tous', value: 'all' },
        {
            label: 'Titulaire abonné',
            value: 'ta',
            className: classes.ta
        },
        {
            label: 'Titulaire non abonné',
            value: 'tna',
            className: classes.tna
        },
        {
            label: 'Passager',
            value: 'p',
            className: classes.p
        }
    ]
});

class SubscriberBills extends React.Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE(props);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            this.state.page != prevState.page ||
            this.state.order != prevState.order ||
            this.state.orderBy != prevState.orderBy ||
            this.state.filter != prevState.filter ||
            this.state.searchValue != prevState.searchValue
        ) {
            this.setState({
                loading: true
            });
        }

        // Changement de page
        if (prevProps.type != this.props.type) {
            this.setState(DEFAULT_STATE(this.props));
            this.setState({
                loading: true
            });
        }

        if (this.state.notify !== '' && this.state.notify != prevState.notify) {
            this.props.history.push(
                `/bills/${this.state.type}/${this.state.action}/${this.state.start}/${this.state.end}/${this.state.notify}`
            );
        }

        if (!this.props.notify && this.props.notify != prevProps.notify) {
            this.setState({ notify: '' });
        }

        if (this.props.start != prevProps.start || this.props.end != prevProps.end) {
            this.setState({
                start: this.props.start || '',
                end: this.props.end || '',
                loading: true
            });
        }

        if (this.state.isSubmited && this.state.isSubmited != prevState.isSubmited) {
            this.setState({
                loading: true
            });
        }

        if (this.state.loading && this.state.loading != prevState.loading) {
            this.getData();
        }
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        const { start, end, searchValue, type, page, order, orderBy, filter } = this.state;

        getBills(start, end, searchValue, page, order, orderBy, filter, type)
            .then(data =>
                this.setState({
                    ...data,
                    disableSearch: false,
                    isSubmited: false,
                    loading: false
                })
            )
            .catch(err => {
                alert(err);
                this.setState({
                    isSubmited: false,
                    loading: false
                });
            });
    };

    handleForm = () => {
        const { type, action, start, end } = this.state;

        this.setState({
            loading: true
        });

        this.props.history.push(`/bills/${type}/${action}/${start}/${end}`);
    };

    handleDialog = (openDialog, billNumber = null, importedMails = [], notify = '') => {
        this.setState({
            openDialog,
            billNumber,
            importedMails,
            notify
        });
    };

    // To remove

    handleCash = numFacture => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/get-bill/${numFacture}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    bill: data,
                    openBill: true
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    closeBill = () => {
        this.setState({
            bill: null,
            openBill: false
        });
    };

    sendData = (numFacture, moyen, banque = false, cheque = false) => {
        const formData = new FormData();
        formData.append('moyen', moyen);
        banque && formData.append('banque', banque);
        cheque && formData.append('cheque', cheque);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/update-bill/${numFacture}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                alert('Facture N°' + numFacture + ' réglée avec succès.');
                this.setState({
                    openBill: false,
                    bill: null
                });
                this.getData();
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    render() {
        const {
            classes,
            searchValue,
            disableSearch,
            chart,
            order,
            orderBy,
            page,
            count,
            rows,
            loading,
            billNumber,
            openDialog,
            filter,
            start,
            end,
            type,
            importedMails,
            notify,
            action
        } = this.state;

        return (
            <div className={classes.root}>
                <BillForm
                    setBillNumber={value => {
                        this.setState({ loading: true });
                        this.handleDialog('payment', value);
                    }}
                    billNumber={billNumber && openDialog === 'payment' ? billNumber : null}
                />

                <BillSender
                    setBillNumber={value => this.handleDialog('sender', value)}
                    billNumber={billNumber && openDialog === 'sender' ? billNumber : null}
                />

                <BillCanceller
                    update={this.getData}
                    setBillNumber={value => this.handleDialog('canceler', value)}
                    billNumber={billNumber && openDialog === 'canceler' ? billNumber : null}
                />

                <Email
                    selectedBillToNotify={billNumber}
                    importedMails={importedMails}
                    history={this.props.history}
                    notify={notify}
                    open={
                        billNumber && openDialog === 'notify' && this.props.notify === 'late_bill'
                    }
                />

                <Grid
                    spacing={3}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch">
                    <Grid item>
                        <Grid container direction="row" spacing={3} alignItems="stretch">
                            <Grid item xs={12} md={8}>
                                <DetailBillCard
                                    chart={chart}
                                    type={type}
                                    loading={Object.keys(chart).length === 0}
                                />
                            </Grid>
                            <Grid item xs md>
                                <SearchDateCard
                                    title="Recherche par date d'émission de facture"
                                    submit={this.handleForm}
                                    start={{
                                        set: val => this.setState({ start: val }),
                                        data: start
                                    }}
                                    end={{
                                        set: val => this.setState({ end: val }),
                                        data: end
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Paper className={classes.paperContent}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                spacing={3}>
                                <Grid item>
                                    <SearchTextField
                                        label={`Rechercher dans la période sélectionnée`}
                                        value={searchValue}
                                        disabled={disableSearch}
                                        onChange={value => {
                                            this.setState({
                                                searchValue: value,
                                                page: 0
                                            });
                                        }}
                                        helperText={
                                            'Saisir un numéro de facture, un SIREN/SIRET, une raison sociale ou une enseigne'
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <MuiTable
                                        // disabled
                                        refresh={() => this.setState({ loading: true })}
                                        print={() =>
                                            printBills(
                                                start,
                                                end,
                                                searchValue,
                                                order,
                                                orderBy,
                                                filter,
                                                type
                                            )
                                        }
                                        loading={loading}
                                        headers={headers}
                                        page={{
                                            set: val => this.setState({ page: val }),
                                            data: page,
                                            count: count
                                        }}
                                        order={{
                                            set: val => this.setState({ order: val }),
                                            data: order
                                        }}
                                        orderBy={{
                                            set: val => this.setState({ orderBy: val }),
                                            data: orderBy
                                        }}
                                        filter={[
                                            {
                                                set: val => this.setState({ filter: val }),
                                                sortLabel: 'statut',
                                                default: 'all',
                                                current: filter,
                                                rows: filters(classes)[type]
                                            }
                                        ]}>
                                        {rows.map((row, index) => (
                                            <TableRow hover key={`${index}-${row.billNumber}`}>
                                                <TableCell
                                                    align="left"
                                                    key={`issueDate-${index}-${row.billNumber}`}>
                                                    {row.issueDate}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    key={`billNumber-${index}-${row.billNumber}`}>
                                                    <div
                                                        style={{
                                                            display: 'inline-flex',
                                                            marginTop: 5
                                                        }}>
                                                        {row.status == 'ANN' ? (
                                                            <HighlightOffIcon
                                                                fontSize="small"
                                                                className={classes.canceled}
                                                                style={{ marginRight: 4 }}
                                                            />
                                                        ) : row.late == 'y' ? (
                                                            <HourglassEmptyIcon
                                                                fontSize="small"
                                                                className={classes.late}
                                                                style={{ marginRight: 4 }}
                                                            />
                                                        ) : row.paid == 'y' ? (
                                                            <CheckCircleIcon
                                                                fontSize="small"
                                                                className={classes.paid}
                                                                style={{ marginRight: 4 }}
                                                            />
                                                        ) : (
                                                            <DescriptionIcon
                                                                fontSize="small"
                                                                className={classes.unpaid}
                                                                style={{ marginRight: 4 }}
                                                            />
                                                        )}

                                                        {row.billNumber}
                                                    </div>
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    key={`socialReason-${index}-${row.billNumber}`}>
                                                    <Typography noWrap>
                                                        {row.socialReason}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    key={`status-${index}-${row.billNumber}`}>
                                                    <StatusButton
                                                        className={clsx({
                                                            [classes.paid]: Boolean(
                                                                row.paid == 'y' &&
                                                                    row.status != 'ANN' &&
                                                                    row.late != 'y'
                                                            ),
                                                            [classes.unpaid]: Boolean(
                                                                row.paid == 'n' &&
                                                                    row.status != 'ANN' &&
                                                                    row.late != 'y'
                                                            ),
                                                            [classes.late]: Boolean(
                                                                row.status != 'ANN' &&
                                                                    row.late == 'y'
                                                            ),
                                                            [classes.canceled]: Boolean(
                                                                row.status == 'ANN'
                                                            )
                                                        })}
                                                        pay={value =>
                                                            this.handleDialog('payment', value)
                                                        }
                                                        setBillCanceller={value =>
                                                            this.handleDialog('canceler', value)
                                                        }
                                                        bill={{
                                                            paid: Boolean(row.paid == 'y'),
                                                            number: row.billNumber,
                                                            canceled: Boolean(row.status == 'ANN'),
                                                            late: Boolean(row.late == 'y')
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                    key={`amount-${index}-${row.billNumber}`}>
                                                    <Typography
                                                        variant="button"
                                                        className={clsx({
                                                            [classes.paid]: Boolean(
                                                                row.paid == 'y' &&
                                                                    row.status != 'ANN' &&
                                                                    row.late != 'y'
                                                            ),
                                                            [classes.unpaid]: Boolean(
                                                                row.paid == 'n' &&
                                                                    row.status != 'ANN' &&
                                                                    row.late != 'y'
                                                            ),
                                                            [classes.late]: Boolean(
                                                                row.status != 'ANN' &&
                                                                    row.late == 'y'
                                                            ),
                                                            [classes.canceled]: Boolean(
                                                                row.status == 'ANN'
                                                            )
                                                        })}>
                                                        {row.amount} €
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    key={`action-${index}-${row.billNumber}`}>
                                                    <ActionButton
                                                        bill={{
                                                            paid: Boolean(row.paid == 'y'),
                                                            number: row.billNumber,
                                                            canceled: Boolean(row.status == 'ANN')
                                                        }}
                                                        view={() => printBill(row.billNumber)}
                                                        billSender={value => {
                                                            if (type !== 'late') {
                                                                this.handleDialog('sender', value);
                                                            } else {
                                                                this.handleDialog(
                                                                    'notify',
                                                                    value,
                                                                    [
                                                                        {
                                                                            id: row.merchantId,
                                                                            raisonSociale:
                                                                                row.socialReason
                                                                        }
                                                                    ],
                                                                    'late_bill'
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </MuiTable>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(combineStyles(styles))(SubscriberBills);
