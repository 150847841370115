import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Button,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Radio,
    RadioGroup,
    Table,
    TableHead,
    TableContainer,
    TableRow,
    TableCell,
    FormControlLabel,
    FormControl,
    FormLabel,
    Typography,
    TableBody
} from '@material-ui/core';


const styles = theme => ({
    root: {

    },
    labelFilter: {
        color: "#3f51b5",
        marginBottom: 5
    }
});



const columnsMerchant = [
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: "50%",
        align: 'left',
    },
    {
        id: 'numero',
        label: 'N°',
        minWidth: "25%",
        align: 'left',
    },
    {
        id: 'metres',
        label: 'Mètres',
        minWidth: "25%",
        align: 'left',
    },
];


const columnsFinance = [
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: "50%",
        align: 'left',
    },
    {
        id: 'facture',
        label: 'Facture',
        minWidth: "25%",
        align: 'center',
    },
    {
        id: 'montant',
        label: 'Montant',
        minWidth: "25%",
        align: 'left',
    },
];


class DialogList extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            ...props,
            list: []
        }
    }

    componentDidMount() {


    }

    render() {

        const { classes } = this.props;
        const { list } = this.state

        const { open, content } = this.props;

        const titleContent = content == "merchant" ? "Liste des commerçants" : "Les recettes du marché";

        return (

            <Dialog
                open={open}
                fullWidth
                maxWidth={"sm"}
            //onClose={handleClose}
            >
                <DialogTitle disableTypography><Typography variant="h5">{titleContent}</Typography></DialogTitle>
                <DialogContent>
                    {content == "merchant" &&
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" className={classes.labelFilter}>Type du commerçant</FormLabel>
                                    <RadioGroup row name="type" /*value={type} onChange={this.handleTypeFilter}*/>
                                        <FormControlLabel value="t" control={<Radio />} label="Titulaires" />
                                        <FormControlLabel value="p" control={<Radio />} label="Passagers" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <TableContainer className={classes.container}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    columnsMerchant.map(column => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                list.map(merchant => {
                                                    return (
                                                        <TableRow hover key={merchant.siret}>
                                                            {
                                                                columnsMerchant.map(column => {
                                                                    return (
                                                                        <TableCell key={merchant.id} align={column.align}>
                                                                            {merchant[column.id]}
                                                                        </TableCell>
                                                                    );
                                                                })
                                                            }
                                                        </TableRow>
                                                    );
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button color="primary">
                        Fermer
                </Button>
                </DialogActions>
            </Dialog>
        );

    }
}

export default withStyles(styles)(DialogList);
