import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Button,
    Grid,
    Avatar,
    Card,
    CardContent,
    Typography,
    Divider,
    CardActionArea
} from '@material-ui/core';
import JournalMarche from '../../JournalMarche';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import StorefrontIcon from '@material-ui/icons/Storefront';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EuroIcon from '@material-ui/icons/Euro';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: 'green',
        height: 48,
        width: 48
    },
    backButton: {
        backgroundColor: 'green',
        height: 60,
        width: 60
    },
    avatar1: {
        backgroundColor: theme.palette.primary.main,
        height: 48,
        width: 48
    },
    avatar2: {
        backgroundColor: theme.palette.primary.main,
        height: 48,
        width: 48
    },
    icon: {
        height: 26,
        width: 26
    },
    iconButton: {
        height: 36,
        width: 36
    },
    button: {
        color: theme.palette.primary.main,
        height: 48,
        width: 48
    }
});

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            titulaire: {
                all: 0,
                present: 0
            },
            placement: {
                all: 0,
                placed: 0
            },
            place: {
                all: 0,
                free: 0
            },
            metrage: {
                all: 0,
                free: 0
            },
            presence: {
                all: 0,
                marchand: []
            },
            placed: {
                all: 0,
                marchand: []
            }
        };
    }

    componentDidMount() {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/dashboard/${JournalMarche.getId()}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data

                this.setState({
                    titulaire: {
                        all: data.titulaires.total_all,
                        present: data.titulaires.total_present
                    },
                    placement: {
                        all: data.placement.total_all,
                        placed: data.placement.total_placed
                    },
                    place: {
                        all: data.place.total_all,
                        free: data.place.total_free
                    },
                    metrage: {
                        all: data.metrage.total_all,
                        free: data.metrage.total_free
                    },
                    presence: {
                        all: data.presence.total_all,
                        marchand: data.presence.marchand
                    },
                    placed: {
                        all: data.commercant_place.total_all,
                        marchand: data.commercant_place.marchand
                    }
                });
            })
            .catch(console.log);
    }

    formatText(text, end) {
        if (text == '' || text == null) {
            return '';
        }

        let length = text.length;
        let textFormated = text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);

        if (length > end) {
            textFormated = textFormated.substring(0, end) + '...';
        }

        return textFormated;
    }

    render() {
        const { classes, titulaire, placement, placed, metrage, presence, place } = this.state;

        return (
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item xs={6} sm={6} lg={3}>
                        <Card>
                            <CardContent style={{ paddingBottom: 16 }}>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography
                                            color="textSecondary"
                                            //className={classes.title}
                                            gutterBottom
                                            variant="body2">
                                            Titulaires placés
                                        </Typography>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="flex-end"
                                            spacing={1}>
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {titulaire.present}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    / {titulaire.all}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Avatar className={classes.avatar}>
                                            <AssignmentTurnedInIcon className={classes.icon} />
                                        </Avatar>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={3}>
                        <Card>
                            <CardContent style={{ paddingBottom: 16 }}>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography
                                            color="textSecondary"
                                            //className={classes.title}
                                            gutterBottom
                                            variant="body2">
                                            Passagers placés
                                        </Typography>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="flex-end"
                                            spacing={1}>
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {placement.placed}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    / {placement.all}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Avatar className={classes.avatar1}>
                                            <PersonPinCircleIcon
                                                className={classes.icon}
                                                style={{ width: 32, height: 32 }}
                                            />
                                        </Avatar>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={6} sm={6} lg={3}>
                        <Card>
                            <CardContent style={{ paddingBottom: 16 }}>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography
                                            color="textSecondary"
                                            //className={classes.title}
                                            gutterBottom
                                            variant="body2">
                                            Places disponibles
                                        </Typography>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="flex-end"
                                            spacing={1}>
                                            <Grid item>
                                                <Typography variant="h3">{place.free}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    / {place.all}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Avatar className={classes.avatar2}>
                                            <StorefrontIcon className={classes.icon} />
                                        </Avatar>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={6} sm={6} lg={3}>
                        <Card>
                            <CardContent style={{ paddingBottom: 16 }}>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography
                                            color="textSecondary"
                                            //className={classes.title}
                                            gutterBottom
                                            variant="body2">
                                            Métrage disponible
                                        </Typography>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="flex-end"
                                            spacing={1}>
                                            <Grid item>
                                                <Typography variant="h3">{metrage.free}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    / {metrage.all} mètres{' '}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Avatar className={classes.avatar2}>
                                            <SquareFootIcon className={classes.icon} />
                                        </Avatar>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container item justify="center" direction="row" spacing={4}>
                    <Grid item xs={12}>
                        <Typography
                            color="primary"
                            variant="h4"
                            style={{ paddingTop: 16, textTransform: 'uppercase' }}>
                            Inscription des commerçants
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardActionArea
                            component={RouterLink}
                            to={'/management/' + JournalMarche.getId() + '/registration/t'}>
                            <Card>
                                <CardContent>
                                    <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item>
                                            <Avatar className={classes.backButton}>
                                                <AssignmentTurnedInIcon
                                                    className={classes.iconButton}
                                                />
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            <Button color="primary">Inscrire les titulaires</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <CardActionArea
                            component={RouterLink}
                            to={'/management/' + JournalMarche.getId() + '/registration/p'}>
                            <Card>
                                <CardContent>
                                    <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item>
                                            <Avatar className={classes.backButton}>
                                                <AssignmentTurnedInIcon
                                                    className={classes.iconButton}
                                                />
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            <Button color="primary">Inscrire les passagers</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>
                </Grid>

                <Grid container item justify="center" direction="row" spacing={4}>
                    <Grid item xs={12}>
                        <Typography
                            color="primary"
                            variant="h4"
                            style={{ paddingTop: 16, textTransform: 'uppercase' }}>
                            Placement les commerçants
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardActionArea
                            component={RouterLink}
                            to={'/management/' + JournalMarche.getId() + '/placement/t'}>
                            <Card>
                                <CardContent>
                                    <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item>
                                            <Avatar
                                                className={classes.backButton}
                                                style={{ background: '#3f51b5' }}>
                                                <PersonPinCircleIcon
                                                    className={classes.iconButton}
                                                />
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            <Button color="primary">Placer les titulaires</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <CardActionArea
                            component={RouterLink}
                            to={'/management/' + JournalMarche.getId() + '/placement/p'}>
                            <Card>
                                <CardContent>
                                    <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item>
                                            <Avatar
                                                className={classes.backButton}
                                                style={{ background: '#3f51b5' }}>
                                                <PersonPinCircleIcon
                                                    className={classes.iconButton}
                                                />
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            <Button color="primary">Placer les passagers</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>
                </Grid>

                <Grid container item justify="center" direction="row" spacing={4}>
                    <Grid item xs={12}>
                        <Typography
                            color="primary"
                            variant="h4"
                            style={{ paddingTop: 16, textTransform: 'uppercase' }}>
                            Encaissement des commerçants
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CardActionArea
                            component={RouterLink}
                            to={'/management/' + JournalMarche.getId() + '/finance/t'}>
                            <Card>
                                <CardContent>
                                    <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item>
                                            <Avatar className={classes.backButton}>
                                                <EuroIcon className={classes.iconButton} />
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            <Button color="primary">
                                                Encaisser les commerçants
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>
                </Grid>

                <Grid container item justify="center" direction="row" spacing={4}>
                    <Grid item xs={12}>
                        <Typography
                            color="primary"
                            variant="h4"
                            style={{ paddingTop: 16, textTransform: 'uppercase' }}>
                            Synthèse du marché
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CardActionArea
                            component={RouterLink}
                            to={'/management/' + JournalMarche.getId() + '/synthesis/t'}>
                            <Card>
                                <CardContent>
                                    <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item>
                                            <Avatar className={classes.backButton}>
                                                <AssignmentTurnedInIcon
                                                    className={classes.iconButton}
                                                />
                                            </Avatar>
                                        </Grid>
                                        <Grid item>
                                            <Button color="primary">Synthèse</Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Dashboard);
