import React, { useEffect, useState } from 'react';
import { empty } from 'common/validators';
import { makeStyles } from '@material-ui/styles';
import { Dialog } from 'components';
import { Label } from './components';
import { getDraws } from './http';
import {
    Divider,
    Typography,
    Grid,
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    Checkbox,
    FormGroup
} from '@material-ui/core';

import LoopIcon from '@material-ui/icons/Loop';

/**
 * Style
 */
const useStyles = makeStyles(theme => ({
    label: {
        width: '100%',
        marginBottom: 10
    },
    typolabel: {
        marginBottom: 7
    },
    drawIcon: {
        position: 'relative',
        top: 5
    }
}));

export default props => {
    const classes = useStyles();
    const { open, onClose, currentFilter, drawer, id, sectors } = props;
    const [draws, setDraws] = useState([]);
    const [disableHolderTypeInput, setDisableHolderTypeInput] = useState(false);
    const [disableActivitiesInput, setDisableActivitiesInput] = useState(false);
    const [disableDrawInput, setDisableDrawInput] = useState(false);

    // useEffect(() => {
    //     setDisableActivitiesInput(!empty(currentFilter.get.draw));
    // }, [currentFilter.get.draw]);

    // useEffect(() => {
    //     setDisableDrawInput(currentFilter.get.activities.length > 0);
    // }, [currentFilter.get.activities]);

    useEffect(() => {
        setDisableHolderTypeInput(currentFilter.get.merchantType === 'p');
        setDisableDrawInput(currentFilter.get.activities.length > 0);
        setDisableActivitiesInput(false);

        currentFilter.set({
            ...currentFilter.get,
            draw: {}
        });
    }, [currentFilter.get.merchantType]);

    useEffect(() => {
        open &&
            drawer &&
            getDraws(id)
                .then(res => setDraws(res))
                .catch(err => alert(err));
    }, [open]);

    /**
     *
     * @param {*} k
     * @param {*} v
     */
    const handleFilter = (k, v) => {
        if (
            k === 'holderType' ||
            k === 'activities' ||
            k === 'merchantPlaceState' ||
            k === 'merchantState' ||
            k === 'billState' ||
            k === 'sectors'
        ) {
            const arr = [...currentFilter.get[k]];
            const index = arr.indexOf(v);
            index === -1 ? arr.push(v) : arr.splice(index, 1);
            v = arr;
            //k === 'activities' && setDisableDrawInput(v.length > 0);
        }

        if (k === 'merchantType') {
            currentFilter.set({
                ...currentFilter.get,
                [k]: v,
                holderType: []
            });
        } else if (k === 'draw') {
            //setDisableActivitiesInput(!Boolean(v.id === currentFilter.get.draw?.id));
            currentFilter.set({
                ...currentFilter.get,
                activities: [],
                [k]: v.id === currentFilter.get.draw?.id ? {} : v
            });
        } else {
            currentFilter.set({
                ...currentFilter.get,
                [k]: v
            });
        }
    };

    return (
        <Dialog title={'Filtrer'} open={open} onClose={() => onClose()}>
            <Grid container spacing={4} direction="column">
                <Grid item>
                    <Grid container direction="row" spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <FormControl component="fieldset" fullWidth>
                                <FormLabel component={Label} className={classes.label}>
                                    Type de commerçant
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="merchantType"
                                    name="merchantType"
                                    value={currentFilter.get.merchantType}
                                    onChange={e => handleFilter(e.target.name, e.target.value)}>
                                    <FormControlLabel
                                        value="t"
                                        control={<Radio />}
                                        label="Titulaire"
                                    />
                                    <FormControlLabel
                                        value="p"
                                        control={<Radio />}
                                        label="Passager"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <FormControl
                                component="fieldset"
                                fullWidth
                                disabled={disableHolderTypeInput}>
                                <FormLabel component={Label} className={classes.label}>
                                    Type de titulaire
                                </FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(
                                                    currentFilter.get.holderType.find(
                                                        v => v === 'ta'
                                                    )
                                                )}
                                                onChange={e =>
                                                    handleFilter('holderType', e.target.name)
                                                }
                                                name="ta"
                                            />
                                        }
                                        label="Abonné"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(
                                                    currentFilter.get.holderType.find(
                                                        v => v === 'tna'
                                                    )
                                                )}
                                                onChange={e =>
                                                    handleFilter('holderType', e.target.name)
                                                }
                                                name="tna"
                                            />
                                        }
                                        label="Non abonné"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth>
                                <FormLabel component={Label} className={classes.label}>
                                    Etat de présence
                                </FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(
                                                    currentFilter.get.merchantState.find(
                                                        v => v === 'here'
                                                    )
                                                )}
                                                onChange={e =>
                                                    handleFilter('merchantState', e.target.name)
                                                }
                                                name="here"
                                            />
                                        }
                                        label="Présent"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(
                                                    currentFilter.get.merchantState.find(
                                                        v => v === 'not_here'
                                                    )
                                                )}
                                                onChange={e =>
                                                    handleFilter('merchantState', e.target.name)
                                                }
                                                name="not_here"
                                            />
                                        }
                                        label="Absent"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <FormControl
                        component="fieldset"
                        fullWidth
                        disabled={disableActivitiesInput || !empty(currentFilter.get.draw)}>
                        <FormLabel component={Label} className={classes.label}>
                            Type d'activité
                        </FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(
                                            currentFilter.get.activities.find(v => v === 1)
                                        )}
                                        onChange={e => handleFilter('activities', 1)}
                                        name="alimentaire"
                                    />
                                }
                                label="Alimentaire"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(
                                            currentFilter.get.activities.find(v => v === 2)
                                        )}
                                        onChange={e => handleFilter('activities', 2)}
                                        name="primeur"
                                    />
                                }
                                label="Primeur"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(
                                            currentFilter.get.activities.find(v => v === 3)
                                        )}
                                        onChange={e => handleFilter('activities', 3)}
                                        name="manu"
                                    />
                                }
                                label="Manufacturé"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(
                                            currentFilter.get.activities.find(v => v === 4)
                                        )}
                                        onChange={e => handleFilter('activities', 4)}
                                        name="demo"
                                    />
                                }
                                label="Démonstrateur/Posticheur"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                {sectors.length > 1 && (
                    <Grid item>
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component={Label} className={classes.label}>
                                Secteurs
                            </FormLabel>
                            <FormGroup row>
                                {sectors.map(sector => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(
                                                    currentFilter.get.sectors.find(
                                                        v => v === sector
                                                    )
                                                )}
                                                onChange={e => handleFilter('sectors', sector)}
                                                name="sector"
                                            />
                                        }
                                        label={sector}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                )}
                {drawer && draws.length > 0 && (
                    <Grid item>
                        <FormControl
                            component="fieldset"
                            fullWidth
                            disabled={
                                !disableHolderTypeInput ||
                                /*disableDrawInput*/ currentFilter.get.activities.length > 0
                            }>
                            <FormLabel component={Label} className={classes.label}>
                                Tirage au sort passager{' '}
                                <LoopIcon
                                    color="secondary"
                                    fontSize="small"
                                    className={classes.drawIcon}
                                />
                            </FormLabel>
                            <FormGroup row>
                                {draws.map(r => (
                                    <FormControlLabel
                                        key={r.id}
                                        control={
                                            <Checkbox
                                                checked={Boolean(
                                                    currentFilter.get.draw?.id === r.id
                                                )}
                                                onChange={e => handleFilter('draw', r)}
                                                name="draw"
                                            />
                                        }
                                        label={r.label}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                )}
                <Grid item>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component={Label} className={classes.label}>
                            Etat de placement
                        </FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(
                                            currentFilter.get.merchantPlaceState.find(
                                                v => v === 'placed'
                                            )
                                        )}
                                        onChange={e =>
                                            handleFilter('merchantPlaceState', e.target.name)
                                        }
                                        name="placed"
                                    />
                                }
                                label="Placé"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(
                                            currentFilter.get.merchantPlaceState.find(
                                                v => v === 'not_placed'
                                            )
                                        )}
                                        onChange={e =>
                                            handleFilter('merchantPlaceState', e.target.name)
                                        }
                                        name="not_placed"
                                    />
                                }
                                label="Non placé"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component={Label} className={classes.label}>
                            Etat d'encaissement
                        </FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(
                                            currentFilter.get.billState.find(v => v === 'paid')
                                        )}
                                        onChange={e => handleFilter('billState', e.target.name)}
                                        name="paid"
                                    />
                                }
                                label="Réglé"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(
                                            currentFilter.get.billState.find(v => v === 'invoiced')
                                        )}
                                        onChange={e => handleFilter('billState', e.target.name)}
                                        name="invoiced"
                                    />
                                }
                                label="Non réglé"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Dialog>
    );
};
