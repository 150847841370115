import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableBody,
    TableHead
} from '@material-ui/core';
import env from 'common/env';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    }
}));

const columns = [
    {
        id: 'date',
        label: "Date de l'observation",
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'createdBy',
        label: 'Créé par',
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'market',
        label: 'Marché',
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'comment',
        label: 'Observation',
        minWidth: '40%',
        align: 'left'
    }
];

const Comment = props => {
    const { marchandId, updateComment, ...rest } = props;
    const [list, setList] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [updateComment]);

    const getData = () => {
        fetch(`${env}/merchant/get-litiges/${marchandId}`)
            .then(response => (response.ok ? response.json() : false))
            .then(data => setList(data))
            .catch(error => console.error(error));
    };

    return (
        <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {columns.map(column => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}>
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((litige, index) => {
                        return (
                            <TableRow hover key={index}>
                                {columns.map(column => {
                                    return (
                                        <TableCell
                                            key={`${column.id}_${litige[column.id]}`}
                                            align={column.align}>
                                            {litige[column.id]}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default Comment;
