import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
    Grid,
    Paper,
    Divider,
    TableCell,
    TableRow,
    Button,
    IconButton,
    Typography,
    Link
} from '@material-ui/core';
import { SearchTextField, MuiTable } from 'components';
import { getUnits } from './services/http';

const useStyles = makeStyles(theme => ({
    paperContent: {
        padding: theme.spacing(4)
    }
}));

const headers = [
    { id: 'unite.libelle', align: 'left', label: 'Libellé', sortable: true },
    { id: 'unite.amount', align: 'right', label: 'Montant', sortable: false },
    { id: 'action', align: 'center', label: 'Actions', sortable: false }
];

const All = ({ tab }) => {
    const [count, setCount] = useState(0);
    const [units, setUnits] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(headers[0].id);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        !tab && setLoading(true);
    }, [tab]);

    useEffect(() => {
        loading && getData();
    }, [loading]);

    useEffect(() => {
        setLoading(true);
    }, [searchValue, order, orderBy, page]);

    const handleSearchValue = value => {
        setSearchValue(value);
        setPage(0);
        setLoading(true);
    };

    const getData = () => {
        getUnits({ searchValue, page, order, orderBy })
            .then(res => {
                setCount(res.count);
                setUnits(res.units);
            })
            .catch(err => alert(err))
            .finally(() => setLoading(false));
    };

    return (
        <Paper className={classes.paperContent}>
            <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
                <Grid container direction="row" alignItems="baseline">
                    <Grid item>
                        <IconButton component={RouterLink} to="/settings">
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography>
                            <Link component={RouterLink} to="/settings">
                                Les paramètres
                            </Link>{' '}
                            / Configurer les unités
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <SearchTextField
                        label={`Rechercher une unité`}
                        value={searchValue}
                        onChange={value => handleSearchValue(value)}
                        helperText="Saisir un libellé"
                    />
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <MuiTable
                        refresh={() => setLoading(true)}
                        loading={loading}
                        headers={headers}
                        page={{
                            set: val => setPage(val),
                            data: page,
                            count: count
                        }}
                        order={{
                            set: val => setOrder(val),
                            data: order
                        }}
                        orderBy={{
                            set: val => setOrderBy(val),
                            data: orderBy
                        }}>
                        {units.map((unit, index) => (
                            <TableRow hover key={`${index}-${unit.ref}`}>
                                <TableCell align="left" key={`ref-${index}-${unit.ref}`}>
                                    {unit.libelle}
                                </TableCell>
                                <TableCell align="right" key={`ref-${index}-${unit.amount}`}>
                                    {unit.amount ?? '-'} €
                                </TableCell>
                                <TableCell align="center" key={`action-${index}-${unit.libelle}`}>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                        component={RouterLink}
                                        to={`/settings/unit/edit/${unit.id}`}>
                                        Modifier
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </MuiTable>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default All;
