import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
    Paper,
    Grid,
    Divider,
    Typography,
    IconButton,
    Link,
    TextField,
    InputAdornment,
    Button,
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableHead,
    TableBody
} from '@material-ui/core';

import { Pagination } from './components';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';

const styles = theme => ({
    root: {
        padding: theme.spacing(3)
    },
    container: {
        marginTop: 20
    }
});

const columns = [
    {
        id: 'siret',
        label: 'Siret',
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: '40%',
        align: 'left'
    },
    {
        id: 'dateCessation',
        label: "Date de cessation d'activité",
        minWidth: '20%',
        align: 'center'
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: '20%',
        align: 'center'
    }
];

class Cessation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            searchValue: '',
            page: 1,
            count: 0,
            list: []
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        (prevState.searchValue != this.state.searchValue || prevState.page != this.state.page) &&
            this.getData();
    };

    getData = () => {
        const { searchValue, page } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-cessations?search=${searchValue}&page=${page}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    render() {
        const { classes, searchValue, list, count, page } = this.state;

        return (
            <Paper className={classes.root}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container direction="row" alignItems="baseline">
                            <Grid item>
                                <IconButton onClick={this.getBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <Link component={RouterLink} to="/merchants/all">
                                        Les commerçants
                                    </Link>{' '}
                                    / Les cessations d'activité
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={'Rechercher un commerçant'}
                            type="text"
                            fullWidth
                            value={searchValue}
                            onChange={this.handleSearch}
                            variant="outlined"
                            helperText="Saisir un SIRET/SIREN, une raison sociale, une enseigne, un nom ou un prénom"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={this.clearSearch} edge="end">
                                            <ClearIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map(column => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list.map(merchant => {
                                        return (
                                            <TableRow hover key={merchant.siret}>
                                                {columns.map(column => {
                                                    if (column.id == 'action') {
                                                        return (
                                                            <TableCell
                                                                key={merchant.siret}
                                                                align={column.align}>
                                                                <Button
                                                                    component={RouterLink}
                                                                    to={`/merchants/get/${merchant.siret}/profil`}
                                                                    variant="contained"
                                                                    color="primary">
                                                                    Profil
                                                                </Button>
                                                            </TableCell>
                                                        );
                                                    }

                                                    return (
                                                        <TableCell
                                                            key={merchant.siret}
                                                            align={column.align}>
                                                            {merchant[column.id]}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item>
                        <Pagination
                            numberOfPages={count}
                            currentPage={page}
                            handlePagination={this.handlePagination}
                        />
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(Cessation);
