import {
    Typography,
    Grid,
    Card,
    CardContent,
    CardActions,
    Checkbox,
    CardActionArea,
    CircularProgress
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import { getGroupedBillsByFilters } from './action';

const GroupedBills = props => {
    const { id, bills, filters, start, end } = props;

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rows, setRows] = useState([]);
    const selected = selectedId => bills.get.find(row => row === selectedId);

    useEffect(() => {
        id &&
            getGroupedBillsByFilters(id, filters, start, end)
                .then(res => {
                    setRows(res);
                    const bf = [];
                    res.map(row => row.map(subrow => bf.push(subrow)));
                    bills.update(bf, true, 'billNumber');
                    setLoading(false);
                })
                .catch(err => {
                    setError(err);
                    setLoading(false);
                });
    }, [filters]);

    return (
        <Grid
            container
            direction="column"
            alignItems={loading || error ? 'center' : 'stretch'}
            justify={loading || error ? 'center' : 'flex-start'}
            justifyContent={loading || error ? 'center' : 'flex-start'}
            spacing={4}>
            {loading || error ? (
                error ? (
                    <Grid item>
                        <Typography variant="body1">{error}</Typography>
                    </Grid>
                ) : (
                    <Grid item>
                        <CircularProgress size={26} />
                    </Grid>
                )
            ) : (
                rows.map(row => (
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justifyContent="stretch"
                            justify="stretch"
                            spacing={2}>
                            <Grid item>
                                <Typography variant="button" color="primary">
                                    {row[0]?.marketLabel}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" spacing={2}>
                                    {row.map(subrow => (
                                        <Grid item>
                                            <CardActionArea
                                                onClick={() => bills.update(subrow.billNumber)}>
                                                <Card
                                                    elevation={selected(subrow.billNumber) ? 5 : 1}>
                                                    <div
                                                        style={{
                                                            padding: 8,
                                                            borderBottom: '1px solid #eee'
                                                        }}>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                            justify="space-between">
                                                            <Grid item>
                                                                <Typography
                                                                    variant="button"
                                                                    color="secondary">
                                                                    {subrow.billNumber}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                {selected(subrow.billNumber) ? (
                                                                    <Checkbox
                                                                        checked={true}
                                                                        size="small"
                                                                    />
                                                                ) : (
                                                                    <Checkbox
                                                                        checked={false}
                                                                        size="small"
                                                                    />
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <CardContent>
                                                        <Grid
                                                            container
                                                            justify="center"
                                                            alignItems="center"
                                                            justifyContent="center">
                                                            <Grid item>
                                                                <DescriptionIcon
                                                                    color="secondary"
                                                                    style={{ fontSize: 50 }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                    <CardActions
                                                        style={{ borderTop: '1px solid #eee' }}>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            spacing={2}
                                                            justifyContent="space-between"
                                                            justify="space-between">
                                                            <Grid item>
                                                                <Typography variant="body2">
                                                                    {subrow.issueDate}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{ fontWeight: 500 }}>
                                                                    {subrow.amount} €
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </CardActions>
                                                </Card>
                                            </CardActionArea>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))
            )}
        </Grid>
    );
};

export default GroupedBills;
