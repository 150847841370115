import React from 'react';

import { makeStyles } from '@material-ui/styles';

import {
    Grid
} from '@material-ui/core';

import {
    TemplateTable,
    TemplateCard,
    TemplateDialog
} from './components'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
}));

const AttendancePoint = props => {
    const { className, history, match, ...rest } = props;
    const classes = useStyles();
    const [records, setRecords] = React.useState(0);


    return (
        <div className={classes.root}>
            {
                match.params.method &&
                <TemplateDialog params={match.params} history={history} />
            }
            <Grid container spacing={4} direction="column">
                <Grid item xs={12} md={4}>
                    <TemplateCard data={records} method={match.params.method} />
                </Grid>
                <Grid item>
                    <TemplateTable setRecords={setRecords} params={match.params} history={history} />
                </Grid>
            </Grid>
        </div>
    );
};

export default AttendancePoint;
