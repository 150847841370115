// @flow

import L from 'leaflet';
import rhumbDestination from '@turf/rhumb-destination';
import turf from 'turf';

const DEFAULT_OPTIONS = {
    //pane: "markerPane",
    interactive: true,
    color: 'white',
    stroke: true,
    //dashArray:"4",
    fillOpacity: 0.4,
    weight: 2,
    draggable: true
};

class MarketPlace extends L.rectangle {
    constructor(saved = false) {
        super(
            [
                [0, 0],
                [0, 0]
            ],
            DEFAULT_OPTIONS
        );

        this.is_a_place = true;

        this._config = {
            type: 'place',
            is_free: true,
            activitie: null,
            meters: 0,
            rotation: 0,
            original_rotation: 0,
            numero: {
                numero: 0,
                label: null
            },
            options: {
                water: false,
                electricity: false,
                car: false,
                sector: ''
            },
            merchant: {},
            status: 'created',
            saved: saved
        };
    }

    getId = () => {
        return this._leaflet_id;
    };

    setId = id => {
        this._leaflet_id = id;
    };

    get = () => {
        return {
            leaflet_id: this._leaflet_id,
            numero: this._config.numero.numero,
            meters: this._config.meters,
            merchant: this._config.merchant,
            activitie: this._config.activitie,
            coords: this.getLatLngs()[0],
            rotation: this._config.rotation,
            water: this._config.options.water,
            electricity: this._config.options.electricity,
            car: this._config.options.car,
            sector: this._config.options.sector,
            status: this._config.status,
            saved: this._config.saved
        };
    };

    getForMerchant = () => {
        return {
            leaflet_id: this._leaflet_id,
            numero: this._config.numero.numero,
            meters: this._config.meters,
            activitie: this._config.activitie,
            rotation: this._config.rotation,
            water: this._config.options.water,
            electricity: this._config.options.electricity,
            car: this._config.options.car,
            sector: this._config.options.sector,
            status: this._config.status,
            saved: this._config.saved
        };
    };

    getStateForBuild = () => {
        return {
            isFree: this._config.is_free,
            selectedMerchant: this._config.merchant,
            selectedActivitie: this._config.activitie.id,
            isSubscriber: this._config.merchant.isSubscriber,
            numero: this._config.numero.numero,
            meters: this._config.meters,
            rotation: this._config.rotation,
            water: this._config.options.water,
            electricity: this._config.options.electricity,
            car: this._config.options.car,
            sector: this._config.options.sector,
            status: this._config.status,
            saved: this._config.saved
        };
    };

    setConfig = (key, value, update = false) => {
        this._config[key] = value;
        update && this._config.saved && this.setConfig('status', 'updated');
    };

    setOptions = (key, value) => {
        // this.setConfig('status', 'updated');
        this.setStyle({
            key: value
        });
    };

    getConfig = key => {
        return this._config[key];
    };

    setActivitie = (activitie, update = false) => {
        this.setConfig('activitie', activitie);
        update && this._config.saved && this.setConfig('status', 'updated');

        this.setStyle({
            color: activitie.color
        });
    };

    setMerchant = (merchant, update = false) => {
        this._config.merchant = merchant;

        let is_free = Boolean(merchant.id == '1');
        this._config.is_free = is_free;

        this.setNumero(this.getConfig('numero').numero, is_free, update);
    };

    setNumero = (numero, is_free = true, update = false) => {
        let center = turf.centroid(this.toGeoJSON()).geometry.coordinates;

        this._config.numero.numero = numero;

        let marginLeft = numero > 99 ? '-10' : numero > 9 ? '-7' : '-3';
        let color = is_free ? 'black' : 'red';

        let htmlCode =
            `<div style='color:${color}; margin-top: -8px;margin-left: ${marginLeft}px'><strong>` +
            numero +
            `</strong></div>`;

        if (this._config.numero.label) {
            let icon = this._config.numero.label.getIcon();
            icon.options.html = htmlCode;
            this._config.numero.label.setIcon(icon);
            this._config.numero.label.setLatLng([center[1], center[0]]);
        } else {
            this._config.numero.label = L.marker([center[1], center[0]], {
                icon: new L.DivIcon({
                    iconSize: null,
                    className: 'label',
                    html: htmlCode
                }),
                //pane: 'overlayPane'
                interactive: false
            });
            this._config.numero.label.is_a_place = false;
        }

        this._config.numero.label._leaflet_id = `n_${numero}_${this._leaflet_id}`;
        update && this._config.saved && this.setConfig('status', 'updated');
    };

    setMeters = (meters, update = false, latlngs = this.getLatLngs()) => {
        let pts = {};
        let depth = 3;
        let lat = latlngs[0] ? latlngs[0][0].lat : latlngs.lat;
        let lng = latlngs[0] ? latlngs[0][0].lng : latlngs.lng;
        let pt = turf.point([lng, lat]);
        let rotation = this.getConfig('rotation');
        this.setConfig('meters', meters);

        pts.a = {
            lat: lat,
            lng: lng
        };

        let scale = rhumbDestination(pt, meters / 1000, 90 + rotation); // in Km

        pts.b = {
            lat: scale.geometry.coordinates[1],
            lng: scale.geometry.coordinates[0]
        };

        pt = turf.point([pts.b.lng, pts.b.lat]);

        scale = rhumbDestination(pt, depth / 1000, 0 + rotation); // in Km

        pts.c = {
            lat: scale.geometry.coordinates[1],
            lng: scale.geometry.coordinates[0]
        };

        pt = turf.point([pts.a.lng, pts.a.lat]);

        scale = rhumbDestination(pt, depth / 1000, 0 + rotation); // in Km

        pts.d = {
            lat: scale.geometry.coordinates[1],
            lng: scale.geometry.coordinates[0]
        };

        this.setLatLngs([pts.a, pts.b, pts.c, pts.d]);
        update && this._config.saved && this.setConfig('status', 'updated');
        //this.setBounds([pts.a, pts.c])
    };
}

export default MarketPlace;
