import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Grid, Paper, Divider, Typography, IconButton, Link } from '@material-ui/core';

import { Form } from './components';
import env from 'common/env';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    }
});

class Creation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            form: null
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        this.state.isLoading && this.sendData();
    };

    prepareData = form => {
        if (form.label == '') {
            alert('Veuillez saisir un libellé.');
            return;
        }

        if (form.type == '') {
            alert('Veuillez séléctionner une typologie.');
            return;
        }

        if (form.unit_id === '') {
            alert('Veuillez séléctionner un prix unitaire.');
            return;
        }

        this.setState({
            form: form,
            isLoading: true
        });
    };

    sendData = () => {
        const { form } = this.state;

        const formData = new FormData();
        formData.append('label', form.label);
        formData.append('type', form.type);
        formData.append('unit_id', form.unit_id);
        form.commentaire != '' && formData.append('commentaire', form.commentaire);

        fetch(`${env}/domaine/other-sales/create`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    isLoading: false
                });
                alert(`Le produit "${form.label}" a été créé avec succès.`);
                this.props.history.push('/other-sales/all');
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la création');
                    this.setState({
                        isLoading: false
                    });
                } // Handle the error response object
            );
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { classes, isLoading } = this.state;

        return (
            <Paper className={classes.root}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container direction="row" alignItems="baseline">
                            <Grid item>
                                <IconButton onClick={this.getBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <Link component={RouterLink} to="/other-sales/all">
                                        Les autres ventes
                                    </Link>{' '}
                                    / Créer un produit
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item style={{ marginTop: 20 }}>
                        <Form isLoading={isLoading} sendData={this.prepareData} />
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(Creation);
