import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import {
    Grid
} from '@material-ui/core';

import {
    EmailTemplateTable,
    EmailTemplateCard,
    EmailTemplateDialog
} from './components'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
}));

const EmailTemplate = props => {
    const { className, history, match, ...rest } = props;
    const classes = useStyles();
    const [records, setRecords] = React.useState(0);


    return (
        <div className={classes.root}>
            {
                match.params.method &&
                <EmailTemplateDialog params={match.params} history={history} />
            }
            <Grid container spacing={4} direction="column">
                {/*<Grid item xs={12} md={4}>
                    <EmailTemplateCard data={records} method={match.params.method} />
        </Grid>*/}
                <Grid item>
                    <EmailTemplateTable setRecords={setRecords} params={match.params} history={history} />
                </Grid>
            </Grid>
        </div>
    );
};

export default EmailTemplate;
