import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    TextField,
    Typography,
    Grid,
    FormControl,
    FormLabel,
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = theme => ({
    root: {
        flexGrow: 1
    }
});

class MarketInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            list: [],
            type: 'all',
            searchValue: '',
            mounted: false,
            value: { label: '', id: 0 }
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.type != this.props.type || prevState.searchValue != this.state.searchValue) {
            this.getData();
        }
    };

    componentDidMount = () => {
        this.getData();
        this.setState({
            mounted: true
        });
    };

    getData = () => {
        const { searchValue, mounted } = this.state;
        let args = `dataToRetrieve=markets&search=${searchValue}`;

        if (this.props.journalMarket.getId() > 0 && !mounted) {
            args = `${args}&journalmarcheId=${this.props.journalMarket.getId()}`;
        }

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-data-for-report-issue?${args}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(error => {
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    handleList = (event, value) => {
        this.setState({
            value: value
        });
        this.props.setMarket(value);
    };

    handleInputList = value => {
        this.setState(prevState => ({
            searchValue: value
            //list: value == "" ? [] : prevState.list
        }));
    };

    render() {
        const { list, value } = this.state;

        return (
            <Grid container direction="column" spacing={1} alignItems="stretch">
                <Grid item>
                    <FormControl required component="fieldset">
                        <FormLabel color="primary">Sélectionnez un marché</FormLabel>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Autocomplete
                        options={list}
                        name="list"
                        autoHighlight
                        onChange={this.handleList}
                        onInputChange={(event, value) => {
                            this.handleInputList(value);
                        }}
                        value={value}
                        noOptionsText={"Aucun marché n'a été trouvé"}
                        getOptionLabel={option => option.label}
                        getOptionSelected={(option, value) => option.id == value.id}
                        renderOption={(option, value) => option.label}
                        renderInput={params => (
                            <TextField
                                fullWidth
                                {...params}
                                helperText={'Saisir un libellé'}
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(MarketInput);
