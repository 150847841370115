import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid, Button, Typography, Divider, Link } from '@material-ui/core';

import FaceIcon from '@material-ui/icons/Face';
import CloseIcon from '@material-ui/icons/Close';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HistoryIcon from '@material-ui/icons/History';
import WarningIcon from '@material-ui/icons/Warning';

const styles = theme => ({
    root: {
        //paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    }
});

class Merchant extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props
        };
    }

    componentDidUpdate = (prevProps, prevState) => {};

    componentDidMount = () => {
        //this.getData();
    };

    getBill = num => {
        window.open(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/print/A/${num}`,
            '_blank'
        );
    };

    render() {
        const { classes } = this.state;

        const { layer } = this.props;

        const _config = layer.get();

        return (
            <Grid
                className={classes.root}
                container
                direction="column"
                justify="space-between"
                //alignItems="flex-start"
                spacing={4}>
                <Grid item>
                    <Button
                        startIcon={<CloseIcon />}
                        size="small"
                        style={{ color: 'red' }}
                        onClick={() => this.props.setTab('store', true)}>
                        Fermer
                    </Button>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                        spacing={3}>
                        <Grid item>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                spacing={0}>
                                {_config.merchant.id != '1' ? (
                                    <React.Fragment>
                                        <Grid item>
                                            <FaceIcon
                                                style={{
                                                    color: _config.activitie.color,
                                                    fontSize: '4rem'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                style={{ color: _config.activitie.color }}>
                                                {_config.merchant.raisonSociale}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                {_config.merchant.metierLabel}
                                            </Typography>
                                        </Grid>
                                    </React.Fragment>
                                ) : (
                                    <Grid item>
                                        <Typography variant="button" style={{ color: 'green' }}>
                                            Place disponible
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Grid
                                        container
                                        direciton="row"
                                        spacing={1}
                                        justify="space-between">
                                        <Grid item>Numéro</Grid>
                                        <Grid item>{_config.numero}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        direciton="row"
                                        spacing={1}
                                        justify="space-between">
                                        <Grid item>Nombre de mètres</Grid>
                                        <Grid item>{_config.meters} m</Grid>
                                    </Grid>
                                </Grid>
                                {_config.merchant.id != '1' && (
                                    <Grid item>
                                        <Grid
                                            container
                                            direciton="row"
                                            spacing={1}
                                            justify="space-between">
                                            <Grid item>Statut du commerçant</Grid>
                                            <Grid item>
                                                {_config.merchant.isHolder
                                                    ? 'Titulaire ' +
                                                      (_config.merchant.isSubscriber
                                                          ? 'abonné'
                                                          : 'non abonné')
                                                    : 'Passager'}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {_config.merchant.id != '1' && (
                                    <Grid item>
                                        <Grid
                                            container
                                            direciton="row"
                                            spacing={1}
                                            justify="space-between">
                                            <Grid item>Moyen de paiement</Grid>
                                            <Grid item>
                                                {_config.merchant.paid
                                                    ? _config.merchant.meanOfPayment.label
                                                    : '-'}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}

                                <Grid item>
                                    <Divider />
                                </Grid>
                                {_config.merchant.id != '1' && _config.merchant.bill.hasBill && (
                                    <Grid item>
                                        <Grid
                                            container
                                            direciton="row"
                                            spacing={1}
                                            justify="space-between"
                                            alignItems="flex-end">
                                            <Grid item>Statut de la facture</Grid>
                                            <Grid item>
                                                <Typography
                                                    variant="button"
                                                    style={{
                                                        color: _config.merchant.paid
                                                            ? 'green'
                                                            : 'red'
                                                    }}>
                                                    {_config.merchant.paid
                                                        ? 'Réglée'
                                                        : 'Non réglée'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {_config.merchant.id != '1' && !_config.merchant.bill.hasBill && (
                                    <Grid item>
                                        <Grid
                                            container
                                            direciton="row"
                                            spacing={1}
                                            justify="center">
                                            <Grid item>
                                                <WarningIcon style={{ color: 'red' }} />
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    variant="button"
                                                    style={{ color: 'red' }}>
                                                    Pas de facture trouvée
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {_config.merchant.id != '1' && _config.merchant.bill.hasBill && (
                                    <React.Fragment>
                                        <Grid item>
                                            <Grid
                                                container
                                                direciton="row"
                                                spacing={1}
                                                justify="space-between">
                                                <Grid item>
                                                    <strong>Montant total</strong>
                                                </Grid>
                                                <Grid item>
                                                    <strong>{_config.merchant.montant} €</strong>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {_config.merchant.bill.cashedBeforeDDay &&
                                            _config.merchant.paid && (
                                                <Grid item>
                                                    <Grid
                                                        container
                                                        direciton="row"
                                                        spacing={1}
                                                        justify="center"
                                                        alignItems="center">
                                                        <Grid item style={{ marginTop: 5 }}>
                                                            <HistoryIcon />
                                                        </Grid>
                                                        <Grid item>
                                                            Réglée le{' '}
                                                            {_config.merchant.bill.paymentDate}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                    </React.Fragment>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {_config.merchant.id != '1' && _config.merchant.bill.hasBill && (
                    <Grid item>
                        <Button
                            size="small"
                            onClick={() => this.getBill(_config.merchant.bill.num)}
                            variant="outlined"
                            fullWidth
                            color="primary"
                            endIcon={<PictureAsPdfIcon />}>
                            Visualiser la facture
                        </Button>
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default withStyles(styles)(Merchant);
