import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import GroupIcon from '@material-ui/icons/Group';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Autocomplete from '@material-ui/lab/Autocomplete';
import L from 'leaflet';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Link as RouterLink } from 'react-router-dom';
import JournalMarche from '../../JournalMarche';

import {
    Avatar,
    Paper,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Typography,
    ListItemText,
    Button,
    Menu,
    MenuItem,
    Card,
    CardContent,
    CardActionArea,
    TextField,
    InputAdornment
} from '@material-ui/core';

import { DigitMap as Map } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    table: {
        maxHeight: 300,
        borderRight: '1px solid #eee',
        borderLeft: '1px solid #eee'
    },
    avatar: {
        backgroundColor: 'green',
        height: 48,
        width: 48
    },
    title: {
        //flex: '1 1 100%',
    },
    content: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    subtitle: {
        padding: '32px 0 0 16px'
    },
    selection: {
        display: 'none'
    },
    headSelection: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5',
        '& p': {
            color: '#3f51b5'
        },
        '& svg': {
            fill: '#3f51b5'
        }
    },
    recommandation: {
        color: '#1976d2',
        '& span, & th, & td': {
            color: '#1976d2'
        },
        '& th, & td': {
            fontWeight: 500
        }
    },
    buttonRemove: {
        color: 'red'
    }
});

const merchants = {
    _0: {
        siret: '0',
        raisonSociale: 'Non affecté',
        metier: 'Aucun'
    },
    _08797687687: {
        siret: '08797687687',
        raisonSociale: 'Dany',
        metier: 'Apiculteur'
    },
    _78767876976: {
        siret: '78767876976',
        raisonSociale: 'Nadir',
        metier: 'Rôtisseur'
    },
    _O9987876446: {
        siret: 'O9987876446',
        raisonSociale: 'Nassim',
        metier: 'Boucher'
    }
};

class Placement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            search: '',
            filterLabel: 'p',
            anchorEl: null,
            itemSelected: '',
            selectedMerchant: false,
            metersPlaceHolder: '',
            layersGroup: L.featureGroup(),
            inputNumero: false,
            inputMeters: '',
            raisonSociale: 'Raison sociale',
            merchantNumero: 0,
            journalplaceId: 0,
            child: React.createRef(),
            titulaire: {
                all: 0,
                placed: 0
            },
            passager: {
                all: 0,
                placed: 0
            },
            listOfMarchand: [],
            listOfPlace: {
                recommandation: [],
                all: []
            },
            mymerchant: []
        };
    }

    componentDidMount() {
        this.update('p');
    }

    update = type => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/${JournalMarche.getId()}/get/${type}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data

                this.setState({
                    titulaire: {
                        placed: data.titulaire.total_placed,
                        all: data.titulaire.total_all
                    },
                    passager: {
                        placed: data.passager.total_placed,
                        all: data.passager.total_all
                    },
                    listOfMarchand: data.marchand
                });
            })
            .catch(console.log);
    };

    formatText(text, end) {
        if (text == '' || text == null) {
            return '';
        }

        let length = text.length;
        let textFormated = text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);

        if (length > end) {
            textFormated = textFormated.substring(0, end) + '...';
        }

        return textFormated;
    }

    handleFilterOpen = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleFilterClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    handleFilter = label => {
        this.setState({
            filterLabel: label,
            anchorEl: null
        });

        this.update(label);
    };

    handleItem = (event, value, edit = false) => {
        if (edit) {
            fetch(
                `https://www.${
                    window.location.hostname.split('.')[1]
                        ? window.location.hostname.split('.')[0] === 'www'
                            ? window.location.hostname.split('.')[1]
                            : window.location.hostname.split('.')[0]
                        : 'dev'
                }.api.digitmarche.fr/api/placement/${JournalMarche.getId()}/get/place/${value}`
            )
                .then(res => res.json()) // Traitement en json
                .then(data => {
                    // Traitement de l'indo for stating data
                    console.log(data.marchand.place);
                    this.setState(prevState => ({
                        itemSelected: prevState.itemSelected == value ? '' : value,
                        selectedMerchant: prevState.itemSelected == value ? false : value,
                        listOfPlace: {
                            recommandation: data.place.recommandation,
                            all: data.place.all
                        },
                        inputNumero: data.marchand.place ? data.marchand.place.numero : '',
                        inputMeters: data.marchand.place ? data.marchand.place.metres : '',
                        raisonSociale: data.marchand.raisonSociale,
                        merchantNumero: data.marchand.place ? data.marchand.place.numero : 0,
                        journalplaceId: data.marchand.place
                            ? data.marchand.place.journalplace_id
                            : 0,
                        mymerchant: data.marchand
                    }));
                })
                .catch(console.log);
        } else {
            this.setState(prevState => ({
                itemSelected: prevState.itemSelected == value ? '' : value,
                selectedMerchant: prevState.itemSelected == value ? false : value,
                inputNumero: '',
                inputMeters: '',
                raisonSociale: 'Raison sociale'
            }));
        }
    };

    onTagsChange = (event, value) => {
        this.setState({
            metersPlaceHolder: value ? `${value.metres}` : '',
            inputNumero: value ? `${value.numero}` : '',
            journalplaceId: value ? value.journalplace_id : 0,
            inputMeters: ''
        });
    };

    handleInputMeters = ({ target: { value } }) => {
        this.setState({
            inputMeters: value
        });
    };

    handleMeters = ({ target: { value } }) => {
        const {
            inputMeters,
            inputNumero,
            layersGroup,
            selectedMerchant,
            journalplaceId,
            filterLabel
        } = this.state;

        this.setState({
            inputMeters: value == '' ? '' : value
        });

        if (inputNumero && value != '') {
            fetch(
                `https://www.${
                    window.location.hostname.split('.')[1]
                        ? window.location.hostname.split('.')[0] === 'www'
                            ? window.location.hostname.split('.')[1]
                            : window.location.hostname.split('.')[0]
                        : 'dev'
                }.api.digitmarche.fr/api/placement/${JournalMarche.getId()}/update?journalplace_id=${journalplaceId}&metres=${inputMeters}&marchand_id=${selectedMerchant}&numero=${inputNumero}`
            ).catch(console.log);

            let oldMeters = this.state.mymerchant.place ? this.state.mymerchant.place.metres : '';
            let oldNumero = this.state.mymerchant.place ? this.state.mymerchant.place.numero : '';

            if (oldNumero != inputNumero && oldMeters != inputMeters) {
                this.state.layersGroup.eachLayer(layer => {
                    if (layer.is_a_place && inputNumero == layer.getConfig('numero').numero) {
                        this.state.child.current.state.child.current._onClick({ layer: layer });
                    }
                });
            }

            this.setState({
                inputNumero: false,
                inputMeters: '',
                selectedMerchant: false,
                itemSelected: ''
            });
        }

        this.update(filterLabel);
    };

    removeMerchantFromPlace = () => {
        const { mymerchant } = this.state;

        let is_removed = this.state.child.current.state.child.current.removeFromPlace();

        is_removed &&
            fetch(
                `https://www.${
                    window.location.hostname.split('.')[1]
                        ? window.location.hostname.split('.')[0] === 'www'
                            ? window.location.hostname.split('.')[1]
                            : window.location.hostname.split('.')[0]
                        : 'dev'
                }.api.digitmarche.fr/api/placement/${JournalMarche.getId()}/update?journalplace_id=${
                    mymerchant.place.journalplace_id
                }&metres=${mymerchant.place.metres}&marchand_id=1&numero=${mymerchant.place.numero}`
            ).catch(console.log);

        is_removed &&
            this.setState({
                inputNumero: false,
                inputMeters: '',
                selectedMerchant: false,
                itemSelected: ''
            });

        is_removed && this.update(this.state.filterLabel);
    };

    handleRecommandation = (event, numero, meters, id) => {
        const { layersGroup, selectedMerchant, filterLabel } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/${JournalMarche.getId()}/update?journalplace_id=${id}&metres=${meters}&marchand_id=${selectedMerchant}&numero=${numero}`
        ).catch(console.log);

        this.state.layersGroup.eachLayer(layer => {
            if (layer.is_a_place && numero == layer.getConfig('numero').numero) {
                this.state.child.current.state.child.current._onClick({ layer: layer });
                this.setState({
                    inputNumero: false,
                    inputMeters: '',
                    selectedMerchant: false,
                    itemSelected: ''
                });
            }
        });

        this.update(filterLabel);
    };

    handleSearch = ({ target: { value } }) => {
        const { filterLabel } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/${JournalMarche.getId()}/get/${filterLabel}?search=${value}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data

                this.setState({
                    search: value,
                    listOfMarchand: data.marchand
                });
            })
            .catch(console.log);
    };

    render() {
        const {
            classes,
            filterLabel,
            anchorEl,
            itemSelected,
            selectedMerchant,
            layersGroup,
            metersPlaceHolder,
            inputMeters,
            inputNumero,
            titulaire,
            passager,
            listOfMarchand,
            listOfPlace,
            raisonSociale,
            search
        } = this.state;

        return (
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item xs={6} sm={6} lg={6}>
                        <CardActionArea onClick={() => this.handleFilter('t')}>
                            <Card elevation={filterLabel == 't' ? 3 : 1}>
                                <CardContent style={{ paddingBottom: 16 }}>
                                    <Grid container justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                //className={classes.title}
                                                gutterBottom
                                                variant="body2">
                                                Titulaires placés
                                            </Typography>
                                            <Grid
                                                container
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="flex-end"
                                                spacing={1}>
                                                <Grid item>
                                                    <Typography variant="h3">
                                                        {titulaire.placed}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        / {titulaire.all}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Avatar className={classes.avatar}>
                                                <PersonPinCircleIcon
                                                    className={classes.icon}
                                                    style={{ width: 32, height: 32 }}
                                                />
                                            </Avatar>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={6}>
                        <CardActionArea onClick={() => this.handleFilter('p')}>
                            <Card elevation={filterLabel == 'p' ? 3 : 1}>
                                <CardContent style={{ paddingBottom: 16 }}>
                                    <Grid container justify="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography
                                                color="textSecondary"
                                                //className={classes.title}
                                                gutterBottom
                                                variant="body2">
                                                Passagers placés
                                            </Typography>
                                            <Grid
                                                container
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="flex-end"
                                                spacing={1}>
                                                <Grid item>
                                                    <Typography variant="h3">
                                                        {passager.placed}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        / {passager.all}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Avatar className={classes.avatar}>
                                                <PersonPinCircleIcon
                                                    className={classes.icon}
                                                    style={{ width: 32, height: 32 }}
                                                />
                                            </Avatar>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </CardActionArea>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.content}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} style={{ padding: '0 0 10px 0' }}>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center">
                                        <Grid item>
                                            <Typography
                                                style={{ textTransform: 'uppercase' }}
                                                variant="h6">
                                                Total{' '}
                                                {parseInt(titulaire.placed) +
                                                    parseInt(passager.placed)}{' '}
                                                commerçants placés
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                component={RouterLink}
                                                color="primary"
                                                to={'/finance/' + JournalMarche.getId()}>
                                                Encaisser les commerçants <ArrowForwardIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container justify="space-between" alignItems="flex-end">
                                        <Grid item>
                                            <Typography variant="h6" className={classes.title}>
                                                Plan du marché
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                style={{ visibility: 'hidden' }}
                                                label="Rechercher un commerçant"
                                                type="text"
                                                value={search}
                                                onChange={this.handleSearch}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <Grid container justify="space-between" alignItems="flex-end">
                                        <Grid item>
                                            <Typography variant="h6" className={classes.title}>
                                                Liste des{' '}
                                                {filterLabel == 'p' ? 'passagers' : 'titulaires'}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label="Rechercher un commerçant"
                                                type="text"
                                                value={search}
                                                onChange={this.handleSearch}
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={6}>
                                    <Map
                                        ref={this.state.child}
                                        handleItem={this.handleItem}
                                        selectedMerchant={this.state.selectedMerchant}
                                        raisonSociale={this.state.raisonSociale}
                                        merchantNumero={this.state.merchantNumero}
                                        layersGroup={layersGroup}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TableContainer style={{ maxHeight: '70vh' }}>
                                        <Table stickyHeader className={classes.table}>
                                            {
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell
                                                            padding="checkbox"
                                                            className={clsx({
                                                                [classes.headSelection]: Boolean(
                                                                    itemSelected != ''
                                                                )
                                                            })}>
                                                            <Checkbox
                                                                disabled={Boolean(
                                                                    itemSelected == ''
                                                                )}
                                                                onClick={event =>
                                                                    this.handleItem(
                                                                        event,
                                                                        itemSelected
                                                                    )
                                                                }
                                                                checked={Boolean(
                                                                    itemSelected != ''
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            padding="none"
                                                            align="left"
                                                            className={clsx({
                                                                [classes.headSelection]: Boolean(
                                                                    itemSelected != ''
                                                                )
                                                            })}>
                                                            {Boolean(itemSelected)
                                                                ? this.formatText(raisonSociale, 13)
                                                                : raisonSociale}
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            style={{
                                                                paddingTop: Boolean(
                                                                    itemSelected != ''
                                                                )
                                                                    ? 10
                                                                    : 16
                                                            }}
                                                            className={clsx({
                                                                [classes.headSelection]: Boolean(
                                                                    itemSelected != ''
                                                                )
                                                            })}>
                                                            {itemSelected != '' ? (
                                                                <Grid
                                                                    container
                                                                    spacing={1}
                                                                    alignItems="flex-end">
                                                                    <Grid item>N°</Grid>
                                                                    <Grid item>
                                                                        <Autocomplete
                                                                            options={
                                                                                listOfPlace.all
                                                                            }
                                                                            getOptionLabel={option =>
                                                                                option.numero
                                                                            }
                                                                            includeInputInList
                                                                            autoComplete
                                                                            defaultValue={{
                                                                                numero: inputNumero,
                                                                                meters: inputMeters
                                                                            }}
                                                                            noOptionsText=" "
                                                                            onChange={
                                                                                this.onTagsChange
                                                                            }
                                                                            renderInput={params => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    style={{
                                                                                        width: 75
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            ) : (
                                                                'N°'
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            align="right"
                                                            style={{
                                                                paddingTop: Boolean(
                                                                    itemSelected != ''
                                                                )
                                                                    ? 10
                                                                    : 16
                                                            }}
                                                            className={clsx({
                                                                [classes.headSelection]: Boolean(
                                                                    itemSelected != ''
                                                                )
                                                            })}>
                                                            {itemSelected != '' ? (
                                                                <TextField
                                                                    placeholder={metersPlaceHolder}
                                                                    onBlur={this.handleMeters}
                                                                    onChange={
                                                                        this.handleInputMeters
                                                                    }
                                                                    style={{ width: 40 }}
                                                                    value={inputMeters}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment
                                                                                position="end"
                                                                                className={
                                                                                    classes.headSelection
                                                                                }>
                                                                                m
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            ) : (
                                                                'Mètres'
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            }
                                            <TableBody
                                                className={clsx({
                                                    [classes.selection]: Boolean(itemSelected != '')
                                                })}>
                                                {listOfMarchand.map((marchand, index) => (
                                                    <TableRow
                                                        key={marchand.id}
                                                        style={{
                                                            backgroundColor:
                                                                index % 2 == 0
                                                                    ? 'rgb(0,0,0, 0.02)'
                                                                    : 'none'
                                                        }}>
                                                        <TableCell
                                                            alignt="center"
                                                            padding="checkbox">
                                                            <Checkbox
                                                                onClick={event =>
                                                                    this.handleItem(
                                                                        event,
                                                                        marchand.marchand_id,
                                                                        true
                                                                    )
                                                                }
                                                                value={marchand.marchand_id}
                                                                checked={Boolean(
                                                                    itemSelected ==
                                                                        marchand.marchand_id
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            padding="none"
                                                            align="left">
                                                            <ListItemText
                                                                primary={this.formatText(
                                                                    marchand.raisonSociale,
                                                                    13
                                                                )}
                                                                secondary={this.formatText(
                                                                    marchand.alias,
                                                                    15
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {marchand.numero}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {marchand.metres &&
                                                                marchand.metres + ' m'}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                            <TableBody
                                                className={clsx({
                                                    [classes.selection]: Boolean(itemSelected == '')
                                                })}>
                                                {listOfPlace.recommandation.map((place, index) => (
                                                    <TableRow
                                                        key={place.journalplace_id}
                                                        className={clsx({
                                                            [classes.recommandation]: Boolean(
                                                                place.recommandation == '1'
                                                            )
                                                        })}>
                                                        <TableCell
                                                            alignt="center"
                                                            padding="checkbox">
                                                            <Checkbox
                                                                onClick={event =>
                                                                    this.handleRecommandation(
                                                                        event,
                                                                        place.numero,
                                                                        place.metres,
                                                                        place.journalplace_id
                                                                    )
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            padding="none"
                                                            scope="row"
                                                            align="left">
                                                            {place.recommandation == '1'
                                                                ? 'Recommandé'
                                                                : 'Autre place'}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {place.numero}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {place.metres} m
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    style={{ textAlign: 'right', padding: '24px 0 0 0' }}>
                                    <Button
                                        className={classes.buttonRemove}
                                        disabled={!Boolean(selectedMerchant)}
                                        onClick={this.removeMerchantFromPlace}>
                                        Annuler le placement{' '}
                                        <HighlightOffIcon style={{ marginLeft: 7 }} />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Placement);
