import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/styles';

import { Pagination } from './components'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import {
    TableRow,
    TableCell,
    TableContainer,
    Table,
    ButtonGroup,
    TableBody,
    ListItemText,
    Button,
    Grid,
    Typography
} from '@material-ui/core';


import DoneIcon from '@material-ui/icons/Done';


const columns = [
    {
        id: 'numero',
        label: 'N°',
        minWidth: "15%",
        align: 'left',
    },
    {
        id: "information",
        label: "Information",
        minWidth: "70%",
        align: 'left',
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: "15%",
        align: 'center',
    },
];


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(0, 0, 0, 0.02)",
        },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#e1f0fb",
        color: "#3f51b5",
    },
}))(TableCell);



const useStyles = makeStyles(theme => ({
    list: {
        [theme.breakpoints.down('sm')]: {
            height: "calc(66vh - 142px)",
        },
        [theme.breakpoints.up('sm')]: {
            height: "calc(100vh - 88px)",
        }
    }
}));


const infoPlace = (place) => {

    let info =
        <ListItemText
            primary={
                <Typography style={{ color: "green" }}>
                    Disponible
                </Typography>
            }
        />

    if (place.usualPlace == "1") {

        info =
            <ListItemText
                primary={
                    <Typography style={{ color: "orange" }}>
                        Place attribuée par défaut {place.onIt == "1" && "(sur cette place)"}
                    </Typography>
                }
                secondary={
                    <Typography style={{ color: "green" }}>
                        Disponible
                    </Typography>
                }
            />
    }

    if (place.otherIntel != "0" && place.information == "") {

        if (place.usualPlace == "1") {
            info = <ListItemText
                primary={
                    <Typography style={{ color: "orange" }}>
                        Place attribuée par défaut {place.onIt == "1" && <strong>(sur cette place)</strong>}
                    </Typography>
                }
                secondary={
                    <Typography variant="body2" style={{ color: "red" }}>
                        Occupée par {place.otherIntel}
                    </Typography>
                }
            />
        } else {
            info = <ListItemText
                primary={
                    <Typography style={{ color: "red" }}>
                        Occupée par {place.otherIntel}
                    </Typography>
                }
            />
        }

    } else if (place.otherIntel != "0" && place.information != "") {

        info = <ListItemText
            primary={
                <Typography style={{ color: place.usualPlace == "1" ? "orange" : "#3f51b5" }}>
                    {place.usualPlace == "0" ? "Recommandée" : "Place attribuée par défaut"} {place.onIt == "1" && <strong>(sur cette place)</strong>}
                </Typography>
            }
            secondary={
                <Typography variant="body2" style={{ color: "red" }}>
                    {`Occupée par ${place.otherIntel}`}
                </Typography>
            }
        />
    } else if (place.otherIntel == "0" && place.information != "") {

        info = <ListItemText
            primary={
                <Typography style={{ color: place.usualPlace == "1" ? "orange" : "#3f51b5" }}>
                    {place.usualPlace == "0" ? "Recommandée" : "Place attribuée par défaut"}
                </Typography>
            }
            secondary={
                <Typography style={{ color: "green" }} variant="body2">
                    Disponible
                </Typography>
            }
        />
    }

    return info;
}


const List = props => {
    const { className, list, numberOfPages, handlePagination, doPlacement, page, ...rest } = props;
    const classes = useStyles();

    return (
        <Grid container direction="column" spacing={0} alignItems="stretch">
            <Grid item>
                <TableContainer className={classes.list} >
                    <Table size="small">
                        <TableBody>
                            {
                                list.map((place, index) => {
                                    return (
                                        <StyledTableRow hover key={index}>
                                            <TableCell key={`1_${place.id}`} style={{ borderLeft: `5px solid ${place.onIt == "1" ? "orange" : place.otherIntel == "0" ? "#10ce10" : place.information != "" ? "#3f51b5" : "red"}` }} component="th" scope="row" align="left">
                                                <ListItemText
                                                    primary={"N°" + place.numero}
                                                    secondary={place.metres + " m"}
                                                />
                                            </TableCell>
                                            <TableCell key={`2_${place.id}`} align="left">
                                                {infoPlace(place)}
                                            </TableCell>
                                            <TableCell key={`3_${place.id}`} align="center">
                                                {
                                                    place.encaisse == "1" ?
                                                        <React.Fragment>
                                                            Réglé <DoneIcon style={{ fontSize: "inherit", color: "green" }} />
                                                        </React.Fragment> :
                                                        <Button variant="contained" onClick={() => doPlacement(place)} color="primary" style={{ background: place.onIt == "1" ? "orange" : "#3f51b5" }}>
                                                            {place.onIt == "1" ? "Modifier" : "Placer"}
                                                        </Button>
                                                }
                                            </TableCell>
                                        </StyledTableRow>
                                    );
                                })
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item>
                <ButtonGroup variant="text" size="large" fullWidth color="primary">
                    <Button disabled={Boolean(page == 1)} onClick={() => handlePagination(-1)}>
                        <ArrowBackIcon />
                    </Button>
                    <Button disabled={Boolean(page == numberOfPages)} onClick={() => handlePagination(1)}>
                        <ArrowForwardIcon />
                    </Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
};

export default List;
