// @flow

import L from 'leaflet';
import React from 'react';
import { withStyles } from '@material-ui/styles';
import { withLeaflet } from 'react-leaflet';
import transformRotate from '@turf/transform-rotate';
import cloneDeep from 'lodash/cloneDeep';

import { Statistic, Configuration, MarketPlace, AdresseBar, Actions } from './components';

const styles = theme => ({});

const defaultLayer = {
    isFree: false,
    selectedMerchant: [],
    selectedActivitie: [],
    isSubscriber: false,
    numero: '',
    meters: '',
    rotation: 0,
    water: false,
    electricity: false,
    car: false,
    sector: ''
};

const nonAffectedMerchant = {
    id: '1',
    activitieLabel: 'Non renseignée',
    isSubscriber: false,
    metierLabel: 'Aucun métier',
    raisonSociale: '',
    isPlaced: '0',
    place: []
};

class Control extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            actions: {
                now: {
                    select: {
                        isSelected: false,
                        text: 'Aucune place sélectionnée',
                        layer: null
                    },
                    text: 'Aucune action en cours',
                    layer: null
                },
                previous: {
                    count: 0,
                    list: []
                }
            },
            rollingBack: false,
            fnc: 'create',
            placesToShow: [],
            currentStateOfPlacesWasUpdated: false,
            listOfPlacesWasRebuild: false,
            counterHaveToUpdate: false,
            filters: [],
            filtersHasChanged: false,
            isEditable: false,
            isFree: false,
            water: false,
            electricity: false,
            car: false,
            selectedMerchant: null,
            selectedActivitie: '',
            isSubscriber: false,
            numero: '',
            meters: '',
            sector: '',
            rotation: 0,
            regexp: /^[0-9\b]+$/,
            layersGroup: L.featureGroup(),
            layer: {
                selectedLayer: null,
                flyOverLayer: null
            }
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            this.state.currentStateOfPlacesWasUpdated &&
            prevState.currentStateOfPlacesWasUpdated != this.state.currentStateOfPlacesWasUpdated
        ) {
            this.rebuild();
        }

        if (!this.props.raz && prevProps.raz != this.props.raz) {
            this.state.layersGroup.eachLayer(layer => {
                if (layer.is_a_place) {
                    layer.getConfig('numero').label.remove();
                    this.props.leaflet.map.removeLayer(layer.getConfig('numero').label);
                    layer.remove();
                    this.props.leaflet.map.removeLayer(layer);
                }
            });
            this.state.layersGroup.clearLayers();
            this.init();
        }

        //if (this.state.counterWasUpdated && (prevState.counterWasUpdated != this.state.counterWasUpdated)) {
        //this.countingStat();
        //}

        if (
            this.state.filtersHasChanged &&
            prevState.filtersHasChanged != this.state.filtersHasChanged
        ) {
            this.showByFilter();
        }

        //if (this.state.rollingBack && (prevState.rollingBack != this.state.rollingBack)) {
        //console.log("one more actionns")
        //}
    };

    componentWillUnmount = () => {
        //const { selectedLayer } = this.state.layer;
        //selectedLayer && selectedLayer.setActivitie(selectedLayer.getConfig("activitie"));
        this.rebuild();
    };

    componentDidMount = () => {
        this.state.layersGroup.addEventListener('click', this._onClick);
        this.state.layersGroup.addEventListener('mouseover', this._onMouseOver);
        this.state.layersGroup.addEventListener('mouseout', this._onMouseOut);
        this.props.leaflet.map.addEventListener('click', this._onClick);
        this.state.layersGroup.addTo(this.props.leaflet.map);
        this.init();
        //this.rebuild();
    };

    prefix = layer => {
        return layer.getConfig('options').sector === '' ? 'N°' : layer.getConfig('options').sector;
    };

    init = () => {
        const { layersGroup } = this.state;

        this.props.places.map(layer => {
            const marketPlace = new MarketPlace(layer.saved ?? false);
            marketPlace.setLatLngs(layer.coords);
            marketPlace.setActivitie(layer.activitie);
            marketPlace.setConfig('meters', layer.meters);
            marketPlace.setConfig('options', {
                water: layer.water,
                electricity: layer.electricity,
                car: layer.car,
                sector: layer.sector
            });
            marketPlace.setId(layer.leaflet_id);
            marketPlace.setNumero(layer.numero);
            marketPlace.setMerchant(layer.merchant);
            marketPlace.addTo(layersGroup);
            marketPlace.getConfig('numero').label.addTo(layersGroup);
            marketPlace.setConfig('rotation', layer.rotation);
            marketPlace.dragging.enable();
            marketPlace.addEventListener('dragstart', this._onDragStart);
            marketPlace.addEventListener('dragend', this._onDragEnd);
            marketPlace.setConfig('status', layer.saved ? 'untouched' : 'created');
        });

        this.setState({
            actions: {
                now: {
                    select: {
                        isSelected: false,
                        text: 'Aucune place sélectionnée',
                        layer: null
                    },
                    text: 'Aucune action en cours',
                    layer: null
                },
                previous: {
                    count: 0,
                    list: []
                }
            }
        });
    };

    showByFilter = () => {
        const { placesToShow, layersGroup } = this.state;

        const { places } = this.props;

        const { selectedLayer } = this.state.layer;

        let canCheck = true;
        let layer = null;

        places.map(place => {
            if (selectedLayer && selectedLayer.getId() == place.leaflet_id) {
                canCheck = false;
            }

            if (canCheck) {
                layer = layersGroup.getLayer(place.leaflet_id);

                if (placesToShow.indexOf(place.leaflet_id) > -1) {
                    layer.setActivitie(layer.getConfig('activitie'));
                } else {
                    layer.setStyle({
                        color: 'white'
                    });
                }
            }

            canCheck = true;
        });

        this.setState({
            filtersHasChanged: false
        });
    };

    rebuild = () => {
        const { layersGroup } = this.state;

        const places = [];
        const merchants = [];

        layersGroup.eachLayer(layer => {
            if (layer.is_a_place) {
                places.push(layer.get());
                if (layer.getConfig('merchant').id != '1') {
                    merchants.push(layer.getConfig('merchant'));
                }
            }
        });

        this.props.updateData('places', places);
        this.props.updateData('merchants', merchants);

        this.setState({
            currentStateOfPlacesWasUpdated: false,
            listOfPlacesWasRebuild: true
            //counterHaveToUpdate: true
        });
    };

    _clickThroughList = leafletId => {
        const { layersGroup } = this.state;

        this._onClick({ layer: layersGroup.getLayer(leafletId), originalEvent: { target: null } });
    };

    _onMouseOverThroughList = leafletId => {
        const { layersGroup } = this.state;

        this._onMouseOver({ layer: layersGroup.getLayer(leafletId) });
    };

    _onMouseOutThroughList = leafletId => {
        const { layersGroup } = this.state;

        this._onMouseOut({ layer: layersGroup.getLayer(leafletId) });
    };

    _onClick = ({ layer, originalEvent: { target } }) => {
        // Selected layer
        const { selectedLayer } = this.state.layer;
        const { map } = this.props.leaflet;
        const { actions } = this.state;

        if (target && target.classList.contains('leaflet-container')) {
            if (selectedLayer) {
                selectedLayer.setActivitie(selectedLayer.getConfig('activitie'));
            }

            actions.now.select.text = `Aucune place sélectionnée`;
            actions.now.select.layer = null;
            actions.now.select.isSelected = false;

            this.setState({
                ...defaultLayer,
                actions: actions,
                isEditable: false,
                fnc: 'create',
                filtersHasChanged: true,
                layer: {
                    flyOverLayer: null,
                    selectedLayer: null
                }
            });
        } else if (layer) {
            if (selectedLayer) {
                selectedLayer.setActivitie(selectedLayer.getConfig('activitie'));
            }

            layer.setStyle({
                color: 'blue'
            });

            map.setView(layer.getLatLngs()[0][0]);

            actions.now.select.text = `Place ${this.prefix(layer)}${
                layer.getConfig('numero').numero
            } sélectionnée`;
            actions.now.select.layer = layer;
            actions.now.select.isSelected = true;

            this.setState({
                isEditable: true,
                fnc: 'edit',
                filtersHasChanged: true,
                layer: {
                    selectedLayer: layer,
                    flyOverLayer: layer
                }
            });
        }
    };

    _onMouseOver = ({ layer }) => {
        const { selectedLayer } = this.state.layer;
        const { fnc } = this.state;

        if (fnc != 'drag') {
            if (selectedLayer) {
                selectedLayer.getId() != layer.getId() &&
                    layer.setStyle({ color: 'cornflowerblue' });
            } else {
                layer.setStyle({ color: 'cornflowerblue' });
            }

            this.setState({
                ...layer.getStateForBuild(),
                layer: {
                    selectedLayer: selectedLayer,
                    flyOverLayer: layer
                }
            });
        }
    };

    _onMouseOut = ({ layer }) => {
        const { selectedLayer } = this.state.layer;
        const { fnc } = this.state;

        if (fnc != 'drag') {
            if (selectedLayer) {
                selectedLayer.getId() != layer.getId() &&
                    layer.setActivitie(layer.getConfig('activitie'));
            } else {
                layer.setActivitie(layer.getConfig('activitie'));
            }

            const currentLayer = selectedLayer ? selectedLayer.getStateForBuild() : defaultLayer;

            this.setState({
                ...currentLayer,
                filtersHasChanged: true,
                layer: {
                    selectedLayer: selectedLayer,
                    flyOverLayer: null
                }
            });
        }
    };

    _onDragStart = ({ target }) => {
        target.getConfig('numero').label.setLatLng([0, 0]);
        this.setState({ fnc: 'drag' });
    };

    _onDragEnd = ({ target }) => {
        target.setNumero(target.getConfig('numero').numero, target.getConfig('is_free'), true);
        this.setState({ fnc: 'create' });
        //this.rebuild();
        this.setState({
            //currentStateOfPlacesWasUpdated: true,
        });
    };

    updateField = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    rotate = () => {
        const { rotation } = this.state;

        const { selectedLayer } = this.state.layer;

        const coords = [];
        let angle = rotation - selectedLayer.getConfig('rotation');

        transformRotate(selectedLayer.toGeoJSON(20), angle).geometry.coordinates[0].map(coord => {
            coords.push(L.latLng(coord[1], coord[0]));
        });

        selectedLayer.setConfig('rotation', rotation, true);
        selectedLayer.setLatLngs(coords);
    };

    removePlace = (selectedLayer = null, newAction = true) => {
        const { layersGroup, actions } = this.state;

        if (!selectedLayer) {
            selectedLayer = this.state.layer.selectedLayer;
        }

        if (selectedLayer.getConfig('saved')) {
            const removedPlaces = [...this.props.removedPlaces];
            removedPlaces.push(selectedLayer.getId());
            this.props.updateData('removedPlaces', removedPlaces);
        }

        selectedLayer.getConfig('numero').label.remove();
        selectedLayer.remove();
        layersGroup.removeLayer(selectedLayer.getConfig('numero').label);
        layersGroup.removeLayer(selectedLayer);

        //this.rebuild();
        //this.countingStat();

        if (newAction) {
            actions.now.text = `Place ${this.prefix(selectedLayer)}${
                selectedLayer.getConfig('numero').numero
            } supprimée`;
            actions.now.layer = selectedLayer;
            actions.now.select.text = `Aucune place sélectionnée`;
            actions.now.select.layer = null;
            actions.now.select.isSelected = false;

            actions.previous.list.push({
                text: `Place ${this.prefix(selectedLayer)}${
                    selectedLayer.getConfig('numero').numero
                } supprimée`,
                layer: cloneDeep(selectedLayer),
                actionNumero: actions.previous.count + 1,
                fnc: 'del'
            });

            actions.previous.count++;
        }

        this.setState({
            ...defaultLayer,
            isEditable: false,
            fnc: 'create',
            currentStateOfPlacesWasUpdated: true,
            counterHaveToUpdate: true,
            actions: actions,
            layer: {
                selectedLayer: null,
                flyOverLayer: null
            }
        });
    };

    editPlace = () => {
        const {
            selectedActivitie,
            selectedMerchant,
            isSubscriber,
            numero,
            meters,
            isFree,
            water,
            electricity,
            car,
            actions,
            sector
        } = this.state;

        const { selectedLayer } = this.state.layer;

        selectedLayer.setActivitie(this.getActivitieById(selectedActivitie), true);
        selectedLayer.setStyle({
            color: 'blue'
        });
        selectedLayer.setMeters(meters, true);
        selectedLayer.setNumero(numero, true);
        selectedLayer.setConfig(
            'options',
            {
                water: water,
                electricity: electricity,
                car: car,
                sector
            },
            true
        );

        if (!isFree) {
            selectedMerchant.isSubscriber = isSubscriber;
            selectedMerchant.place = selectedLayer.getForMerchant();
        } else {
            nonAffectedMerchant.isSubscriber = false;
            nonAffectedMerchant.place = selectedLayer.getForMerchant();
        }

        selectedLayer.setMerchant(isFree ? nonAffectedMerchant : selectedMerchant, true);

        //this.rebuild();
        //this.countingStat();

        actions.now.text = `Place ${this.prefix(selectedLayer)}${
            selectedLayer.getConfig('numero').numero
        } modifiée`;
        actions.now.layer = selectedLayer;
        actions.now.select.text = `Place ${this.prefix(selectedLayer)}${
            selectedLayer.getConfig('numero').numero
        } sélectionnée`;
        actions.now.select.layer = selectedLayer;
        actions.now.select.isSelected = false;

        actions.previous.list.push({
            text: `Place ${this.prefix(selectedLayer)}${
                selectedLayer.getConfig('numero').numero
            } modifiée`,
            layer: cloneDeep(selectedLayer),
            actionNumero: actions.previous.count + 1,
            fnc: 'edit'
        });

        actions.previous.count++;

        this.setState({
            ...selectedLayer.getStateForBuild(),
            filtersHasChanged: true,
            actions: actions,
            counterHaveToUpdate: true,
            currentStateOfPlacesWasUpdated: true
        });
    };

    addPlace = () => {
        const {
            selectedActivitie,
            selectedMerchant,
            isSubscriber,
            numero,
            meters,
            isFree,
            water,
            electricity,
            car,
            actions,
            sector
        } = this.state;

        const { layersGroup } = this.state;

        const { selectedLayer } = this.state.layer;

        if (selectedLayer) {
            selectedLayer.setActivitie(selectedLayer.getConfig('activitie'));
        }

        const { map } = this.props.leaflet;
        const marketPlace = new MarketPlace();
        marketPlace.addTo(layersGroup);

        marketPlace.setActivitie(this.getActivitieById(selectedActivitie));
        marketPlace.setMeters(meters, false, map.getCenter());
        marketPlace.setNumero(numero);
        marketPlace.setConfig('options', {
            water: water,
            electricity: electricity,
            car: car,
            sector
        });

        //[(isFree ? "nonAffectedMerchant" : "selectedMerchant")].place = marketPlace.getForMerchant();
        //[(isFree ? "nonAffectedMerchant" : "selectedMerchant")].isPlaced = "1";

        if (!isFree) {
            selectedMerchant.isSubscriber = isSubscriber;
            selectedMerchant.place = marketPlace.getForMerchant();
        } else {
            nonAffectedMerchant.isSubscriber = false;
            nonAffectedMerchant.place = marketPlace.getForMerchant();
        }

        marketPlace.setMerchant(isFree ? nonAffectedMerchant : selectedMerchant);
        marketPlace.getConfig('numero').label.addTo(layersGroup);
        marketPlace.setStyle({ color: 'blue' });
        marketPlace.dragging.enable();

        marketPlace.addEventListener('dragstart', this._onDragStart);
        marketPlace.addEventListener('dragend', this._onDragEnd);

        //this.rebuild();
        //this.countingStat();

        actions.now.text = `Place ${this.prefix(marketPlace)}${
            marketPlace.getConfig('numero').numero
        } créée`;
        actions.now.layer = marketPlace;
        actions.now.select.text = `Place ${this.prefix(marketPlace)}${
            marketPlace.getConfig('numero').numero
        } sélectionnée`;
        actions.now.select.layer = marketPlace;
        actions.now.select.isSelected = false;

        actions.previous.list.push({
            text: `Place ${this.prefix(marketPlace)}${
                marketPlace.getConfig('numero').numero
            } créée`,
            layer: cloneDeep(marketPlace),
            actionNumero: actions.previous.count + 1,
            fnc: 'add'
        });

        actions.previous.count++;

        this.setState({
            ...marketPlace.getStateForBuild(),
            isEditable: true,
            fnc: 'edit',
            counterHaveToUpdate: true,
            currentStateOfPlacesWasUpdated: true,
            filtersHasChanged: true,
            actions: actions,
            layer: {
                selectedLayer: marketPlace,
                flyOverLayer: null
            }
        });
    };

    getActivitieById = id => {
        let activitie = [];
        this.props.activities.map(act => {
            if (id == act.id) {
                activitie = act;
            }
        });

        return activitie;
    };

    disableDragging = _bool => {
        !_bool
            ? this.props.leaflet.map.dragging.enable()
            : this.props.leaflet.map.dragging.disable();
        !_bool
            ? this.props.leaflet.map.on('click', this._onClick)
            : this.props.leaflet.map.off('click');
    };

    render() {
        const {
            //classes,
            selectedActivitie,
            selectedMerchant,
            isSubscriber,
            numero,
            meters,
            rotation,
            isEditable,
            isFree,
            fnc,
            //filters,
            //numbers,
            water,
            electricity,
            car,
            placesToShow,
            listOfPlacesWasRebuild,
            counterHaveToUpdate,
            actions,
            layersGroup,
            sector
        } = this.state;

        const { selectedLayer } = this.state.layer;

        const { map } = this.props.leaflet;

        return (
            <div
                onMouseOver={() => this.disableDragging(true)}
                onMouseLeave={() => this.disableDragging(false)}>
                {/*<AdresseBar
          marketData={this.props.marketData}
          updateData={this.props.updateData}
          showPos={this.props.showPos}
          updateShowPos={this.props.updateShowPos}
        />*/}
                <Actions
                    actions={actions}
                    map={map}
                    layersGroup={layersGroup}
                    listOfPlacesWasRebuild={listOfPlacesWasRebuild}
                    removePlace={this.removePlace}
                    _onClick={this._clickThroughList}
                />
                <Statistic
                    activities={this.props.activities}
                    placesToShow={placesToShow}
                    places={this.props.places}
                    listOfPlacesWasRebuild={listOfPlacesWasRebuild}
                    counterHaveToUpdate={counterHaveToUpdate}
                    //numbers={numbers}
                    updateField={this.updateField}
                    //filters={filters}
                    //countingStat={this.countingStat}
                />
                <Configuration
                    merchants={this.props.merchants}
                    places={this.props.places}
                    activities={this.props.activities}
                    marketData={this.props.marketData}
                    updateData={this.props.updateData}
                    showPos={this.props.showPos}
                    updateShowPos={this.props.updateShowPos}
                    updateField={this.updateField}
                    placeData={{
                        numero: numero,
                        meters: meters,
                        rotation: rotation,
                        selectedMerchant: selectedMerchant,
                        selectedActivitie: selectedActivitie,
                        isSubscriber: isSubscriber,
                        isEditable: isEditable,
                        isFree: isFree,
                        water: water,
                        sector,
                        electricity: electricity,
                        car: car,
                        fnc: fnc,
                        layer: selectedLayer
                    }}
                    fncs={{
                        add: this.addPlace,
                        edit: this.editPlace,
                        remove: this.removePlace,
                        rotate: this.rotate,
                        rebuild: this.rebuild,
                        select: this._clickThroughList,
                        mouseHover: this._onMouseOverThroughList,
                        mouseOut: this._onMouseOutThroughList
                    }}
                />
            </div>
        );
    }
}

export default withStyles(styles)(withLeaflet(Control));
