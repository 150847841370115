import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
    Popover,
    Divider,
    ListItem,
    List,
    ListItemText,
    Typography,
    Paper,
    Grid,
    ListItemSecondaryAction,
    IconButton
} from '@material-ui/core';

import WarningIcon from '@material-ui/icons/Warning';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(theme => ({
    root: {

    },
}));

const Alert = props => {
    const { className, data, siret, anchorAlert, handleAlert, ...rest } = props;

    const classes = useStyles();

    const _handleAlert = () => {
        handleAlert();
    }

    return (

        <Popover
            open={Boolean(anchorAlert)}
            anchorEl={anchorAlert}
            onClose={_handleAlert}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <Paper elevation={4}>
                <Grid container direction="row"
                    justify="flex-start"
                    alignItems="flex-end"
                    spacing={1}
                    style={{ padding: 10, paddingBottom: 0 }}
                >
                    <Grid item>
                        <WarningIcon style={{ color: "red" }} />
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">
                            Alerte commmerçant
                        </Typography>
                    </Grid>
                </Grid>
                <List>
                    {
                        data.bloque != "0" &&
                        <React.Fragment>
                            <Divider />
                            <ListItem>
                                <ListItemText primary="Commerçant bloqué" secondary={data.bloque} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" component={RouterLink} to={`/merchants/get/${siret}/blocage`}>
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </React.Fragment>
                    }
                    {
                        data.assuranceManquante == "1" &&
                        <React.Fragment>
                            <Divider />
                            <ListItem>
                                <ListItemText primary="Assurance manquante" secondary="Aucun assureur n'a été saisi" />
                                <ListItemSecondaryAction>
                                    <IconButton  edge="end" component={RouterLink} to={`/merchants/get/${siret}/missed-data`}>
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </React.Fragment>
                    }
                    {
                        data.assuranceExpiree != "0" &&
                        <React.Fragment>
                            <Divider />
                            <ListItem>
                                <ListItemText primary="Assurance expirée" secondary={data.assuranceExpiree + " date de fin de validité dépassée"} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" component={RouterLink} to={`/merchants/get/${siret}/missed-data`}>
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </React.Fragment>
                    }
                    {
                        data.bapRetard != "0" &&
                        <React.Fragment>
                            <Divider />
                            <ListItem>
                                <ListItemText primary={<Typography>Retard de paiement</Typography>} secondary={data.bapRetard + " bon" + (data.bapRetard != "1" ? "s" : "") + " à payer n'" + (data.bapRetard != "1" ? "ont pas été réglés" : "a pas été réglé")} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" component={RouterLink} to={`/merchants/get/${siret}/facture`}>
                                        <ArrowForwardIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </React.Fragment>
                    }
                </List>
            </Paper>
        </Popover>

    );
};

export default Alert;
