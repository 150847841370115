import React from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';

import {
    Grid,
    InputAdornment,
    TextField,
    IconButton,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Table,
    TableContainer,
    Typography,
    CircularProgress
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

import { Pagination } from './components';

const styles = theme => ({
    root: {}
});

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    },
    hover: {
        '&$hover:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.08)'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

const WAIT_INTERVAL = 475;
const ENTER_KEY = 13;

class PreviewTable extends React.Component {
    constructor(props) {
        super(props);

        this.timer = null;
        this.state = {
            ...props,
            page: 1,
            init: true,
            numberOfPages: 0,
            columns: [],
            rows: [],
            records: 0,
            isLoading: true
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.update && this.props.update != prevProps.update) {
            this.setState({
                merchants: this.props.merchants,
                months: this.props.months,
                year: this.props.year,
                weeksPerYear: this.props.weeksPerYear,
                init: true,
                isLoading: true
            });
        }

        if (!this.state.init && prevState.init != this.state.init) {
            this.handleSearch({ target: { value: '' } });
        }

        if (this.state.isLoading && prevState.isLoading != this.state.isLoading) {
            this.getData();
        }
    };

    getData = () => {
        const { year, months, weeksPerYear, merchants, init, page, searchValue } = this.state;

        const formData = new FormData();
        formData.append('init', init);
        formData.append('year', year);
        formData.append('months', JSON.stringify(months));
        formData.append('merchants', JSON.stringify(merchants));
        formData.append('weeksPerYear', weeksPerYear);
        formData.append('page', page);
        formData.append('search', searchValue);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/preview-bills`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    isLoading: false,
                    init: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    this.setState({
                        isLoading: false
                    });
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleSearch = ({ target: { value } }) => {
        clearTimeout(this.timer);

        this.timer = setTimeout(this.refresh, WAIT_INTERVAL);

        this.setState({
            searchValue: value,
            isLoading: false,
            page: 1
        });
    };

    handleKeyDown = e => {
        if (e.keyCode === ENTER_KEY) {
            this.refresh();
        }
    };

    refresh = () => {
        this.setState({
            isLoading: true
        });
    };

    handlePagination = value => {
        this.setState({
            page: value,
            isLoading: true
        });
    };

    render() {
        const {
            classes,
            records,
            searchValue,
            columns,
            rows,
            isLoading,
            numberOfPages,
            page
        } = this.state;

        return (
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <TextField
                        label={'Rechercher dans les facture'}
                        helperText="Saisir une raison sociale, un numéro de facture ou un marché"
                        type="text"
                        fullWidth
                        value={searchValue}
                        onChange={this.handleSearch}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isLoading ? (
                                        <CircularProgress size={25} />
                                    ) : (
                                        <IconButton
                                            onClick={() =>
                                                this.handleSearch({ target: { value: '' } })
                                            }
                                            edge="end">
                                            <ClearIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item>
                    <Grid container spacing={1} direction="column">
                        <Grid item>
                            <Typography color="primary">Nombre de résultats : {records}</Typography>
                        </Grid>
                        <Grid item>
                            <TableContainer>
                                <Table stickyHeader size="small">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map(column => (
                                                <StyledTableCell
                                                    key={column.id}
                                                    align={column.align}>
                                                    {column.label}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, index) => {
                                            return (
                                                <StyledTableRow hover key={index}>
                                                    {columns.map((column, index) => (
                                                        <TableCell
                                                            key={`${column.id}_${index}`}
                                                            align="left">
                                                            <Typography variant="body1">
                                                                {row[column.id]}
                                                            </Typography>
                                                        </TableCell>
                                                    ))}
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item>
                            <Pagination
                                numberOfPages={numberOfPages}
                                currentPage={page}
                                handlePagination={this.handlePagination}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default PreviewTable;
