import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import {
    Dialog,
    DialogContent,
    Typography,
    CircularProgress,
    Grid,
    Slide
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    content: {
        padding: theme.spacing(2),
    },
    root: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/images/auth.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }
}))

const Logout = props => {
    const { className, ...rest } = props;
    const [dialogIsOpen, setDialogIsOpen] = React.useState(true);
    const [slideIsOpen, setSlideIsOpen] = React.useState(true);
    const classes = useStyles();

    //console.log(props.userProfile.getName())

    useEffect(() => {
        setTimeout(() => {
            setDialogIsOpen(false)
        }, 2500);
    }, []);

    !dialogIsOpen && setTimeout(() => {
        setSlideIsOpen(false)
    }, 500);

    !slideIsOpen && setTimeout(() => {
        props.history.push("sign-in");
    }, 500);


    return (
        <Slide in={slideIsOpen}>
            <div className={classes.root}>
                <Dialog open={dialogIsOpen}>
                    <DialogContent className={classes.content}>
                        <Grid container direction="column" alignItems="center" spacing={1}>
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                            <Grid item>
                                <Typography align="center">
                                    Veuillez patienter pendant la déconnexion...
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        </Slide>
    )
}

export default Logout;


