import React from 'react';
import { withStyles } from '@material-ui/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import GroupIcon from '@material-ui/icons/Group';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import DescriptionIcon from '@material-ui/icons/Description';
import DoneIcon from '@material-ui/icons/Done';
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

import JournalMarche from './../../JournalMarche';

import {
    Avatar,
    Paper,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Typography,
    ListItemText,
    Button,
    Menu,
    MenuItem,
    Card,
    CardContent,
    CardActionArea,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    DialogActions,
    ExpansionPanelSummary,
    ExpansionPanel,
    ExpansionPanelDetails
} from '@material-ui/core';

const currencies = [
    {
        value: 'esp',
        label: 'Numéraire',
        id: 1
    },
    {
        value: 'Chèque',
        label: 'Chèque',
        id: 2
    },
    {
        value: 'CB',
        label: 'Carte bancaire',
        id: 3
    },
    {
        value: 'Prélèvement',
        label: 'Prélèvement',
        id: 4
    },
    {
        value: 'Téléphone',
        label: 'Téléphone',
        id: 5
    }
];

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    cell: {
        padding: '10px 10px 10px 24px'
    },
    table: {
        maxHeight: 300,
        borderRight: '1px solid #eee',
        borderLeft: '1px solid #eee'
    },
    avatar: {
        backgroundColor: 'green',
        height: 48,
        width: 48
    },
    title: {
        //flex: '1 1 100%',
    },
    content: {
        padding: theme.spacing(3),
        flexGrow: 1
    },
    subtitle: {
        padding: '32px 0 0 16px'
    },
    info: {
        textAlign: 'center'
    },
    rotate: {
        transform: 'rotate(180deg)'
    }
});

class Finance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            filterLabel: 'p',
            anchorEl: null,
            cash: false,
            paiementMethod: '',
            meters: 20,
            id: 0,
            montantTotal: 20.05,
            detailsExpanded: false,
            totalEncaisse: 0,
            totalNonEncaisse: 0,
            totalEnRetard: 0,
            factures: [],
            journalplace_id: 0,
            myFacture: [],
            cheque: '',
            banque: '',
            search: '',
            count: 0,
            sum1: 0,
            sum2: 0,
            sum3: 0,
            sum4: 0,
            sum5: 0
        };
    }

    componentDidMount() {
        const { filterLabel } = this.state;

        this.update(filterLabel);
    }

    update = filterLabel => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/finance/${JournalMarche.getId()}/${filterLabel}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data

                this.setState({
                    totalEncaisse: data.montant.encaisse,
                    totalNonEncaisse: data.montant.non_encaisse,
                    totalEnRetard: 0,
                    factures: data.factures,
                    count: data.montant.count,
                    sum1: data.montant['1'],
                    sum2: data.montant['2'],
                    sum3: data.montant['3'],
                    sum4: data.montant['4'],
                    sum5: data.montant['5']
                });
            })
            .catch(console.log);
    };

    formatText(text, end) {
        if (text == '' || text == null) {
            return '';
        }

        let length = text.length;
        let textFormated = text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);

        if (length > end) {
            textFormated = textFormated.substring(0, end) + '...';
        }

        return textFormated;
    }

    handleFilterOpen = event => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleFilterClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    handleFilter = label => {
        this.setState({
            filterLabel: label,
            anchorEl: null
        });

        this.update(label);
    };

    handleCash = (value, id) => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/finance/${value}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data

                this.setState({
                    myFacture: data,
                    id: id,
                    cash: value,
                    paiementMethod: '',
                    banque: '',
                    cheque: ''
                });
            })
            .catch(console.log);
    };

    handleCloseCash = value => {
        this.setState({
            cash: value
        });
    };

    handlePaiementMethod = event => {
        this.setState({
            paiementMethod: event.target.value,
            banque: '',
            cheque: ''
        });
    };

    handleCheque = (key, { target: { value } }) => {
        this.setState({
            [key]: value
        });
    };

    handleMeters = i => {
        const { myFacture } = this.state;

        let metres = parseInt(myFacture.metres) + i < 3 ? 2 : parseInt(myFacture.metres) + i;

        let montants = (
            parseFloat(myFacture.prixAuMetre) *
            (parseInt(myFacture.metres) + i < 3 ? 2 : parseInt(myFacture.metres) + i)
        )
            .toString()
            .split('.');
        let montantTotal =
            montants[0] +
            ',' +
            (montants[1] ? (montants[1] + '00')[0] + (montants[1] + '00')[1] : '00');

        this.setState(prevState => ({
            myFacture: {
                ...myFacture,
                metres: metres,
                montant: montantTotal
            }
        }));
    };

    handleDetail = () => {
        this.setState(prevState => ({
            detailsExpanded: !prevState.detailsExpanded
        }));
    };

    send = () => {
        const { myFacture, id, paiementMethod, cheque, banque, filterLabel } = this.state;

        let moyenId = 0;

        currencies.map(c => {
            if (c.value == paiementMethod) {
                moyenId = c.id;
            }
        });

        if (paiementMethod == '') {
            alert('Veuillez renseigner un moyen de paiement');
            return;
        }

        if (paiementMethod == 'Chèque') {
            if (cheque == '') {
                alert('Veuillez indiquer un numéro de chèque valide');
                return;
            }

            if (cheque.length != 7) {
                alert('votre numéro de chèque doit comporter 7 chiffres');
            }

            if (banque == '') {
                alert('Veuillez indiquer une banque');
                return;
            }
        }

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/finance/${id}/update/${filterLabel}?cheque=${cheque}&banque=${banque}&metres=${
                myFacture.metres
            }&moyen_paiement_id=${moyenId}`
        ).catch(console.log);

        this.handleCloseCash(false);
        this.update(filterLabel);
    };

    getPdf = numFacture => {
        const { filterLabel } = this.state;

        let filter = 'E';

        if (filterLabel == 'ta') {
            filter = 'F';
        } else if (filterLabel == 'tna') {
            filter = 'V';
        }

        window.open(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/print/${filter}/${numFacture}`,
            '_blank'
        );
    };

    handleSearch = ({ target: { value } }) => {
        const { filterLabel } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/finance/${JournalMarche.getId()}/${filterLabel}?search=${value}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data

                this.setState({
                    search: value,
                    factures: data.factures
                });
            })
            .catch(console.log);
    };

    render() {
        const {
            classes,
            filterLabel,
            anchorEl,
            cash,
            paiementMethod,
            meters,
            montantTotal,
            detailsExpanded,
            factures,
            myFacture,
            totalEncaisse,
            totalNonEncaisse,
            search,
            count,
            sum1,
            sum2,
            sum3,
            sum4,
            sum5
        } = this.state;

        let labelM = 'passagers';

        if (filterLabel == 'ta') {
            labelM = 'titulaires abonnés';
        } else if (filterLabel == 'tna') {
            labelM = 'titulaires non abonnés';
        }

        return (
            <div className={classes.root}>
                <Dialog
                    open={cash}
                    onClose={() => this.handleCloseCash(false)}
                    fullWidth={true}
                    maxWidth="sm">
                    <DialogTitle>Récapitulatif avant encaissement</DialogTitle>
                    <DialogContent dividers style={{ padding: 0 }}>
                        <Table>
                            <TableBody>
                                <TableRow key={0} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                                    <TableCell padding="none" className={classes.cell} align="left">
                                        <ListItemText
                                            primary="Raison sociale"
                                            secondary="Enseigne"
                                        />
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1" color="primary">
                                                    {myFacture.raisonSociale}
                                                </Typography>
                                            }
                                            secondary={myFacture.alias}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow key={11}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Métier
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Typography variant="body1" color="primary">
                                            {myFacture.metier}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={1} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Marché
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Typography variant="body1" color="primary">
                                            {myFacture.marche}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={3}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Date
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Typography variant="body1" color="primary">
                                            {myFacture.dateJour}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={5} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Numéro de la place
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Typography variant="body1" color="primary">
                                            {myFacture.numero}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={85}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Nombre de mètres
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center">
                                            <Grid item>
                                                <Typography variant="body1" color="primary">
                                                    {myFacture.metres} mètres
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center">
                                                    <Grid item>
                                                        <IconButton
                                                            onClick={() => this.handleMeters(-1)}>
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton
                                                            onClick={() => this.handleMeters(1)}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={56} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Prix du mètre linéaire
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Typography variant="body1" color="primary">
                                            {myFacture.prixAuMetre} €
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={5628}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Moyen de paiement
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <TextField
                                                    select
                                                    size="small"
                                                    value={paiementMethod}
                                                    onChange={this.handlePaiementMethod}>
                                                    {currencies.map(option => (
                                                        <MenuItem
                                                            key={option.value}
                                                            value={option.value}>
                                                            <Typography
                                                                variant="body1"
                                                                color="primary">
                                                                {option.label}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            {paiementMethod == 'Chèque' && (
                                                <React.Fragment>
                                                    <Grid item>
                                                        <TextField
                                                            size="small"
                                                            name="banque"
                                                            onChange={event =>
                                                                this.handleCheque('banque', event)
                                                            }
                                                            placeholder="Banque"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            size="small"
                                                            name="cheque"
                                                            onChange={event =>
                                                                this.handleCheque('cheque', event)
                                                            }
                                                            placeholder="Chèque"
                                                        />
                                                    </Grid>
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={568} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <strong>Montant total</strong>
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Typography
                                            variant="body1"
                                            style={{ fontWeight: 'bold' }}
                                            color="primary">
                                            {myFacture.montant} €
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => this.handleCash(false)} color="primary">
                            Fermer
                        </Button>
                        <Button onClick={this.send} color="primary">
                            Encaisser
                        </Button>
                    </DialogActions>
                </Dialog>

                <Grid container spacing={4}>
                    <Grid item xs={9}>
                        <Paper className={classes.content}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} style={{ paddingBottom: 24 }}>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center">
                                        <Grid item>
                                            <Typography
                                                style={{ textTransform: 'uppercase' }}
                                                variant="h6">
                                                {count} {labelM} à régler
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                aria-haspopup="true"
                                                onClick={this.handleFilterOpen}>
                                                Filtrer <FilterListIcon style={{ marginLeft: 7 }} />
                                            </Button>
                                            <Menu
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={this.handleFilterClose}>
                                                <MenuItem onClick={() => this.handleFilter('ta')}>
                                                    Titulaires abonnés
                                                </MenuItem>
                                                <MenuItem onClick={() => this.handleFilter('tna')}>
                                                    Titulaires non abonnés
                                                </MenuItem>
                                                <MenuItem onClick={() => this.handleFilter('p')}>
                                                    Passagers
                                                </MenuItem>
                                            </Menu>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container justify="flex-end">
                                <Grid item>
                                    <TextField
                                        label="Rechercher un commerçant"
                                        type="text"
                                        value={search}
                                        onChange={this.handleSearch}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12}>
                                    <TableContainer style={{ maxHeight: '60vh' }}>
                                        <Table stickyHeader className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">
                                                        Raison Sociale
                                                    </TableCell>
                                                    <TableCell padding="none" align="left">
                                                        N°
                                                    </TableCell>
                                                    <TableCell align="left">Facture</TableCell>
                                                    <TableCell align="right">Montant</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {factures.map((facture, index) => (
                                                    <TableRow key={facture.numFacture}>
                                                        <TableCell align="left">
                                                            <ListItemText
                                                                primary={this.formatText(
                                                                    facture.raisonSociale,
                                                                    13
                                                                )}
                                                                secondary={this.formatText(
                                                                    facture.alias,
                                                                    15
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            padding="none"
                                                            align="left">
                                                            <ListItemText
                                                                primary={facture.numero}
                                                                secondary={
                                                                    facture.metres + ' mètres'
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left" padding="none">
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justify="flex-start"
                                                                alignItems="center">
                                                                <Grid item>
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            this.getPdf(
                                                                                facture.numFacture
                                                                            )
                                                                        }>
                                                                        <DescriptionIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item>
                                                                    {facture.encaisse == '1' ? (
                                                                        <ListItemText
                                                                            primary={
                                                                                facture.numFacture
                                                                            }
                                                                            secondary={
                                                                                <React.Fragment>
                                                                                    Réglé{' '}
                                                                                    <DoneIcon
                                                                                        style={{
                                                                                            fontSize:
                                                                                                'inherit',
                                                                                            color:
                                                                                                'green'
                                                                                        }}
                                                                                    />
                                                                                </React.Fragment>
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <Button
                                                                            color="primary"
                                                                            variant="outlined"
                                                                            onClick={() =>
                                                                                this.handleCash(
                                                                                    facture.journalplace_id,
                                                                                    facture.id
                                                                                )
                                                                            }
                                                                            size="small">
                                                                            Encaisser
                                                                        </Button>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {facture.montant} €
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={3} style={{ maxHeight: '80vh', overflow: ' auto' }}>
                        <Card>
                            <CardContent style={{ paddingBottom: 16 }}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="stretch"
                                    spacing={4}>
                                    <Grid item>
                                        <ExpansionPanel
                                            style={{ boxShadow: 'none', padding: 0 }}
                                            expanded={detailsExpanded}
                                            onChange={this.handleDetail}>
                                            <ExpansionPanelSummary>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: -15,
                                                        right: -15
                                                    }}>
                                                    <IconButton>
                                                        <ExpandMoreIcon
                                                            className={clsx({
                                                                [classes.rotate]: detailsExpanded
                                                            })}
                                                        />
                                                    </IconButton>
                                                </div>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justify="space-between"
                                                    alignItems="stretch"
                                                    spacing={1}>
                                                    <Grid item>
                                                        <Typography
                                                            className={classes.info}
                                                            variant="h3"
                                                            style={{ color: 'green' }}>
                                                            {totalEncaisse} €
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            className={classes.info}
                                                            variant="body2">
                                                            Total réglé
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justify="center"
                                                    alignItems="stretch"
                                                    spacing={4}>
                                                    <Grid item>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            justify="space-between"
                                                            alignItems="center">
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.info}
                                                                    variant="h5">
                                                                    {sum1} €
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.info}
                                                                    variant="body2">
                                                                    Numéraire
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        style={{
                                                            borderBottom: '1px solid #eee',
                                                            borderTop: '1px solid #eee'
                                                        }}>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            justify="space-between"
                                                            alignItems="center">
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.info}
                                                                    variant="h5">
                                                                    {sum2} €
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.info}
                                                                    variant="body2">
                                                                    Chèque
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            justify="space-between"
                                                            alignItems="center">
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.info}
                                                                    variant="h5">
                                                                    {sum3} €
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.info}
                                                                    variant="body2">
                                                                    Carte bancaire
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        style={{
                                                            borderBottom: '1px solid #eee',
                                                            borderTop: '1px solid #eee'
                                                        }}>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            justify="space-between"
                                                            alignItems="center">
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.info}
                                                                    variant="h5">
                                                                    {sum4} €
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.info}
                                                                    variant="body2">
                                                                    Prélèvement
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item>
                                                        <Grid
                                                            container
                                                            direction="column"
                                                            justify="space-between"
                                                            alignItems="center">
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.info}
                                                                    variant="h5">
                                                                    {sum5} €
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    className={classes.info}
                                                                    variant="body2">
                                                                    Téléphone
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>

                                    <Grid item style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                                        <Grid
                                            container
                                            direction="column"
                                            justify="space-between"
                                            alignItems="stretch"
                                            spacing={1}>
                                            <Grid item>
                                                <Typography
                                                    className={classes.info}
                                                    variant="h3"
                                                    style={{ color: 'red' }}>
                                                    {totalNonEncaisse} €
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    className={classes.info}
                                                    variant="body2">
                                                    Total non réglé
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/*<Grid item>
                    <Grid container
                      direction="column"
                      justify="space-between"
                      alignItems="stretch" spacing={1}>
                      <Grid item>
                        <Typography className={classes.info} style={{ color: "orange" }} variant="h3">
                          0 €
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.info} variant="body2">
                          Total en retard
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>*/}
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Finance);
