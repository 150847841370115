import React from 'react';
import { withStyles, makeStyles } from '@material-ui/styles';

import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Typography,
    IconButton,
    TableContainer,
    Table,
    TableRow,
    ListItemText,
    TableCell,
    TableBody,
    Button
} from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';
import ReplayIcon from '@material-ui/icons/Replay';
import PrintIcon from '@material-ui/icons/Print';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
        //flexGrow: 1
    }
});

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

class DialogForMerchant extends React.Component {
    constructor(props) {
        super();

        this.state = {
            ...props,
            isLoading: false,
            merchants: [],
            list: [],
            total: 0,
            totalDone: 0,
            totalSuccess: 0,
            generationIsDone: false
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        prevState.isLoading != this.state.isLoading && this.state.isLoading && this.getData();

        if (
            this.state.generationIsDone != prevState.generationIsDone &&
            this.state.generationIsDone
        ) {
            if (this.state.totalDone == this.state.totalSuccess) {
                alert(
                    'La génération de tous les états récapitulatifs a été effectuée avec succès.'
                );
            }
            if (this.state.totalDone != this.state.totalSuccess) {
                alert(
                    `Il semblerait que certains états récapitulatifs n'ont pas été générés (${this
                        .state.totalDone - this.state.totalSuccess} au total).`
                );
            }
        }

        if (this.state.totalDone == this.state.total && !this.state.generationIsDone) {
            this.setState({
                generationIsDone: true
            });
        }
    };

    componentDidMount = () => {
        const {
            marketSubscriberPerMerchant,
            marketNoneSubscriberPerMerchant,
            marketPassengerPerMerchant
        } = this.props;

        const formData = new FormData();
        formData.append('marketSubscriber', marketSubscriberPerMerchant);
        formData.append('marketNoneSubscriber', marketNoneSubscriberPerMerchant);
        formData.append('marketPassenger', marketPassengerPerMerchant);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/prepare-data-for-summary-statement-by-merchant`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    isLoading: true
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    getData = () => {
        const { merchants, list } = this.state;

        const { start, end } = this.props;

        const formData = new FormData();
        formData.append('start', start);
        formData.append('end', end);
        formData.append('market', true);
        formData.append('from', 'market');
        formData.append('domain', false);

        merchants.map(({ raisonSociale, id }, i) => {
            setTimeout(() => {
                fetch(
                    `https://www.${
                        window.location.hostname.split('.')[1]
                            ? window.location.hostname.split('.')[0] === 'www'
                                ? window.location.hostname.split('.')[1]
                                : window.location.hostname.split('.')[0]
                            : 'dev'
                    }.api.digitmarche.fr/api/merchant/get-summary-statement/${id}`,
                    {
                        method: 'POST',
                        body: formData
                    }
                )
                    .then(response => {
                        return response.ok ? response.json() : false;
                    })
                    .then(data => {
                        list[id] = {
                            ok: true,
                            link: data
                        };

                        this.setState(prevState => ({
                            list: list,
                            totalDone: prevState.totalDone + 1,
                            totalSuccess: prevState.totalSuccess + 1
                        }));
                    })
                    .catch(
                        error => {
                            list[id] = {
                                ok: false,
                                link: null
                            };

                            this.setState(prevState => ({
                                list: list,
                                totalDone: prevState.totalDone + 1
                            }));
                        } // Handle the error response object
                    );
            }, i * 1000);
        });
    };

    handleDialog = () => {
        this.props.handleDialogForMerchant();
    };

    getDataForOneMerchant = id => {
        const { list } = this.state;

        const { start, end } = this.props;

        const formData = new FormData();
        formData.append('start', start);
        formData.append('end', end);
        formData.append('market', true);
        formData.append('from', 'market');
        formData.append('domain', false);

        delete list[id];
        this.setState(prevState => ({
            list: list
        }));

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-summary-statement/${id}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                list[id] = {
                    ok: true,
                    link: data
                };

                this.setState(prevState => ({
                    list: list,
                    totalSuccess: prevState.totalSuccess + 1
                }));
            })
            .catch(
                error => {
                    list[id] = {
                        ok: false,
                        link: null
                    };

                    this.setState(prevState => ({
                        list: list
                    }));
                } // Handle the error response object
            );
    };

    getPdf = link => {
        window.open(link, '_blank');
    };

    render() {
        const { classes, list, merchants, total, totalDone, totalSuccess } = this.state;

        return (
            <Dialog open={true} fullWidth maxWidth={'sm'}>
                <DialogTitle>
                    Génération des états récapitulatifs par commerçant du marché ({totalSuccess}/
                    {total})
                </DialogTitle>
                <DialogContent dividers>
                    <TableContainer className={classes.container}>
                        <Table size="small">
                            <TableBody>
                                {merchants.map(merchant => {
                                    return (
                                        <StyledTableRow hover key={merchant.id}>
                                            <StyledTableCell align="left">
                                                <ListItemText
                                                    primary={merchant.raisonSociale}
                                                    secondary={
                                                        <Typography variant="body2">
                                                            {merchant.statut}
                                                        </Typography>
                                                    }
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                {list[merchant.id] ? (
                                                    list[merchant.id].ok ? (
                                                        <Button
                                                            color="primary"
                                                            onClick={() =>
                                                                this.getPdf(list[merchant.id].link)
                                                            }
                                                            endIcon={<PrintIcon />}>
                                                            Imprimer
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            onClick={() =>
                                                                this.getDataForOneMerchant(
                                                                    merchant.id
                                                                )
                                                            }
                                                            style={{ color: 'red' }}
                                                            endIcon={
                                                                <ReplayIcon
                                                                    style={{ color: 'red' }}
                                                                />
                                                            }>
                                                            Réessayer
                                                        </Button>
                                                    )
                                                ) : (
                                                    <Typography>
                                                        En cour de chargement...
                                                    </Typography>
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleDialog}
                        color="primary"
                        disabled={Boolean(totalDone != total)}>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(DialogForMerchant);
