import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
    Card,
    Stepper,
    Step,
    StepButton,
    StepLabel,
    StepConnector,
    Grid,
    Button,
    ButtonGroup
} from '@material-ui/core';
import StorefrontIcon from '@material-ui/icons/Storefront';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EuroIcon from '@material-ui/icons/Euro';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

/**
 *
 */
const steps = [
    {
        step: 0,
        page: 'dashboard',
        label: 'Gestion du marché'
    },
    {
        step: 1,
        page: 'synthesis',
        label: 'Synthèse du marché'
    }
];

/**
 *
 */
const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22
    },
    line: {
        height: 2,
        border: 0,
        backgroundColor: '#2979ff',
        borderRadius: 1
    }
})(StepConnector);

/**
 *
 */
const useColorlibStepIconStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 45,
        height: 45,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    },
    notActive: {
        backgroundColor: 'white',
        color: '#2979ff',
        border: '1px solid #2979ff'
    }
}));

/**
 *
 * @param {*} props
 * @returns
 */
function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, icon } = props;

    const icons = {
        1: <StorefrontIcon />,
        2: <AssignmentTurnedInIcon />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.notActive]: !active
            })}>
            {icons[String(props.icon)]}
        </div>
    );
}

/**
 *
 * @param {*} current
 * @returnsƒ
 */
function getCurrentStep(current) {
    const step = current === undefined ? steps[0] : steps.find(r => r.page === current);
    return step.step;
}

const useStyle = makeStyles(theme => ({
    buttonAction: {
        padding: 14
    }
}));

/**
 *
 */
export default props => {
    const { id, currentPage } = props;
    const activeStep = useMemo(() => getCurrentStep(currentPage), [currentPage]);
    const classes = useStyle();

    return (
        <Card>
            <Grid container direction="column" justify="center" alignItems="stretch">
                <Grid item>
                    <Stepper
                        nonLinear
                        alternativeLabel
                        activeStep={activeStep}
                        connector={<ColorlibConnector />}>
                        {steps.map((step, index) => (
                            <Step key={index}>
                                <StepButton
                                    component={RouterLink}
                                    to={`/my-market/${id}/${step.page}`}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                                        {step.label}
                                    </StepLabel>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <ButtonGroup fullWidth variant="text" size="large" color="primary">
                    <Button
                        className={classes.buttonAction}
                        color="secondary"
                        fullWidth
                        startIcon={<GroupAddIcon />}
                        component={RouterLink}
                        to={'/merchants/creation'}>
                        Créer un commerçant
                    </Button>
                    {/* <Button
                        className={classes.buttonAction}
                        style={{ color: 'green' }}
                        color="primary"
                        fullWidth
                        endIcon={<EuroIcon />}
                        component={RouterLink}
                        to={`/management/${id}/facturation/t`}>
                        Facturer un commerçant
                    </Button> */}
                </ButtonGroup>
            </Grid>
        </Card>
    );
};
