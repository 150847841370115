import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Grid,
    TableContainer,
    MenuItem,
    TextField,
    ListItemText,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import { formatAsBankCheck } from 'common/validators';
import { combineStyles } from 'common/styles';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';

/**
 * url
 */
const url = `https://www.${
    window.location.hostname.split('.')[1]
        ? window.location.hostname.split('.')[0] === 'www'
            ? window.location.hostname.split('.')[1]
            : window.location.hostname.split('.')[0]
        : 'dev'
}.api.digitmarche.fr/api`;

/**
 *
 */
const useStyles = makeStyles(
    combineStyles(theme => ({
        root: {
            //marginTop: -24,
            //marginBottom: -24
        },
        table: {
            tableLayout: 'fixed'
        },
        tableBody: {
            '& tr:nth-child(even)': {
                backgroundColor: 'rgba(0, 0, 0, 0.02)'
            },
            '& tr td': {
                paddingTop: 8,
                paddingBottom: 8
            },
            '& tr:first-child': {
                '& td': {
                    borderTop: '1px solid #eee'
                }
            }
        }
    }))
);

/**
 *
 * @param {*} props
 * @returns
 */
export default function BillDetail(props) {
    const { bill, fields, paymentMethod, paymentMethods, bank, errors } = props;

    const classes = useStyles();

    return (
        <TableContainer className={classes.root}>
            <Grid
                container
                direction="column"
                spacing={4}
                justifyContent="space-between"
                alignItems="stretch">
                <Grid item>
                    <Table size="small" className={classes.table}>
                        <TableBody className={classes.tableBody}>
                            {fields &&
                                Object.keys(fields).map(
                                    key =>
                                        fields[key].value !== 'paymentMethod' &&
                                        fields[key].value !== 'unitPrice' &&
                                        fields[key].value !== 'totalAmount' && (
                                            <TableRow hover key={`${key}-${fields[key].value}`}>
                                                <TableCell align="left">
                                                    {fields[key].label}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography color="primary">
                                                        {bill[fields[key].value]}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                )}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item>
                    <Table size="small" className={classes.table}>
                        <TableBody className={classes.tableBody}>
                            <TableRow hover key="paymentMethods">
                                <TableCell align="left">Moyen de paiement</TableCell>
                                <TableCell align="left">
                                    <TextField
                                        name="paymentMethods"
                                        type="text"
                                        fullWidth
                                        select
                                        variant="standard"
                                        size="small"
                                        error={Boolean(errors?.paymentMethod)}
                                        helperText={errors?.paymentMethod}
                                        value={paymentMethod.value}
                                        onChange={({ target: { value } }) =>
                                            paymentMethod.set(value)
                                        }>
                                        {paymentMethods.value.map(item => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </TableCell>
                            </TableRow>
                            <TableRow hover key="bank">
                                <TableCell align="left">
                                    <ListItemText primary="Banque" secondary="Numéro de chèque" />
                                </TableCell>
                                <TableCell align="left">
                                    <Grid
                                        container
                                        direction="column"
                                        spacing={1}
                                        justifyContent="center"
                                        justify="center"
                                        alignItems="stretch">
                                        <Grid item>
                                            <TextField
                                                placeholder="Crédit Agricole, LCL, Banque populaire..."
                                                disabled={!paymentMethod.isPayingByCheck}
                                                name="bank"
                                                error={Boolean(errors?.bank)}
                                                type="text"
                                                fullWidth
                                                variant="standard"
                                                size="small"
                                                value={bank.bank}
                                                helperText={errors?.bank}
                                                onChange={({ target: { value } }) =>
                                                    bank.setBank(value)
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                disabled={
                                                                    !paymentMethod.isPayingByCheck
                                                                }
                                                                onClick={() => bank.setBank('')}
                                                                edge="end"
                                                                size="small">
                                                                <ClearIcon fontSize="small" />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                placeholder="XXXXXXX"
                                                disabled={!paymentMethod.isPayingByCheck}
                                                name="bankCheck"
                                                error={Boolean(errors?.bankCheck)}
                                                helperText={errors?.bankCheck}
                                                type="text"
                                                fullWidth
                                                variant="standard"
                                                size="small"
                                                value={bank.check}
                                                onChange={({ target: { value } }) =>
                                                    bank.setCheck(formatAsBankCheck(value))
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                disabled={
                                                                    !paymentMethod.isPayingByCheck
                                                                }
                                                                onClick={() => bank.setCheck('')}
                                                                edge="end"
                                                                size="small">
                                                                <ClearIcon fontSize="small" />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item>
                    <Table size="small" className={classes.table}>
                        <TableBody className={classes.tableBody}>
                            {fields &&
                                Object.keys(fields).map(
                                    key =>
                                        (fields[key].value === 'unitPrice' ||
                                            fields[key].value === 'totalAmount') && (
                                            <TableRow hover key={`${key}-${fields[key].value}`}>
                                                <TableCell align="left">
                                                    <Typography variant="h6">
                                                        {fields[key].label}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography variant="h6" color="primary">
                                                        {
                                                            bill[
                                                                fields[key].value !== 'totalAmount'
                                                                    ? fields[key].value
                                                                    : '_totalAmount'
                                                            ]
                                                        }{' '}
                                                        €
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                )}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </TableContainer>
    );
}

BillDetail.propTypes = {
    bill: PropTypes.object.isRequired,
    paymentMethod: PropTypes.objectOf({
        set: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired
    }),
    fields: PropTypes.object.isRequired,
    bank: PropTypes.objectOf({
        bank: PropTypes.string.isRequired,
        setBank: PropTypes.func.isRequired,
        check: PropTypes.func.isRequired,
        setCheck: PropTypes.func.isRequired
    }).isRequired
};
