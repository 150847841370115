import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Avatar,
    Button,
    TableContainer,
    Table,
    TableBody,
    ListItemText,
    TableRow,
    TableCell,
    ListItem,
    ListItemAvatar
} from '@material-ui/core';

import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import CardActionArea from '@material-ui/core/CardActionArea';
import { getLastTerrace, getStatsBill } from '../services/http';
import { Doughnut } from 'react-chartjs-2';
import theme from 'theme';
import { combineStyles } from 'common/styles';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        height: 56,
        width: 56
    },
    number: {
        color: theme.palette.primary.main
    },
    icon: {
        height: 32,
        width: 32
    },
    labelAction: {
        width: '100%',
        textAlign: 'center'
    },
    footer: {
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center'
    }
}));

const useChartStyles = makeStyles(
    combineStyles(theme => ({
        root: {
            height: '100%'
        },
        content: {
            display: 'inline-flex',
            width: '100%',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'column'
        },
        chartContainer: {
            position: 'relative',
            //height: "30vh",
            width: '100%'
        },
        chartDispo: {
            width: 70,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
        },
        chartDispoText: {
            color: 'green'
        },
        cardAvatar: {
            color: theme.palette.getContrastText(theme.palette.primary.main),
            backgroundColor: theme.palette.primary.main
        },
        legendAvatar: {
            width: 36,
            height: 36
        },
        paidAvatar: {
            backgroundColor: theme.palette.paid.normal,
            '& *': {
                color: 'white !important'
            }
        },
        partially_paidAvatar: {
            backgroundColor: theme.palette.partially_paid.normal,
            '& *': {
                color: 'white !important'
            }
        },
        generatedAvatar: {
            backgroundColor: theme.palette.generated.normal,
            '& *': {
                color: 'white !important'
            }
        },
        listItem: {
            paddingLeft: 0
        },
        row: {
            '& *': {
                borderColor: '#eeeeee'
            },
            '&:last-child': {
                '& td': {
                    border: 0
                }
            }
        },
        listItemAvatar: {
            minWidth: 50
        },
        paid: {
            '& *': {
                color: theme.palette.paid.normal
            }
        },
        generated: {
            '& *': {
                color: theme.palette.generated.normal
            }
        },
        partially_paid: {
            '& *': {
                color: theme.palette.partially_paid.normal
            }
        }
    }))
);

const StatisticsCard = ({ className, rest, updateStats }) => {
    const classes = useStyles();
    const chartClasses = useChartStyles();
    const [chart, setChart] = useState({});

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData();
    }, [updateStats]);

    const getData = () => {
        getStatsBill()
            .then(res => setChart(res))
            .catch(err => alert(err));
    };

    const data = {
        labels: chart?.labels ?? [],
        datasets: [
            {
                borderColor: ['white', 'white', 'white'],
                backgroundColor: [
                    theme.palette.paid.normal,
                    theme.palette.partially_paid.normal,
                    theme.palette.generated.normal
                ],
                data: chart?.count ?? []
            }
        ]
    };

    const options = {
        responsive: true,
        onHover: (e, element) => {
            if (element.length > 0) {
                e.target.style.cursor = 'pointer';
            } else {
                e.target.style.cursor = 'auto';
            }
        },
        tooltips: {
            caretSize: 0,
            //displayColors: false,
            callbacks: {
                label: function(tooltipItem, data) {
                    return `${chart?.count[tooltipItem.index]} ${chart?.labels[tooltipItem.index]}`;
                },
                footer: function(tooltipItem, data) {
                    return `Total ${chart?.sum?.[tooltipItem[0].index]} €`;
                }
            }
        },
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        cutoutPercentage: 75,
        hover: {
            animationDuration: 0
        },
        responsiveAnimationDuration: 0
    };

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardContent>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    alignContent="center">
                    <Grid item xs={5}>
                        <div className={chartClasses.chartContainer}>
                            <div className={chartClasses.chartDispo}>
                                <Typography
                                    align="center"
                                    className={chartClasses.chartDispoText}
                                    variant="body2">
                                    {chart?.rate ?? '0'}% réglée(s)
                                </Typography>
                            </div>
                            <Doughnut data={data} options={options} />
                        </div>
                    </Grid>
                    <Grid item xs>
                        <TableContainer>
                            <Table size="small" padding="none">
                                <TableBody>
                                    {chart?.labels?.map((_, key) => (
                                        <TableRow
                                            key={key}
                                            className={clsx(
                                                chartClasses.row,
                                                chartClasses[chart.state?.[key]]
                                            )}>
                                            <TableCell align="left">
                                                <ListItem className={chartClasses.listItem}>
                                                    <ListItemAvatar
                                                        className={chartClasses.listItemAvatar}>
                                                        <Avatar
                                                            className={clsx(
                                                                chartClasses[
                                                                    `${chart.state[key]}Avatar`
                                                                ],
                                                                chartClasses.legendAvatar
                                                            )}>
                                                            <CheckIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="h5">
                                                                {chart?.count[key] ?? 0}
                                                            </Typography>
                                                        }
                                                        secondary={
                                                            <Typography
                                                                variant="body2"
                                                                style={{
                                                                    color: '#546e7a'
                                                                }}>
                                                                {chart?.labels[key] ?? ''}
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItem>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="h5">
                                                    {chart?.sum[key] ?? '0,00'} €
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default StatisticsCard;
