import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import {
    Dialog as MuiDialog,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress
} from '@material-ui/core';

import { DialogTitle } from './components';

const useStyles = makeStyles(theme => ({
    content: {}
}));

const Dialog = props => {
    const { title, onClose, open, children, action, loading } = props;

    const classes = useStyles();
    const close = () => {
        !loading && onClose();
    };

    return (
        <MuiDialog fullWidth maxWidth={'sm'} onClose={close} open={open}>
            <DialogTitle onClose={close}>{title}</DialogTitle>
            <DialogContent dividers className={classes.content}>
                {children}
            </DialogContent>
            <DialogActions>
                {loading && <CircularProgress size={25} />}
                <Button color="primary" disabled={loading} onClick={close}>
                    Fermer
                </Button>
                {action}
            </DialogActions>
        </MuiDialog>
    );
};

Dialog.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    action: PropTypes.element,
    loading: PropTypes.bool,
    children: PropTypes.node
};

export default Dialog;
