import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';

import { Radar } from 'react-chartjs-2';

import {
    Typography,
    Card,
    CardHeader,
    CardContent,
    Avatar
} from '@material-ui/core';

import TrendingUpIcon from '@material-ui/icons/TrendingUp';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    paperContent: {
        padding: theme.spacing(3),
    },
    labelFilter: {
        color: "#3f51b5",
        marginBottom: 15
    },
    labelFilterRadio: {
        color: "#3f51b5",
        marginBottom: 5
    }
}));

const AlertedMerchantsRadar = props => {
    const { className, rawData, date, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    const data = {
        labels: rawData.labels,
        datasets: [
            {
                borderWidth: 1,
                borderColor: theme.palette.primary.main,
                backgroundColor: "rgb(197, 202, 233, 0.5)",
                pointHoverBackgroundColor: theme.palette.primary.semi,
                fill: true,
                spanGaps: true,
                //lineTension: 0,
                data: rawData.data,
                pointRadius: 6,
                pointHoverRadius: 7,
            }
        ],
    }

    const options = {
        responsive: true,
        legend: {
            display: false,
        },
        scale: {
            angleLines: {
                display: true
            },
            ticks: {
                max: rawData.max,
                stepSize: rawData.step,
                beginAtZero: true
            }
        },
        tooltips: {
            caretSize: 0,
            displayColors: false,
            callbacks: {
                title: function (tooltipItem, data) {
                    return;
                },
                label: function (tooltipItem, data) {
                    return `${rawData.labels[tooltipItem.index]} : ${tooltipItem.yLabel}/${(tooltipItem.index == 1 ? rawData.maxHolders : rawData.max)}`;
                },
            }
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0 // animation duration after a resize
    }

    return (

        <Card>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        <TrendingUpIcon />
                    </Avatar>
                }
                title={
                    <Typography color="primary" variant="subtitle2">
                        Alerte données commerçants pour la période du {date.start} au {date.end}
                    </Typography>
                }
            />
            <CardContent>
                <Radar
                    data={data}
                    options={options}
                />
            </CardContent>
        </Card>
    );
};

export default AlertedMerchantsRadar;
