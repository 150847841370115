import React from 'react';
import env from 'common/env';

import { withStyles, makeStyles } from '@material-ui/styles';
import ScheduleIcon from '@material-ui/icons/Schedule';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PrintIcon from '@material-ui/icons/Print';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';

import {
    Grid,
    Paper,
    FormControl,
    TextField,
    Divider,
    Button,
    FormLabel,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Step,
    Stepper,
    StepContent,
    StepLabel,
    StepConnector,
    CircularProgress,
    Input,
    InputAdornment,
    Typography,
    Radio,
    RadioGroup
} from '@material-ui/core';

import { DialogForMerchant } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
        //flexGrow: 1
    },
    stepContent: {
        paddingLeft: theme.spacing(5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        margin: -10,
        fontWeight: 500
    },
    stepper: {
        //display: "grid"
        paddingLeft: theme.spacing(5)
    }
});

const ColorlibConnector = withStyles({
    alternativeLabel: {
        left: 10
    },
    line: {
        //height: 2,
        //border: 0,
        //backgroundColor: '#2979ff',
        //borderRadius: 1,
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        position: 'relative',
        right: 12,
        backgroundColor: '#3f51b5',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        backgroundColor: '#2979ff',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    },
    notActive: {
        backgroundColor: 'white',
        color: '#2979ff',
        border: '1px solid #2979ff'
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { icon } = props;

    const icons = {
        1: <SpellcheckIcon />,
        2: <AccountBalanceIcon />,
        3: <ScheduleIcon />,
        4: <DashboardIcon />,
        5: <PrintIcon />
    };

    return <div className={classes.root}>{icons[String(icon)]}</div>;
}

class SummaryStatement extends React.Component {
    constructor(props) {
        super();

        this.state = {
            ...props,
            link: false,
            isGenerating: false,
            start: '',
            end: '',
            label: '',
            regieCode: '',
            slipNumber: '',
            comment: '',
            domain: true,
            market: true,
            marketPerMerchant: true,
            marketSubscriber: false,
            marketNoneSubscriber: false,
            marketPassenger: false,

            marketSubscriberPerMerchant: false,
            marketNoneSubscriberPerMerchant: false,
            marketPassengerPerMerchant: false,

            domainAot: false,
            domainOther: false,
            domainEvent: false,
            domainTerrace: false,
            domainItinerantShop: false,
            domainPound: false,

            optionForMarketSummary: '',

            paymentMethods: [1, 2, 3],

            approvedDate: {
                start: new Date(),
                end: new Date()
            },
            options: {
                //weekday: 'numeric',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            users: [],
            userSelector: []
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        this.state.isGenerating && !this.state.marketPerMerchant && this.printSummary();

        if (
            (this.state.marketSubscriberPerMerchant ||
                this.state.marketNoneSubscriberPerMerchant ||
                this.state.marketPassengerPerMerchant) &&
            this.state.domain &&
            this.state.market
        ) {
            this.setState({
                marketPerMerchant: true,
                domain: false,
                market: false
            });
        }

        if (
            !this.state.marketSubscriberPerMerchant &&
            !this.state.marketNoneSubscriberPerMerchant &&
            !this.state.marketPassengerPerMerchant &&
            !this.state.domain &&
            !this.state.market
        ) {
            this.setState({
                marketPerMerchant: true,
                domain: true,
                market: true
            });
        }

        ///

        if (
            (this.state.marketSubscriber ||
                this.state.marketNoneSubscriber ||
                this.state.marketPassenger ||
                this.state.domainAot ||
                this.state.domainEvent ||
                this.state.domainTerrace ||
                this.state.domainItinerantShop ||
                this.state.domainOther ||
                this.state.domainPound) &&
            this.state.marketPerMerchant
        ) {
            this.setState({
                marketPerMerchant: false,
                domain: true,
                market: true
            });
        }

        if (
            !this.state.marketSubscriber &&
            !this.state.marketNoneSubscriber &&
            !this.state.marketPassenger &&
            !this.state.domainAot &&
            !this.state.domainEvent &&
            !this.state.domainItinerantShop &&
            !this.state.domainTerrace &&
            !this.state.domainOther &&
            !this.state.domainPound &&
            !this.state.marketPerMerchant
        ) {
            this.setState({
                marketPerMerchant: true,
                domain: true,
                market: true
            });
        }
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        fetch(`${env}/regie/get-data-for-summary-statement`)
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleDate = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    handleModule = ({ target: { name, checked } }) => {
        this.setState({
            [name]: checked
        });
    };

    handleModulePerMerchant = ({ target: { name, checked } }) => {
        this.setState(prevState => ({
            [name]: checked
        }));
    };

    handleForm = () => {
        const {
            start,
            end,
            domain,
            market,
            marketPerMerchant,
            marketSubscriber,
            marketNoneSubscriber,
            marketPassenger,
            marketSubscriberPerMerchant,
            marketNoneSubscriberPerMerchant,
            marketPassengerPerMerchant,
            domainOther,
            domainEvent,
            domainTerrace,
            domainItinerantShop,
            domainAot,
            approvedDate,
            optionForMarketSummary,
            paymentMethods,
            domainPound
        } = this.state;

        if (start == '') {
            alert('Veuillez saisir une date de début');
            return;
        }

        if (end == '') {
            alert('Veuillez saisir une date de fin');
            return;
        }

        const _start = new Date(start);
        const _end = new Date(end);

        if (_start.getTime() > _end.getTime()) {
            let inverse = window.confirm(
                'La date de début est supérieur à la date de fin\nInverser les dates ?'
            );
            inverse &&
                this.setState({
                    start: end,
                    end: start
                });
            return;
        }

        if (
            !marketSubscriber &&
            !marketNoneSubscriber &&
            !marketPassenger &&
            !domainOther &&
            !domainEvent &&
            !domainTerrace &&
            !domainItinerantShop &&
            !domainPound &&
            !marketNoneSubscriberPerMerchant &&
            !marketSubscriberPerMerchant &&
            !marketPassengerPerMerchant
        ) {
            alert('Veuillez sélectionner au moins un choix parmi les modules.');
            return;
        }

        if (marketPerMerchant) {
            let _confirm = window.confirm(
                'La génération des récapitulatifs par commerçant peut prendre quelques instants.\nVeuillez patienter pendant le chargement.'
            );
            if (!_confirm) {
                return;
            }
        }

        if (paymentMethods.length === 0) {
            alert(
                "Veuillez sélectionner au moins un moyen de paiement pour la génération de l'état des recettes."
            );
            return;
        }

        if (optionForMarketSummary == '' && market) {
            alert("Veuillez sélectionner une option pour la génération de l'état des recettes.");
            return;
        }

        this.setState({
            approvedDate: {
                start: _start,
                end: _end
            },
            //market: !Boolean(!marketSubscriber && !marketNoneSubscriber && !marketPassenger),
            //domain: !Boolean(!domainOther && !domainEvent),
            link: false,
            isGenerating: true
        });
    };

    printSummary = () => {
        const {
            start,
            end,
            domain,
            market,
            marketSubscriber,
            marketNoneSubscriber,
            marketPassenger,
            domainOther,
            domainEvent,
            domainAot,
            domainTerrace,
            domainItinerantShop,
            regieCode,
            slipNumber,
            label,
            optionForMarketSummary,
            comment,
            paymentMethods,
            userSelector,
            domainPound
        } = this.state;

        const formData = new FormData();
        formData.append('start', start);
        formData.append('end', end);
        formData.append('market', market);

        formData.append('marketSubscriber', marketSubscriber);
        formData.append('marketNoneSubscriber', marketNoneSubscriber);
        formData.append('marketPassenger', marketPassenger);

        formData.append('domain', domain);
        formData.append('domainOther', domainOther);
        formData.append('domainEvent', domainEvent);
        formData.append('domainTerrace', domainTerrace);
        formData.append('domainItinerantShop', domainItinerantShop);
        formData.append('domainAot', domainAot);
        formData.append('domainPound', domainPound);

        formData.append('regieCode', regieCode);
        formData.append('slipNumber', slipNumber);
        formData.append('label', label);
        formData.append('comment', comment);
        formData.append('optionForMarketSummary', optionForMarketSummary);

        formData.append('paymentMethods', JSON.stringify(paymentMethods));
        formData.append('users', JSON.stringify(userSelector));

        fetch(`${env}/regie/print-summary-statement`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    link: data,
                    isGenerating: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                    this.setState({
                        isGenerating: false
                    });
                } // Handle the error response object
            );
    };

    open = () => {
        window.open(this.state.link, '_blank');
    };

    handleDialogForMerchant = () => {
        this.setState({
            isGenerating: false
        });
    };

    handleIntel = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    handleUser = userId => {
        const bf = [...this.state.userSelector];
        const index = bf.indexOf(userId);

        if (index > -1) {
            bf.splice(index, 1);
        } else {
            bf.push(userId);
        }

        this.setState({
            userSelector: bf
        });
    };

    handlePaymentMethod = paymentid => {
        const bf = [...this.state.paymentMethods];
        const index = bf.indexOf(paymentid);

        if (index > -1) {
            bf.splice(index, 1);
        } else {
            bf.push(paymentid);
        }

        this.setState({
            paymentMethods: bf
        });
    };

    render() {
        const {
            classes,
            start,
            end,
            domain,
            market,
            marketPerMerchant,
            marketSubscriber,
            marketNoneSubscriber,
            marketPassenger,
            marketSubscriberPerMerchant,
            marketNoneSubscriberPerMerchant,
            marketPassengerPerMerchant,
            domainOther,
            domainEvent,
            domainTerrace,
            domainItinerantShop,
            domainAot,
            link,
            isGenerating,
            regieCode,
            slipNumber,
            label,
            optionForMarketSummary,
            comment,
            paymentMethods,
            users,
            userSelector,
            domainPound
        } = this.state;

        return (
            <div className={classes.root}>
                {marketPerMerchant && isGenerating && (
                    <DialogForMerchant
                        start={start}
                        end={end}
                        marketSubscriberPerMerchant={marketSubscriberPerMerchant}
                        marketNoneSubscriberPerMerchant={marketNoneSubscriberPerMerchant}
                        marketPassengerPerMerchant={marketPassengerPerMerchant}
                        handleDialogForMerchant={this.handleDialogForMerchant}
                    />
                )}

                <Stepper
                    className={classes.stepper}
                    component={Paper}
                    connector={<ColorlibConnector />}
                    orientation="vertical">
                    <Step active expanded key={0}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <FormLabel className={classes.labelFilter} component="label">
                                Libellé de l'état récapitulatif
                            </FormLabel>
                        </StepLabel>
                        <StepContent className={classes.stepContent}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="label"
                                        value={label}
                                        onChange={this.handleIntel}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </StepContent>
                    </Step>
                    <Step active expanded key={1}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <FormLabel className={classes.labelFilter} component="label">
                                Numéro de bordereau / Code régie
                            </FormLabel>
                        </StepLabel>
                        <StepContent className={classes.stepContent}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="slipNumber"
                                        value={slipNumber}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography>N° de bordereau</Typography>
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={this.handleIntel}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography>Code régie</Typography>
                                                </InputAdornment>
                                            )
                                        }}
                                        name="regieCode"
                                        value={regieCode}
                                        onChange={this.handleIntel}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </StepContent>
                    </Step>
                    <Step active expanded key={2}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <FormLabel className={classes.labelFilter} component="label">
                                Sélectionner des dates
                            </FormLabel>
                        </StepLabel>
                        <StepContent className={classes.stepContent}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="date"
                                        fullWidth
                                        name="start"
                                        value={start}
                                        onChange={this.handleDate}
                                        size="small"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="date"
                                        fullWidth
                                        size="small"
                                        name="end"
                                        value={end}
                                        onChange={this.handleDate}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </StepContent>
                    </Step>
                    <Step active expanded key={3}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <FormLabel className={classes.labelFilter} component="label">
                                Sélectionner par module
                            </FormLabel>
                        </StepLabel>
                        <StepContent className={classes.stepContent}>
                            <Grid container spacing={4} direction="row">
                                <Grid item xs={12} sm={6}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <FormLabel component="legend">
                                                Etat des recettes <strong>global</strong> pour le
                                                marché
                                            </FormLabel>

                                            {/*<FormControl component="fieldset" required>
                                                <FormControlLabel
                                                    label="Etat des recettes pour le domaine"
                                                //control={<Checkbox checked={domaib} onChange={this.handleModule} name="domain" />}
                                                />
        </FormControl>*/}
                                        </Grid>
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                        <Grid item>
                                            <FormControl component="fieldset" disabled={!market}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={marketSubscriber}
                                                                name="marketSubscriber"
                                                                onChange={this.handleModule}
                                                            />
                                                        }
                                                        label="Titulaire abonné"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={marketNoneSubscriber}
                                                                name="marketNoneSubscriber"
                                                                onChange={this.handleModule}
                                                            />
                                                        }
                                                        label="Titulaire non abonné"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={marketPassenger}
                                                                name="marketPassenger"
                                                                onChange={this.handleModule}
                                                            />
                                                        }
                                                        label="Passager"
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <FormLabel component="legend">
                                                Etat des recettes <strong>global</strong> pour le
                                                domaine
                                            </FormLabel>
                                            {/*<FormControl component="fieldset" required> 
                                                <FormControlLabel
                                                    label="Etat des recettes pour le domaine"
                                                   control={<Checkbox checked={market} onChange={this.handleModule} name="market" />}
                                                />
                                                    </FormControl>*/}
                                        </Grid>
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                        <Grid item>
                                            <FormControl component="fieldset" disabled={!domain}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        style={{ display: 'none' }}
                                                        control={
                                                            <Checkbox
                                                                checked={false}
                                                                name="domainAot"
                                                                onChange={this.handleModule}
                                                            />
                                                        }
                                                        label="Autorisation"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={domainEvent}
                                                                name="domainEvent"
                                                                onChange={this.handleModule}
                                                            />
                                                        }
                                                        label="Evénementiel"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={domainTerrace}
                                                                name="domainTerrace"
                                                                onChange={this.handleModule}
                                                            />
                                                        }
                                                        label="Terrasse"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={domainItinerantShop}
                                                                name="domainItinerantShop"
                                                                onChange={this.handleModule}
                                                            />
                                                        }
                                                        label="Commerce ambulant"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={domainOther}
                                                                name="domainOther"
                                                                onChange={this.handleModule}
                                                            />
                                                        }
                                                        label="Autres ventes"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={domainPound}
                                                                name="domainPound"
                                                                onChange={this.handleModule}
                                                            />
                                                        }
                                                        label="Fourrière"
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <FormLabel component="legend">
                                                Etat des recettes <strong>par commerçant</strong> du
                                                marché
                                            </FormLabel>
                                        </Grid>
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                        <Grid item>
                                            <FormControl
                                                component="fieldset"
                                                disabled={!marketPerMerchant}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    marketSubscriberPerMerchant
                                                                }
                                                                name="marketSubscriberPerMerchant"
                                                                onChange={
                                                                    this.handleModulePerMerchant
                                                                }
                                                            />
                                                        }
                                                        label="Titulaire abonné"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    marketNoneSubscriberPerMerchant
                                                                }
                                                                name="marketNoneSubscriberPerMerchant"
                                                                onChange={
                                                                    this.handleModulePerMerchant
                                                                }
                                                            />
                                                        }
                                                        label="Titulaire non abonné"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={marketPassengerPerMerchant}
                                                                name="marketPassengerPerMerchant"
                                                                onChange={
                                                                    this.handleModulePerMerchant
                                                                }
                                                            />
                                                        }
                                                        label="Passager"
                                                    />
                                                </FormGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StepContent>
                    </Step>
                    <Step active expanded key={4}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <FormLabel className={classes.labelFilter} component="label">
                                Générer l'état des recettes
                            </FormLabel>
                        </StepLabel>
                        <StepContent className={classes.stepContent}>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="column"
                                        justify="flex-start"
                                        alignItems="stretch">
                                        <Grid item>
                                            <Grid item>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">
                                                        Filtrer par utilisateur
                                                    </FormLabel>
                                                    <Divider
                                                        style={{ marginTop: 10, marginBottom: 10 }}
                                                    />
                                                    <FormGroup row>
                                                        {users.map(user => (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={Boolean(
                                                                            userSelector.find(
                                                                                r => r === user.id
                                                                            )
                                                                        )}
                                                                        onChange={e =>
                                                                            this.handleUser(user.id)
                                                                        }
                                                                        name="user"
                                                                    />
                                                                }
                                                                label={user.name}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item style={{ marginTop: 20 }}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">
                                                        Filtrer par moyen de paiement
                                                    </FormLabel>
                                                    <Divider
                                                        style={{ marginTop: 10, marginBottom: 10 }}
                                                    />
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(
                                                                        paymentMethods.find(
                                                                            r => r === 1
                                                                        )
                                                                    )}
                                                                    onChange={e =>
                                                                        this.handlePaymentMethod(1)
                                                                    }
                                                                    name="paymentMethods_1"
                                                                />
                                                            }
                                                            label="Numéraire"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(
                                                                        paymentMethods.find(
                                                                            r => r === 2
                                                                        )
                                                                    )}
                                                                    onChange={e =>
                                                                        this.handlePaymentMethod(2)
                                                                    }
                                                                    name="paymentMethods_2"
                                                                />
                                                            }
                                                            label="Chèque"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(
                                                                        paymentMethods.find(
                                                                            r => r === 3
                                                                        )
                                                                    )}
                                                                    onChange={e =>
                                                                        this.handlePaymentMethod(3)
                                                                    }
                                                                    name="paymentMethods_3"
                                                                />
                                                            }
                                                            label="Carte bancaire"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(
                                                                        paymentMethods.find(
                                                                            r => r === 4
                                                                        )
                                                                    )}
                                                                    onChange={e =>
                                                                        this.handlePaymentMethod(4)
                                                                    }
                                                                    name="paymentMethods_4"
                                                                />
                                                            }
                                                            label="Tresor Public"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={Boolean(
                                                                        paymentMethods.find(
                                                                            r => r === 6
                                                                        )
                                                                    )}
                                                                    onChange={e =>
                                                                        this.handlePaymentMethod(6)
                                                                    }
                                                                    name="paymentMethods_6"
                                                                />
                                                            }
                                                            label="Virement/Prélevement"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Trier par</FormLabel>
                                                <Divider
                                                    style={{ marginTop: 10, marginBottom: 10 }}
                                                />
                                                <RadioGroup
                                                    name="optionForMarketSummary"
                                                    value={optionForMarketSummary}
                                                    onChange={this.handleIntel}>
                                                    <FormControlLabel
                                                        disabled={!market}
                                                        value="withDistinction"
                                                        control={<Radio />}
                                                        label="Trier par numéro de facture croissant avec distinction des types de commerçant"
                                                    />
                                                    <FormControlLabel
                                                        disabled={!market}
                                                        value="withoutDistinction"
                                                        control={<Radio />}
                                                        label="Trier par numéro de facture croissant sans distinction des types de commerçant"
                                                    />
                                                    {/* <FormControlLabel
                                                        disabled={!market}
                                                        value="paymentMethod"
                                                        control={<Radio />}
                                                        label="Trier par numéro de facture croisant et par moyen de paiement"
                                                    /> */}
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label="Commentaires"
                                                multiline
                                                fullWidth
                                                rows={5}
                                                value={comment}
                                                name="comment"
                                                onChange={this.handleIntel}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center">
                                        <Grid item>
                                            <Button
                                                disabled={isGenerating}
                                                variant="contained"
                                                color="primary"
                                                onClick={this.handleForm}>
                                                Générer un état des recettes
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                disabled={!link}
                                                variant="contained"
                                                endIcon={<PrintIcon />}
                                                color="primary"
                                                onClick={this.open}>
                                                Imprimer
                                            </Button>
                                        </Grid>
                                        {isGenerating && (
                                            <Grid item>
                                                <CircularProgress color="primary" size={25} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        );
    }
}

export default withStyles(styles)(SummaryStatement);
