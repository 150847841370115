import React from 'react';
import { withStyles } from '@material-ui/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import TotalUsers from '../TotalUsers';
import PasswordStrengthBar from 'react-password-strength-bar';
import {
    Grid,
    Paper,
    TextField,
    Container,
    Button,
    MenuItem,
    InputAdornment,
    IconButton,
    FormControlLabel,
    Switch,
    Typography,
    Divider
} from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paper: {
        padding: '32px 48px 32px 48px'
    },
    button: {
        width: '100%',
        textAlign: 'center',
        marginTop: 40
    },
    actif: {
        padding: 16
    },
    paperHeader: {
        padding: 10
    }
});

const roles = [
    {
        nom: 'Placier',
        id: 'Placier'
    },
    {
        nom: 'Administrateur',
        id: 'Administrateur'
    },
    {
        nom: 'Régisseur',
        id: 'Regisseur'
    }
];

class Edit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            username: '',
            email: '',
            role: '',
            mdp: '',
            mdpVerif: '',
            status: true,
            showPassword: false,
            config: {
                nombre: 0,
                last: ''
            }
        };
    }

    componentDidMount = () => {
        const { id } = this.props.match.params;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/user/get/${id}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                this.setState({
                    username: data.username,
                    role: data.role,
                    email: data.email,
                    status: data.status
                });
            });

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/administrateur/settings`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                this.setState({
                    config: {
                        nombre:
                            data.administrateur_settings.les_utilisateurs
                                .nombre_utilisateurs_enregistres,
                        last:
                            data.administrateur_settings.les_utilisateurs
                                .dernier_utilisateur_enregistre
                    }
                });
            })
            .catch(console.log);
    };

    handleMerchantForm = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    showPasswd = value => {
        this.setState({
            showPassword: value
        });
    };

    handleCreate = () => {
        const { username, role, status, mdp, mdpVerif, email, history } = this.state;

        if (username == '') {
            alert("Veuillez saisir un nom d'utilisateur");
            return;
        }

        if (email == '') {
            alert('Veuillez saisir une adresse mail');
            return;
        }

        if (role == '') {
            alert('Veuillez sélectionnez un profil');
            return;
        }

        if (mdp == '') {
            alert('Veuillez saisir un mot de passe');
            return;
        }

        if (mdp.length < 4) {
            alert("Le mot de passe doit être composé d'au moins 4 caractères.");
            return;
        }

        if (mdpVerif == '') {
            alert('Veuillez à nouveau saisir votre mot de passe');
            return;
        }

        if (mdp != mdpVerif) {
            alert('Les mots de passe ne sont pas identiques');
            return;
        }

        const { id } = this.props.match.params;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/user/update/${id}?username=${username}&role=${role}&mdp=${mdp}&email=${email}&status=${status}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                if (data) {
                    history.push('/settings/users');
                } else {
                    alert("Ce nom d'utlisateur ou cette adresse mail existe déjà");
                }
            });
    };

    handleActif = value => {
        this.setState({
            status: value
        });
    };

    render() {
        const { classes, username, role, email, mdp, status, mdpVerif, showPassword } = this.state;

        return (
            <Container className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <TotalUsers data={this.state.config} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <div className={classes.paperHeader}>
                                <Typography variant="h5">
                                    <IconButton
                                        component={RouterLink}
                                        to="/settings/users"
                                        type="button">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    Mettre à jour
                                </Typography>
                            </div>
                            <Divider />
                            <div className={classes.paper}>
                                <Grid container className={classes.actif} spacing={4}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={status}
                                                name="status"
                                                onChange={() => this.handleActif(!status)}
                                            />
                                        }
                                        label="Actif"
                                    />
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            name="username"
                                            id="username"
                                            label="Nom d'utilisateur"
                                            type="text"
                                            onChange={this.handleMerchantForm}
                                            helperText="Saisir un nom d'utilisateur"
                                            value={username}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            name="email"
                                            id="email"
                                            label="Email"
                                            type="text"
                                            onChange={this.handleMerchantForm}
                                            helperText="Saisir une adresse mail"
                                            value={email}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            name="role"
                                            id="role"
                                            label="Rôle du profil"
                                            type="text"
                                            select
                                            onChange={this.handleMerchantForm}
                                            helperText="Choisir un rôle"
                                            value={role}
                                            variant="outlined">
                                            {roles.map((role, index) => (
                                                <MenuItem key={role.id} value={role.id}>
                                                    {role.nom}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            name="mdp"
                                            id="mdp"
                                            type={showPassword ? 'text' : 'password'}
                                            label="Mot de passe"
                                            onChange={this.handleMerchantForm}
                                            //helperText="Saisir un mot de passe"
                                            value={mdp}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() =>
                                                                this.showPasswd(!showPassword)
                                                            }>
                                                            {showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <PasswordStrengthBar
                                            password={mdp}
                                            shortScoreWord={'4 caractères minimum'}
                                            scoreWordStyle={{
                                                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                                fontSize: 11,
                                                color: '#546e7a'
                                            }}
                                            scoreWords={[
                                                'Très faible',
                                                'Faible',
                                                'Moyen',
                                                'Fort',
                                                'Très fort'
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            name="mdpVerif"
                                            id="mdpVerif"
                                            type={showPassword ? 'text' : 'password'}
                                            label="Vérification mot de passe"
                                            onChange={this.handleMerchantForm}
                                            //helperText="Saisir à nouveau votre mot de passe"
                                            value={mdpVerif}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() =>
                                                                this.showPasswd(!showPassword)
                                                            }>
                                                            {showPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <PasswordStrengthBar
                                            password={mdpVerif}
                                            shortScoreWord={'4 caractères minimum'}
                                            scoreWordStyle={{
                                                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                                fontSize: 11,
                                                color: '#546e7a'
                                            }}
                                            scoreWords={[
                                                'Très faible',
                                                'Faible',
                                                'Moyen',
                                                'Bon',
                                                'Très bon'
                                            ]}
                                        />
                                    </Grid>
                                </Grid>

                                <div className={classes.button}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleCreate}>
                                        Mettre à jour cet utilisateur
                                    </Button>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withStyles(styles)(Edit);
