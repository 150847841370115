import React from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';

import { Bar } from 'react-chartjs-2';

import {
    TableContainer,
    Table,
    TableBody,
    Typography,
    TableRow,
    TableCell
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    head: {
        borderBottom: "1px solid",
        borderBottomColor: theme.palette.secondary.main
    },
    cell: {
        borderLeft: "4px solid",
        borderLeftColor: theme.palette.secondary.main
    }
}));

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "rgba(0, 0, 0, 0.02)",
        },
    },
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: "#e1f0fb",
        color: "#3f51b5",
    },
}))(TableCell);



const RecipeBar = props => {
    const { className, merchants, places, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <StyledTableRow hover key={"merchantfree"}>
                        <StyledTableCell className={classes.head}>
                            <Typography color="primary">
                                Recette théorique
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell className={classes.head}>
                            <Typography color="primary">
                                {
                                    places.pricePerYear &&
                                    places.pricePerYear.countFromDayOne
                                } journées
                            </Typography>
                        </StyledTableCell>
                        <StyledTableCell className={classes.head}>
                            <Typography color="primary">
                                {
                                    places.pricePerYear &&
                                    places.pricePerYear.sumFromDayOne.formatted
                                } €
                            </Typography>
                        </StyledTableCell>
                    </StyledTableRow>
                    {
                        places.pricePerMonth ?
                            months.map((key) => {
                                return (
                                    <StyledTableRow hover key={"indicators" + key}>
                                        <StyledTableCell className={classes.cell}>
                                            {places.pricePerMonth[key].label}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {places.pricePerMonth[key].count} journées
                                    </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {places.pricePerMonth[key].sum.formatted} €
                                    </StyledTableCell>
                                    </StyledTableRow>
                                )
                            }) : null
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RecipeBar;
