import React from 'react';
import env from 'common/env';
import { makeStyles } from '@material-ui/styles';
import {
    Dialog,
    DialogContent,
    Typography,
    Button,
    DialogTitle,
    TextField,
    DialogActions
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {}
}));

const DialogComment = props => {
    const {
        className,
        open,
        data,
        openDialog,
        update,
        userProfile,
        updateComment,
        ...rest
    } = props;

    const classes = useStyles();

    const [comment, setComment] = React.useState('');

    const create = () => {
        if (!comment || comment.length == 0 || !comment.replace(/\s/g, '').length) {
            alert('Observation non valide');
            return;
        }

        const formData = new FormData();
        formData.append('comment', comment);
        formData.append('name', userProfile.getName());

        fetch(`${env}/merchant/litige-merchant/${data.marchand_id}`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                if (data) {
                    openDialog();
                    update();
                    updateComment();
                }
            });
    };

    const handleComment = ({ target: { value } }) => {
        setComment(value);
    };

    return (
        <Dialog open={open} onClose={openDialog} maxWidth={'sm'} fullWidth>
            <DialogTitle>
                <Typography color="primary" variant="button">
                    Créer une observation pour {data.raisonSociale} ?
                </Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="Observation"
                    variant="outlined"
                    onChange={handleComment}
                    InputLabelProps={{
                        shrink: true
                    }}
                    multiline
                    fullWidth
                    rows={4}
                    helperText="Saisir une observation"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={openDialog} color="primary">
                    Annuler
                </Button>
                <Button variant="contained" color="primary" onClick={create}>
                    Créer une observation
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogComment;
