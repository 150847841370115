import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {}
}));

const Field = props => {
    const { className, merchantId, refresh, ...rest } = props;

    const classes = useStyles();
    const [value, setValue] = React.useState('');

    const handleData = () => {
        if (!value || value.length == 0 || !value.replace(/\s/g, '').length) {
            alert('Donnée incorrect');
            return;
        }

        sendData();
    };

    const handleValue = ({ target: { value } }) => {
        setValue(value);
    };

    const sendData = () => {
        const confirmation = window.confirm(
            "Cette action mettra à jour l'adresse mail du commerçant, continuer ?"
        );

        if (confirmation) {
            const formData = new FormData();
            formData.append('field', 'mailMarchand');
            formData.append('data', value);

            fetch(
                `https://www.${
                    window.location.hostname.split('.')[1]
                        ? window.location.hostname.split('.')[0] === 'www'
                            ? window.location.hostname.split('.')[1]
                            : window.location.hostname.split('.')[0]
                        : 'dev'
                }.api.digitmarche.fr/api/merchant/update-fields-missed/${merchantId}`,
                {
                    method: 'POST',
                    body: formData
                }
            )
                .then(res => res.json())
                .then(data => {
                    if (data) {
                        alert('Mise à jour effectuée.');
                        refresh();
                    }
                })
                .catch(console.log);
        }
    };

    return (
        <Grid container direction="row" spacing={2} justify="center" alignItems="flex-start">
            <Grid item>
                <TextField
                    error
                    value={value}
                    type={'text'}
                    fullWidth
                    helperText="Adresse mail manquante"
                    onChange={handleValue}
                    size="small"
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <Button size="small" onClick={handleData} variant="contained" color="primary">
                    Mettre à jour
                </Button>
            </Grid>
        </Grid>
    );
};

export default Field;
