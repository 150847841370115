import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    Dialog,
    DialogContent,
    Button,
    Grid,
    DialogActions,
    MenuItem,
    TextField,
    CircularProgress
} from '@material-ui/core';

import { DialogTitle } from './components';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    tabs: {
        boxShadow: 'none',
        borderTop: '1px solid #eee'
    },
    contentInfo: {
        marginTop: theme.spacing(2)
    },
    contentOverview: {
        marginTop: theme.spacing(3)
    },
    contentList: {
        marginTop: theme.spacing(2)
    }
});

class EmailTemplateDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            object: '',
            label: '',
            content: '',
            type_id: '',
            types: [],
            stats: [],
            isValid: false
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.isValid && this.state.isValid != prevState.isValid) {
            this.sendData();
        }
    };

    getData = () => {
        const { method, id } = this.props.params;

        const _route = method + (method == 'edit' ? `/${id}` : '');

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/email/${_route}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    isValid: false
                });
            })
            .catch(error => {
                this.setState({
                    isValid: false
                });
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    sendData = () => {
        const { object, label, content, type_id } = this.state;

        const { method, id } = this.props.params;

        const _route = method + (method == 'edit' ? `/${id}` : '');
        const formData = new FormData();
        formData.append('object', object);
        formData.append('label', label);
        formData.append('content', content);
        formData.append('type_id', type_id);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/email/${_route}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                alert(`Modèle ${method == 'edit' ? 'modifié' : 'créé'} avec succès.`);
                this.handleClose();
            })
            .catch(error => {
                this.setState({
                    isValid: false
                });
                console.error(error);
                alert(`·Echec de la ${method == 'edit' ? 'modification' : 'création'} du modèle.`);
            });
    };

    handleClose = () => {
        this.props.history.goBack();
    };

    handleData = ({ target: { value, name } }) => {
        this.setState({
            [name]: value
        });
    };

    prepareData = () => {
        const { content, object, label, type_id } = this.state;

        if (!object.replace(/\s/g, '').length) {
            alert('Veuillez saisir un objet.');
            return;
        }

        /*if (!label.replace(/\s/g, '').length) {
            alert("Veuillez saisir un libellé.");
            return;
        }*/

        if (type_id == '') {
            alert('Veuillez sélectionner le type de relance.');
            return;
        }

        if (!content.replace(/\s/g, '').length) {
            alert('Veuillez saisir le corps du mail.');
            return;
        }

        this.setState({
            isValid: true
        });
    };

    render() {
        const { classes, types, type_id, object, label, content, isValid } = this.state;

        const { method } = this.props.params;

        return (
            <Dialog fullWidth maxWidth={'sm'} onClose={this.handleClose} open>
                <DialogTitle onClose={this.handleClose}>
                    {method == 'edit'
                        ? "Modifier un modèle d'email"
                        : "Ajouter un nouveau modèle d'email"}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <TextField
                                label={'Modèle de relance pour'}
                                fullWidth
                                select
                                disabled
                                //helperText="Sélectionner un type de relance"
                                value={type_id}
                                name="type_id"
                                variant="outlined"
                                onChange={this.handleData}>
                                {types.map((row, index) => (
                                    <MenuItem key={row.id} value={row.id}>
                                        {row.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                label={'Objet'}
                                type="text"
                                fullWidth
                                value={object}
                                name="object"
                                variant="outlined"
                                helperText="Saisir un objet"
                                onChange={this.handleData}
                            />
                        </Grid>
                        {/*<Grid item>
                            <TextField
                                label={"Libellé"}
                                type="text"
                                fullWidth
                                value={label}
                                name="label"
                                variant="outlined"
                                helperText="Saisir un libellé"
                                onChange={this.handleData}
                            />
                        </Grid>*/}
                        <Grid item>
                            <TextField
                                value={content}
                                name="content"
                                fullWidth
                                helperText="Saisir le corps du mail"
                                variant="outlined"
                                label={'Corps du mail'}
                                multiline
                                rows={10}
                                onChange={this.handleData}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {isValid && <CircularProgress color="primary" size={25} />}
                    <Button disabled={isValid} onClick={this.handleClose} color="primary">
                        Fermer
                    </Button>
                    <Button
                        disabled={isValid}
                        color="primary"
                        variant="contained"
                        onClick={this.prepareData}>
                        {method == 'edit' ? 'Modifier' : 'Ajouter'} le modèle
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(EmailTemplateDialog);
