import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';

import { Pie } from 'react-chartjs-2';

import {
    Typography,
    Card,
    CardHeader,
    CardContent,
    Avatar
} from '@material-ui/core';

import EqualizerIcon from '@material-ui/icons/Equalizer';

const Colors = {};
Colors.names = {
    aqua: "#00ffff",
    azure: "#f0ffff",
    beige: "#f5f5dc",
    black: "#000000",
    blue: "#0000ff",
    brown: "#a52a2a",
    cyan: "#00ffff",
    darkblue: "#00008b",
    darkcyan: "#008b8b",
    darkgrey: "#a9a9a9",
    darkgreen: "#006400",
    darkkhaki: "#bdb76b",
    darkmagenta: "#8b008b",
    darkolivegreen: "#556b2f",
    darkorange: "#ff8c00",
    darkorchid: "#9932cc",
    darkred: "#8b0000",
    darksalmon: "#e9967a",
    darkviolet: "#9400d3",
    fuchsia: "#ff00ff",
    gold: "#ffd700",
    green: "#008000",
    indigo: "#4b0082",
    khaki: "#f0e68c",
    lightblue: "#add8e6",
    lightcyan: "#e0ffff",
    lightgreen: "#90ee90",
    lightgrey: "#d3d3d3",
    lightpink: "#ffb6c1",
    lightyellow: "#ffffe0",
    lime: "#00ff00",
    magenta: "#ff00ff",
    maroon: "#800000",
    navy: "#000080",
    olive: "#808000",
    orange: "#ffa500",
    pink: "#ffc0cb",
    purple: "#800080",
    violet: "#800080",
    red: "#ff0000",
    silver: "#c0c0c0",
    white: "#ffffff",
    yellow: "#ffff00"
};

Colors.random = function () {
    var result;
    var count = 0;
    for (var prop in this.names)
        if (Math.random() < 1 / ++count)
            result = prop;
    return result;
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    paperContent: {
        padding: theme.spacing(3),
    },
    labelFilter: {
        color: "#3f51b5",
        marginBottom: 15
    },
    labelFilterRadio: {
        color: "#3f51b5",
        marginBottom: 5
    }
}));

const UnfreePlaceOccupation = props => {
    const { className, data, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    const occupation = [];
    const labels = [];
    const colors = []

    data.map((market, index) => {
        labels.push(market.date + " (" + market.label + ")")
        occupation.push(market.numberMeters)
        colors.push(Colors.names[Colors.random()])
    })


    const stat = {
        datasets: [
            {
                data: occupation,
                backgroundColor: colors,
                borderWidth: 4,
                borderColor: theme.palette.secondary.main,
                hoverBorderColor: theme.palette.secondary.main
            }
        ],
        labels: labels
    };


    const options = {
        legend: {
            display: true,
            position: "right",
            //fullWidth: true,
        },
        responsive: true,
        //maintainAspectRatio: true,
        animation: {
            duration: 3000,
        },
        //layout: { padding: 0 },
        tooltips: {
            enabled: true,
            callbacks: {
                label: function (tooltipItem) {
                    return data[tooltipItem.index].numberMeters + ' métres (' + data[tooltipItem.index].numberPlace + ' places)';
                },
            }
        }
    };


    return (

        <Card>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        <EqualizerIcon />
                    </Avatar>
                }
                title={
                    <Typography color="primary" variant="subtitle2">
                        Taux d'occupation du métre linéaire par journée de marché
                    </Typography>
                }
            />
            <CardContent>
                <Pie
                    data={stat}
                    height={250}
                    width={700}
                    options={options}
                />
            </CardContent>
        </Card>
    );
};

export default UnfreePlaceOccupation;
