import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';

import {
    Grid,
    Paper,
    Slide,
    Button,
    IconButton,
    Typography,
    Divider,
} from '@material-ui/core';

import {
    MiniForm,
    TabControl,
    TabContent,
    Build,
    Places,
    Holders,
    Merchant
} from './components'

import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import StateOfPayment from './components/StateOfPayment';

const useStyles = makeStyles(theme => ({
    button: {
        position: "absolute",
        right: 0,
        borderRadius: 0,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        marginTop: theme.spacing(6),
        zIndex: 999,
    },
    paper: {
        width: 270,
        position: "absolute",
        right: 0,
        height: "100%",
        zIndex: 1000,
    },
    config: {
        height: "100%"
    },
    tabcontrol: {
        paddingBottom: "0 !important",
        paddingTop: "0 !important"
    }
}));


const Configuration = props => {

    const {
        className,
        marketData,
        updateData,
        activities,
        meansOfPayment,
        places,
        merchants,
        showPos,
        updateShowPos,
        tab,
        updateField,
        placeData,
        fncs,
        //enableDraging,
        //disableDraging,
        ...rest
    } = props;

    const [open, setOpen] = React.useState(true);
    //const [tab, setTab] = React.useState("store");
    const classes = useStyles();
    const theme = useTheme();

    return (
        <React.Fragment>
            <Button
                size={"small"}
                startIcon={<MenuOpenIcon />}
                variant="contained"
                className={classes.button}
                onClick={() => setOpen(!open)}
            >
                Menu
            </Button>
            <Slide direction="left" in={open} mountOnEnter unmountOnExit>
                <Paper
                    square
                    className={classes.paper}
                    elevation={3}
                >
                    <Grid container direction="column" spacing={2} justify="flex-start" className={classes.config}>
                        <Grid item>
                            <Grid container direction="row" alignItems="center" style={{ margin: 7 }}>
                                <Grid item>
                                    <IconButton size="small" onClick={() => setOpen(!open)}>
                                        <ChevronRightIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        Masquer
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </Grid>
                        {
                        /*<Grid item style={{ paddingBottom: 0 }}>
                            <MiniForm
                                showPos={showPos}
                                updateShowPos={updateShowPos}
                                updateData={updateData}
                                data={marketData}
                            />
                        </Grid>
                        
                        <Grid item>
                            <Divider />
                        </Grid>
                        */}
                        <Grid item className={classes.tabcontrol}>
                            <TabControl
                                data={tab}
                                updateData={fncs.setTab}
                            />
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            {/*<TabContent value={tab} index={"home"}>
                                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                                    <Build
                                        places={places}
                                        placedMerchants={merchants}
                                        activities={activities}
                                        updateField={updateField}
                                        placeData={placeData}
                                        fncs={fncs}
                                    />
                                </Slide>
                    </TabContent>*/}
                            <TabContent value={tab} index={"merchant"}>
                                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                                    <Merchant
                                        setTab={fncs.setTab}
                                        layer={placeData.layer}
                                    />
                                </Slide>
                            </TabContent>
                            <TabContent value={tab} index={"store"}>
                                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                                    <Places
                                        activities={activities}
                                        places={places}
                                        placeData={placeData}
                                        fncs={fncs}
                                    />
                                </Slide>
                            </TabContent>
                            <TabContent value={tab} index={"holders"}>
                                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                                    <Holders
                                        activities={activities}
                                        merchants={merchants}
                                        placeData={placeData}
                                        fncs={fncs}
                                    />
                                </Slide>
                            </TabContent>
                            <TabContent value={tab} index={"payments"}>
                                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                                    <StateOfPayment
                                        meansOfPayment={meansOfPayment}
                                        merchants={merchants}
                                        placeData={placeData}
                                        fncs={fncs}
                                    />
                                </Slide>
                            </TabContent>
                        </Grid>
                    </Grid>
                </Paper>
            </Slide>
        </React.Fragment>
    );
};

export default Configuration;
