import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem
} from '@material-ui/core'
import data from 'views/Dashboard/components/LatestProducts/data';


const useStyles = makeStyles(theme => ({
    popper: {
        zIndex: 9
    },
    root: {
        display: "inline-flex"
    }
}));

/**
 * options
 */
const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
};

/**
 * 
 * @param {*} value 
 * @returns 
 */
function format(value) {
    return value.toISOString().slice(0, 10);
}

const MuiPopper = (props) => {
    const { open, clearForm, anchorRef, setOpen, setDate } = props
    const classes = useStyles();
    const today = new Date();


    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen();
    };

    const dateHandler = (what) => {

        if (what == 'today') {
            setDate(format(today), format(today));
        } else if (what == 'month') {
            const year = today.getFullYear();
            const month = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : "0" + (today.getMonth() + 1);
            const lastDay = new Date(today.getFullYear(), today.getMonth()+1, 1);
            setDate(`${year}-${month}-01`, format(lastDay));
        } else if (what == 'last-month') {
            const lastDay = new Date(today.getFullYear(), today.getMonth(), 1);
            const month = today.getMonth() > 9 ? today.getMonth() : "0" + today.getMonth();
            setDate(`${lastDay.getFullYear()}-${month}-01`, format(lastDay));
        } else if (what == 'year') {
            const year = today.getFullYear();
            setDate(`${year}-01-01`, format(today));
        } else if (what == 'last-year') {
            const year = today.getFullYear() - 1;
            setDate(`${year}-01-01`, `${year}-12-31`);
        }
    }

    return (
        <Popper className={classes.popper} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList>
                                <MenuItem
                                    key={1}
                                    onClick={(event) => {
                                        dateHandler('today');
                                        handleClose(event);
                                    }}
                                >
                                    Date du jour
                                </MenuItem>
                                <MenuItem
                                    key={2}
                                    onClick={(event) => {
                                        dateHandler('month');
                                        handleClose(event);
                                    }}
                                >
                                    Mois en cours
                                </MenuItem>
                                <MenuItem
                                    key={3}
                                    onClick={(event) => {
                                        dateHandler('last-month');
                                        handleClose(event);
                                    }}
                                >
                                    Mois précédent
                                </MenuItem>
                                <MenuItem
                                    key={4}
                                    onClick={(event) => {
                                        dateHandler('year');
                                        handleClose(event);
                                    }}
                                >
                                    Année en cours
                                </MenuItem>
                                <MenuItem
                                    key={5}
                                    onClick={(event) => {
                                        dateHandler('last-year');
                                        handleClose(event);
                                    }}
                                >
                                    Année précédente
                                </MenuItem>
                                <MenuItem
                                    key={6}
                                    style={{ color: "red" }}
                                    onClick={(event) => {
                                        clearForm();
                                        handleClose(event);
                                    }}
                                >
                                    Effacer tous les champs
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}

export default MuiPopper