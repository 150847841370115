import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { TextField, InputAdornment, Button } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles(theme => ({
    input: {
        display: 'none'
    }
}));

const FileInput = props => {
    const { className, file, setFile, ...rest } = props;

    const classes = useStyles();
    const ref = React.createRef();

    const addFile = () => {
        ref.current.click();
    };

    const removeFile = () => {
        setFile(null);
    };

    const handleFile = ({ target: { files } }) => {
        setFile(files[0]);
    };

    return (
        <div>
            <TextField
                type="text"
                fullWidth
                value={file ? file.name : ''}
                variant="outlined"
                InputProps={{
                    readOnly: true,
                    startAdornment: (
                        <InputAdornment position="start">
                            <Button size="small" onClick={addFile}>
                                Ajouter <AttachFileIcon />
                            </Button>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button size="small" onClick={removeFile}>
                                Supprimer <DeleteIcon />
                            </Button>
                        </InputAdornment>
                    )
                }}
            />
            <input type="file" onChange={handleFile} className={classes.input} ref={ref} />
        </div>
    );
};

export default FileInput;
