import React from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';

import { Grid, Button } from '@material-ui/core';

const styles = theme => ({
    root: {}
});

class Month extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            isLoading: true,
            months: [],
            selectedMonth: '',
            selectedMonths: []
        };
    }

    componentDidMount = () => {
        this.getData('GET');
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.frequency != this.props.frequency) {
            this.setState({
                frequency: this.props.frequency,
                isLoading: true
            });
        }

        if (this.state.isLoading && this.state.isLoading != prevState.isLoading) {
            this.getData();
        }

        if (!this.state.isLoading && this.state.isLoading != prevState.isLoading) {
            this.props.handleSelectedMonths(this.state.selectedMonths);
        }
    };

    handleMonth = value => {
        this.setState({
            isLoading: true,
            selectedMonth: value
        });
    };

    getData = (_method = 'POST') => {
        const { selectedMonths, selectedMonth, frequency } = this.state;

        const args = {
            method: _method
        };

        if (_method == 'POST') {
            const formData = new FormData();
            formData.append('month', selectedMonth);
            formData.append('frequency', frequency);
            args.body = formData;
        }

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/handle-months-calendar`,
            args
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    isLoading: false
                });
            })
            .catch(
                error => {
                    this.setState({
                        isLoading: false
                    });
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    render() {
        const { className, months, isLoading } = this.state;

        return (
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                {Object.keys(months).map(key => {
                    return (
                        <Grid item>
                            <Button
                                disabled={isLoading}
                                onClick={() => this.handleMonth(months[key].id)}
                                color={months[key].color}
                                variant={months[key].variant}>
                                {months[key].label}
                            </Button>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }
}

export default Month;
