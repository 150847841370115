import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    Grid,
    FormControl,
    FormLabel,
    TextField,
    Button
} from '@material-ui/core';

import { Pagination } from './components';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 15
    }
});

const columns = [
    /*{
        id: "dateJour",
        label: "Date",
        minWidth: "20%",
        align: 'center',
    },*/
    {
        id: 'marche',
        label: 'Marché (Journée)',
        minWidth: '40%',
        align: 'left'
    },
    {
        id: 'numero',
        label: 'Numéro',
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'metres',
        label: 'Nombre de mètres',
        minWidth: '20%',
        align: 'left'
    }
];

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            marchand_id: this.props.marchandId,
            list: [],
            start: '',
            end: '',
            isSubmited: false,
            approvedDate: {
                start: '',
                end: ''
            },
            page: 1,
            numberOfPages: 0
        };
    }

    componentDidMount = () => {
        //this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        prevState.page != this.state.page && this.getData();
        this.state.isSubmited && this.getData();
    };

    getData = () => {
        const { marchand_id, start, end, page } = this.state;

        this.setState({
            isSubmited: false
        });

        const formData = new FormData();
        formData.append('start', start);
        formData.append('end', end);
        formData.append('page', page);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-placements/${marchand_id}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                !data.numberOfPages && alert('Aucun placement trouvé pour cette période.');

                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleDate = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    handleForm = () => {
        const { start, end } = this.state;

        if (start == '') {
            alert('Veuillez saisir une date de début');
            return;
        }

        if (end == '') {
            alert('Veuillez saisir une date de fin');
            return;
        }

        const _start = new Date(start);
        const _end = new Date(end);

        if (_start.getTime() > _end.getTime()) {
            let inverse = window.confirm(
                'La date de début est supérieur à la date de fin\nInverser les dates ?'
            );
            inverse &&
                this.setState({
                    start: end,
                    end: start
                });
            return;
        }

        this.setState({
            isSubmited: true,
            approvedDate: {
                start: _start,
                end: _end
            }
        });
    };

    render() {
        const { classes, list, start, end, numberOfPages, page } = this.state;

        return (
            <Grid container spacing={3} direction="column" alignItems="stretch">
                <Grid item>
                    <FormControl component="fieldset" required>
                        <FormLabel className={classes.labelFilter} component="label">
                            Rechercher par date
                        </FormLabel>
                    </FormControl>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}>
                        <Grid item xs={12} md={5}>
                            <TextField
                                type="date"
                                fullWidth
                                name="start"
                                value={start}
                                onChange={this.handleDate}
                                size="small"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <TextField
                                type="date"
                                fullWidth
                                size="small"
                                name="end"
                                value={end}
                                onChange={this.handleDate}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={this.handleForm}>
                                Rechercher
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map(column => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}>
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map((placement, index) => {
                                    return (
                                        <TableRow hover key={index}>
                                            {columns.map(column => {
                                                return (
                                                    <TableCell
                                                        key={`${column.id}_${placement[column.id]}`}
                                                        align={column.align}>
                                                        {placement[column.id]}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {/*<Grid item>
                    <Pagination numberOfPages={numberOfPages} currentPage={page} handlePagination={this.handlePagination} />
                </Grid>*/}
            </Grid>
        );
    }
}

export default withStyles(styles)(History);
