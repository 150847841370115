import env from './env';

/**
 *
 * @returns
 */
const payments = async function getPaymentMethod() {
    const result = await fetch(`${env}/finance/get-way-of-payment-form`, {
        method: 'GET'
    })
        .then(res => (res.ok ? res.json() : []))
        .then(res => res)
        .catch(err => {
            console.error(err);
            throw 'Une erreur est survenue lors de la récupération des moyens de paiement.';
        });

    return result;
};

export default payments;
