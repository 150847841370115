import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import {
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableRow
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {

    },
}));

const ValidationForm = props => {
    const { className, data, ...rest } = props;

    const classes = useStyles();

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    <TableRow hover key="label">
                        <TableCell component="th" scope="row">
                            Libellé de l'événement
                        </TableCell>
                        <TableCell align="left">
                            {data.label}
                        </TableCell>
                    </TableRow>
                    <TableRow hover key="adresse">
                        <TableCell component="th" scope="row">
                            Adresse
                        </TableCell>
                        <TableCell align="left">
                            {data.adresse}
                        </TableCell>
                    </TableRow>
                    <TableRow hover key="nbplaces">
                        <TableCell component="th" scope="row">
                            Nombre de places
                        </TableCell>
                        <TableCell align="left">
                            {data.nbPlaces}
                        </TableCell>
                    </TableRow>
                    <TableRow hover key="nbplaces">
                        <TableCell component="th" scope="row">
                            Nombre de places occupées
                        </TableCell>
                        <TableCell align="left">
                            {data.nbPlacesOccuped}
                        </TableCell>
                    </TableRow>
                    <TableRow hover key="nbplaces">
                        <TableCell component="th" scope="row">
                            Commentaire
                        </TableCell>
                        <TableCell align="left">
                            {((data.commentaire == "null" || data.commentaire == "" || !data.commentaire) ? "Aucun commentaire" : data.commentaire)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ValidationForm;
