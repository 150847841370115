import env from 'common/env';

/**
 *
 * @param {*} $start
 * @param {*} $end
 * @param {*} $page
 * @param {*} $order
 * @param {*} $orderBy
 * @param {*} $type
 * @param {*} $filterHasChanged
 * @param {*} $search
 * @returns
 */
export async function getUnpaidBillsByMerchant(
    start,
    end,
    page,
    order,
    orderBy,
    type,
    filterHasChanged,
    search
) {
    const params = `start=${start}&end=${end}&page=${page}&order=${order}&orderBy=${orderBy}&type=${type}&filterHasChanged=${
        filterHasChanged ? 'y' : 'n'
    }&search=${search}`;

    const result = await fetch(`${env}/regie/get-unpaid-bills-by-merchant?${params}`, {
        method: 'GET'
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw `Echec de l'affichage des commerçants.`;
        });

    return result;
}

/**
 *
 * @param {*} ids
 * @param {*} start
 * @param {*} end
 * @returns
 */
export async function sendBills(ids, start, end) {
    const formData = new FormData();
    formData.append('ids', JSON.stringify(ids));
    formData.append('start', start);
    formData.append('end', end);

    const result = await fetch(`${env}/regie/send-unpaid-bills-by-merchant`, {
        method: 'POST',
        body: formData
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw `Echec de l'envoi des factures aux commerçants.`;
        });

    return result;
}
