import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  CardActionArea,
  Tooltip
} from '@material-ui/core';

import {
  Drafts,
  Send,
  NotInterested,
  Timelapse,
  CancelScheduleSend,
  Unsubscribe,
  HourglassEmpty
} from '@material-ui/icons'


const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  card: {
    height: "100%"
  },
  title: {
    fontWeight: 700
  },
  icon: {
    height: 32,
    width: 32
  },
  avatar: {
    float: 'right',
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  content: {
    '&:last-child': {
      paddingBottom: 16
    }
  },
}));

const CardInfo = props => {
  const { className, data, filter, setFilter, ...rest } = props;

  const classes = useStyles();
  const icons = {
    Drafts: <Drafts className={classes.icon} />,
    Send: <Send className={classes.icon} />,
    NotInterested: <NotInterested className={classes.icon} />,
    Timelapse: <Timelapse className={classes.icon} />,
    CancelScheduleSend: <CancelScheduleSend className={classes.icon} />,
    Unsubscribe: <Unsubscribe className={classes.icon} />,
    HourglassEmpty: <HourglassEmpty className={classes.icon} />
  }

  return (
    <Tooltip
      arrow
      title={data.description}
      interactive placement="top"
    >
      <CardActionArea className={classes.root} onClick={() => setFilter(data.key)}>
        <Card
          {...rest}
          raised={(Boolean(filter == data.key))}
        >
          <CardContent className={classes.content}>
            <Grid container direction="row" wrap="nowrap" justify="space-between" spacing={2}>
              <Grid item xs={10}>
                <Grid container direction="column"
                  justify="flex-start"
                  alignItems="stretch"
                  spacing={1}>
                  <Grid item>
                    <Typography className={classes.title} color='textSecondary' variant="body1">
                      {data.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container justify='flex-start' alignItems='flex-start' direction='column'>
                      <Grid item>
                        <Grid container direction="row" spacing={1} alignItems="flex-end">
                          <Grid item>
                            <Typography variant="h3" style={{ color: data.color }} color="primary">
                              {data.records}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              ({data.todayRecords} aujourd’hui)
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Avatar className={classes.avatar} style={{ backgroundColor: data.color }} >
                  {icons[data.icon]}
                </Avatar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardActionArea>
    </Tooltip>
  );
};

export default CardInfo;
