// @flow

import React from 'react'
import { LayersControl as LeafletLayersControl } from 'react-leaflet'


export default class LayersControl extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ...props
    }
  }

  render() {

    const { labels, layers, defaultChecked } = this.state;

    return (
      <LeafletLayersControl position="topright" collapsed={false}>

        {
          layers.map((layer, key) =>
            <LeafletLayersControl.Overlay key={key} name={labels[key]} checked={defaultChecked}>
              {layer}
            </LeafletLayersControl.Overlay>
          )
        }

      </LeafletLayersControl>
    )
  }
}
