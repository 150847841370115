import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DescriptionIcon from '@material-ui/icons/Description';

import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableCell,
    Card,
    CardHeader,
    Avatar,
    CardContent,
    TableRow,
    TableBody,
    IconButton,
    Button,
    ListItemText
} from '@material-ui/core';

const columns = [
    {
        id: 'numero',
        label: 'numero',
        minWidth: '15%',
        align: 'left'
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: '70%',
        align: 'left'
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: '15%',
        align: 'center'
    }
];

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    avatar: {
        backgroundColor: 'orange'
    },
    title: {
        color: theme.palette.primary.main
    },
    container: {
        maxHeight: '45vh'
    }
}));

const LateBills = props => {
    const { className, data, ...rest } = props;

    const getPdf = numFacture => {
        window.open(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/print/A/${numFacture}`,
            '_blank'
        );
    };

    const classes = useStyles();

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        <WatchLaterIcon />
                    </Avatar>
                }
                title={
                    <Typography style={{ color: 'orange' }} variant="subtitle2">
                        Les factures en retard
                    </Typography>
                }
                subheader={`${data.count.total} factures en retard au total`}
            />
            <CardContent>
                <TableContainer className={classes.container}>
                    <Table size="small" stickyHeader>
                        <TableBody>
                            {data.list.map((bill, index) => {
                                return (
                                    <TableRow hover key={index}>
                                        {columns.map(column => {
                                            if (column.id == 'action') {
                                                return (
                                                    <TableCell
                                                        key={`${column.id}_${bill[column.id]}`}
                                                        align={column.align}>
                                                        <IconButton
                                                            component={RouterLink}
                                                            to={`/merchants/get/${bill.siret}/facture`}>
                                                            <ArrowForwardIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                );
                                            }

                                            if (column.id == 'numero') {
                                                return (
                                                    <TableCell
                                                        key={`${column.id}_${bill[column.id]}`}
                                                        align={column.align}>
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justify="flex-start"
                                                            alignItems="center">
                                                            <Grid item>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        getPdf(bill.numero)
                                                                    }>
                                                                    <DescriptionIcon />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item>
                                                                <ListItemText
                                                                    primary={bill.numero}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                );
                                            }

                                            return (
                                                <TableCell
                                                    key={`${column.id}_${bill[column.id]}`}
                                                    align={column.align}>
                                                    {bill[column.id]}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                            {parseInt(data.count.total) > 10 && (
                                <TableRow hover={false} key={'seemore'}>
                                    <TableCell key={`see_more`} align={'center'} colSpan={3}>
                                        <Button
                                            color="primary"
                                            component={RouterLink}
                                            to={`/late-bills/2020-01-01/${data.lastDayOfPreviosMonth}/F`}>
                                            Voir plus de factures...
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default LateBills;
