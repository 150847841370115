import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Paper,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    ButtonGroup,
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Button,
    Radio,
    Divider,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableRow,
    TableHead,
    ListItemText
} from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import PrintIcon from '@material-ui/icons/Print';

import { Pagination } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

const columns = [
    {
        id: 'numero',
        label: 'Numéro',
        minWidth: '25%',
        align: 'left'
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: '60%',
        align: 'left'
    },
    {
        id: 'montant',
        label: 'Montant',
        minWidth: '15%',
        align: 'center'
    }
];

class BillSynthesis extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            searchValue: '',
            type: this.props.filter == 't' ? 'ta' : 'p',
            list: [],
            total: 0,
            page: 1,
            numberOfPages: 0
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.filter != this.props.filter) {
            this.setState({
                type: this.props.filter == 't' ? 'ta' : 'p'
            });
        }

        (prevState.type != this.state.type ||
            prevState.page != this.state.page ||
            prevState.searchValue != this.state.searchValue) &&
            this.getData();
    };

    getData = () => {
        const { type, searchValue, page } = this.state;

        const { id } = this.props;

        const formData = new FormData();
        formData.append('search', searchValue);
        formData.append('page', page);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/synthesis/${id}/get-facture-by/${type}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handleTypeFilter = ({ target: { value } }) => {
        this.setState({
            type: value,
            page: 1
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    getPdf = numFacture => {
        window.open(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/print/A/${numFacture}`,
            '_blank'
        );
    };

    getPdfList = () => {
        const { type } = this.state;

        const { id } = this.props;

        let _confirm = window.confirm('Imprimer la sélection ?');
        _confirm &&
            window.open(
                `https://www.${
                    window.location.hostname.split('.')[1]
                        ? window.location.hostname.split('.')[0] === 'www'
                            ? window.location.hostname.split('.')[1]
                            : window.location.hostname.split('.')[0]
                        : 'dev'
                }.api.digitmarche.fr/api/synthesis/print-get-by?id=${id}&type=${type}`,
                '_blank'
            );
    };

    formatCash = value => {
        let montants = parseFloat(value)
            .toString()
            .split('.');
        return (
            montants[0] +
            ',' +
            (montants[1] ? (montants[1] + '00')[0] + (montants[1] + '00')[1] : '00') +
            ' €'
        );
    };

    render() {
        const { classes, searchValue, type, list, page, numberOfPages, total } = this.state;

        const { id, filter } = this.props;

        return (
            <Paper className={classes.paperContent}>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            justify="space-between"
                            alignItems="baseline">
                            <Grid item>
                                <Typography variant="h4" color="primary">
                                    Synthèse de l'encaissement
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    endIcon={<PrintIcon />}
                                    onClick={this.getPdfList}
                                    color="primary">
                                    Imprimer
                                </Button>
                            </Grid>
                            {/*<Grid item>
                                <ButtonGroup fullWidth color="primary">
                                    <Button fullWidth component={RouterLink} to={`/management/${id}/synthesis/t`} color="primary" variant={filter == "t" ? "contained" : "outlined"} >Titulaires</Button>
                                    <Button fullWidth component={RouterLink} to={`/management/${id}/synthesis/p`} color="primary" variant={filter == "p" ? "contained" : "outlined"}>Passagers</Button>
                                </ButtonGroup>
                            </Grid>*/}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={'Rechercher un ' + (type == 'p' ? 'passager' : 'titulaire')}
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={searchValue}
                            onChange={this.handleSearch}
                            helperText="Saisir une raison sociale, une enseigne, un nom, un prénom ou un numéro de facture"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={this.clearSearch} edge="end">
                                            <ClearIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" className={classes.labelFilter}>
                                Filtrer par statut
                            </FormLabel>
                            <RadioGroup
                                row
                                name="type"
                                value={type}
                                onChange={this.handleTypeFilter}>
                                <FormControlLabel
                                    /*isabled={Boolean(filter == "p")}*/ value="ta"
                                    control={<Radio />}
                                    label="Titulaires abonnés"
                                />
                                <FormControlLabel
                                    /*disabled={Boolean(filter == "p")}*/ value="tna"
                                    control={<Radio />}
                                    label="Titulaires non abonnés"
                                />
                                <FormControlLabel
                                    /*disabled={Boolean(filter == "t")}*/ value="p"
                                    control={<Radio />}
                                    label="Passagers"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item style={{ paddingTop: 7, paddingBottom: 0 }}>
                        <Typography variant="body1" color="primary">
                            Nombre de résultat(s) trouvé(s) : {total}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map(column => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list.map((bill, index) => {
                                        return (
                                            <TableRow hover key={index}>
                                                {columns.map(column => {
                                                    if (column.id == 'numero') {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${
                                                                    bill[column.id]
                                                                }`}
                                                                align={column.align}>
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justify="flex-start"
                                                                    alignItems="center">
                                                                    <Grid item>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                this.getPdf(
                                                                                    bill.numero
                                                                                )
                                                                            }>
                                                                            <DescriptionIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <ListItemText
                                                                            primary={bill.numero}
                                                                            secondary={
                                                                                bill.encaisse ==
                                                                                '0' ? (
                                                                                    <React.Fragment>
                                                                                        Non réglé{' '}
                                                                                        <ClearIcon
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    'inherit',
                                                                                                color:
                                                                                                    'red'
                                                                                            }}
                                                                                        />
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        Réglé{' '}
                                                                                        <DoneIcon
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    'inherit',
                                                                                                color:
                                                                                                    'green'
                                                                                            }}
                                                                                        />
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (column.id == 'montant') {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${
                                                                    bill[column.id]
                                                                }`}
                                                                align={column.align}>
                                                                {this.formatCash(bill[column.id])}
                                                            </TableCell>
                                                        );
                                                    }

                                                    return (
                                                        <TableCell
                                                            key={`${column.id}_${bill[column.id]}`}
                                                            align={column.align}>
                                                            {bill[column.id]}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item>
                        <Pagination
                            numberOfPages={numberOfPages}
                            currentPage={page}
                            handlePagination={this.handlePagination}
                        />
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(BillSynthesis);
