import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import {
    Paper,
    Grid,
    IconButton,
    Stepper,
    StepButton,
    Divider,
    Typography,
    Link,
    Step,
    StepLabel,
    Button,
    CircularProgress
} from '@material-ui/core';

import { Caracteristic, TabControl, Billing, Validation, MarketMap as Map } from './components';
import { checkIntervalsBillPackage } from 'common/validators';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const steps = {
    caracteristic: {
        id: 0,
        link: 'caracteristic',
        label: 'Caractéristiques',
        previous: false,
        next: 'billing',
        error: false,
        completed: false
    },
    billing: {
        id: 1,
        link: 'billing',
        label: 'Facturation',
        next: 'plan',
        previous: 'caracteristic',
        error: false,
        completed: false
    },
    plan: {
        id: 2,
        link: 'plan',
        label: 'Plan',
        next: 'validation',
        previous: 'billing',
        error: false,
        completed: false
    },
    validation: {
        id: 3,
        link: 'validation',
        label: "Vue d'ensemble",
        next: false,
        previous: 'plan',
        error: false,
        completed: false
    }
};

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 15
    },
    isNotActive: {
        '& svg': {
            color: 'lightblue !important'
        }
    },
    completed: {
        '& svg': {
            color: 'green !important'
        }
    }
});

class Creation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            steps: { ...steps },
            days: [],
            places: [],
            merchants: [],
            activities: [],
            //selectedMerchants: [],
            isLoading: false,
            caracteristic: {
                label: '',
                day: '',
                placementMode: 0,
                allowBlocked: 0,
                type: 0,
                adresse: null
            },
            billing: {
                billPackage: false,
                subscriber: '',
                noneSubscriber: '',
                truck: '',
                water: '',
                electricity: '',
                noneSubscriberWater: '',
                noneSubscriberElectricity: '',
                intervals: [
                    {
                        id: 1,
                        min: 1,
                        max: 40,
                        amount: 2,
                        electricity: 0,
                        water: 0,
                        isValid: true
                    }
                ]
            }
        };
    }

    componentDidMount = () => {
        this.init();
    };

    componentDidUpdate = (prevProps, prevState) => {
        this.state.isLoading && this.state.isLoading != prevState.isLoading && this.sendData();
    };

    init = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/init`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState(prevState => ({
                    ...data,
                    billing: {
                        ...prevState.billing,
                        billPackage: data.billPackage
                    }
                }));
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    prepareData = (node, value) => {
        this.setState({
            [node]: value
        });
    };

    convertLinkToStep = link => {
        return steps[link].id;
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    getStepLinkButton = forward => {
        const { args1, args2 } = this.props.args;

        return {
            previous: steps[args1].previous,
            next: steps[args1].next
        };
    };

    hasErrorOnStep = (node, _bool) => {
        this.setState({
            steps: {
                ...steps,
                [node]: {
                    ...steps[node],
                    error: _bool
                }
            }
        });
    };

    StepIsValid = (node, _bool) => {
        this.setState({
            steps: {
                ...steps,
                [node]: {
                    ...steps[node],
                    done: _bool
                }
            }
        });
    };

    updateStepper = steps => {
        this.setState({
            steps: steps
        });
    };

    validate = () => {
        const { caracteristic, billing, steps, places } = this.state;

        let isValid = true;
        let newSteps = steps;
        let isLoading = true;

        Object.keys(caracteristic).map(index => {
            if (isValid) {
                if (index == 'adresse') {
                    if (!caracteristic[index]) {
                        alert('Veuillez saisir une adresse dans les caractéristiques du marché.');
                        isValid = false;
                        isLoading = false;
                        newSteps = {
                            ...newSteps,
                            caracteristic: {
                                ...newSteps.caracteristic,
                                error: true,
                                completed: false
                            }
                        };
                    } else {
                        newSteps = {
                            ...newSteps,
                            caracteristic: {
                                ...newSteps.caracteristic,
                                error: false,
                                completed: true
                            }
                        };
                    }
                } else {
                    //console.log(index, caracteristic[index]);
                    if (
                        caracteristic[index].length == 0 ||
                        (index == 'label' && !caracteristic[index].replace(/\s/g, '').length)
                    ) {
                        alert(
                            'Certains champs sont incorrects ou vides pour les caractéristiques du marché.'
                        );
                        isValid = false;
                        isLoading = false;
                        newSteps = {
                            ...newSteps,
                            caracteristic: {
                                ...newSteps.caracteristic,
                                error: true,
                                completed: false
                            }
                        };
                    } else {
                        newSteps = {
                            ...newSteps,
                            caracteristic: {
                                ...newSteps.caracteristic,
                                error: false,
                                completed: true
                            }
                        };
                    }
                }
            }
        });

        isValid = true;

        Object.keys(billing).map(index => {
            if (isValid) {
                // Forfait
                if (billing.billPackage) {
                    if (
                        index !== 'water' &&
                        index !== 'truck' &&
                        index !== 'electricity' &&
                        index !== 'subscriber' &&
                        index !== 'intervals' &&
                        index !== 'noneSubscriberWater' &&
                        index !== 'noneSubscriberElectricity' &&
                        index !== 'billPackage' &&
                        (!billing[index] ||
                            billing[index].length == 0 ||
                            !billing[index].replace(/\s/g, '').length)
                    ) {
                        alert(
                            'Certains champs sont incorrects ou vides pour la facturation du marché.'
                        );
                        isValid = false;
                        isLoading = false;
                        newSteps = {
                            ...newSteps,
                            billing: {
                                ...newSteps.billing,
                                error: true,
                                completed: false
                            }
                        };
                    } else if (index === 'intervals') {
                        if (checkIntervalsBillPackage(billing.intervals)) {
                            newSteps = {
                                ...newSteps,
                                billing: {
                                    ...newSteps.billing,
                                    error: false,
                                    completed: true
                                }
                            };
                        } else {
                            alert(
                                'Certains champs sont incorrects ou vides pour la facturation du marché.'
                            );
                            isValid = false;
                            isLoading = false;
                            newSteps = {
                                ...newSteps,
                                billing: {
                                    ...newSteps.billing,
                                    error: true,
                                    completed: false
                                }
                            };
                        }
                    }
                } else {
                    // Pas de forfait
                    if (
                        index != 'water' &&
                        index != 'truck' &&
                        index != 'electricity' &&
                        index != 'intervals' &&
                        index !== 'noneSubscriberWater' &&
                        index !== 'noneSubscriberElectricity' &&
                        index !== 'billPackage' &&
                        (!billing[index] ||
                            billing[index].length == 0 ||
                            !billing[index].replace(/\s/g, '').length)
                    ) {
                        alert(
                            'Certains champs sont incorrects ou vides pour la facturation du marché.'
                        );
                        isValid = false;
                        isLoading = false;
                        newSteps = {
                            ...newSteps,
                            billing: {
                                ...newSteps.billing,
                                error: true,
                                completed: false
                            }
                        };
                    } else {
                        newSteps = {
                            ...newSteps,
                            billing: {
                                ...newSteps.billing,
                                error: false,
                                completed: true
                            }
                        };
                    }
                }
            }
        });

        if (places.length == 0) {
            alert('Le marché doit comporter au moins une place afin de valider sa création.');
            isLoading = false;
            newSteps = {
                ...newSteps,
                plan: {
                    ...newSteps.plan,
                    error: true,
                    completed: false
                }
            };
        } else {
            newSteps = {
                ...newSteps,
                plan: {
                    ...newSteps.plan,
                    error: false,
                    completed: true
                }
            };
        }

        this.setState({
            steps: newSteps,
            isLoading: isLoading
        });
    };

    updateMerchants = value => {
        this.setState({
            selectedMerchants: value
        });
    };

    sendData = () => {
        const { caracteristic, billing, places, merchants } = this.state;

        const formData = new FormData();
        formData.append('merchants', JSON.stringify(merchants));
        formData.append('places', JSON.stringify(places));
        formData.append('billing', JSON.stringify(billing));
        formData.append('caracteristic', JSON.stringify(caracteristic));

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/create-market`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                alert('Marché créé avec succès.');
                this.setState({
                    isLoading: false
                });
                this.props.history.push(`/markets/edit/caracteristic/${data}`);
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la création du marché.');
                    this.setState({
                        isLoading: false
                    });
                } // Handle the error response object
            );
    };

    render() {
        const {
            classes,
            days,
            id,
            caracteristic,
            billing,
            steps,
            activities,
            places,
            merchants,
            isLoading
        } = this.state;

        const { args1, args2 } = this.props.args;

        const linkButton = this.getStepLinkButton();

        return (
            <Grid container direction="column" spacing={6}>
                <Grid item>
                    <Paper className={classes.paperContent}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="stretch"
                            spacing={2}>
                            <Grid item>
                                <Grid container direction="row" alignItems="baseline">
                                    <Grid item>
                                        <IconButton onClick={this.getBack}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            <Link component={RouterLink} to="/markets/all">
                                                Les marchés
                                            </Link>{' '}
                                            / Créer un marché
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <Stepper
                                    nonLinear
                                    alternativeLabel
                                    activeStep={this.convertLinkToStep(args1)}>
                                    {Object.keys(steps).map(index => {
                                        return (
                                            <Step
                                                key={steps[index].id}
                                                completed={steps[index].completed}>
                                                <StepButton
                                                    component={RouterLink}
                                                    to={`/markets/creation/${steps[index].link}`}>
                                                    <StepLabel
                                                        className={clsx({
                                                            [classes.completed]:
                                                                steps[index].completed
                                                        })}
                                                        error={steps[index].error}>
                                                        {steps[index].label}
                                                    </StepLabel>
                                                </StepButton>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <TabControl value={args1} index={'caracteristic'}>
                                    <Caracteristic
                                        data={caracteristic}
                                        node="caracteristic"
                                        updateData={this.prepareData}
                                        days={days}
                                    />
                                </TabControl>
                                <TabControl value={args1} index={'billing'}>
                                    <Billing
                                        data={billing}
                                        node="billing"
                                        updateData={this.prepareData}
                                    />
                                </TabControl>
                                <TabControl value={args1} index={'plan'}>
                                    <Map
                                        history={this.props.history}
                                        marketData={caracteristic}
                                        places={places}
                                        merchants={merchants}
                                        activities={activities}
                                        updateData={this.prepareData}
                                        //rebuildList={this.rebuildList}
                                    />
                                </TabControl>
                                <TabControl value={args1} index={'validation'}>
                                    <Validation
                                        steps={steps}
                                        days={days}
                                        activities={activities}
                                        data={{
                                            caracteristic: caracteristic,
                                            billing: billing,
                                            places: places,
                                            merchants: merchants
                                        }}
                                        updateData={this.prepareData}
                                    />
                                </TabControl>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <Grid container justify="flex-end" alignItems="center" spacing={2}>
                                    {isLoading && (
                                        <Grid item>
                                            <CircularProgress color="primary" size={25} />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Button
                                            disabled={!linkButton.previous || isLoading}
                                            component={RouterLink}
                                            variant="outlined"
                                            to={`/markets/creation/${linkButton.previous}`}>
                                            Étape précédente
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={!linkButton.next}
                                            color="primary"
                                            variant="outlined"
                                            component={RouterLink}
                                            to={`/markets/creation/${linkButton.next}`}>
                                            Étape suivante
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={isLoading}
                                            color="primary"
                                            variant="contained"
                                            onClick={this.validate}>
                                            Valider la création
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Creation);
