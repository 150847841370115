import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Button,
    InputAdornment,
    TextField,
    CircularProgress
} from '@material-ui/core';
import {
    Search as SearchIcon,
    Backspace as BackspaceIcon,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 20
    },
}));

const Keyboard = props => {
    const { className, kabis, setKabis, setLoading, isLoading, ...rest } = props;

    const classes = useStyles();

    const handleKeyboard = (value) => {
        setKabis(value ? (kabis + value) : kabis.slice(0, -1));

    }

    const handleKabis = ({ target: { value } }) => {
        setKabis(value.replace(/\s/g, ''));
    }


    const prepareData = (event) => {
        event && event.preventDefault();

        if (kabis.length == 0) {
            alert("Veuillez saisir un Siren/Siret.");
            return;
        }

        if (kabis.length != 9 && kabis.length != 14) {
            alert("Le format du Siren/Siret est incorrect.");
            return;
        }

        setLoading(true)
    }


    return (
        <div className={classes.root}>
            <Grid container direction="column" spacing={3} alignItems="stretch" justify="center">
                <Grid item>
                    <form onSubmit={prepareData}>
                        <TextField
                            label="Siren / Siret"
                            value={kabis}
                            fullWidth
                            onChange={handleKabis}
                            placeholder="123456789...14"
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        {
                                            isLoading ?
                                                <CircularProgress size={25} />
                                                :
                                                <Button type="submit" variant="contained" color="primary">
                                                    <SearchIcon />
                                                </Button>
                                        }

                                    </InputAdornment>,
                            }}
                            variant="outlined"
                        />
                    </form>
                </Grid>
                <Grid item>
                    <Grid container direction="column" spacing={2} alignItems="center">
                        <Grid item>
                            <Grid container alignItems="center" justify="center" spacing={3}>
                                <Grid item>
                                    <Button onClick={() => handleKeyboard(1)} variant="outlined" disableElevation color="secondary">1</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => handleKeyboard(2)} variant="outlined" disableElevation color="primary">2</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => handleKeyboard(3)} variant="outlined" disableElevation color="secondary">3</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" justify="center" spacing={3}>
                                <Grid item>
                                    <Button onClick={() => handleKeyboard(4)} variant="outlined" disableElevation color="primary">4</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => handleKeyboard(5)} variant="outlined" disableElevation color="secondary">5</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => handleKeyboard(6)} variant="outlined" disableElevation color="primary">6</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" justify="center" spacing={3}>
                                <Grid item>
                                    <Button onClick={() => handleKeyboard(7)} variant="outlined" disableElevation color="secondary">7</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => handleKeyboard(8)} variant="outlined" disableElevation color="primary">8</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => handleKeyboard(9)} variant="outlined" disableElevation color="secondary">9</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" justify="center" spacing={3}>
                                <Grid item>
                                    <Button variant="contained" disableElevation color="default"></Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => handleKeyboard("0")} variant="outlined" disableElevation color="secondary">0</Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => handleKeyboard(false)} variant="contained" disableElevation color="default"><BackspaceIcon style={{ color: "red" }} /></Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Keyboard;
