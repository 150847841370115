import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import {
    Grid,
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    Button,
    ListItemText,
    IconButton,
    TextField,
    FormControl,
    FormLabel,
    Typography
} from '@material-ui/core';

import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import DescriptionIcon from '@material-ui/icons/Description';

import { Pagination } from './components';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    late: {
        background: 'rgb(255,165,0, 0.1)',
        color: 'red',
        fontWeight: 500,
        '& button': {
            color: 'red',
            fontWeight: 500,
            borderColor: 'red'
        }
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 15
    }
});

const columns = [
    /*{
        id: "dateJour",
        label: "Date",
        minWidth: "10%",
        align: 'center',
    },*/
    {
        id: 'numFacture',
        label: 'N°',
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'marche',
        label: 'Marché (Journée)',
        minWidth: '40%',
        align: 'left'
    },
    {
        id: 'facture',
        label: 'Facture',
        minWidth: '10%',
        align: 'left'
    },
    {
        id: 'mail',
        label: 'Mail',
        minWidth: '10%',
        align: 'center'
    },
    {
        id: 'montant',
        label: 'Montant',
        minWidth: '10%',
        align: 'center'
    }
];

class Facture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            marchand_id: this.props.marchandId,
            list: [],
            start: '',
            end: '',
            isSubmited: false,
            approvedDate: {
                start: '',
                end: ''
            },
            page: 1,
            numberOfPages: 0,
            numberOfLateBills: 0
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        prevState.page != this.state.page && this.getData();
        this.state.isSubmited && this.getData();
    };

    componentDidMount = () => {
        //this.getData();
    };

    getData = () => {
        const { marchand_id, start, end, page } = this.state;

        this.setState({
            isSubmited: false
        });

        const formData = new FormData();
        formData.append('start', start);
        formData.append('end', end);
        formData.append('page', page);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-factures/${marchand_id}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                !data.numberOfPages && alert('Aucune facture trouvée pour cette période.');

                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    formatCash = value => {
        let montants = parseFloat(value)
            .toString()
            .split('.');
        return (
            montants[0] +
            ',' +
            (montants[1] ? (montants[1] + '00')[0] + (montants[1] + '00')[1] : '00') +
            ' €'
        );
    };

    getPdf = numFacture => {
        window.open(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/print/A/${numFacture}`,
            '_blank'
        );
    };

    handleDate = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    handleForm = () => {
        const { start, end } = this.state;

        if (start == '') {
            alert('Veuillez saisir une date de début');
            return;
        }

        if (end == '') {
            alert('Veuillez saisir une date de fin');
            return;
        }

        const _start = new Date(start);
        const _end = new Date(end);

        if (_start.getTime() > _end.getTime()) {
            let inverse = window.confirm(
                'La date de début est supérieur à la date de fin\nInverser les dates ?'
            );
            inverse &&
                this.setState({
                    start: end,
                    end: start
                });
            return;
        }

        this.setState({
            isSubmited: true,
            approvedDate: {
                start: _start,
                end: _end
            }
        });
    };

    render() {
        const { classes, list, start, end, numberOfPages, page, numberOfLateBills } = this.state;

        return (
            <Grid container spacing={3} direction="column" alignItems="stretch">
                <Grid item>
                    <FormControl component="fieldset" required>
                        <FormLabel className={classes.labelFilter} component="label">
                            Rechercher par date
                        </FormLabel>
                    </FormControl>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}>
                        <Grid item xs={12} md={5}>
                            <TextField
                                type="date"
                                fullWidth
                                name="start"
                                value={start}
                                onChange={this.handleDate}
                                size="small"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <TextField
                                type="date"
                                fullWidth
                                size="small"
                                name="end"
                                value={end}
                                onChange={this.handleDate}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button
                                variant="contained"
                                fullWidth
                                color="primary"
                                onClick={this.handleForm}>
                                Rechercher
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {numberOfLateBills > 0 && (
                    <Grid item>
                        <Typography style={{ color: 'red' }}>
                            <strong>{numberOfLateBills}</strong> factures en retard de paiement
                            toutes périodes confondues
                        </Typography>
                    </Grid>
                )}
                <Grid item>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map(column => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}>
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.map(bap => {
                                    return (
                                        <TableRow hover key={bap.numFacture}>
                                            {columns.map(column => {
                                                if (column.id == 'facture') {
                                                    return (
                                                        <TableCell
                                                            className={clsx({
                                                                [classes.late]: Boolean(
                                                                    bap.retard == '1'
                                                                )
                                                            })}
                                                            align="left"
                                                            padding="none">
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justify="flex-start"
                                                                alignItems="center">
                                                                <Grid item>
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            this.getPdf(
                                                                                bap.numFacture
                                                                            )
                                                                        }>
                                                                        <DescriptionIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item>
                                                                    {
                                                                        <ListItemText
                                                                            primary={bap.numFacture}
                                                                            secondary={
                                                                                bap.encaisse ==
                                                                                '1' ? (
                                                                                    <React.Fragment>
                                                                                        Réglé{' '}
                                                                                        <DoneIcon
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    'inherit',
                                                                                                color:
                                                                                                    'green'
                                                                                            }}
                                                                                        />
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        Non réglé{' '}
                                                                                        <ClearIcon
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    'inherit',
                                                                                                color:
                                                                                                    'red'
                                                                                            }}
                                                                                        />
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        />
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    );
                                                }

                                                if (column.id == 'mail') {
                                                    return (
                                                        <TableCell
                                                            className={clsx({
                                                                [classes.late]: Boolean(
                                                                    bap.retard == '1'
                                                                )
                                                            })}
                                                            key={`${column.id}_${bap.numFacture}`}
                                                            align={column.align}>
                                                            <Button
                                                                color="primary"
                                                                variant="outlined"
                                                                size="small">
                                                                Envoyer
                                                            </Button>
                                                        </TableCell>
                                                    );
                                                }

                                                if (column.id == 'montant') {
                                                    return (
                                                        <TableCell
                                                            className={clsx({
                                                                [classes.late]: Boolean(
                                                                    bap.retard == '1'
                                                                )
                                                            })}
                                                            key={`${column.id}_${bap.numFacture}`}
                                                            align={column.align}>
                                                            {this.formatCash(bap[column.id])}
                                                        </TableCell>
                                                    );
                                                }

                                                return (
                                                    <TableCell
                                                        className={clsx({
                                                            [classes.late]: Boolean(
                                                                bap.retard == '1'
                                                            )
                                                        })}
                                                        key={`${column.id}_${bap.numFacture}`}
                                                        align={column.align}>
                                                        {bap[column.id]}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {/*<Grid item>
                    <Pagination numberOfPages={numberOfPages} currentPage={page} handlePagination={this.handlePagination} />
                </Grid>*/}
            </Grid>
        );
    }
}

export default withStyles(styles)(Facture);
