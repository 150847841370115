import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { TabControl } from 'components';
import { Form, StatisticsCard } from './components';
import All from './All';
import Creation from './Creation';
import Edit from './Edit';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paperContent: {
        padding: theme.spacing(4)
        //padding: 10
    },
    item: {
        minWidth: '30%'
    }
}));

const NewTerraceBill = props => {
    const classes = useStyles();
    const { tab, id } = props.match.params;
    const [updateStats, setUpdateStas] = useState(false);

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item md={7} xs={12}>
                    <StatisticsCard updateStats={updateStats} />
                </Grid>
                <Grid item xs={12}>
                    <TabControl value={tab ?? ''} index={''}>
                        <All updateStats={() => setUpdateStas(!updateStats)} />
                    </TabControl>
                    <TabControl value={tab} index={'create'}>
                        {/* <Creation history={props.history} /> */}
                    </TabControl>
                    <TabControl value={tab} index={'edit'}>
                        {/* <Edit history={props.history} id={id} /> */}
                    </TabControl>
                    <TabControl value={tab} index={'get'}>
                        {/* <MyTerrace /> */}
                    </TabControl>
                </Grid>
            </Grid>
        </div>
    );
};

export default NewTerraceBill;
