import React from 'react';
import validator from 'validator';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import WarningIcon from '@material-ui/icons/Warning';
import SendIcon from '@material-ui/icons/Send';

import { Divider, Grid, Button } from '@material-ui/core';

import { Dialog } from 'components';

import { BillSenderForm, BillSenderLoader } from './components';

/**
 * env
 */
const env = `https://www.${
    window.location.hostname.split('.')[1]
        ? window.location.hostname.split('.')[0] === 'www'
            ? window.location.hostname.split('.')[1]
            : window.location.hostname.split('.')[0]
        : 'dev'
}.api.digitmarche.fr/api`;

/**
 *
 * @param {*} billNumber
 * @returns
 */
export async function sendBill(billNumber) {
    const result = await fetch(`${env}/merchant/send-bill/${billNumber}`, {
        method: 'GET'
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
            throw `Echec de l'envoi de la facture N°${billNumber}.`;
        });

    return result;
}

/**
 * Check if email exist for a merchant's bill
 *
 * @param {*} billNumber
 * @returns
 */
export async function checkEmailBeforeSendingBill(billNumber) {
    const result = await fetch(`${env}/regie/has-email-before-sending-bill/${billNumber}`, {
        method: 'GET'
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
            throw "Echec de la vérification de l'adresse mail du commerçant.";
        });

    return result;
}

/**
 *
 * @param {*} billNumber
 * @param {*} email
 * @returns
 */
export async function updateMerchantEmail(billNumber, email) {
    const formData = new FormData();
    formData.append('billNumber', billNumber);
    formData.append('email', email);

    const result = await fetch(`${env}/regie/update-email-by-bill-number/${billNumber}`, {
        method: 'POST',
        body: formData
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
            throw "Echec de la mise à jour de l'adresse mail du commerçant.";
        });

    return result;
}

/**
 *
 * @param {*} props
 * @returns React.render
 */
const BillSender = props => {
    const { billNumber, setBillNumber } = props;
    const [open, setOpen] = React.useState(false);
    const [hasEmail, setHasEmail] = React.useState(false);
    const [mounted, setMounted] = React.useState(false);
    const [hasError, setHasError] = React.useState(false);
    const [isSending, setIsSending] = React.useState(false);
    const [describe, setDescribe] = React.useState('');
    const [icon, setIcon] = React.useState();
    const [email, setEmail] = React.useState('');

    const submit = () => {
        if (email.isEmpty() || !validator.isEmail(email)) {
            alert('Veuillez renseigner une adresse mail valide.');
            return;
        }

        setHasEmail(true);
    };

    React.useEffect(() => {
        setOpen(Boolean(billNumber));
        setBillNumber(billNumber);

        if (mounted) {
            setDescribe(
                hasEmail
                    ? `Envoi de la facture N°${billNumber}...`
                    : `Aucune adresse mail n'a été trouvée pour la facture N°${billNumber}.`
            );
            !hasEmail && setIcon(<WarningIcon style={{ color: 'orange' }} />);
            hasEmail && setIsSending(true);
        }

        if (!billNumber) {
            setIcon();
            setHasEmail(false);
            setMounted(false);
            setHasError(false);
            setIsSending(false);
            setDescribe("Vérification de l'adresse mail en cours...");
        }

        if (billNumber && !mounted) {
            checkEmailBeforeSendingBill(billNumber)
                .then(response => setHasEmail(response.ok))
                .catch(e => setHasError(e))
                .finally(() => setMounted(true));
        }
    }, [billNumber, mounted]);

    React.useEffect(() => {
        if (mounted) {
            updateMerchantEmail(billNumber, email)
                .then(() => {
                    setIcon();
                    setDescribe(`Envoi de la facture N°${billNumber}...`);
                    setIsSending(true);
                })
                .catch(e => setHasError(e));
        }
    }, [hasEmail]);

    React.useEffect(() => {
        if (hasError) {
            setIcon(<HighlightOffIcon style={{ color: 'red' }} />);
            setDescribe(hasError);
        }
    }, [hasError]);

    React.useEffect(() => {
        if (isSending) {
            sendBill(billNumber)
                .then(response => {
                    if (response.sent > 0) {
                        setIcon(<CheckCircleOutlineIcon style={{ color: 'green' }} />);
                        setDescribe(`Facture N°${billNumber} envoyée avec succès.`);
                    } else {
                        setHasError(`Echec de l'envoi de la facture N°${billNumber}.`);
                    }
                })
                .catch(e => setHasError(e));
        }
    }, [isSending]);

    return (
        <Dialog
            title={'Envoi de facture'}
            onClose={() => setBillNumber(null)}
            action={
                <Button
                    onClick={submit}
                    disabled={hasEmail || !mounted}
                    variant="contained"
                    color="primary"
                    endIcon={<SendIcon />}>
                    Envoyer
                </Button>
            }
            open={open}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <BillSenderLoader icon={icon} describe={describe} />
                </Grid>
                {mounted && !hasEmail && (
                    <React.Fragment>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <BillSenderForm onChange={event => setEmail(event.target.value)} />
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </Dialog>
    );
};

export default BillSender;

/**
 * check wether or not if string is empty
 * @returns Boolean
 */
String.prototype.isEmpty = function() {
    return this.length === 0 || !this.trim();
};
