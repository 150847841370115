import React from 'react';
import { withStyles } from '@material-ui/styles';

import { Link as RouterLink } from 'react-router-dom';
import {
    Grid,
    Paper,
    FormControl,
    TextField,
    Button,
    FormLabel,
    Typography,
    Divider
} from '@material-ui/core';

import {
    NewMerchantsBar,
    AddedMerchantsLine,
    ClosedMerchantsLine,
    AlertedMerchantsRadar,
    LateBillsLine,
    LateBillsBar,
    RecipesMarketBar,
    NewHoldersLine
} from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 15
    },
    labelFilterRadio: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

class Reporting extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            isSubmited: Boolean(this.props.match.params.start),
            start: this.props.match.params.start || '',
            end: this.props.match.params.end || '',
            approvedDate: {
                start: this.props.match.params.start ? new Date(this.props.match.params.start) : '',
                end: this.props.match.params.start ? new Date(this.props.match.params.end) : ''
            },
            options: {
                //weekday: 'numeric',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            stats: false
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.match.params.start != this.props.match.params.start ||
            prevProps.match.params.end != this.props.match.params.end
        ) {
            this.setState({
                start: this.props.match.params.start || '',
                end: this.props.match.params.end || '',
                approvedDate: {
                    start:
                        this.props.match.params.start && this.props.match.params.start != 'creation'
                            ? new Date(this.props.match.params.start)
                            : '',
                    end: this.props.match.params.end ? new Date(this.props.match.params.end) : ''
                },
                isSubmited: Boolean(this.props.match.params.start)
            });
        }

        if (this.state.isSubmited) {
            this.getData();
        }
    };

    componentDidMount = () => {
        this.state.isSubmited && this.getData();
    };

    getData = () => {
        const formData = new FormData();
        formData.append('end', this.state.end);
        formData.append('start', this.state.start);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/administrateur/reporting`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    stats: data,
                    isSubmited: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleDate = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    handleForm = () => {
        const { start, end, approvedDate } = this.state;

        if (start == '') {
            alert('Veuillez saisir une date de début');
            return;
        }

        if (end == '') {
            alert('Veuillez saisir une date de fin');
            return;
        }

        const _start = new Date(start);
        const _end = new Date(end);

        if (_start.getTime() > _end.getTime()) {
            let inverse = window.confirm(
                'La date de début est supérieur à la date de fin\nInverser les dates ?'
            );
            inverse &&
                this.setState({
                    start: end,
                    end: start
                });
            return;
        }

        this.setState({
            approvedDate: {
                start: _start,
                end: _end
            }
        });

        this.props.history.push('/reporting/' + start + '/' + end);
    };

    render() {
        const { classes, start, end, approvedDate, options, stats } = this.state;

        return (
            <div className={classes.root}>
                <Grid container direction="column">
                    <Grid item>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Paper className={classes.paperContent}>
                                    <FormControl component="fieldset" required>
                                        <FormLabel
                                            className={classes.labelFilter}
                                            component="label">
                                            Rechercher par date
                                        </FormLabel>
                                    </FormControl>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-start"
                                        alignItems="center"
                                        spacing={2}>
                                        <Grid item xs={12} md={5}>
                                            <TextField
                                                type="date"
                                                fullWidth
                                                name="start"
                                                value={start}
                                                onChange={this.handleDate}
                                                size="small"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <TextField
                                                type="date"
                                                fullWidth
                                                size="small"
                                                name="end"
                                                value={end}
                                                onChange={this.handleDate}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                color="primary"
                                                onClick={this.handleForm}>
                                                Rechercher
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="stretch"
                                    spacing={3}>
                                    <Grid item>
                                        {stats && (
                                            <NewMerchantsBar
                                                date={{
                                                    start: approvedDate.start.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    ),
                                                    end: approvedDate.end.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    )
                                                }}
                                                rawData={stats.newMerchants}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item>
                                        {stats && (
                                            <AddedMerchantsLine
                                                date={{
                                                    start: approvedDate.start.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    ),
                                                    end: approvedDate.end.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    )
                                                }}
                                                rawData={stats.addedMerchants}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item>
                                        {stats && (
                                            <ClosedMerchantsLine
                                                date={{
                                                    start: approvedDate.start.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    ),
                                                    end: approvedDate.end.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    )
                                                }}
                                                rawData={stats.closedMerchants}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item>
                                        {stats && (
                                            <AlertedMerchantsRadar
                                                date={{
                                                    start: approvedDate.start.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    ),
                                                    end: approvedDate.end.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    )
                                                }}
                                                rawData={stats.alertedMerchants}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item>
                                        {stats && (
                                            <LateBillsLine
                                                date={{
                                                    start: approvedDate.start.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    ),
                                                    end: approvedDate.end.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    )
                                                }}
                                                rawData={stats.lateBills}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item>
                                        {stats && (
                                            <LateBillsBar
                                                date={{
                                                    start: approvedDate.start.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    ),
                                                    end: approvedDate.end.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    )
                                                }}
                                                rawData={stats.lateBills}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            spacing={3}
                                            alignItems="stretch"
                                            direction="column">
                                            {stats &&
                                                stats.recipesMarket.data.map((data, index) => {
                                                    return (
                                                        <Grid item>
                                                            <RecipesMarketBar
                                                                date={{
                                                                    start: approvedDate.start.toLocaleDateString(
                                                                        'fr-FR',
                                                                        options
                                                                    ),
                                                                    end: approvedDate.end.toLocaleDateString(
                                                                        'fr-FR',
                                                                        options
                                                                    )
                                                                }}
                                                                labels={stats.recipesMarket.labels}
                                                                rawData={data}
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Reporting);
