import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';

import {
    Grid,
    IconButton,
    Tooltip
} from '@material-ui/core';

import StorefrontIcon from '@material-ui/icons/Storefront';
import GroupIcon from '@material-ui/icons/Group';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

const useStyles = makeStyles(theme => ({

}));


const TabControl = props => {

    const { className, data, updateData, ...rest } = props;

    return (
        <Grid container direction="row" justify="space-evenly">
            {/*<Grid item>
                <Tooltip title="Le marché" arrow>
                    <IconButton
                        onClick={() => updateData("home")}
                        color={data == "home" ? "secondary" : "default"}>
                        <HomeIcon />
                    </IconButton>
                </Tooltip>
            </Grid>*/}
            <Grid item>
                <Tooltip title="Liste des places" arrow>
                    <IconButton
                        onClick={() => updateData("store")}
                        color={data == "store" ? "secondary" : "default"}>
                        <StorefrontIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title="Liste des commerçants" arrow>
                    <IconButton
                        onClick={() => updateData("holders")}
                        color={data == "holders" ? "secondary" : "default"}>
                        <GroupIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title="Liste des encaissements" arrow>
                    <IconButton
                        onClick={() => updateData("payments")}
                        color={data == "payments" ? "secondary" : "default"}>
                        <EuroSymbolIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default TabControl;
