import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

import { DialogTitle } from './components'

import {
    Grid,
    Button,
    TextField,
    Table,
    TableCell,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    TableRow,
    TableBody,
    ListItemText,
    MenuItem
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    cell: {
        padding: "10px 10px 10px 24px"
    },
}));


const currencies = [
    {
        value: 'esp',
        label: 'Numéraire',
        id: 1
    },
    {
        value: 'Chèque',
        label: 'Chèque',
        id: 2
    },
    {
        value: 'CB',
        label: 'Carte bancaire',
        id: 3
    },
    {
        value: 'Prélèvement',
        label: 'Prélèvement',
        id: 4
    },
    {
        value: 'Téléphone',
        label: 'Téléphone',
        id: 5
    },
];


const Bill = props => {
    const { className, data, close, sendData, open, ...rest } = props;

    const classes = useStyles();
    const [regexp, setRegexp] = React.useState(/^[0-9\b]+$/);
    const [moyen, setMoyen] = React.useState("");
    const [banque, setBanque] = React.useState("");
    const [cheque, setCheque] = React.useState("");


    const handleMoyen = ({ target: { value } }) => {
        setMoyen(value);
        setBanque("");
        setCheque("");
    };


    const handleBanque = ({ target: { value } }) => {
        setBanque(value);
    }

    const handleCheque = ({ target: { value } }) => {
        (value === '' || regexp.test(value)) && setCheque((value.replace(/\s/g, '')).slice(0, 7));
    }

    const formatCash = (value) => {
        let montants = (parseFloat(value)).toString().split(".");
        return montants[0] + "," + (montants[1] ? (montants[1] + "00")[0] + (montants[1] + "00")[1] : "00") + " €"
    }


    const prepareData = () => {

        if (moyen == 2) {
            if (!banque || banque.length == 0 || !banque.replace(/\s/g, '').length) {
                alert("Veuillez saisir une banque");
                return;
            }

            if (!cheque || cheque.length == 0 || !cheque.replace(/\s/g, '').length) {
                alert("Veuillez saisir un numéro de chèque");
                return;
            }

            if (cheque.length != 7) {
                alert("Veuillez saisir un numéro de chèque valide (7 numéros obligatoires)");
                return;
            }
        }

        sendData(data.numero, moyen, (moyen == 2 ? banque : false), (moyen == 2 ? cheque : false));
    }


    return (
        <Dialog
            open={open}
            onClose={close}
            fullWidth
            maxWidth="sm"
        >

            <DialogTitle onClose={close} >
                Récapitulatif avant encaissement
            </DialogTitle>
            <DialogContent dividers style={{ padding: 0 }}>
                <Table>
                    <TableBody>

                        <TableRow key={118766576}>
                            <TableCell className={classes.cell} padding="none" align="left">
                                Numéro de facture
                            </TableCell>
                            <TableCell className={classes.cell} padding="none" align="left">
                                <Typography variant="body1" color="primary">
                                    {data.numero}
                                </Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow key={0} style={{ backgroundColor: "rgb(0,0,0, 0.02)" }}>
                            <TableCell padding="none" className={classes.cell} align="left">
                                <ListItemText
                                    primary="Raison sociale"
                                    secondary="Enseigne"
                                />
                            </TableCell>
                            <TableCell className={classes.cell} padding="none" align="left">

                                <ListItemText
                                    primary={
                                        <Typography variant="body1" color="primary">
                                            {data.raisonSociale}
                                        </Typography>
                                    }
                                    secondary={data.enseigne}
                                />

                            </TableCell>
                        </TableRow>

                        <TableRow key={11}>
                            <TableCell className={classes.cell} padding="none" align="left">
                                Métier
                            </TableCell>
                            <TableCell className={classes.cell} padding="none" align="left">
                                <Typography variant="body1" color="primary">
                                    {data.metier}
                                </Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow key={1} style={{ backgroundColor: "rgb(0,0,0, 0.02)" }}>
                            <TableCell className={classes.cell} padding="none" align="left">
                                Marché
                            </TableCell>
                            <TableCell className={classes.cell} padding="none" align="left">
                                <Typography variant="body1" color="primary">
                                    {data.marche}
                                </Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow key={3}>
                            <TableCell className={classes.cell} padding="none" align="left">
                                Date
                            </TableCell>
                            <TableCell className={classes.cell} padding="none" align="left">
                                <Typography variant="body1" color="primary">
                                    {data.date}
                                </Typography>
                            </TableCell>
                        </TableRow>

                        <TableRow key={5} style={{ backgroundColor: "rgb(0,0,0, 0.02)" }}>
                            <TableCell className={classes.cell} padding="none" align="left">
                                Numéro de la place
                            </TableCell>
                            <TableCell className={classes.cell} padding="none" align="left">
                                <Typography variant="body1" color="primary">
                                    {data.numeroPlace}
                                </Typography>
                            </TableCell>
                        </TableRow>


                        <TableRow key={85}>
                            <TableCell className={classes.cell} padding="none" align="left">
                                Nombre de mètres
                            </TableCell>
                            <TableCell className={classes.cell} padding="none" align="left">
                                <Typography variant="body1" color="primary">
                                    {data.metres}
                                </Typography>
                            </TableCell>
                        </TableRow>


                        <TableRow key={56} style={{ backgroundColor: "rgb(0,0,0, 0.02)" }}>
                            <TableCell className={classes.cell} padding="none" align="left">
                                Prix du mètre linéaire
                            </TableCell>
                            <TableCell className={classes.cell} padding="none" align="left">
                                <Typography variant="body1" color="primary">
                                    {formatCash(data.prixUnitaire)}
                                </Typography>
                            </TableCell>
                        </TableRow>



                        <TableRow key={5628}>
                            <TableCell className={classes.cell} padding="none" align="left">
                                Moyen de paiement
                            </TableCell>
                            <TableCell className={classes.cell} padding="none" style={{ padding: "16px 16px 16px 0px" }} align="left">
                                <Grid container spacing={2} direction="column" alignItems="stretch">
                                    <Grid item>
                                        <TextField
                                            select
                                            fullWidth
                                            size="small"
                                            value={moyen}
                                            onChange={handleMoyen}
                                        >
                                            {
                                                currencies.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        <Typography variant="body1" color="primary">
                                                            {option.label}
                                                        </Typography>
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                    {

                                        moyen == 2 &&

                                        <React.Fragment>
                                            <Grid item>
                                                <TextField
                                                    size="small"
                                                    name="banque"
                                                    fullWidth
                                                    helperText="Saisir une banque"
                                                    onChange={handleBanque}
                                                    placeholder="Ma banque"
                                                    value={banque}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    size="small"
                                                    name="cheque"
                                                    helperText="Saisir un numéro de chèque"
                                                    fullWidth
                                                    value={cheque}
                                                    onChange={handleCheque}
                                                    placeholder="1234567"
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    }
                                </Grid>
                            </TableCell>
                        </TableRow>


                        <TableRow key={568} style={{ backgroundColor: "rgb(0,0,0, 0.02)" }}>
                            <TableCell className={classes.cell} padding="none" align="left">
                                <strong>Montant total</strong>
                            </TableCell>
                            <TableCell className={classes.cell} padding="none" align="left">
                                <Typography variant="body1" style={{ fontWeight: "bold" }} color="primary">
                                    {formatCash(data.montant)}
                                </Typography>
                            </TableCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={close}>
                    Annuler
                </Button>
                <Button variant="contained" color="primary" onClick={prepareData}>
                    Encaisser
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Bill;
