import React from 'react';
import { withStyles } from '@material-ui/styles';

import { Link as RouterLink } from 'react-router-dom';
import {
    Grid,
    Paper,
    FormControl,
    TextField,
    Button,
    Divider,
    FormLabel,
    IconButton,
    InputAdornment,
    ListItemText,
    FormControlLabel,
    Radio,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Typography
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import DescriptionIcon from '@material-ui/icons/Description';
import SendIcon from '@material-ui/icons/Send';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import TabControl from './TabControl';

import { TotalLate, TotalBills, Pagination, Field, Bill, Creation } from './components';
import env from 'common/env';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 15
    },
    labelFilterRadio: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

const columns = [
    {
        id: 'date',
        label: 'Date',
        minWidth: '10%',
        align: 'left'
    },
    {
        id: 'name',
        label: 'Nom/Prénom/Adresse',
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'label',
        label: 'Produit/Prestation',
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'facture',
        label: 'Facture',
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'mail',
        label: 'Mail',
        minWidth: '20%',
        align: 'center'
    },
    {
        id: 'montant',
        label: 'Montant',
        minWidth: '10%',
        align: 'left'
    }
];

class OtherSalesBills extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            isSubmited: Boolean(
                this.props.match.params.start && this.props.match.params.start != 'creation'
            ),
            searchValue: '',
            cat: this.props.match.params.start == 'creation' ? 'creation' : 'bill',
            start: this.props.match.params.start || '',
            end: this.props.match.params.end || '',
            approvedDate: {
                start:
                    this.props.match.params.start && this.props.match.params.start != 'creation'
                        ? new Date(this.props.match.params.start)
                        : '',
                end:
                    this.props.match.params.start && this.props.match.params.start != 'creation'
                        ? new Date(this.props.match.params.end)
                        : ''
            },
            disableSearch: !Boolean(
                this.props.match.params.start && this.props.match.params.start != 'creation'
            ),
            list: [],
            montant: 0,
            montantInvoice: 0,
            montantNoInvoice: 0,
            number: 0,
            numberInvoice: 0,
            numberNoInvoice: 0,
            options: {
                //weekday: 'numeric',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            page: 1,
            numberOfPages: 0,
            openBill: false,
            bill: null
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.match.params.start != this.props.match.params.start ||
            prevProps.match.params.end != this.props.match.params.end ||
            prevProps.match.params.type != this.props.match.params.type
        ) {
            this.setState({
                start: this.props.match.params.start || '',
                end: this.props.match.params.end || '',
                approvedDate: {
                    start:
                        this.props.match.params.start && this.props.match.params.start != 'creation'
                            ? new Date(this.props.match.params.start)
                            : '',
                    end: this.props.match.params.end ? new Date(this.props.match.params.end) : ''
                },
                cat: this.props.match.params.start == 'creation' ? 'creation' : 'bill',
                disableSearch: !Boolean(
                    this.props.match.params.start && this.props.match.params.start != 'creation'
                ),
                isSubmited: Boolean(
                    this.props.match.params.start && this.props.match.params.start != 'creation'
                ),
                list: []
            });
        }

        if (this.state.isSubmited) {
            this.getData();
        }

        (this.state.searchValue != prevState.searchValue || this.state.page != prevState.page) &&
            this.getData();
    };

    componentDidMount = () => {
        this.state.isSubmited && this.getData();
    };

    getData = () => {
        this.setState({
            isSubmited: false
        });

        const formData = new FormData();
        formData.append('page', this.state.page);
        formData.append('start', this.state.start);
        formData.append('end', this.state.end);
        formData.append('search', this.state.searchValue);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/domaine/get-bill-other-sales`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    sendData = (numFacture, moyen, banque = false, cheque = false) => {
        const formData = new FormData();
        formData.append('moyen', moyen);
        banque && formData.append('banque', banque);
        cheque && formData.append('cheque', cheque);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/update-bill/${numFacture}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                alert('Facture N°' + numFacture + ' réglée avec succès.');
                this.setState({
                    openBill: false,
                    bill: null
                });
                this.getData();
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    handleDate = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    handleForm = () => {
        const { start, end, approvedDate, type } = this.state;

        if (start == '') {
            alert('Veuillez saisir une date de début');
            return;
        }

        if (end == '') {
            alert('Veuillez saisir une date de fin');
            return;
        }

        const _start = new Date(start);
        const _end = new Date(end);

        if (_start.getTime() > _end.getTime()) {
            let inverse = window.confirm(
                'La date de début est supérieur à la date de fin\nInverser les dates ?'
            );
            inverse &&
                this.setState({
                    start: end,
                    end: start
                });
            return;
        }

        this.setState({
            approvedDate: {
                start: _start,
                end: _end
            }
        });

        this.props.history.push('/other-sales-bills/' + start + '/' + end + '/' + (type || 'all'));
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    sendMissedData = (data, marchand_id, numFacture) => {
        const confirmation = window.confirm(
            "Cette action mettra à jour l'adresse mail du commerçant et l'enverra sur celle-ci.\nContinuer l'envoi ?"
        );
        let mailMarchand = data;

        if (confirmation) {
            const formData = new FormData();
            formData.append('field', 'mailMarchand');
            formData.append('data', data);

            fetch(
                `https://www.${
                    window.location.hostname.split('.')[1]
                        ? window.location.hostname.split('.')[0] === 'www'
                            ? window.location.hostname.split('.')[1]
                            : window.location.hostname.split('.')[0]
                        : 'dev'
                }.api.digitmarche.fr/api/merchant/update-fields-missed/${marchand_id}`,
                {
                    method: 'POST',
                    body: formData
                }
            )
                .then(res => res.json())
                .then(data => {
                    if (data) {
                        alert('Mise à jour effectuée');
                        this.getData();
                        this.sendByMail(numFacture, mailMarchand);
                    }
                })
                .catch(console.log);
        }
    };

    getPdf = numFacture => {
        window.open(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/domaine/get-bill-for-pdf/${numFacture}`,
            '_blank'
        );
    };

    sendByMail = (numFacture, mail) => {
        const formData = new FormData();
        formData.append('mail', mail);

        fetch(`${env}/domaine/send-other-sales-bill-by-mail/${numFacture}`, {
            method: 'POST',
            body: formData
        })
            .then(res => (res.ok ? res.json() : false))
            .then(data =>
                alert(data.sent ? 'Facture envoyée avec succès.' : "Echec d'envoi de la facture.")
            )
            .catch(error => {
                console.error(error);
                alert("Echec d'envoi de la facture.");
            })
            .finally(() => this.getData());
    };

    handleCash = numFacture => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/domain/get-bill/${numFacture}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    bill: data,
                    openBill: true
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    closeBill = () => {
        this.setState({
            bill: null,
            openBill: false
        });
    };

    render() {
        const {
            classes,
            searchValue,
            start,
            end,
            approvedDate,
            disableSearch,
            options,
            list,
            page,
            numberOfPages,
            number,
            numberNoInvoice,
            numberInvoice,
            montant,
            montantInvoice,
            montantNoInvoice,
            openBill,
            bill,
            cat
        } = this.state;

        return (
            <div className={classes.root}>
                {bill && (
                    <Bill
                        data={bill}
                        sendData={this.sendData}
                        close={this.closeBill}
                        open={openBill}
                    />
                )}
                <Grid container direction="column">
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            component={RouterLink}
                            to={'/other-sales-bills/creation'}>
                            <EuroSymbolIcon style={{ marginRight: 7 }} /> Créer une nouvelle vente
                        </Button>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <TabControl value={cat} index={'creation'}>
                                    <Creation history={this.props.history} />
                                </TabControl>
                            </Grid>
                            <Grid item>
                                <TabControl value={cat} index={'bill'}>
                                    <Paper className={classes.paperContent}>
                                        <FormControl component="fieldset" required>
                                            <FormLabel
                                                className={classes.labelFilter}
                                                component="label">
                                                Rechercher par date
                                            </FormLabel>
                                        </FormControl>
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-start"
                                            alignItems="center"
                                            spacing={2}>
                                            <Grid item xs={12} md={5}>
                                                <TextField
                                                    type="date"
                                                    fullWidth
                                                    name="start"
                                                    value={start}
                                                    onChange={this.handleDate}
                                                    size="small"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={5}>
                                                <TextField
                                                    type="date"
                                                    fullWidth
                                                    size="small"
                                                    name="end"
                                                    value={end}
                                                    onChange={this.handleDate}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    color="primary"
                                                    onClick={this.handleForm}>
                                                    Rechercher
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </TabControl>
                            </Grid>
                            <Grid item>
                                <TabControl value={cat} index={'bill'}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TotalLate
                                                data={{
                                                    montant: montant,
                                                    montantInvoice: montantInvoice,
                                                    montantNoInvoice: montantNoInvoice
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TotalBills
                                                data={{
                                                    number: number,
                                                    numberInvoice: numberInvoice,
                                                    numberNoInvoice: numberNoInvoice
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paperContent}>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justify="center"
                                                    alignItems="stretch"
                                                    spacing={3}>
                                                    <Grid item>
                                                        <TextField
                                                            label={
                                                                !disableSearch &&
                                                                `Rechercher une facture du ${approvedDate.start.toLocaleDateString(
                                                                    'fr-FR',
                                                                    options
                                                                )} au ${approvedDate.end.toLocaleDateString(
                                                                    'fr-FR',
                                                                    options
                                                                )}`
                                                            }
                                                            type="text"
                                                            fullWidth
                                                            value={searchValue}
                                                            disabled={disableSearch}
                                                            onChange={this.handleSearch}
                                                            variant="outlined"
                                                            helperText={
                                                                !disableSearch &&
                                                                "Saisir un numéro de facture ou le libellé d'un produit"
                                                            }
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            disabled={disableSearch}
                                                                            onClick={
                                                                                this.clearSearch
                                                                            }
                                                                            edge="end">
                                                                            <ClearIcon
                                                                                style={{
                                                                                    color: 'red'
                                                                                }}
                                                                            />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Divider />
                                                    </Grid>
                                                    <Grid item>
                                                        <TableContainer
                                                            className={classes.container}>
                                                            <Table
                                                                stickyHeader
                                                                aria-label="sticky table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {columns.map(column => (
                                                                            <TableCell
                                                                                key={column.id}
                                                                                align={column.align}
                                                                                style={{
                                                                                    minWidth:
                                                                                        column.minWidth
                                                                                }}>
                                                                                {column.label}
                                                                            </TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {list.map(bill => (
                                                                        <TableRow
                                                                            hover
                                                                            key={bill.numero}>
                                                                            {columns.map(column => {
                                                                                if (
                                                                                    column.id ==
                                                                                    'label'
                                                                                ) {
                                                                                    return (
                                                                                        <TableCell
                                                                                            key={
                                                                                                bill.numFacture
                                                                                            }
                                                                                            align={
                                                                                                column.align
                                                                                            }>
                                                                                            <ListItemText
                                                                                                primary={
                                                                                                    bill.label
                                                                                                }
                                                                                                secondary={
                                                                                                    <Typography variant="body2">
                                                                                                        {bill.type ==
                                                                                                        'p'
                                                                                                            ? 'Produit'
                                                                                                            : 'Prestation de service'}
                                                                                                    </Typography>
                                                                                                }
                                                                                            />
                                                                                        </TableCell>
                                                                                    );
                                                                                }

                                                                                if (
                                                                                    column.id ==
                                                                                    'name'
                                                                                ) {
                                                                                    return (
                                                                                        <TableCell
                                                                                            key={
                                                                                                bill.numFacture
                                                                                            }
                                                                                            align={
                                                                                                column.align
                                                                                            }>
                                                                                            <ListItemText
                                                                                                primary={`${bill.title}. ${bill.name} ${bill.surname}`}
                                                                                                secondary={
                                                                                                    <Typography variant="body2">
                                                                                                        {
                                                                                                            bill.adresse
                                                                                                        }

                                                                                                        ,{' '}
                                                                                                        {
                                                                                                            bill.cp
                                                                                                        }{' '}
                                                                                                        {
                                                                                                            bill.city
                                                                                                        }
                                                                                                    </Typography>
                                                                                                }
                                                                                            />
                                                                                        </TableCell>
                                                                                    );
                                                                                }

                                                                                if (
                                                                                    column.id ==
                                                                                    'mail'
                                                                                ) {
                                                                                    return (
                                                                                        <TableCell
                                                                                            key={`mail-${bill.numFacture}`}
                                                                                            align={
                                                                                                column.align
                                                                                            }>
                                                                                            {bill.mail ==
                                                                                                '0' &&
                                                                                            bill.marchand_id !==
                                                                                                null ? (
                                                                                                <Field
                                                                                                    sendData={
                                                                                                        this
                                                                                                            .sendMissedData
                                                                                                    }
                                                                                                    data={
                                                                                                        bill
                                                                                                    }
                                                                                                />
                                                                                            ) : (
                                                                                                <Button
                                                                                                    disabled={
                                                                                                        bill.marchand_id ===
                                                                                                        null
                                                                                                    }
                                                                                                    size="small"
                                                                                                    variant="contained"
                                                                                                    color="primary"
                                                                                                    onClick={() =>
                                                                                                        this.sendByMail(
                                                                                                            bill.numero,
                                                                                                            bill.mail
                                                                                                        )
                                                                                                    }>
                                                                                                    Envoyer{' '}
                                                                                                    <SendIcon
                                                                                                        style={{
                                                                                                            marginLeft: 7
                                                                                                        }}
                                                                                                    />
                                                                                                </Button>
                                                                                            )}
                                                                                        </TableCell>
                                                                                    );
                                                                                }

                                                                                if (
                                                                                    column.id ==
                                                                                    'facture'
                                                                                ) {
                                                                                    return (
                                                                                        <TableCell
                                                                                            key={
                                                                                                bill.numFacture
                                                                                            }
                                                                                            align={
                                                                                                column.align
                                                                                            }>
                                                                                            <Grid
                                                                                                container
                                                                                                direction="row"
                                                                                                justify="flex-start"
                                                                                                alignItems="center">
                                                                                                <Grid
                                                                                                    item>
                                                                                                    <IconButton
                                                                                                        onClick={() =>
                                                                                                            this.getPdf(
                                                                                                                bill.numero
                                                                                                            )
                                                                                                        }>
                                                                                                        <DescriptionIcon />
                                                                                                    </IconButton>
                                                                                                </Grid>
                                                                                                <Grid
                                                                                                    item>
                                                                                                    <ListItemText
                                                                                                        primary={
                                                                                                            bill.numero
                                                                                                        }
                                                                                                        secondary={
                                                                                                            <React.Fragment>
                                                                                                                Réglé{' '}
                                                                                                                <DoneIcon
                                                                                                                    style={{
                                                                                                                        fontSize:
                                                                                                                            'inherit',
                                                                                                                        color:
                                                                                                                            'green'
                                                                                                                    }}
                                                                                                                />
                                                                                                            </React.Fragment>
                                                                                                        }
                                                                                                    />
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </TableCell>
                                                                                    );
                                                                                }

                                                                                return (
                                                                                    <TableCell
                                                                                        key={
                                                                                            bill.ref
                                                                                        }
                                                                                        align={
                                                                                            column.align
                                                                                        }>
                                                                                        {
                                                                                            bill[
                                                                                                column
                                                                                                    .id
                                                                                            ]
                                                                                        }{' '}
                                                                                        {column.id ==
                                                                                            'montant' &&
                                                                                            ' €'}
                                                                                    </TableCell>
                                                                                );
                                                                            })}
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                    <Grid item>
                                                        <Pagination
                                                            numberOfPages={numberOfPages}
                                                            currentPage={page}
                                                            handlePagination={this.handlePagination}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </TabControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(OtherSalesBills);
