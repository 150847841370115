import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const Pax = () => {
    const classes = useStyles();

    const urlParams = new URLSearchParams(window.location.search);
    const back = urlParams.get('back');
    const filter = urlParams.get('filter');

    useEffect(() => {
        setTimeout(() => {
            back && window.location.replace(`${back}?filter=${filter}&pax=true`);
        }, 5000);
    }, []);

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="column"
                spacing={4}
                alignItems="center"
                alignContent="center">
                <Grid item>
                    <CircularProgress size={48} />
                </Grid>
                <Grid item>
                    <Typography variant="h4">Impression en cours...</Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default Pax;
