import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
    Paper,
    Grid,
    Divider,
    Typography,
    IconButton,
    Link,
    TextField,
    InputAdornment,
    Button,
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Radio,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel
} from '@material-ui/core';

import { Pagination, Field } from './components';

import { EmailField } from '../../../components';

import { Email } from '../.';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';

const styles = theme => ({
    root: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

const columns = [
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'field',
        label: 'Nouvelle donnée',
        minWidth: '60%',
        align: 'left'
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: '20%',
        align: 'center'
    }
];

class AlertAssurance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            searchValue: '',
            importedMails: [],
            notify: '',
            type: 'all',
            insuranceFilter: 'missing',
            expiredSoon: false,
            page: 1,
            count: 0,
            list: []
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        (prevState.searchValue != this.state.searchValue ||
            prevState.page != this.state.page ||
            prevState.insuranceFilter != this.state.insuranceFilter ||
            prevState.type != this.state.type) &&
            this.getData();
    };

    getData = () => {
        const { searchValue, page, type, insuranceFilter } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-alert-data-assurance?search=${searchValue}&page=${page}&type=${type}&insuranceFilter=${insuranceFilter}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.props.updateCompteur();
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    sendData = (first, second, marchand_id) => {
        const formData = new FormData();
        first.replace(/\s/g, '').length && formData.append('first', first);
        second && formData.append('second', second);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/update-fields-about-assurance/${marchand_id}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(res => res.json())
            .then(data => {
                if (data) {
                    alert('Mise à jour effectuée');
                    this.getData();
                }
            })
            .catch(console.log);
    };

    getPdf = () => {
        const { type, insuranceFilter } = this.state;

        let _confirm = window.confirm('Imprimer la sélection ?');
        _confirm &&
            window.open(
                `https://www.${
                    window.location.hostname.split('.')[1]
                        ? window.location.hostname.split('.')[0] === 'www'
                            ? window.location.hostname.split('.')[1]
                            : window.location.hostname.split('.')[0]
                        : 'dev'
                }.api.digitmarche.fr/api/merchant/print-get-by-alert-assurance?type=${type}&filter=${insuranceFilter}`,
                '_blank'
            );
    };

    handleExpiredSoon = () => {
        this.setState(prevState => ({
            expiredSoon: !prevState.expiredSoon
        }));
    };

    notify = (notify, importedMails) => {
        this.setState({
            notify: notify,
            importedMails: importedMails
        });
    };

    handleTypeFilter = ({ target: { value } }) => {
        this.setState({
            type: value,
            page: 1
        });
    };

    handleInsuranceFilter = ({ target: { value } }) => {
        this.setState({
            insuranceFilter: value,
            page: 1
        });
    };

    render() {
        const {
            classes,
            searchValue,
            list,
            count,
            page,
            type,
            expiredSoon,
            notify,
            importedMails,
            insuranceFilter
        } = this.state;

        const { params, history } = this.props;

        return (
            <div>
                {params.siret == 'notify' && (
                    <Email
                        importedMails={importedMails}
                        history={history}
                        notify={notify}
                        open={true}
                    />
                )}

                <Paper className={classes.root}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Grid container direction="row" alignItems="baseline">
                                <Grid item>
                                    <IconButton onClick={this.getBack}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        <Link component={RouterLink} to="/merchants/all">
                                            Les commerçants
                                        </Link>{' '}
                                        / Alerte données d'assurances
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <TextField
                                label={'Rechercher un commerçant'}
                                type="text"
                                fullWidth
                                value={searchValue}
                                onChange={this.handleSearch}
                                variant="outlined"
                                helperText="Saisir un SIRET/SIREN, une raison sociale, une enseigne, un nom ou un prénom"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={this.clearSearch} edge="end">
                                                <ClearIcon style={{ color: 'red' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset" style={{ marginTop: 10 }}>
                                <FormLabel component="legend" className={classes.labelFilter}>
                                    Type du commerçant
                                </FormLabel>
                                <RadioGroup
                                    row
                                    name="type"
                                    value={type}
                                    onChange={this.handleTypeFilter}>
                                    <FormControlLabel
                                        value="all"
                                        control={<Radio />}
                                        label="Tous"
                                    />
                                    <FormControlLabel
                                        value="titulaire"
                                        control={<Radio />}
                                        label="Titulaire"
                                    />
                                    <FormControlLabel
                                        value="passager"
                                        control={<Radio />}
                                        label="Passager"
                                    />
                                    <FormControlLabel
                                        value="blocked"
                                        control={<Radio style={{ color: 'red' }} />}
                                        label="Bloqué"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                justify="space-between"
                                direction="row"
                                spacing={2}
                                alignItems="flex-end">
                                <Grid item>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <FormControl
                                                component="fieldset"
                                                style={{ marginTop: 10 }}>
                                                <FormLabel
                                                    component="legend"
                                                    className={classes.labelFilter}>
                                                    Date d'assurance
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    name="type"
                                                    value={insuranceFilter}
                                                    onChange={this.handleInsuranceFilter}>
                                                    <FormControlLabel
                                                        value="missing"
                                                        control={<Radio />}
                                                        label="Manquante"
                                                    />
                                                    <FormControlLabel
                                                        value="expired"
                                                        control={<Radio />}
                                                        label="Expirée"
                                                    />
                                                    <FormControlLabel
                                                        value="expiredSoon"
                                                        control={<Radio />}
                                                        label="Bientôt expirée"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item>
                                            <Button
                                                endIcon={<MailIcon />}
                                                component={RouterLink}
                                                to={`/reminder/${
                                                    insuranceFilter == 'expired'
                                                        ? 'expired_insurance'
                                                        : insuranceFilter == 'missing'
                                                        ? 'missing_insurance'
                                                        : 'insurance_expired_soon'
                                                }/${
                                                    type == 'all'
                                                        ? 'all'
                                                        : type == 'titulaire'
                                                        ? 't'
                                                        : 'p'
                                                }`}
                                                color="primary">
                                                Notifier par mail
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                endIcon={<PrintIcon />}
                                                onClick={this.getPdf}
                                                color="primary">
                                                Imprimer
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                        <TableContainer className={classes.container}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map(column => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{
                                                                    minWidth: column.minWidth
                                                                }}>
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {list.map(merchant => {
                                                        return (
                                                            <TableRow hover key={merchant.siret}>
                                                                {columns.map(column => {
                                                                    if (column.id == 'field') {
                                                                        return (
                                                                            <TableCell
                                                                                key={`${merchant.siret}_${column.id}`}
                                                                                align={
                                                                                    column.align
                                                                                }>
                                                                                <Field
                                                                                    sendData={
                                                                                        this
                                                                                            .sendData
                                                                                    }
                                                                                    data={merchant}
                                                                                />
                                                                            </TableCell>
                                                                        );
                                                                    }

                                                                    if (column.id == 'action') {
                                                                        return (
                                                                            <TableCell
                                                                                key={`${merchant.siret}_${column.id}`}
                                                                                align={
                                                                                    column.align
                                                                                }>
                                                                                {merchant.email ? (
                                                                                    <Button
                                                                                        component={
                                                                                            RouterLink
                                                                                        }
                                                                                        to={`/merchants/alert-data-assurance/notify`}
                                                                                        onClick={() =>
                                                                                            this.notify(
                                                                                                merchant.notify,
                                                                                                [
                                                                                                    {
                                                                                                        id:
                                                                                                            merchant.id,
                                                                                                        raisonSociale:
                                                                                                            merchant.raisonSociale
                                                                                                    }
                                                                                                ]
                                                                                            )
                                                                                        }
                                                                                        endIcon={
                                                                                            <MailOutlineIcon />
                                                                                        }
                                                                                        variant="contained"
                                                                                        color="primary">
                                                                                        Notifier
                                                                                    </Button>
                                                                                ) : (
                                                                                    <EmailField
                                                                                        refresh={
                                                                                            this
                                                                                                .getData
                                                                                        }
                                                                                        merchantId={
                                                                                            merchant.id
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </TableCell>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <TableCell
                                                                            key={`${merchant.siret}_${column.id}`}
                                                                            align={column.align}>
                                                                            <Link
                                                                                component={
                                                                                    RouterLink
                                                                                }
                                                                                to={`/merchants/get/${merchant.siret}/profil`}>
                                                                                {
                                                                                    merchant[
                                                                                        column.id
                                                                                    ]
                                                                                }
                                                                            </Link>
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item>
                            <Pagination
                                numberOfPages={count}
                                currentPage={page}
                                handlePagination={this.handlePagination}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(AlertAssurance);
