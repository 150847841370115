import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@material-ui/core';

const styles = (theme) => ({
    labelFilter: {
        color: "#3f51b5",
        marginBottom: 10
    },
});

const RadioFilter = withStyles(styles)((props) => {
    const { children, classes, data, handleFilter, ...other } = props;
    return (
        <FormControl component="fieldset" required>
            <FormLabel component="legend" className={classes.labelFilter}>
                {data.filters.label}
            </FormLabel>
            <RadioGroup row value={data.value} onChange={({ target: { value } }) => handleFilter(value)}>
                {
                    data.filters.rows.map((row, index) => {
                        return (
                            <FormControlLabel
                                label={row.label}
                                value={row.value}
                                key={index}
                                control={
                                    <Radio
                                        size="small"
                                        style={{ color: row.color }}
                                    />
                                }
                            />
                        )
                    })
                }
            </RadioGroup>
        </FormControl>
    );
});


export default RadioFilter;