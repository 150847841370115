import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableBody,
    TableHead
} from '@material-ui/core';

const styles = theme => ({
    root: {
        flexGrow: 1
    }
});

const columns = [
    {
        id: 'date',
        label: "Date de l'observation",
        minWidth: '20%',
        align: 'center'
    },
    {
        id: 'createdBy',
        label: 'Créé par',
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'comment',
        label: 'Observation',
        minWidth: '60%',
        align: 'left'
    }
];

class Comment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            marchand_id: this.props.marchandId,
            list: []
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        const { marchand_id } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-litiges/${marchand_id}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    list: data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    render() {
        const { classes, list } = this.state;

        return (
            <div>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map(column => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((litige, index) => {
                                return (
                                    <TableRow hover key={index}>
                                        {columns.map(column => {
                                            return (
                                                <TableCell
                                                    key={`${column.id}_${litige[column.id]}`}
                                                    align={column.align}>
                                                    {litige[column.id]}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default withStyles(styles)(Comment);
