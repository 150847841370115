// @flow

import React from 'react';
//import L from 'leaflet';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

import { withStyles } from '@material-ui/styles';

import { Control } from './components';

//delete L.Icon.Default.prototype._getIconUrl;

/*L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});*/

const styles = theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            //height: "33vh",
        },
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100vh - 100px)'
        }
    }
});

class MarketMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            showPos: false,
            mapConfiguration: {
                center: [0, 0],
                zoom: 19,
                maxZoom: 19,
                minZoom: 17,
                scrollWheelZoom: false,
                zoomControl: false,
                useFlyTo: false,
                doubleClickZoom: false
            }
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { mapConfiguration } = this.state;

        if (
            this.props.marketData.adresse &&
            (this.props.marketData.adresse.coordinates.lat !=
                prevProps.marketData.adresse.coordinates.lat ||
                this.props.marketData.adresse.coordinates.lng !=
                    prevProps.marketData.adresse.coordinates.lng)
        ) {
            this.setState({
                //showPos: true,
                showPos: false,
                mapConfiguration: {
                    ...mapConfiguration,
                    center: [
                        this.props.marketData.adresse.coordinates.lat,
                        this.props.marketData.adresse.coordinates.lng
                    ]
                }
            });
        }
    };

    componentDidMount = () => {
        const { mapConfiguration } = this.state;

        if (!this.props.marketData.adresse) {
            alert('Veuillez renseigner une adresse pour accéder au plan du marché.');
            this.props.history.push(`/markets/edit/caracteristic/${this.props.id}`);
        } else {
            this.setState({
                //showPos: true,
                showPos: false,
                mapConfiguration: {
                    ...mapConfiguration,
                    center: [
                        this.props.marketData.adresse.coordinates.lat,
                        this.props.marketData.adresse.coordinates.lng
                    ]
                }
            });
        }
    };

    updateShowPos = value => {
        this.setState({
            showPos: value
        });
    };

    render() {
        const { mapConfiguration, showPos, classes } = this.state;

        return (
            <Map {...mapConfiguration} className={classes.root}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; Digit'Marché"
                />
                {!showPos ? null : (
                    <Marker
                        position={[
                            this.props.marketData.adresse.coordinates.lat,
                            this.props.marketData.adresse.coordinates.lng
                        ]}>
                        <Popup>{this.props.marketData.adresse.description}</Popup>
                    </Marker>
                )}

                <Control
                    activities={this.props.activities}
                    raz={this.props.raz}
                    marketData={this.props.marketData}
                    merchants={this.props.merchants}
                    places={this.props.places}
                    removedPlaces={this.props.removedPlaces}
                    updateData={this.props.updateData}
                    showPos={showPos}
                    updateShowPos={this.updateShowPos}
                    //rebuildList={this.props.rebuildList}
                />
            </Map>
        );
    }
}

export default withStyles(styles)(MarketMap);
