import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    Dialog,
    DialogContent,
    Button,
    Grid,
    DialogActions,
    MenuItem,
    TextField,
    CircularProgress,
    Link
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DialogTitle, FileInput } from './components';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    previewContainer: {
        textAlign: 'center',
        border: '1px solid #c4c4c4',
        borderRadius: 4,
        padding: 8
    },
    previewImg: {
        width: '100%'
    }
});

class LocationPictureDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            label: '',
            isValid: false,
            file: props.fileId ? true : null,
            isPicture: props.fileId ? true : false,
            pictureHasChanged: false,
            method: props.fileId ? 'edit' : 'add',
            isNotTheSameFile: false
        };
    }

    componentDidMount = () => {
        this.state.method == 'edit' && this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.isValid && this.state.isValid != prevState.isValid) {
            this.sendData();
        }

        if (
            this.state.pictureHasChanged &&
            this.state.pictureHasChanged != prevState.pictureHasChanged
        ) {
            this.showPreview();
            this.setState({
                isNotTheSameFile: true
            });
        }
    };

    getData = () => {
        const { fileId, type, merchantId } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/document/${type}/${merchantId}?fileId=${fileId}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                const output_1 = document.getElementById('output_1');
                const output_2 = document.getElementById('output_2');
                output_1.src = data.file;
                output_2.href = data.file;

                this.setState({
                    ...data
                });
            })
            .catch(error => {
                console.error(error);
                alert('Echec de la récupération des données.');
            });
    };

    sendData = () => {
        const { file, fileId, label, type, merchantId, method, isNotTheSameFile } = this.state;

        const formData = new FormData();
        formData.append('label', label);
        formData.append('file', file);
        method == 'edit' && formData.append('fileId', fileId);
        method == 'edit' && formData.append('isNotTheSameFile', isNotTheSameFile);
        formData.append('do', method);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/document/${type}/${merchantId}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                alert(`Fichier ${method == 'edit' ? 'mise à jour' : 'enregistré'} avec succès.`);
                this.setState({
                    isValid: false
                });
                this.props.handleClose();
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    isValid: false
                });
                alert(`Echec de ${method == 'edit' ? 'la mise à jour' : "l'enregistrement"}.`);
            });
    };

    handleData = ({ target: { value, name } }) => {
        this.setState({
            [name]: value
        });
    };

    showPreview = () => {
        const { file, isPicture } = this.state;

        if (file) {
            const reader = new FileReader();
            reader.onload = function() {
                const output_1 = document.getElementById('output_1');
                output_1.src = isPicture ? reader.result : null;

                const output_2 = document.getElementById('output_2');
                output_2.href = reader.result;
            };

            reader.readAsDataURL(file);
        }

        this.setState({
            pictureHasChanged: false
        });
    };

    handleFile = value => {
        let isPicture = false;

        if (value) {
            const extension = this.getExtension(value.name);
            if (extension && (extension == 'png' || extension == 'jpg' || extension == 'jpeg')) {
                isPicture = true;
            }
        }

        this.setState({
            file: value,
            isPicture: isPicture,
            pictureHasChanged: true
        });
    };

    getExtension = filename => {
        return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0].toLowerCase() : undefined;
    };

    prepareData = () => {
        const { label, file } = this.state;

        if (!label.replace(/\s/g, '').length) {
            alert('Veuillez saisir un libellé.');
            return;
        }

        if (!file) {
            alert('Veuillez choisir un fichier.');
            return;
        }

        this.setState({
            isValid: true
        });
    };

    render() {
        const { classes, label, isValid, method, file, isPicture } = this.state;

        return (
            <Dialog fullWidth maxWidth={'sm'} onClose={this.props.handleClose} open>
                <DialogTitle onClose={this.props.handleClose}>
                    {method == 'edit' ? 'Modifier une photo' : 'Ajouter une nouvelle photo'}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <TextField
                                label={'Libellé'}
                                type="text"
                                fullWidth
                                value={label}
                                name="label"
                                variant="outlined"
                                helperText="Saisir un libellé"
                                onChange={this.handleData}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <FileInput
                                        file={file}
                                        acceptedMimeType={'image/*'}
                                        setFile={this.handleFile}
                                    />
                                </Grid>
                                <Grid item style={{ margin: 4 }}>
                                    {file && (
                                        <Grid
                                            className={classes.previewContainer}
                                            container
                                            direction="column"
                                            alignItems="center"
                                            spacing={1}>
                                            <Grid
                                                item
                                                style={{ display: isPicture ? 'block' : 'none' }}>
                                                <img className={classes.previewImg} id="output_1" />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    href=""
                                                    target="_blank"
                                                    id="output_2"
                                                    download={label == '' ? 'mon_fichier' : label}
                                                    component={Link}
                                                    color="primary">
                                                    Ouvrir le fichier
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {isValid && <CircularProgress color="primary" size={25} />}
                    <Button disabled={isValid} onClick={this.props.handleClose} color="primary">
                        Fermer
                    </Button>
                    <Button
                        disabled={isValid}
                        color="primary"
                        variant="contained"
                        onClick={this.prepareData}>
                        {method == 'edit' ? 'Modifier' : 'Ajouter'} la photo
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(LocationPictureDialog);
