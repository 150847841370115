import React, { useEffect, useState } from 'react';
import { Dialog } from 'components';
import {
    Button,
    Grid,
    InputAdornment,
    TextField,
    IconButton,
    Paper,
    Typography,
    Divider,
    Stepper,
    Step,
    StepLabel,
    TableBody,
    Table,
    TableRow,
    TableCell,
    MenuItem
} from '@material-ui/core';
// import { toUpper } from 'ramda';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import NumberFormat from 'react-number-format';
import PdfFrame from './PdfFrame';
import { toUpper } from 'ramda';
import { createBill, getBill, editBill, getTerrace } from '../services/http';
import { makeStyles } from '@material-ui/styles';
import { getUnits } from 'views/Unit/services/http';

const DEFAULT_ROW = {
    label: '',
    qty: 1,
    amount: 1,
    unite_id: ''
};

const DEFAULT_STATE = {
    billNumber: '',
    totalAmount: 1,
    rows: [{ ...DEFAULT_ROW }]
};

function CustomTextField(props) {
    const { inputRef, onChange, allowedToChangePrice, noIcon, ...other } = props;

    return (
        <TextField
            {...other}
            onChange={onChange}
            variant="outlined"
            fullWidth
            //helperText={props.name != 'total' && 'Saisir un prix unitaire'}
            InputProps={{
                readOnly: !allowedToChangePrice,
                endAdornment: noIcon ? null : (
                    <InputAdornment position="end">
                        <EuroSymbolIcon />
                    </InputAdornment>
                )
            }}
        />
    );
}

const useStyles = makeStyles(theme => ({
    table: {
        tableLayout: 'fixed'
    },
    tableBody: {
        '& tr:nth-child(even)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        },
        '& tr td': {
            paddingTop: 8,
            paddingBottom: 8
        },
        '& tr:first-child': {
            '& td': {
                borderTop: '1px solid #eee'
            }
        }
    }
}));

const steps = ['Edition', 'Visualisation'];

const BillDialog = ({ open, id, billId }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [units, setUnits] = useState([]);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [state, setState] = useState({
        billNumber: '',
        rows: [],
        terrace: {}
    });

    useEffect(() => {
        if (open.value) {
            setState({ ...DEFAULT_STATE });
            setActiveStep(0);

            if (billId) {
                getBill(id, billId)
                    .then(res =>
                        setState({
                            billNumber: res.number,
                            totalAmount: res.total,
                            rows: [...res.rows],
                            terrace: { ...res.terrace }
                        })
                    )
                    .catch(err => alert(err));
            } else {
                getTerrace(id)
                    .then(res =>
                        setState({
                            ...DEFAULT_STATE,
                            terrace: res
                        })
                    )
                    .catch(err => alert(err));
            }

            getUnits({ page: '*' }).then(res => setUnits(res.units));
        }
    }, [open.value]);

    useEffect(() => {
        const totalAmount = state.rows.reduce(
            (acc, curr) => acc + parseFloat(curr.qty ?? 0) * parseFloat(curr.amount ?? 0),
            0
        );
        setState({ ...state, totalAmount });
    }, [state.rows]);

    const handleBill = () => {
        setLoading(true);
        if (billId) {
            editBill(id, billId, state)
                .then(() => open.func())
                .catch(err => alert(err))
                .finally(() => setLoading(false));
        } else {
            createBill(id, state)
                .then(() => open.func())
                .catch(err => alert(err))
                .finally(() => setLoading(false));
        }
    };

    const handleState = (k, v) => {
        setState({ ...state, [k]: v });
    };

    const handleRow = (i, k, v) => {
        const rows = [...state.rows];
        rows[i][k] = v;
        setState({ ...state, rows });
    };

    const addRow = () => {
        const rows = [...state.rows];
        rows.push({ ...DEFAULT_ROW });
        setState({ ...state, rows });
    };

    const removeRow = idx => {
        const rows = [...state.rows].filter((_, i) => i !== idx);
        setState({ ...state, rows });
    };

    const moveRow = (idx, i) => {
        const rows = [...state.rows];

        const current = { ...rows[idx] };
        rows[idx] = { ...rows[idx + i] };
        rows[idx + i] = current;

        setState({ ...state, rows });
    };

    return (
        <Dialog
            open={open.value}
            title={billId ? 'Modifier la facture' : 'Créer une facture'}
            onClose={() => open.func()}
            action={
                <>
                    <Button
                        disabled={activeStep === 0}
                        onClick={() => setActiveStep(activeStep - 1)}
                        color="primary"
                        variant="contained">
                        Précédent
                    </Button>
                    <Button
                        disabled={activeStep === 1}
                        onClick={() => setActiveStep(activeStep + 1)}
                        color="primary"
                        variant="contained">
                        Suivant
                    </Button>
                    <Button
                        disabled={loading || activeStep === 0}
                        onClick={handleBill}
                        color="primary"
                        variant="contained">
                        {billId ? 'Modifier la facture' : 'Créer une facture'}
                    </Button>
                </>
            }>
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                {activeStep === 1 ? (
                    <Grid item>
                        <PdfFrame id={id} details={state} />
                    </Grid>
                ) : (
                    <>
                        <Grid item>
                            <Typography variant="h4" color="primary">
                                Informations terrasse
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            style={{
                                border: '1px solid #ddd',
                                padding: 0,
                                margin: 14,
                                borderRadius: 7
                            }}>
                            <Table size="small" className={classes.table}>
                                <TableBody className={classes.tableBody}>
                                    <TableRow hover>
                                        <TableCell align="left">Enseigne</TableCell>
                                        <TableCell align="left">
                                            {state.terrace?.enseigne}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover>
                                        <TableCell align="left">Référence</TableCell>
                                        <TableCell align="left">{state.terrace?.ref}</TableCell>
                                    </TableRow>
                                    <TableRow hover>
                                        <TableCell align="left">Commerçant</TableCell>
                                        <TableCell align="left">
                                            {state.terrace?.marchand?.raisonSociale ??
                                                state.terrace?.marchand}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover>
                                        <TableCell align="left">Date de création</TableCell>
                                        <TableCell align="left">
                                            {state.terrace?.createdAt}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" color="primary">
                                Informations facture
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Numéro de facture"
                                placeholder="XX-XX-XXX"
                                name="billNumber"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={state.billNumber}
                                onChange={e => handleState('billNumber', toUpper(e.target.value))}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">N°</InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={3}>
                                {state.rows.map((row, i) => (
                                    <Grid item key={i}>
                                        <Paper style={{ padding: 14 }} elevation={1}>
                                            <Grid
                                                container
                                                direction="row"
                                                spacing={2}
                                                alignItems="center"
                                                alignContent="center">
                                                <Grid item xs={2}>
                                                    <Typography color="primary" variant="body2">
                                                        Ligne n°{i + 1}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Divider />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: 7,
                                                            justifyContent: 'center'
                                                        }}>
                                                        <IconButton
                                                            size="small"
                                                            disabled={i === 0}
                                                            onClick={() => moveRow(i, -1)}>
                                                            <ArrowUpwardIcon fontSize="small" />
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            disabled={i === state.rows.length - 1}
                                                            onClick={() => moveRow(i, 1)}>
                                                            <ArrowDownwardIcon fontSize="small" />
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            disabled={state.rows.length === 1}
                                                            onClick={() => removeRow(i)}>
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Libellé"
                                                        name="nature"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        value={row.label}
                                                        onChange={e =>
                                                            handleRow(i, 'label', e.target.value)
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <NumberFormat
                                                        label="Quantité"
                                                        value={row.qty}
                                                        customInput={CustomTextField}
                                                        allowedToChangePrice={true}
                                                        onValueChange={v =>
                                                            handleRow(i, 'qty', v.value)
                                                        }
                                                        noIcon
                                                        thousandsGroupStyle="fr"
                                                        thousandSeparator={' '}
                                                        decimalSeparator={'.'}
                                                        type={'text'}
                                                        allowedDecimalSeparators={[
                                                            ',',
                                                            '.',
                                                            '?',
                                                            ';'
                                                        ]}
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        isNumericString
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <NumberFormat
                                                        label="Prix unitaire"
                                                        value={row.amount}
                                                        customInput={CustomTextField}
                                                        allowedToChangePrice={true}
                                                        onValueChange={v =>
                                                            handleRow(i, 'amount', v.value)
                                                        }
                                                        thousandsGroupStyle="fr"
                                                        thousandSeparator={' '}
                                                        decimalSeparator={','}
                                                        type={'text'}
                                                        allowedDecimalSeparators={[
                                                            ',',
                                                            '.',
                                                            '?',
                                                            ';'
                                                        ]}
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        isNumericString
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        label="Unité"
                                                        value={row.unite_id}
                                                        onChange={v =>
                                                            handleRow(i, 'unite_id', v.target.value)
                                                        }
                                                        variant="outlined">
                                                        {units.map(unit => (
                                                            <MenuItem key={unit.id} value={unit.id}>
                                                                {unit.libelle}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                ))}
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        onClick={addRow}>
                                        Ajouter une ligne
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <NumberFormat
                                label="Montant net"
                                value={state.totalAmount}
                                customInput={CustomTextField}
                                allowedToChangePrice={true}
                                onValueChange={v => handleState('totalAmount', v.value)}
                                thousandsGroupStyle="fr"
                                thousandSeparator={' '}
                                decimalSeparator={','}
                                type={'text'}
                                allowedDecimalSeparators={[',', '.', '?', ';']}
                                allowNegative={false}
                                decimalScale={2}
                                isNumericString
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Dialog>
    );
};

export default BillDialog;
