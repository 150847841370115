import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import { Grid, Button } from '@material-ui/core';

import { TotalAdded, All, Creation, ItinerantShop as MyItinerantShop } from './components';

import TabControl from './TabControl';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paperContent: {
        padding: theme.spacing(4)
        //padding: 10
    },
    item: {
        minWidth: '30%'
    }
});

class ItinerantShop extends React.Component {
    constructor(props) {
        super(props);

        const { cat, id } = this.props.match.params;

        this.state = {
            ...props,
            cat: cat,
            id: id,
            totalAdded: {
                number: 0,
                last: {
                    enseigne: '',
                    date: ''
                }
            }
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate(prevProps, prevState) {
        const { cat, id } = this.props.match.params;

        if (prevState.cat != cat || prevState.id != id) {
            this.setState({
                cat: cat,
                id: id
            });

            this.getData();
        }
    }

    getData = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/domaine/itinerant-shop/home-page`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    render() {
        const { classes, totalAdded, id, cat } = this.state;

        return (
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item md={6} sm={12} xs={12}>
                        <TotalAdded cat={cat} data={totalAdded} />
                    </Grid>
                    <Grid item xs={12}>
                        <TabControl value={cat} index={'all'}>
                            <All />
                        </TabControl>
                        <TabControl value={cat} index={'creation'}>
                            <Creation history={this.props.history} />
                        </TabControl>
                        <TabControl value={cat} index={'get'}>
                            <MyItinerantShop />
                        </TabControl>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(ItinerantShop);
