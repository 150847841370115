import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import { Grid, Divider, Button, CircularProgress, Typography, Link } from '@material-ui/core';

import { MarketState, Weather, ResultDrawDialog } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        //flexGrow: 1
    },
    item: {
        flexGrow: 1
    }
}));

const MarketCaracteristics = props => {
    const { className, data, init, id, ...rest } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const classes = useStyles();

    return (
        <>
            <ResultDrawDialog open={openDialog} id={id} close={() => setOpenDialog(false)} />

            <Grid
                container
                direction="row"
                justify={init ? 'center' : 'flex-start'}
                alignItems={init ? 'center' : 'flex-start'}
                spacing={2}
                className={classes.root}>
                {init ? (
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={1}>
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Grid item>
                            <Weather marketData={data} />
                        </Grid>
                        <Grid item className={classes.item}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        spacing={1}
                                                        alignItems="flex-end">
                                                        <Grid item>
                                                            <Typography
                                                                variant="h4"
                                                                color="primary">
                                                                A propos du marché
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body2">
                                                                (
                                                                <Link
                                                                    component={RouterLink}
                                                                    to={`/markets/edit/validation/${data.marketId}`}>
                                                                    Voir les caractéristiques du
                                                                    marché
                                                                </Link>
                                                                )
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">
                                                Nombre total de places théorique :{' '}
                                                {data.places.total.count} ({data.places.total.sum}{' '}
                                                mètres)
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">
                                                Nombre total de places occupées théorique :{' '}
                                                {data.places.unfree.count} ({data.places.unfree.sum}{' '}
                                                mètres)
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">
                                                Nombre total de places libres théorique :{' '}
                                                {data.places.free.count} ({data.places.free.sum}{' '}
                                                mètres)
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">
                                                Nombre de places supplémentaires créées ce jour :{' '}
                                                {data.places.additional.count} (
                                                {data.places.additional.sum} mètres)
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">
                                                Nombre de titulaires abonnés :{' '}
                                                {data.merchants.subscriber.count}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">
                                                Nombre de titulaires non abonnés :{' '}
                                                {data.merchants.noneSubscriber.count}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">
                                                Prix du mètre linéaire abonné :{' '}
                                                {data.subscriberPrice}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1">
                                                Prix du mètre linéaire non abonné :{' '}
                                                {data.noneSubscriberPrice} €
                                            </Typography>
                                        </Grid>
                                        {data.tirage === '1' && (
                                            <Grid item>
                                                <Typography variant="body1">
                                                    <Link
                                                        onClick={() => setOpenDialog(true)}
                                                        style={{ cursor: 'pointer' }}>
                                                        Afficher le résultat du tirage au sort
                                                    </Link>
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider
                                orientation="vertical"
                                style={{ height: 250 }}
                                variant="fullWidth"
                                flexItem
                            />
                        </Grid>
                        <Grid item className={classes.item}>
                            <Grid container direction="column" justify="space-between" spacing={2}>
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Typography variant="h4" color="primary">
                                                Etat du marché
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <MarketState
                                        data={{
                                            state: data.state,
                                            user: data.user,
                                            comment: data.comment
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </>
    );
};

export default MarketCaracteristics;
