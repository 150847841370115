import React from 'react';
import { makeStyles } from '@material-ui/styles';

import {
    Button,
    Grid,
    Divider,
    Typography
} from '@material-ui/core';

import {
    PreviewTable
} from './components'

const useStyles = makeStyles(theme => ({
    root: {

    },
}));

const Preview = props => {
    const { className, year, months, weeksPerYear, merchants, update, ...rest } = props;

    return (
        <div>
            {
                Boolean(merchants.length > 0 && months.length > 0) ?
                    <PreviewTable
                        update={update}
                        year={year}
                        months={months}
                        weeksPerYear={weeksPerYear}
                        merchants={merchants}
                    />
                    :
                    <Typography variant="body1">
                        Veuillez sélectionner au moins un mois et au moins un abonné pour pré-visualiser les factures.
                    </Typography>
            }
        </div>
    );
};

export default Preview;
