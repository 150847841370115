import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Doughnut } from 'react-chartjs-2';

import { Grid, Typography } from '@material-ui/core';

import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 10
    },
    chartContainer: {
        position: 'relative',
        //height: "30vh",
        width: '250px'
    },
    chartDispo: {
        width: 70,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    chartDispoText: {
        color: 'green'
    }
}));

const Statistics = props => {
    const { className, rawData, handleFilter, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    const data = {
        labels: ['Facture(s) réglée(s)', 'Facture(s) non réglée(s)', 'Facture(s) en retard'],
        datasets: [
            {
                borderWidth: [2, 2, 6],
                borderAlign: ['inner', 'inner', 'inner', 'inner'],
                borderColor: ['green', 'red', 'red', 'grey'],
                backgroundColor: ['green', 'red', 'orange', 'grey'],
                data: rawData.datasets.count
            }
        ]
    };

    const options = {
        responsive: true,
        onClick: function(event, element) {
            if (element.length > 0) {
                if (element[0]._index == 0) {
                    handleFilter({ target: { value: 'y' } });
                } else if (element[0]._index == 1 || element[0]._index == 2) {
                    handleFilter({ target: { value: 'n' } });
                } else if (element[0]._index == 3) {
                    handleFilter({ target: { value: 'canceled' } });
                } else {
                    handleFilter({ target: { value: 'all' } });
                }
            }
        },
        onHover: (e, element) => {
            if (element.length > 0) {
                e.target.style.cursor = 'pointer';
            } else {
                e.target.style.cursor = 'auto';
            }
        },
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        cutoutPercentage: 75,
        tooltips: {
            caretSize: 0,
            //displayColors: false,
            callbacks: {
                label: function(tooltipItem, data) {
                    return ` ${
                        tooltipItem.index == 1
                            ? rawData.due.count
                            : rawData.datasets.count[tooltipItem.index]
                    } facture(s) ${
                        tooltipItem.index == 0
                            ? 'réglée(s)'
                            : tooltipItem.index == 1
                            ? 'non réglée(s)'
                            : tooltipItem.index == 2
                            ? 'en retard'
                            : 'annulée(s)'
                    }`;
                },
                footer: function(tooltipItem, data) {
                    return `Total ${
                        tooltipItem[0].index == 0
                            ? 'réglée(s)'
                            : tooltipItem[0].index == 1
                            ? 'non réglée(s)'
                            : tooltipItem[0].index == 2
                            ? 'en retard'
                            : 'annulée(s)'
                    } : ${rawData.datasets.value.formatted[tooltipItem[0].index]} €`;
                }
            }
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0 // animation duration after a resize
    };

    return (
        <div className={classes.root}>
            <Grid container justify="center" alignItems="center" direction="row">
                <Grid item>
                    <div className={classes.chartContainer}>
                        <div className={classes.chartDispo}>
                            <Typography
                                align="center"
                                className={classes.chartDispoText}
                                variant="body2">
                                {rawData.paid.percentage}% réglées
                            </Typography>
                        </div>
                        <Doughnut data={data} options={options} />
                    </div>
                </Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="flex-start" spacing={1}>
                        <Grid item>
                            <Typography style={{ color: 'green' }}>
                                <strong>{rawData.paid.count}</strong> facture(s) réglée(s) :{' '}
                                <strong>{rawData.paid.value.formatted} €</strong>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: 'red' }}>
                                <strong>{rawData.due.count}</strong> facture(s) non réglée(s) :{' '}
                                <strong>{rawData.due.value.formatted} €</strong>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" alignItems="center">
                                <Grid item>
                                    <SubdirectoryArrowRightIcon style={{ color: 'darkorange' }} />
                                </Grid>
                                <Grid item>
                                    <Typography style={{ color: 'darkorange' }}>
                                        Dont <strong>{rawData.late.count}</strong> facture(s) en
                                        retard : <strong>{rawData.late.value.formatted} €</strong>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: 'grey' }}>
                                <strong>{rawData.canceled.count}</strong> facture(s) annulée(s) :{' '}
                                <strong>{rawData.canceled.value.formatted} €</strong>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                <strong>{rawData.total.count}</strong> facture(s) au total :{' '}
                                <strong>{rawData.total.value} €</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Statistics;
