import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
    Grid,
    StepButton,
    Step,
    Stepper,
    IconButton,
    Typography,
    Divider,
    Link,
    Paper,
    Button
} from '@material-ui/core';

import { EventForm, Osm, ValidationForm } from './components';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TabControl from './TabControl';
import L from 'leaflet';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paperContent: {
        padding: theme.spacing(4)
        //padding: 10
    },
    item: {
        minWidth: '30%'
    }
});

class Event extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            id: null,
            label: '',
            adresse: '',
            lat: 0,
            lng: 0,
            actif: false,
            nbPlaces: 0,
            nbPlacesOccuped: 0,
            commentaire: '',
            places: [],
            marchands: []
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate(prevProps, prevState) {}

    getStep = step => {
        let activeStep = 0;

        if (step == 'map') {
            activeStep = 1;
        }

        if (step == 'validation') {
            activeStep = 2;
        }

        return activeStep;
    };

    getLinkStep = (forward, step) => {
        let link = 'info';

        if (forward) {
            if (step == 'info') {
                link = 'map';
            } else if (step == 'map') {
                link = 'validation';
            }
        } else {
            if (step == 'map') {
                link = 'info';
            } else if (step == 'validation') {
                link = 'map';
            }
        }

        return link;
    };

    getData = () => {
        const { id } = this.props;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/event/get-event/info/${id}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleForm = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    setPlace = (places, number, numberOccuped) => {
        this.setState({
            places: places,
            nbPlaces: number,
            nbPlacesOccuped: numberOccuped
        });
    };

    sendData = () => {
        const {
            id,
            label,
            lat,
            lng,
            adresse,
            actif,
            commentaire,
            places,
            nbPlaces,
            nbPlacesOccuped
        } = this.state;

        if (label == '') {
            alert('Veuillez saisir un libellé');
            this.props.history.push(`/events/event/${id}/info`);
            return;
        }

        if (nbPlaces == 0) {
            alert("Aucune place n'a été créée");
            this.props.history.push(`/events/event/${id}/map`);
            return;
        }

        let _confirm = window.confirm('Confirmer la mise à jour de l\'événement "' + label + '" ?');

        if (!_confirm) {
            return;
        }

        const formData = new FormData();
        formData.append('label', label);
        formData.append('lat', lat);
        formData.append('lng', lng);
        formData.append('adresse', adresse);
        formData.append('actif', actif);
        formData.append('commentaire', commentaire);
        formData.append('nbPlaces', nbPlaces);

        places.map((data, index) => {
            formData.append(`places[${index}][numero]`, data.numeroPlace);
            formData.append(`places[${index}][marchand_id]`, data.marchand_id);
            formData.append(`places[${index}][metres]`, data.metres);
            formData.append(`places[${index}][rotation]`, data.rotation);
            formData.append(`places[${index}][a_lat]`, data.a_lat);
            formData.append(`places[${index}][a_lng]`, data.a_lng);
            formData.append(`places[${index}][b_lat]`, data.b_lat);
            formData.append(`places[${index}][b_lng]`, data.b_lng);
            formData.append(`places[${index}][c_lat]`, data.c_lat);
            formData.append(`places[${index}][c_lng]`, data.c_lng);
            formData.append(`places[${index}][d_lat]`, data.d_lat);
            formData.append(`places[${index}][d_lng]`, data.d_lng);
        });

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/event/update-event/${id}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                if (data) {
                    alert('Mise à jour effectuée.');
                    this.props.history.push(`/events/event/${id}/info`);
                }
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    render() {
        const {
            classes,
            id,
            label,
            lat,
            lng,
            adresse,
            actif,
            commentaire,
            places,
            marchands,
            nbPlaces,
            nbPlacesOccuped
        } = this.state;

        const { step } = this.props;

        const activeStep = this.getStep(step);

        const linkStepForward = this.getLinkStep(true, step);
        const linkStepBackward = this.getLinkStep(false, step);

        return (
            <div>
                <Paper className={classes.paperContent}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Grid container direction="row" alignItems="baseline">
                                <Grid item>
                                    <IconButton onClick={this.getBack}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        <Link component={RouterLink} to="/events/all">
                                            Les événements
                                        </Link>{' '}
                                        / {label}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                                <Step key={0}>
                                    <StepButton
                                        component={RouterLink}
                                        to={`/events/event/${id}/info`}>
                                        Caractéristique
                                    </StepButton>
                                </Step>
                                <Step key={1}>
                                    <StepButton
                                        component={RouterLink}
                                        to={`/events/event/${id}/map`}>
                                        Map
                                    </StepButton>
                                </Step>
                                <Step key={2}>
                                    <StepButton
                                        component={RouterLink}
                                        to={`/events/event/${id}/validation`}>
                                        Validation
                                    </StepButton>
                                </Step>
                            </Stepper>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <TabControl value={step} index={'info'}>
                                <EventForm
                                    data={{
                                        label: label,
                                        adresse: adresse,
                                        lat: lat,
                                        lng: lng,
                                        actif: actif,
                                        commentaire: commentaire
                                    }}
                                    handleForm={this.handleForm}
                                />
                            </TabControl>
                            <TabControl value={step} index={'map'}>
                                <Osm
                                    id={id}
                                    setPlace={this.setPlace}
                                    data={{
                                        adresse: {
                                            lat: lat,
                                            lng: lng
                                        },
                                        places: places,
                                        marchands: marchands,
                                        layersGroup: L.featureGroup()
                                    }}
                                />
                            </TabControl>
                            <TabControl value={step} index={'validation'}>
                                <ValidationForm
                                    id={id}
                                    data={{
                                        label: label,
                                        commentaire: commentaire,
                                        adresse: adresse,
                                        nbPlaces: nbPlaces,
                                        places: places,
                                        nbPlacesOccuped: nbPlacesOccuped
                                    }}
                                />
                            </TabControl>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" spacing={2} justify="space-between">
                                <Grid item>
                                    <Button
                                        disabled={Boolean(step == 'info')}
                                        component={RouterLink}
                                        to={`/events/event/${id}/${linkStepBackward}`}>
                                        Précédent
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {step == 'validation' ? (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={this.sendData}>
                                            Valider les changements
                                        </Button>
                                    ) : (
                                        <Button
                                            disabled={Boolean(step == 'validation')}
                                            color="primary"
                                            variant="contained"
                                            component={RouterLink}
                                            to={`/events/event/${id}/${linkStepForward}`}>
                                            Suivant
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(Event);
