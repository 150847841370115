import React from 'react';

import { makeStyles } from '@material-ui/styles';
import NumberFormat from 'react-number-format';

import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    Button,
    Divider,
    InputAdornment, 
    CircularProgress
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

import { FileInput } from './components';


const useStyles = makeStyles(theme => ({
    head: {
        background: "rgb(192,226,251,0.35)",
        borderBottom: "1px solid #c0e2fb"
    },
    cellKabis: {
        borderLeft: "7px solid #c0e2fb"
    }
}));


function CustomTextFieldPrice(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <TextField
            {...other}
            onChange={onChange}
            variant="outlined"
            fullWidth
            label={(props.name == "tarif" ? "Prix unitaire" : "Montant total")}
            helperText={(props.name == "tarif" && "Saisir un prix unitaire")}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <EuroSymbolIcon />
                </InputAdornment>,
            }}
        />
    );
}


const Form = props => {
    const { className, data, listOfMerchant, listOfUnity, sendData, isLoading, ...rest } = props;

    const classes = useStyles();

    const _intergers = /^[0-9\b]+$/;

    const [state, setState] = React.useState(
        {
            ref: "",
            enseigne: "",
            aot: null,
            merchant: null,
            activitie: "",
            adresse: "",
            photo1: null,
            photo2: null,
            photo3: null,
            unity: null,
            qte: 0,
            total: 0,
            tarif: 0,
            info: "",
            start: "",
            end: ""
        }
    );

    const handleData = ({ target: { name, value } }) => {

        let total = state.total;

        if (!(value === '' || _intergers.test(value)) && name == "qte") {
            return;
        }

        if (name == "qte" || name == "tarif") {
            if (value != "" && (name == "qte" || name == "tarif")) {
                if (name == "qte") {
                    total = parseInt(value) * parseFloat(state.tarif)
                } else if (name == "tarif") {
                    total = parseInt(state.qte) * parseFloat(value)
                } else {
                    total = parseInt(state.qte) * parseFloat(state.tarif)
                }
            }
        }


        setState({ ...state, [name]: value, total: total });
    }

    const handleMerchant = (event, value) => {
        setState({ ...state, merchant: value });
    }

    const handleFile = (name, file) => {
        setState({ ...state, [name]: file });
    }

    const handleUnity = (event, value) => {
        setState({ ...state, unity: value });
    }

    const prepareData = () => {
        sendData(state);
    }


    return (
        <Grid container direction="column" spacing={5}>
            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography color="primary" variant="h5">
                                    Caractéristiques administratives
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider style={{ width: "80%", color: "#ddd" }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3} direction="row">
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Référence"
                                    helperText="Saisir une référence"
                                    name="ref"
                                    type="text"
                                    onChange={handleData}
                                    value={state.ref}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Enseigne"
                                    helperText="Saisir une enseigne"
                                    name="enseigne"
                                    type="text"
                                    onChange={handleData}
                                    value={state.enseigne}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Autocomplete
                                    options={listOfMerchant}
                                    //size="small"
                                    autoHighlight
                                    value={state.merchant}
                                    onChange={handleMerchant}
                                    noOptionsText={"Aucun commerçant trouvé"}
                                    getOptionLabel={(option) => option.raisonSociale}
                                    getOptionSelected={(option, value) => option.id == value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            {...params}
                                            label="Commerçant"
                                            helperText={"Choisir un commerçant"}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Activité"
                                    helperText="Saisir une activité"
                                    name="activitie"
                                    type="text"
                                    onChange={handleData}
                                    value={state.activitie}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Adresse de la terrasse"
                                    helperText="Saisir une adresse"
                                    name="adresse"
                                    type="text"
                                    onChange={handleData}
                                    value={state.adresse}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography color="primary" variant="h5">
                            AOT
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Divider style={{ width: "80%", color: "#ddd" }} />
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FileInput setFile={handleFile} name="aot" file={state.aot} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography color="primary" variant="h5">
                            Photo(s) (3 maximums)
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Divider style={{ width: "80%", color: "#ddd" }} />
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FileInput setFile={handleFile} name="photo1" file={state.photo1} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FileInput setFile={handleFile} name="photo2" file={state.photo2} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FileInput setFile={handleFile} name="photo3" file={state.photo3} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography color="primary" variant="h5">
                            Caractéristiques techniques
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Divider style={{ width: "80%", color: "#ddd" }} />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2} direction="row">
                            <Grid item sm={3} xs={6}>
                                <NumberFormat
                                    value={state.tarif}
                                    customInput={CustomTextFieldPrice}
                                    onValueChange={(values) => {
                                        handleData({
                                            target: {
                                                name: "tarif",
                                                value: values.value,
                                            },
                                        });
                                    }}
                                    //onChange={handleData}
                                    thousandsGroupStyle="fr"
                                    thousandSeparator={" "}
                                    decimalSeparator={","}
                                    type={'text'}
                                    name="tarif"
                                    allowedDecimalSeparators={[",", ".", "?", ";"]}
                                    allowNegative={false}
                                    decimalScale={2}
                                    isNumericString
                                />
                            </Grid>
                            <Grid item sm={4} xs={6}>
                                <Autocomplete
                                    options={listOfUnity}
                                    autoHighlight
                                    //size="small"
                                    value={state.unity}
                                    onChange={handleUnity}
                                    noOptionsText={"Aucune unité trouvée"}
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option, value) => option.id == value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            {...params}
                                            helperText={"Choisir une unité"}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item sm={2} xs={6}>
                                <TextField
                                    fullWidth
                                    label="Quantité"
                                    helperText="Saisir une quantité"
                                    name="qte"
                                    type="text"
                                    onChange={handleData}
                                    value={state.qte}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item sm={3} xs={6}>
                                <NumberFormat
                                    value={state.total}
                                    customInput={CustomTextFieldPrice}
                                    onValueChange={(values) => {
                                        handleData({
                                            target: {
                                                name: "total",
                                                value: values.value,
                                            },
                                        });
                                    }}
                                    //onChange={handleData}
                                    thousandsGroupStyle="fr"
                                    thousandSeparator={" "}
                                    decimalSeparator={","}
                                    type={'text'}
                                    name="total"
                                    allowedDecimalSeparators={[",", ".", "?", ";"]}
                                    allowNegative={false}
                                    decimalScale={2}
                                    isNumericString
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Information(s) complémentaire(s)"
                                    helperText="Saisir une/des informations(s) concernant le commerce"
                                    name="info"
                                    type="text"
                                    rows={5}
                                    multiline
                                    onChange={handleData}
                                    value={state.info}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography color="primary" variant="h5">
                            Dates d'autorisation
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Divider style={{ width: "80%", color: "#ddd" }} />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3} direction="row">
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    label="Date de début"
                                    helperText="Choisir une date"
                                    name="start"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleData}
                                    value={state.start}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    type="date"
                                    label="Date de fin"
                                    helperText="Choisir une date"
                                    name="end"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleData}
                                    value={state.end}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ textAlign: "center" }}>
                <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                    <Grid item>
                        <Button disabled={isLoading} onClick={prepareData} variant="contained" color="primary">
                            Créer ce commerce ambulant
                        </Button>
                    </Grid>
                    {
                        isLoading &&
                        <Grid item>
                            <CircularProgress color="primary" size={25} />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid >
    );
};

export default Form;
