import env from 'common/env';
import { empty } from 'common/validators';

export async function payBills(bills, paymentMethod) {
    const body = new FormData();
    body.append(
        'payload',
        JSON.stringify({
            bills,
            paymentMethod
        })
    );

    const result = await fetch(`${env}/regie/pay-bills`, {
        method: 'POST',
        body
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw "Une erreur est survenue lors du règlement, certaines factures n'ont pas pu être réglées.";
        });

    return result;
}

/**
 *
 * @returns
 */
export async function getPaymentMethods() {
    const result = await fetch(`${env}/regie/get-means-of-payment`, {
        method: 'GET'
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw "Une erreur est survenue lors de l'affichage des moyens de paiement.";
        });

    return result;
}

/**
 *
 * @param {*} bills
 * @returns
 */
export async function getBillsBeforePaid(bills) {
    const formData = new FormData();
    formData.append('bills', JSON.stringify(bills));

    const result = await fetch(`${env}/regie/get-bills-before-paid`, {
        method: 'POST',
        body: formData
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw `Une erreur s'est produite lors de l'affichage des factures.`;
        });

    return result;
}

/**
 *
 * @param {*} merchantId
 * @returns
 */
export async function getMerchant(merchantId) {
    const result = await fetch(`${env}/regie/get-merchant-for-grouped-bills/${merchantId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw `Une erreur s'est produite lors de l'affichage du commerçant.`;
        });

    return result;
}

/**
 *
 * @param {*} merchantId
 * @returns
 */
export async function getFilters(merchantId) {
    const result = await fetch(`${env}/regie/get-grouped-bills-filters/${merchantId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw `Une erreur s'est produite lors de l'affichage des filtres.`;
        });

    return result;
}

/**
 *
 * @param {*} merchantId
 * @param {*} filters
 * @param {*} start
 * @param {*} end
 * @returns
 */
export async function getGroupedBillsByFilters(merchantId, filters, start = '', end = '') {
    const formData = new FormData();
    formData.append('filters', JSON.stringify(filters));
    !empty(start) && formData.append('start', start);
    !empty(end) && formData.append('end', end);

    const result = await fetch(`${env}/regie/get-grouped-bills-by-market/${merchantId}`, {
        method: 'POST',
        body: formData
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw `Une erreur s'est produite lors de l'affichage des factures.`;
        });

    return result;
}
