import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Avatar,
    Button,
    CardActions,
    useMediaQuery
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch'
    },
    button: {
        textAlign: 'center'
    },
    content: {
        height: '100%'
    },
    avatar: {
        float: 'right',
        backgroundColor: theme.palette.primary.main,
        height: 50,
        width: 50,
        '& svg': {
            height: 32,
            width: 32
        }
    }
}));

/**
 * Title with subtitle, Title with number, Title with number and subtitle
 *
 * @param {*} props
 * @returns
 */
export const CardButton = props => {
    const {
        title,
        subtitle,
        button,
        icon,
        to,
        count,
        color,
        text,
        raised,
        disabled,
        subtitleColor
    } = props;

    const classes = useStyles();
    const matches = useMediaQuery('(max-width:450px)');
    const describe =
        typeof text === 'object' ? (
            text.map((row, index) => (
                <Typography variant="body2" color="textSecondary" key={index}>
                    {row}
                </Typography>
            ))
        ) : (
            <Typography variant="body2" color="textSecondary">
                {text}
            </Typography>
        );

    return (
        <Card className={classes.card} raised={raised}>
            <CardContent className={classes.content}>
                <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-between"
                    spacing={2}>
                    <Grid item xs={matches ? 12 : 8} md={9}>
                        <Grid
                            style={{ display: 'block' }}
                            container
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="stretch"
                            spacing={1}>
                            <Grid item>
                                <Grid container style={{ display: 'block' }} direciton="column">
                                    <Grid item>
                                        <Typography variant="body1">{title}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            noWrap
                                            variant="body2"
                                            style={subtitleColor ? { color: subtitleColor } : {}}>
                                            {subtitle}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h3"
                                    color="primary"
                                    style={color ? { color: color } : {}}>
                                    {count}
                                </Typography>
                            </Grid>
                            {text && <Grid item>{describe}</Grid>}
                        </Grid>
                    </Grid>
                    {!matches && (
                        <Grid item xs={4} md={3}>
                            <Avatar
                                className={classes.avatar}
                                style={color ? { backgroundColor: color } : {}}>
                                {icon}
                            </Avatar>
                        </Grid>
                    )}
                </Grid>
            </CardContent>

            <CardActions>
                <Button
                    disabled={disabled}
                    className={classes.button}
                    component={RouterLink}
                    to={to}
                    color="primary"
                    fullWidth
                    size="small"
                    style={
                        !disabled && color
                            ? {
                                  color: color
                              }
                            : {}
                    }>
                    {button}
                </Button>
            </CardActions>
        </Card>
    );
};

CardButton.propTypes = {
    title: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.string,
    subtitle: PropTypes.string,
    text: PropTypes.string,
    disabled: PropTypes.bool,
    subtitleColor: PropTypes.string
};

export default CardButton;
