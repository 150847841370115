import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, Avatar, Button } from '@material-ui/core';

import BuildIcon from '@material-ui/icons/Build';
import CardActionArea from '@material-ui/core/CardActionArea';
import { getLastUnit } from './services/http';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        height: 56,
        width: 56
    },
    number: {
        color: theme.palette.primary.main
    },
    icon: {
        height: 32,
        width: 32
    },
    labelAction: {
        width: '100%',
        textAlign: 'center'
    },
    footer: {
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center'
    }
}));

const TotalAddedCard = ({ className, rest }) => {
    const classes = useStyles();
    const [data, setData] = useState({});

    useEffect(() => {
        getLastUnit()
            .then(res => setData(res))
            .catch(err => alert(err));
    }, []);

    return (
        <CardActionArea component={RouterLink} to={'/settings/unit/create'}>
            <Card {...rest} className={clsx(classes.root, className)}>
                <CardContent>
                    <Grid container direction="column" spacing={2}>
                        <Grid item container justify="space-between">
                            <Grid item>
                                <Typography
                                    className={classes.title}
                                    color="textSecondary"
                                    gutterBottom
                                    variant="body1">
                                    Unité enregistrées
                                </Typography>
                                <Typography variant="h3" className={classes.number} gutterBottom>
                                    {data.number ?? 0}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Avatar className={classes.avatar}>
                                    <BuildIcon className={classes.icon} />
                                </Avatar>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                Dernière unité créée <strong>{data.last?.label}</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <div className={classes.footer}>
                    <Button className={classes.labelAction} color="primary">
                        Créer une nouvelle unité
                    </Button>
                </div>
            </Card>
        </CardActionArea>
    );
};

export default TotalAddedCard;
