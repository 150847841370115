import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Typography, CircularProgress, Divider, TextField } from '@material-ui/core';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import WarningIcon from '@material-ui/icons/Warning';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import env from 'common/env';
import { Dialog } from 'components';

/**
 *
 * @param {*} billNumber
 * @param {*} comment
 * @returns
 */
async function billCanceller(billNumber, comment) {
    const formData = new FormData();
    formData.append('comment', comment);

    const result = await fetch(`${env}/regie/cancel-bill/${billNumber}`, {
        method: 'POST',
        body: formData
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error(error);
            throw `Echec de l'annulation de la facture N°${billNumber}.`;
        });

    return result;
}

const useStyles = makeStyles(theme => ({
    button: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'darkred'
        }
    }
}));

const BillCanceller = props => {
    const { setBillNumber, billNumber, update } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isCanceling, setIsCanceling] = useState(false);
    const [done, setDone] = useState(false);
    const [message, setMessage] = useState('');
    const [icon, setIcon] = useState(<WarningIcon fontSize={'large'} color="error" />);
    const [hasError, setHasError] = useState(false);
    const [comment, setComment] = useState({
        value: '',
        error: false
    });

    useEffect(() => {
        if (!open) {
            setDone(false);
            setIsCanceling(false);
            setMessage('');
            setIcon(<WarningIcon fontSize={'large'} color="error" />);
            setHasError(false);
        }
    }, [open]);

    useEffect(() => {
        setOpen(Boolean(billNumber));
        setBillNumber(billNumber);

        if (!done) {
            setMessage(`Êtes-vous sûr de vouloir annuler la facture N°${billNumber} ?`);
        }

        if (isCanceling && comment.value.trim() !== '') {
            setIcon(<CircularProgress size={25} />);
            setMessage(`Annulation de la facture N°${billNumber} en cours...`);

            billCanceller(billNumber, comment.value)
                .catch(e => setHasError(e))
                .finally(() => {
                    setDone(true);
                    setIsCanceling(false);
                });
        } else {
            setIsCanceling(false);
            setComment({
                value: comment.value,
                error: 'Veuillez saisir un commentaire'
            });
        }
    }, [billNumber, isCanceling]);

    useEffect(() => {
        if (done && !hasError) {
            setIcon(<CheckCircleOutlineIcon fontSize={'large'} style={{ color: 'green' }} />);
            setMessage(`Facture N°${billNumber} annulée avec succès.`);
            update && update();
        }

        if (hasError) {
            setIcon(<HighlightOffIcon fontSize={'large'} color="error" />);
            setMessage(hasError);
        }
    }, [hasError, done]);

    return (
        <Dialog
            open={open}
            title={'Annulation de facture'}
            onClose={() => setBillNumber(null)}
            action={
                <Button
                    disabled={isCanceling || done}
                    onClick={() => setIsCanceling(true)}
                    color="primary"
                    variant="contained"
                    endIcon={<DeleteForeverIcon />}>
                    Annuler la facture
                </Button>
            }>
            <Grid
                container
                direction="column"
                alignItems="center"
                justify="stretch"
                justifyContent="stretch"
                spacing={2}>
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="center"
                        spacing={2}>
                        <Grid item>{icon}</Grid>
                        <Grid item>
                            <Typography variant="body1">{message}</Typography>
                            {!isCanceling && !done && (
                                <Typography variant="body2">
                                    Cette action est irréversible.
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {!done ? (
                    <Grid item style={{ width: '100%' }}>
                        <TextField
                            label="Commentaire"
                            required
                            disabled={isCanceling}
                            error={Boolean(comment.error)}
                            multiline
                            rows={4}
                            minRows={4}
                            fullWidth
                            variant="outlined"
                            onChange={e => setComment({ value: e.target.value, error: false })}
                            helperText={comment.error ?? null}
                        />
                    </Grid>
                ) : null}
            </Grid>
        </Dialog>
    );
};

export default BillCanceller;
