import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    FormControl,
    FormControlLabel,
    Grid,
    FormLabel,
    FormGroup,
    Checkbox
} from '@material-ui/core';

import { Dialog } from 'components';
import { Label } from '../../../components';

/**
 * Style
 */
const useStyles = makeStyles(theme => ({
    label: {
        width: '100%',
        marginBottom: 10
    },
    typolabel: {
        marginBottom: 7
    },
    drawIcon: {
        position: 'relative',
        top: 5
    }
}));

const FilterForm = props => {
    const classes = useStyles();
    const { close, open, placesState, activities, merchantType, sectors } = props;

    return (
        <Dialog open={open} onClose={close} title={"Filtrer l'affichage des places"}>
            <Grid container spacing={4} direction="column">
                <Grid item>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component={Label} className={classes.label}>
                            Type de commerçant
                        </FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox name="t" />}
                                onChange={() => merchantType.set('t')}
                                checked={Boolean(merchantType.get.find(v => v === 't'))}
                                label="Titulaire"
                            />
                            <FormControlLabel
                                control={<Checkbox name="p" />}
                                onChange={() => merchantType.set('p')}
                                checked={Boolean(merchantType.get.find(v => v === 'p'))}
                                label="Passager"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component={Label} className={classes.label}>
                            Etat de la place
                        </FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox name="free" />}
                                onChange={() => placesState.set('free')}
                                checked={Boolean(placesState.get.find(v => v === 'free'))}
                                label="Disponible"
                            />
                            <FormControlLabel
                                control={<Checkbox name="occupied" />}
                                onChange={() => placesState.set('occupied')}
                                checked={Boolean(placesState.get.find(v => v === 'occupied'))}
                                label="Occupée"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component={Label} className={classes.label}>
                            Type d'activité
                        </FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox name="alimentaire" />}
                                onChange={() => activities.set(1)}
                                checked={Boolean(activities.get.find(v => v === 1))}
                                label="Alimentaire"
                            />
                            <FormControlLabel
                                control={<Checkbox name="primeur" />}
                                onChange={() => activities.set(2)}
                                checked={Boolean(activities.get.find(v => v === 2))}
                                label="Primeur"
                            />
                            <FormControlLabel
                                control={<Checkbox name="manu" />}
                                onChange={() => activities.set(3)}
                                checked={Boolean(activities.get.find(v => v === 3))}
                                label="Manufacturé"
                            />
                            <FormControlLabel
                                control={<Checkbox name="demo" />}
                                onChange={() => activities.set(4)}
                                checked={Boolean(activities.get.find(v => v === 4))}
                                label="Démonstrateur/Posticheur"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                {sectors.availables.length > 1 && (
                    <Grid item>
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component={Label} className={classes.label}>
                                Secteurs
                            </FormLabel>
                            <FormGroup row>
                                {sectors.availables.map(sector => (
                                    <FormControlLabel
                                        control={<Checkbox name="sector" />}
                                        onChange={() => sectors.set(sector)}
                                        checked={Boolean(sectors.get.find(v => v === sector))}
                                        label={`Secteur ${sector}`}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
        </Dialog>
    );
};

export default FilterForm;
