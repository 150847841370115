import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    CardActionArea,
    Grid,
    Typography
} from '@material-ui/core';
import FaceTwoToneIcon from '@material-ui/icons/FaceTwoTone';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    card: {
        width: "100%",
        padding: theme.spacing(2),
    },
    raisonSociale: {
        display: "inline-block",
        width: "100%",
        textAlign: "center"
    },
    icon: {
        height: 80,
        width: "100%"
    },
    metier: {
        width: "100%",
        textAlign: "center",
    },
}));

const activities = {
    1: {
        id: 1,
        key: "alim",
        label: "Alimentaire",
        color: "gold"
    },
    2: {
        id: 2,
        key: "prim",
        label: "Primeur",
        color: "#32CD32	"
    },
    3: {
        id: 3,
        key: "manu",
        label: "Manufacturé",
        color: "#87CEFA"
    },
    4: {
        id: 4,
        key: "dempos",
        label: "Démonstrateur/Posticheur",
        color: "#BD33A4"
    }
}

const MerchantButton = props => {
    const { className, data, ...rest } = props;

    const classes = useStyles();

    return (
        <Grid item lg={3} sm={3} xl={3} xs={6}>
            <CardActionArea component={RouterLink} to={`/merchants/get/${data.siret}/profil`} key={data.siret} className={classes.card}>
                <FaceTwoToneIcon style={{ color: `${(data.bloque == "1" ? "red" : activities[data.activite_id].color)}` }} className={classes.icon} />
                <Typography
                    className={classes.raisonSociale}
                    variant="button"
                    color="primary"
                >
                    {data.raisonSociale}
                </Typography>
                <Typography
                    className={classes.metier}
                    variant="subtitle2"
                >
                    {data.metier}
                </Typography>
            </CardActionArea>
        </Grid>
    );
};

export default MerchantButton;