import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

const FormDraw = props => {
    const {
        activities: { all, selected, update }
    } = props;

    const isSelected = val => selected.find(item => item === val);

    return (
        <Grid container direction="row" justifyContent="flex-start" spacing={1}>
            <Grid item key={0}>
                <Button variant="outlined" color="primary" key={0} onClick={() => update(0)}>
                    Tous
                </Button>
            </Grid>
            {all.map(row => (
                <Grid item key={row.id}>
                    <Button
                        disabled={Boolean(row.launched === 'y')}
                        color="primary"
                        key={row.id}
                        variant={isSelected(row.id) ? 'contained' : 'outlined'}
                        onClick={() => update(row.id)}>
                        {row.label} ({row._count})
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
};

FormDraw.propTypes = {};

export default FormDraw;
