import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { isMobile } from 'react-device-detect';

import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import {
    AppBar,
    Toolbar,
    Button,
    Hidden,
    IconButton,
    InputBase,
    InputAdornment,
    Divider,
    Grid,
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Badge
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import EmailIcon from '@material-ui/icons/Email';
import InputIcon from '@material-ui/icons/Input';
import SearchIcon from '@material-ui/icons/Search';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import ExploreIcon from '@material-ui/icons/Explore';
import RoomIcon from '@material-ui/icons/Room';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import UserProfile from '../../../../UserProfile';
import JournalMarche from '../../../../JournalMarche';

import { Profile } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: '14px -7px 7px 7px rgba(0, 0, 0, 0.2)',
        backgroundColor: 'white',
        '& button': isMobile
            ? {
                  padding: 0
              }
            : {}
    },
    flexGrow: {
        flexGrow: 1,
        width: '100%'
    },
    signOutButton: {
        //marginLeft: theme.spacing(1),
        color: '#43425D'
    },
    messageButton: {
        color: '#BCBCCB'
    },
    searchButton: {
        color: '#BCBCCB'
    },
    divider: {
        margin: theme.spacing(0, 2),
        border: '#BCBCCB',
        height: 25
    },
    labelMarket: {
        color: theme.palette.primary.main
    }
}));

function HideOnScroll(props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const Topbar = props => {
    const { className, onSidebarOpen, ...rest } = props;

    const classes = useStyles();

    const [notifications] = useState([]);
    const [expanded, setExpanded] = React.useState(false);

    const getMoreOptions = () => {
        setExpanded(!expanded);
    };

    let content = <div></div>;

    if (UserProfile.getProfile().toLowerCase() == 'placier') {
        content = (
            <React.Fragment>
                <Toolbar>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item>
                            <Hidden lgUp>
                                <IconButton color="primary" onClick={onSidebarOpen}>
                                    <MenuIcon />
                                </IconButton>
                            </Hidden>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" className={classes.labelMarket}>
                                {JournalMarche.getId() != 0 &&
                                    /*JournalMarche.getLabel() + ", " + */ JournalMarche.getDate()}
                            </Typography>
                        </Grid>
                        {JournalMarche.getId() > 0 && (
                            <Grid item>
                                <Grid container>
                                    <Grid item>
                                        <IconButton component={RouterLink} to="/logout">
                                            <PowerSettingsNewIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {JournalMarche.getId() == 0 && (
                            <Grid item>
                                <IconButton component={RouterLink} to="/logout">
                                    <PowerSettingsNewIcon style={{ color: 'red' }} />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Toolbar>
            </React.Fragment>
        );
    } else if (
        UserProfile.getProfile().toLowerCase() == 'administrateur' ||
        UserProfile.getProfile().toLowerCase() == 'régisseur'
    ) {
        content = (
            <React.Fragment>
                <Toolbar>
                    <div className={classes.flexGrow}>
                        <InputBase
                            style={{ visibility: 'hidden' }}
                            inputProps={{ 'aria-label': 'naked' }}
                            placeholder="Rechercher un commerçant, une date ou une journée de marché..."
                            type="search"
                            fullWidth
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconButton aria-label="search something">
                                        <SearchIcon className={classes.searchButton} />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </div>

                    {/*
            <IconButton>
              <Badge badgeContent={4} color="error">
                <EmailIcon className={classes.messageButton} />
              </Badge>
            </IconButton>
            */}
                    <Divider orientation="vertical" className={classes.divider} />
                    <Profile />

                    <Hidden lgUp>
                        <IconButton color="primary" onClick={onSidebarOpen}>
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                </Toolbar>
            </React.Fragment>
        );
    }

    return (
        <AppBar {...rest} position="relative" className={clsx(classes.root, className)}>
            {content}
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func
};

export default Topbar;
