import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Grid, Paper, Divider, Typography, IconButton, Link } from '@material-ui/core';

import { Form } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    }
});

class Creation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            form: null,
            listOfMerchant: [],
            listOfUnity: []
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        this.state.isLoading && this.sendData();
    };

    getData = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/domaine/get-domain-bill-for-creation`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    prepareData = form => {
        if (form.ref == '') {
            alert('Veuillez saisir une référence.');
            return;
        }

        if (form.enseigne == '') {
            alert('Veuillez saisir une enseigne.');
            return;
        }

        if (!form.merchant) {
            alert('Veuillez séléctionner un commerçant.');
            return;
        }

        if (form.activitie == '') {
            alert('Veuillez saisir une activité.');
            return;
        }

        if (form.adresse == '') {
            alert('Veuillez saisir une adresse.');
            return;
        }

        if (form.tarif == '') {
            alert('Veuillez saisir un prix unitaire.');
            return;
        } else {
            if (parseFloat(form.tarif) == 0) {
                alert('Veuillez saisir un prix unitaire supérieur à 0€.');
                return;
            }
        }

        if (!form.unity) {
            alert('Veuillez séléctionner une unité.');
            return;
        }

        if (form.qte == '') {
            alert('Veuillez saisir une tarif.');
            return;
        } else {
            if (parseInt(form.qte) == 0) {
                alert('Veuillez saisir une quantité supérieure à 0.');
            }
        }

        if (form.start == '' && form.end == '') {
            const continueToCreate = window.confirm(
                "Aucune date d'autorisation n'a été saisie, continuer la création ?"
            );
            if (!continueToCreate) {
                return;
            }
        } else {
            if (form.start == '') {
                const continueToCreate = window.confirm(
                    "Aucune date de début d'autorisation n'a été saisie, continuer la création ?"
                );
                if (!continueToCreate) {
                    return;
                }
            }

            if (form.end == '') {
                const continueToCreate = window.confirm(
                    "Aucune date de fin d'autorisation n'a été saisie, continuer la création ?"
                );
                if (!continueToCreate) {
                    return;
                }
            }
        }

        this.setState({
            form: form,
            isLoading: true
        });
    };

    sendData = () => {
        const { form } = this.state;

        const formData = new FormData();
        formData.append('enseigne', form.enseigne);
        formData.append('ref', form.ref);
        formData.append('marchand_id', form.merchant.id);
        formData.append('unity_id', form.unity.id);
        form.start != '' && formData.append('start', form.start);
        form.end != '' && formData.append('end', form.end);
        formData.append('activite', form.activitie);
        formData.append('adresse', form.adresse);
        formData.append('tarif', form.tarif);
        formData.append('qte', form.qte);
        formData.append('total', form.total);
        form.info && formData.append('info', form.info);
        form.photo1 && formData.append('photo1', form.photo1);
        form.photo2 && formData.append('photo2', form.photo2);
        form.photo3 && formData.append('photo3', form.photo3);
        form.aot && formData.append('aot', form.aot);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/domaine/itinerant-shop/create`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    isLoading: false
                });
                alert(`Le commerce "${form.enseigne}" a été créé avec succès.`);
                this.props.history.push('/itinerant-shop/all');
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la création');
                    this.setState({
                        isLoading: false
                    });
                } // Handle the error response object
            );
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { classes, listOfMerchant, listOfUnity, isLoading } = this.state;

        return (
            <Paper className={classes.root}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container direction="row" alignItems="baseline">
                            <Grid item>
                                <IconButton onClick={this.getBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <Link component={RouterLink} to="/itinerant-shop/all">
                                        Les commerces ambulants
                                    </Link>{' '}
                                    / Créer un commerce ambulant
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item style={{ marginTop: 20 }}>
                        <Form
                            isLoading={isLoading}
                            listOfMerchant={listOfMerchant}
                            listOfUnity={listOfUnity}
                            sendData={this.prepareData}
                        />
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(Creation);
