import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    Grid,
    Paper,
    FormControlLabel,
    Checkbox,
    Typography,
    Divider,
    IconButton,
    Slide,
    CircularProgress
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const styles = theme => ({
    root: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: 250,
        borderTopRightRadius: 4,
        zIndex: 999,
        maxHeight: 'calc(100vh - 205px)'
    },
    buttonRoot: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: 250,
        borderTopRightRadius: 4,
        zIndex: 998
    },
    content: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    toolbox: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 252px)'
    },
    header: {
        paddingTop: 8,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    slideUp: {},
    slideDown: {}
});

class Statistic extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            open: true,
            filters: [],
            availableFilters: [],
            filtersHasChanged: false,
            numbers: {
                availableMatch: 0,
                match: 0,
                all: 0,
                susbscribers: 0,
                noneSusbscribers: 0,
                passengers: 0,
                freePlaces: 0,
                unfreePlaces: 0,
                alim: 0,
                prim: 0,
                manu: 0,
                dem: 0,
                placesWithWater: 0,
                placesWithElectricity: 0,
                placesWithCar: 0,
                placesWithNoOptions: 0,
                paid: 0,
                unpaid: 0,
                cash: 0,
                cb: 0,
                phone: 0,
                check: 0,
                withdrawal: 0
            }
        };
    }

    componentDidMount = () => {
        this.getFilter();
        this.getCounter();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (
            this.state.filtersHasChanged &&
            prevState.filtersHasChanged != this.state.filtersHasChanged
        ) {
            this.getFilter();
            //this.getCounter();
        }

        if (
            this.props.listOfPlacesWasRebuild &&
            prevProps.listOfPlacesWasRebuild != this.props.listOfPlacesWasRebuild &&
            this.props.counterHaveToUpdate
        ) {
            this.getCounter();
        }
    };

    getCounter = () => {
        const { filters } = this.state;

        const { places, meansOfPayment } = this.props;

        const formData = new FormData();
        formData.append('filters', JSON.stringify(filters));
        formData.append('places', JSON.stringify(places));
        formData.append('meansOfPayment', JSON.stringify(meansOfPayment));

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/get-counter-places-by-for-dashboard`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    filtersHasChanged: true
                });

                this.props.updateField('listOfPlacesWasRebuild', false);
                this.props.updateField('counterHaveToUpdate', false);
            })
            .catch(
                error => {
                    console.error(error);
                    this.setState({
                        filtersHasChanged: false
                    });
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    getFilter = () => {
        const { filters } = this.state;

        const { places, meansOfPayment } = this.props;

        const formData = new FormData();
        formData.append('filters', JSON.stringify(filters));
        formData.append('places', JSON.stringify(places));
        formData.append('meansOfPayment', JSON.stringify(meansOfPayment));

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/get-filtered-places-by-for-dashboard`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.props.updateField('placesToShow', data.placesToShow);
                this.props.updateField('filtersHasChanged', true);

                this.setState(prevState => ({
                    filtersHasChanged: false,
                    numbers: {
                        ...prevState.numbers,
                        match: data.match
                    }
                }));
            })
            .catch(
                error => {
                    console.error(error);
                    this.setState({
                        filtersHasChanged: false
                    });
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleOpen = () => {
        this.setState(prevState => ({
            open: !prevState.open
        }));
    };

    handleFilters = id => {
        const { filters } = this.state;

        const index = filters.indexOf(id);

        if (index > -1) {
            filters.splice(index, 1);
        } else {
            filters.push(id);
        }

        this.setState({
            filters: filters,
            filtersHasChanged: true
        });
    };

    handleSelectAllFilter = ({ target: { checked } }) => {
        const { availableFilters } = this.state;

        const filters = [];

        availableFilters.map(filter => {
            filters.push(filter);
        });

        this.setState({
            filters: checked ? filters : [],
            filtersHasChanged: true
        });
    };

    render() {
        const { classes, open, filters, numbers, filtersHasChanged } = this.state;

        const { activities, meansOfPayment } = this.props;

        return (
            <div>
                <Slide direction="up" in={open} mountOnEnter unmountOnExit>
                    <Paper className={classes.root} square>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Grid
                                    container
                                    className={classes.header}
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center">
                                    <Grid item>
                                        <Typography variant="body2" align="center">
                                            Affichage des places & compteurs
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {filtersHasChanged ? (
                                            <CircularProgress
                                                size={22}
                                                style={{ margin: 2, marginTop: 1 }}
                                            />
                                        ) : (
                                            <IconButton size="small" onClick={this.handleOpen}>
                                                <ExpandMoreIcon size="small" />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item className={classes.toolbox}>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                        className={classes.content}>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        disabled={Boolean(numbers.all == 0)}
                                                        checked={Boolean(
                                                            filters.length ==
                                                                numbers.availableMatch &&
                                                                numbers.availableMatch > 0
                                                        )}
                                                        indeterminate={Boolean(
                                                            filters.length <
                                                                numbers.availableMatch &&
                                                                filters.length > 0
                                                        )}
                                                        onChange={this.handleSelectAllFilter}
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body2">Tous</Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                {numbers.match}/{numbers.all}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="column"
                                                className={classes.content}>
                                                {activities.map((activitie, index) => {
                                                    let key = 'dem';
                                                    const propsType = {};

                                                    if (
                                                        activitie.label.toLowerCase() ==
                                                        'alimentaire'
                                                    ) {
                                                        key = 'alim';
                                                    } else if (
                                                        activitie.label.toLowerCase() == 'primeur'
                                                    ) {
                                                        key = 'prim';
                                                    } else if (
                                                        activitie.label.toLowerCase() ==
                                                        'manufacturé'
                                                    ) {
                                                        key = 'manu';
                                                    }

                                                    Boolean(numbers[key] > 0) &&
                                                        (propsType.style = {
                                                            color: activitie.color
                                                        });

                                                    return (
                                                        <Grid item key={activitie.id}>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justify="space-between"
                                                                alignItems="center">
                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                key={activitie.id}
                                                                                {...propsType}
                                                                                disabled={Boolean(
                                                                                    numbers[key] ==
                                                                                        0
                                                                                )}
                                                                                checked={Boolean(
                                                                                    filters.indexOf(
                                                                                        activitie.id
                                                                                    ) > -1
                                                                                )}
                                                                                onChange={() =>
                                                                                    this.handleFilters(
                                                                                        activitie.id
                                                                                    )
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            <Typography variant="body2">
                                                                                {activitie.label}
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography
                                                                        variant="body2"
                                                                        key={
                                                                            activitie.id +
                                                                            'compteur'
                                                                        }>
                                                                        {numbers[key]}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="column"
                                                className={classes.content}>
                                                <Grid item key="abo">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="space-between"
                                                        alignItems="center">
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        key="abo"
                                                                        disabled={Boolean(
                                                                            numbers.susbscribers ==
                                                                                0
                                                                        )}
                                                                        checked={Boolean(
                                                                            filters.indexOf(
                                                                                'isSubscriber'
                                                                            ) > -1
                                                                        )}
                                                                        onChange={() =>
                                                                            this.handleFilters(
                                                                                'isSubscriber'
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography variant="body2">
                                                                        Abonné
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                variant="body2"
                                                                key="abocompteur">
                                                                {numbers.susbscribers}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item key="noneabo">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="space-between"
                                                        alignItems="center">
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        key="noneabo"
                                                                        disabled={Boolean(
                                                                            numbers.noneSusbscribers ==
                                                                                0
                                                                        )}
                                                                        checked={Boolean(
                                                                            filters.indexOf(
                                                                                'isNoneSubscriber'
                                                                            ) > -1
                                                                        )}
                                                                        onChange={() =>
                                                                            this.handleFilters(
                                                                                'isNoneSubscriber'
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography variant="body2">
                                                                        Non abonné
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                variant="body2"
                                                                key="noneabocompteur">
                                                                {numbers.noneSusbscribers}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item key="pass">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="space-between"
                                                        alignItems="center">
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        key="passenger"
                                                                        disabled={Boolean(
                                                                            numbers.passengers == 0
                                                                        )}
                                                                        checked={Boolean(
                                                                            filters.indexOf(
                                                                                'isPassenger'
                                                                            ) > -1
                                                                        )}
                                                                        onChange={() =>
                                                                            this.handleFilters(
                                                                                'isPassenger'
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography variant="body2">
                                                                        Passager
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                variant="body2"
                                                                key="passcompteur">
                                                                {numbers.passengers}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="column"
                                                className={classes.content}>
                                                <Grid item key="occu">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="space-between"
                                                        alignItems="center">
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        key="occu"
                                                                        disabled={Boolean(
                                                                            numbers.unfreePlaces ==
                                                                                0
                                                                        )}
                                                                        checked={Boolean(
                                                                            filters.indexOf(
                                                                                'isNotFree'
                                                                            ) > -1
                                                                        )}
                                                                        onChange={() =>
                                                                            this.handleFilters(
                                                                                'isNotFree'
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography variant="body2">
                                                                        Occupée
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                variant="body2"
                                                                key="occucompteur">
                                                                {numbers.unfreePlaces}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item key="dispo">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="space-between"
                                                        alignItems="center">
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        key="dispo"
                                                                        disabled={Boolean(
                                                                            numbers.freePlaces == 0
                                                                        )}
                                                                        checked={Boolean(
                                                                            filters.indexOf(
                                                                                'isFree'
                                                                            ) > -1
                                                                        )}
                                                                        onChange={() =>
                                                                            this.handleFilters(
                                                                                'isFree'
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography variant="body2">
                                                                        Disponible
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                variant="body2"
                                                                key="dispocompteur">
                                                                {numbers.freePlaces}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="column"
                                                className={classes.content}>
                                                <Grid item key="paid">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="space-between"
                                                        alignItems="center">
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        key="paid"
                                                                        style={{ color: 'green' }}
                                                                        disabled={Boolean(
                                                                            numbers.paid == 0
                                                                        )}
                                                                        checked={Boolean(
                                                                            filters.indexOf(
                                                                                'paid'
                                                                            ) > -1
                                                                        )}
                                                                        onChange={() =>
                                                                            this.handleFilters(
                                                                                'paid'
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography variant="body2">
                                                                        Réglé
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                variant="body2"
                                                                key="paidcompteur">
                                                                {numbers.paid}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item key="unpaid">
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justify="space-between"
                                                        alignItems="center">
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        size="small"
                                                                        key="unpaid"
                                                                        style={{ color: 'red' }}
                                                                        disabled={Boolean(
                                                                            numbers.unpaid == 0
                                                                        )}
                                                                        checked={Boolean(
                                                                            filters.indexOf(
                                                                                'unpaid'
                                                                            ) > -1
                                                                        )}
                                                                        onChange={() =>
                                                                            this.handleFilters(
                                                                                'unpaid'
                                                                            )
                                                                        }
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography variant="body2">
                                                                        Non réglé
                                                                    </Typography>
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                variant="body2"
                                                                key="unpaidcompteur">
                                                                {numbers.unpaid}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="column"
                                                className={classes.content}>
                                                {meansOfPayment.map(payment => {
                                                    return (
                                                        <Grid item key={payment.label}>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justify="space-between"
                                                                alignItems="center">
                                                                <Grid item>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                key={payment.alias}
                                                                                disabled={Boolean(
                                                                                    numbers[
                                                                                        payment
                                                                                            .alias
                                                                                    ] == 0
                                                                                )}
                                                                                checked={Boolean(
                                                                                    filters.indexOf(
                                                                                        payment.alias
                                                                                    ) > -1
                                                                                )}
                                                                                onChange={() =>
                                                                                    this.handleFilters(
                                                                                        payment.alias
                                                                                    )
                                                                                }
                                                                            />
                                                                        }
                                                                        label={
                                                                            <Typography variant="body2">
                                                                                {payment.label}
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography
                                                                        variant="body2"
                                                                        key="sanscompteur">
                                                                        {numbers[payment.alias]}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Slide>
                {!open && (
                    <Paper className={classes.buttonRoot} square>
                        <Grid
                            container
                            className={classes.header}
                            direction="row"
                            justify="space-between"
                            alignItems="center">
                            <Grid item>
                                <Typography variant="body2" align="center">
                                    Affichage des places & compteurs
                                </Typography>
                            </Grid>
                            <Grid item>
                                {filtersHasChanged ? (
                                    <CircularProgress
                                        size={22}
                                        style={{ margin: 2, marginTop: 1 }}
                                    />
                                ) : (
                                    <IconButton size="small" onClick={this.handleOpen}>
                                        <ExpandLessIcon size="small" />
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(Statistic);
