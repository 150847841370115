
import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
  Admin,
  Placier,
  Regisseur
} from './components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(4),
    flexGrow: 1
  },
})


class Home extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      ...props,
    }
  }

  render() {
    const { classes } = this.state;

    let content = <div></div>;

    if (this.props.userProfile.getRoleId() == 2) {
      content = <Placier />
    } else if (this.props.userProfile.getRoleId() == 1) {
      content = <Admin />
    } else if (this.props.userProfile.getRoleId() == 3) {
      content = <Regisseur />
    }

    return (
      <div className={classes.root} >
        {content}
      </div>
    )

  }


}


export default withStyles(styles)(Home);
