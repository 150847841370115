import React from 'react';

import { makeStyles } from '@material-ui/styles';
import {
    TextField,
    InputAdornment,
    Button
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles(theme => ({
    input: {
        display: "none"
    },
}));

const FileInput = props => {
    const { className, file, setFile, wasModified, name, ...rest } = props;

    const classes = useStyles();
    const ref = React.createRef();

    const addFile = () => {
        ref.current.click();
    }

    const handleFile = ({ target: { files } }) => {
        setFile(name, files[0])
    }


    return (

        <div>
            <TextField
                type="text"
                size="small"
                fullWidth
                value={(file ? file.name : "")}
                variant="outlined"
                InputProps={{
                    readOnly: true,
                    startAdornment:
                        <InputAdornment position="start" size="small">
                            <Button size="small" onClick={addFile}>
                                Ajouter <AttachFileIcon />
                            </Button>
                        </InputAdornment>,
                    /*endAdornment:
                        <InputAdornment position="end" size="small">
                            <Button size="small" disabled={!Boolean(file && wasModified)} onClick={removeFile}>
                                Supprimer <DeleteIcon />
                            </Button>
                        </InputAdornment>,*/
                }}
            />
            <input type="file" onChange={handleFile} className={classes.input} ref={ref} />
        </div>
    );
};

export default FileInput;
