import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
    Paper,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TableHead,
    Typography,
    ListItemText
} from '@material-ui/core';

import { Pagination } from './components';

import ClearIcon from '@material-ui/icons/Clear';

const columns = [
    {
        id: 'label',
        label: 'Libellé',
        minWidth: '70%',
        align: 'left'
    },
    {
        id: 'type',
        label: 'Typologie',
        minWidth: '30%',
        align: 'left'
    }
];

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

class All extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            searchValue: '',
            page: 1,
            numberOfPage: 0,
            list: []
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        (this.state.searchValue != prevState.searchValue || this.state.page != prevState.page) &&
            this.getData();
    };

    getData = () => {
        const { page, searchValue } = this.state;

        const formData = new FormData();
        formData.append('search', searchValue);
        formData.append('page', page);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/domaine/other-sales/get-by`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    render() {
        const { classes, searchValue, page, numberOfPages, list } = this.state;

        return (
            <Paper className={classes.paperContent}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                    spacing={3}>
                    <Grid item>
                        <TextField
                            label={'Rechercher un produit'}
                            type="text"
                            fullWidth
                            value={searchValue}
                            onChange={this.handleSearch}
                            variant="outlined"
                            helperText="Saisir un libellé"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={this.clearSearch} edge="end">
                                            <ClearIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map(column => (
                                            <StyledTableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list.map((data, index) => {
                                        return (
                                            <StyledTableRow hover key={index}>
                                                {columns.map(column => {
                                                    if (column.id == 'label') {
                                                        return (
                                                            <TableCell
                                                                key={data.id + data[column.id]}
                                                                align={column.align}>
                                                                <ListItemText
                                                                    primary={data.label}
                                                                    secondary={
                                                                        <Typography variant="body2">
                                                                            {data.commentaire}
                                                                        </Typography>
                                                                    }
                                                                />
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (column.id == 'type') {
                                                        return (
                                                            <TableCell
                                                                key={data.id + data[column.id]}
                                                                align={column.align}>
                                                                {data.type == 'p'
                                                                    ? 'Produit'
                                                                    : 'Prestation de service'}
                                                            </TableCell>
                                                        );
                                                    }

                                                    return (
                                                        <TableCell
                                                            key={data.id + data[column.id]}
                                                            align={column.align}>
                                                            {data[column.id]}
                                                        </TableCell>
                                                    );
                                                })}
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item style={{ marginTop: 35 }}>
                        <Pagination
                            numberOfPages={numberOfPages}
                            currentPage={page}
                            handlePagination={this.handlePagination}
                        />
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(All);
