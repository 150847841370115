import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import { Grid, Button } from '@material-ui/core';

import {
    TotalAdded,
    TotalCessation,
    TotalAlert,
    TotalAlertAot,
    TotalAlertAssurance,
    All,
    Merchant,
    Cessation,
    Creation,
    Alert,
    AlertAssurance,
    AlertAot,
    Email
} from './components';

import TabControl from './TabControl';
import MailIcon from '@material-ui/icons/Mail';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paperContent: {
        padding: theme.spacing(4)
        //padding: 10
    },
    item: {
        minWidth: '30%'
    }
});

class DomainMerchants extends React.Component {
    constructor(props) {
        super(props);

        const { cat, siret, tab } = this.props.match.params;

        this.state = {
            ...props,
            cat: cat,
            siret: siret,
            tab: tab,
            totalAdded: {
                number: 0,
                last: {
                    raisonSociale: '',
                    date: ''
                }
            },
            totalCessation: {
                number: 0,
                last: {
                    raisonSociale: '',
                    date: ''
                }
            },
            totalAlert: {
                number: 0,
                passager: 0,
                titulaire: 0
            },
            totalAssurance: {
                number: 0,
                passager: 0,
                titulaire: 0
            },
            totalAot: {
                number: 0,
                titulaire: 0
            }
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate(prevProps, prevState) {
        const { cat, siret, tab } = this.props.match.params;

        if (prevState.cat != cat || prevState.tab != tab || prevState.siret != siret) {
            this.setState({
                cat: cat,
                siret: siret,
                tab: tab
            });
            this.getData();
        }
    }

    getData = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/home-page?domaine=1`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    render() {
        const {
            classes,
            totalAdded,
            totalAlert,
            totalCessation,
            totalAssurance,
            totalAot,
            cat
        } = this.state;

        return (
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid
                        container
                        item
                        justify="flex-start"
                        spacing={3}
                        alignItems="stretch"
                        wrap="wrap">
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                component={RouterLink}
                                to={'/domain-merchants/email'}>
                                <MailIcon style={{ marginRight: 7 }} /> Contacter les commerçants
                            </Button>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <TotalAdded cat={cat} data={totalAdded} />
                        </Grid>
                        {totalCessation.number > 0 && (
                            <Grid item md={6} sm={12} xs={12}>
                                <TotalCessation cat={cat} data={totalCessation} />
                            </Grid>
                        )}
                        {totalAssurance.number > 0 && (
                            <Grid item md={4} sm={12} xs={12}>
                                <TotalAlertAssurance cat={cat} data={totalAssurance} />
                            </Grid>
                        )}
                        {totalAot.number > 0 && (
                            <Grid item md={4} sm={12} xs={12}>
                                <TotalAlertAot cat={cat} data={totalAot} />
                            </Grid>
                        )}
                        {totalAlert.number > 0 && (
                            <Grid item md={4} sm={12} xs={12}>
                                <TotalAlert cat={cat} data={totalAlert} />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <TabControl value={cat} index={'all'}>
                            <All />
                        </TabControl>
                        <TabControl value={cat} index={'creation'}>
                            <Creation history={this.props.history} />
                        </TabControl>
                        <TabControl value={cat} index={'cessation'}>
                            <Cessation history={this.props.history} />
                        </TabControl>
                        <TabControl value={cat} index={'alert-data'}>
                            <Alert updateCompteur={this.getData} history={this.props.history} />
                        </TabControl>
                        <TabControl value={cat} index={'alert-data-aot'}>
                            <AlertAot updateCompteur={this.getData} history={this.props.history} />
                        </TabControl>
                        <TabControl value={cat} index={'alert-data-assurance'}>
                            <AlertAssurance
                                updateCompteur={this.getData}
                                history={this.props.history}
                            />
                        </TabControl>
                        <TabControl value={cat} index={'get'}>
                            <Merchant
                                updateCompteur={this.getData}
                                params={this.props.match.params}
                                history={this.props.history}
                            />
                        </TabControl>
                        <TabControl value={cat} index={'email'}>
                            <Email history={this.props.history} open={Boolean(cat === 'email')} />
                        </TabControl>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(DomainMerchants);
