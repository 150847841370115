// @flow

//import L from 'leaflet';
import React from 'react';
import { withStyles } from '@material-ui/styles';
import { withLeaflet } from 'react-leaflet';
import transformRotate from '@turf/transform-rotate';
import turf from 'turf';
import JournalMarche from '../../../../JournalMarche';
import FaceTwoToneIcon from '@material-ui/icons/FaceTwoTone';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { Paper, Grid, Typography, Button, ButtonGroup } from '@material-ui/core';

import MarketPlace from './MarketPlace';

//const layersGroup = L.featureGroup();

const DEFAULT_STATE = {
    sliderRotation: 0,
    inputNumero: '',
    inputMeters: '',
    activitieKey: '',
    merchantSiret: '_0',
    x: 0,
    y: 0,
    //information: "",
    information: false,
    layers: {
        selectedLayer: null,
        flyOverLayer: null
        //layersGroup: layersGroup
    }
};

const sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
};

const styles = {
    root: {
        padding: 10,
        position: 'absolute'
    },
    paper: {
        padding: 14,
        position: 'absolute',
        borderRadius: 4,
        //boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.4)",
        //border: "1px solid rgba(0, 0, 0, 0.6)",
        minWidth: 180
    },
    finance: {
        display: 'inline-flex',
        paddingTop: 7
    }
};

class Control extends React.Component {
    activities = {
        alim: {
            id: 1,
            key: 'alim',
            label: 'Alimentaire',
            color: 'gold'
        },
        prim: {
            id: 2,
            key: 'prim',
            label: 'Primeur',
            color: '#32CD32	'
        },
        dempos: {
            id: 4,
            key: 'dempos',
            label: 'Démonstrateur/Posticheur',
            color: '#BD33A4'
        },
        manu: {
            id: 3,
            key: 'manu',
            label: 'Manufacturé',
            color: '#87CEFA'
        }
    };

    merchants = {
        _0: {
            siret: '0',
            raisonSociale: 'Non affecté',
            metier: 'Aucun',
            numero: false
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            ...props,
            ...DEFAULT_STATE,
            layers: {
                ...DEFAULT_STATE.layers,
                layersGroup: this.props.layersGroup
            },
            marchand: false,
            tabValue: 0,
            listOfPlaces: []
        };

        let { layersGroup } = this.state.layers;
        let { map } = this.props.leaflet;

        layersGroup.addEventListener('click', this._onClick);
        //map.addEventListener("clickAway", this._reset);
        //layersGroup.addEventListener("mouseover", this._onMouseOver);
        //layersGroup.addEventListener("mouseout", this._onMouseOut);

        layersGroup.addTo(this.props.leaflet.map);

        this.loadData();
    }

    _reset = e => {
        this.setState({
            information: false
        });
    };

    _onClick = e => {
        // Fly over layer
        let layer = e.layer;
        let mouse = e.containerPoint;

        let jmid = this.props.jmid;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/${jmid}/get/place/admin/${layer.getConfig(
                'journalplace_id'
            )}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                this.setState({
                    marchand: { ...data },
                    information: true,
                    x: mouse.x,
                    y: mouse.y
                });
            })
            .catch(console.log);
    };

    _onMouseOut = e => {
        // Fly out layer
        let layer = e.layer;
    };

    getActivite = id => {
        let myact = '';
        Object.keys(this.activities).map(key => {
            if (this.activities[key].id == parseInt(id)) {
                myact = this.activities[key];
            }
        });

        return myact;
    };

    loadData = () => {
        let { layersGroup } = this.state.layers;
        let { map } = this.props.leaflet;
        const { jmid } = this.props;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/${jmid}/coords`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data

                map.setView([data[0].a_lat, data[0].a_lng]);

                data.map(layer => {
                    let marketPlace = new MarketPlace();
                    //marketPlace.addEventListener("dragstart", this._onDragStart);
                    //marketPlace.addEventListener("dragend", this._onDragEnd);

                    marketPlace.setActivitie(this.getActivite(layer['activite_id']));

                    let coords = [
                        {
                            lat: layer['a_lat'],
                            lng: layer['a_lng']
                        },
                        {
                            lat: layer['b_lat'],
                            lng: layer['b_lng']
                        },
                        {
                            lat: layer['c_lat'],
                            lng: layer['c_lng']
                        },
                        {
                            lat: layer['d_lat'],
                            lng: layer['d_lng']
                        }
                    ];

                    marketPlace.setLatLngs(coords);
                    marketPlace.setConfig('meters', layer['metres']);

                    marketPlace.setConfig('rotation', layer['rotation']);
                    marketPlace.addTo(layersGroup);
                    //marketPlace.dragging.enable();
                    marketPlace.setNumero(layer['numero']);
                    marketPlace.getConfig('numero').label.addTo(map);
                    marketPlace.setConfig('journalplace_id', layer['journalplace_id']);

                    let marchand = {
                        raisonSociale: layer['raisonSociale'],
                        siret: layer['marchand_id']
                    };

                    if (layer['marchand_id'] == '1') {
                        marchand = this.merchants['_0'];
                    }

                    marketPlace.setMerchant(/*this.merchants["_0"]*/ marchand);
                });
            })
            .catch(console.log);
    };

    zoomControl = fnc => {
        let { map } = this.props.leaflet;

        fnc == 'in' ? map.zoomIn() : map.zoomOut();
    };

    render() {
        const { information, classes, x, y, marchand } = this.state;

        return (
            <div className={classes.root}>
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    spacing={2}
                    style={{
                        zIndex: 1000,
                        position: 'relative',
                        margin: 20
                    }}>
                    <Grid item>
                        <ButtonGroup orientation="vertical" variant="contained">
                            <Button size="small" onClick={() => this.zoomControl('in')}>
                                <AddIcon />
                            </Button>
                            <Button size="small" onClick={() => this.zoomControl('out')}>
                                <RemoveIcon />
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item>
                        {/*style={{ left: x, top: y }}*/
                        information && (
                            <Paper className={classes.paper} elevation={2}>
                                <Grid
                                    container
                                    direction="column"
                                    justify="flex-start"
                                    alignItems="stretch"
                                    spacing={1}>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center">
                                        <Grid item xs={3}>
                                            <FaceTwoToneIcon className={classes.icon} />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Grid
                                                container
                                                direction="column"
                                                justify="flex-start"
                                                alignItems="stretch">
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        {marchand.raisonSociale}
                                                    </Typography>
                                                </Grid>
                                                {marchand.marchand_id != '1' && (
                                                    <Grid item>
                                                        <Typography
                                                            variant="body2"
                                                            style={{ fontSize: 10 }}>
                                                            {marchand.libelleMetier}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center">
                                        <Grid item>
                                            <Typography variant="body2">Numéro</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                {marchand.numero}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {marchand.marchand_id != '1' && (
                                        <React.Fragment>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="center">
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        Mètres linéaires
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        {marchand.metres}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="center">
                                                <Grid item>
                                                    <Typography variant="body2">Tarif</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        {marchand.prixAuMetre} €
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                style={{
                                                    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                                                    marginTop: 10
                                                }}
                                                item
                                                container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="center">
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        <strong>Total</strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        <strong>{marchand.montant} €</strong>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ marginTop: 10 }}>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        left: '100%',
                                                        bottom: 0,
                                                        transform: 'translateX(-100%)',
                                                        width: 0,
                                                        height: 0,
                                                        borderTop: '45px solid transparent',
                                                        borderBottom: '0px solid transparent',
                                                        borderRight:
                                                            '70px solid ' +
                                                            (marchand.encaisse == '0'
                                                                ? 'red'
                                                                : 'green'),
                                                        opacity: 0.1
                                                    }}></div>
                                                <Typography align="right" variant="body2">
                                                    {marchand.encaisse == '0'
                                                        ? 'Non réglé'
                                                        : 'Réglé'}
                                                </Typography>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(withLeaflet(Control));
