import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';

import {
    Grid,
    FormControlLabel,
    Checkbox,
    Typography
} from '@material-ui/core';

import { Adresse } from './components'

const useStyles = makeStyles(theme => ({
    root: {
        //paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    }
}));


const MiniForm = props => {

    const { className, data, updateData, showPos, updateShowPos, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    const handleData = ({ target: { name, value } }) => {
        updateData("caracteristic", { ...data.caracteristic, [name]: value });
    }

    return (
        <div className={classes.root}>
            <Grid container direction="column">
                <Grid item>
                    <Adresse
                        selectedAdresse={data.adresse}
                        node="caracteristic"
                        setAdresse={handleData}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                checked={showPos}
                                onChange={(event) => {
                                    updateShowPos(event.target.checked)
                                }}
                            />
                        }
                        label={
                            <Typography variant="body2">
                                Afficher la position
                            </Typography>
                        }
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default MiniForm;
