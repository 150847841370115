import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
    Grid,
    Divider,
    Button,
    CircularProgress,
    Typography
} from '@material-ui/core';

import HourglassFullTwoToneIcon from '@material-ui/icons/HourglassFullTwoTone';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles(theme => ({
    root: {
        //flexGrow: 1
    },
    content: {
        marginTop: 10
        //position: "relative",
        //top: "100%",
        //transform: "translateY(-25%)"
    }
}));

const MarketState = props => {
    const { className, data, ...rest } = props;

    const classes = useStyles();

    return (
        <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
            {
                data.state == 0 ?
                    <React.Fragment>
                        <Grid item>
                            <Grid container direction="column" alignItems="center" justify="center" spacing={2}
                                className={classes.content}>
                                <Grid container direction="column" alignItems="center" justify="center" spacing={1}>
                                    <CancelIcon style={{ fontSize: 50, color: "red" }} />
                                </Grid>
                                <Grid item>
                                    <Typography variant="button" style={{ color: "red" }}>
                                        Marché non ouvert
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                    :
                    data.state == 1 ?
                        <React.Fragment>
                            <Grid item>
                                <Grid container direction="column" alignItems="center" justify="center" spacing={1}
                                    className={classes.content}>
                                    <Grid item>
                                        <HourglassFullTwoToneIcon style={{ fontSize: 50, }} color="secondary" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="button" color="secondary">
                                            Marché en cours
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                        : data.state == 2 ?
                            <React.Fragment>
                                <Grid item>
                                    <Grid container direction="column" alignItems="center" justify="center" spacing={1}>
                                        <Grid item>
                                            <CheckCircleOutlineIcon style={{ fontSize: 50, color: "green" }} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="button" style={{ color: "green" }}>
                                                Marché clôturé par <font style={{ textDecoration: "underline" }}>{data.user}</font>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item style={{ width: "100%" }}>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" justify="flex-start">
                                        <Grid item>
                                            <Typography variant="body1" align="left">
                                                <strong>Commentaires</strong> : {data.comment}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                            : data.state == 3 ?
                                <React.Fragment>
                                    <Grid item>
                                        <Grid container direction="column" alignItems="center" justify="center" spacing={2}
                                            className={classes.content}>
                                            <Grid container direction="column" alignItems="center" justify="center" spacing={1}>
                                                <WarningIcon style={{ fontSize: 50, color: "orange" }} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="button" style={{ color: "orange" }}>
                                                    Marché non clôturé
                                            </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                                : null
            }
        </Grid>

    );
};

export default MarketState;
