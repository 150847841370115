import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';

import { Bar } from 'react-chartjs-2';

import {
    Typography,
    Card,
    CardHeader,
    CardContent,
    Avatar
} from '@material-ui/core';

import TrendingUpIcon from '@material-ui/icons/TrendingUp';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    paperContent: {
        padding: theme.spacing(3),
    },
    labelFilter: {
        color: "#3f51b5",
        marginBottom: 15
    },
    labelFilterRadio: {
        color: "#3f51b5",
        marginBottom: 5
    }
}));

const RecipesMarketBar = props => {
    const { className, rawData, date, labels, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    const formattedData = [];

    rawData.data &&
        rawData.data.map((_data, index) => {
            formattedData.push({
                label: rawData.activitie[index].label,
                borderWidth: 1,
                borderColor: theme.palette.primary.main,
                backgroundColor: rawData.activitie[index].color,
                data: _data,
            })
        })


    const data = {
        labels: labels,
        datasets: formattedData,
    }

    const options = {
        responsive: true,
        title: {
            display: true,
            position: "bottom",
            text: rawData.marketLabel, 
            fontColor: "#3f51b5", 
            fontSize: 14,
        },
        legend: {
            position: "right",
            align: "center",
            labels: {
                boxWidth: 12.5,
            }
        },
        scales: {
            xAxes: [{
                stacked: true
            }],
            yAxes: [{
                stacked: true
            }]
        },
        tooltips: {
            caretSize: 0,
            displayColors: true,
            callbacks: {
                label: function (tooltipItem, data) {
                    return `${rawData.activitie[tooltipItem.datasetIndex].label} : ${rawData.formattedData[tooltipItem.datasetIndex][tooltipItem.index]} €`;
                },
                footer: function (tooltipItem, data) {
                    return `Somme total des activités : ${rawData.formattedTotal[tooltipItem[0].index]} €`
                }
            }
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0 // animation duration after a resize
    }

    return (

        <Card>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        <TrendingUpIcon />
                    </Avatar>
                }
                title={
                    <Typography color="primary" variant="subtitle2">
                        Recettes par marché pour la période du {date.start} au {date.end}
                    </Typography>
                }
            />
            <CardContent>
                <Bar
                    data={data}
                    options={options}
                />
            </CardContent>
        </Card>
    );
};

export default RecipesMarketBar;
