import React from 'react';
import { site, showNewDashboard } from 'common/env';
import { NavLink as RouterLink } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Card,
    CardContent,
    CardActionArea,
    Grid,
    Typography,
    Divider,
    Button,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Dialog,
    DialogActions
} from '@material-ui/core';

import JournalMarche from '../../../../../../JournalMarche';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    statusHeader: {
        zIndex: -1,
        boxShadow: '0 0 0 1px rgba(63,63,68,0.08), 0 1px 3px 0 rgba(63,63,68,0.15)',
        position: 'absolute',
        top: -21,
        left: '50%',
        transform: 'translateX(-50%)',
        background: 'white',
        margin: 'auto',
        width: 39,
        height: 39,
        paddingLeft: 1,
        paddingTop: 3,
        borderRadius: 50
    },
    status: {
        zIndex: 1,
        position: 'absolute',
        border: '4px solid white',
        top: -20,
        left: '50%',
        transform: 'translateX(-50%)',
        background: '#2699FB',
        margin: 'auto',
        width: 35,
        height: 35,
        borderRadius: 50
    },
    content: {
        //marginBottom: 15
    },
    finance: {
        display: 'inline-flex'
    },
    chartContainer: {
        position: 'relative'
        //height: '300px'
    },
    chartDispo: {
        width: 70,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    chartDispoText: {
        //position: "absolute",
        //top: "50%",
        //width: "100%",
        //padding: 20,
        //transform: "translateY(-40%)"
    },
    cardFooter: {
        //marginTop: 20,
        padding: 20,
        paddingBottom: 15,
        paddingTop: 17,
        background: '#43425D'
    },
    marketLabel: {
        color: 'white'
    },
    marketStatus: {
        marginTop: 7,
        fontWeight: 500,
        color: '#2699FB'
    },
    action: {
        marginTop: -5,
        color: 'white'
    },
    marketCard: {
        position: 'relative',
        width: 290,
        margin: 'auto',
        marginTop: 35,
        marginBottom: 35
    },
    availableDot: {
        height: 9,
        width: 9,
        margin: 6,
        marginRight: 4,
        borderRadius: 50,
        background: '#ddd'
    },
    alim: {
        height: 9,
        width: 9,
        margin: 4,
        marginRight: 0,
        marginLeft: 4,
        borderRadius: 50,
        background: 'gold'
    },
    prim: {
        height: 9,
        width: 9,
        margin: 4,
        marginRight: 0,
        marginLeft: 4,
        borderRadius: 50,
        background: '#32CD32'
    },
    manu: {
        height: 9,
        width: 9,
        margin: 4,
        marginRight: 0,
        marginLeft: 4,
        borderRadius: 50,
        background: '#87CEFA'
    },
    dempos: {
        height: 9,
        width: 9,
        margin: 4,
        marginRight: 0,
        marginLeft: 4,
        borderRadius: 50,
        background: '#BD33A4'
    }
}));

const Overview = props => {
    const { className, data, jmid, ...rest } = props;

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let etat = 'Marché non ouvert';
    let color = 'red';

    if (data.marche.etat == '1') {
        etat = 'Marché en cours...';
        color = '#2699FB';
    } else if (data.marche.etat == '2') {
        etat = 'Marché clôturé';
        color = '#0ff80f';
    } else if (data.marche.etat == '3') {
        etat = 'Marché non clôturé';
        color = 'orange';
    }

    const classes = useStyles();
    const theme = useTheme();

    const occupation = [
        parseInt(data.occupation.free.nombre),
        parseInt(data.occupation.alim.nombre),
        parseInt(data.occupation.prim.nombre),
        parseInt(data.occupation.manu.nombre),
        parseInt(data.occupation.dempos.nombre)
    ];

    let pourcentage = parseInt(
        ((parseInt(data.occupation.alim.nombre) +
            parseInt(data.occupation.prim.nombre) +
            parseInt(data.occupation.manu.nombre) +
            parseInt(data.occupation.dempos.nombre)) /
            (parseInt(data.occupation.alim.nombre) +
                parseInt(data.occupation.prim.nombre) +
                parseInt(data.occupation.manu.nombre) +
                parseInt(data.occupation.dempos.nombre) +
                parseInt(data.occupation.free.nombre))) *
            100
    );

    const donuts = {
        datasets: [
            {
                data: occupation,
                backgroundColor: ['#f0f0f7', 'gold', '#32CD32', '#87CEFA', '#BD33A4'],
                borderWidth: 4,
                borderColor: theme.palette.white,
                hoverBorderColor: theme.palette.white
            }
        ],
        labels: ['Disponible', 'Alimentaire', 'Primeur', 'Manufacturé', 'Démonstrateur/Posticheur']
    };

    const options = {
        legend: {
            display: false
        },
        responsive: true,
        maintainAspectRatio: true,
        animation: {
            duration: 3000
        },
        cutoutPercentage: 75,
        layout: { padding: 0 },
        tooltips: {
            enabled: true
        }
    };

    const toDashboard = (event, init = true) => {
        if (data.marche.etat == '0' && init) {
            event.preventDefault();
            setOpen(true);
        }

        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        const today = new Date();
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        JournalMarche.setId(jmid);
        //JournalMarche.setLabel('Bagnols-sur-cèze');
        JournalMarche.setDate(today.toLocaleDateString('fr-FR', options));
    };

    const dashboardRoute = () => {
        if (showNewDashboard()) {
            return `/my-market/${jmid}/dashboard`;
        }
        return '/dashboard/' + jmid;
    };

    return (
        <div className={classes.marketCard}>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h5">{'Initialisation du marché'}</Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography color="primary" variant="h5">
                        Voulez-vous démarrer cette journée de marché ?
                    </Typography>
                    <DialogContentText style={{ fontSize: 12 }}>
                        Note : Cette action vous permettra de placer les commerçants
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        autoFocus
                        component={RouterLink}
                        onClick={event => toDashboard(event, false)}
                        to={
                            showNewDashboard()
                                ? `/my-market/${jmid}/dashboard`
                                : `/dashboard/${jmid}`
                        }>
                        Démarrer
                    </Button>
                </DialogActions>
            </Dialog>

            <div className={classes.statusHeader} />
            <Card {...rest} className={clsx(classes.root, className)}>
                <div className={classes.status} style={{ background: color }} />
                <CardContent className={classes.content}>
                    <Grid container>
                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <Typography gutterBottom align="left" variant="body1">
                                Recettes
                            </Typography>
                        </Grid>
                        <Grid item lg={12} sm={12} xl={12} xs={12} className={classes.finance}>
                            <Grid item lg={6} sm={6} xl={6} xs={6}>
                                <Typography align="left" variant="body2">
                                    Réglé
                                </Typography>
                            </Grid>
                            <Grid item lg={6} sm={6} xl={6} xs={6}>
                                <Typography
                                    align="right"
                                    style={{ color: 'green' }}
                                    variant="body2">
                                    {data.finance.encaisse} €
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item lg={12} sm={12} xl={12} xs={12} className={classes.finance}>
                            <Grid item lg={6} sm={6} xl={6} xs={6}>
                                <Typography align="left" variant="body2">
                                    Reste à régler
                                </Typography>
                            </Grid>
                            <Grid item lg={6} sm={6} xl={6} xs={6}>
                                <Typography align="right" style={{ color: 'red' }} variant="body2">
                                    {data.finance.non_encaisse} €
                                </Typography>
                            </Grid>
                        </Grid>

                        <Divider style={{ width: '95%', margin: '17px auto 17px auto' }} />

                        <Grid container>
                            <Grid item lg={12} sm={12} xl={12} xs={12}>
                                <Typography gutterBottom align="left" variant="body1">
                                    Occupation du marché
                                </Typography>
                            </Grid>
                            <Grid item lg={12} sm={12} xl={12} xs={12}>
                                <div className={classes.chartContainer}>
                                    <div className={classes.chartDispo}>
                                        <Typography
                                            align="center"
                                            className={classes.chartDispoText}
                                            variant="body2">
                                            {pourcentage}% occupées
                                        </Typography>
                                    </div>
                                    <Doughnut data={donuts} height={150} options={options} />
                                </div>

                                <Grid xs={12} style={{ marginTop: 30 }}>
                                    <Grid container justify="space-between">
                                        <Grid item>
                                            <Grid item xs={12}>
                                                <Grid container justify="space-between">
                                                    <Grid item>
                                                        <Typography
                                                            gutterBottom
                                                            align="right"
                                                            variant="body1">
                                                            Occupées
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{
                                                            position: 'absolute',
                                                            left: 80,
                                                            marginTop: -11
                                                        }}>
                                                        <div className={classes.alim} />
                                                        <div className={classes.prim} />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{
                                                            position: 'absolute',
                                                            left: 95,
                                                            marginTop: -11
                                                        }}>
                                                        <div className={classes.dempos} />
                                                        <div className={classes.manu} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography align="left" variant="body2">
                                                    {parseInt(data.occupation.alim.nombre) +
                                                        parseInt(data.occupation.prim.nombre) +
                                                        parseInt(data.occupation.manu.nombre) +
                                                        parseInt(data.occupation.dempos.nombre) +
                                                        ' places (' +
                                                        (parseInt(data.occupation.alim.metres) +
                                                            parseInt(data.occupation.prim.metres) +
                                                            parseInt(data.occupation.manu.metres) +
                                                            parseInt(
                                                                data.occupation.dempos.metres
                                                            )) +
                                                        ' m)'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid item xs={12}>
                                                <Grid container justify="space-between">
                                                    <Grid item>
                                                        <div className={classes.availableDot} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            gutterBottom
                                                            align="right"
                                                            variant="body1">
                                                            Disponibles
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography align="right" variant="body2">
                                                    {data.occupation.free.nombre +
                                                        ' places (' +
                                                        data.occupation.free.metres +
                                                        ' m)'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
                {/* <CardActionArea component={RouterLink} onClick={toDashboard} to={"/dashboard/" + jmid}> */}
                <CardActionArea
                    component={RouterLink}
                    onClick={toDashboard}
                    // to={`/my-market/${jmid}/dashboard`}>
                    to={dashboardRoute}>
                    <Grid container className={classes.cardFooter}>
                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <Typography align="center" className={classes.marketLabel} variant="h5">
                                {data.marche.libelle}
                            </Typography>
                        </Grid>
                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <Typography
                                align="center"
                                className={classes.marketStatus}
                                style={{ color: color }}
                                variant="body2">
                                {etat}
                            </Typography>
                        </Grid>

                        <Divider
                            style={{
                                width: '95%',
                                margin: '12px auto 17px auto',
                                background: 'rgb(204, 204, 204, 0.3)'
                            }}
                        />

                        <Grid item lg={12} sm={12} xl={12} xs={12}>
                            <Button className={classes.action} fullWidth>
                                Visualiser
                            </Button>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        </div>
    );
};

export default Overview;
