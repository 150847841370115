import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Typography,
    DialogContent,
    DialogActions,
    Button,
    Link
} from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles(theme => ({

}));

const Icon = (props) => {

    const { numbers } = props;
    let icon = <div></div>

    if (numbers.unsent == 0) {
        icon = <CheckCircleIcon size={60} style={{ color: "green", fontSize: 60 }} />
    } else if (numbers.unsent > 0 && numbers.sent > 0) {
        icon = <WarningIcon size={60} style={{ color: "orange", fontSize: 60 }} />
    } else if (numbers.sent == 0 && numbers.unsent > 0) {
        icon = <ErrorIcon size={60} style={{ color: "red", fontSize: 60 }} />
    }

    return icon
}

const Done = props => {
    const { className, getBack, close, data, ...rest } = props;

    const classes = useStyles();

    return (
        <React.Fragment>
            <DialogContent dividers>
                <Grid container
                    direction="column" spacing={3}
                    justify="center"
                    alignItems="center"
                >
                    <Grid item>
                        <Icon numbers={data.numbers} />
                    </Grid>
                    <Grid item>
                        <Typography>
                            <ul>
                                <li>Nombre total de commerçants sélectionnés : <strong>{data.numbers.total}</strong></li>
                                <li>Nombre de commerçants ayant reçu le mail : <strong>{data.numbers.sent}</strong></li>
                                <li>Nombre de commerçants n'ayant pas reçu le mail : <strong>{data.numbers.unsent}</strong></li>
                            </ul>
                        </Typography>
                    </Grid>
                    {
                        (data.numbers.unsent == 0 && data.numbers.sent > 0) &&
                        <Grid item>
                            <Typography style={{ color: "green" }}>
                                Tous les commerçants ont reçus le mail.{" "}
                                <Link href={data.link} target="_blank">
                                    Imprimer la liste <PrintIcon style={{ position: "relative", top: 7 }} />
                                </Link>
                            </Typography>
                        </Grid>
                    }
                    {
                        (data.numbers.unsent > 0 && data.numbers.sent > 0) &&
                        <Grid item>
                            <Typography style={{ color: "orange" }}>
                                Il semblerait que certains commerçants n'ont pas reçus le mail.{" "}
                                <Link href={data.link} target="_blank">
                                    Imprimer la liste <PrintIcon style={{ position: "relative", top: 7 }} />
                                </Link>
                            </Typography>
                        </Grid>
                    }
                    {
                        (data.numbers.sent == 0 && data.numbers.unsent > 0) &&
                        <Grid item>
                            <Typography style={{ color: "red" }}>
                                Aucun commerçant n'a reçu le mail.{" "}
                                <Link href={data.link} target="_blank">
                                    Imprimer la liste <PrintIcon style={{ position: "relative", top: 7 }} />
                                </Link>
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={getBack}>
                    Revenir au formulaire
                </Button>
                <Button variant="contained" color="primary" onClick={close}>
                    Fermer
                </Button>
            </DialogActions>
        </React.Fragment>

    );
};

export default Done;
