import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
    Grid,
    Typography,
    Button,
    CircularProgress
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        //flexGrow: 1
    },
}));

const Indicators = props => {
    const {
        className,
        data,
        init,
        link,
        journalMarketId,
        ...rest
    } = props;

    const classes = useStyles();

    return (
        <Grid container
            direction="column"
            justify="space-between"
            alignItems="stretch" spacing={2}
            className={classes.root}>
            {
                init ?
                    <Grid item>
                        <Grid container direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={1}>
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <React.Fragment>
                        <Grid item>
                            <Typography variant="body2">
                                {data.label}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column"
                                //justify="space-between"
                                alignItems="stretch"
                                spacing={1}>
                                <Grid item>
                                    {
                                        data.numbers.onlyOne ?
                                            <Typography variant="h4" align="center" gutterBottom>
                                                {data.numbers.theOne}
                                            </Typography> :
                                            <Grid container direction="row" spacing={1} justify="center" alignItems="flex-end">
                                                <Grid item>
                                                    <Typography variant="h4" align="center" gutterBottom>
                                                        {data.numbers.first}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" gutterBottom>
                                                        /
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h4" align="center" gutterBottom>
                                                        {data.numbers.second}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                    }
                                </Grid>
                                <Grid item>
                                    <Grid container
                                        direction="column"
                                        justify="center"
                                        alignItems="stretch"
                                    >
                                        {
                                            data.details.map(indicator => {
                                                return (
                                                    <Grid item key={indicator.label}>
                                                        <Grid container direction="row" justify="space-between" spacing={1}>
                                                            <Grid item>
                                                                <Typography variant="body2" style={{ color: (indicator.color == "default" ? "inherit" : indicator.color) }}>
                                                                    {indicator.label}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body2" style={{ color: (indicator.color == "default" ? "inherit" : indicator.color) }}>
                                                                    {indicator.count}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Button fullWidth component={RouterLink} to={`${journalMarketId}/${link}`} color="primary">
                                Détail
                            </Button>
                        </Grid>
                    </React.Fragment>
            }
        </Grid>
    );
};

export default Indicators;
