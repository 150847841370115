import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Paper,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Divider,
    Typography,
    Link,
    Button,
    InputBase,
    FormControl,
    FormLabel
} from '@material-ui/core';

import { Overview } from './components';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 15
    }
});

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            isSubmited: Boolean(props.args.args1),
            searchValue: '',
            start: props.args.args1 || '',
            end: props.args.args2 || '',
            approvedDate: {
                start: new Date(props.args.args1),
                end: new Date(props.args.args2)
            },
            disableSearch: !Boolean(props.args.args1),
            list: [],
            page: 1,
            number_of_pages: 0,
            options: {
                //weekday: 'numeric',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            }
        };
    }

    componentDidMount = () => {
        //this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.args.args1 != this.props.args.args1 ||
            prevProps.args.args2 != this.props.args.args2
        ) {
            this.setState({
                start: this.props.args.args1 || '',
                end: this.props.args.args2 || '',
                approvedDate: {
                    start: this.props.args.args1 ? new Date(this.props.args.args1) : '',
                    end: this.props.args.args2 ? new Date(this.props.args.args2) : ''
                },
                disableSearch: !Boolean(this.props.args.args1),
                isSubmited: Boolean(this.props.args.args1),
                list: []
            });
        }

        if (this.state.isSubmited) {
            this.getData();
        }

        (this.state.searchValue != prevState.searchValue || this.state.page != prevState.page) &&
            this.getData();
    };

    getData = () => {
        const { start, end, searchValue } = this.state;

        this.setState({
            isSubmited: false
        });

        const formData = new FormData();
        formData.append('start', start);
        formData.append('end', end);
        formData.append('search', searchValue);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/get-by-date`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleDate = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    handleForm = () => {
        const { start, end, approvedDate } = this.state;

        if (start == '') {
            alert('Veuillez saisir une date de début');
            return;
        }

        if (end == '') {
            alert('Veuillez saisir une date de fin');
            return;
        }

        const _start = new Date(start);
        const _end = new Date(end);

        if (_start.getTime() > _end.getTime()) {
            let inverse = window.confirm(
                'La date de début est inférieur à la date de fin\nInverser les dates ?'
            );
            inverse &&
                this.setState({
                    start: end,
                    end: start
                });
            return;
        }

        this.setState({
            approvedDate: {
                start: _start,
                end: _end
            }
        });

        this.props.history.push('/markets/search/' + start + '/' + end);
    };

    render() {
        const {
            classes,
            searchValue,
            start,
            end,
            approvedDate,
            disableSearch,
            options,
            list
        } = this.state;

        return (
            <Grid container direction="column" spacing={6}>
                <Grid item>
                    <Paper className={classes.paperContent}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="stretch"
                            spacing={2}>
                            <Grid item>
                                <Grid container direction="row" alignItems="baseline">
                                    <Grid item>
                                        <IconButton onClick={this.getBack}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            <Link component={RouterLink} to="/markets/all">
                                                Les marchés
                                            </Link>{' '}
                                            / Rechercher une journée
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset" required>
                                    <FormLabel className={classes.labelFilter} component="label">
                                        Rechercher par date
                                    </FormLabel>
                                </FormControl>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                    spacing={2}>
                                    <Grid item xs={12} md={5}>
                                        <TextField
                                            type="date"
                                            fullWidth
                                            name="start"
                                            value={start}
                                            onChange={this.handleDate}
                                            size="small"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <TextField
                                            type="date"
                                            fullWidth
                                            size="small"
                                            name="end"
                                            value={end}
                                            onChange={this.handleDate}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            color="primary"
                                            onClick={this.handleForm}>
                                            Rechercher
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <TextField
                                    label={
                                        !disableSearch &&
                                        `Rechercher un marché du ${approvedDate.start.toLocaleDateString(
                                            'fr-FR',
                                            options
                                        )} au ${approvedDate.end.toLocaleDateString(
                                            'fr-FR',
                                            options
                                        )}`
                                    }
                                    type="text"
                                    fullWidth
                                    value={searchValue}
                                    disabled={disableSearch}
                                    onChange={this.handleSearch}
                                    variant="outlined"
                                    helperText={
                                        !disableSearch &&
                                        'Saisir le libellé du marché ou son adresse'
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    disabled={disableSearch}
                                                    onClick={this.clearSearch}
                                                    edge="end">
                                                    <ClearIcon style={{ color: 'red' }} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        spacing={6}
                        justify="center"
                        alignItems="center"
                        direction="row">
                        {list &&
                            Object.keys(list).map((date, index) => {
                                return (
                                    <Grid item xs={12}>
                                        <Grid container direction="column">
                                            <Grid item>
                                                <Grid container alignItems="center" spacing={1}>
                                                    <Grid item xs={12} sm={3} md={2}>
                                                        <Grid
                                                            container
                                                            justify="center"
                                                            alignItems="baseline"
                                                            direction="row"
                                                            spacing={1}>
                                                            <Grid item>
                                                                <FiberManualRecordIcon
                                                                    color="primary"
                                                                    style={{ fontSize: 16 }}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    color="primary"
                                                                    variant="h3">
                                                                    {date}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={9} md={10}>
                                                        <hr
                                                            style={{ borderTop: '1px solid #bbd' }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    spacing={3}
                                                    justify="center"
                                                    alignItems="center"
                                                    direction="row">
                                                    {list[date].map(journalmarche => (
                                                        <Grid item>
                                                            {
                                                                <Overview
                                                                    jmid={journalmarche.marche.id}
                                                                    data={journalmarche}
                                                                />
                                                            }
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Search);
