import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';

import { Grid, Slide } from '@material-ui/core';
import { UiStepper, Dashboard, Synthesis } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1)
        }
    }
});

class MyMarket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            page: props.match.params.page
        };
    }

    componentDidUpdate(prevProps) {
        const { page } = this.props.match.params;
        if (page !== prevProps.match.params.page) {
            this.setState({ page: page });
        }
    }

    render() {
        const { classes } = this.props;
        const { id, page } = this.state;

        return (
            <div className={classes.root}>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <UiStepper id={id} currentPage={page} />
                    </Grid>
                    <Grid item>
                        <div style={{ display: page === 'dashboard' ? 'inherit' : 'none' }}>
                            <Dashboard id={id} />
                        </div>
                        <div style={{ display: page === 'synthesis' ? 'inherit' : 'none' }}>
                            {page === 'synthesis' && <Synthesis id={id} />}
                        </div>
                        {/* {page === 'dashboard' && (
                            <Slide
                                in
                                direction="right"
                                mountOnEnter
                                timeout={{
                                    enter: 300
                                }}>
                                <Dashboard id={id} />
                            </Slide>
                        )}
                        {page === 'synthesis' && (
                            <Slide
                                in
                                direction="left"
                                mountOnEnter
                                timeout={{
                                    enter: 300
                                }}>
                                <Synthesis id={id} />
                            </Slide>
                        )} */}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(MyMarket);
