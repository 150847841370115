var UserProfile = (function () {
  var fullName = "";
  var profile = "";
  var roleId = 0;
  var id = 0;
  var token = null;
  var domaine = false;
  var contributor = false;

  var getId = function () {
    return localStorage.getItem('id')
    return id;    // Or pull this from cookie/localStorage
  };

  var getName = function () {
    return localStorage.getItem('name')
    return fullName;    // Or pull this from cookie/localStorage
  };

  var getToken = function () {
    return localStorage.getItem('token')
    return token;    // Or pull this from cookie/localStorage
  };

  var getContributor = function () {
    return JSON.parse(localStorage.getItem('contributor'))
    return token;    // Or pull this from cookie/localStorage
  };

  var getProfile = function () {
    return localStorage.getItem('profile');
    //return profile;    // Or pull this from cookie/localStorage
  };

  var getRoleId = function () {
    return localStorage.getItem('roleId');
    //return profile;    // Or pull this from cookie/localStorage
  };

  var getDomaine = function () {
    return JSON.parse(localStorage.getItem('domaine'));
    //return profile;    // Or pull this from cookie/localStorage
  };

  var setId = function (id) {
    id = id;
    // Also set this in cookie/localStorage
    localStorage.setItem('id', id);
  };

  var setName = function (name) {
    fullName = name;
    // Also set this in cookie/localStorage
    localStorage.setItem('name', name);
  };

  var setToken = function (token) {
    token = token;
    // Also set this in cookie/localStorage
    localStorage.setItem('token', token);
  };

  var setProfile = function (_profile) {
    profile = _profile;
    // Also set this in cookie/localStorage
    localStorage.setItem('profile', _profile);
  };

  var setContributor = function (_contributor) {
    contributor = _contributor;
    // Also set this in cookie/localStorage
    localStorage.setItem('contributor', _contributor);
  };

  var setRoleId = function (_roleId) {
    roleId = _roleId;
    // Also set this in cookie/localStorage
    localStorage.setItem('roleId', _roleId);
  };


  var setDomaine = function (_domaine) {
    domaine = _domaine;
    // Also set this in cookie/localStorage
    localStorage.setItem('domaine', _domaine);
  };

  var setSession = function () {
    sessionStorage.setItem('id', getId());
    sessionStorage.setItem('name', getName());
    sessionStorage.setItem('token', getToken());
    sessionStorage.setItem('profile', getProfile());
    sessionStorage.setItem('domaine', getDomaine());
    sessionStorage.setItem('contributor', getContributor());
  };

  var clearSession = function () {
    sessionStorage.setItem('id', null);
    sessionStorage.setItem('name', null);
    sessionStorage.setItem('token', null);
    sessionStorage.setItem('profile', null);
    sessionStorage.setItem('domaine', null);
    sessionStorage.setItem('contributor', null);
  };

  var getSessionStorage = function () {
    return {
      'name': getName(),
      'profile': getProfile()
    }
  }

  return {
    getId: getId,
    setId: setId,
    getToken: getToken,
    setToken: setToken,
    getContributor: getContributor,
    setContributor: setContributor,
    getName: getName,
    setName: setName,
    getProfile: getProfile,
    setProfile: setProfile,
    getRoleId: getRoleId,
    setRoleId: setRoleId,
    getDomaine: getDomaine,
    setDomaine: setDomaine,
    setSession: setSession,
    clearSession: clearSession,
    getSessionStorage: getSessionStorage
  }

})();

export default UserProfile;
