// @flow

//import L from 'leaflet';
import React from 'react';
import { withStyles } from '@material-ui/styles';
import { withLeaflet } from 'react-leaflet';
import transformRotate from '@turf/transform-rotate';
import turf from 'turf';
import JournalMarche from '../../../../JournalMarche';

import { Paper, Grid, Typography } from '@material-ui/core';

import MarketPlace from './MarketPlace';

//const layersGroup = L.featureGroup();

const DEFAULT_STATE = {
    sliderRotation: 0,
    inputNumero: '',
    inputMeters: '',
    activitieKey: '',
    merchantSiret: '_0',
    information: '',
    layers: {
        selectedLayer: null,
        flyOverLayer: null
        //layersGroup: layersGroup
    }
};

const sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
};

const styles = {
    root: {
        padding: 10
    },
    paper: {
        padding: 6,
        position: 'relative',
        zIndex: 1000
    }
};

class Control extends React.Component {
    activities = {
        alim: {
            id: 1,
            key: 'alim',
            label: 'Alimentaire',
            color: 'gold'
        },
        prim: {
            id: 2,
            key: 'prim',
            label: 'Primeur',
            color: '#32CD32	'
        },
        dempos: {
            id: 3,
            key: 'dempos',
            label: 'Démonstrateur/Posticheur',
            color: '#BD33A4'
        },
        manu: {
            id: 4,
            key: 'manu',
            label: 'Manufacturé',
            color: '#87CEFA'
        }
    };

    merchants = {
        _0: {
            siret: '0',
            raisonSociale: 'Non affecté',
            metier: 'Aucun',
            numero: false
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            ...props,
            ...DEFAULT_STATE,
            layers: {
                ...DEFAULT_STATE.layers,
                layersGroup: this.props.layersGroup
            },
            tabValue: 0,
            listOfPlaces: []
        };

        let { layersGroup } = this.state.layers;

        layersGroup.addEventListener('click', this._onClick);
        //layersGroup.addEventListener("mouseover", this._onMouseOver);
        //layersGroup.addEventListener("mouseout", this._onMouseOut);

        layersGroup.addTo(this.props.leaflet.map);

        this.loadData();
    }

    _onClick = e => {
        // Selected layer
        let layer = e.layer;
        let { selectedMerchant, raisonSociale, numero, layersGroup } = this.props;
        let { map } = this.props.leaflet;
        let moveTo = 'placé sur';
        //let { selectedLayer } = this.state.layers

        //if (selectedLayer) {
        //  selectedLayer.setActivitie(selectedLayer.getConfig("activitie"))
        //}

        //layer.setStyle({
        //  color: "blue",
        //});

        if (!selectedMerchant) {
            alert("Sélectionner d'abord un commerçant");
            return false;
        }

        let data = {
            siret: selectedMerchant,
            numero: numero,
            raisonSociale: raisonSociale
        };

        layer.setMerchant(data);

        if (data.numero) {
            layersGroup.eachLayer(layer => {
                if (layer.is_a_place && data.numero == layer.getConfig('numero').numero) {
                    layer.setMerchant(this.merchants['_0']);
                    moveTo = 'déplacé du N°' + layer.getConfig('numero').numero + ' au';
                }
            });
        }

        data.numero = layer.getConfig('numero').numero;

        map.setView(layer.getLatLngs()[0][0]);
        layer.setStyle({
            weight: 8,
            fillOpacity: 1
        });

        const resetPlace = async () => {
            await sleep(4000);
            layer.setStyle({
                weight: 2,
                fillOpacity: 0.4
            });
        };

        resetPlace();

        this.setState({
            information: `${layer.getConfig('merchant').raisonSociale} ${moveTo} N°${
                layer.getConfig('numero').numero
            }`
        });

        this.props.handleItem(null, selectedMerchant);
    };

    removeFromPlace = () => {
        let { selectedMerchant, layersGroup, numero, raisonSociale } = this.props;
        let mylayer = null;

        let merchant = {
            siret: selectedMerchant,
            numero: numero,
            raisonSociale: raisonSociale
        };

        if (!merchant.numero) {
            alert('Commerçant non placé !');
            return false;
        }

        layersGroup.eachLayer(layer => {
            if (layer.is_a_place && merchant.numero == layer.getConfig('numero').numero) {
                mylayer = layer;
            }
        });

        this.setState({
            information: `${raisonSociale} enlevé de la place N°${numero}`
        });

        mylayer.setMerchant(this.merchants['_0']);
        //this.merchants[`_${selectedMerchant}`].numero = false;

        return true;
    };

    saveData = () => {
        let { layersGroup } = this.state.layers;
        let geoJSON = {
            type: 'FeatureCollection',
            features: []
        };

        layersGroup.eachLayer(layer => {
            if (layer.is_a_place) {
                let layerGeoJSON = {};
                layerGeoJSON.activitie = layer.getConfig('activitie').key;
                layerGeoJSON.marchand = layer.getConfig('merchant');
                layerGeoJSON.meters = layer.getConfig('meters');
                layerGeoJSON.rotation = layer.getConfig('rotation');
                layerGeoJSON.numero = layer.getConfig('numero').numero;
                layerGeoJSON.coordinates = layer.getLatLngs()[0];

                geoJSON.features.push(layerGeoJSON);
            }
        });

        //console.log(geoJSON)
    };

    getActivite = id => {
        let myact = '';
        Object.keys(this.activities).map(key => {
            if (this.activities[key].id == parseInt(id)) {
                myact = this.activities[key];
            }
        });

        return myact;
    };

    loadData = () => {
        let { layersGroup } = this.state.layers;
        let { map } = this.props.leaflet;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/${JournalMarche.getId()}/coords`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data

                map.setView([data[0].a_lat, data[0].a_lng]);

                data.map(layer => {
                    let marketPlace = new MarketPlace();
                    //marketPlace.addEventListener("dragstart", this._onDragStart);
                    //marketPlace.addEventListener("dragend", this._onDragEnd);

                    marketPlace.setActivitie(this.getActivite(layer['activite_id']));

                    let coords = [
                        {
                            lat: layer['a_lat'],
                            lng: layer['a_lng']
                        },
                        {
                            lat: layer['b_lat'],
                            lng: layer['b_lng']
                        },
                        {
                            lat: layer['c_lat'],
                            lng: layer['c_lng']
                        },
                        {
                            lat: layer['d_lat'],
                            lng: layer['d_lng']
                        }
                    ];

                    marketPlace.setLatLngs(coords);
                    marketPlace.setConfig('meters', layer['metres']);

                    marketPlace.setConfig('rotation', layer['rotation']);
                    marketPlace.addTo(layersGroup);
                    //marketPlace.dragging.enable();
                    marketPlace.setNumero(layer['numero']);
                    marketPlace.getConfig('numero').label.addTo(map);

                    let marchand = {
                        raisonSociale: layer['raisonSociale'],
                        siret: layer['marchand_id']
                    };

                    if (layer['marchand_id'] == '1') {
                        marchand = this.merchants['_0'];
                    }

                    marketPlace.setMerchant(/*this.merchants["_0"]*/ marchand);
                });
                this.saveData();
            })
            .catch(console.log);

        /*
    import('./marseille.json')
      .then((data) => {

        map.setView(data.features[0].coordinates[0])

        data.features.map((layer) => {
          let marketPlace = new MarketPlace()
          //marketPlace.addEventListener("dragstart", this._onDragStart);
          //marketPlace.addEventListener("dragend", this._onDragEnd);

          marketPlace.setActivitie(this.activities[layer.activitie])

          marketPlace.setLatLngs(layer.coordinates)
          marketPlace.setConfig('meters', layer.meters);

          marketPlace.setConfig('rotation', layer.rotation)
          marketPlace.addTo(layersGroup);
          //marketPlace.dragging.enable();
          marketPlace.setNumero(layer.numero);
          marketPlace.getConfig('numero').label.addTo(map);

          marketPlace.setMerchant(this.merchants["_0"]);

        })

      });
      */
    };

    render() {
        const { information, classes } = this.state;

        return (
            <div className={classes.root}>
                {information != '' && (
                    <Grid container justify="space-between" spacing={4}>
                        <Grid item>
                            <Paper className={classes.paper} elevation={2}>
                                <Typography
                                    variant="body1"
                                    style={{ fontWeight: 500 }}
                                    color="primary">
                                    {information}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }
}

export default withStyles(styles)(withLeaflet(Control));
