
import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Link as RouterLink } from 'react-router-dom';

import TodayIcon from '@material-ui/icons/Today';
import {
    Grid,
    Avatar,
    Typography,
    Card,
    CardHeader,
    CardContent
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    reviewCell: {
        textAlign: "center",
        display: "block"
    },
}))

const TodayNews = props => {
    const { className, data, ...rest } = props;

    const classes = useStyles();


    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        <TodayIcon />
                    </Avatar>
                }
                title={
                    <Typography color="primary" variant="subtitle2">
                        Les informations générales du jour
                    </Typography>
                }
            />
            <CardContent>
                <Grid container spacing={6} justify="center">
                    <Grid item xs={12} sm={6} className={classes.reviewCell} style={{ backgroundColor: "rgb(0,0,0, 0.02)" }}>
                        <Typography variant="h2" style={{ color: "green" }}>
                            {data.totalCashedOfToday} €
                        </Typography>
                        <Typography variant="subtitle2">
                            Total des encaissements du jour
                            </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.reviewCell}>
                        <Typography variant="h2" style={{ color: "red" }}>
                            {data.totalNotCashedOfToday} €
                        </Typography>
                        <Typography variant="subtitle2">
                            Reste à régler du jour
                            </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.reviewCell}>
                        <Typography variant="h2">
                            {data.totalPlacedMerchant}
                        </Typography>
                        <Typography variant="subtitle2">
                            Commerçants placés
                            </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} className={classes.reviewCell} style={{ backgroundColor: "rgb(0,0,0, 0.02)" }}>
                        <Typography variant="h2">
                            {data.totalNewMerchant}
                        </Typography>
                        <Typography variant="subtitle2">
                            Nouveau(x) commerçant(s)
                            </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default TodayNews;


