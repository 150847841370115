import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';

import {
    Grid,
    TextField,
    FormControl,
    FormLabel,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({

}));

const CommentInput = props => {
    const { className, setComment, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    const [value, setValue] = React.useState("");

    const handleComment = ({ target: { value } }) => {
        setComment(value);
        setValue(value);
    }

    return (

        <Grid container direction="column" spacing={1} alignItems="stretch">
            <Grid item>
                <FormControl required component="fieldset">
                    <FormLabel color="primary">
                        Observation
                        </FormLabel>
                </FormControl>
            </Grid>
            <Grid item>
                <TextField
                    multiline
                    fullWidth
                    rows={4}
                    value={value}
                    rowsMax={8}
                    variant="outlined"
                    onChange={handleComment}
                />
            </Grid>
        </Grid>
    );
};

export default CommentInput;
