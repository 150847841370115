import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
    Grid,
    Paper,
    FormControl,
    TextField,
    Button,
    Divider,
    FormLabel,
    IconButton,
    InputAdornment,
    RadioGroup,
    FormControlLabel,
    Radio,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Typography
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import DescriptionIcon from '@material-ui/icons/Description';
import SendIcon from '@material-ui/icons/Send';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import PrintIcon from '@material-ui/icons/Print';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';

import { TotalLate, TotalBills, Pagination, Field, Bill } from './components';

import { EmailField } from '../components';

import { Email } from '../Merchants/components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 15
    },
    labelFilterRadio: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

const columns = [
    {
        id: 'date',
        label: 'Date',
        minWidth: '15%',
        align: 'left'
    },
    {
        id: 'numero',
        label: 'N°',
        minWidth: '15%',
        align: 'left'
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: '25%',
        align: 'left'
    },
    {
        id: 'facture',
        label: 'Facture',
        minWidth: '10%',
        align: 'center'
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: '30%',
        align: 'center'
    },
    {
        id: 'montant',
        label: 'Montant',
        minWidth: '5%',
        align: 'right'
    }
];

class LateBills extends React.Component {
    constructor(props) {
        super(props);

        const lastDayOfPreviousMonth = new Date();
        lastDayOfPreviousMonth.setDate(1);
        lastDayOfPreviousMonth.setHours(-1);

        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        this.state = {
            ...props,
            type: this.props.match.params.type || 'F',
            isSubmited: Boolean(this.props.match.params.start),
            searchValue: '',
            lastDayOfPreviousMonth: `${lastDayOfPreviousMonth.getFullYear()}-${
                lastDayOfPreviousMonth.getMonth() + 1 > 9
                    ? lastDayOfPreviousMonth.getMonth() + 1
                    : '0' + (lastDayOfPreviousMonth.getMonth() + 1)
            }-${lastDayOfPreviousMonth.getDate()}`,
            yesterday: `${yesterday.getFullYear()}-${
                yesterday.getMonth() + 1 > 9
                    ? yesterday.getMonth() + 1
                    : '0' + (yesterday.getMonth() + 1)
            }-${yesterday.getDate()}`,
            start: this.props.match.params.start || '',
            end: this.props.match.params.end || '',
            approvedDate: {
                start: new Date(this.props.match.params.start),
                end: new Date(this.props.match.params.end)
            },
            disableSearch: !Boolean(this.props.match.params.start),
            list: [],
            montant: 0,
            number: 0,
            options: {
                //weekday: 'numeric',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            },
            page: 1,
            numberOfPages: 0,
            openBill: false,
            bill: null,

            importedMails: [],
            selectedBillToNotify: '',
            notify: ''
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.match.params.start != this.props.match.params.start ||
            prevProps.match.params.end != this.props.match.params.end ||
            prevProps.match.params.type != this.props.match.params.type
        ) {
            this.setState({
                start: this.props.match.params.start || '',
                end: this.props.match.params.end || '',
                approvedDate: {
                    start: this.props.match.params.start
                        ? new Date(this.props.match.params.start)
                        : '',
                    end: this.props.match.params.end ? new Date(this.props.match.params.end) : ''
                },
                type: this.props.match.params.type || 'F',
                disableSearch: !Boolean(this.props.match.params.start),
                isSubmited: Boolean(this.props.match.params.start),
                list: []
            });
        }

        if (this.state.isSubmited) {
            this.getData();
        }

        (this.state.searchValue != prevState.searchValue ||
            this.state.page != prevState.page ||
            prevProps.match.params.type != this.props.match.params.type) &&
            this.getData();
    };

    componentDidMount = () => {
        this.state.isSubmited && this.getData();
    };

    getData = () => {
        this.setState({
            isSubmited: false
        });

        const { start, end, searchValue, type, page } = this.state;

        const formData = new FormData();
        formData.append('start', start);
        formData.append('end', end);
        formData.append('search', searchValue);
        formData.append('type', type);
        formData.append('page', page);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/finance/get-late-bills`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    sendData = (numFacture, moyen, banque = false, cheque = false) => {
        const formData = new FormData();
        formData.append('moyen', moyen);
        banque && formData.append('banque', banque);
        cheque && formData.append('cheque', cheque);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/update-bill/${numFacture}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                alert('Facture N°' + numFacture + ' réglée avec succès.');
                this.setState({
                    openBill: false,
                    bill: null
                });
                this.getData();
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    handleTypeFilter = ({ target: { value } }) => {
        const { start, end, lastDayOfPreviousMonth, type } = this.state;

        let validate_start = start;
        let validate_end = end;
        /*
                const _start = new Date(start);
                const _end = new Date(end);
                const _lastDayOfPreviousMonth = new Date(lastDayOfPreviousMonth);
        
                if (value == "F" && _start.getTime() > _lastDayOfPreviousMonth.getTime()) {
                    validate_start = `${_lastDayOfPreviousMonth.getFullYear()}-${(_lastDayOfPreviousMonth.getMonth() + 1) > 9 ? (_lastDayOfPreviousMonth.getMonth() + 1) : "0" + (_lastDayOfPreviousMonth.getMonth() + 1)}-${_lastDayOfPreviousMonth.getDate()}`;
                    alert(`La date de début est passée au ${_lastDayOfPreviousMonth.getDate()}/${(_lastDayOfPreviousMonth.getMonth() + 1) > 9 ? (_lastDayOfPreviousMonth.getMonth() + 1) : "0" + (_lastDayOfPreviousMonth.getMonth() + 1)}/${_lastDayOfPreviousMonth.getFullYear()}`)
                }
        
                if (value == "F" && _end.getTime() > _lastDayOfPreviousMonth.getTime()) {
                    validate_end = `${_lastDayOfPreviousMonth.getFullYear()}-${(_lastDayOfPreviousMonth.getMonth() + 1) > 9 ? (_lastDayOfPreviousMonth.getMonth() + 1) : "0" + (_lastDayOfPreviousMonth.getMonth() + 1)}-${_lastDayOfPreviousMonth.getDate()}`;
                    alert(`La date de fin est passée au ${_lastDayOfPreviousMonth.getDate()}/${(_lastDayOfPreviousMonth.getMonth() + 1) > 9 ? (_lastDayOfPreviousMonth.getMonth() + 1) : "0" + (_lastDayOfPreviousMonth.getMonth() + 1)}/${_lastDayOfPreviousMonth.getFullYear()}`)
                }
        */
        this.setState({
            type: value,
            page: 1
        });

        this.props.history.push('/late-bills/' + validate_start + '/' + validate_end + '/' + value);
    };

    handleDate = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    handleForm = () => {
        const { start, end, approvedDate, type } = this.state;

        if (start == '') {
            alert('Veuillez saisir une date de début');
            return;
        }

        if (end == '') {
            alert('Veuillez saisir une date de fin');
            return;
        }

        const _start = new Date(start);
        const _end = new Date(end);

        if (_start.getTime() > _end.getTime()) {
            let inverse = window.confirm(
                'La date de début est supérieur à la date de fin\nInverser les dates ?'
            );
            inverse &&
                this.setState({
                    start: end,
                    end: start
                });
            return;
        }

        this.setState({
            approvedDate: {
                start: _start,
                end: _end
            }
        });

        this.props.history.push('/late-bills/' + start + '/' + end + '/' + (type || 'all'));
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    formatCash = value => {
        let montants = parseFloat(value)
            .toString()
            .split('.');
        return (
            montants[0] +
            ',' +
            (montants[1] ? (montants[1] + '00')[0] + (montants[1] + '00')[1] : '00') +
            ' €'
        );
    };

    sendMissedData = (data, marchand_id, numFacture) => {
        const confirmation = window.confirm(
            "Cette action mettra à jour l'adresse mail du commerçant et l'enverra sur celle-ci.\nContinuer l'envoi ?"
        );

        if (confirmation) {
            const formData = new FormData();
            formData.append('field', 'mailMarchand');
            formData.append('data', data);

            fetch(
                `https://www.${
                    window.location.hostname.split('.')[1]
                        ? window.location.hostname.split('.')[0] === 'www'
                            ? window.location.hostname.split('.')[1]
                            : window.location.hostname.split('.')[0]
                        : 'dev'
                }.api.digitmarche.fr/api/merchant/update-fields-missed/${marchand_id}`,
                {
                    method: 'POST',
                    body: formData
                }
            )
                .then(res => res.json())
                .then(data => {
                    if (data) {
                        alert('Mise à jour effectuée.');
                        this.sendBill(numFacture);
                        this.getData();
                    }
                })
                .catch(console.log);
        }
    };

    getPdf = numFacture => {
        window.open(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/print/A/${numFacture}`,
            '_blank'
        );
    };

    handleCash = numFacture => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/get-bill/${numFacture}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    bill: data,
                    openBill: true
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    closeBill = () => {
        this.setState({
            bill: null,
            openBill: false
        });
    };

    getListPdf = () => {
        const { type, start, end } = this.state;

        let _confirm = window.confirm('Imprimer la sélection ?');
        _confirm &&
            window.open(
                `https://www.${
                    window.location.hostname.split('.')[1]
                        ? window.location.hostname.split('.')[0] === 'www'
                            ? window.location.hostname.split('.')[1]
                            : window.location.hostname.split('.')[0]
                        : 'dev'
                }.api.digitmarche.fr/api/regie/print-get-by-late-bills?type=${type}&start=${start}&end=${end}`,
                '_blank'
            );
    };

    sendBill = billNum => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/send-bill/${billNum}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                if (data.sent > 1) {
                    alert('Facture N°' + billNum + ' envoyée avec succès.');
                } else {
                    alert("Echec de l'envoi de la facture");
                }
            })
            .catch(
                error => {
                    console.error(error);
                    alert("Echec de l'envoi de la facture");
                } // Handle the error response object
            );
    };

    notify = (notify, importedMails, selectedBillToNotify) => {
        console.log(importedMails, selectedBillToNotify, notify);
        this.setState({
            notify: notify,
            importedMails: importedMails,
            selectedBillToNotify: selectedBillToNotify
        });

        this.props.history.push(window.location.pathname + '/notify');
    };

    render() {
        const {
            classes,
            searchValue,
            start,
            end,
            approvedDate,
            disableSearch,
            options,
            list,
            type,
            page,
            numberOfPages,
            number,
            montant,
            openBill,
            bill,
            lastDayOfPreviousMonth,
            yesterday,
            importedMails,
            notify,
            selectedBillToNotify
        } = this.state;

        const { history } = this.props;

        const limitDate = `${yesterday.split('-')[2]}/${yesterday.split('-')[1]}/${
            yesterday.split('-')[0]
        }`;

        return (
            <div className={classes.root}>
                {bill && (
                    <Bill
                        data={bill}
                        sendData={this.sendData}
                        close={this.closeBill}
                        open={openBill}
                    />
                )}

                {this.props.match.params.notify == 'notify' && (
                    <Email
                        selectedBillToNotify={selectedBillToNotify}
                        importedMails={importedMails}
                        history={history}
                        notify={notify}
                        open={true}
                    />
                )}

                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Paper className={classes.paperContent}>
                            <FormControl component="fieldset" required>
                                <FormLabel className={classes.labelFilter} component="label">
                                    Rechercher par date (jusqu'au {limitDate})
                                </FormLabel>
                            </FormControl>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                spacing={2}>
                                <Grid item xs={12} md={5}>
                                    <TextField
                                        type="date"
                                        fullWidth
                                        name="start"
                                        value={start}
                                        onChange={this.handleDate}
                                        size="small"
                                        variant="outlined"
                                        inputProps={{
                                            max: yesterday
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <TextField
                                        type="date"
                                        fullWidth
                                        size="small"
                                        name="end"
                                        value={end}
                                        onChange={this.handleDate}
                                        variant="outlined"
                                        inputProps={{
                                            max: yesterday
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        onClick={this.handleForm}>
                                        Rechercher
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" spacing={3}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TotalLate data={{ montant: montant }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TotalBills data={{ number: number }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paperContent}>
                                    <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="stretch"
                                        spacing={3}>
                                        <Grid item>
                                            <TextField
                                                label={
                                                    !disableSearch &&
                                                    `Rechercher une facture du ${approvedDate.start.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    )} au ${approvedDate.end.toLocaleDateString(
                                                        'fr-FR',
                                                        options
                                                    )}`
                                                }
                                                type="text"
                                                fullWidth
                                                value={searchValue}
                                                disabled={disableSearch}
                                                onChange={this.handleSearch}
                                                variant="outlined"
                                                helperText={
                                                    !disableSearch &&
                                                    'Saisir un numéro de facture, un SIREN, une raison sociale ou une enseigne'
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                disabled={disableSearch}
                                                                onClick={this.clearSearch}
                                                                edge="end">
                                                                <ClearIcon
                                                                    style={{ color: 'red' }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                container
                                                spacing={2}
                                                direction="row"
                                                alignItems="flex-end"
                                                justify="space-between">
                                                <Grid item>
                                                    <FormControl
                                                        component="fieldset"
                                                        disabled={disableSearch}>
                                                        <FormLabel
                                                            component="legend"
                                                            className={classes.labelFilterRadio}>
                                                            Type du commerçant
                                                        </FormLabel>
                                                        <RadioGroup
                                                            row
                                                            name="type"
                                                            value={type}
                                                            onChange={this.handleTypeFilter}>
                                                            {/*<FormControlLabel value="all" control={<Radio />} label="Tous" />*/}
                                                            <FormControlLabel
                                                                value="F"
                                                                control={<Radio />}
                                                                label="Titulaire abonné"
                                                            />
                                                            <FormControlLabel
                                                                value="V"
                                                                control={<Radio />}
                                                                label="Titulaire non abonné"
                                                            />
                                                            <FormControlLabel
                                                                value="E"
                                                                control={<Radio />}
                                                                label="Passager"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="row" spacing={2}>
                                                        <Grid item>
                                                            <Button
                                                                disabled={disableSearch}
                                                                endIcon={<MailIcon />}
                                                                component={RouterLink}
                                                                to={`/reminder/late_bill/${
                                                                    type == 'F' ? 't' : 'p'
                                                                }/${start}/${end}`}
                                                                color="primary">
                                                                Notifier par mail
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                disabled={disableSearch}
                                                                endIcon={<PrintIcon />}
                                                                onClick={this.getListPdf}
                                                                color="primary">
                                                                Imprimer
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                        <Grid item>
                                            <TableContainer className={classes.container}>
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {columns.map(column => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{
                                                                        minWidth: column.minWidth
                                                                    }}>
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {list.map(bill => {
                                                            return (
                                                                <TableRow hover key={bill.numero}>
                                                                    {columns.map(column => {
                                                                        if (
                                                                            column.id == 'facture'
                                                                        ) {
                                                                            return (
                                                                                <TableCell
                                                                                    key={
                                                                                        bill.numero
                                                                                    }
                                                                                    align={
                                                                                        column.align
                                                                                    }>
                                                                                    <Grid
                                                                                        container
                                                                                        direction="row"
                                                                                        justify="center"
                                                                                        alignItems="center">
                                                                                        <Grid item>
                                                                                            <IconButton
                                                                                                onClick={() =>
                                                                                                    this.getPdf(
                                                                                                        bill.numero
                                                                                                    )
                                                                                                }>
                                                                                                <DescriptionIcon />
                                                                                            </IconButton>
                                                                                        </Grid>
                                                                                        <Grid item>
                                                                                            <Button
                                                                                                color="primary"
                                                                                                variant="contained"
                                                                                                onClick={() =>
                                                                                                    this.handleCash(
                                                                                                        bill.numero
                                                                                                    )
                                                                                                }
                                                                                                size="small">
                                                                                                Encaisser
                                                                                            </Button>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </TableCell>
                                                                            );
                                                                        }

                                                                        /*if (column.id == "mail") {
                                                                                    return (
                                                                                        <TableCell key={bill.numero} align={column.align}>
                                                                                            {
                                                                                                bill.mail == "0" ?
                                                                                                    <Field sendData={this.sendMissedData} data={bill} /> :
                                                                                                    <Button onClick={() => this.sendBill(bill.numero)} size="small" variant="contained" color="primary" endIcon={<SendIcon />}>
                                                                                                        Envoyer
                                                                                                    </Button>
                                                                                            }
                                                                                        </TableCell>
                                                                                    );
                                                                                }*/

                                                                        if (
                                                                            column.id == 'montant'
                                                                        ) {
                                                                            return (
                                                                                <TableCell
                                                                                    key={
                                                                                        bill.numero
                                                                                    }
                                                                                    align={
                                                                                        column.align
                                                                                    }>
                                                                                    {
                                                                                        bill[
                                                                                            column
                                                                                                .id
                                                                                        ]
                                                                                    }{' '}
                                                                                    €
                                                                                </TableCell>
                                                                            );
                                                                        }

                                                                        if (column.id == 'action') {
                                                                            return (
                                                                                <TableCell
                                                                                    key={`${bill.numero}_${column.id}`}
                                                                                    align={
                                                                                        column.align
                                                                                    }>
                                                                                    {bill.mail !=
                                                                                    '0' ? (
                                                                                        <Button
                                                                                            //component={RouterLink}
                                                                                            //to={`/merchants/alert-data-assurance/notify`}
                                                                                            size="small"
                                                                                            onClick={() =>
                                                                                                this.notify(
                                                                                                    bill.notify,
                                                                                                    [
                                                                                                        {
                                                                                                            id:
                                                                                                                bill.merchant_id,
                                                                                                            raisonSociale:
                                                                                                                bill.raisonSociale
                                                                                                        }
                                                                                                    ],
                                                                                                    bill.numero
                                                                                                )
                                                                                            }
                                                                                            endIcon={
                                                                                                <MailOutlineIcon />
                                                                                            }
                                                                                            variant="contained"
                                                                                            color="primary">
                                                                                            Notifier
                                                                                        </Button>
                                                                                    ) : (
                                                                                        <EmailField
                                                                                            refresh={
                                                                                                this
                                                                                                    .getData
                                                                                            }
                                                                                            merchantId={
                                                                                                bill.merchant_id
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                </TableCell>
                                                                            );
                                                                        }

                                                                        return (
                                                                            <TableCell
                                                                                key={bill.numero}
                                                                                align={
                                                                                    column.align
                                                                                }>
                                                                                {bill[column.id]}
                                                                            </TableCell>
                                                                        );
                                                                    })}
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item>
                                            <Pagination
                                                numberOfPages={numberOfPages}
                                                currentPage={page}
                                                handlePagination={this.handlePagination}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(LateBills);
