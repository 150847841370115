import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Dialog, DialogActions, DialogContent, Button } from '@material-ui/core';

import SendIcon from '@material-ui/icons/Send';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

import {
    DialogTitle,
    TabControl,
    FormDialogContent,
    TableDialogContent,
    Loader,
    Done
} from './components';

const styles = theme => ({
    root: {
        flexGrow: 1
    }
});

class Reminder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            reminder: this.props.match.params.reminder,
            type: this.props.match.params.type,
            start: this.props.match.params.start || '',
            end: this.props.match.params.end || '',
            title: '',
            step: 'template',
            subject: '',
            message: '',
            choosenFilter: 'all',
            isSending: false,
            stats: [],
            link: ''
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { isSending } = this.state;

        if (isSending && isSending != prevState.isSending) {
            this.send();
        }
    };

    handleStep = value => {
        this.setState({
            step: value,
            isSending: Boolean(value == 'loader')
        });
    };

    handleData = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    send = () => {
        const { subject, message, choosenFilter, start, end, reminder } = this.state;

        const formData = new FormData();
        formData.append('subject', subject);
        formData.append('message', message);
        formData.append('choosenFilter', choosenFilter);
        formData.append('start', start);
        formData.append('end', end);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/email/send-remind/${reminder}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    isSending: false,
                    step: 'done',
                    ...data
                });
            })
            .catch(error => {
                this.setState({
                    isSending: false,
                    step: 'table'
                });
                console.error(error);
                alert("Echec de l'envoi des rappels.");
            });
    };

    render() {
        const {
            classes,
            step,
            reminder,
            choosenFilter,
            message,
            subject,
            title,
            type,
            start,
            end,
            link,
            stats
        } = this.state;

        return (
            <Dialog fullWidth maxWidth={'sm'} onClose={this.getBack} open>
                <DialogTitle step={step} onClose={this.getBack}>
                    Rappel : {title}
                </DialogTitle>
                <DialogContent dividers>
                    <TabControl value={step} index={'template'}>
                        <FormDialogContent
                            title={title}
                            message={message}
                            subject={subject}
                            reminder={reminder}
                            setData={this.handleData}
                        />
                    </TabControl>
                    <TabControl value={step} index={'table'}>
                        <TableDialogContent
                            start={start}
                            end={end}
                            type={type}
                            reminder={reminder}
                            choosenFilter={choosenFilter}
                            setData={this.handleData}
                        />
                    </TabControl>
                    <TabControl value={step} index={'loader'}>
                        <Loader />
                    </TabControl>
                    <TabControl value={step} index={'done'}>
                        <Done
                            data={{
                                stats: stats,
                                link: link
                            }}
                        />
                    </TabControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        onClick={this.getBack}
                        disabled={Boolean(step == 'loader')}>
                        Fermer
                    </Button>
                    <Button
                        disabled={Boolean(step == 'template' || step == 'loader')}
                        variant="contained"
                        onClick={() => this.handleStep(step == 'done' ? 'table' : 'template')}
                        color="primary"
                        startIcon={<SkipPreviousIcon />}>
                        étape précédente
                    </Button>
                    {step == 'template' ? (
                        <Button
                            variant="contained"
                            onClick={() => this.handleStep('table')}
                            color="primary"
                            endIcon={<SkipNextIcon />}>
                            étape suivante
                        </Button>
                    ) : (
                        <Button
                            disabled={Boolean(step == 'done')}
                            variant="contained"
                            onClick={() => this.handleStep('loader')}
                            color="primary"
                            endIcon={<SendIcon />}>
                            Envoyer
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Reminder);
