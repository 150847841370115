import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
// add
import WorkIcon from '@material-ui/icons/Work';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  labelAction: {
    width: '100%',
    textAlign: 'center'
  }
}));

const TotalProfession = props => {
  const { className, elevation, data, ...rest } = props;

  const classes = useStyles();

  return (

    <CardActionArea component={RouterLink} to="/settings/professions/add">
      <Card
        {...rest}
        elevation={elevation}
        className={clsx(classes.root, className)}
      >
        <CardContent>
          <Grid
            container
            justify="space-between"
          >
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body1"
              >
                Métiers enregistrés
            </Typography>
              <Typography variant="h3" gutterBottom>{data.nombre}</Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <WorkIcon className={classes.icon} />
              </Avatar>
            </Grid>
          </Grid>
          <div className={classes.difference}>
            <Typography
              gutterBottom
              className={classes.caption}
              variant="body2"
            >
              {'Dernier métier créé "' + data.last.libelleMetier + '" le ' + data.last.dateCreation}
            </Typography>
          </div>
          <div className={classes.difference}>
            <Typography
              className={classes.labelAction}
              variant="subtitle2"
              color="primary"
            >
              Créer un nouveau métier
            </Typography>
          </div>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

export default TotalProfession;
