import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';

import {
    Grid,
    FormControlLabel,
    Checkbox,
    Paper
} from '@material-ui/core';

import { MiniForm } from './components'

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        width: "36%",
        position: "absolute",
        right: "50%",
        transform: "translateX(50%)",
        zIndex: 1000,
        padding: theme.spacing(0.5),
        marginTop: theme.spacing(2),
    }
}));


const AdresseBar = props => {

    const {
        className,
        marketData,
        updateData,
        showPos,
        updateShowPos,
        ...rest
    } = props;

    const classes = useStyles();
    const theme = useTheme();


    return (
        <Paper className={classes.root}>
            <MiniForm
                showPos={showPos}
                updateShowPos={updateShowPos}
                updateData={updateData}
                data={marketData}
            />
        </Paper>
    );
};

export default AdresseBar;
