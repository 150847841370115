import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Button, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {}
}));

const Periodicity = props => {
    const { className, handlePeriodicity, frequency, ...rest } = props;

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Button
                    variant={Boolean(frequency == 1) ? 'contained' : 'outlined'}
                    onClick={() => handlePeriodicity(1)}
                    color="primary">
                    Mensuelle
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant={Boolean(frequency == 2) ? 'contained' : 'outlined'}
                    onClick={() => handlePeriodicity(2)}
                    color="primary">
                    Bimensuelle
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant={Boolean(frequency == 3) ? 'contained' : 'outlined'}
                    onClick={() => handlePeriodicity(3)}
                    color="primary">
                    Trimestrielle
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant={Boolean(frequency == 6) ? 'contained' : 'outlined'}
                    onClick={() => handlePeriodicity(6)}
                    color="primary">
                    Semestrielle
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant={Boolean(frequency == 12) ? 'contained' : 'outlined'}
                    onClick={() => handlePeriodicity(12)}
                    color="primary">
                    Annuelle
                </Button>
            </Grid>
        </Grid>
    );
};

export default Periodicity;
