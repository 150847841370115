import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Grid,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableRow,
    Typography,
    TextField,
    MenuItem,
    Button,
    InputAdornment
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import { FileInput, EditPointForm, HistoryDialog } from './components';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    head: {
        background: 'rgb(192,226,251,0.35)',
        borderBottom: '1px solid #c0e2fb'
    },
    cellKabis: {
        borderLeft: '7px solid #c0e2fb'
    }
});

const activities = [
    {
        id: 0,
        key: 'none',
        label: '',
        color: 'black'
    },
    {
        id: 1,
        key: 'alim',
        label: 'Alimentaire',
        color: 'yellow'
    },
    {
        id: 2,
        key: 'prim',
        label: 'Primeur',
        color: 'green'
    },
    {
        id: 3,
        key: 'manu',
        label: 'Manufacturé',
        color: 'red'
    },
    {
        id: 4,
        key: 'dempos',
        label: 'Démonstrateur/Posticheur',
        color: 'purple'
    }
];

class Profil extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            tab: 'profil',
            marchand_id: '',
            siret: this.props.siret,
            dateCreation: '',
            siren: '',
            raisonSociale: '',
            nom: '',
            prenom: '',
            adresse: '',
            cp: '',
            ville: '',
            tva: '',
            formeJuridique: '',
            codeNaf: '',
            libelleNaf: '',
            dateCreationSiren: '',
            dateCreationSiret: '',
            enseigne: '',
            metres: '',
            telephone: '',
            mail: '',
            internet: '',
            assurance: '',
            numeroPoliceAssurance: '',
            dateFinAssurance: '',
            activite_id: 0,
            formatedDateFinAssurance: '',
            aot: '',
            aotFile: null,
            aotUrl: '',
            wasModified: false,
            assuranceFile: null,
            assuranceUrl: '',
            assuranceWasModified: false,
            assurancePerimee: false,
            metier: null,
            arrivedAt: '',
            holderDate: '',
            merchantCardDate: '',
            metiers: {
                0: []
            },
            points: 0,
            currentPoints: 0,
            openPointHistory: false,
            openEditPoint: false
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        const { siret } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-profil/${siret}?expand=more`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    currentPoints: data.points,
                    wasModified: false,
                    assuranceWasModified: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    update = () => {
        const {
            enseigne,
            metres,
            telephone,
            mail,
            internet,
            assurance,
            numeroPoliceAssurance,
            dateFinAssurance,
            activite_id,
            metier,
            marchand_id,
            aot,
            aotFile,
            wasModified,
            assuranceFile,
            assuranceWasModified,
            assuranceUrl,
            arrivedAt,
            merchantCardDate,
            points,
            currentPoints,
            holderDate
        } = this.state;

        let comment = false;

        if (activite_id == 0) {
            alert('Veuillez renseigner une activité');
            return;
        }

        if (!metier) {
            alert('Veuillez renseigner un métier');
            return;
        }

        if (points != currentPoints) {
            comment = window.prompt(
                'Veuillez saisir un commentaire qui justifie le changement de points.'
            );
            if (!comment) {
                return;
            }
        }

        const formData = new FormData();
        formData.append('activite_id', activite_id);
        formData.append('metier_id', metier.id);
        formData.append('tel', telephone);
        formData.append('link', internet);
        formData.append('mail', mail);
        //formData.append("assureur", assurance);
        formData.append('dateFinAssurance', dateFinAssurance);
        //formData.append("numeroPolice", numeroPoliceAssurance);
        formData.append('metres', metres);
        formData.append('enseigne', enseigne);
        formData.append('aot', aot);
        formData.append('aotFile', aotFile);
        formData.append('aotFileWasModified', wasModified);
        formData.append('assuranceFile', assuranceFile);
        formData.append('assuranceFileWasModified', assuranceWasModified);
        formData.append('points', points);
        formData.append('merchantCardDate', merchantCardDate);
        comment && formData.append('comment', comment);
        arrivedAt != '' && formData.append('arrivedAt', arrivedAt);
        holderDate != '' && formData.append('holderDate', holderDate);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/update/${marchand_id}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                if (data) {
                    alert('Mise à jour effectuée');
                    this.props.updateMerchant();
                    this.props.updateCompteur();
                    this.getData();
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleData = ({ target: { name, value } }) => {
        if (name == 'activite_id') {
            this.setState({
                metier: null
            });
        }
        if (name != 'points') {
            this.setState({
                [name]: value
            });
        } else if (value > -1) {
            this.setState({
                [name]: value
            });
        }
    };

    handleMetier = (event, value) => {
        this.setState({
            metier: value
        });
    };

    handleFileAot = (name, file) => {
        this.setState({
            aotFile: file,
            wasModified: file ? true : false
        });
    };

    handleFileAssurance = (name, file) => {
        this.setState({
            assuranceFile: file,
            assuranceWasModified: file ? true : false
        });
    };

    seeFile = url => {
        window.open('https://' + url, '_blank');
    };

    removeFileAot = () => {
        const _confirm = window.confirm("Êtes-vous de vouloir supprimer l'AOT du commerçant ?");

        _confirm &&
            this.setState({
                aotFile: null,
                wasModified: true,
                aotUrl: ''
            });

        _confirm &&
            alert(
                'Afin que les changements soient validés vous devez mettre à jour le commerçant.'
            );
    };

    removeFileAssurance = () => {
        const _confirm = window.confirm(
            "Êtes-vous de vouloir supprimer le document d'assurance du commerçant ?"
        );

        _confirm &&
            this.setState({
                assuranceFile: null,
                assuranceWasModified: true,
                assuranceUrl: ''
            });

        _confirm &&
            alert(
                'Afin que les changements soient validés vous devez mettre à jour le commerçant.'
            );
    };

    handleCloseEditPoints = () => {
        const { openEditPoint } = this.state;
        this.getData();
        this.setState({ openEditPoint: !openEditPoint });
    };

    render() {
        const {
            classes,
            siret,
            siren,
            nom,
            prenom,
            adresse,
            cp,
            ville,
            tva,
            formeJuridique,
            codeNaf,
            libelleNaf,
            dateCreationSiren,
            dateCreationSiret,
            enseigne,
            metres,
            telephone,
            mail,
            internet,
            assurance,
            numeroPoliceAssurance,
            dateFinAssurance,
            formatedDateFinAssurance,
            assurancePerimee,
            activite_id,
            metier,
            metiers,
            dateCreation,
            aot,
            aotUrl,
            aotFile,
            wasModified,
            assuranceFile,
            assuranceWasModified,
            assuranceUrl,
            arrivedAt,
            merchantCardDate,
            points,
            openEditPoint,
            openPointHistory,
            marchand_id,
            holderDate
        } = this.state;

        return (
            <>
                <HistoryDialog
                    id={marchand_id}
                    open={openPointHistory}
                    onClose={() => this.setState({ openPointHistory: !openPointHistory })}
                />
                <EditPointForm
                    id={marchand_id}
                    open={openEditPoint}
                    pts={points}
                    onClose={this.handleCloseEditPoints}
                />
                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow hover key="infokabis">
                                        <TableCell
                                            className={classes.head}
                                            colSpan={2}
                                            align="left">
                                            <Typography color="secondary" variant="subtitle1">
                                                Informations administratives
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover key="siren">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Siren
                                        </TableCell>
                                        <TableCell align="left">{siren}</TableCell>
                                    </TableRow>
                                    <TableRow hover key="siret">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Siret
                                        </TableCell>
                                        <TableCell align="left">{siret}</TableCell>
                                    </TableRow>

                                    <TableRow hover key="nom">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Nom du dirigeant
                                        </TableCell>
                                        <TableCell align="left">{nom}</TableCell>
                                    </TableRow>

                                    <TableRow hover key="prenom">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Prénom du dirigeant
                                        </TableCell>
                                        <TableCell align="left">{prenom}</TableCell>
                                    </TableRow>

                                    <TableRow hover key="adresse">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Adresse
                                        </TableCell>
                                        <TableCell align="left">{adresse}</TableCell>
                                    </TableRow>

                                    <TableRow hover key="codePostal">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Code postal
                                        </TableCell>
                                        <TableCell align="left">{cp}</TableCell>
                                    </TableRow>

                                    <TableRow hover key="ville">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Ville
                                        </TableCell>
                                        <TableCell align="left">{ville}</TableCell>
                                    </TableRow>

                                    <TableRow hover key="tva">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            TVA Intra.
                                        </TableCell>
                                        <TableCell align="left">{tva}</TableCell>
                                    </TableRow>

                                    <TableRow hover key="dateCreationSiren">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Date de création du siren
                                        </TableCell>
                                        <TableCell align="left">{dateCreationSiren}</TableCell>
                                    </TableRow>

                                    <TableRow hover key="dateCreationSiret">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Date de création du siret
                                        </TableCell>
                                        <TableCell align="left">{dateCreationSiret}</TableCell>
                                    </TableRow>

                                    <TableRow hover key="codeNaf">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Code NAF
                                        </TableCell>
                                        <TableCell align="left">{codeNaf}</TableCell>
                                    </TableRow>

                                    <TableRow hover key="labelNaf">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Libellé NAF
                                        </TableCell>
                                        <TableCell align="left">{libelleNaf}</TableCell>
                                    </TableRow>

                                    <TableRow hover key="formeJuridique">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Forme juridique
                                        </TableCell>
                                        <TableCell align="left">{formeJuridique}</TableCell>
                                    </TableRow>

                                    <TableRow hover key="dateCreation">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Date de création
                                        </TableCell>
                                        <TableCell align="left">{dateCreation}</TableCell>
                                    </TableRow>

                                    <TableRow hover key="arrivedAt">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Date d'arrivée sur le marché
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                fullWidth
                                                name="arrivedAt"
                                                type="date"
                                                onChange={this.handleData}
                                                value={arrivedAt ? arrivedAt : ''}
                                                variant="outlined"
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow hover key="merchantCardDate">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Date de la carte du commerçant
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                fullWidth
                                                name="merchantCardDate"
                                                type="date"
                                                onChange={this.handleData}
                                                value={merchantCardDate ? merchantCardDate : ''}
                                                variant="outlined"
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow hover key="arrivedAt">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Date de titularisation
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                fullWidth
                                                name="holderDate"
                                                type="date"
                                                onChange={this.handleData}
                                                value={holderDate ? holderDate : ''}
                                                variant="outlined"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow hover key="infokabis">
                                        <TableCell
                                            className={classes.head}
                                            colSpan={2}
                                            align="left">
                                            <Typography color="secondary" variant="subtitle1">
                                                Caractéristiques du commerçant
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover key="aot">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            AOT
                                        </TableCell>
                                        <TableCell align="left">
                                            <Grid container spacing={2} direction="row">
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        name="aot"
                                                        type="text"
                                                        onChange={this.handleData}
                                                        value={aot ? aot : ''}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        alignItems="center"
                                                        justify="space-between"
                                                        spacing={2}>
                                                        <Grid item>
                                                            <FileInput
                                                                setFile={this.handleFileAot}
                                                                wasModified={wasModified}
                                                                name="aotFile"
                                                                file={aotFile}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                spacing={1}>
                                                                <Grid item>
                                                                    <Button
                                                                        onClick={() =>
                                                                            this.seeFile(aotUrl)
                                                                        }
                                                                        color="primary"
                                                                        variant="contained"
                                                                        startIcon={
                                                                            <AttachFileIcon />
                                                                        }
                                                                        disabled={Boolean(
                                                                            aotUrl == ''
                                                                        )}>
                                                                        Visualiser l'AOT enregistré
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        disabled={Boolean(
                                                                            aotUrl == ''
                                                                        )}
                                                                        onClick={this.removeFileAot}
                                                                        color="primary"
                                                                        variant="contained"
                                                                        startIcon={<DeleteIcon />}
                                                                        style={
                                                                            !Boolean(aotUrl == '')
                                                                                ? {
                                                                                      background:
                                                                                          'red'
                                                                                  }
                                                                                : {}
                                                                        }>
                                                                        Supprimer
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover key="enseigne">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Enseigne
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                fullWidth
                                                name="enseigne"
                                                type="text"
                                                onChange={this.handleData}
                                                value={enseigne ? enseigne : ''}
                                                variant="outlined"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover key="activite">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            <Grid container direction="column">
                                                <Grid item>Activité</Grid>
                                                <Grid item>
                                                    <Typography
                                                        variant="body2"
                                                        style={{ color: 'red' }}>
                                                        Champ obligatoire
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                fullWidth
                                                name="activite_id"
                                                select
                                                value={activite_id}
                                                onChange={this.handleData}
                                                variant="outlined">
                                                {activities.map(
                                                    (act, index) =>
                                                        act.id > 0 && (
                                                            <MenuItem key={act.id} value={act.id}>
                                                                {act.label}
                                                            </MenuItem>
                                                        )
                                                )}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover key="metier">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            <Grid container direction="column">
                                                <Grid item>Métier</Grid>
                                                <Grid item>
                                                    <Typography
                                                        variant="body2"
                                                        style={{ color: 'red' }}>
                                                        Champ obligatoire
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Autocomplete
                                                options={metiers[activite_id]}
                                                name="metier"
                                                autoHighlight
                                                value={metier}
                                                onChange={this.handleMetier}
                                                noOptionsText="Métier non référencé"
                                                getOptionLabel={option => option.libelle}
                                                getOptionSelected={(option, value) =>
                                                    option.id == value.id
                                                }
                                                renderInput={params => (
                                                    <TextField
                                                        fullWidth
                                                        {...params}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover key="metres">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Métrage souhaité
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                fullWidth
                                                name="metres"
                                                select
                                                value={metres}
                                                onChange={this.handleData}
                                                variant="outlined">
                                                <MenuItem key={0} value={0}>
                                                    Pas de préférence
                                                </MenuItem>
                                                {[...Array(40).keys()].map(
                                                    nbr =>
                                                        nbr + 1 > 1 && (
                                                            <MenuItem key={nbr + 1} value={nbr + 1}>
                                                                {nbr + 1}
                                                            </MenuItem>
                                                        )
                                                )}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover key="points">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            <Grid container direction="column">
                                                <Grid item>Nombre de points</Grid>
                                                {this.props.userProfile.getRoleId() != 1 && (
                                                    <Grid item>
                                                        <Typography
                                                            variant="body2"
                                                            style={{ color: 'red' }}>
                                                            Vous devez être connecté en tant
                                                            qu'administrateur pour modifier ce champ
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                                spacing={2}>
                                                <Grid item>
                                                    <TextField
                                                        disabled={Boolean(
                                                            this.props.userProfile.getRoleId() != 1
                                                        )}
                                                        fullWidth
                                                        name="points"
                                                        type="number"
                                                        onChange={this.handleData}
                                                        value={points}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        onClick={() =>
                                                            this.setState({
                                                                openEditPoint: !openEditPoint
                                                            })
                                                        }
                                                        disabled={Boolean(
                                                            this.props.userProfile.getRoleId() != 1
                                                        )}
                                                        variant="contained"
                                                        color="primary">
                                                        Editer le nombre de points
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        onClick={() =>
                                                            this.setState({
                                                                openPointHistory: !openPointHistory
                                                            })
                                                        }
                                                        variant="contained"
                                                        color="primary">
                                                        Visualiser l'historique des points
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow hover key="contact">
                                        <TableCell
                                            className={classes.head}
                                            colSpan={2}
                                            align="left">
                                            <Typography color="secondary" variant="subtitle1">
                                                Contact du commerçant
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover key="telephone">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Téléphone
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                fullWidth
                                                name="telephone"
                                                type="text"
                                                onChange={this.handleData}
                                                value={telephone ? telephone : ''}
                                                variant="outlined"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover key="mail">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Mail
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                fullWidth
                                                name="mail"
                                                type="text"
                                                onChange={this.handleData}
                                                value={mail ? mail : ''}
                                                variant="outlined"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover key="internet">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Lien internet
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                fullWidth
                                                name="internet"
                                                type="text"
                                                onChange={this.handleData}
                                                value={internet ? internet : ''}
                                                variant="outlined"
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow hover key="assureur">
                                        <TableCell
                                            className={classes.head}
                                            colSpan={2}
                                            align="left">
                                            <Typography color="secondary" variant="subtitle1">
                                                Données assurance du commerçant
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover key="dateFinAssurance">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            <Grid container direction="column">
                                                <Grid item>Date de fin de validité</Grid>
                                                {assurancePerimee == '1' && (
                                                    <Grid item>
                                                        <Typography
                                                            variant="body2"
                                                            style={{ color: 'red' }}>
                                                            {formatedDateFinAssurance} date de fin
                                                            de validité dépassée
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                fullWidth
                                                name="dateFinAssurance"
                                                type="date"
                                                onChange={this.handleData}
                                                value={dateFinAssurance ? dateFinAssurance : ''}
                                                variant="outlined"
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover key="telephone">
                                        <TableCell
                                            className={classes.cellKabis}
                                            component="th"
                                            scope="row">
                                            Document assurance
                                        </TableCell>
                                        <TableCell align="left">
                                            <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                                justify="space-between"
                                                spacing={2}>
                                                <Grid item>
                                                    <FileInput
                                                        setFile={this.handleFileAssurance}
                                                        wasModified={assuranceWasModified}
                                                        name="assuranceFile"
                                                        file={assuranceFile}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Grid container direction="row" spacing={1}>
                                                        <Grid item>
                                                            <Button
                                                                onClick={() =>
                                                                    this.seeFile(assuranceUrl)
                                                                }
                                                                color="primary"
                                                                variant="contained"
                                                                startIcon={<AttachFileIcon />}
                                                                disabled={Boolean(
                                                                    assuranceUrl == ''
                                                                )}>
                                                                Visualiser le document enregistré
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                disabled={Boolean(
                                                                    assuranceUrl == ''
                                                                )}
                                                                onClick={this.removeFileAssurance}
                                                                color="primary"
                                                                variant="contained"
                                                                startIcon={<DeleteIcon />}
                                                                style={
                                                                    !Boolean(assuranceUrl == '')
                                                                        ? { background: 'red' }
                                                                        : {}
                                                                }>
                                                                Supprimer
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        {/*<TableCell align="left">
                                        <TextField
                                            fullWidth
                                            name="assurance"
                                            type="text"
                                            onChange={this.handleData}
                                            value={assurance ? assurance : ""}
                                            variant="outlined"
                                        />
                                    </TableCell>*/}
                                    </TableRow>
                                    {/*<TableRow hover key="numero">
                                    <TableCell className={classes.cellKabis} component="th" scope="row">
                                        Numéro de police
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField
                                            fullWidth
                                            name="numeroPoliceAssurance"
                                            type="text"
                                            onChange={this.handleData}
                                            value={numeroPoliceAssurance ? numeroPoliceAssurance : ""}
                                            variant="outlined"
                                        />
                                    </TableCell>
                                        </TableRow>*/}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item style={{ textAlign: 'center', marginTop: 20 }}>
                        <Button onClick={this.update} variant="contained" color="primary">
                            Mettre à jour
                        </Button>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withStyles(styles)(withRouter(Profil));
