import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
    FormControl,
    FormLabel,
    Button,
    TextField,
    Grid
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

const styles = (theme) => ({
    labelFilter: {
        color: "#3f51b5",
        marginBottom: 15
    },
});

const DateInput = withStyles(styles)((props) => {
    const { children, classes, start, end, fncs, ...other } = props;

    const handleDate = ({ target: { name, value } }) => {
        fncs.handleDate(name, value)
    }

    const handleDateForm = () => {
        if (!start) {
            alert("Veuillez saisir une date de début.");
            return;
        }

        if (!end) {
            alert("Veuillez saisir une date de fin.");
            return;
        }

        const _start = new Date(start);
        const _end = new Date(end);

        if (_start.getTime() > _end.getTime()) {
            let inverse = window.confirm("La date de début est supérieur à la date de fin\nInverser les dates ?");
            if (inverse) {
                fncs.handleDate("start", end);
                fncs.handleDate("end", start);
            }
            return;
        }

        fncs.search();
    }

    return (
        <div>
            <FormControl component="fieldset">
                <FormLabel
                    className={classes.labelFilter}
                    component="label"
                >
                    Rechercher par date
                </FormLabel>
            </FormControl>
            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <Grid item xs={5} md={5}>
                    <TextField
                        type="date"
                        fullWidth
                        name="start"
                        value={start}
                        onChange={handleDate}
                        size="small"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={5} md={5}>
                    <TextField
                        type="date"
                        fullWidth
                        size="small"
                        name="end"
                        value={end}
                        onChange={handleDate}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={2} md={2}>
                    <Button variant="contained" fullWidth color="primary" onClick={handleDateForm}>
                        <SearchIcon />
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
});


export default DateInput;