import React, { useState, useEffect } from 'react';
import { Button, Grid, CircularProgress, Typography, Divider } from '@material-ui/core';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import { Dialog } from 'components';
import PropTypes from 'prop-types';
import { FormDraw, TableDraw } from './components';
import { empty } from 'common/validators';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

/**
 * url
 */
const url = `https://www.${
    window.location.hostname.split('.')[1]
        ? window.location.hostname.split('.')[0] === 'www'
            ? window.location.hostname.split('.')[1]
            : window.location.hostname.split('.')[0]
        : 'dev'
}.api.digitmarche.fr/api`;

/**
 *
 * @param {*} journalMarketId
 * @returns
 */
async function getActivities(journalMarketId) {
    const result = await fetch(
        `${url}/placement/get-activities-before-drawing/${journalMarketId}`,
        {
            method: 'GET'
        }
    )
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw "Une erreur est survenue lors de l'affichage des activités.";
        });

    return result;
}

/**
 *
 * @param {} $journalMarketId
 * @param {*} activities
 * @returns
 */
async function launchDraw(journalMarketId, activities) {
    const formData = new FormData();
    formData.append('activities', JSON.stringify(activities));

    const result = await fetch(`${url}/placement/launch-draw/${journalMarketId}`, {
        method: 'POST',
        body: formData
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw 'Une erreur est survenue lors du tirage au sort.';
        });

    return result;
}

/**
 *
 */
const LoadingIcon = <CircularProgress fontSize={25} />;
const SuccessIcon = <CheckCircleOutlineIcon fontSize={'large'} style={{ color: 'green' }} />;
const ErrorIcon = <HighlightOffIcon fontSize={'large'} color="error" />;
const InfoIcon = <InfoOutlinedIcon fontSize={'large'} style={{ color: 'orange' }} />;

const DrawDialog = props => {
    const { open, close, id } = props;
    const [launched, setLaunched] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [icon, setIcon] = useState(LoadingIcon);
    const [describe, setDescribe] = useState('Chargement en cours...');
    const [activities, setActivities] = useState([]);
    const [selectedActivities, setSelectedActivities] = useState([]);

    const updateSelectedActivities = val => {
        const bf = [];
        if (val === 0) {
            activities.map(item => item.launched === 'n' && bf.push(item.id));
        } else {
            const index = selectedActivities.indexOf(val);
            if (index > -1) {
                selectedActivities.splice(index, 1);
            } else {
                selectedActivities.push(val);
            }
            selectedActivities.map(item => bf.push(item));
        }

        setSelectedActivities(bf);
    };

    const isValid = () => {
        if (empty(selectedActivities)) {
            alert('Veuillez sélectionner au moins une activité.');
            return false;
        }

        return true;
    };

    useEffect(() => {
        setLoading(false);
        setIcon(LoadingIcon);
        setError(false);
        setSelectedActivities([]);
        setDescribe('Chargement en cours...');
        setLaunched(false);

        if (open) {
            setLoading(true);

            getActivities(id)
                .then(data => {
                    setLoading(false);
                    setActivities(data);
                })
                .catch(err => {
                    setLoading(false);
                    setIcon(ErrorIcon);
                    setError(true);
                    setDescribe(err);
                });
        }
    }, [open]);

    useEffect(() => {
        if (launched) {
            setLoading(true);
            setDescribe('Tirage au sort en cours...');
            setIcon(LoadingIcon);
            launchDraw(id, selectedActivities)
                .then(data => {
                    setLoading(false);
                    setIcon(SuccessIcon);
                    setDescribe(`Tirage au sort effectué avec succès (${data} passagers).`);
                })
                .catch(err => {
                    setDescribe(err);
                    setIcon(ErrorIcon);
                    setLoading(false);
                });
        }
    }, [launched]);

    return (
        <Dialog
            open={open}
            title="Lancer le tirage au sort"
            action={
                <Button
                    color="primary"
                    onClick={() => isValid() && setLaunched(true)}
                    disabled={loading || error || launched}
                    variant="contained"
                    endIcon={<SlowMotionVideoIcon />}>
                    Lancer le tirage
                </Button>
            }
            onClose={close}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    {loading || error || launched ? (
                        <Grid
                            container
                            direction="column"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center">
                            <Grid item>{icon}</Grid>
                            <Grid item>
                                <Typography variant="body1">{describe}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        open && (
                            <Grid container spacing={2} direction="column">
                                <Grid item>
                                    <FormDraw
                                        activities={{
                                            all: activities,
                                            update: updateSelectedActivities,
                                            selected: selectedActivities
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <TableDraw id={id} activities={selectedActivities} />
                                </Grid>
                            </Grid>
                        )
                    )}
                </Grid>
            </Grid>
        </Dialog>
    );
};

DrawDialog.propTypes = {};

export default DrawDialog;
