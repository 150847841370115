import React from 'react';
import { withStyles } from '@material-ui/styles';
import NumberFormat from 'react-number-format';
import { empty } from 'common/validators';

import { Link as RouterLink } from 'react-router-dom';
import {
    Grid,
    Dialog,
    DialogContent,
    TextField,
    FormControl,
    FormLabel,
    MenuItem,
    Typography,
    DialogActions,
    Button,
    InputAdornment,
    CircularProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

import { DialogTitle } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    }
});

const currencies = [
    {
        value: 'esp',
        label: 'Numéraire',
        id: 1
    },
    {
        value: 'Chèque',
        label: 'Chèque',
        id: 2
    },
    {
        value: 'CB',
        label: 'Carte bancaire',
        id: 3
    },
    {
        value: 'Prélèvement',
        label: 'Prélèvement',
        id: 4
    },
    {
        value: 'Téléphone',
        label: 'Téléphone',
        id: 5
    }
];

function CustomTextField(props) {
    const { inputRef, onChange, allowedToChangePrice, noIcon, ...other } = props;

    return (
        <TextField
            {...other}
            onChange={onChange}
            variant="outlined"
            fullWidth
            //helperText={props.name != 'total' && 'Saisir un prix unitaire'}
            InputProps={{
                readOnly: !allowedToChangePrice,
                endAdornment: noIcon ? null : (
                    <InputAdornment position="end">
                        <EuroSymbolIcon />
                    </InputAdornment>
                )
            }}
        />
    );
}

class Creation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            isCreating: false,
            listOfMerchant: [],
            listOfEvent: [],
            selectedEvent: null,
            selectedUnity: '',
            selectedMerchant: '',
            listOfUnity: [],
            qte: '1',
            tarif: '',
            total: '',
            cheque: '',
            banque: '',
            observation: '',
            open: true,
            allowedToChangePrice: false,
            moyen: '',
            regexp: /^[0-9\b]+$/,
            price: /^[0-9\b]+$/
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.tarif != this.state.tarif || prevState.qte != this.state.qte) {
            if (this.state.qte != '' && this.state.tarif != '') {
                this.setState({
                    total: parseFloat(this.state.qte) * parseFloat(this.state.tarif)
                });
            }
        }

        this.state.isCreating &&
            this.state.isCreating != prevState.isCreating &&
            this.prepareData();
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/domaine/get-domain-bill-for-creation`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    prepareData = () => {
        const {
            moyen,
            banque,
            cheque,
            tarif,
            observation,
            selectedMerchant,
            selectedUnity,
            selectedEvent,
            qte,
            total
        } = this.state;

        if (!selectedMerchant) {
            alert('Veuillez sélectionner un commerçant');
            return;
        }

        if (!selectedUnity) {
            alert('Veuillez sélectionner une unité');
            return;
        }

        if (!tarif.replace(/\s/g, '').length) {
            alert('Veuillez saisir un prix unitaire pour la facture');
            return;
        }

        if (parseFloat(qte) == 0 || !qte.replace(/\s/g, '').length) {
            alert('La quantité ne peut pas être égale ou inférieur à 0');
            return;
        }

        if (total == '') {
            alert('Veuillez saisir un montant total pour la facture');
            return;
        }

        if (moyen == 2) {
            if (!banque || banque.length == 0 || !banque.replace(/\s/g, '').length) {
                alert('Veuillez saisir une banque');
                return;
            }

            if (!cheque || cheque.length == 0 || !cheque.replace(/\s/g, '').length) {
                alert('Veuillez saisir un numéro de chèque');
                return;
            }

            if (cheque.length != 7) {
                alert('Veuillez saisir un numéro de chèque valide (7 numéros obligatoires)');
                return;
            }
        }

        const formData = new FormData();
        empty(observation) === false && formData.append('observation', observation);
        formData.append('marchand_id', selectedMerchant.id);
        formData.append('unity', selectedUnity.id);
        formData.append('tarif', tarif);
        formData.append('moyen', moyen);
        formData.append('qte', qte);
        formData.append('total', total);
        selectedEvent && formData.append('event', selectedEvent.id);
        moyen == 2 && formData.append('cheque', cheque);
        moyen == 2 && formData.append('banque', banque);

        this.sendData(formData);
    };

    sendData = formData => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/domaine/create-bill-for-event`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                alert('Facture n°' + data.numFacture + ' créée avec succés.');
                this.props.history.push('/domain-bills/' + data.date + '/' + data.date);
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    formatCash = value => {
        let montants = parseFloat(value)
            .toString()
            .split('.');
        return (
            montants[0] +
            ',' +
            (montants[1] ? (montants[1] + '00')[0] + (montants[1] + '00')[1] : '00') +
            ' €'
        );
    };

    handleClose = () => {
        this.props.history.goBack();
        this.setState({
            open: false
        });
    };

    handleMoyen = ({ target: { value } }) => {
        this.setState({
            moyen: value,
            cheque: '',
            banque: ''
        });
    };

    handleMerchantList = (event, value) => {
        this.setState({
            selectedMerchant: value
        });
    };

    handleEventList = (event, value) => {
        this.setState({
            selectedEvent: value
        });
    };

    handleUnityList = (event, value) => {
        this.setState({
            selectedUnity: value,
            tarif: value.amount !== '' ? value.amount : this.state.tarif
        });
    };

    handleQte = ({ target: { value } }) => {
        //(value === '' || this.state.regexp.test(value)) &&
        this.setState({
            qte: value
        });
    };

    handleTotalTarif = ({ target: { value, name } }) => {
        //this.state.price.test(value) &&
        this.setState({
            [name]: value
        });
    };

    handleBanque = ({ target: { value } }) => {
        this.setState({
            banque: value
        });
    };

    handleCheque = ({ target: { value } }) => {
        (value === '' || this.state.regexp.test(value)) &&
            this.setState({
                cheque: value.replace(/\s/g, '').slice(0, 7)
            });
    };

    handleObservation = ({ target: { value } }) => {
        this.setState({
            observation: value
        });
    };

    allowPriceToBeModify = () => {
        if (!this.state.allowedToChangePrice) {
            let confirm = window.confirm('Etes-vous sûr de vouloir modifier le montant total ?');
            confirm && this.setState({ allowedToChangePrice: true });
        }
    };

    create = () => {
        this.setState({
            isCreating: true
        });
    };

    render() {
        const {
            classes,
            open,
            listOfMerchant,
            selectedMerchant,
            listOfEvent,
            selectedEvent,
            selectedUnity,
            listOfUnity,
            qte,
            total,
            tarif,
            moyen,
            banque,
            cheque,
            observation,
            allowedToChangePrice,
            isCreating
        } = this.state;

        return (
            <Dialog fullWidth maxWidth={'sm'} onClose={this.handleClose} open={open}>
                <DialogTitle onClose={this.handleClose}>Créer une nouvelle facture</DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <FormControl component="fieldset">
                                <FormLabel color="primary">
                                    Sélectionnez un événement (non obligatoire)
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={listOfEvent}
                                //size="small"
                                autoHighlight
                                value={selectedEvent}
                                onChange={this.handleEventList}
                                noOptionsText={'Aucun événement trouvé'}
                                getOptionLabel={option => option.label}
                                getOptionSelected={(option, value) => option.id == value.id}
                                renderInput={params => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        helperText={'Choisir un événement'}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item>
                            <FormControl required component="fieldset">
                                <FormLabel color="primary">Sélectionnez un commerçant</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={listOfMerchant}
                                //size="small"
                                autoHighlight
                                value={selectedMerchant}
                                onChange={this.handleMerchantList}
                                noOptionsText={'Aucun commerçant trouvé'}
                                getOptionLabel={option => option.raisonSociale}
                                getOptionSelected={(option, value) => option.id == value.id}
                                renderInput={params => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        helperText={'Choisir un commerçant'}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item style={{ marginTop: 10 }}>
                            <FormControl required component="fieldset">
                                <FormLabel color="primary">Prix unitaire</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={tarif}
                                customInput={CustomTextField}
                                helperText={'Saisir un prix unitaire'}
                                allowedToChangePrice={true}
                                onValueChange={values => {
                                    this.handleTotalTarif({
                                        target: {
                                            name: 'tarif',
                                            value: values.value
                                        }
                                    });
                                }}
                                onChange={this.handleTotalTarif}
                                thousandsGroupStyle="fr"
                                thousandSeparator={' '}
                                decimalSeparator={','}
                                type={'text'}
                                allowedDecimalSeparators={[',', '.', '?', ';']}
                                allowNegative={false}
                                decimalScale={2}
                                isNumericString
                            />
                        </Grid>
                        <Grid item style={{ marginTop: 10 }}>
                            <FormControl required component="fieldset">
                                <FormLabel color="primary">Unité</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={listOfUnity}
                                autoHighlight
                                //size="small"
                                value={selectedUnity}
                                onChange={this.handleUnityList}
                                noOptionsText={'Aucune unité trouvée'}
                                getOptionLabel={option => option.label}
                                getOptionSelected={(option, value) => option.id == value.id}
                                renderInput={params => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        helperText={'Choisir une unité'}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item style={{ marginTop: 10 }}>
                            <FormControl required component="fieldset">
                                <FormLabel color="primary">Quantité</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            {/* <TextField
                                variant="outlined"
                                //size="small"
                                fullWidth
                                value={qte}
                                helperText={'Saisir une quantité'}
                                onChange={this.handleQte}
                            /> */}

                            <NumberFormat
                                value={qte}
                                customInput={CustomTextField}
                                allowedToChangePrice={true}
                                onValueChange={values => {
                                    this.handleQte({
                                        target: {
                                            name: 'qte',
                                            value: values.value
                                        }
                                    });
                                }}
                                noIcon
                                //onChange={this.handleQte}
                                helperText={'Saisir une quantité'}
                                thousandsGroupStyle="fr"
                                thousandSeparator={' '}
                                decimalSeparator={'.'}
                                type={'text'}
                                allowedDecimalSeparators={[',', '.', '?', ';']}
                                allowNegative={false}
                                decimalScale={2}
                                isNumericString
                            />
                        </Grid>
                        <Grid item style={{ marginTop: 10 }}>
                            <FormControl required component="fieldset">
                                <FormLabel color="primary">Total</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={total}
                                customInput={CustomTextField}
                                allowedToChangePrice={true}
                                // helperText={'Saisir un prix unitaire'}
                                onValueChange={values => {
                                    this.handleTotalTarif({
                                        target: {
                                            name: 'total',
                                            value: values.value
                                        }
                                    });
                                }}
                                onChange={this.handleTotalTarif}
                                thousandsGroupStyle="fr"
                                thousandSeparator={' '}
                                decimalSeparator={','}
                                type={'text'}
                                allowedDecimalSeparators={[',', '.', '?', ';']}
                                allowNegative={false}
                                decimalScale={2}
                                isNumericString
                            />
                        </Grid>
                        <Grid item style={{ marginTop: 10 }}>
                            <FormControl component="fieldset">
                                <FormLabel color="primary">
                                    Moyen de paiement (facultatif)
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField
                                select
                                fullWidth
                                //size="small"
                                variant="outlined"
                                value={moyen}
                                helperText={'Choisir un moyen de paiement'}
                                onChange={this.handleMoyen}>
                                {currencies.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        <Typography variant="body1" color="primary">
                                            {option.label}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {moyen == 2 && (
                            <React.Fragment>
                                <Grid item>
                                    <TextField
                                        //size="small"
                                        name="banque"
                                        variant="outlined"
                                        fullWidth
                                        helperText="Saisir une banque"
                                        onChange={this.handleBanque}
                                        placeholder="Ma banque"
                                        value={banque}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        //size="small"
                                        variant="outlined"
                                        name="cheque"
                                        helperText="Saisir un numéro de chèque"
                                        fullWidth
                                        value={cheque}
                                        onChange={this.handleCheque}
                                        placeholder="1234567"
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                        <Grid item style={{ marginTop: 10 }}>
                            <FormControl component="fieldset">
                                <FormLabel color="primary">Observation (facultatif)</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                //size="small"
                                multiline
                                fullWidth
                                rows={5}
                                rowsMax={10}
                                value={observation}
                                helperText={'Saisir une observation'}
                                onChange={this.handleObservation}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {isCreating && <CircularProgress color="primary" size={25} />}
                    <Button onClick={this.handleClose} color="primary">
                        Annuler la création
                    </Button>
                    <Button
                        onClick={this.create}
                        disabled={isCreating}
                        variant="contained"
                        color="primary">
                        Créer la facture
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Creation);
