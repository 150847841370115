import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Divider, TableCell, TableRow, Button } from '@material-ui/core';
import { SearchTextField, MuiTable } from 'components';
import { getBills } from './services/http';
import { ActionButton, BillDialog } from './components';
import ActionButtonBill from './components/ActionButtonBill';
import BillRecipeDialog from './components/BillRecipeDialog';

const useStyles = makeStyles(theme => ({
    paperContent: {
        padding: theme.spacing(4)
    }
}));

const headers = [
    { id: 'bill.number', align: 'left', label: 'N°', sortable: true },
    { id: 'bill.status', align: 'left', label: 'Statut', sortable: true },
    { id: 'user.username', align: 'left', label: 'Utilisateur', sortable: true },
    { id: 'bill.total', align: 'right', label: 'Montant', sortable: true },
    { id: 'bill.created_at', align: 'left', label: 'Créée le', sortable: true },
    { id: 'bill.updated_at', align: 'left', label: 'Modifiée le', sortable: true },
    { id: 'action', align: 'center', label: 'Actions', sortable: false }
];

const Bills = ({ id }) => {
    const [count, setCount] = useState(0);
    const [bills, setBills] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(headers[0].id);
    const [openBill, setOpenBill] = useState(false);
    const [openRecipeBill, setOpenRecipeBill] = useState(false);
    const [billId, setBillId] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        !openBill && setLoading(true);
    }, [openBill]);

    useEffect(() => {
        loading && getData();
    }, [loading]);

    useEffect(() => {
        setLoading(true);
    }, [searchValue, order, orderBy, page]);

    const handleSearchValue = value => {
        setSearchValue(value);
        setPage(0);
        setLoading(true);
    };

    const getData = () => {
        getBills(id, { searchValue, page, order, orderBy })
            .then(res => {
                setCount(res.count);
                setBills(res.bills);
            })
            .catch(err => alert(err))
            .finally(() => setLoading(false));
    };

    const handleOpenBill = (id = null) => {
        setOpenBill(!openBill);
        setBillId(id);
    };

    const handleOpenRecipeBill = (id = null) => {
        setOpenRecipeBill(!openRecipeBill);
        setBillId(id);
        openRecipeBill && setLoading(true);
    };

    return (
        <>
            <BillDialog
                id={id}
                billId={billId}
                open={{ func: () => handleOpenBill(), value: openBill }}
            />

            <BillRecipeDialog
                id={billId}
                open={{ func: () => handleOpenRecipeBill(), value: openRecipeBill }}
            />

            <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
                <Grid item style={{ textAlign: 'right' }}>
                    <Button color="primary" variant="contained" onClick={() => handleOpenBill()}>
                        Créer une nouvelle facture
                    </Button>
                </Grid>
                <Grid item>
                    <SearchTextField
                        label={`Rechercher une facture`}
                        value={searchValue}
                        onChange={value => handleSearchValue(value)}
                        helperText="Saisir un numéro de facture ou un utilisateur"
                    />
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <MuiTable
                        refresh={() => setLoading(true)}
                        loading={loading}
                        headers={headers}
                        page={{
                            set: val => setPage(val),
                            data: page,
                            count: count
                        }}
                        order={{
                            set: val => setOrder(val),
                            data: order
                        }}
                        orderBy={{
                            set: val => setOrderBy(val),
                            data: orderBy
                        }}>
                        {bills.map((bill, index) => (
                            <TableRow hover key={`${index}-${bill.number}`}>
                                <TableCell align="left">{bill.number}</TableCell>
                                <TableCell align="left">{bill.status}</TableCell>
                                <TableCell align="left">{bill.user}</TableCell>
                                <TableCell align="right">{bill.total} €</TableCell>
                                <TableCell align="left">{bill.created_at}</TableCell>
                                <TableCell align="left">{bill.updated_at}</TableCell>
                                <TableCell align="center">
                                    <ActionButtonBill
                                        reload={() => setLoading(true)}
                                        editBill={() => handleOpenBill(bill.id)}
                                        editRecipe={() => handleOpenRecipeBill(bill.id)}
                                        terraceId={id}
                                        billId={bill.id}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </MuiTable>
                </Grid>
            </Grid>
        </>
    );
};

export default Bills;
