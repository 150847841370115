import React from 'react'
import { NavLink as RouterLink } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardActionArea,
  Grid,
  Typography,
  Divider,
  LinearProgress,
  Avatar,
} from '@material-ui/core';

import GroupIcon from '@material-ui/icons/Group';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  footer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center'
  },
  labelAction: {
    width: '100%',
    textAlign: 'center'
  },
  statusHeader: {
    zIndex: -1,
    boxShadow: "0 0 0 1px rgba(63,63,68,0.08), 0 1px 3px 0 rgba(63,63,68,0.15)",
    position: "absolute",
    top: -21,
    left: "50%",
    transform: "translateX(-50%)",
    background: "white",
    margin: "auto",
    width: 39,
    height: 39,
    paddingLeft: 1,
    paddingTop: 3,
    borderRadius: 50
  },
  status: {
    zIndex: 1,
    position: "absolute",
    border: "4px solid white",
    top: -20,
    left: "50%",
    transform: "translateX(-50%)",
    background: "#2699FB",
    margin: "auto",
    width: 35,
    height: 35,
    borderRadius: 50
  },
  content: {
    //marginBottom: 15
  },
  finance: {
    display: "inline-flex",
    paddingTop: 7,
  },
  chartContainer: {
    position: 'relative',
    //height: '300px'
  },
  chartDispo: {
    width: 70,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)"
  },
  chartDispoText: {
    //position: "absolute",
    //top: "50%",
    //width: "100%",
    //padding: 20, 
    //transform: "translateY(-40%)"
  },
  cardFooter: {
    //marginTop: 20,
    padding: 20,
    paddingBottom: 15,
    paddingTop: 17,
    background: "#43425D"
  },
  marketLabel: {
    color: "white"
  },
  marketStatus: {
    marginTop: 7,
    fontWeight: 500,
    color: "#2699FB"
  },
  action: {
    marginTop: -5,
    color: "white"
  },
  marketCard: {
    position: "relative",
    minWidth: 290,
    margin: "auto",
  },
  availableDot: {
    height: 9,
    width: 9,
    margin: 6,
    marginRight: 4,
    borderRadius: 50,
    background: "#ddd"
  },
  alim: {
    height: 9,
    width: 9,
    margin: 4,
    marginRight: 0,
    marginLeft: 4,
    borderRadius: 50,
    background: "gold"
  },
  prim: {
    height: 9,
    width: 9,
    margin: 4,
    marginRight: 0,
    marginLeft: 4,
    borderRadius: 50,
    background: "#32CD32"
  },
  dempos: {
    height: 9,
    width: 9,
    margin: 4,
    marginRight: 0,
    marginLeft: 4,
    borderRadius: 50,
    background: "#87CEFA"
  },
  manu: {
    height: 9,
    width: 9,
    margin: 4,
    marginRight: 0,
    marginLeft: 4,
    borderRadius: 50,
    background: "#BD33A4"
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 62,
    width: 62
  },
  icon: {
    height: 38,
    width: 38
  },
}));

const Overview = props => {
  const { className, encaisse, nonencaisse, placed, ...rest } = props;


  const classes = useStyles();
  const theme = useTheme();


  return (
    <div className={classes.marketCard}>
      <Card {...rest} className={clsx(classes.root, className)} style={{ overflow: "hidden" }}>
        <div style={{ position: "absolute", left: "50%", width: "50%", height: "100%", background: "red", opacity: 0.1 }}></div>
        <div style={{ position: "absolute", width: "50%", height: "100%", background: "green", opacity: 0.1 }}></div>
        <CardContent className={classes.content}>
          <Grid container>
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
              style={{ marginBottom: 10 }}
            >
              <Typography gutterBottom align="left" variant="body1" >
                Les recettes
                </Typography>
            </Grid>

            <Grid container item
              direction="row"
              justify="center"
              alignItems="center"
              style={{ width: "100%" }}
            >
              <Grid item container xs={6}
                direction="column"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography variant="h2" style={{ color: "green" }}>{encaisse} €</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" style={{ color: "green", fontWeight: 500 }}>réglé au total</Typography>
                </Grid>
              </Grid>

              <Grid item container xs={6}
                direction="column"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography variant="h2" style={{ color: "red" }}>{nonencaisse}  €</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" style={{ color: "red", fontWeight: 500 }}>reste à régler</Typography>
                </Grid>
              </Grid>

            </Grid>

          </Grid>
          {
            /*
            <div className={classes.footer}>
              <Typography
                className={classes.labelAction}
                variant="subtitle2"
                color="primary"
              >
                Visualiser les recettes
            </Typography>
            </div>*/
          }
        </CardContent>
      </Card>
    </div>
  );
};


export default Overview;
