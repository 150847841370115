// @flow

import React from 'react';
import { withStyles } from '@material-ui/styles';

import {
    Button,
    Paper,
    TableRow,
    TableCell,
    ListItemText,
    Typography,
    TableContainer,
    Table,
    TableBody,
    CircularProgress,
    Grid
} from '@material-ui/core';

import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DoneIcon from '@material-ui/icons/Done';

const styles = theme => ({});

const infoPlace = place => {
    let info = (
        <ListItemText primary={<Typography style={{ color: 'green' }}>Disponible</Typography>} />
    );

    if (place.usualPlace == '1') {
        info = (
            <ListItemText
                primary={
                    <Typography style={{ color: 'orange' }}>
                        Place attribuée par défaut {place.onIt == '1' && '(sur cette place)'}
                    </Typography>
                }
                secondary={<Typography style={{ color: 'green' }}>Disponible</Typography>}
            />
        );
    }

    if (place.otherIntel != '0' && place.information == '') {
        if (place.usualPlace == '1') {
            info = (
                <ListItemText
                    primary={
                        <Typography style={{ color: 'orange' }}>
                            Place attribuée par défaut{' '}
                            {place.onIt == '1' && <strong>(sur cette place)</strong>}
                        </Typography>
                    }
                    secondary={
                        <Typography style={{ color: 'red' }}>
                            Occupée par {place.otherIntel}
                        </Typography>
                    }
                />
            );
        } else {
            info = (
                <ListItemText
                    primary={
                        <Typography style={{ color: 'red' }}>
                            Occupée par {place.otherIntel}
                        </Typography>
                    }
                />
            );
        }
    } else if (place.otherIntel != '0' && place.information != '') {
        info = (
            <ListItemText
                primary={
                    <Typography style={{ color: place.usualPlace == '1' ? 'orange' : '#3f51b5' }}>
                        {place.usualPlace == '0' ? 'Recommandée' : 'Place attribuée par défaut'}{' '}
                        {place.onIt == '1' && <strong>(sur cette place)</strong>}
                    </Typography>
                }
                secondary={
                    <Typography style={{ color: 'red' }}>
                        {`Occupée par ${place.otherIntel}`}
                    </Typography>
                }
            />
        );
    } else if (place.otherIntel == '0' && place.information != '') {
        info = (
            <ListItemText
                primary={
                    <Typography style={{ color: place.usualPlace == '1' ? 'orange' : '#3f51b5' }}>
                        {place.usualPlace == '0' ? 'Recommandée' : 'Place attribuée par défaut'}
                    </Typography>
                }
                secondary={<Typography style={{ color: 'green' }}>Disponible</Typography>}
            />
        );
    }

    return info;
};

class InfoPlace extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            isLoading: true,
            place: []
        };
    }

    componentDidUpdate = prevProps => {
        if (prevProps.journalplaceId != this.props.journalplaceId && this.props.journalplaceId) {
            this.setState({
                isLoading: true
            });
            this.getData();
        }
    };

    componentDidMount = () => {
        //this.getData()
    };

    getData = () => {
        const formData = new FormData();

        formData.append('marchand_id', this.props.marchandId);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/get-info-about-one-place-journalmarket/${
                this.props.journalplaceId
            }`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    isLoading: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    render() {
        const { classes, place, isLoading } = this.state;

        return this.props.journalplaceId ? (
            <TableContainer
                component={Paper}
                elevation={3}
                style={{
                    borderLeft: `5px solid ${
                        place.onIt == '1'
                            ? 'orange'
                            : place.otherIntel == '0'
                            ? '#10ce10'
                            : place.information != ''
                            ? '#3f51b5'
                            : 'red'
                    }`
                    //position: "relative",
                    //top: -65,
                }}>
                <Table size="small">
                    <TableBody>
                        {isLoading ? (
                            <TableRow hover>
                                <TableCell align="center" style={{ padding: 12 }}>
                                    <CircularProgress size={35} />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow hover>
                                <TableCell key={`1_${place.id}`} align="left">
                                    <ListItemText
                                        primary={place.numero}
                                        secondary={place.metres + ' m'}
                                    />
                                </TableCell>
                                <TableCell key={`2_${place.id}`} align="left">
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        spacing={2}
                                        alignItems="center">
                                        <Grid item>{infoPlace(place)}</Grid>
                                        <Grid item>
                                            {place.electricity === '1' ? (
                                                <FlashOnOutlinedIcon style={{ color: 'gold' }} />
                                            ) : null}
                                            {place.water === '1' ? (
                                                <InvertColorsIcon style={{ color: 'dodgerblue' }} />
                                            ) : null}
                                            {place.vehicule === '1' ? <LocalShippingIcon /> : null}
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell key={`3_${place.id}`} align="center">
                                    {place.encaisse == '1' ? (
                                        <React.Fragment>
                                            Réglé{' '}
                                            <DoneIcon
                                                style={{ fontSize: 'inherit', color: 'green' }}
                                            />
                                        </React.Fragment>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={() => this.props.doPlacement(place)}
                                            color="primary"
                                            style={{
                                                background: place.onIt == '1' ? 'orange' : '#3f51b5'
                                            }}>
                                            {place.onIt == '1' ? 'Modifier' : 'Placer'}
                                        </Button>
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        ) : null;
    }
}

export default withStyles(styles)(InfoPlace);
