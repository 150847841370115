import React from 'react';
import { withStyles } from '@material-ui/styles';
import NumberFormat from 'react-number-format';
import { empty } from 'common/validators';

import banks from 'common/banks';
import { Link as RouterLink } from 'react-router-dom';
import {
    Grid,
    Dialog,
    DialogContent,
    TextField,
    FormControl,
    FormLabel,
    MenuItem,
    Typography,
    DialogActions,
    Button,
    InputAdornment,
    Checkbox,
    FormControlLabel,
    FormGroup,
    CircularProgress,
    RadioGroup,
    Radio
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

import { DialogTitle } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    }
});
const filter = createFilterOptions();

const currencies = [
    {
        value: 'esp',
        label: 'Numéraire',
        id: 1
    },
    {
        value: 'Chèque',
        label: 'Chèque',
        id: 2
    },
    {
        value: 'CB',
        label: 'Carte bancaire',
        id: 3
    },
    {
        value: 'Tresor Public',
        label: 'Tresor Public',
        id: 4
    },
    {
        value: 'Téléphone',
        label: 'Téléphone',
        id: 5
    },
    {
        value: 'Virement/Prélevement',
        label: 'Virement/Prélevement',
        id: 6
    }
];

function CustomTextField(props) {
    const { inputRef, onChange, allowedToChangePrice, noIcon, ...other } = props;

    return (
        <TextField
            {...other}
            onChange={onChange}
            variant="outlined"
            fullWidth
            helperText={props.name != 'total' && 'Saisir un prix unitaire'}
            InputProps={{
                readOnly: !allowedToChangePrice,
                endAdornment: noIcon ? null : (
                    <InputAdornment position="end">
                        <EuroSymbolIcon />
                    </InputAdornment>
                )
            }}
        />
    );
}

class Creation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            listOfProduct: [],
            listOfMerchant: [],
            isLoading: false,
            selectedMerchant: null,
            selectedProduct: null,
            billNumber: '',
            qte: '',
            tarif: '',
            total: '',
            cheque: '',
            banque: '',
            observation: '',
            open: true,
            allowedToChangePrice: false,
            moyen: '',
            regexp: /^[0-9\b]+$/,
            price: /^[0-9\b]+$/,
            isParticular: false,
            noInvoice: false,
            selectedTitle: null,
            listOfTitle: [],
            selectedName: null,
            listOfName: [],
            selectedSurname: null,
            listOfSurname: [],
            selectedAdresse: null,
            listOfAdresse: [],
            selectedCp: null,
            listOfCp: [],
            selectedCity: null,
            listOfCity: []
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevState.tarif != this.state.tarif || prevState.qte != this.state.qte) {
            if (this.state.qte != '' && this.state.tarif != '') {
                this.setState({
                    total: parseFloat(this.state.qte) * parseFloat(this.state.tarif)
                });
            }
        }

        if (prevState.isParticular != this.state.isParticular) {
            this.getData();
        }

        if (this.state.isLoading) {
            this.sendData();
        }
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/domaine/get-other-sales-bill-for-creation`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    prepareData = () => {
        const {
            moyen,
            banque,
            cheque,
            tarif,
            observation,
            selectedMerchant,
            isParticular,
            selectedProduct,
            selectedName,
            selectedSurname,
            selectedCity,
            selectedCp,
            selectedAdresse,
            selectedTitle,
            qte,
            total,
            noInvoice
        } = this.state;

        if (noInvoice == '') {
            this.setState({
                noInvoice: window.confirm('Souhaitez-vous émettre une facture ?') ? 'y' : 'n'
            });
            alert('Changement effectué.\nVeuillez à nouveau cliquer sur "Créer la vente"');
            return;
        }

        if (!selectedProduct) {
            alert('Veuillez sélectionner un produit ou une prestation.');
            return;
        }

        if (Boolean(noInvoice == 'y')) {
            if (!selectedTitle) {
                alert('Veuillez sélectionner ou saisir un titre.');
                return;
            }

            if (!selectedMerchant && !isParticular) {
                alert('Veuillez sélectionner un commerçant.');
                return;
            } else {
                if (!selectedName) {
                    alert('Veuillez sélectionner ou saisir un nom.');
                    return;
                }
                if (!selectedSurname) {
                    alert('Veuillez sélectionner ou saisir un prénom.');
                    return;
                }
                /*if (!selectedAdresse) {
                    alert("Veuillez sélectionner ou saisir une adresse.")
                    return;
                }

                if (!selectedCity) {
                    alert("Veuillez sélectionner ou saisir un ville.")
                    return;
                }

                if (!selectedCp) {
                    alert("Veuillez sélectionner ou saisir un code postal.")
                    return;
                }*/
            }
        }

        if (!tarif.replace(/\s/g, '').length) {
            alert('Veuillez saisir un prix unitaire pour la facture');
            return;
        }

        if (parseFloat(qte) == 0 || !qte.replace(/\s/g, '').length) {
            alert('La quantité ne peut pas être égale ou inférieur à 0');
            return;
        }

        if (total == '') {
            alert('Veuillez saisir un montant total pour la facture');
            return;
        }

        if (moyen == '') {
            alert('Veuillez sélectionner un moyen de paiement');
            return;
        }

        if (moyen == 2) {
            if (!banque || banque.length == 0 || !banque.replace(/\s/g, '').length) {
                alert('Veuillez saisir une banque');
                return;
            }

            if (!cheque || cheque.length == 0 || !cheque.replace(/\s/g, '').length) {
                alert('Veuillez saisir un numéro de chèque');
                return;
            }

            if (cheque.length != 7) {
                alert('Veuillez saisir un numéro de chèque valide (7 numéros obligatoires)');
                return;
            }
        }

        //this.sendData(formData);*/

        this.setState({
            isLoading: true
        });
    };

    sendData = () => {
        const {
            moyen,
            banque,
            cheque,
            tarif,
            observation,
            selectedMerchant,
            isParticular,
            selectedProduct,
            selectedName,
            selectedSurname,
            selectedCity,
            selectedCp,
            selectedAdresse,
            selectedTitle,
            qte,
            total,
            noInvoice,
            billNumber
        } = this.state;

        const formData = new FormData();
        if (observation) {
            empty(observation) === false && formData.append('observation', observation);
        }

        Boolean(noInvoice == 'y') && formData.append('title', selectedTitle.title);
        formData.append('product_id', selectedProduct.id);
        Boolean(noInvoice == 'y') && formData.append('name', selectedName.name);
        Boolean(noInvoice == 'y') && formData.append('surname', selectedSurname.surname);
        Boolean(noInvoice == 'y') && formData.append('city', selectedCity.city);
        Boolean(noInvoice == 'y') && formData.append('adresse', selectedAdresse.adresse);
        Boolean(noInvoice == 'y') && formData.append('cp', selectedCp.cp);
        Boolean(noInvoice == 'y') && formData.append('billNumber', billNumber);
        selectedMerchant && formData.append('marchand_id', selectedMerchant.id);
        formData.append('tarif', tarif);
        formData.append('moyen', moyen);
        formData.append('qte', qte);
        formData.append('total', total);
        formData.append('noInvoice', noInvoice);
        moyen == 2 && formData.append('cheque', cheque);
        moyen == 2 && formData.append('banque', banque);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/domaine/create-bill-for-other-sales`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    isLoading: false
                });
                if (Boolean(noInvoice == 'y')) {
                    alert('Facture n°' + data.numFacture + ' créée et réglée avec succés.');
                } else {
                    alert('Vente créée avec succés.');
                }

                this.props.history.push('/other-sales-bills/' + data.date + '/' + data.date);
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la création.');
                    this.setState({
                        isLoading: false
                    });
                } // Handle the error response object
            );
    };

    handleClose = () => {
        this.props.history.goBack();
        this.setState({
            open: false
        });
    };

    handleMoyen = ({ target: { value } }) => {
        this.setState({
            moyen: value,
            cheque: '',
            banque: ''
        });
    };

    handleMerchantList = (event, value) => {
        this.setState({
            selectedMerchant: value,
            selectedSurname: value ? { surname: value.surname } : null,
            selectedName: value ? { name: value.name } : null,
            selectedAdresse: value ? { adresse: value.adresse } : null,
            selectedCity: value ? { city: value.city } : null,
            selectedCp: value ? { cp: value.cp } : null
        });
    };

    handleTitleList = (event, value) => {
        this.setState({
            selectedTitle: value
        });
    };

    handleSurnameList = (event, value) => {
        this.setState({
            selectedSurname: value
        });
    };

    handleNameList = (event, value) => {
        this.setState({
            selectedName: value
        });
    };
    handleCityList = (event, value) => {
        this.setState({
            selectedCity: value
        });
    };
    handleAdresseList = (event, value) => {
        this.setState({
            selectedAdresse: value
        });
    };

    handleCpList = (event, value) => {
        this.setState({
            selectedCp: value
        });
    };

    handleProductList = (event, value) => {
        this.setState({
            selectedProduct: value,
            observation: value ? value.commentaire : '',
            tarif: value.amount ?? 1
        });
    };

    handleQte = ({ target: { value } }) => {
        // (value === '' || this.state.regexp.test(value)) &&
        this.setState({
            qte: value
        });
    };

    handleTotalTarif = ({ target: { value, name } }) => {
        //this.state.price.test(value) &&
        this.setState({
            [name]: value
        });
    };

    handleBanque = event => {
        this.setState({
            banque: event.target.value
        });
    };

    handleCheque = ({ target: { value } }) => {
        (value === '' || this.state.regexp.test(value)) &&
            this.setState({
                cheque: value.replace(/\s/g, '').slice(0, 7)
            });
    };

    handleObservation = ({ target: { value } }) => {
        this.setState({
            observation: value
        });
    };

    handleBillNumber = ({ target: { value } }) => {
        this.setState({
            billNumber: value
        });
    };

    allowPriceToBeModify = () => {
        if (!this.state.allowedToChangePrice) {
            let confirm = window.confirm('Etes-vous sûr de vouloir modifier le montant total ?');
            confirm && this.setState({ allowedToChangePrice: true });
        }
    };

    handleChangeParticular = ({ target: { checked } }) => {
        this.setState(prevState => ({
            isParticular: checked,
            selectedMerchant: checked ? '' : prevState.selectedMerchant,
            selectedSurname: checked ? '' : prevState.selectedSurname,
            selectedName: checked ? '' : prevState.selectedName,
            selectedAdresse: checked ? '' : prevState.selectedAdresse,
            selectedCity: checked ? '' : prevState.selectedCity,
            selectedCp: checked ? '' : prevState.selectedCp
        }));
    };

    handleChangeNoInvoice = ({ target: { value } }) => {
        this.setState(prevState => ({
            noInvoice: value,
            isParticular: '',
            selectedMerchant: value == 'n' ? '' : prevState.selectedMerchant,
            selectedSurname: value == 'n' ? '' : prevState.selectedSurname,
            selectedName: value == 'n' ? '' : prevState.selectedName,
            selectedAdresse: value == 'n' ? '' : prevState.selectedAdresse,
            selectedCity: value == 'n' ? '' : prevState.selectedCity,
            selectedCp: value == 'n' ? '' : prevState.selectedCp
        }));
    };

    handleOnChangeAutocomplete = (fnc, key, newValue) => {
        if (typeof newValue === 'string') {
            fnc(null, { [key]: newValue });
        } else if (newValue && newValue.inputValue) {
            fnc(null, { [key]: newValue.inputValue });
        } else {
            fnc(null, newValue);
        }
    };

    handleFilterOptionAutocomplete = (options, params, key) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
            filtered.push({
                inputValue: params.inputValue,
                [key]: `Ajouter "${params.inputValue}"`
            });
        }

        return filtered;
    };

    handleOptionLabelAutocomplement = (option, key) => {
        if (typeof option === 'string') {
            return option;
        }
        if (option.inputValue) {
            return option.inputValue;
        }

        return option[key];
    };

    render() {
        const {
            classes,
            open,
            listOfMerchant,
            listOfProduct,
            selectedMerchant,
            selectedProduct,
            qte,
            total,
            tarif,
            moyen,
            banque,
            cheque,
            observation,
            allowedToChangePrice,
            isParticular,
            selectedTitle,
            listOfTitle,
            selectedSurname,
            listOfSurname,
            selectedName,
            listOfName,
            selectedAdresse,
            listOfAdresse,
            selectedCity,
            listOfCity,
            selectedCp,
            listOfCp,
            isLoading,
            noInvoice,
            billNumber
        } = this.state;

        return (
            <Dialog fullWidth maxWidth={'sm'} onClose={this.handleClose} open={open}>
                <DialogTitle onClose={this.handleClose}>
                    Créer une nouvelle vente avec facture/sans facture
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <FormControl component="fieldset" required>
                                <FormLabel color="primary">Emettre une facture </FormLabel>
                                <RadioGroup
                                    row
                                    name="quiz"
                                    value={noInvoice}
                                    onChange={this.handleChangeNoInvoice}>
                                    <FormControlLabel value="y" control={<Radio />} label="Oui" />
                                    <FormControlLabel value="n" control={<Radio />} label="Non" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset" fullWidth>
                                <FormLabel color="primary" style={{ marginBottom: 20 }}>
                                    Numéro de facture (facultatif)
                                </FormLabel>
                                <TextField
                                    //size="small"
                                    name="billNumber"
                                    variant="outlined"
                                    disabled={noInvoice === 'n'}
                                    fullWidth
                                    helperText="Saisir un numéro de facture"
                                    onChange={this.handleBillNumber}
                                    placeholder="XXXX"
                                    value={billNumber}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">N°</InputAdornment>
                                        )
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item style={{ marginTop: 10 }}>
                            <FormControl required component="fieldset">
                                <FormLabel color="primary">
                                    Sélectionnez un produit ou prestation de service
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={listOfProduct}
                                //size="small"
                                autoHighlight
                                value={selectedProduct}
                                onChange={this.handleProductList}
                                noOptionsText={'Aucun produit trouvé'}
                                getOptionLabel={option => option.label}
                                getOptionSelected={(option, value) => option.id == value.id}
                                renderOption={(option, value) => (
                                    <Grid container direction="column" alignItems="stretch">
                                        <Grid item>
                                            <Grid
                                                container
                                                direciton="row"
                                                justifyContent="space-between"
                                                justify="space-between"
                                                spacing={2}
                                                alignItems="flex-start">
                                                <Grid item>
                                                    <Typography>{option.label}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="caption">
                                                        {option.type == 'p'
                                                            ? 'Produit'
                                                            : 'Prestation de service'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                {option.amount
                                                    ? `Tarif à ${option.amount} €`
                                                    : 'Tarif non configuré'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                renderInput={params => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        helperText={
                                            'Choisir un produit ou une prestation de service'
                                        }
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item style={{ marginTop: 10 }}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <FormControl
                                        required
                                        component="fieldset"
                                        disabled={Boolean(noInvoice == 'n')}>
                                        <FormLabel color="primary">
                                            Sélectionnez un commerçant ou un particulier
                                        </FormLabel>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isParticular}
                                                        onChange={this.handleChangeParticular}
                                                        name="particular"
                                                    />
                                                }
                                                label="Particulier"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        options={listOfMerchant}
                                        //size="small"
                                        disabled={isParticular || Boolean(noInvoice == 'n')}
                                        autoHighlight
                                        value={selectedMerchant}
                                        onChange={this.handleMerchantList}
                                        noOptionsText={'Aucun commerçant trouvé'}
                                        getOptionLabel={option => option.raisonSociale}
                                        getOptionSelected={(option, value) => option.id == value.id}
                                        renderInput={params => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                helperText={'Choisir un commerçant'}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        options={listOfTitle}
                                        value={selectedTitle}
                                        disabled={Boolean(noInvoice == 'n')}
                                        freeSolo
                                        autoHighlight
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        onChange={(event, newValue) =>
                                            this.handleOnChangeAutocomplete(
                                                this.handleTitleList,
                                                'title',
                                                newValue
                                            )
                                        }
                                        filterOptions={(options, params) =>
                                            this.handleFilterOptionAutocomplete(
                                                options,
                                                params,
                                                'title'
                                            )
                                        }
                                        getOptionLabel={option =>
                                            this.handleOptionLabelAutocomplement(option, 'title')
                                        }
                                        renderOption={option => option.title}
                                        renderInput={params => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                label="Titre"
                                                helperText={'Saisir un titre'}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        options={listOfName}
                                        disabled={!isParticular || Boolean(noInvoice == 'n')}
                                        value={selectedName}
                                        freeSolo
                                        autoHighlight
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        onChange={(event, newValue) =>
                                            this.handleOnChangeAutocomplete(
                                                this.handleNameList,
                                                'name',
                                                newValue
                                            )
                                        }
                                        filterOptions={(options, params) =>
                                            this.handleFilterOptionAutocomplete(
                                                options,
                                                params,
                                                'name'
                                            )
                                        }
                                        getOptionLabel={option =>
                                            this.handleOptionLabelAutocomplement(option, 'name')
                                        }
                                        renderOption={option => option.name}
                                        renderInput={params => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                label="Nom"
                                                helperText={'Saisir un nom'}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        options={listOfSurname}
                                        value={selectedSurname}
                                        disabled={!isParticular || Boolean(noInvoice == 'n')}
                                        freeSolo
                                        autoHighlight
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        onChange={(event, newValue) =>
                                            this.handleOnChangeAutocomplete(
                                                this.handleSurnameList,
                                                'surname',
                                                newValue
                                            )
                                        }
                                        filterOptions={(options, params) =>
                                            this.handleFilterOptionAutocomplete(
                                                options,
                                                params,
                                                'surname'
                                            )
                                        }
                                        getOptionLabel={option =>
                                            this.handleOptionLabelAutocomplement(option, 'surname')
                                        }
                                        renderOption={option => option.surname}
                                        renderInput={params => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                label="Prénom"
                                                helperText={'Saisir un prénom'}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        options={listOfAdresse}
                                        value={selectedAdresse}
                                        disabled={!isParticular || Boolean(noInvoice == 'n')}
                                        freeSolo
                                        autoHighlight
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        onChange={(event, newValue) =>
                                            this.handleOnChangeAutocomplete(
                                                this.handleAdresseList,
                                                'adresse',
                                                newValue
                                            )
                                        }
                                        filterOptions={(options, params) =>
                                            this.handleFilterOptionAutocomplete(
                                                options,
                                                params,
                                                'adresse'
                                            )
                                        }
                                        getOptionLabel={option =>
                                            this.handleOptionLabelAutocomplement(option, 'adresse')
                                        }
                                        renderOption={option => option.adresse}
                                        renderInput={params => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                label="Adresse"
                                                helperText={'Saisir une adresse'}
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12} sm={8}>
                                            <Autocomplete
                                                options={listOfCity}
                                                disabled={
                                                    !isParticular || Boolean(noInvoice == 'n')
                                                }
                                                value={selectedCity}
                                                freeSolo
                                                autoHighlight
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                onChange={(event, newValue) =>
                                                    this.handleOnChangeAutocomplete(
                                                        this.handleCityList,
                                                        'city',
                                                        newValue
                                                    )
                                                }
                                                filterOptions={(options, params) =>
                                                    this.handleFilterOptionAutocomplete(
                                                        options,
                                                        params,
                                                        'city'
                                                    )
                                                }
                                                getOptionLabel={option =>
                                                    this.handleOptionLabelAutocomplement(
                                                        option,
                                                        'city'
                                                    )
                                                }
                                                renderOption={option => option.city}
                                                renderInput={params => (
                                                    <TextField
                                                        fullWidth
                                                        {...params}
                                                        label="Ville"
                                                        helperText={'Saisir une ville'}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Autocomplete
                                                options={listOfCp}
                                                disabled={
                                                    !isParticular || Boolean(noInvoice == 'n')
                                                }
                                                value={selectedCp}
                                                freeSolo
                                                autoHighlight
                                                selectOnFocus
                                                clearOnBlur
                                                handleHomeEndKeys
                                                onChange={(event, newValue) =>
                                                    this.handleOnChangeAutocomplete(
                                                        this.handleCpList,
                                                        'cp',
                                                        newValue
                                                    )
                                                }
                                                filterOptions={(options, params) =>
                                                    this.handleFilterOptionAutocomplete(
                                                        options,
                                                        params,
                                                        'cp'
                                                    )
                                                }
                                                getOptionLabel={option =>
                                                    this.handleOptionLabelAutocomplement(
                                                        option,
                                                        'cp'
                                                    )
                                                }
                                                renderOption={option => option.cp}
                                                renderInput={params => (
                                                    <TextField
                                                        fullWidth
                                                        {...params}
                                                        label="Code postal"
                                                        helperText={'Saisir un code postal'}
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{ marginTop: 10 }}>
                            <FormControl required component="fieldset">
                                <FormLabel color="primary">Prix unitaire</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={tarif}
                                customInput={CustomTextField}
                                allowedToChangePrice={true}
                                helperText={'Saisir un prix unitaire'}
                                onValueChange={values => {
                                    this.handleTotalTarif({
                                        target: {
                                            name: 'tarif',
                                            value: values.value
                                        }
                                    });
                                }}
                                onChange={this.handleTotalTarif}
                                thousandsGroupStyle="fr"
                                thousandSeparator={' '}
                                decimalSeparator={','}
                                type={'text'}
                                allowedDecimalSeparators={[',', '.', '?', ';']}
                                allowNegative={false}
                                decimalScale={2}
                                isNumericString
                            />
                        </Grid>
                        <Grid item style={{ marginTop: 10 }}>
                            <FormControl required component="fieldset">
                                <FormLabel color="primary">Quantité</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            {/* <TextField
                                variant="outlined"
                                //size="small"
                                fullWidth
                                value={qte}
                                helperText={'Saisir une quantité'}
                                onChange={this.handleQte}
                            /> */}

                            <NumberFormat
                                value={qte}
                                customInput={CustomTextField}
                                allowedToChangePrice={true}
                                onValueChange={values => {
                                    this.handleQte({
                                        target: {
                                            name: 'qte',
                                            value: values.value
                                        }
                                    });
                                }}
                                noIcon
                                //onChange={this.handleQte}
                                helperText={'Saisir une quantité'}
                                thousandsGroupStyle="fr"
                                thousandSeparator={' '}
                                decimalSeparator={'.'}
                                type={'text'}
                                allowedDecimalSeparators={[',', '.', '?', ';']}
                                allowNegative={false}
                                decimalScale={2}
                                isNumericString
                            />
                        </Grid>
                        <Grid item style={{ marginTop: 10 }}>
                            <FormControl required component="fieldset">
                                <FormLabel color="primary">Total</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <NumberFormat
                                value={total}
                                customInput={CustomTextField}
                                allowedToChangePrice={true}
                                // helperText={'Saisir un prix unitaire'}
                                onValueChange={values => {
                                    this.handleTotalTarif({
                                        target: {
                                            name: 'total',
                                            value: values.value
                                        }
                                    });
                                }}
                                onChange={this.handleTotalTarif}
                                thousandsGroupStyle="fr"
                                thousandSeparator={' '}
                                decimalSeparator={','}
                                type={'text'}
                                allowedDecimalSeparators={[',', '.', '?', ';']}
                                allowNegative={false}
                                decimalScale={2}
                                isNumericString
                            />
                        </Grid>
                        <Grid item style={{ marginTop: 10 }}>
                            <FormControl required component="fieldset">
                                <FormLabel color="primary">Moyen de paiement</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField
                                select
                                fullWidth
                                //size="small"
                                variant="outlined"
                                value={moyen}
                                helperText={'Choisir un moyen de paiement'}
                                onChange={this.handleMoyen}>
                                {currencies.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        <Typography variant="body1" color="primary">
                                            {option.label}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {moyen == 2 && (
                            <React.Fragment>
                                <Grid item>
                                    <TextField
                                        select
                                        fullWidth
                                        value={banque}
                                        variant="outlined"
                                        helperText="Saisir une banque"
                                        onChange={this.handleBanque}>
                                        {banks.map(option => (
                                            <MenuItem key={option.title} value={option.title}>
                                                <Typography variant="body1" color="primary">
                                                    {option.title}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        //size="small"
                                        variant="outlined"
                                        name="cheque"
                                        helperText="Saisir un numéro de chèque"
                                        fullWidth
                                        value={cheque}
                                        onChange={this.handleCheque}
                                        placeholder="1234567"
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                        <Grid item style={{ marginTop: 10 }}>
                            <FormControl component="fieldset">
                                <FormLabel color="primary">Observation (facultatif)</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                //size="small"
                                multiline
                                fullWidth
                                rows={5}
                                rowsMax={10}
                                value={observation}
                                helperText={'Saisir une observation'}
                                onChange={this.handleObservation}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                        {isLoading && (
                            <Grid item>
                                <CircularProgress color="primary" size={25} />
                            </Grid>
                        )}
                        <Grid item>
                            <Button onClick={this.handleClose} color="primary">
                                Annuler la création
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={isLoading}
                                onClick={this.prepareData}
                                variant="contained"
                                color="primary">
                                Créer la vente
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Creation);
