import React, { useState, useEffect } from 'react';
import { empty, isValidBankCheck } from 'common/validators';
import PropTypes from 'prop-types';
import { Button, Grid, CircularProgress, Typography } from '@material-ui/core';
import Dialog from '../../components/Dialog';
import EuroIcon from '@material-ui/icons/Euro';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { BillDetail } from './components';

/**
 * url
 */
const url = `https://www.${
    window.location.hostname.split('.')[1]
        ? window.location.hostname.split('.')[0] === 'www'
            ? window.location.hostname.split('.')[1]
            : window.location.hostname.split('.')[0]
        : 'dev'
}.api.digitmarche.fr/api`;

/**
 *
 * @param {*} billNumber
 * @returns
 */
export async function getBill(billNumber) {
    const result = await fetch(`${url}/regie/get-bill/${billNumber}?method=get`, {
        method: 'GET'
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw "Une erreur est survenue lors de l'affichage de la facture.";
        });

    return result;
}

/**
 *
 * @returns
 */
async function getpaymentMethods() {
    const result = await fetch(`${url}/regie/get-means-of-payment`, {
        method: 'GET'
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw "Une erreur est survenue lors de l'affichage des moyens de paiement.";
        });

    return result;
}

/**
 *
 * @param {*} billNumber
 * @param {*} bill
 * @returns
 */
export async function payBill(billNumber, payload) {
    const formData = new FormData();
    formData.append('payload', JSON.stringify(payload));
    console.log(payload);

    const result = await fetch(`${url}/regie/opt-update-bill/${billNumber}`, {
        method: 'POST',
        body: formData
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw 'Une erreur est survenue lors du règlement de la facture.';
        });

    return result;
}

/**
 *
 */
const LoadingIcon = <CircularProgress fontSize={25} />;
const SuccessIcon = <CheckCircleOutlineIcon fontSize={'large'} style={{ color: 'green' }} />;
const ErrorIcon = <HighlightOffIcon fontSize={'large'} color="error" />;
const InfoIcon = <InfoOutlinedIcon fontSize={'large'} style={{ color: 'orange' }} />;

/**
 *
 * @param {*} props
 * @returns
 */
export default function BillForm(props) {
    const { billNumber, setBillNumber } = props;
    const [open, setOpen] = useState(false);
    const [payed, setPayed] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [icon, setIcon] = useState(LoadingIcon);
    const [describe, setDescribe] = useState('Chargement en cours...');
    const [bill, setBill] = useState(null);
    const [errors, setErrors] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [bankCheck, setBankcheck] = useState('');
    const [bank, setBank] = useState('');
    const isPayingByCheck = paymentMethods.find(
        item => item.id === paymentMethod && item.label === 'Chèque'
    );

    useEffect(() => {
        if (open) {
            getBill(billNumber)
                .then(res => {
                    setBill(res);
                    setLoading(false);
                })
                .then(() =>
                    getpaymentMethods()
                        .then(data => setPaymentMethods(data))
                        .catch(err => alert(err))
                )
                .catch(err => {
                    setError(true);
                    setDescribe(err);
                    setIcon(ErrorIcon);
                });
        } else {
            setLoading(false);
            setBank('');
            setBankcheck('');
            setIcon(LoadingIcon);
            setError(false);
            setErrors(null);
            setPaymentMethod('');
            setDescribe('Chargement en cours...');
            setBill(null);
            setPayed(false);
        }
    }, [open]);

    useEffect(() => {
        setOpen(Boolean(billNumber));
        setLoading(Boolean(billNumber));
    }, [billNumber]);

    useEffect(() => {
        if (isValid && loading) {
            setDescribe(`Mise à jour de la facture N°${billNumber} en cours...`);
            setIcon(LoadingIcon);
            setIsValid(false);

            const payload = {
                ...bill.bill,
                paymentMethod: paymentMethod,
                bank: bank,
                bankCheck: bankCheck
            };

            payBill(billNumber, payload)
                .then(({ successfulTransaction }) => {
                    if (successfulTransaction) {
                        setDescribe(`Facture N°${billNumber} réglée avec succès.`);
                        setIcon(SuccessIcon);
                        setLoading(false);
                        setPayed(true);
                    } else {
                        setError(true);
                        setDescribe(`La facture N°${billNumber} a déjà été réglée.`);
                        setIcon(InfoIcon);
                        setLoading(false);
                    }
                })
                .catch(err => {
                    setError(err);
                    setDescribe(err);
                    setIcon(ErrorIcon);
                    setLoading(false);
                });
        }
    }, [isValid]);

    const handlePay = () => {
        const fErrors = {};

        if (empty(paymentMethod)) {
            fErrors.paymentMethod = 'Veuillez sélectionner un moyen de paiement.';
        }
        if (isPayingByCheck) {
            if (empty(bank)) {
                fErrors.bank = 'Veuillez renseigner une banque.';
            }

            if (!isValidBankCheck(bankCheck)) {
                fErrors.bankCheck =
                    'Veuillez renseigner un numéro de chèque (7 chiffres obligatoires)';
            }
        }

        setErrors(empty(fErrors) ? null : fErrors);
        setIsValid(empty(fErrors));
        setLoading(empty(fErrors));
    };

    return (
        <Dialog
            open={open}
            title={`Détail de facture`}
            onClose={() => setBillNumber(null)}
            action={
                <Button
                    disabled={loading || error || payed}
                    onClick={handlePay}
                    color="primary"
                    variant="contained"
                    endIcon={<EuroIcon />}>
                    Régler la facture
                </Button>
            }>
            {loading || error || payed ? (
                <Grid
                    container
                    direction="column"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center">
                    <Grid item>{icon}</Grid>
                    <Grid item>
                        <Typography variant="body1"> {describe} </Typography>
                    </Grid>
                </Grid>
            ) : (
                <BillDetail
                    {...bill}
                    errors={errors}
                    paymentMethods={{
                        set: setPaymentMethods,
                        value: paymentMethods
                    }}
                    paymentMethod={{
                        set: setPaymentMethod,
                        value: paymentMethod,
                        isPayingByCheck: isPayingByCheck
                    }}
                    bank={{
                        setBank: setBank,
                        setCheck: setBankcheck,
                        bank: bank,
                        check: bankCheck
                    }}
                />
            )}
        </Dialog>
    );
}

BillForm.propTypes = {
    setBillNumber: PropTypes.func.isRequired,
    billNumber: PropTypes.string.isRequired
};
