import React from 'react'

import {
    Divider,
    Grid,
    IconButton,
    Paper,
    Typography,
    Tooltip
} from '@material-ui/core'

import { withStyles } from '@material-ui/styles';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';


const styles = theme => ({
    root: {
        width: "36%",
        position: "absolute",
        bottom: 0,
        right: "50%",
        transform: "translateX(50%)",
        zIndex: 999,
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
});

class Actions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...props,
            currentAction: 0
        }
    }

    componentDidUpdate = (prevProps, prevState) => {

        (this.props.listOfPlacesWasRebuild && (prevProps.listOfPlacesWasRebuild != this.props.listOfPlacesWasRebuild)) &&
            this.setState({
                currentAction: this.props.actions.previous.count
            })
    }

    componentDidMount = () => {

    }

    forward = () => {
    }

    backward = () => {
        const {
            layersGroup,
            map,
            actions,
        } = this.props

        const currentAction = actions.previous.list[this.state.currentAction - 1];
        const backToAction = actions.previous.list[this.state.currentAction - 2];
        this.props.removePlace(currentAction.layer, false);
        backToAction.layer.setActivitie(backToAction.layer.getConfig("activitie"))
        backToAction.layer.addTo(layersGroup);
        backToAction.layer.getConfig('numero').label.addTo(layersGroup);
        this.props._onClick(backToAction.layer.getId())

        this.setState({
            currentAction: backToAction.actionNumero
        })
    }

    render() {

        const {
            classes,
            currentAction
        } = this.state

        const {
            actions
        } = this.props

        return (
            <Paper className={classes.root}>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Grid container justify="space-between" alignItems="center" spacing={1}>
                            <Grid item xs={12}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography color="primary">
                                            {actions.now.select.isSelected ? actions.now.select.text : actions.now.text}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="caption">
                                            {actions.now.select.isSelected ? "Action en cours" : "Dernière action"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                /*
                                <Grid item xs={2}>
                                <Tooltip title="Aller à l'action suivante" arrow placement="right">
                                    <IconButton onClick={this.forward}>
                                        <SkipNextIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                                */
                            }

                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Grid container justify="space-between" alignItems="center" spacing={1}>
                            <Grid item xs={12}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography>
                                            {actions.previous.list[currentAction - 2] ? actions.previous.list[currentAction - 2].text : "Aucune action n'a été effectuée"}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="caption">
                                            Action précédente
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                /*<Grid item xs={2}>
                                <Tooltip title="Revenir à l'action précédente" arrow placement="right">
                                    <IconButton disabled={Boolean(currentAction <= 1)} onClick={this.backward}>
                                        <SkipPreviousIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>*/
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        )

    }

}

export default withStyles(styles)(Actions);
