import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid, Typography, TextField } from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = theme => ({
    root: {
        //paddingRight: theme.spacing(2),
        //paddingLeft: theme.spacing(2)
    }
});

class Merchants extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            searchValue: '',
            merchants: []
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        (prevState.searchValue != this.state.searchValue ||
            prevProps.selectedActivitie != this.props.selectedActivitie) &&
            this.getData();
    };

    componentDidMount = () => {};

    getData = () => {
        const { placedMerchants, selectedActivitie } = this.props;

        const { searchValue } = this.state;

        const formData = new FormData();
        formData.append('search', searchValue);
        selectedActivitie != '' && formData.append('activitie', selectedActivitie);

        placedMerchants.map((merchant, index) => {
            formData.append(`merchants[${index}]`, merchant.id);
        });

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/get-merchants-by-for-creation`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleInputList = (value, reason) => {
        this.setState(prevState => ({
            searchValue: value,
            merchants: reason == 'reset' ? [] : prevState.merchants
        }));
    };

    render() {
        const { classes, searchValue, merchants } = this.state;

        const { selectedMerchant, selectedActivitie, isFree } = this.props;

        return (
            <Autocomplete
                options={merchants}
                name="merchants"
                autoHighlight
                disabled={isFree}
                value={selectedMerchant}
                onChange={(event, value) => {
                    this.props.handleSelectedMerchant(value);
                }}
                onInputChange={(event, value, reason) => {
                    this.handleInputList(value, reason);
                }}
                noOptionsText={"Aucun commerçant n'a été trouvé"}
                getOptionSelected={(option, value) => (value.id ? option.id == value.id : true)}
                getOptionLabel={option => (option.raisonSociale ? option.raisonSociale : '')}
                getOptionDisabled={option => option.isPlaced == '1'}
                renderOption={(option, value) => (
                    <Grid container direction="column" alignItems="stretch">
                        <Grid item>{option.raisonSociale}</Grid>
                        <Grid item>
                            <Typography variant="body2">
                                {option.metierLabel}/{option.activitieLabel}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                renderInput={params => (
                    <TextField
                        fullWidth
                        {...params}
                        size="small"
                        label="Commerçant"
                        variant="outlined"
                    />
                )}
            />
        );
    }
}

export default withStyles(styles)(Merchants);
