import React, { useEffect, useState } from 'react';
import { Dialog } from 'components';
import {
    Button,
    Grid,
    InputAdornment,
    TextField,
    IconButton,
    Paper,
    Typography,
    Divider,
    Stepper,
    Step,
    StepLabel,
    Table,
    TableRow,
    TableCell,
    TableBody,
    ListItemText,
    MenuItem
} from '@material-ui/core';
// import { toUpper } from 'ramda';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import NumberFormat from 'react-number-format';
import PdfFrame from './PdfFrame';
import { toUpper } from 'ramda';
import { createBill, getBill, editBill, generateNewBillNumber } from '../services/http';
import { makeStyles } from '@material-ui/styles';
import { Autocomplete } from '@material-ui/lab';
import { getUnits } from 'views/Unit/services/http';
import { isEmpty } from 'validate.js';

const DEFAULT_ROW = {
    label: '',
    qty: 1,
    amount: 1,
    unite_id: ''
};

const DEFAULT_STATE = {
    billNumber: '',
    totalAmount: 1,
    rows: [{ ...DEFAULT_ROW }],
    type: '',
    firstname: '',
    lastname: '',
    matriculation: ''
};

function CustomTextField(props) {
    const { inputRef, onChange, allowedToChangePrice, noIcon, ...other } = props;

    return (
        <TextField
            {...other}
            onChange={onChange}
            variant="outlined"
            fullWidth
            //helperText={props.name != 'total' && 'Saisir un prix unitaire'}
            InputProps={{
                readOnly: !allowedToChangePrice,
                endAdornment: noIcon ? null : (
                    <InputAdornment position="end">
                        <EuroSymbolIcon />
                    </InputAdornment>
                )
            }}
        />
    );
}

const useStyles = makeStyles(theme => ({
    table: {
        tableLayout: 'fixed'
    },
    tableBody: {
        '& tr:nth-child(even)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        },
        '& tr td': {
            paddingTop: 8,
            paddingBottom: 8
        },
        '& tr:first-child': {
            '& td': {
                borderTop: '1px solid #eee'
            }
        }
    }
}));

const steps = ['Edition', 'Visualisation'];

const BillDialog = ({ open, id, setId, billId }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [units, setUnits] = useState([]);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [state, setState] = useState({
        billNumber: '',
        rows: [],
        type: '',
        firstname: '',
        lastname: '',
        matriculation: '',
        city: '',
        address: '',
        postalCode: '',
        mark_model: ''
    });

    useEffect(() => {
        if (state.type !== 'car') {
            handleState('mark_model', '');
        }
    }, [state.type]);

    useEffect(() => {
        if (open.value) {
            setState({ ...DEFAULT_STATE });
            setActiveStep(0);

            if (billId) {
                getBill(billId)
                    .then(res =>
                        setState({
                            billNumber: res.number,
                            totalAmount: res.total,
                            rows: [...res.rows],
                            type: res.type,
                            firstname: res.firstname,
                            lastname: res.lastname,
                            matriculation: res.matriculation,
                            city: res.city,
                            address: res.address,
                            postalCode: res.postalCode,
                            mark_model: res.mark_model
                        })
                    )
                    .catch(err => alert(err));
            } else {
                generateNewBillNumber()
                    .then(res =>
                        setState({
                            ...DEFAULT_STATE,
                            billNumber: res.number
                        })
                    )
                    .catch(err => alert(err));
            }

            getUnits({ page: '*' }).then(res => setUnits(res.units));
        }
    }, [open.value]);

    const cleanUpAmount = rows => {
        return [
            ...rows.map(r => ({
                ...r,
                amount: parseFloat(r.amount ? `${r.amount}`.replace(',', '.') : 1.0)
            }))
        ];
    };

    const handleBill = () => {
        if (billId) {
            editBill(billId, { ...state, rows: cleanUpAmount(state.rows) })
                .then(() => open.func())
                .catch(err => alert(err))
                .finally(() => setLoading(false));

            return;
        }

        createBill({ ...state, rows: cleanUpAmount(state.rows) })
            .then(() => open.func())
            .catch(err => alert(err))
            .finally(() => setLoading(false));
    };

    const handleState = (k, v) => {
        setState({ ...state, [k]: v });
    };

    const handleRow = (i, k, v) => {
        const rows = [...state.rows];
        rows[i][k] = v;

        if (k === 'unite_id') {
            const unit = units.find(unit => unit.id === v);
            rows[i]['amount'] = isEmpty(unit.amount)
                ? 1.0
                : parseFloat(`${unit.amount}`.replace(',', '.'));
            rows[i]['label'] = unit.libelle;
        }

        const totalAmount = calculAmount(rows);

        setState({ ...state, rows, totalAmount });
    };

    const calculAmount = rows => {
        return rows.reduce(
            (acc, curr) =>
                acc +
                parseFloat(curr.qty ? `${curr.qty}`.replace(',', '.') : 1.0) *
                    parseFloat(curr.amount ? `${curr.amount}`.replace(',', '.') : 1.0),
            0
        );
    };

    const addRow = () => {
        const rows = [...state.rows];
        rows.push({ ...DEFAULT_ROW });
        const totalAmount = calculAmount(rows);

        setState({ ...state, rows, totalAmount });
    };

    const removeRow = idx => {
        const rows = [...state.rows].filter((_, i) => i !== idx);
        const totalAmount = calculAmount(rows);
        setState({ ...state, rows, totalAmount });
    };

    const moveRow = (idx, i) => {
        const rows = [...state.rows];

        const current = { ...rows[idx] };
        rows[idx] = { ...rows[idx + i] };
        rows[idx + i] = current;

        setState({ ...state, rows });
    };

    return (
        <Dialog
            open={open.value}
            title={billId ? 'Modifier la facture' : 'Créer une facture'}
            onClose={() => open.func()}
            action={
                <>
                    <Button
                        disabled={activeStep === 0}
                        onClick={() => setActiveStep(activeStep - 1)}
                        color="primary"
                        variant="contained">
                        Précédent
                    </Button>
                    <Button
                        disabled={activeStep === 1}
                        onClick={() => setActiveStep(activeStep + 1)}
                        color="primary"
                        variant="contained">
                        Suivant
                    </Button>
                    <Button
                        disabled={loading || activeStep === 0}
                        onClick={handleBill}
                        color="primary"
                        variant="contained">
                        {billId ? 'Modifier la facture' : 'Créer une facture'}
                    </Button>
                </>
            }>
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map(label => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                {activeStep === 1 ? (
                    <Grid item>
                        <PdfFrame id={id} details={state} />
                    </Grid>
                ) : (
                    <>
                        <Grid item>
                            <Typography variant="h4" color="primary">
                                Informations fourrière
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="flex" spacing={1} direction="row">
                                <Grid item>
                                    <Button
                                        onClick={() => handleState('type', 'car')}
                                        color="primary"
                                        variant={state.type === 'car' ? 'contained' : 'outlined'}>
                                        Véhicule
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => handleState('type', 'cyclo')}
                                        color="primary"
                                        variant={state.type === 'cyclo' ? 'contained' : 'outlined'}>
                                        Cyclo
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => handleState('type', 'animal')}
                                        color="primary"
                                        variant={
                                            state.type === 'animal' ? 'contained' : 'outlined'
                                        }>
                                        Animal
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Immatriculation"
                                name="nature"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={state.matriculation}
                                onChange={e => handleState('matriculation', e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                disabled={state.type !== 'car'}
                                label="Marque et modèle"
                                name="mark_model"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={state.mark_model}
                                onChange={e => handleState('mark_model', e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" color="primary">
                                Informations facture
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Numéro de facture"
                                placeholder="XX-XX-XXX"
                                name="billNumber"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={state.billNumber}
                                onChange={e => handleState('billNumber', toUpper(e.target.value))}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">N°</InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Prénom"
                                        name="nature"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={state.firstname}
                                        onChange={e => handleState('firstname', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Nom"
                                        name="nature"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={state.lastname}
                                        onChange={e => handleState('lastname', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Adresse"
                                        name="address"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={state.address}
                                        onChange={e => handleState('address', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Ville"
                                        name="city"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={state.city}
                                        onChange={e => handleState('city', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Code postal"
                                        name="postalCode"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={state.postalCode}
                                        onChange={e => handleState('postalCode', e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={3}>
                                {state.rows.map((row, i) => (
                                    <Grid item key={i}>
                                        <Paper style={{ padding: 14 }} elevation={1}>
                                            <Grid
                                                container
                                                direction="row"
                                                spacing={2}
                                                alignItems="center"
                                                alignContent="center">
                                                <Grid item xs={2}>
                                                    <Typography color="primary" variant="body2">
                                                        Ligne n°{i + 1}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <Divider />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: 7,
                                                            justifyContent: 'center'
                                                        }}>
                                                        <IconButton
                                                            size="small"
                                                            disabled={i === 0}
                                                            onClick={() => moveRow(i, -1)}>
                                                            <ArrowUpwardIcon fontSize="small" />
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            disabled={i === state.rows.length - 1}
                                                            onClick={() => moveRow(i, 1)}>
                                                            <ArrowDownwardIcon fontSize="small" />
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            disabled={state.rows.length === 1}
                                                            onClick={() => removeRow(i)}>
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Libellé"
                                                        name="nature"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        value={row.label}
                                                        onChange={e =>
                                                            handleRow(i, 'label', e.target.value)
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <NumberFormat
                                                        label="Quantité"
                                                        value={row.qty}
                                                        customInput={CustomTextField}
                                                        allowedToChangePrice={true}
                                                        onValueChange={v =>
                                                            handleRow(i, 'qty', v.value)
                                                        }
                                                        noIcon
                                                        thousandsGroupStyle="fr"
                                                        thousandSeparator={' '}
                                                        decimalSeparator={'.'}
                                                        type={'text'}
                                                        allowedDecimalSeparators={[
                                                            ',',
                                                            '.',
                                                            '?',
                                                            ';'
                                                        ]}
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        isNumericString
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <NumberFormat
                                                        label="Prix unitaire"
                                                        value={row.amount}
                                                        customInput={CustomTextField}
                                                        allowedToChangePrice={true}
                                                        onValueChange={v =>
                                                            handleRow(i, 'amount', v.value)
                                                        }
                                                        thousandsGroupStyle="fr"
                                                        thousandSeparator={' '}
                                                        decimalSeparator={','}
                                                        type={'text'}
                                                        allowedDecimalSeparators={[
                                                            ',',
                                                            '.',
                                                            '?',
                                                            ';'
                                                        ]}
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        isNumericString
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        label="Unité"
                                                        value={row.unite_id}
                                                        onChange={v =>
                                                            handleRow(i, 'unite_id', v.target.value)
                                                        }
                                                        variant="outlined">
                                                        {units.map(unit => (
                                                            <MenuItem key={unit.id} value={unit.id}>
                                                                {unit.libelle}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                ))}
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        color="secondary"
                                        onClick={addRow}>
                                        Ajouter une ligne
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <NumberFormat
                                label="Montant net"
                                value={state.totalAmount}
                                customInput={CustomTextField}
                                allowedToChangePrice={true}
                                onValueChange={v => handleState('totalAmount', v.value)}
                                thousandsGroupStyle="fr"
                                thousandSeparator={' '}
                                decimalSeparator={','}
                                type={'text'}
                                allowedDecimalSeparators={[',', '.', '?', ';']}
                                allowNegative={false}
                                decimalScale={2}
                                isNumericString
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Dialog>
    );
};

export default BillDialog;
