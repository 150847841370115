import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
// add

import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: "green",
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },

  difference: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(10)
  },
  differenceAction: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  },
  labelAction: {
    width: '100%',
    textAlign: 'center'
  }
}));

const Prices = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (

    <CardActionArea>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent>
          <Grid
            container
            justify="space-between"
          >
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body1"
              >
                Tarification
            </Typography>
              <Typography
                gutterBottom
                className={classes.caption}
                component="span"
                variant="body2"
              >
                <ul style={{listStyle: "inside circle"}}>
                  <li>Tarif mensuel abonné : <strong>7€</strong></li>
                  <li>Tarif passager : <strong>2€</strong></li>
                  <li>Changement de place <strong>non autorisé</strong></li>
                  <li>Supplément de mètres autorisé : <strong>3€</strong></li>
                </ul>
            </Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <LocalOfferIcon className={classes.icon} />
              </Avatar>
            </Grid>
          </Grid>

          <div className={classes.differenceAction}>
            <Typography
              className={classes.labelAction}
              variant="subtitle2"
              color="primary"
            >
              Configurer les tarifications
            </Typography>
          </div>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};


export default Prices;
