import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';

import { Grid, Typography, Button, CircularProgress } from '@material-ui/core';

import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles(theme => ({
    root: {}
}));

const SendButton = props => {
    const { className, numFacture, hasMail, marchandId, update, ...rest } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [isSending, setIsSending] = React.useState(false);

    const send = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/send-bill/${numFacture}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                setIsSending(false);
                update();
                if (data.sent > 0) {
                    alert(`Facture N°${numFacture} envoyée avec succès.`);
                } else {
                    alert(`Echec de l'envoi de la facture N°${numFacture}.`);
                }
            })
            .catch(error => {
                console.error(error);
                setIsSending(false);
                update();
                alert(`Echec de l'envoi de la facture N°${numFacture}.`);
            });
    };

    const checkUpBeforeSending = () => {
        if (hasMail) {
            setIsSending(true);
            send();
        } else {
            const newMail = window.prompt(
                "Ce commerçant ne possède par d'adresse mail.\nVeuillez renseigner une adresse mail ci-dessous."
            );

            if (!newMail) {
                return;
            }

            if (newMail.replace(/\s/g, '').length == 0 || newMail.length == 0) {
                alert('Adresse mail incorrect.');
                checkUpBeforeSending();
            }

            const formData = new FormData();
            formData.append('field', 'mailMarchand');
            formData.append('data', newMail);

            fetch(
                `https://www.${
                    window.location.hostname.split('.')[1]
                        ? window.location.hostname.split('.')[0] === 'www'
                            ? window.location.hostname.split('.')[1]
                            : window.location.hostname.split('.')[0]
                        : 'dev'
                }.api.digitmarche.fr/api/merchant/update-fields-missed/${marchandId}`,
                {
                    method: 'POST',
                    body: formData
                }
            )
                .then(res => res.json())
                .then(data => {
                    if (data) {
                        setIsSending(true);
                        send();
                    }
                })
                .catch(error => {
                    console.error(error);
                    alert(`Echec de la mise à jour.`);
                });
        }
    };

    return (
        <div className={classes.root}>
            {isSending ? (
                <CircularProgress size={25} color="primary" />
            ) : (
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    endIcon={<MailIcon />}
                    onClick={checkUpBeforeSending}>
                    Envoyer
                </Button>
            )}
        </div>
    );
};

export default SendButton;
