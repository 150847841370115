import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { TabControl } from 'components';
import { Form, TotalAddedCard } from './components';
import All from './All';
import Creation from './Creation';
import Edit from './Edit';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paperContent: {
        padding: theme.spacing(4)
        //padding: 10
    },
    item: {
        minWidth: '30%'
    }
}));

const NewTerrace = props => {
    const classes = useStyles();
    const { tab, id } = props.match.params;

    return (
        <div className={classes.root}>
            <Grid container spacing={4}>
                <Grid item md={6} sm={6} xs={12}>
                    <TotalAddedCard raised={tab === 'create'} />
                </Grid>
                <Grid item xs={12}>
                    <TabControl value={tab ?? ''} index={''}>
                        <All />
                    </TabControl>
                    <TabControl value={tab} index={'create'}>
                        <Creation history={props.history} />
                    </TabControl>
                    <TabControl value={tab} index={'edit'}>
                        <Edit history={props.history} id={id} />
                    </TabControl>
                    <TabControl value={tab} index={'get'}>
                        {/* <MyTerrace /> */}
                    </TabControl>
                </Grid>
            </Grid>
        </div>
    );
};

export default NewTerrace;
