import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    TextField,
    Grid,
    IconButton,
    InputAdornment,
    Divider,
    CircularProgress,
    Checkbox,
    FormControlLabel,
    FormLabel,
    FormGroup,
    FormControl,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    ListItemText
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import { Pagination } from './components';

const styles = theme => ({
    root: {
        //flexGrow: 1
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

class ListDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            init: true,
            page: 1,
            numberOfPages: 0,
            searchValue: '',
            choosenFilters: [],
            filterHasChanged: false,
            filtersToUse: [],
            filters: [],
            headers: [],
            rows: []
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            this.state.filterHasChanged &&
            prevState.filterHasChanged != this.state.filterHasChanged
        ) {
            this.getData();
        }

        if (this.state.page != prevState.page || this.state.searchValue != prevState.searchValue) {
            this.getData();
        }
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        const { journalMarketId, link } = this.props;

        const { init, choosenFilters, filtersToUse, page, searchValue } = this.state;

        const formData = new FormData();
        formData.append('init', init);
        formData.append('page', page);
        formData.append('search', searchValue);
        formData.append('filters', JSON.stringify(choosenFilters));
        formData.append('filtersToUse', JSON.stringify(filtersToUse));

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/get-detail-list-for-dashboard/${link}/${journalMarketId}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    init: false,
                    filterHasChanged: false
                });
            })
            .catch(error => {
                this.setState({
                    init: false,
                    filterHasChanged: false
                });
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    handleFilters = ({ target: { name } }) => {
        const { choosenFilters } = this.state;

        const index = choosenFilters.indexOf(name);

        if (index > -1) {
            choosenFilters.splice(index, 1);
        } else {
            choosenFilters.push(name);
        }

        this.setState({
            choosenFilters: choosenFilters,
            filterHasChanged: true,
            page: 1
        });
    };

    handlePagination = value => {
        this.setState({
            page: value
        });
    };

    render() {
        const {
            classes,
            filters,
            headers,
            rows,
            searchValue,
            init,
            choosenFilters,
            numberOfPages,
            page
        } = this.state;

        return (
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <TextField
                                label={'Rechercher un commerçant'}
                                type="text"
                                //size="small"
                                fullWidth
                                disabled={init}
                                value={searchValue}
                                onChange={this.handleSearch}
                                variant="outlined"
                                helperText="Saisir un numéro de place, une raison sociale, une enseigne, un nom ou un prénom"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    this.handleSearch({ target: { value: '' } })
                                                }
                                                edge="end">
                                                <ClearIcon style={{ color: 'red' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            {!init && (
                                <Grid container direction="column" spacing={1}>
                                    {filters.keys.map(key => {
                                        return (
                                            <Grid item>
                                                <FormControl component="fieldset">
                                                    <FormLabel
                                                        component="legend"
                                                        className={classes.labelFilter}>
                                                        {key.label}
                                                    </FormLabel>
                                                    <FormGroup row>
                                                        {filters[key.key].map(filter => {
                                                            return (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            size="small"
                                                                            onChange={
                                                                                this.handleFilters
                                                                            }
                                                                            name={filter.key}
                                                                            checked={Boolean(
                                                                                choosenFilters.indexOf(
                                                                                    filter.key
                                                                                ) > -1
                                                                            )}
                                                                            style={{
                                                                                color: filter.color
                                                                            }}
                                                                        />
                                                                    }
                                                                    label={filter.label}
                                                                />
                                                            );
                                                        })}
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {init ? (
                        <Grid container direction="row" alignItems="center" justify="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    ) : (
                        <TableContainer className={classes.container}>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        {headers.map(header => (
                                            <TableCell
                                                size="small"
                                                variant="head"
                                                key={header.key}
                                                align={'left'}
                                                //style={{ minWidth: column.minWidth }}
                                            >
                                                {header.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map(row => {
                                        return (
                                            <TableRow hover key={row._index}>
                                                {headers.map(header => {
                                                    return (
                                                        <TableCell
                                                            variant="body"
                                                            key={header.key}
                                                            size="small"
                                                            align="left">
                                                            <ListItemText
                                                                primary={row[header.key]}
                                                                secondary={
                                                                    header.subkey
                                                                        ? row[header.subkey]
                                                                        : null
                                                                }
                                                            />
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Grid>
                <Grid item>
                    <Pagination
                        numberOfPages={numberOfPages}
                        currentPage={page}
                        handlePagination={this.handlePagination}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ListDetail);
