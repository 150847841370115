/**
 *
 * @param  {...any} styles
 * @returns
 */
export function combineStyles(...styles) {
    styles.push(base);

    return function CombineStyles(theme) {
        const outStyles = styles.map(arg => {
            if (typeof arg === 'function') {
                return arg(theme);
            }
            return arg;
        });

        return outStyles.reduce((acc, val) => Object.assign(acc, val));
    };
}

const base = theme => ({
    paid: {
        color: theme.palette.paid.normal,
        borderColor: theme.palette.paid.normal,
        '& *': {
            color: theme.palette.paid.normal,
            '& button': {
                borderColor: theme.palette.paid.normal
            }
        },
        '&:hover': {
            color: theme.palette.paid.hover,
            borderColor: theme.palette.paid.hover,
            '& *': {
                color: theme.palette.paid.hover,
                '& button': {
                    borderColor: theme.palette.paid.hover
                }
            },
            '& button': {
                borderColor: theme.palette.paid.hover
            }
        }
    },
    unpaid: {
        color: theme.palette.unpaid.normal,
        borderColor: theme.palette.unpaid.normal,
        '& *': {
            color: theme.palette.unpaid.normal,
            '& button': {
                borderColor: theme.palette.unpaid.normal
            }
        },
        '&:hover': {
            color: theme.palette.unpaid.hover,
            borderColor: theme.palette.unpaid.hover,
            '& *': {
                color: theme.palette.unpaid.hover,
                '& button': {
                    borderColor: theme.palette.unpaid.hover
                }
            },
            '& button': {
                borderColor: theme.palette.unpaid.hover
            }
        }
    },
    canceled: {
        color: theme.palette.canceled.normal,
        borderColor: theme.palette.canceled.normal,
        '& *': {
            color: theme.palette.canceled.normal,
            '& button': {
                borderColor: theme.palette.canceled.normal
            }
        },
        '&:hover': {
            color: theme.palette.canceled.hover,
            borderColor: theme.palette.canceled.hover,
            '& *': {
                color: theme.palette.canceled.hover,
                '& button': {
                    borderColor: theme.palette.canceled.hover
                }
            },
            '& button': {
                borderColor: theme.palette.canceled.hover
            }
        }
    },
    late: {
        color: theme.palette.late.normal,
        borderColor: theme.palette.late.normal,
        '& *': {
            color: theme.palette.late.normal,
            '& button': {
                borderColor: theme.palette.late.normal
            }
        },
        '&:hover': {
            color: theme.palette.late.hover,
            borderColor: theme.palette.late.hover,
            '& *': {
                color: theme.palette.late.hover,
                '& button': {
                    borderColor: theme.palette.late.hover
                }
            },
            '& button': {
                borderColor: theme.palette.late.hover
            }
        }
    },
    ta: {
        color: theme.palette.ta.normal,
        borderColor: theme.palette.ta.normal,
        '& *': {
            color: theme.palette.ta.normal,
            '& button': {
                borderColor: theme.palette.ta.normal
            }
        },
        '&:hover': {
            color: theme.palette.ta.hover,
            borderColor: theme.palette.ta.hover,
            '& *': {
                color: theme.palette.ta.hover,
                '& button': {
                    borderColor: theme.palette.ta.hover
                }
            },
            '& button': {
                borderColor: theme.palette.ta.hover
            }
        }
    },
    tna: {
        color: theme.palette.tna.normal,
        borderColor: theme.palette.tna.normal,
        '& *': {
            color: theme.palette.tna.normal,
            '& button': {
                borderColor: theme.palette.tna.normal
            }
        },
        '&:hover': {
            color: theme.palette.tna.hover,
            borderColor: theme.palette.tna.hover,
            '& *': {
                color: theme.palette.tna.hover,
                '& button': {
                    borderColor: theme.palette.tna.hover
                }
            },
            '& button': {
                borderColor: theme.palette.tna.hover
            }
        }
    },
    p: {
        color: theme.palette.p.normal,
        borderColor: theme.palette.p.normal,
        '& *': {
            color: theme.palette.p.normal,
            '& button': {
                borderColor: theme.palette.p.normal
            }
        },
        '&:hover': {
            color: theme.palette.p.hover,
            borderColor: theme.palette.p.hover,
            '& *': {
                color: theme.palette.p.hover,
                '& button': {
                    borderColor: theme.palette.p.hover
                }
            },
            '& button': {
                borderColor: theme.palette.p.hover
            }
        }
    }
});

export { base };
