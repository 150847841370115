import React from 'react';
import { makeStyles } from '@material-ui/styles';
import ReactWeather, { useOpenWeather } from 'react-open-weather';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: "none !important"
    },
}));

const Weather = props => {

    const { className, marketData, ...rest } = props;

    const { data, isLoading, errorMessage } = useOpenWeather({
        key: 'ea1c78e0f9f5c27b6eb80e3bf2a5f891',
        lat: marketData.adresse.coordinates.lat,
        lon: marketData.adresse.coordinates.lng,
        lang: 'fr',
        unit: 'metric', // values are (metric, standard, imperial)
    });

    const classes = useStyles();

    return (
        <ReactWeather
            className={classes.root}
            isLoading={isLoading}
            errorMessage={errorMessage}
            data={data}
            lang="fr"
            locationLabel={marketData.label}
            unitsLabels={{ temperature: '°C', windSpeed: 'Km/h' }}
            showForecast={false}
        />
    );
};

export default Weather;