import React, { useState, useEffect } from 'react';
import { Button, Grid, TableCell, TableRow, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { MuiTable } from 'components';
import { SettingsCellOutlined } from '@material-ui/icons';

/**
 * url
 */
const url = `https://www.${
    window.location.hostname.split('.')[1]
        ? window.location.hostname.split('.')[0] === 'www'
            ? window.location.hostname.split('.')[1]
            : window.location.hostname.split('.')[0]
        : 'dev'
}.api.digitmarche.fr/api`;

/**
 *
 * @param {*} journalMarketId
 * @param {*} activities
 */
async function showMerchantBeforeDrawing(journalMarketId, activities, page, order, orderBy) {
    const formData = new FormData();
    formData.append('activities', JSON.stringify(activities));
    formData.append('page', page);
    formData.append('order', order);
    formData.append('orderBy', orderBy);

    const result = await fetch(`${url}/placement/show-merchant-before-drawing/${journalMarketId}`, {
        method: 'POST',
        body: formData
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw "Une erreur est survenue lors de l'affichage des commerçants.";
        });

    return result;
}

const headers = [
    {
        id: 'raisonSociale',
        align: 'left',
        label: 'Raison sociale',
        sortable: true
    },
    { id: 'libelleMetier', align: 'left', label: 'Métier', sortable: true }
];

const TableDraw = props => {
    const { id, activities } = props;
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(headers[0].id);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        mounted && setLoading(true);
    }, [activities, page, order, orderBy]);

    useEffect(() => {
        loading &&
            showMerchantBeforeDrawing(id, activities, page, order, orderBy)
                .then(data => {
                    setCount(data.count);
                    setRows(data.rows);
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                });
    }, [loading]);

    return (
        <MuiTable
            refresh={() => setLoading(true)}
            //print={() => printSubscribersBills(start, end, type)}
            loading={loading}
            headers={headers}
            page={{
                set: val => setPage(val),
                data: page,
                count: count
            }}
            order={{
                set: val => setOrder(val),
                data: order
            }}
            orderBy={{
                set: val => setOrderBy(val),
                data: orderBy
            }}>
            {rows.map((row, index) => (
                <TableRow hover key={`${index}-${row.id}`}>
                    <TableCell align="left" key={`socialReason-${index}-${row.id}`}>
                        <Typography noWrap>{row.raisonSociale}</Typography>
                    </TableCell>
                    <TableCell align="left" key={`job-${index}-${row.id}`}>
                        <Typography noWrap>{row.libelleMetier}</Typography>
                    </TableCell>
                </TableRow>
            ))}
        </MuiTable>
    );
};

TableDraw.propTypes = {};

export default TableDraw;
