import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
        //minWidth: 290,
        //margin: "auto",
    }
}));

const Overview = props => {
    const { className, encaisse, nonencaisse, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    let total = parseFloat(encaisse.raw) + parseFloat(nonencaisse.raw);
    let per_cashed = (parseFloat(encaisse.raw) / total) * 100;
    let per_not_cashed = (parseFloat(nonencaisse.raw) / total) * 100;

    if (parseInt(total) == 0) {
        per_cashed = 0;
        per_not_cashed = 0;
    }

    if (parseInt(encaisse.raw) == 0) {
        per_cashed = 0;
    }

    if (parseInt(nonencaisse.raw) == 0) {
        per_not_cashed = 0;
    }

    return (
        <div className={classes.root}>
            <Card
                {...rest}
                className={clsx(classes.root, className)}
                style={{ overflow: 'hidden' }}>
                <div
                    style={{
                        position: 'absolute',
                        left: per_cashed + '%',
                        width: per_not_cashed + '%',
                        height: '100%',
                        background: 'red',
                        opacity: 0.1
                    }}></div>
                <div
                    style={{
                        position: 'absolute',
                        width: per_cashed + '%',
                        height: '100%',
                        background: 'green',
                        opacity: 0.1
                    }}></div>
                <CardContent>
                    <Grid container>
                        <Grid item lg={12} sm={12} xl={12} xs={12} style={{ marginBottom: 10 }}>
                            <Typography align="left" variant="body2">
                                Les recettes
                            </Typography>
                        </Grid>

                        <Grid
                            container
                            item
                            direction="row"
                            justify="center"
                            alignItems="center"
                            style={{ width: '100%' }}>
                            <Grid
                                item
                                container
                                xs={6}
                                direction="column"
                                justify="center"
                                alignItems="flex-start">
                                <Grid item>
                                    <Typography variant="h3" style={{ color: 'green' }}>
                                        {encaisse.total} €
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" style={{ color: 'green' }}>
                                        réglé au total
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                container
                                xs={6}
                                direction="column"
                                justify="center"
                                alignItems="flex-end">
                                <Grid item>
                                    <Typography variant="h3" style={{ color: 'red' }}>
                                        {nonencaisse.total} €
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" style={{ color: 'red' }}>
                                        reste à régler
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export default Overview;
