import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Grid,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Checkbox,
    FormGroup
} from '@material-ui/core';
import { Dialog } from 'components';
import CheckIcon from '@material-ui/icons/Check';
import { Label } from './components';
import { updateAttendanceRegisterInBulkByActivitieByHolder } from './http';

const useStyles = makeStyles(theme => ({
    root: {}
}));

const BulkFormDialog = props => {
    const { open, onClose, id, ...rest } = props;
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('1');
    const [selectedActivities, setSelectedActivities] = useState([]);
    const classes = useStyles();

    const handleActivities = v => {
        const arr = [...selectedActivities];
        const index = arr.indexOf(v);
        index === -1 ? arr.push(v) : arr.splice(index, 1);

        setSelectedActivities(arr);
    };

    useEffect(() => {
        loading && makeUpdate();
    }, [loading]);

    const makeUpdate = () => {
        updateAttendanceRegisterInBulkByActivitieByHolder(id, selectedActivities, status)
            .then(() => {
                onClose();
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                alert(err);
            });
    };

    return (
        <Dialog
            loading={loading}
            title="Action titulaire"
            open={open}
            onClose={() => onClose()}
            action={
                <Button
                    disabled={loading}
                    color="primary"
                    variant="contained"
                    endIcon={<CheckIcon />}
                    onClick={() => setLoading(true)}>
                    Valider
                </Button>
            }>
            <Grid container spacing={4} direction="column">
                <Grid item>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component={Label} className={classes.label}>
                            Statut des commerçants
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-label="status"
                            name="status"
                            value={status}
                            onChange={({ target: { value } }) => setStatus(value)}>
                            <FormControlLabel value="1" control={<Radio />} label="Tous présents" />
                            <FormControlLabel value="0" control={<Radio />} label="Tous absents" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component={Label} className={classes.label}>
                            Type d'activité
                        </FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(selectedActivities.find(v => v === 1))}
                                        onChange={e => handleActivities(1)}
                                        name="alimentaire"
                                    />
                                }
                                label="Alimentaire"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(selectedActivities.find(v => v === 2))}
                                        onChange={e => handleActivities(2)}
                                        name="primeur"
                                    />
                                }
                                label="Primeur"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(selectedActivities.find(v => v === 3))}
                                        onChange={e => handleActivities(3)}
                                        name="manu"
                                    />
                                }
                                label="Manufacturé"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(selectedActivities.find(v => v === 4))}
                                        onChange={e => handleActivities(4)}
                                        name="demo"
                                    />
                                }
                                label="Démonstrateur/Posticheur"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default BulkFormDialog;
