import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Dialog } from 'components';
import { getMerchant, payBills } from './action';
import EuroIcon from '@material-ui/icons/Euro';
import {
    Button,
    Grid,
    ListItemText,
    Table,
    TableBody,
    TableRow,
    Typography,
    TableCell
} from '@material-ui/core';
import FilterButton from './FilterButton';
import Done from './Done';
import GroupedBills from './GroupedBills';
import PaymentForm from './PaymentForm';
import { empty } from 'common/validators';

const styles = theme => ({
    table: {
        tableLayout: 'fixed'
    },
    tableBody: {
        '& tr:nth-child(even)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        },
        '& tr td': {
            paddingTop: 8,
            paddingBottom: 8
        },
        '& tr:first-child': {
            '& td': {
                borderTop: '1px solid #eee'
            }
        }
    }
});

/**
 *
 * @param {*} props
 * @returns
 */
const DEFAULT_STATE = props => ({
    step: 0,
    transactions: [],
    bills: [],
    filters: [],
    merchant: null,
    loading: false,
    paymentMethod: { value: '', check: '', bank: '', errors: null }
});

export class GroupedBillsForm extends Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE(props);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.props.id && prevProps.id !== this.props.id) {
            this.setState(DEFAULT_STATE(this.props));
        }

        if (this.props.id && prevProps.id !== this.props.id) {
            getMerchant(this.props.id)
                .then(res => this.setState({ merchant: { ...res } }))
                .catch(err => alert(err));
        }
        if (this.state.step === 0 && this.state.step !== prevState.step) {
            this.setState({
                bills: [],
                paymentMethod: { value: '', check: '', bank: '', errors: null }
            });
        }

        if (this.state.step === 1 && this.state.step !== prevState.step) {
            if (this.state.bills.length === 0) {
                const errors = {
                    bills: "Veuillez sélectionner au moins une facture dans l'étape précédente"
                };

                this.setState({
                    paymentMethod: {
                        ...this.state.paymentMethod,
                        errors
                    }
                });
            }
        }

        if (this.state.loading && this.state.loading != prevState.loading) {
            payBills(this.state.bills, this.state.paymentMethod)
                .then(res =>
                    this.setState({
                        transactions: res,
                        loading: false
                    })
                )
                .catch(err => {
                    console.error(err);
                    this.setState({
                        transactions: err,
                        loading: false
                    });
                });
        }
    };

    updateList(name, value, all = false, key = '') {
        const bf = [];

        if (all) {
            value.map(row => bf.push(row[key]));
        } else {
            this.state[name].map(row => bf.push(row));

            const index = bf.indexOf(value);

            if (bf.indexOf(value) > -1) {
                bf.splice(index, 1);
            } else {
                bf.push(value);
            }
        }

        this.setState({
            [name]: bf
        });
    }

    handleStep = i => {
        this.setState(prevState => ({
            step: prevState.step + i
        }));
    };

    handlePayementMethod = (value, bank = '', check = '') => {
        this.setState({
            paymentMethod: { ...this.state.paymentMethod, value, bank, check }
        });
    };

    prepare = () => {
        const {
            step,
            paymentMethod: { value, check, bank },
            bills
        } = this.state;

        const errors = {};

        if (bills.length === 0) {
            errors.bills = "Veuillez sélectionner au moins une facture dans l'étape précédente";
        } else {
            if (empty(value)) {
                errors.value = 'Veuillez sélectionner un moyen de paiement.';
            }

            if (value === '2') {
                if (empty(check)) {
                    errors.bank = 'Veuillez renseigner une banque.';
                }

                if (empty(bank)) {
                    errors.check =
                        'Veuillez renseigner un numéro de chèque (7 chiffres obligatoires)';
                }
            }
        }

        this.setState({
            loading: empty(errors),
            step: empty(errors) ? step + 1 : step,
            paymentMethod: {
                ...this.state.paymentMethod,
                errors
            }
        });
    };

    render() {
        const { merchant, bills, filters, step, paymentMethod, transactions, loading } = this.state;
        const { id, close, classes, start, end } = this.props;

        return (
            <Dialog
                title="Détail des factures"
                onClose={!loading && close}
                open={id}
                action={
                    <>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={step < 1 || loading}
                            onClick={() => this.handleStep(-1)}>
                            Précèdent
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={step > 1}
                            endIcon={step === 1 ? <EuroIcon /> : null}
                            onClick={() => (step === 1 ? this.prepare() : this.handleStep(1))}>
                            {step < 1 ? 'Suivant' : 'Régler'}
                        </Button>
                    </>
                }>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid
                            container
                            direction="column"
                            justifyContent="flex-start"
                            justify="flex-start"
                            spacing={2}>
                            <Grid item>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item>
                                        <Typography variant="button" color="primary">
                                            Commerçant
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Table size="small" className={classes.table}>
                                            <TableBody className={classes.tableBody}>
                                                <TableRow hover key={`socialeReason`}>
                                                    <TableCell align="left">
                                                        <ListItemText
                                                            primary={merchant?.socialeReason}
                                                            secondary={`${merchant?.firstname ??
                                                                ''} ${merchant?.lastname ??
                                                                ''} ${merchant?.commercialName ??
                                                                ''}`}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <ListItemText
                                                            primary={merchant?.job}
                                                            secondary={merchant?.activitie}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {step === 0 ? (
                        <>
                            <Grid item>
                                <FilterButton
                                    id={id}
                                    filters={{
                                        update: (a, b, c) => this.updateList('filters', a, b, c),
                                        get: filters
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <GroupedBills
                                    id={id}
                                    start={start}
                                    end={end}
                                    filters={filters}
                                    bills={{
                                        update: (a, b, c) => this.updateList('bills', a, b, c),
                                        get: bills
                                    }}
                                />
                            </Grid>
                        </>
                    ) : step === 1 ? (
                        <Grid item>
                            <PaymentForm
                                bills={bills}
                                merchant={merchant}
                                paymentMethod={{
                                    set: (a, b, c) => this.handlePayementMethod(a, b, c),
                                    get: paymentMethod
                                }}
                            />
                        </Grid>
                    ) : (
                        <Grid item>
                            <Done loading={loading} transactions={transactions} />
                        </Grid>
                    )}
                </Grid>
            </Dialog>
        );
    }
}

export default withStyles(styles)(GroupedBillsForm);
