import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Avatar
} from '@material-ui/core';
import EuroIcon from '@material-ui/icons/Euro';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.success.main,
        height: 56,
        width: 56
    },
    number: {
        color: theme.palette.success.main,
    },
    icon: {
        height: 32,
        width: 32
    },
    labelAction: {
        width: '100%',
        textAlign: 'center'
    },
    footer: {
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        textAlign: "center"
    },
}));

const TotalLate = props => {
    const { className, data, ...rest } = props;

    const classes = useStyles();

    const formatCash = (value) => {
        let montants = (parseFloat(value)).toString().split(".");
        return montants[0] + ", " + (montants[1] ? (montants[1] + "00")[0] + (montants[1] + "00")[1] : "00") + " €"
    }

    return (

        <Card>
            <CardContent>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            //className={classes.title}
                            gutterBottom
                            variant="body2"
                        >
                            Factures réglées
                        </Typography>
                        <Grid container direction="row"
                            justify="flex-start"
                            alignItems="flex-end" spacing={1}>
                            <Grid item>
                                <Typography variant="h3">{data.montant} €</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <EuroIcon className={classes.icon} style={{ width: 32, height: 32 }} />
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default TotalLate;
