import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    TextField,
    Typography,
    Grid,
    FormControl,
    FormLabel,
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { T } from 'ramda';

const styles = theme => ({
    root: {
        flexGrow: 1
    }
});

class MerchantInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            list: [],
            searchValue: ''
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.type != this.props.type ||
            prevProps.numFacture != this.props.numFacture ||
            prevState.searchValue != this.state.searchValue
        ) {
            if (this.state.searchValue == '') {
                this.setState({
                    list: []
                });
            } else {
                this.getData();
            }
        }
    };

    componentDidMount = () => {
        //this.getData();
    };

    getData = () => {
        const { type, id } = this.props;

        const { searchValue } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-merchant-not-cashed-form/${id}?type=${type}&search=${searchValue}`,
            {
                // Your POST endpoint
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                //this.props.setMerchant([]);
                this.setState({
                    ...data
                });
            })
            .catch(error => {
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    handleList = (event, value) => {
        this.props.setMerchant(value);
    };

    handleType = ({ target: { name } }) => {
        this.props.setType(name);
    };

    handleInputList = value => {
        this.setState(prevState => ({
            searchValue: value
            //list: value == "" ? [] : prevState.list
        }));
    };

    renderOptionsForm = option => {
        return option.raisonSociale !== undefined
            ? `${option.raisonSociale}${option.numero ? ' (N° ' + option.numero + ')' : ''} - ${
                  option.otherIntel
              }`
            : '';
    };

    render() {
        const { list, searchValue } = this.state;

        const { selectedMerchant, type } = this.props;

        return (
            <Grid container direction="column" spacing={1} alignItems="stretch">
                <Grid item>
                    <FormControl required component="fieldset">
                        <FormLabel color="primary">Sélectionnez un commerçant</FormLabel>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={this.handleType}
                                        checked={Boolean(type == 'ta')}
                                        name="ta"
                                    />
                                }
                                label="Titulaires abonnés seulement"
                            />
                        </FormGroup>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Autocomplete
                        options={list}
                        name="listOfMerchant"
                        autoHighlight
                        value={selectedMerchant}
                        onChange={this.handleList}
                        onInputChange={(event, value) => {
                            this.handleInputList(value);
                        }}
                        noOptionsText={"Aucun commerçant n'a été trouvé"}
                        getOptionLabel={option => this.renderOptionsForm(option)}
                        getOptionSelected={(option, value) => option.id == value.id}
                        getOptionDisabled={(option, value) =>
                            option.allowBlocked == '0' && option.blocked == '1'
                        }
                        renderOption={(option, value) => (
                            <Grid container direction="row" justify="space-between">
                                <Grid item>
                                    <Grid container direction="column" alignItems="stretch">
                                        <Grid item>
                                            <Typography
                                                style={{
                                                    color: option.blocked == '1' ? 'red' : 'default'
                                                }}>
                                                {option.raisonSociale}
                                                {option.numero ? ' (N° ' + option.numero + ')' : ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body2"
                                                style={{
                                                    color: option.blocked == '1' ? 'red' : 'default'
                                                }}>
                                                {option.otherIntel}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            color: option.blocked == '1' ? 'red' : 'default'
                                        }}>
                                        {option.status}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        renderInput={params => (
                            <TextField
                                fullWidth
                                {...params}
                                helperText={
                                    'Saisir une raison sociale, une enseigne, un nom ou un prénom'
                                }
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(MerchantInput);
