// @flow

import L from 'leaflet';
import React from 'react';
import { withStyles } from '@material-ui/styles';
import { withLeaflet } from 'react-leaflet';

import { Button, ButtonGroup, Paper } from '@material-ui/core';

import { InfoPlace } from './components';

import MarketPlace from './MarketPlace';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const none = {
    siret: '0',
    raisonSociale: 'Non affecté',
    metier: 'Aucun',
    numero: false
};

const activities = [
    {
        id: 0,
        key: 'none',
        label: '',
        color: 'whitesmoke'
    },
    {
        id: 1,
        key: 'alim',
        label: 'Alimentaire',
        color: 'gold'
    },
    {
        id: 2,
        key: 'prim',
        label: 'Primeur',
        color: '#32CD32	'
    },
    {
        id: 3,
        key: 'manu',
        label: 'Manufacturé',
        color: '#87CEFA'
    },
    {
        id: 4,
        key: 'dempos',
        label: 'Démonstrateur/Posticheur',
        color: '#BD33A4'
    }
];

const styles = theme => ({
    root: {
        //padding: 10
    },
    zoomButton: {
        padding: 10,
        position: 'absolute',
        zIndex: 1000
    },
    info: {
        position: 'absolute',
        padding: 10,
        zIndex: 1000,
        width: '100%',
        top: '100%',
        transform: 'translateY(-115%)'
    }
});

class Control extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            list: [],
            moved: false,
            containerBounds: this.props.leaflet.map.getBounds(),
            layers: {
                selectedLayer: null,
                layersGroup: L.featureGroup()
            }
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.args.searchValue != this.props.args.searchValue ||
            prevProps.args.page != this.props.args.page ||
            this.state.moved
        ) {
            this.getData();
        }
    };

    componentDidMount = () => {
        this.state.layers.layersGroup.addEventListener('click', this._onClickMarketPlace);
        this.props.leaflet.map.addEventListener('click', this._reset);
        this.props.leaflet.map.addEventListener('moveend', this._onUpdatedMap);
        this.state.layers.layersGroup.addTo(this.props.leaflet.map);
        this.getData();
    };

    _onUpdatedMap = e => {
        this.setState(prevState => ({
            containerBounds: this.props.leaflet.map.getBounds(),
            moved: true,
            layers: {
                ...prevState.layers,
                selectedLayer: null
            }
        }));
    };

    zoomControl = fnc => {
        let { map } = this.props.leaflet;

        fnc == 'in' ? map.zoomIn() : map.zoomOut();
    };

    _reset = e => {
        const { selectedLayer } = this.state.layers;

        L.DomEvent.stopPropagation(e);

        if (e.originalEvent.target.classList.contains('leaflet-container')) {
            if (selectedLayer) {
                selectedLayer.setActivitie(selectedLayer.getConfig('activitie'));
            }

            this.setState(prevState => ({
                layers: {
                    ...prevState.layers,
                    selectedLayer: null
                }
            }));
        }
    };

    _onClickMarketPlace = e => {
        const layer = e.layer;
        const { selectedLayer } = this.state.layers;

        L.DomEvent.stopPropagation(e);

        if (selectedLayer) {
            selectedLayer.setActivitie(selectedLayer.getConfig('activitie'));
        }

        layer.setStyle({
            color: 'blue'
        });

        this.setState(prevState => ({
            layers: {
                ...prevState.layers,
                selectedLayer: layer
            }
        }));
    };

    getData = () => {
        const { searchValue, page } = this.props.args;

        const { containerBounds } = this.state;

        const formData = new FormData();

        formData.append('page', page);
        formData.append('search', searchValue);
        formData.append('load', true);
        formData.append('northEast_lat', containerBounds._northEast.lat);
        formData.append('northEast_lng', containerBounds._northEast.lng);
        formData.append('southWest_lat', containerBounds._southWest.lat);
        formData.append('southWest_lng', containerBounds._southWest.lng);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/get-map-places-journalmarket/${this.props.id}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    moved: false
                });

                this.updateMap();
            })
            .catch(
                error => {
                    console.error(error);
                    //alert("Echec de la récupération des données")
                } // Handle the error response object
            );
    };

    clearLayers = () => {
        let { layersGroup } = this.state.layers;
        let { map } = this.props.leaflet;

        layersGroup.eachLayer(layer => {
            layer.getConfig('numero').label.remove();
            map.removeLayer(layer.getConfig('numero').label);
            layer.remove();
            map.removeLayer(layer);
        });
    };

    updateMap = () => {
        const { layersGroup } = this.state.layers;
        const { map } = this.props.leaflet;

        const { list } = this.state;

        this.clearLayers();

        list.map(layer => {
            const marketPlace = new MarketPlace();
            marketPlace.setActivitie(activities[layer['activite_id']]);
            marketPlace.setConfig('journalplaceId', layer['id']);
            marketPlace.setLatLngs([
                {
                    lat: layer['a_lat'],
                    lng: layer['a_lng']
                },
                {
                    lat: layer['b_lat'],
                    lng: layer['b_lng']
                },
                {
                    lat: layer['c_lat'],
                    lng: layer['c_lng']
                },
                {
                    lat: layer['d_lat'],
                    lng: layer['d_lng']
                }
            ]);
            marketPlace.setConfig('meters', layer['metres']);
            marketPlace.setConfig('rotation', layer['rotation']);
            marketPlace.addTo(layersGroup);
            marketPlace.setNumero(layer['numero']);
            marketPlace.getConfig('numero').label.addTo(map);

            const marchand = {
                raisonSociale: layer['raisonSociale'],
                siret: layer['marchand_id']
            };

            marketPlace.setMerchant(layer['marchand_id'] == '1' ? none : marchand);
        });
    };

    disableDragging = _bool => {
        !_bool
            ? this.props.leaflet.map.dragging.enable()
            : this.props.leaflet.map.dragging.disable();
        _bool
            ? this.props.leaflet.map.off('click')
            : this.props.leaflet.map.on('click', this._reset);
    };

    render() {
        const { classes } = this.state;

        const { selectedLayer } = this.state.layers;

        return (
            <div>
                <div className={classes.zoomButton}>
                    <ButtonGroup
                        orientation="vertical"
                        aria-label="vertical contained primary button group"
                        variant="contained">
                        <Button size="small" onClick={() => this.zoomControl('in')}>
                            <AddIcon />
                        </Button>
                        <Button size="small" onClick={() => this.zoomControl('out')}>
                            <RemoveIcon />
                        </Button>
                    </ButtonGroup>
                </div>
                <div
                    className={classes.info}
                    style={{ display: selectedLayer ? 'inherit' : 'none' }}
                    onMouseEnter={() => this.disableDragging(true)}
                    onMouseLeave={() => this.disableDragging(false)}
                    onTouchStart={() => this.disableDragging(true)}
                    onTouchEnd={() => this.disableDragging(false)}>
                    <InfoPlace
                        journalplaceId={
                            selectedLayer ? selectedLayer.getConfig('journalplaceId') : null
                        }
                        marchandId={this.props.args.marchandId}
                        doPlacement={this.props.doPlacement}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(withLeaflet(Control));
