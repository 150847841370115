import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Button,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

import L from 'leaflet';

import { Overview, DigitMap as Map, Merchants, Encaissement, DialogList } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: 'green',
        height: 48,
        width: 48
    },
    avatar1: {
        backgroundColor: theme.palette.primary.main,
        height: 48,
        width: 48
    },
    avatar2: {
        backgroundColor: theme.palette.primary.main,
        height: 48,
        width: 48
    },
    icon: {
        height: 26,
        width: 26
    },
    button: {
        color: theme.palette.primary.main,
        height: 48,
        width: 48
    }
});

class DashboardAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            marche: false,
            titulaire: 0,
            presence: 0,
            placed: 0,
            layersGroup: L.featureGroup(),
            child: React.createRef(),
            openDialog: false
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/home/admin/${id}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data

                this.setState({
                    marche: data,
                    titulaire: data.titulaires.total_present,
                    presence: data.presence.total_all,
                    placed: data.commercant_place.total_all
                });
            })
            .catch(console.log);
    }

    formatText(text, end) {
        if (text == '' || text == null) {
            return '';
        }

        let length = text.length;
        let textFormated = text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);

        if (length > end) {
            textFormated = textFormated.substring(0, end) + '...';
        }

        return textFormated;
    }

    handleDialog = name => {
        this.setState(prevState => ({
            dialogContent: name,
            openDialog: !prevState.openDialog
        }));
    };

    render() {
        const { classes } = this.props;
        const { marche, openDialog, dialogContent } = this.state;

        return (
            <div className={classes.root}>
                <DialogList open={openDialog} content={dialogContent} />

                <Grid container spacing={4} alignItems="flex-start">
                    <Grid item xs={8}>
                        <Map
                            ref={this.state.child}
                            jmid={this.props.match.params.id}
                            layersGroup={this.state.layersGroup}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="stretch"
                            spacing={4}>
                            <Grid item>
                                {marche && (
                                    <Overview data={marche} jmid={this.props.match.params.id} />
                                )}
                            </Grid>
                            <Grid item>
                                {marche && (
                                    <Merchants
                                        titulaire={this.state.titulaire}
                                        placed={this.state.placed}
                                        presence={this.state.presence}
                                    />
                                )}
                            </Grid>
                            <Grid item>
                                {marche && (
                                    <Encaissement
                                        encaisse={this.state.marche.finance.encaisse}
                                        nonencaisse={this.state.marche.finance.non_encaisse}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(DashboardAdmin);
