import React from 'react';
import { withStyles, makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
//import ScheduleIcon from '@material-ui/icons/Schedule';
import PeopleIcon from '@material-ui/icons/People';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import WarningIcon from '@material-ui/icons/Warning';
import ClearIcon from '@material-ui/icons/Clear';
import SendIcon from '@material-ui/icons/Send';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventIcon from '@material-ui/icons/Event';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    TableHead,
    TableCell,
    ListItemText,
    TextField,
    InputAdornment,
    Checkbox,
    Typography,
    Link,
    Divider,
    Button,
    FormLabel,
    IconButton,
    Step,
    Stepper,
    StepContent,
    StepLabel,
    StepConnector,
    CircularProgress
    //FormGroup,
    //FormControl,
    //FormControlLabel
} from '@material-ui/core';

import {
    Alert,
    Pagination,
    EnhancedCheckbox,
    Month,
    Year,
    Periodicity,
    Preview
} from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        flexGrow: 1
    },
    stepContent: {
        paddingLeft: theme.spacing(5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        margin: -10,
        fontWeight: 500
    },
    stepper: {
        //display: "grid"
        paddingLeft: theme.spacing(5)
    }
});

const ColorlibConnector = withStyles({
    alternativeLabel: {
        left: 10
    }
})(StepConnector);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

const columns = [
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: '60%',
        align: 'left'
    },
    {
        id: 'market',
        label: 'Marché(s)',
        minWidth: '40%',
        align: 'left'
    }
];

const useColorlibStepIconStyles = makeStyles({
    root: {
        position: 'relative',
        right: 12,
        backgroundColor: '#3f51b5',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        backgroundColor: '#2979ff',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    },
    notActive: {
        backgroundColor: 'white',
        color: '#2979ff',
        border: '1px solid #2979ff'
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { icon } = props;

    const icons = {
        1: <DateRangeIcon />,
        2: <EventIcon />,
        3: <AvTimerIcon />,
        4: <PeopleIcon />,
        5: <GetAppIcon />,
        6: <SendIcon />
    };

    return <div className={classes.root}>{icons[String(icon)]}</div>;
}

class GenerateBills extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            list: [],
            listOfMerchantSelected: [],
            listOfMerchantSelectedUpdated: false,
            all: false,
            init: true,
            dateHasChanged: false,
            isGenerating: false,
            numberOfSelected: 0,
            anchorAlert: null,
            assuranceExpiree: '0',
            assuranceManquante: '0',
            bapRetard: '0',
            bloque: '0',
            siret: '',
            searchValue: '',
            page: 1,
            numberOfPages: 0,
            total: 0,

            years: '',
            frequency: 1,
            weeksPerYear: 1,
            selectedMonths: [],
            previewHasToUpdate: false,
            isSending: false,
            listOfBillsCreated: [],
            isDone: false,
            disableWeeksPerYear: true,
            attendance: false
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        (prevState.page != this.state.page ||
            prevState.searchValue != this.state.searchValue ||
            this.state.listOfMerchantSelectedUpdated ||
            prevState.all != this.state.all) &&
            this.getData();

        this.state.isGenerating && this.generateBills();
        this.state.isSending && this.sendBills();

        if (
            this.state.selectedMonths != prevState.selectedMonths ||
            this.state.years != prevState.years
        ) {
            this.calculateWeeksPerMonths();
        }

        if (
            this.state.previewHasToUpdate &&
            prevState.previewHasToUpdate != this.state.previewHasToUpdate
        ) {
            this.setState({
                previewHasToUpdate: false
            });
        }

        if (
            this.state.listOfMerchantSelectedUpdated &&
            prevState.listOfMerchantSelectedUpdated != this.state.listOfMerchantSelectedUpdated
        ) {
            this.setState({
                previewHasToUpdate: true
            });
        }
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        const { page, searchValue, listOfMerchantSelected, all, init } = this.state;

        const formData = new FormData();

        formData.append('page', page);
        formData.append('search', searchValue);
        formData.append('all', all);
        formData.append('init', init);

        listOfMerchantSelected.map((merchantId, index) => {
            formData.append(`listOfSelected[${index}]`, merchantId);
        });

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/get-list-of-subscribers`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    listOfMerchantSelectedUpdated: false,
                    init: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    calculateWeeksPerMonths = () => {
        const { years, selectedMonths, frequency } = this.state;

        const formData = new FormData();
        formData.append('year', years);
        formData.append('months', JSON.stringify(selectedMonths));
        formData.append('frequency', frequency);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/calculate-weeks-per-months`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    previewHasToUpdate: true
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleAlert = (
        event = null,
        assuranceExpiree = '0',
        assuranceManquante = '0',
        bapRetard = '0',
        bloque = '0',
        siret
    ) => {
        this.setState({
            anchorAlert: event ? event.currentTarget : null,
            assuranceExpiree: assuranceExpiree,
            assuranceManquante: assuranceManquante,
            bapRetard: bapRetard,
            bloque: bloque,
            siret: siret
        });
    };

    alertMerchant = merchant => {
        let alert = 0;
        merchant.assuranceExpiree != '0' && ++alert;
        merchant.assuranceManquante == '1' && ++alert;
        merchant.bapRetard != '0' && ++alert;
        merchant.bloque != '0' && ++alert;

        return (
            <Grid spacing={1} container direction="row" justify="flex-start" alignItems="center">
                {alert > 0 && (
                    <Grid item>
                        <Button
                            onClick={event =>
                                this.handleAlert(
                                    event,
                                    merchant.assuranceExpiree,
                                    merchant.assuranceManquante,
                                    merchant.bapRetard,
                                    merchant.bloque,
                                    merchant.siret
                                )
                            }
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{
                                background: '#eb5a46',
                                minWidth: 'fit-content',
                                padding: '4px 6px'
                            }}>
                            <WarningIcon fontSize="small" />{' '}
                            <Typography style={{ color: 'white', marginLeft: 5, fontWeight: 500 }}>
                                {alert}
                            </Typography>
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <ListItemText primary={merchant.raisonSociale} />
                </Grid>
            </Grid>
        );
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    handleAll = checked => {
        this.setState(prevState => ({
            all: checked,
            listOfMerchantSelectedUpdated: checked,
            listOfMerchantSelected: checked ? prevState.listOfMerchantSelected : []
        }));
    };

    handleListOfMerchant = merchantId => {
        const { listOfMerchantSelected } = this.state;

        const index = listOfMerchantSelected.indexOf(merchantId);

        if (index > -1) {
            listOfMerchantSelected.splice(index, 1);

            this.setState({
                all: false
            });
        } else {
            listOfMerchantSelected.push(merchantId);
        }

        this.setState({
            listOfMerchantSelected: listOfMerchantSelected,
            listOfMerchantSelectedUpdated: true
        });
    };

    prepareBillsForSending = (attendance = false) => {
        this.setState({
            isSending: true,
            attendance
        });
    };

    prepareBills = () => {
        const { selectedMonths, listOfMerchantSelected, years } = this.state;

        if (years == '') {
            alert('Veuillez sélectionner une année.');
            return;
        }

        if (selectedMonths.length == 0) {
            alert('Veuillez sélectionner au moins un mois.');
            return;
        }

        if (listOfMerchantSelected.length == 0) {
            alert('Veuillez sélectionner au moins un abonné.');
            return;
        }

        this.setState({
            isGenerating: true
        });
    };

    sendBills = () => {
        const { listOfBillsCreated, attendance } = this.state;

        const formData = new FormData();
        formData.append('bills', JSON.stringify(listOfBillsCreated));
        formData.append('attendance', attendance);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/new-send-subscriber-bills`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                alert(data.message);

                this.setState({
                    isSending: false
                });
            })
            .catch(
                error => {
                    this.setState({
                        isSending: false
                    });
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    generateBills = () => {
        const { selectedMonths, listOfMerchantSelected, years, weeksPerYear } = this.state;

        const formData = new FormData();
        formData.append('year', years);
        formData.append('months', JSON.stringify(selectedMonths));
        formData.append('merchants', JSON.stringify(listOfMerchantSelected));
        formData.append('weeksPerYear', weeksPerYear);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/generate-subscriber-bills`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    isGenerating: false
                });
                alert(data.message);

                /*if (data.records.success > 0) {
                    const _confirm = window.confirm(
                        'Souhaitez vous envoyer les ' +
                            data.records.success +
                            ' factures par mail ? '
                    );
                    if (_confirm) {
                        this.setState({
                            isSending: true
                        });
                    }
                }*/
            })
            .catch(
                error => {
                    this.setState({
                        isGenerating: false
                    });
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleWeeks = ({ target: { value } }) => {
        this.setState({
            weeksPerYear: value
        });
    };

    handleSelectedMonths = value => {
        this.setState({
            selectedMonths: value
        });
    };

    handleYears = value => {
        this.setState({
            years: value
        });
    };

    handlePeriodicity = value => {
        this.setState({
            frequency: value
        });
    };

    render() {
        const {
            classes,
            isGenerating,
            anchorAlert,
            assuranceExpiree,
            assuranceManquante,
            bloque,
            bapRetard,
            siret,
            list,
            searchValue,
            page,
            numberOfPages,
            all,
            numberOfSelected,
            total,
            selectedMonths,
            years,
            weeksPerYear,
            frequency,
            listOfMerchantSelected,
            dateHasChanged,
            previewHasToUpdate,
            isSending,
            isDone,
            disableWeeksPerYear
        } = this.state;

        return (
            <Paper className={classes.root}>
                <Alert
                    anchorAlert={anchorAlert}
                    data={{
                        assuranceExpiree: assuranceExpiree,
                        assuranceManquante: assuranceManquante,
                        bloque: bloque,
                        bapRetard: bapRetard
                    }}
                    siret={siret}
                    handleAlert={this.handleAlert}
                />

                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container direction="row" alignItems="baseline">
                            <Grid item>
                                <IconButton component={RouterLink} to="/bills/subscribers/view">
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <Link component={RouterLink} to="/bills/subscribers/view">
                                        Facturation abonné
                                    </Link>{' '}
                                    / Générer les factures des abonés
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Stepper
                            className={classes.stepper}
                            connector={<ColorlibConnector />}
                            orientation="vertical">
                            <Step active expanded key={0}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                    <FormLabel className={classes.labelFilter} component="label">
                                        Sélectionner une période
                                    </FormLabel>
                                </StepLabel>
                                <StepContent className={classes.stepContent}>
                                    <Periodicity
                                        handlePeriodicity={this.handlePeriodicity}
                                        frequency={frequency}
                                    />
                                </StepContent>
                            </Step>
                            <Step active expanded key={1}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                    <FormLabel className={classes.labelFilter} component="label">
                                        Sélectionner une année et un/des mois
                                    </FormLabel>
                                </StepLabel>
                                <StepContent className={classes.stepContent}>
                                    <Grid container spacing={3} direction="column">
                                        <Grid item>
                                            <Year year={years} handleYear={this.handleYears} />
                                        </Grid>
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                        <Grid item>
                                            <Month
                                                handleSelectedMonths={this.handleSelectedMonths}
                                                frequency={frequency}
                                            />
                                        </Grid>
                                    </Grid>
                                </StepContent>
                            </Step>
                            {!disableWeeksPerYear && (
                                <Step active expanded key={2}>
                                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                                        <FormLabel
                                            className={classes.labelFilter}
                                            style={{ color: 'red' }}
                                            component="label">
                                            Saisir un nombre de semaines par période
                                        </FormLabel>
                                    </StepLabel>
                                    <StepContent className={classes.stepContent}>
                                        <TextField
                                            type="number"
                                            style={{ width: 200 }}
                                            variant="outlined"
                                            value={weeksPerYear}
                                            onChange={this.handleWeeks}
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    max: 52,
                                                    step: 1
                                                },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Typography
                                                            color="primary"
                                                            variant="button">
                                                            semaines
                                                        </Typography>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </StepContent>
                                </Step>
                            )}

                            <Step active expanded key={3}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                    <FormLabel className={classes.labelFilter} component="label">
                                        Sélectionner des commerçants abonnés
                                    </FormLabel>
                                </StepLabel>
                                <StepContent className={classes.stepContent}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <TextField
                                                label={'Rechercher un abonné'}
                                                type="text"
                                                fullWidth
                                                variant="outlined"
                                                value={searchValue}
                                                onChange={this.handleSearch}
                                                helperText="Saisir une raison sociale, une enseigne, un nom, un prénom"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={this.clearSearch}
                                                                edge="end">
                                                                <ClearIcon
                                                                    style={{ color: 'red' }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={() => this.handleAll(!all)}
                                                color="primary"
                                                startIcon={
                                                    <Checkbox
                                                        indeterminate={
                                                            numberOfSelected > 0 &&
                                                            numberOfSelected < total
                                                        }
                                                        checked={all}
                                                    />
                                                }>
                                                Sélectionner tous les abonnés
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <TableContainer>
                                                <Table
                                                    stickyHeader
                                                    size="small"
                                                    className={classes.table}>
                                                    <TableHead>
                                                        <TableRow>
                                                            {columns.map(column => (
                                                                <StyledTableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{
                                                                        minWidth: column.minWidth
                                                                    }}>
                                                                    {column.label}
                                                                </StyledTableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {list.map((merchant, index) => {
                                                            return (
                                                                <StyledTableRow
                                                                    hover
                                                                    key={merchant.id}>
                                                                    <TableCell align="left">
                                                                        <Grid
                                                                            container
                                                                            spacing={1}
                                                                            alignItems="center">
                                                                            <Grid item>
                                                                                <EnhancedCheckbox
                                                                                    key={`${merchant.id}_${merchant.selected}`}
                                                                                    handleListOfMerchant={
                                                                                        this
                                                                                            .handleListOfMerchant
                                                                                    }
                                                                                    marchandId={
                                                                                        merchant.id
                                                                                    }
                                                                                    checked={Boolean(
                                                                                        merchant.selected ==
                                                                                            '1'
                                                                                    )}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                {this.alertMerchant(
                                                                                    merchant
                                                                                )}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </TableCell>
                                                                    <TableCell align="left">
                                                                        {merchant.market}
                                                                    </TableCell>
                                                                </StyledTableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        <Grid item>
                                            <Pagination
                                                numberOfPages={numberOfPages}
                                                currentPage={page}
                                                handlePagination={this.handlePagination}
                                            />
                                        </Grid>
                                    </Grid>
                                </StepContent>
                            </Step>
                            <Step active expanded key={4}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                    <FormLabel className={classes.labelFilter} component="label">
                                        Pré-visualiser les factures avant leurs générations
                                    </FormLabel>
                                </StepLabel>
                                <StepContent className={classes.stepContent}>
                                    <Preview
                                        update={previewHasToUpdate}
                                        year={years}
                                        months={selectedMonths}
                                        weeksPerYear={weeksPerYear}
                                        merchants={listOfMerchantSelected}
                                    />
                                </StepContent>
                            </Step>
                            <Step active expanded key={5}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                    <FormLabel className={classes.labelFilter} component="label">
                                        Généreration et envoi des factures
                                    </FormLabel>
                                </StepLabel>
                                <StepContent className={classes.stepContent}>
                                    <Grid
                                        container
                                        spacing={2}
                                        direction="column"
                                        alignItems="flex-start">
                                        <Grid item>
                                            <Grid
                                                container
                                                spacing={2}
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center">
                                                <Grid item>
                                                    <Button
                                                        disabled={isGenerating || isDone}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.prepareBills}>
                                                        {isDone
                                                            ? 'Factures générées avec succés'
                                                            : 'Générer les factures'}
                                                    </Button>
                                                </Grid>
                                                {isGenerating && (
                                                    <Grid item>
                                                        <CircularProgress
                                                            color="primary"
                                                            size={25}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                container
                                                spacing={2}
                                                direction="row"
                                                justify="flex-start"
                                                alignItems="center">
                                                <Grid item>
                                                    <Grid container direction="row" spacing={2}>
                                                        <Grid item>
                                                            <Button
                                                                disabled={!isDone}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={
                                                                    this.prepareBillsForSending
                                                                }>
                                                                Envoyer les factures
                                                            </Button>
                                                        </Grid>
                                                        <Grid item>
                                                            <Button
                                                                disabled={!isDone}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() =>
                                                                    this.prepareBillsForSending(
                                                                        true
                                                                    )
                                                                }>
                                                                Envoyer les factures et le rapport
                                                                d'assiduité
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {isSending && (
                                                    <Grid item>
                                                        <CircularProgress
                                                            color="primary"
                                                            size={25}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StepContent>
                            </Step>
                        </Stepper>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(GenerateBills);
