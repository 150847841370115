import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import GroupIcon from '@material-ui/icons/Group';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import DescriptionIcon from '@material-ui/icons/Description';
import DoneIcon from '@material-ui/icons/Done';
import SettingsIcon from '@material-ui/icons/Settings';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

import JournalMarche from '../../JournalMarche';

import {
    Button,
    Grid,
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Divider,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    TextField,
    ExpansionPanelSummary,
    ExpansionPanel,
    ExpansionPanelDetails,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControlLabel,
    RadioGroup,
    Radio
} from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    cell: {
        padding: '10px 10px 10px 24px'
    },
    table: {
        maxHeight: 300,
        borderRight: '1px solid #eee',
        borderLeft: '1px solid #eee'
    },
    avatar: {
        backgroundColor: 'green',
        height: 48,
        width: 48
    },
    title: {
        //flex: '1 1 100%',
    },
    content: {
        padding: theme.spacing(3),
        flexGrow: 1
    },
    subtitle: {
        padding: '32px 0 0 16px'
    },
    info: {
        textAlign: 'center'
    },
    rotate: {
        transform: 'rotate(180deg)'
    }
});

class Synthesis extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            marchand: {
                encaisse: 0,
                non_encaisse: 0
            },
            comChoice: '',
            openAlert: false,
            detailsExpanded: false,
            retard: 0,
            placement: [],
            commentaire: '',
            filter: 'p',
            etat: '1'
        };
    }

    componentDidMount() {
        const { filter } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/synthesis/${JournalMarche.getId()}/${filter}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                console.log(data);
                this.setState({
                    ...data
                });
            })
            .catch(console.log);

        //console.log(this.state)
    }

    cloture = () => {
        const { commentaire, history } = this.state;

        if (!commentaire || commentaire.length == 0 || !commentaire.replace(/\s/g, '').length) {
            alert('Veuillez laisser un commentaire ou bien cochez la case "Rien à signaler"');
            this.setState({
                openAlert: false
            });
            return;
        }

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/synthesis/update/${JournalMarche.getId()}/commentaire?c=${commentaire}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data

                if (data) {
                    history.push('/home');
                }
            })
            .catch(console.log);
    };

    formatText(text, end) {
        if (text == '' || text == null) {
            return '';
        }

        let length = text.length;
        let textFormated = text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);

        if (length > end) {
            textFormated = textFormated.substring(0, end) + '...';
        }

        return textFormated;
    }

    handleDetail = () => {
        this.setState(prevState => ({
            detailsExpanded: !prevState.detailsExpanded
        }));
    };

    handleDialog = value => {
        this.setState({
            openAlert: value
        });
    };

    handleText = (event, key) => {
        this.setState({
            [key]: event.target.value
        });

        console.log(event.target.value);
    };

    handleChangeCommentaire = ({ target: { value } }) => {
        let com = '';

        if (value == 'ras') {
            com = 'Rien à signaler';
        }

        this.setState({
            comChoice: value,
            commentaire: com
        });
    };

    render() {
        const {
            classes,
            marchand,
            retard,
            placement,
            commentaire,
            detailsExpanded,
            openAlert,
            etat,
            comChoice
        } = this.state;

        return (
            <div className={classes.root}>
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="stretch"
                    spacing={4}>
                    <Grid item>
                        <Grid
                            container
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="flex-start"
                            spacing={4}>
                            <Grid item xs={8}>
                                <Card>
                                    <CardContent>
                                        <Grid container direction="column" spacing={1}>
                                            <Grid
                                                item
                                                container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="center">
                                                <Grid item>
                                                    <Typography
                                                        color="textSecondary"
                                                        //className={classes.title}
                                                        gutterBottom
                                                        variant="body2">
                                                        Commerçants placés
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <TableContainer style={{ maxHeight: '60vh' }}>
                                                    <Table stickyHeader className={classes.table}>
                                                        <TableBody>
                                                            {placement.map((place, index) => (
                                                                <TableRow
                                                                    style={{
                                                                        backgroundColor:
                                                                            index % 2 == 0
                                                                                ? 'rgb(0,0,0, 0.02)'
                                                                                : 'none'
                                                                    }}
                                                                    key={place.raisonSociale}>
                                                                    <TableCell
                                                                        size="small"
                                                                        variant="body"
                                                                        align="left"
                                                                        padding="none"
                                                                        style={{ paddingLeft: 16 }}>
                                                                        <ListItemText
                                                                            primary={this.formatText(
                                                                                place.raisonSociale,
                                                                                13
                                                                            )}
                                                                            secondary={place.alias}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell
                                                                        size="small"
                                                                        variant="body"
                                                                        padding="none"
                                                                        align="left">
                                                                        {this.formatText(
                                                                            place.metier,
                                                                            20
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        size="small"
                                                                        variant="body"
                                                                        align="right"
                                                                        padding="none"
                                                                        style={{
                                                                            paddingRight: 16
                                                                        }}>
                                                                        <ListItemText
                                                                            primary={
                                                                                'N°' + place.numero
                                                                            }
                                                                            secondary={
                                                                                place.metres +
                                                                                ' mètres'
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4} style={{ maxHeight: '80vh', overflow: ' auto' }}>
                                <Card>
                                    <CardContent style={{ paddingBottom: 16 }}>
                                        <Grid
                                            container
                                            direction="column"
                                            justify="center"
                                            alignItems="stretch"
                                            spacing={4}>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justify="space-between"
                                                    alignItems="stretch"
                                                    spacing={1}>
                                                    <Grid item>
                                                        <Typography
                                                            className={classes.info}
                                                            variant="h3"
                                                            style={{ color: 'green' }}>
                                                            {marchand.encaisse} €
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            className={classes.info}
                                                            variant="body2">
                                                            Total réglé
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                item
                                                style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justify="space-between"
                                                    alignItems="stretch"
                                                    spacing={1}>
                                                    <Grid item>
                                                        <Typography
                                                            className={classes.info}
                                                            variant="h3"
                                                            style={{ color: 'red' }}>
                                                            {marchand.non_encaisse} €
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            className={classes.info}
                                                            variant="body2">
                                                            Total non réglé
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Card>
                            <CardContent style={{ paddingBottom: 10 }}>
                                <Grid container direction="column" spacing={1}>
                                    {Boolean(etat != 2) && (
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center">
                                            <Grid item>
                                                <RadioGroup
                                                    value={comChoice}
                                                    onChange={this.handleChangeCommentaire}>
                                                    <FormControlLabel
                                                        value="ras"
                                                        control={<Radio />}
                                                        label="Rien à signaler"
                                                    />
                                                    <FormControlLabel
                                                        value="com"
                                                        control={<Radio />}
                                                        label="Laisser un commentaire"
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid item>
                                        <TextField
                                            multiline
                                            disabled={
                                                Boolean(comChoice == '') || Boolean(etat == 2)
                                            }
                                            variant="outlined"
                                            fullWidth
                                            rows="5"
                                            value={commentaire}
                                            onChange={event =>
                                                this.handleText(event, 'commentaire')
                                            }
                                        />
                                    </Grid>
                                    <Grid item style={{ paddingTop: 24, textAlign: 'center' }}>
                                        <Button
                                            disabled={Boolean(etat == 2)}
                                            color="primary"
                                            onClick={() => this.handleDialog(true)}
                                            variant="contained">
                                            {Boolean(etat == 2)
                                                ? 'Marché clôturé'
                                                : 'Clôturer le marché'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Dialog open={openAlert} onClose={() => this.handleDialog(false)}>
                    <DialogTitle>{'Clôture de marché'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Etes-vous sûr de vouloir clôturer le marché ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleDialog(false)} color="secondary">
                            Ne pas clôturer
                        </Button>
                        <Button
                            onClick={this.cloture}
                            variant="contained"
                            color="primary"
                            autoFocus>
                            Clôturer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(Synthesis);
