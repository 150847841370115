import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { printBill } from 'common/pdf';
import {
    Divider,
    Paper,
    Grid,
    Button,
    InputAdornment,
    TextField,
    Typography,
    ListItemText,
    TableRow,
    TableCell,
    CircularProgress,
    Chip,
    Link
} from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { getListOfPlaceNumbers, getMerchants, getSectors } from './http';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FilterListIcon from '@material-ui/icons/FilterList';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import LoopIcon from '@material-ui/icons/Loop';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GroupIcon from '@material-ui/icons/Group';
import { empty } from 'common/validators';
import { AttendanceButton, PlacementButton, PayButton, SocialeReasonDetail } from './components';
import { SearchTextField, MuiTable } from 'components';
import { DEFAULT_STATE, keysForFilter, headers } from './utils';
import FilterFormDialog from './FilterFormDialog';
import BulkFormDialog from './BulkFormDialog';
import ResultDrawDialog from './ResultDrawDialog';
import DrawDialog from './DrawDialog';
import DigitMap from './DigitMap';
import BillForm from './BillForm';
import FormCashDirectly from './FormCashDirectly';
import { BillSender } from 'views';

import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import InvertColorsIcon from '@material-ui/icons/InvertColors';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1)
        }
    },
    filterButton: {
        borderRadius: 15
    }
});

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE(props);
    }

    componentDidUpdate = (_, prevState) => {
        if (
            this.state.page !== prevState.page ||
            this.state.searchInput !== prevState.searchInput ||
            this.state.order !== prevState.order ||
            this.state.beginAt !== prevState.beginAt ||
            this.state.orderBy !== prevState.orderBy
        ) {
            this.setState({
                loading: true
            });
        }

        if (this.state.loading && prevState.loading !== this.state.loading) {
            this.getData();
        }
    };

    componentDidMount = () => {
        this.getData();
        getListOfPlaceNumbers(this.props.id)
            .then(res =>
                this.setState({
                    ...res
                })
            )
            .catch(err => alert(err));

        getSectors(this.props.id)
            .then(res =>
                this.setState({
                    ...res
                })
            )
            .catch(err => alert(err));
    };

    getData = () => {
        const { id } = this.props;
        const { currentFilter, page, order, orderBy, searchInput, beginAt, mounted } = this.state;

        getMerchants(id, currentFilter, searchInput, page, order, orderBy, beginAt, mounted)
            .then(res =>
                this.setState({
                    loading: false,
                    ...res
                })
            )
            .catch(err => {
                this.setState({
                    loading: false
                });
                alert(err);
            });
    };

    openFilterForm = () => {
        this.setState(prevState => ({
            openFilter: !prevState.openFilter,
            loading: prevState.openFilter
        }));
    };

    openMapForm = (merchant = false) => {
        this.setState(prevState => ({
            openMap: !prevState.openMap,
            loading: prevState.openMap,
            currentMerchant: merchant
        }));
    };

    openBulkForm = () => {
        this.setState(prevState => ({
            openBulk: !prevState.openBulk,
            loading: prevState.openBulk
        }));
    };

    openDrawForm = () => {
        this.setState(prevState => ({
            openDraw: !prevState.openDraw,
            loading: prevState.openDraw
        }));
    };

    openQuickPaymentForm = (merchant = false) => {
        this.setState(prevState => ({
            openQuickPayment: !prevState.openQuickPayment,
            loading: prevState.openQuickPayment,
            currentMerchant: merchant
        }));
    };

    openSenderForm = (bill = false) => {
        this.setState(prevState => ({
            openSender: !prevState.openSender,
            loading: !Boolean(bill),
            currentBill: bill
        }));
    };

    openPaymentMethodForm = (openPaymentMethod = false, merchant = false) => {
        this.setState(prevState => ({
            openPaymentMethod: openPaymentMethod,
            loading: prevState.openPaymentMethod,
            currentMerchant: merchant
        }));
    };

    removeFromFilter = (k, v) => {
        const bf = { ...this.state.currentFilter };

        if (k === 'draw') {
            bf.draw = {};
        } else {
            bf[k].splice(bf[k].indexOf(v), 1);
        }

        this.setState({
            currentFilter: bf,
            loading: true
        });
    };

    updateRow = (index, row) => {
        const { rows } = this.state;
        const updatedRows = [...rows];
        updatedRows[index] = row;

        this.setState({
            rows: updatedRows
        });
    };

    swapMerchantType = () => {
        const { currentFilter } = this.state;
        currentFilter.merchantType = currentFilter.merchantType === 't' ? 'p' : 't';
        currentFilter.holderType = [];

        this.setState({
            loading: true,
            currentFilter: currentFilter
        });
    };

    render() {
        const { classes, id } = this.props;
        const {
            currentFilter,
            openFilter,
            searchInput,
            listOfPlaceNumbers,
            loading,
            order,
            orderBy,
            page,
            record,
            rows,
            beginAt,
            drawer,
            openBulk,
            openDraw,
            openDrawResult,
            currentMerchant,
            openMap,
            openPaymentMethod,
            currentBill,
            openQuickPayment,
            sectors
        } = this.state;

        return (
            <div>
                <FilterFormDialog
                    currentFilter={{
                        set: v => this.setState({ currentFilter: v }),
                        get: currentFilter
                    }}
                    id={id}
                    drawer={drawer}
                    open={openFilter}
                    sectors={sectors}
                    onClose={this.openFilterForm}
                />

                <DigitMap
                    id={id}
                    marchand={currentMerchant}
                    close={() => this.openMapForm()}
                    open={openMap}
                    sectors={sectors}
                />

                <BulkFormDialog open={openBulk} id={id} onClose={this.openBulkForm} />

                <FormCashDirectly
                    currentMerchant={currentMerchant}
                    close={this.openQuickPaymentForm}
                    open={openQuickPayment}
                    id={id}
                />

                {drawer && (
                    <>
                        <DrawDialog open={openDraw} id={id} close={this.openDrawForm} />
                        <ResultDrawDialog
                            open={openDrawResult}
                            id={id}
                            close={() => this.setState({ openDrawResult: false })}
                        />
                    </>
                )}

                <BillForm
                    // setBillNumber={() => this.openPaymentMethodForm(false)}
                    id={id}
                    merchant={currentMerchant}
                    open={openPaymentMethod}
                    close={() => this.openPaymentMethodForm(false)}
                />

                <BillSender setBillNumber={this.openSenderForm} billNumber={currentBill} />

                <Paper className={classes.root}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Grid container direction="column" alignItems="stretch" spacing={2}>
                                <Grid item>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={this.openFilterForm}
                                                startIcon={<FilterListIcon />}>
                                                Filtrer
                                            </Button>
                                        </Grid>
                                        {/* <Grid item>
                                            <Button
                                                disabled={currentFilter.merchantType === 'p'}
                                                size="small"
                                                color="secondary"
                                                variant="contained"
                                                onClick={() => this.setState({ openBulk: true })}
                                                startIcon={<GroupIcon />}>
                                                Action titulaire
                                            </Button>
                                        </Grid> */}
                                        {drawer && (
                                            <>
                                                <Grid item>
                                                    <Divider orientation="vertical" />
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        disabled={
                                                            currentFilter.merchantType === 't'
                                                        }
                                                        size="small"
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={this.openDrawForm}
                                                        endIcon={<LoopIcon />}>
                                                        tirage au sort
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        disabled={
                                                            currentFilter.merchantType === 't'
                                                        }
                                                        size="small"
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() =>
                                                            this.setState({ openDrawResult: true })
                                                        }
                                                        endIcon={<SortByAlphaIcon />}>
                                                        Résultat
                                                    </Button>
                                                </Grid>
                                            </>
                                        )}
                                        {/* <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Cliquez sur une des croix pour enlever un filtre
                                            </Typography>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={1}>
                                        <Grid item>
                                            <Button
                                                className={classes.filterButton}
                                                color="primary"
                                                size="small"
                                                variant="outlined"
                                                onClick={this.swapMerchantType}
                                                startIcon={<SwapHorizIcon />}>
                                                {
                                                    keysForFilter.merchantType[
                                                        currentFilter.merchantType
                                                    ]
                                                }
                                            </Button>
                                        </Grid>
                                        {!empty(currentFilter.holderType) && (
                                            <>
                                                <Grid item>
                                                    <Divider orientation="vertical" />
                                                </Grid>
                                                {currentFilter.holderType.map((k, i) => (
                                                    <Grid item key={i}>
                                                        <Button
                                                            className={classes.filterButton}
                                                            color="primary"
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={() =>
                                                                this.removeFromFilter(
                                                                    'holderType',
                                                                    k
                                                                )
                                                            }
                                                            startIcon={<HighlightOffIcon />}>
                                                            {keysForFilter.holderType[k]}
                                                        </Button>
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                        {!empty(currentFilter.merchantState) && (
                                            <>
                                                <Grid item>
                                                    <Divider orientation="vertical" />
                                                </Grid>
                                                {currentFilter.merchantState.map((k, i) => (
                                                    <Grid item key={i}>
                                                        <Button
                                                            className={classes.filterButton}
                                                            color="primary"
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={() =>
                                                                this.removeFromFilter(
                                                                    'merchantState',
                                                                    k
                                                                )
                                                            }
                                                            startIcon={<HighlightOffIcon />}>
                                                            {keysForFilter.merchantState[k]}
                                                        </Button>
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                        {!empty(currentFilter.activities) && (
                                            <>
                                                <Grid item>
                                                    <Divider orientation="vertical" />
                                                </Grid>
                                                {currentFilter.activities.map((k, i) => (
                                                    <Grid item key={i}>
                                                        <Button
                                                            className={classes.filterButton}
                                                            color="primary"
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={() =>
                                                                this.removeFromFilter(
                                                                    'activities',
                                                                    k
                                                                )
                                                            }
                                                            startIcon={<HighlightOffIcon />}>
                                                            {
                                                                keysForFilter.activities[
                                                                    parseInt(k) - 1
                                                                ]
                                                            }
                                                        </Button>
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                        {!empty(currentFilter.sectors) && (
                                            <>
                                                <Grid item>
                                                    <Divider orientation="vertical" />
                                                </Grid>
                                                {currentFilter.sectors.map((k, i) => (
                                                    <Grid item key={i}>
                                                        <Button
                                                            className={classes.filterButton}
                                                            color="primary"
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={() =>
                                                                this.removeFromFilter('sectors', k)
                                                            }
                                                            startIcon={<HighlightOffIcon />}>
                                                            Secteur {k}
                                                        </Button>
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                        {!empty(currentFilter.draw) && (
                                            <>
                                                <Grid item>
                                                    <Divider orientation="vertical" />
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className={classes.filterButton}
                                                        color="secondary"
                                                        size="small"
                                                        variant="outlined"
                                                        onClick={() =>
                                                            this.removeFromFilter('draw', {})
                                                        }
                                                        startIcon={<HighlightOffIcon />}>
                                                        {currentFilter.draw.label}
                                                    </Button>
                                                </Grid>
                                            </>
                                        )}
                                        {!empty(currentFilter.merchantPlaceState) && (
                                            <>
                                                <Grid item>
                                                    <Divider orientation="vertical" />
                                                </Grid>
                                                {currentFilter.merchantPlaceState.map((k, i) => (
                                                    <Grid item key={i}>
                                                        <Button
                                                            className={classes.filterButton}
                                                            color="primary"
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={() =>
                                                                this.removeFromFilter(
                                                                    'merchantPlaceState',
                                                                    k
                                                                )
                                                            }
                                                            startIcon={<HighlightOffIcon />}>
                                                            {keysForFilter.merchantPlaceState[k]}
                                                        </Button>
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                        {!empty(currentFilter.billState) && (
                                            <>
                                                <Grid item>
                                                    <Divider orientation="vertical" />
                                                </Grid>
                                                {currentFilter.billState.map((k, i) => (
                                                    <Grid item key={i}>
                                                        <Button
                                                            className={classes.filterButton}
                                                            color="primary"
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={() =>
                                                                this.removeFromFilter(
                                                                    'billState',
                                                                    k
                                                                )
                                                            }
                                                            startIcon={<HighlightOffIcon />}>
                                                            {keysForFilter.billState[k]}
                                                        </Button>
                                                    </Grid>
                                                ))}
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={3}>
                                <Grid item>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                options={listOfPlaceNumbers}
                                                name="startNumero"
                                                autoHighlight
                                                value={{ place_number: beginAt }}
                                                onChange={(_, value) =>
                                                    this.setState({
                                                        beginAt: value ? value.place_number : ''
                                                    })
                                                }
                                                noOptionsText={"Aucune place n'a été trouvée"}
                                                getOptionLabel={option => option.place_number}
                                                getOptionSelected={(option, value) =>
                                                    option.place_number == value.place_number
                                                }
                                                renderInput={params => (
                                                    <TextField
                                                        fullWidth
                                                        {...params}
                                                        type="number"
                                                        min="1"
                                                        max="999"
                                                        variant="outlined"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                                <InputAdornment position="end">
                                                                    Afficher à partir du N°
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SearchTextField
                                                label={`Rechercher un commerçant`}
                                                value={searchInput}
                                                onChange={value => {
                                                    this.setState({
                                                        searchInput: value,
                                                        page: 0
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography color="error" align="right">
                                        ⚠️ En rouge les commerçants comportants des alertes
                                        administratives (nombre d'alertes)
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>
                                <Grid item>
                                    <MuiTable
                                        //rowsPerPage={record}
                                        refresh={() => this.setState({ loading: true })}
                                        loading={loading}
                                        headers={headers(drawer, currentFilter.merchantType, () =>
                                            this.setState({ openBulk: true })
                                        )}
                                        page={{
                                            set: val => this.setState({ page: val }),
                                            data: page,
                                            count: record
                                        }}
                                        order={{
                                            set: val => this.setState({ order: val }),
                                            data: order
                                        }}
                                        orderBy={{
                                            set: val => this.setState({ orderBy: val }),
                                            data: orderBy
                                        }}>
                                        {rows.map((row, index) => (
                                            <TableRow hover key={`${index}-${row.billNumber}`}>
                                                <TableCell
                                                    colSpan={2}
                                                    padding="none"
                                                    key={`attendance-${index}-${row.billNumber}`}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        direction="row"
                                                        justifyContent="flex-start"
                                                        justify="flex-start"
                                                        alignItems="center">
                                                        <Grid item>
                                                            <AttendanceButton
                                                                id={row.id}
                                                                update={{
                                                                    fnc: r =>
                                                                        this.updateRow(index, r),
                                                                    row
                                                                }}
                                                                type={currentFilter.merchantType}
                                                                marketDayId={id}
                                                                isPresent={row.isPresent === 'y'}
                                                                isBlocked={row.blocked === 'y'}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            {row.journalplace_id ||
                                                            row.loading === 'y' ? (
                                                                row.loading === 'y' ? (
                                                                    <CircularProgress size={20} />
                                                                ) : (
                                                                    <ListItemText
                                                                        primary={row.placeNumber}
                                                                        secondary={
                                                                            <Typography
                                                                                noWrap
                                                                                variant="body2">
                                                                                {row.placeMeters}
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                )
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell
                                                    colSpan={3}
                                                    align="left"
                                                    key={`sr-${index}-${row.marchand_id}`}
                                                    style={{ padding: 14 }}>
                                                    <Grid container direction="column" spacing={2}>
                                                        <Grid item>
                                                            <SocialeReasonDetail
                                                                key={row.merchant_id}
                                                                id={row.marchand_id}
                                                                siret={row.siret}
                                                                socialeReason={row.socialeReason}
                                                                alert={
                                                                    row.administrative_alert !== '0'
                                                                }
                                                            />
                                                        </Grid>

                                                        <Grid item>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                spacing={1}
                                                                justify={
                                                                    (currentFilter.merchantType ===
                                                                        'p' &&
                                                                        drawer) ||
                                                                    !drawer
                                                                        ? 'space-between'
                                                                        : 'flex-end'
                                                                }
                                                                justifyContent={
                                                                    (currentFilter.merchantType ===
                                                                        'p' &&
                                                                        drawer) ||
                                                                    !drawer
                                                                        ? 'space-between'
                                                                        : 'flex-end'
                                                                }>
                                                                {((currentFilter.merchantType ===
                                                                    'p' &&
                                                                    drawer) ||
                                                                    !drawer) && (
                                                                    <Grid
                                                                        item
                                                                        key={`points_order-${index}-${row.marchand_id}`}>
                                                                        {currentFilter.merchantType ===
                                                                        't' ? (
                                                                            <Chip
                                                                                label={
                                                                                    row.defaultPlaceNumber
                                                                                }
                                                                                color="primary"
                                                                                size="small"
                                                                                style={{
                                                                                    marginRight: 7
                                                                                }}
                                                                            />
                                                                        ) : null}
                                                                        {drawer &&
                                                                            currentFilter.merchantType ==
                                                                                'p' &&
                                                                            !empty(
                                                                                row.draw_order
                                                                            ) && (
                                                                                <Chip
                                                                                    label={
                                                                                        row.draw_order
                                                                                    }
                                                                                    color="secondary"
                                                                                    size="small"
                                                                                />
                                                                            )}
                                                                        {!drawer && (
                                                                            <Chip
                                                                                label={`${row.points} points`}
                                                                                color="secondary"
                                                                                size="small"
                                                                            />
                                                                        )}
                                                                    </Grid>
                                                                )}
                                                                <Grid item>
                                                                    {row.blocked === 'y' ? (
                                                                        <Typography
                                                                            style={{ color: 'red' }}
                                                                            variant="body1">
                                                                            Bloqué
                                                                        </Typography>
                                                                    ) : (
                                                                        <Grid
                                                                            container
                                                                            spacing={1}
                                                                            direction="row"
                                                                            justifyContent="flex-end"
                                                                            justify="flex-end">
                                                                            {row.electricity ===
                                                                            '1' ? (
                                                                                <Grid item>
                                                                                    <FlashOnOutlinedIcon
                                                                                        style={{
                                                                                            color:
                                                                                                'gold'
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            ) : null}
                                                                            {row.water === '1' ? (
                                                                                <Grid item>
                                                                                    <InvertColorsIcon
                                                                                        style={{
                                                                                            color:
                                                                                                'dodgerblue'
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                            ) : null}
                                                                            <Grid item>
                                                                                <PlacementButton
                                                                                    id={row.id}
                                                                                    marketDayId={id}
                                                                                    paid={
                                                                                        row.paid ===
                                                                                        'y'
                                                                                    }
                                                                                    isHolder={
                                                                                        currentFilter.merchantType ===
                                                                                        't'
                                                                                    }
                                                                                    isPlaced={
                                                                                        row.loading !==
                                                                                            'y' &&
                                                                                        row.journalplace_id !==
                                                                                            null
                                                                                    }
                                                                                    loading={
                                                                                        row.loading ===
                                                                                        'y'
                                                                                    }
                                                                                    isPresent={
                                                                                        row.isPresent ===
                                                                                        'y'
                                                                                    }
                                                                                    update={{
                                                                                        fnc: r =>
                                                                                            this.updateRow(
                                                                                                index,
                                                                                                r
                                                                                            ),
                                                                                        doPlacement: () =>
                                                                                            this.openMapForm(
                                                                                                row
                                                                                            ),
                                                                                        row
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                {currentFilter.merchantType ===
                                                                                    'p' ||
                                                                                (currentFilter.merchantType ===
                                                                                    't' &&
                                                                                    row.journalplace_id !==
                                                                                        null) ? (
                                                                                    <PayButton
                                                                                        currentFilter={JSON.stringify(
                                                                                            currentFilter
                                                                                        )}
                                                                                        billNumber={
                                                                                            row.bill_number
                                                                                        }
                                                                                        isPlaced={
                                                                                            row.loading !==
                                                                                                'y' &&
                                                                                            row.journalplace_id !==
                                                                                                null
                                                                                        }
                                                                                        fnc={{
                                                                                            quick: () =>
                                                                                                this.openQuickPaymentForm(
                                                                                                    row
                                                                                                ),
                                                                                            show: () =>
                                                                                                printBill(
                                                                                                    row.bill_number
                                                                                                ),
                                                                                            send: () =>
                                                                                                this.openSenderForm(
                                                                                                    row.bill_number
                                                                                                ),
                                                                                            payment: () =>
                                                                                                this.openPaymentMethodForm(
                                                                                                    true,
                                                                                                    row
                                                                                                )
                                                                                        }}
                                                                                        paid={
                                                                                            row.paid ===
                                                                                            'y'
                                                                                        }
                                                                                        loading={
                                                                                            row.loading ===
                                                                                            'y'
                                                                                        }
                                                                                    />
                                                                                ) : null}
                                                                            </Grid>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </MuiTable>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(Dashboard);
