import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import { PlacementSynthesis, BillSynthesis, Comment, GlobalBillSynthesis } from './components';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

class Synthesis extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            etat: '',
            comment: ''
        };
    }

    componentDidUpdate = (nextProps, nextState) => {
        if (this.props.filter != nextProps.filter) {
            this.getData();
        }
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/synthesis/${this.props.id}`,
            {
                // Your POST endpoint
                method: 'GET'
            }
        )
            .then(response => {
                // Obligé de retouner la réponse JSON pour qu'elle soit contenu dans la v.a data
                return response.ok ? response.json() : false;
            })
            .then(
                data => {
                    this.setState({
                        ...data
                    });
                } // if the response is a JSON object
            )
            .then(
                success => console.log(success) // Handle the success response object
            )
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { classes, etat, comment } = this.state;

        const { filter, id } = this.props;

        return (
            <div className={classes.root}>
                <Grid
                    container
                    direction="column"
                    spacing={4}
                    justify="center"
                    alignItems="stretch">
                    <Grid item>
                        <PlacementSynthesis filter={filter} id={id} />
                    </Grid>
                    <Grid item>
                        <GlobalBillSynthesis id={id} />
                    </Grid>
                    <Grid item>
                        <BillSynthesis filter={filter} id={id} />
                    </Grid>
                    <Grid item>
                        <Comment
                            data={{
                                comment: comment,
                                etat: etat
                            }}
                            history={this.props.history}
                            id={id}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Synthesis);
