import React from 'react';
import { Badge, Typography, Tooltip } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    bill: {
        marginLeft: 10
    },
    badge: {
        '& span': {
            border: '1px solid',
            background: 'aliceblue'
        }
    }
}));

const BillBadge = props => {
    const { className, record, bills } = props;
    const classes = useStyles();
    const title = bills.map((row, index) => `${row}${index + 1 < record ? ', ' : ''}`);

    return (
        <div className={classes.root}>
            <Badge
                badgeContent={record > 1 ? record : null}
                className={clsx(className, classes.badge)}>
                {record > 1 ? (
                    <FileCopyIcon fontSize="small" />
                ) : (
                    <DescriptionIcon fontSize="small" />
                )}
            </Badge>{' '}
            {record > 1 ? (
                <Tooltip title={title}>
                    <Typography noWrap className={classes.bill}>
                        {title}
                    </Typography>
                </Tooltip>
            ) : (
                <Typography noWrap className={classes.bill}>
                    {title}
                </Typography>
            )}
        </div>
    );
};

export default BillBadge;
