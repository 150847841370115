import React from 'react';
import { makeStyles } from '@material-ui/styles';

import {
    Button,
    Grid,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {

    },
}));

const Year = props => {
    const { className, handleYear, year, ...rest } = props;

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Button onClick={() => handleYear((new Date().getFullYear()) - 1)} color="primary" variant={(new Date().getFullYear()) - 1 == year ? "contained" : "outlined"}>
                    {(new Date().getFullYear()) - 1}
                </Button>
            </Grid>
            <Grid item>
                <Button onClick={() => handleYear((new Date().getFullYear()))} color="primary" variant={(new Date().getFullYear()) == year ? "contained" : "outlined"}>
                    {(new Date().getFullYear())}
                </Button>
            </Grid>
            <Grid item>
                <Button onClick={() => handleYear((new Date().getFullYear()) + 1)} color="primary" variant={(new Date().getFullYear()) + 1 == year ? "contained" : "outlined"}>
                    {(new Date().getFullYear()) + 1}
                </Button>
            </Grid>
        </Grid>
    );
};

export default Year;
