import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import EventIcon from '@material-ui/icons/Event';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
//import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import {
    ListItem,
    ListItemAvatar,
    ListItemText,
    Avatar,
    ListItemSecondaryAction,
    IconButton,
    Divider,
    Typography,
    Grid,
    List
} from '@material-ui/core';

import {
    Option
} from './components'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    avatar: {
        backgroundColor: theme.palette.success.main,
        height: 46,
        width: 46
    },
    info: {
        paddingBottom: 0
    },
    detail: {
        paddingBottom: 0,
        paddingTop: 0
    },
    inline: {
        //display: 'inline',
    },
}));


const MarketButton = props => {
    const { className, data, ...rest } = props;

    const classes = useStyles();

    return (
        <div>
            <ListItem key={data.id} alignItems="flex-start" className={classes.info}>
                <ListItemAvatar>
                    <Avatar className={classes.avatar} style={{ backgroundColor: data.actif == "1" ? "#43a047" : "red" }}>
                        <EventIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography
                            variant="subtitle1"
                            color="primary"
                            style={{ color: data.actif == "1" ? "#3f51b5" : "red" }}
                        >
                            {data.label} {data.actif == "0" && " (événement désactivé)"}
                        </Typography>
                    }
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="subtitle2"
                                className={classes.inline}
                                color="secondary"
                            >
                                {data.jour}
                            </Typography>
                            {data.adresse}
                        </React.Fragment>
                    }
                />
                <ListItemSecondaryAction>
                    <Option id={data.id} />
                </ListItemSecondaryAction>
            </ListItem>
            <List>
                <ListItem key={data.id + "_places"} dense alignItems="flex-start" className={classes.detail}>
                    <ListItemText inset disableTypography primary={
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <ArrowRightIcon style={{ color: "#546e7a" }} />
                            </Grid>
                            <Grid item>
                                <Typography color="textSecondary">
                                    <strong>{data.nbPlaces}</strong> places
                                </Typography>
                            </Grid>
                        </Grid>
                    } />
                </ListItem>
                <ListItem key={data.id + "_metres"} dense alignItems="flex-start" className={classes.detail}>
                    <ListItemText inset disableTypography primary={
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <ArrowRightIcon style={{ color: "#546e7a" }} />
                            </Grid>
                            <Grid item>
                                <Typography color="textSecondary">
                                    <strong>{data.nbPlacesOccuped}</strong> places occupées
                                </Typography>
                            </Grid>
                        </Grid>
                    } />
                </ListItem>
            </List>
            <Divider variant="inset" component="li" />
        </div>
    );
};

export default MarketButton;