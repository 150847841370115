import React from 'react';

import { TextField, IconButton, InputAdornment } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

const SearchTextField = props => {
    const { className, label, onChange, disabled, helperText, size } = props;

    const [value, setValue] = React.useState('');

    React.useEffect(() => {
        const delayDebounce = setTimeout(() => {
            onChange(value);
        }, 1000);

        return () => clearTimeout(delayDebounce);
    }, [value]);

    return (
        <TextField
            label={label}
            type="text"
            fullWidth
            size={size ? size : 'medium'}
            value={value}
            disabled={disabled}
            onChange={event => setValue(event.target.value)}
            variant="outlined"
            helperText={helperText}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton disabled={disabled} onClick={() => setValue('')} edge="end">
                            <ClearIcon style={{ color: 'red' }} />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
};

/*SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};*/

export default SearchTextField;
