// @flow

import L from 'leaflet'
import rhumbDestination from '@turf/rhumb-destination'
import turf from 'turf'

const DEFAULT_OPTIONS = { /*pane: "markerPane",*/ color: "white", stroke: true,/* dashArray:"4",*/ fillOpacity: 0.4, weight: 2, draggable: true }

class MarketPlace extends L.rectangle {

  constructor() {

    super([[0, 0], [0, 0]], DEFAULT_OPTIONS);

    this.is_a_place = true;

    this._config = {
      type: "place",
      is_free: true,
      activitie: null,
      meters: 0,
      rotation: 0,
      numero: {
        numero: 0,
        label: null
      },
      merchant: {
        merchant: {},
        label: null
      },
    }
  }

  getId = () => {
    return this._leaflet_id;
  }

  setConfig = (key, value) => {
    this._config[key] = value
  }


  setOptions = (key, value) => {

    this.setStyle({
      key: value
    })

  }

  getConfig = (key) => {
    return this._config[key];
  }


  setActivitie = (activitie) => {
    this.setConfig("activitie", activitie);

    this.setStyle({
      color: activitie.color,
    });
  }

  setMerchant = (merchant) => {

    //if (this._config.merchant.label && merchant.siret == this._config.merchant.merchant.siret && merchant.siret != "0") {

    //  this._config.merchant.label.setLatLng(this.getLatLngs()[0][2])

    //} else {

    this._config.merchant.merchant = merchant

    let is_free = Boolean(merchant.siret == "1");
    this._config.is_free = is_free;

    this.setNumero(this.getConfig('numero').numero, is_free);
    
    //if (merchant.siret != "0") {
    //let svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 396.016 396.016" style="width:14px; height:14px; position:relative; left:-8px; top:-8px" xml:space="preserve" width="512px" height="512px" class=""><g><g>
    // <path style="fill:#D4E1F4" d="M198.008,86.727c36.571,0,65.829,29.78,65.829,66.351s-29.257,66.351-65.829,66.351   s-65.829-29.78-65.829-66.351S161.437,86.727,198.008,86.727z" data-original="#D4E1F4" class="" data-old_color="#D4E1F4"/>
    // <path style="fill:#D4E1F4" d="M332.278,321.306c-33.437,36.049-80.98,59.037-134.269,59.037S97.176,357.355,63.739,321.306   C137.927,224.653,258.09,224.653,332.278,321.306z" data-original="#D4E1F4" class="" data-old_color="#D4E1F4"/>
    //  </g><path style="fill:#4DCFE0" d="M380.343,198.008c0,47.543-18.286,90.906-48.065,123.298c-74.188-96.653-194.351-96.653-268.539,0  c-29.78-32.392-48.065-75.755-48.065-123.298c0-100.833,81.502-182.335,182.335-182.335S380.343,97.176,380.343,198.008z   M263.837,153.078c0-36.571-29.257-66.351-65.829-66.351s-65.829,29.78-65.829,66.351s29.257,66.351,65.829,66.351  S263.837,189.649,263.837,153.078z" data-original="#4DCFE0" class="" data-old_color="#4DCFE0"/><g>
    // <path style="fill:#3A2C51" d="M198.008,0C88.816,0,0,88.816,0,198.008c0,49.633,18.286,97.175,52.245,133.747l0,0   c38.139,41.273,89.861,64.261,145.763,64.261s107.624-22.988,145.763-64.261c33.437-36.571,52.245-84.114,52.245-133.747   C396.016,88.816,307.2,0,198.008,0z M198.008,31.347c91.951,0,166.661,74.71,166.661,166.661c0,36.049-11.494,70.531-32.392,98.743   c-37.094-41.273-84.114-63.739-134.269-63.739s-96.653,22.465-134.269,63.739c-20.898-28.735-32.392-63.216-32.392-98.743   C31.347,106.057,106.057,31.347,198.008,31.347z M85.159,320.261c31.347-36.049,71.053-55.902,112.849-55.902   s81.502,19.853,112.849,55.902c-31.347,28.735-70.531,44.408-112.849,44.408S115.984,348.996,85.159,320.261z" data-original="#3A2C51" class="active-path" data-old_color="#3A2C51"/>
    // <path style="fill:#3A2C51" d="M198.008,235.102c-44.931,0-81.502-36.571-81.502-82.024s36.571-82.024,81.502-82.024   s81.502,36.571,81.502,82.024S242.939,235.102,198.008,235.102z M198.008,102.4c-27.69,0-50.155,22.988-50.155,50.678   s22.465,50.678,50.155,50.678s50.155-22.988,50.155-50.678S225.698,102.4,198.008,102.4z" data-original="#3A2C51" class="active-path" data-old_color="#3A2C51"/>
    //  </g></g> </svg>`;

    //this._config.merchant.label = L.marker(this.getLatLngs()[0][2], {
    //  icon: new L.DivIcon({
    //    iconSize: null,
    //    className: 'label',
    //    html: svg
    //  }),
    //  interactive: false
    //})
    //this._config.merchant.label.is_a_place = false;
    //}
  //}
}

setNumero = (numero, is_free = true) => {

  //this.getConfig('numero').label && this.getConfig('numero').label.remove();
  let center = turf.centroid(this.toGeoJSON()).geometry.coordinates;

  //if (this._config.numero.label && numero == this._config.numero.numero && is_free == this._config.is_free) {

  //  this._config.numero.label.setLatLng([center[1], center[0]])

  //} else {

    this._config.numero.numero = numero;

    let marginLeft = numero > 99 ? "-10" : (numero > 9 ? "-7" : "-3");
    let color = is_free ? "black" : "red";

    let htmlCode = `<div style='color:${color}; margin-top: -8px;margin-left: ${marginLeft}px'><strong>` + numero + `</strong></div>`

    if (this._config.numero.label) {

      let icon = this._config.numero.label.getIcon()
      icon.options.html = htmlCode;
      this._config.numero.label.setIcon(icon);
      this._config.numero.label.setLatLng([center[1], center[0]]);

    } else {

      this._config.numero.label = L.marker([center[1], center[0]], {
        icon: new L.DivIcon({
          iconSize: null,
          className: 'label',
          html: htmlCode
        }),
        //pane: 'overlayPane'
        interactive: false
      })

      this._config.numero.label.is_a_place = false;
    }
  //}
}

setMeters = (meters, latlngs = this.getLatLngs()) => {

  let pts = {}
  let depth = 3
  let lat = latlngs[0] ? latlngs[0][0].lat : latlngs.lat;
  let lng = latlngs[0] ? latlngs[0][0].lng : latlngs.lng;
  let pt = turf.point([lng, lat])
  let rotation = this.getConfig('rotation')
  this.setConfig("meters", meters)

  pts.a = {
    lat: lat,
    lng: lng
  }

  let scale = rhumbDestination(pt, meters / 1000, 90 + rotation) // in Km

  pts.b = {
    lat: scale.geometry.coordinates[1],
    lng: scale.geometry.coordinates[0]
  }

  pt = turf.point([pts.b.lng, pts.b.lat])

  scale = rhumbDestination(pt, depth / 1000, 0 + rotation) // in Km

  pts.c = {
    lat: scale.geometry.coordinates[1],
    lng: scale.geometry.coordinates[0]
  }

  pt = turf.point([pts.a.lng, pts.a.lat])

  scale = rhumbDestination(pt, depth / 1000, 0 + rotation) // in Km

  pts.d = {
    lat: scale.geometry.coordinates[1],
    lng: scale.geometry.coordinates[0]
  }

  this.setLatLngs([pts.a, pts.b, pts.c, pts.d])

  //this.setBounds([pts.a, pts.c])

}

};

export default MarketPlace;
