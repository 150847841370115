import React from 'react';
import { withStyles } from '@material-ui/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';

import { Grid, Paper, Divider, IconButton, Typography, Link, Button } from '@material-ui/core';

import { ConnectionTime } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        margin: theme.spacing(3),
        flexGrow: 1
    }
});

class Security extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            configuration: []
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/city/get-config`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleForm = ({ target: { name, value, index } }) => {
        const { configuration } = this.state;

        configuration[index] = {
            name: name,
            value: value
        };

        this.setState({
            configuration: configuration
        });
    };

    update = () => {
        const { configuration } = this.state;

        const formData = new FormData();
        configuration.map((data, index) => {
            formData.append('config[' + index + '][name]', data.name);
            formData.append('config[' + index + '][value]', data.value);
        });
        //formData.append("maxConnectionTime", maxConnectionTime);

        this.sendData(formData);
    };

    sendData = formData => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/city/update-config`,
            {
                // Your POST endpoint
                method: 'POST',
                body: formData // This is your file object
            }
        )
            .then(response => {
                // Obligé de retouner la réponse JSON pour qu'elle soit contenu dans la v.a data
                return response.ok ? response.json() : false;
            })
            .then(
                data => {
                    if (data) {
                        alert('Mise à jour effectuée');
                        let goToSetting = window.confirm("Retourner sur l'écran des paramètres ?");
                        goToSetting && this.state.history.push('/settings');
                        this.getData();
                    }
                } // if the response is a JSON object
            )
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la mise à jour.');
                } // Handle the error response object
            );
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    render() {
        const { classes, configuration } = this.state;

        return (
            <Paper className={classes.root}>
                <Grid container direction="column" spacing={2} justify="center">
                    <Grid item>
                        <Grid container direction="row" alignItems="baseline">
                            <Grid item>
                                <IconButton onClick={this.getBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <Link component={RouterLink} to="/settings">
                                        Les paramètres
                                    </Link>{' '}
                                    / Configurer la securité
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={6}>
                            <Grid item>
                                <Grid
                                    container
                                    direction="column"
                                    spacing={3}
                                    justify="center"
                                    alignItems="stretch">
                                    {configuration.map((c, index) => {
                                        if (c.name == 'maxConnectionTime') {
                                            return (
                                                <Grid item>
                                                    <ConnectionTime
                                                        handleForm={this.handleForm}
                                                        data={c.value}
                                                        index={index}
                                                    />
                                                </Grid>
                                            );
                                        }
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container direction="column" spacing={2} alignItems="center">
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.update}
                            style={{ marginTop: 20 }}>
                            Mettre à jour
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(Security);
