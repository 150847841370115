// @flow

import React from 'react'
import L from 'leaflet';
import { Map, TileLayer, FeatureGroup, Pane, Circle } from 'react-leaflet'
import Marker from './Marker'
import LayersControl from './LayersControl'
import Control from './Control'

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


export default class Osm extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      mapConfiguration: {
        id: "mydigitmarchemap",
        center: [0, 0],
        //center: [44.1616194, 4.6221462],
        zoom: 19,
        maxZoom: 19,
        minZoom: 17,
        scrollWheelZoom: false,
        zoomControl: false,
        useFlyTo: false,
        doubleClickZoom: false, 
        style: {
          height: "800px",
        }
      },
      userLocation: [0, 0],
      hasLocation: false,
    }
  }


  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        this.setState(prevState => ({
          userLocation: [latitude, longitude],
          hasLocation: true,
          mapConfiguration: {
            ...prevState.mapConfiguration,
            //center: [43.2949898, 5.3863683]
          }
        }));
      },
      (error) => this.setState({ error: error.message }),
      {
        enableHighAccuracy: true,
        timeout: 2000,
        maximumAge: 1000
      },
    );
  }

  layersEdit = (e) => {

    console.log(e);

  }

  render() {

    const { mapConfiguration, userLocation, hasLocation} = this.state
    const markerPosition = [<Marker position={userLocation} text="Vous êtes ici" />]

    return (
      <Map {...mapConfiguration}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;https://www.pilotparc.com&quot;>Digit'Marché</a>"
        />

        <Control setPlace={this.props.setPlace} eventid={this.props.id} adresse={this.props.data.adresse} places={this.props.data.places} marchands={this.props.data.marchands} layersGroup={this.props.data.layersGroup} userLocation={userLocation} />

        { /*hasLocation && <LayersControl layers={markerPosition} labels={['Ma position']} defaultChecked />*/ }

      </Map>
    )

  }

}
