import React from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import {
    IconButton,
    Tooltip
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));


const EnhancedTablePaginationActions = (props) => {

    const classes = useStyles();
    const theme = useTheme();

    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <Tooltip title="Première page">
                <span>
                    <IconButton
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 0}
                    >
                        <FirstPageIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Page précédente">
                <span>
                    <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
                        <KeyboardArrowLeft />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Page suivante">
                <span>
                    <IconButton
                        onClick={handleNextButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    >
                        <KeyboardArrowRight />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Dernière page">
                <span>
                    <IconButton
                        onClick={handleLastPageButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    >
                        <LastPageIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    );
}

export default EnhancedTablePaginationActions