import React from 'react';
import { makeStyles } from '@material-ui/styles';

import {
    Checkbox,
    CircularProgress
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({

}));

const EnhancedCheckbox = props => {
    const { className, marchandId, checked, handleListOfMerchant, ...rest } = props;
    const [isSelected, setIsSelected] = React.useState(false);

    const handleCheckbox = () => {
        setIsSelected(true);
        handleListOfMerchant(marchandId)
    }

    return (
        <div>
            {
                isSelected  ?
                    <CircularProgress style={{ marginTop: 9, marginRight: 9, marginLeft: 8 }} size={25} />
                    :
                    <Checkbox onChange={handleCheckbox} checked={checked} />
            }
        </div>
    );
};

export default EnhancedCheckbox;
