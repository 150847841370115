import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';

import { Doughnut, Pie } from 'react-chartjs-2';

import {
    Typography,
    Card,
    CardHeader,
    CardContent,
    Avatar
} from '@material-ui/core';

import PieChartIcon from '@material-ui/icons/PieChart';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    paperContent: {
        padding: theme.spacing(3),
    },
    labelFilter: {
        color: "#3f51b5",
        marginBottom: 15
    },
    labelFilterRadio: {
        color: "#3f51b5",
        marginBottom: 5
    }
}));

const SubscriberRepartitionActivities = props => {
    const { className, data, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();
    
    const occupation = [];

    data.map(activitie => {
        occupation.push(activitie.number)
    })

    const stat = {
        datasets: [
            {
                data: occupation,
                backgroundColor: [
                    "gold",
                    "#32CD32",
                    "#87CEFA",
                    "#BD33A4",
                ],
                borderWidth: 4,
                borderColor: theme.palette.white,
                hoverBorderColor: theme.palette.white
            }
        ],
        labels: ["Alimentaire", "Primeur", "Manufacturé", "Démonstrateur/Posticheur"]
    };


    const options = {
        legend: {
            display: true,
            position: "right",
            //fullWidth: true,
        },
        responsive: true,
        //maintainAspectRatio: true,
        animation: {
            duration: 3000,
        },
        //layout: { padding: 0 },
        tooltips: {
            enabled: true,
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue / total * 100).toFixed(2));
                    return currentValue + ' (' + percentage + '%)';
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        }
    };


    return (

        <Card>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        <PieChartIcon />
                    </Avatar>
                }
                title={
                    <Typography color="primary" variant="subtitle2">
                        Répartition des <strong>titulaires abonnés</strong> par activité
                    </Typography>
                }
            />
            <CardContent>
                <Pie
                    data={stat}
                    height={250}
                    width={700}
                    options={options}
                />
            </CardContent>
        </Card>
    );
};

export default SubscriberRepartitionActivities;
