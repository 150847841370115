import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Grid,
    ClickAwayListener,
    Grow,
    Popper,
    MenuItem,
    MenuList,
    Paper
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EuroIcon from '@material-ui/icons/Euro';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
    popper: {
        zIndex: 9
    },
    button: {
        width: 125
    }
}));

const StatusButton = props => {
    const { className, pay, id, paid, ...rest } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                size="small"
                color="primary"
                ref={anchorRef}
                onClick={!paid && handleToggle}
                className={clsx(className, classes.button)}
                endIcon={!paid && <ArrowDropDownIcon />}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item style={{ textAlign: 'right' }}>
                        {paid ? 'Payée' : 'Facturée'}
                    </Grid>
                </Grid>
            </Button>
            <Popper
                className={classes.popper}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                        }}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <MenuItem
                                        key={2}
                                        onClick={event => {
                                            pay(id);
                                            handleClose(event);
                                        }}>
                                        Régler{' '}
                                        <EuroIcon style={{ color: 'green', paddingLeft: 7 }} />
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};

export default StatusButton;
