const checked = (value, options) => {
    if (value !== true) {
        return options.message || 'must be checked';
    }
};

/**
 *
 * @param {*} filename
 * @returns
 */
export const getExtension = filename => {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0].toLowerCase() : undefined;
};

/**
 * Check empty string/object
 *
 * @param {*} value
 * @returns
 */
export const empty = value => {
    if (value === null || value === undefined) {
        return true;
    }

    if (typeof value === 'object') {
        return Object.keys(value).length === 0;
    }

    if (typeof value === 'number') {
        return false;
    }

    return value.length === 0 || !value.trim();
};

/**
 *
 * @param {*} value
 * @returns
 */
export const formatAsBankCheck = value => {
    const re = /^[0-9\b]+$/;

    if (value === '' || re.test(value)) {
        return value.slice(0, 7);
    }

    return value.slice(0, -1);
};

/**
 *
 * @param {*} value
 * @returns
 */
export const isValidBankCheck = value => {
    const re = /^[0-9\b]+$/;
    return re.test(value) && value.length == 7;
};

/**
 *
 * @param {*} values
 * @returns
 */
export const checkOneIntervalsBillPackage = (a, b) => {
    return parseInt(b.min) > parseInt(a.min) && parseInt(b.max) > parseInt(a.max);
};

/**
 *
 * @param {*} values
 * @returns
 */
export const isIntervalsEqualToOneBillPackage = (a, b) => {
    return parseInt(b.min) - parseInt(a.max) === 1;
};

/**
 *
 * @param {*} values
 * @returns
 */
export const checkIntervalsBillPackage = values => {
    let isValid = true;
    const sortedValues = [...values];

    values.map(a => {
        if (isValid) {
            sortedValues.shift();
            isValid = !sortedValues.some(
                b =>
                    parseInt(a.min) >= parseInt(b.min) ||
                    parseInt(a.max) >= parseInt(b.max) ||
                    parseInt(a.min) >= parseInt(b.max) ||
                    parseInt(a.max) >= parseInt(b.min)
            );
        }
    });

    if (isValid) {
        const last = values.slice(-1)[0];
        if (parseInt(last.min) > parseInt(last.max)) {
            isValid = false;
        }
    }

    if (isValid) {
        isValid = values.every(
            r => parseFloat(r.amount) > 0 && parseInt(r.min) > 0 && parseInt(r.max) > 0
        );
    }

    return isValid;
};

export default {
    checked
};
