import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
    black,
    white,
    primary: {
        contrastText: white,
        dark: colors.indigo[900],
        main: colors.indigo[500],
        semi: colors.indigo[400],
        light: colors.indigo[100]
    },
    secondary: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue['A400'],
        light: colors.blue['A400']
    },
    success: {
        contrastText: white,
        dark: colors.green[900],
        main: colors.green[600],
        light: colors.green[400]
    },
    info: {
        contrastText: white,
        dark: colors.blue[900],
        main: colors.blue[600],
        light: colors.blue[400]
    },
    warning: {
        contrastText: white,
        dark: colors.orange[900],
        main: colors.orange[600],
        light: colors.orange[400]
    },
    error: {
        contrastText: white,
        dark: colors.red[900],
        main: colors.red[600],
        light: colors.red[400],
        veryLight: colors.red[100]
    },
    text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
        link: colors.blue[600]
    },
    background: {
        default: '#F4F6F8',
        paper: white
    },
    icon: colors.blueGrey[600],
    divider: colors.grey[200],

    // For Bill
    canceled: {
        normal: colors.blueGrey[700],
        hover: colors.blueGrey[900]
    },
    paid: {
        normal: colors.green[600],
        hover: colors.green[900]
    },
    partially_paid: {
        normal: colors.blue[600],
        hover: colors.blue[900]
    },
    unpaid: {
        normal: colors.orange[700],
        hover: colors.orange[900]
    },
    late: {
        normal: colors.red[600],
        hover: colors.red[900]
    },
    generated: {
        normal: colors.orange[600],
        hover: colors.orange[900]
    },
    ta: {
        normal: colors.indigo[500],
        hover: colors.indigo[800]
    },
    tna: {
        normal: colors.blue[500],
        hover: colors.blue[800]
    },
    p: {
        normal: colors.cyan[500],
        hover: colors.cyan[800]
    }
};
