import React from 'react';
import { withStyles } from '@material-ui/styles';
import { FormControl, FormLabel, TextField, Grid } from '@material-ui/core';

const styles = theme => ({
    root: {
        flexGrow: 1
    }
});

class FormDialogContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            label: ''
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { subject, message, title } = this.state;

        if (
            subject != prevState.subject ||
            message != prevState.message ||
            title != prevState.title
        ) {
            this.props.setData('subject', subject);
            this.props.setData('message', message);
            this.props.setData('title', title);
        }
    };

    getData = _method => {
        const { reminder } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/email/get-template/${reminder}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    handleForm = ({ target: { name, value } }) => {
        this.props.setData(name, value);
        this.setState({
            [name]: value
        });
    };

    render() {
        const { classes, subject, message, label } = this.state;

        return (
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <FormControl component="fieldset">
                                <FormLabel color="primary">Modèle du message</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={label}
                                inputProps={{
                                    readOnly: true
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        spacing={1}
                        justify="center"
                        alignItems="stretch">
                        <Grid item>
                            <FormControl required component="fieldset">
                                <FormLabel color="primary">Objet et corps du message</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <TextField
                                placeholder="Votre objet"
                                variant="outlined"
                                fullWidth
                                name="subject"
                                value={subject}
                                onChange={this.handleForm}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                placeholder="Votre message"
                                variant="outlined"
                                name="message"
                                multiline
                                fullWidth
                                value={message}
                                rows={7}
                                rowsMax={15}
                                onChange={this.handleForm}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(FormDialogContent);
