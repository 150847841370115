import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {}
}));

const EventForm = props => {
    const { className, data, handleForm, ...rest } = props;

    const classes = useStyles();
    const provider = new OpenStreetMapProvider();
    const [lookingForAdresses, setLookingForAdresses] = React.useState([]);

    const handledLocationSelected = (event, value) => {
        if (value) {
            handleForm({ target: { name: 'adresse', value: value.label } });
            handleForm({ target: { name: 'lat', value: value.y } });
            handleForm({ target: { name: 'lng', value: value.x } });
        } else {
            handleForm({ target: { name: 'adresse', value: '' } });
            handleForm({ target: { name: 'lat', value: '' } });
            handleForm({ target: { name: 'lng', value: '' } });
        }
    };

    const handleSearch = ({ target: { name, value } }) => {
        (async () => {
            const res = await provider.search({ query: value });
            setLookingForAdresses(res);
        })();
    };

    return (
        <Grid container direction="row" spacing={4} style={{ marginTop: 10 }}>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Libellé"
                    name="label"
                    type="text"
                    onChange={handleForm}
                    value={data.label}
                    variant="outlined"
                    helperText="Saisir un libellé"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Autocomplete
                    options={lookingForAdresses}
                    getOptionLabel={option => option.label}
                    filterOptions={x => x}
                    autoComplete
                    autoHighlight
                    noOptionsText="Aucune adresse trouvée"
                    onChange={handledLocationSelected}
                    renderInput={params => (
                        <TextField
                            fullWidth
                            name="adresse"
                            onChange={handleSearch}
                            label="Adresse de l'événement"
                            type="text"
                            placeholder={data.adresse}
                            helperText="Saisir une adresse"
                            variant="outlined"
                            {...params}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Commentaire (facultatif)"
                    name="commentaire"
                    type="text"
                    rows={4}
                    rowsMax={5}
                    multiline
                    onChange={handleForm}
                    value={data.commentaire == 'null' ? '' : data.commentaire}
                    variant="outlined"
                    helperText="Saisir un commentaire"
                />
            </Grid>
        </Grid>
    );
};

export default EventForm;
