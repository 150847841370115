import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Paper, Grid, Divider, Typography, IconButton, Link, Button } from '@material-ui/core';

import { Search as SearchIcon } from '@material-ui/icons';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Keyboard, Profil, ChooseSiteDialog, TabControl } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        flexGrow: 1
    }
});

class Creation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            step: 0,
            kabis: '',
            selectedKabis: '',
            openSiteDialog: false,
            metiers: [],
            isLoading: false,
            company: null,
            availabality: null,
            mandataire: null,
            match: false,
            totalSites: 0,
            totalSitesEnable: 0,
            totalSitesDisable: 0,
            totalIn: 0,
            totalNotIn: 0,
            choosenKey: false,
            hasOpposed: false,
            regexp: /^[0-9\b]+$/
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        this.state.isLoading &&
            this.state.step === 0 &&
            prevState.isLoading != this.state.isLoading &&
            this.getData();
        //prevState.data != this.state.data && this.setState({ step: 1 })
    };

    getData = () => {
        const { kabis } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/search?kabis=${kabis}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                if (!data.match) {
                    alert('SIRET ou SIREN introuvable.');
                    this.setState({
                        openSiteDialog: false,
                        isLoading: false
                    });
                    return;
                }

                this.setState({
                    openSiteDialog: true,
                    isLoading: false,
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('SIRET ou SIREN introuvable.');
                    this.setState({
                        openSiteDialog: false,
                        isLoading: false
                    });
                } // Handle the error response object
            );
    };

    sendData = data => {
        const { selectedKabis } = this.state;

        if (data.activite_id == 0) {
            alert('Veuillez renseigner une activité.');
            this.setState({
                isLoading: false
            });
            return;
        }

        if (!data.metier) {
            alert('Veuillez renseigner un métier.');
            this.setState({
                isLoading: false
            });
            return;
        }

        const formData = new FormData();
        formData.append('kabis', selectedKabis);
        formData.append('activite_id', data.activite_id);
        formData.append('metier_id', data.metier.id);
        formData.append('tel', data.telephone);
        formData.append('link', data.internet);
        formData.append('mail', data.mail);
        //formData.append("assureur", data.assurance);
        formData.append('dateFinAssurance', data.dateFinAssurance);
        formData.append('numeroPolice', data.numeroPoliceAssurance);
        formData.append('meters', data.metres);
        formData.append('enseigne', data.enseigne);
        formData.append('hasOpposed', data.hasOpposed);
        formData.append('points', data.points);
        formData.append('username', this.props.userProfile.getName());
        data.arrivedAt != '' && formData.append('arrivedAt', data.arrivedAt);
        data.holderDate != '' && formData.append('holderDate', data.holderDate);
        data.merchantCardDate != '' && formData.append('merchantCardDate', data.merchantCardDate);
        data.aot != '' && formData.append('aot', data.aot);
        data.aotFile && formData.append('aotFile', data.aotFile);
        data.assuranceFile && formData.append('assuranceFile', data.assuranceFile);
        this.props.journalMarket.getId() != 0 &&
            formData.append('journalmarche_id', this.props.journalMarket.getId());

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/new`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                if (data) {
                    if (data.journalmarche) {
                        // let goto = window.confirm(
                        //     `Commerçant créé avec succès et inscrit sur ${data.journalmarche.label}\nRetourner sur le marché ?`
                        // );
                        // if (goto) {
                        //     this.props.history.push(
                        //         `/my-market/${data.journalmarche.id}/dashboard/`
                        //     );
                        // } else {
                        this.props.history.push(`/merchants/get/${data.siret}/profil`);
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                        // }
                    } else {
                        alert('Commerçant créé avec succès.');
                        this.props.history.push(`/merchants/get/${data.siret}/profil`);
                        window.scroll({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }
                }
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la création.');
                    this.setState({
                        isLoading: false
                    });
                } // Handle the error response object
            );
    };

    handleKabis = value => {
        (value === '' || this.state.regexp.test(value)) &&
            this.setState({
                kabis: value.slice(0, 14)
            });
    };

    handleStep = step => {
        this.setState({
            step: step
        });
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleDialog = () => {
        this.setState({
            openSiteDialog: false
        });
    };

    handleLoading = value => {
        this.setState({
            isLoading: value
        });
    };

    handleChoosenKey = value => {
        this.setState({
            choosenKey: value
        });
    };

    handleSelectedKabis = (kabis, choosenKey) => {
        this.setState({
            selectedKabis: kabis,
            choosenKey: choosenKey
        });
    };

    render() {
        const {
            classes,
            step,
            kabis,
            openSiteDialog,
            isLoading,
            choosenKey,
            selectedKabis,
            company,
            sites,
            metiers,
            availabality,
            mandataire,
            totalSites,
            hasOpposed
        } = this.state;

        return (
            <Paper className={classes.root}>
                {openSiteDialog && (
                    <ChooseSiteDialog
                        close={this.handleDialog}
                        setKabis={this.handleSelectedKabis}
                        setChoosenKey={this.handleChoosenKey}
                        nextStep={this.handleStep}
                        data={{
                            company: company,
                            sites: sites,
                            availabality: availabality,
                            mandataire: mandataire,
                            choosenKey: choosenKey,
                            kabis: kabis,
                            selectedKabis: selectedKabis,
                            totalSites: totalSites,
                            hasOpposed: hasOpposed
                        }}
                    />
                )}
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container direction="row" alignItems="baseline">
                            <Grid item>
                                <IconButton onClick={this.getBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <Link component={RouterLink} to="/merchants/all">
                                        Les commerçants
                                    </Link>{' '}
                                    / Créer un commerçant
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    {step == 1 && (
                        <Grid item>
                            <Button
                                style={{ float: 'right' }}
                                onClick={() => this.handleStep(0)}
                                variant="contained"
                                color="primary">
                                <SearchIcon /> Nouvelle recherche
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        <TabControl value={step} index={0}>
                            <Keyboard
                                isLoading={isLoading}
                                setLoading={this.handleLoading}
                                setKabis={this.handleKabis}
                                kabis={kabis}
                            />
                        </TabControl>
                        <TabControl value={step} index={1}>
                            <Profil
                                data={{
                                    company: company,
                                    sites: sites,
                                    availabality: availabality,
                                    mandataire: mandataire,
                                    choosenKey: choosenKey,
                                    kabis: kabis,
                                    selectedKabis: selectedKabis,
                                    totalSites: totalSites,
                                    metiers: metiers,
                                    hasOpposed: hasOpposed
                                }}
                                userProfile={this.props.userProfile}
                                isLoading={isLoading}
                                setIsLoading={this.handleLoading}
                                sendData={this.sendData}
                            />
                        </TabControl>
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(Creation);
