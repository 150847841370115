import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import All from './All';
import TotalAddedCard from './TotalAddedCard';
import FormDialog from './FormDialog';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paperContent: {
        padding: theme.spacing(4)
        //padding: 10
    },
    item: {
        minWidth: '30%'
    }
}));

const Unit = props => {
    const classes = useStyles();
    const { history } = props;
    const { tab, id } = props.match.params;

    const close = () => {
        history.push('/settings/unit');
    };

    return (
        <div className={classes.root}>
            <FormDialog close={close} open={tab === 'create' || tab === 'edit'} id={id} />
            <Grid container direction="column" spacing={3}>
                <Grid item xs={12} sm={4}>
                    <TotalAddedCard />
                </Grid>
                <Grid item>
                    <All tab={tab} />
                </Grid>
            </Grid>
        </div>
    );
};

export default Unit;
