
import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Link as RouterLink } from 'react-router-dom';

import UpdateIcon from '@material-ui/icons/Update';
import TodayIcon from '@material-ui/icons/Today';
import {
    Grid,
    Typography,
    Link
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
    },
    title: {
        color: theme.palette.primary.main,
    }
}))

const UpcomingMarkets = props => {
    const { className, data, ...rest } = props;

    const today = new Date();
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    }

    const classes = useStyles();

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h3" gutterBottom className={classes.title}>
                    Bonjour PLACIER,
                </Typography>
                <Typography variant="h5" >
                    Voici ce qu'il s'est passé aujourd'hui à {data.city} le <Link underline="always">{today.toLocaleDateString('fr-FR', options)}</Link>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom className={classes.title}>
                    <TodayIcon /> Marché(s) du jour
                </Typography>
                <ul style={{ listStyle: "inside circle", paddingLeft: 30 }}>
                    {
                        data.list.today.length > 0 ?
                            data.list.today.map((market) => (
                                <Typography variant="body1">
                                    <li>{market.label}</li>
                                </Typography>
                            )) :
                            <Typography variant="body1">
                                <li>Pas de marché aujourd'hui</li>
                            </Typography>
                    }
                </ul>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom className={classes.title}>
                    <UpdateIcon /> Marché(s) à venir pour cette semaine
                </Typography>
                <ul style={{ listStyle: "inside circle", paddingLeft: 30 }}>
                    {
                        data.list.upcoming.length > 0 ?
                            data.list.upcoming.map((market) => (
                                <Typography variant="body1">
                                    <li>{market.label}, le {market.date}</li>
                                </Typography>
                            )) :
                            <Typography variant="body1">
                                <li>Aucun marché n'est à venir</li>
                            </Typography>
                    }
                </ul>
            </Grid>
        </Grid>
    )
}

export default UpcomingMarkets;


