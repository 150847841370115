import React from 'react'

class TabControl extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...props
    }
  }

  render() {

    const { children, value, index } = this.props;

    return (
      <div>
        {value === index && children}
      </div>
    )

  }

}

export default TabControl
