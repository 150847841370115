import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    Grid,
    Typography,
    TextField,
    Divider,
    ListItemText,
    TableBody,
    TableRow,
    Table,
    TableCell,
    TableContainer,
    FormControlLabel,
    Checkbox,
    IconButton,
    TableFooter,
    ButtonGroup,
    Button
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import ClearIcon from '@material-ui/icons/Clear';

const styles = theme => ({
    searchInput: {
        //paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    container: {
        maxHeight: 'calc(100vh - 456px)'
    },
    containerList: {
        position: 'absolute',
        width: '100%'
    },
    pageButton: {
        position: 'absolute',
        bottom: 0,
        width: '100%'
    },
    content: {
        position: 'absolute',
        height: 'calc(100vh - 427px)'
    },
    filter: {
        paddingLeft: theme.spacing(2)
    },
    tableRow: {
        '&$hover:hover': {
            backgroundColor: 'blue'
        }
    },
    tableCell: {
        '$hover:hover &': {
            color: 'pink'
        }
    },
    cellRs: {
        maxWidth: 185
    }
});

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    },
    hover: {
        '&$hover:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.08)',
            cursor: 'pointer'
        }
    },
    selected: {
        '&$selected, &$selected:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.15)',
            cursor: 'auto'
        }
    }
}))(TableRow);

class Holders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            searchValue: '',
            list: [],
            selectedActivities: [],
            numberOfPages: 0,
            page: 1,
            isSubscriber: false,
            isNotSubscriber: false,
            filterHasChanged: false
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        (prevState.searchValue != this.state.searchValue ||
            (this.state.filterHasChanged &&
                prevState.filterHasChanged != this.state.filterHasChanged) ||
            prevState.isSubscriber != this.state.isSubscriber ||
            prevState.isNotSubscriber != this.state.isNotSubscriber ||
            prevState.page != this.state.page) &&
            this.getData();
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        const { merchants } = this.props;

        const { searchValue, page, isSubscriber, isNotSubscriber, selectedActivities } = this.state;

        const formData = new FormData();
        formData.append('search', searchValue);
        formData.append('merchants', JSON.stringify(merchants));
        formData.append('page', page);
        formData.append('isNotSubscriber', isNotSubscriber);
        formData.append('isSubscriber', isSubscriber);
        selectedActivities.length > 0 &&
            formData.append('activities', JSON.stringify(selectedActivities));

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/get-holders-by-for-creation`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    filterHasChanged: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    this.setState({
                        filterHasChanged: false
                    });
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value
        });
    };

    handleActivitieFilter = id => {
        const { selectedActivities } = this.state;

        const index = selectedActivities.indexOf(id);

        if (index > -1) {
            selectedActivities.splice(index, 1);
        } else {
            selectedActivities.push(id);
        }

        this.setState({
            selectedActivities: selectedActivities,
            filterHasChanged: true
        });
    };

    handleStateOfMerchant = ({ target: { name, checked } }) => {
        this.setState({
            [name]: checked
        });
    };

    handlePagination = page => {
        this.setState(prevState => ({
            page: prevState.page + page
        }));
    };

    render() {
        const {
            classes,
            searchValue,
            list,
            selectedActivities,
            isSubscriber,
            isNotSubscriber,
            numberOfPages,
            page
        } = this.state;

        const { activities, merchants } = this.props;

        const { layer } = this.props.placeData;

        const { select, mouseHover, mouseOut } = this.props.fncs;

        return (
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <div className={classes.searchInput}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            onChange={this.handleSearch}
                            value={searchValue}
                            label="Rechercher un commerçant"
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            this.handleSearch({ target: { value: '' } })
                                        }>
                                        <ClearIcon style={{ color: 'red' }} />
                                    </IconButton>
                                )
                            }}
                        />
                    </div>
                </Grid>
                <Grid item>
                    <div className={classes.filter}>
                        <Grid container direction="row" justify="flex-start">
                            {activities.map(({ id, label, color }) => {
                                if (label == 'Démonstrateur/Posticheur') {
                                    return (
                                        <Grid item xs={6} key={label}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        style={{ color: color }}
                                                        checked={Boolean(
                                                            selectedActivities.indexOf(id) > -1
                                                        )}
                                                        onChange={() =>
                                                            this.handleActivitieFilter(id)
                                                        }
                                                    />
                                                }
                                                label={
                                                    <Typography variant="body2">
                                                        Dém.../Posticheur
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                    );
                                }

                                return (
                                    <Grid item xs={6} key={label}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    size="small"
                                                    style={{ color: color }}
                                                    checked={Boolean(
                                                        selectedActivities.indexOf(id) > -1
                                                    )}
                                                    onChange={() => this.handleActivitieFilter(id)}
                                                />
                                            }
                                            label={<Typography variant="body2">{label}</Typography>}
                                        />
                                    </Grid>
                                );
                            })}
                            <Grid item xs={6} key="abo">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={isSubscriber}
                                            onChange={this.handleStateOfMerchant}
                                            name="isSubscriber"
                                        />
                                    }
                                    label={<Typography variant="body2">Abonné</Typography>}
                                />
                            </Grid>
                            <Grid item key="noneabon">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={isNotSubscriber}
                                            onChange={this.handleStateOfMerchant}
                                            name="isNotSubscriber"
                                        />
                                    }
                                    label={<Typography variant="body2">Non abonné</Typography>}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        justify="space-between"
                        className={classes.content}>
                        <Grid item className={classes.containerList}>
                            <TableContainer className={classes.container}>
                                <Table size="small">
                                    <TableBody>
                                        {list.length > 0 ? (
                                            list.map(merchant => {
                                                return (
                                                    <StyledTableRow
                                                        onMouseOut={() =>
                                                            mouseOut(merchant.data.place.leaflet_id)
                                                        }
                                                        onMouseOver={() =>
                                                            mouseHover(
                                                                merchant.data.place.leaflet_id
                                                            )
                                                        }
                                                        onClick={() =>
                                                            select(merchant.data.place.leaflet_id)
                                                        }
                                                        selected={Boolean(
                                                            layer
                                                                ? layer.getId() ==
                                                                      merchant.data.place.leaflet_id
                                                                : false
                                                        )}
                                                        hover
                                                        key={merchant.order}>
                                                        <TableCell
                                                            align="left"
                                                            key={merchant.data.place.numero}>
                                                            <ListItemText
                                                                primary={`N°${merchant.data.place.numero}`}
                                                                secondary={
                                                                    <Typography variant="body2">
                                                                        {merchant.data.place.meters}{' '}
                                                                        m
                                                                    </Typography>
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            className={classes.cellRs}
                                                            key={merchant.data.raisonSociale}>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography noWrap>
                                                                        {
                                                                            merchant.data
                                                                                .raisonSociale
                                                                        }
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        noWrap>
                                                                        {merchant.data.metierLabel}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </TableCell>
                                                    </StyledTableRow>
                                                );
                                            })
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    style={{ border: 'none' }}>
                                                    <Typography align="center">
                                                        Aucun commerçant n'a été trouvé...
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item className={classes.pageButton}>
                            <ButtonGroup variant="text" size="small" fullWidth color="primary">
                                <Button
                                    disabled={Boolean(page == 1)}
                                    onClick={() => this.handlePagination(-1)}>
                                    <ArrowBackIcon fontSize="small" />
                                </Button>
                                <Button
                                    disabled={
                                        numberOfPages == 0 ? true : Boolean(page == numberOfPages)
                                    }
                                    onClick={() => this.handlePagination(1)}>
                                    <ArrowForwardIcon fontSize="small" />
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Holders);
