import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MuiPopper } from './components';
import {
    Card,
    CardContent,
    CardActions,
    Button,
    Typography,
    TextField,
    Grid,
    CardHeader,
    Avatar,
    IconButton
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    avatar: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.primary.main
    },
    form: {
        display: 'contents'
    },
    container: {
        height: '100%'
    },
    root: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch'
    }
}));

const SearchDateCard = props => {
    const { inline, start, end, submit, title, noPaper } = props;

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [submited, setSubmited] = React.useState(false);
    const [error, setError] = React.useState(false);
    const anchorRef = React.useRef(null);

    React.useEffect(() => {
        submited && setSubmited(false);

        if (submited && !error) {
            if (new Date(start.data).getTime() > new Date(end.data).getTime()) {
                if (
                    window.confirm(
                        'La date de début est supérieur à la date de fin.\nInverser les dates ?'
                    )
                ) {
                    start.set(end.data);
                    end.set(start.data);
                } else {
                    setSubmited(false);
                    return;
                }
            }

            submit();
        }
    }, [submited, start.data, end.data]);

    const submitHandler = event => {
        event.preventDefault();
        setError(false);

        if (!start.data || start.data == '') {
            setError('start');
        }

        if (!end.data || end.data == '') {
            setError('end');
        }

        if ((!start.data && !end.data) || (start.data == '' && end.data == '')) {
            setError('all');
        }

        setSubmited(true);
    };

    return (
        <React.Fragment>
            {noPaper ? (
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    className={classes.container}>
                    <form onSubmit={submitHandler} className={classes.form}>
                        <Grid item>
                            <CardContent>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12} md={inline ? 5 : 12}>
                                        <TextField
                                            label="Date de début"
                                            type="date"
                                            fullWidth
                                            size="small"
                                            name="start"
                                            helperText={
                                                (error == 'start' || error == 'all') &&
                                                'Veuillez saisir une date de début'
                                            }
                                            error={error == 'start' || error == 'all'}
                                            value={start.data}
                                            onChange={event => start.set(event.target.value)}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={
                                                {
                                                    //max: (type == "F" ? lastDayOfPreviousMonth : yesterday)
                                                }
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={inline ? 5 : 12}>
                                        <TextField
                                            label="Date de fin"
                                            type="date"
                                            fullWidth
                                            size="small"
                                            name="end"
                                            helperText={
                                                (error == 'end' || error == 'all') &&
                                                'Veuillez saisir une date de fin'
                                            }
                                            error={error == 'end' || error == 'all'}
                                            value={end.data}
                                            onChange={event => end.set(event.target.value)}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={
                                                {
                                                    //max: (type == "F" ? lastDayOfPreviousMonth : yesterday)
                                                }
                                            }
                                        />
                                    </Grid>
                                    {inline && (
                                        <Grid item xs={12} md={2}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary">
                                                Rechercher
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        </Grid>

                        <Grid item>
                            {!inline && (
                                <CardActions>
                                    <Button
                                        type="submit"
                                        size="small"
                                        fullWidth
                                        variant="contained"
                                        color="primary">
                                        Rechercher
                                    </Button>
                                </CardActions>
                            )}
                        </Grid>
                    </form>
                </Grid>
            ) : (
                <Card className={classes.root}>
                    <Grid
                        container
                        direction="column"
                        justify="space-between"
                        className={classes.container}>
                        <Grid item>
                            <CardHeader
                                avatar={
                                    <Avatar
                                        className={classes.avatar}
                                        variant="rounded"
                                        color="primary">
                                        <DateRangeIcon />
                                    </Avatar>
                                }
                                action={
                                    <IconButton
                                        ref={anchorRef}
                                        onClick={() => setOpen(prevOpen => !prevOpen)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title={
                                    <Typography color="primary" variant="h5">
                                        {title}
                                    </Typography>
                                }
                            />
                        </Grid>
                        <form onSubmit={submitHandler} className={classes.form}>
                            <Grid item>
                                <CardContent>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12} md={inline ? 5 : 12}>
                                            <TextField
                                                label="Date de début"
                                                type="date"
                                                fullWidth
                                                size="small"
                                                name="start"
                                                helperText={
                                                    (error == 'start' || error == 'all') &&
                                                    'Veuillez saisir une date de début'
                                                }
                                                error={error == 'start' || error == 'all'}
                                                value={start.data}
                                                onChange={event => start.set(event.target.value)}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={
                                                    {
                                                        //max: (type == "F" ? lastDayOfPreviousMonth : yesterday)
                                                    }
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={inline ? 5 : 12}>
                                            <TextField
                                                label="Date de fin"
                                                type="date"
                                                fullWidth
                                                size="small"
                                                name="end"
                                                helperText={
                                                    (error == 'end' || error == 'all') &&
                                                    'Veuillez saisir une date de fin'
                                                }
                                                error={error == 'end' || error == 'all'}
                                                value={end.data}
                                                onChange={event => end.set(event.target.value)}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={
                                                    {
                                                        //max: (type == "F" ? lastDayOfPreviousMonth : yesterday)
                                                    }
                                                }
                                            />
                                        </Grid>
                                        {inline && (
                                            <Grid item xs={12} md={2}>
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary">
                                                    Rechercher
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </CardContent>
                            </Grid>

                            <Grid item>
                                {!inline && (
                                    <CardActions>
                                        <Button
                                            type="submit"
                                            size="small"
                                            fullWidth
                                            variant="contained"
                                            color="primary">
                                            Rechercher
                                        </Button>
                                    </CardActions>
                                )}
                            </Grid>
                        </form>
                    </Grid>
                </Card>
            )}

            <MuiPopper
                setOpen={() => setOpen(prevOpen => !prevOpen)}
                open={open}
                anchorRef={anchorRef}
                setDate={(start, end) => {
                    props.start.set(start);
                    props.end.set(end);
                    setSubmited(true);
                }}
                clearForm={() => {
                    start.set('');
                    end.set('');
                    setError(false);
                    setSubmited(false);
                }}
            />
        </React.Fragment>
    );
};

export default SearchDateCard;
