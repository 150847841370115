import React, { useState, useEffect } from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { combineStyles } from 'common/styles';
import clsx from 'clsx';

const useStyles = makeStyles(
    combineStyles(theme => ({
        root: {
            //padding: theme.spacing(4),
            flexGrow: 1
        },
        paperContent: {
            padding: theme.spacing(3)
        }
    }))
);

/**
 *
 */
const LoadingIcon = <CircularProgress fontSize={25} />;
const SuccessIcon = <CheckCircleOutlineIcon fontSize={'large'} style={{ color: 'green' }} />;
const ErrorIcon = <HighlightOffIcon fontSize={'large'} color="error" />;
const InfoIcon = <InfoOutlinedIcon fontSize={'large'} style={{ color: 'orange' }} />;

/**
 *
 * @param {*} res
 * @returns
 */
function checkResultFilter(res) {
    return res.result === true;
}

/**
 *
 * @param {*} transactions
 * @param {*} setIcon
 * @param {*} setDescribe
 */
function showResult(classes, transactions, setIcon, setDescribe) {
    if (typeof transactions === 'string') {
        setIcon(ErrorIcon);
        setDescribe(transactions);
    } else {
        const fullTransaction =
            transactions.totalTransaction.filter(checkResultFilter).length ===
            transactions.totalTransaction.length;
        const unfullTransaction =
            transactions.totalTransaction.filter(checkResultFilter).length === 0;

        setIcon(fullTransaction ? SuccessIcon : InfoIcon);
        setDescribe(
            <>
                <Typography paragraph variant="body1">
                    {fullTransaction
                        ? 'Liste des factures réglées avec succès : '
                        : 'Certaines factures ont déjà été réglées :'}
                </Typography>
                {transactions.totalTransaction.map(row => (
                    <Typography
                        align="center"
                        className={clsx({
                            [classes.paid]: row.result === true,
                            [classes.unpaid]: row.result === false
                        })}>
                        N°{row.bill} : {row.result ? 'Réglée' : 'Déjà réglée'}
                    </Typography>
                ))}
            </>
        );
    }
}

const Done = props => {
    const { loading, transactions } = props;
    const classes = useStyles();
    const [icon, setIcon] = useState(LoadingIcon);
    const [describe, setDescribe] = useState(
        <Typography variant="body1">Réglement en cours...</Typography>
    );

    useEffect(() => {
        !loading && showResult(classes, transactions, setIcon, setDescribe);
    }, [loading]);

    return (
        <Grid
            style={{ marginTop: 10 }}
            container
            direction="column"
            spacing={2}
            justify="center"
            justifyContent="center"
            alignItems="center">
            <Grid item>{icon}</Grid>
            <Grid item>{describe}</Grid>
        </Grid>
    );
};

export default Done;
