import React from 'react'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import {
    Card,
    Typography,
    Grid,
    Divider,
    Button,
    CardContent, 
    CardActions
} from '@material-ui/core'

import { BillCanceller } from 'views';

const StateBillCard = (props) => {
    const { state, billNumber, update } = props
    const [billCanceller, setBillCanceler] = React.useState(null);

    return (
        <React.Fragment>

            <BillCanceller
                update={update}
                setBillNumber={setBillCanceler}
                billNumber={billCanceller}
            />

            <Card elevation={2}>
                <CardContent>
                    <Grid container direction="column" spacing={2} alignItems="center" justify="stretch">
                        <Grid item>
                            <Grid container direction="column" alignItems="center" justify="center">
                                <Grid item>
                                    <Typography variant='h5' style={{ color: state.color }} align="center">
                                        {state.label}
                                    </Typography>
                                </Grid>
                                {
                                    (state.state != 'del' && state.paid) &&
                                    <React.Fragment>
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body2' style={{ color: state.color }} align="center">
                                                Réglée le {state.date}
                                            </Typography>
                                        </Grid>
                                    </React.Fragment>
                                }
                            </Grid>
                        </Grid>
                        <Grid item>
                            {
                                state.state == 'del' ?
                                    <HighlightOffIcon fontSize="large" style={{ color: state.color }} />
                                    :
                                    state.paid ?
                                        <CheckCircleIcon fontSize="large" style={{ color: state.color }} /> :
                                        <HourglassEmptyIcon fontSize="large" style={{ color: state.color }} />
                            }
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button fullWidth onClick={() => setBillCanceler(billNumber)} disabled={!(state.state != 'del' && !state.paid)} variant="contained" color="primary" size="small">
                        Annuler la facture
                    </Button>
                </CardActions>
            </Card>
        </React.Fragment>
    )
}

export default StateBillCard;