import React, { useEffect, useState } from 'react';
import { printBill } from 'common/pdf';
import { formatAsBankCheck } from 'common/validators';
import {
    Grid,
    Typography,
    Table,
    TableCell,
    TableBody,
    TableRow,
    MenuItem,
    TextField,
    ListItemText,
    InputAdornment,
    IconButton,
    CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getBillsBeforePaid, getPaymentMethods } from './action';
import ClearIcon from '@material-ui/icons/Clear';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles(theme => ({
    table: {
        tableLayout: 'fixed'
    },
    tableBody: {
        '& tr:nth-child(even)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        },
        '& tr td': {
            paddingTop: 8,
            paddingBottom: 8
        },
        '& tr:first-child': {
            '& td': {
                borderTop: '1px solid #eee'
            }
        }
    }
}));

const PaymentForm = props => {
    const { bills, paymentMethod } = props;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [rows, setRows] = useState([]);
    const [totalAmount, setTotatAmount] = useState('0,00');
    const classes = useStyles();
    const isPayingByCheck = paymentMethods.find(
        r => r.label === 'Chèque' && paymentMethod.get.value === r.id
    );

    useEffect(() => {
        getPaymentMethods()
            .then(res => setPaymentMethods(res))
            .catch(err => alert(err));

        getBillsBeforePaid(bills)
            .then(res => {
                if (res) {
                    setRows(res.rows);
                    setTotatAmount(res.totalAmount);
                } else {
                    setError(true);
                }

                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            justify="flex-start"
            spacing={3}>
            <Grid item>
                <Grid container spacing={2} direction="column" alignItems="flex-start">
                    <Grid item>
                        <Typography variant="button" color="primary">
                            Facture(s) sélectionnée(s) : {rows?.bills?.length ?? 0}
                        </Typography>
                    </Grid>
                    {loading || error ? (
                        error ? (
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    style={paymentMethod.get.errors?.bills ? { color: 'red' } : {}}>
                                    {paymentMethod.get.errors?.bills ?? error}
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid item style={{ textAlign: 'center', width: '100%' }}>
                                <CircularProgress size={26} />
                            </Grid>
                        )
                    ) : (
                        <Grid item>
                            <Table size="small" className={classes.table}>
                                <TableBody className={classes.tableBody}>
                                    {rows?.bills?.map(row => (
                                        <TableRow hover key={row.billNumber}>
                                            <TableCell align="center">
                                                <IconButton
                                                    onClick={() => printBill(row.billNumber)}
                                                    size="small">
                                                    <PictureAsPdfIcon size="small" />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align="left">{row.issueDate}</TableCell>
                                            <TableCell align="left">{row.billNumber}</TableCell>
                                            <TableCell align="left">{row.meters} mètres</TableCell>
                                            <TableCell align="right">{row.amount} €</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow hover key={'amount'}>
                                        <TableCell align="right" colSpan={4}>
                                            <Typography variant="body1" style={{ fontWeight: 500 }}>
                                                Montant total
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography variant="body1" style={{ fontWeight: 500 }}>
                                                {totalAmount} €
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    )}
                </Grid>
                <Grid item style={{ marginTop: 10 }}>
                    <Grid container spacing={2} direction="column" alignItems="flex-start">
                        <Grid item>
                            <Typography variant="button" color="primary">
                                Paiement
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Table size="small" className={classes.table}>
                                <TableBody className={classes.tableBody}>
                                    <TableRow hover key="paymentMethods">
                                        <TableCell align="left">Moyen de paiement</TableCell>
                                        <TableCell align="left">
                                            <TextField
                                                name="paymentMethods"
                                                type="text"
                                                fullWidth
                                                select
                                                variant="standard"
                                                size="small"
                                                error={paymentMethod.get.errors?.value}
                                                helperText={paymentMethod.get.errors?.value}
                                                value={paymentMethod.get.value}
                                                onChange={({ target: { value } }) =>
                                                    paymentMethod.set(value, '', '')
                                                }>
                                                {paymentMethods.map(item => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow hover key="bank">
                                        <TableCell align="left">
                                            <ListItemText
                                                primary="Banque"
                                                secondary="Numéro de chèque"
                                            />
                                        </TableCell>
                                        <TableCell align="left">
                                            <Grid
                                                container
                                                direction="column"
                                                spacing={1}
                                                justifyContent="center"
                                                justify="center"
                                                alignItems="stretch">
                                                <Grid item>
                                                    <TextField
                                                        placeholder="Crédit Agricole, LCL, Banque populaire..."
                                                        disabled={!isPayingByCheck}
                                                        name="bank"
                                                        error={paymentMethod.get.errors?.bank}
                                                        helperText={paymentMethod.get.errors?.bank}
                                                        type="text"
                                                        fullWidth
                                                        variant="standard"
                                                        size="small"
                                                        value={paymentMethod.get.bank}
                                                        onChange={({ target: { value } }) =>
                                                            paymentMethod.set(
                                                                paymentMethod.get.value,
                                                                value,
                                                                paymentMethod.get.check
                                                            )
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        disabled={!isPayingByCheck}
                                                                        onClick={() =>
                                                                            paymentMethod.set(
                                                                                paymentMethod.get
                                                                                    .value,
                                                                                '',
                                                                                paymentMethod.get
                                                                                    .check
                                                                            )
                                                                        }
                                                                        edge="end"
                                                                        size="small">
                                                                        <ClearIcon fontSize="small" />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        placeholder="XXXXXXX"
                                                        disabled={!isPayingByCheck}
                                                        name="bankCheck"
                                                        error={paymentMethod.get.errors?.check}
                                                        helperText={paymentMethod.get.errors?.check}
                                                        type="text"
                                                        fullWidth
                                                        variant="standard"
                                                        size="small"
                                                        value={paymentMethod.get.check}
                                                        onChange={({ target: { value } }) =>
                                                            paymentMethod.set(
                                                                paymentMethod.get.value,
                                                                paymentMethod.get.bank,
                                                                formatAsBankCheck(value)
                                                            )
                                                        }
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        disabled={!isPayingByCheck}
                                                                        onClick={() =>
                                                                            paymentMethod.set(
                                                                                paymentMethod.get
                                                                                    .value,
                                                                                paymentMethod.get
                                                                                    .bank,
                                                                                ''
                                                                            )
                                                                        }
                                                                        edge="end"
                                                                        size="small">
                                                                        <ClearIcon fontSize="small" />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PaymentForm;
