import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import { Grid, Button } from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    }
});

class ItinerantShop extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props
        };
    }

    componentDidMount = () => {
        //this.getData();
    };

    getData = () => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/home-page`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    render() {
        const { classes } = this.state;

        return <div>ma terrace</div>;
    }
}

export default withStyles(styles)(ItinerantShop);
