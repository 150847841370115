import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Typography,
    Link
} from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles(theme => ({}));

const Icon = (props) => {

    const { stats } = props;
    let icon = <div></div>

    if (stats.unsent == 0) {
        icon = <CheckCircleIcon size={60} style={{ color: "green", fontSize: 60 }} />
    } else if (stats.unsent > 0 && stats.sent > 0) {
        icon = <WarningIcon size={60} style={{ color: "orange", fontSize: 60 }} />
    } else if (stats.sent == 0 && stats.unsent > 0) {
        icon = <ErrorIcon size={60} style={{ color: "red", fontSize: 60 }} />
    }

    return icon
}

const Done = props => {
    const { className, data, ...rest } = props;

    const classes = useStyles();

    return (
        <Grid container
            direction="column" spacing={3}
            justify="center"
            alignItems="center"
        >
            <Grid item>
                <Icon stats={data.stats} />
            </Grid>
            <Grid item>
                <Typography>
                    <ul>
                        <li>Nombre total de commerçants sélectionnés : <strong>{data.stats.total}</strong></li>
                        <li>Nombre de commerçants ayant reçu le mail : <strong>{data.stats.sent}</strong></li>
                        <li>Nombre de commerçants n'ayant pas reçu le mail : <strong>{data.stats.unsent}</strong></li>
                    </ul>
                </Typography>
            </Grid>
            {
                (data.stats.unsent == 0 && data.stats.sent > 0) &&
                <Grid item>
                    <Typography style={{ color: "green" }}>
                        Tous les mails ont été envoyés.{" "}
                        <Link href={data.link} target="_blank">
                            Imprimer la liste <PrintIcon style={{ position: "relative", top: 7 }} />
                        </Link>
                    </Typography>
                </Grid>
            }
            {
                (data.stats.unsent > 0 && data.stats.sent > 0) &&
                <Grid item>
                    <Typography style={{ color: "orange" }}>
                        Certains commerçants n'auraient pas reçu le mail.{" "}
                        <Link href={data.link} target="_blank">
                            Imprimer la liste <PrintIcon style={{ position: "relative", top: 7 }} />
                        </Link>
                    </Typography>
                </Grid>
            }
            {
                (data.stats.sent == 0 && data.stats.unsent > 0) &&
                <Grid item>
                    <Typography style={{ color: "red" }}>
                        Aucun commerçant n'a reçu le mail.{" "}
                        <Link href={data.link} target="_blank">
                            Imprimer la liste <PrintIcon style={{ position: "relative", top: 7 }} />
                        </Link>
                    </Typography>
                </Grid>
            }
        </Grid>


    );
};

export default Done;
