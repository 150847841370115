import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Dialog } from '@material-ui/core';

import { DialogTitle, Form, Loader, Done } from './components';

import TabControl from './TabControl';

const styles = theme => ({
    root: {
        flexGrow: 1
    }
});

class Email extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            open: props.open,
            step: 'form',
            mailsNotSent: [],
            numbers: [],
            link: '',
            file: null,
            message: '',
            objet: '',
            previousMails: [],
            type: ''
        };
    }

    componentDidUpdate = prevProps => {
        if (prevProps.open != this.props.open) {
            this.setState({ open: this.props.open });
        }
    };

    sendData = data => {
        const formData = new FormData();
        data.type && formData.append('type', data.type);
        data.file && formData.append('file', data.file);
        formData.append('message', data.message);
        formData.append('objet', data.objet);

        data.type != 'all' &&
            data.selectedMails.map((data, index) => {
                formData.append(`mails[${index}]`, data.id);
            });

        this.setState({
            step: 'loader',
            message: data.message,
            objet: data.objet,
            file: data.file,
            type: data.type ? data.type : '',
            previousMails: data.selectedMails
        });

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/send-email`,
            {
                // Your POST endpoint
                method: 'POST',
                body: formData // This is your file object
            }
        )
            .then(response => {
                // Obligé de retouner la réponse JSON pour qu'elle soit contenu dans la v.a data
                return response.ok ? response.json() : false;
            })
            .then(
                data => {
                    this.setState({
                        ...data
                    });
                } // if the response is a JSON object
            )
            .then(
                success => console.log(success) // Handle the success response object
            )
            .catch(
                error => {
                    console.error(error);
                    alert("Le message n'a pas pu être envoyé, veuillez réessayer ultérieurement");
                    this.setState({ step: 'form' });
                } // Handle the error response object
            );
    };

    handleClose = () => {
        this.state.step !== 'loader' && this.props.history.goBack();
    };

    getBack = () => {
        this.setState({
            step: 'form'
        });
    };

    render() {
        const {
            classes,
            open,
            step,
            numbers,
            link,
            message,
            objet,
            file,
            type,
            previousMails
        } = this.state;

        const { importedMails, notify, selectedBillToNotify } = this.props;

        return (
            <Dialog fullWidth maxWidth={'sm'} onClose={this.handleClose} open={open}>
                <DialogTitle onClose={this.handleClose} step={step}>
                    Contacter les commerçants
                </DialogTitle>
                <TabControl value={step} index={'form'}>
                    <Form
                        hasToImport={importedMails ? true : null}
                        bill={selectedBillToNotify ? selectedBillToNotify : null}
                        notify={notify ? notify : false}
                        importedMails={importedMails}
                        message={message}
                        objet={objet}
                        file={file}
                        type={type}
                        previousMails={previousMails}
                        sendData={this.sendData}
                        close={this.handleClose}
                    />
                </TabControl>
                <TabControl value={step} index={'loader'}>
                    <Loader />
                </TabControl>
                <TabControl value={step} index={'done'}>
                    <Done
                        data={{
                            numbers: numbers,
                            link: link
                        }}
                        close={this.handleClose}
                        getBack={this.getBack}
                    />
                </TabControl>
            </Dialog>
        );
    }
}

export default withStyles(styles)(Email);
