import env from 'common/env';
import UserProfile from 'UserProfile';

/**
 *
 * @param {*} id
 * @param {*} payload
 * @returns
 */
export async function getAllBills(payload) {
    const formData = new FormData();
    formData.append('search', payload.searchValue);
    formData.append('order', payload.order);
    formData.append('orderBy', payload.orderBy);
    formData.append('page', payload.page);
    formData.append('state', payload.filter ?? 'all');

    const result = await fetch(`${env}/pound-bill/`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @param {*} payload
 * @returns
 */
export function getPdfPreview(payload) {
    const token = UserProfile.getToken();
    return `${env}/pound-bill/preview?token=${token}&payload=${btoa(JSON.stringify(payload))}`;
}

/**
 *
 * @param {*} id
 * @param {*} payload
 * @returns
 */
export async function createBill(payload) {
    const formData = new FormData();
    formData.append('payload', btoa(JSON.stringify(payload)));

    const result = await fetch(`${env}/pound-bill/create`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la création de la facture';
        });

    return result;
}

/**
 *
 * @param {*} billid
 * @returns
 */
export async function getBill(billId) {
    const result = await fetch(`${env}/pound-bill/show/${billId}`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @param {*} payload
 * @returns
 */
export async function editBill(billId, payload) {
    const formData = new FormData();
    formData.append('payload', btoa(JSON.stringify(payload)));

    const result = await fetch(`${env}/pound-bill/edit/${billId}`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la modification de la facture';
        });

    return result;
}

/**
 *
 * @param {*} billid
 * @returns
 */
export async function deleteBill(billId) {
    const result = await fetch(`${env}/pound-bill/delete/${billId}`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la suppression des données';
        });

    return result;
}

/**
 * @param {*} billId
 * @returns
 */
export function getBillPdf(billId) {
    const token = UserProfile.getToken();
    return `${env}/pound-bill/print/${billId}?token=${token}`;
}

/**
 *
 * @returns
 */
export async function getPaymentMethods() {
    const result = await fetch(`${env}/regie/get-means-of-payment`, {
        method: 'GET'
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw "Une erreur est survenue lors de l'affichage des moyens de paiement.";
        });

    return result;
}

/**
 *
 * @param {*} recipeId
 * @param {*} payload
 * @returns
 */
export async function addRecipeBill(recipeId, payload) {
    const formData = new FormData();
    formData.append('label', payload.label);
    formData.append('payment', payload.payment);
    formData.append('bank', payload.bank?.title);
    formData.append('bankCheck', payload.bankCheck);
    formData.append('amount', payload.amount);

    const result = await fetch(`${env}/pound-bill/add-recipe/${recipeId}`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la modification de la facture';
        });

    return result;
}

/**
 *
 * @param {*} id
 * @param {*} payload
 * @returns
 */
export async function getBillRecipes(id, payload) {
    const formData = new FormData();
    formData.append('search', '');
    formData.append('order', payload.order);
    formData.append('orderBy', payload.orderBy);
    formData.append('page', payload.page);

    const result = await fetch(`${env}/pound-bill/recipes/${id}`, {
        method: 'POST',
        body: formData
    })
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

/**
 *
 * @param {*} billRecipeId
 * @returns
 */
export async function deleteBillRecipe(billRecipeId) {
    const result = await fetch(`${env}/pound-bill/delete-recipe/${billRecipeId}`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la suppression des données';
        });

    return result;
}

export async function getStatsBill() {
    const result = await fetch(`${env}/pound-bill/statistics`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la récupération des données';
        });

    return result;
}

export async function generateNewBillNumber() {
    const result = await fetch(`${env}/pound-bill/generate-new-bill-number`)
        .then(response => (response.ok ? response.json() : false))
        .then(res => res)
        .catch(error => {
            console.error(error);
            throw 'Echec de la suppression des données';
        });

    return result;
}
