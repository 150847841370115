import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';

import { Bar } from 'react-chartjs-2';

import {
    Typography,
    Card,
    CardHeader,
    CardContent,
    Avatar
} from '@material-ui/core';

import TrendingUpIcon from '@material-ui/icons/TrendingUp';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    paperContent: {
        padding: theme.spacing(3),
    },
    labelFilter: {
        color: "#3f51b5",
        marginBottom: 15
    },
    labelFilterRadio: {
        color: "#3f51b5",
        marginBottom: 5
    }
}));

const LateBillsBar = props => {
    const { className, rawData, date, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    const data = {
        labels: rawData.labels,
        datasets: [
            {
                label: `${rawData.val} € de facture en retard de paiement`,
                borderWidth: 1,
                borderColor: theme.palette.error.main,
                backgroundColor: theme.palette.error.veryLight,
                data: rawData.data.unformattedVal,
            }
        ],
    }

    const options = {
        responsive: true,
        legend: {
            position: "right",
            align: "center",
            labels: {
                boxWidth: 12.5,
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    min: 0,
                    beginAtZero: true,
                    //stepSize: rawData.step.val
                }
            }]
        },
        tooltips: {
            caretSize: 0,
            displayColors: false,
            callbacks: {
                label: function (tooltipItem, data) {
                    return `Valeur des factures en retard : ${rawData.data.formattedVal[tooltipItem.index]} €`;
                },
            }
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0 // animation duration after a resize
    }

    return (

        <Card>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        <TrendingUpIcon />
                    </Avatar>
                }
                title={
                    <Typography color="primary" variant="subtitle2">
                        Valeurs des factures en retard de paiement pour la période du {date.start} au {date.end}
                    </Typography>
                }
            />
            <CardContent>
                <Bar
                    data={data}
                    options={options}
                />
            </CardContent>
        </Card>
    );
};

export default LateBillsBar;
