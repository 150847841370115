import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import {
    Grid,
    Paper,
    TextField,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Button,
    ListItemText,
    IconButton,
    Typography,
    InputAdornment,
    FormControlLabel,
    RadioGroup,
    Radio,
    Checkbox,
    FormControl,
    FormLabel,
    FormGroup,
    Divider
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import ExposureNeg1Icon from '@material-ui/icons/ExposureNeg1';
import WarningIcon from '@material-ui/icons/Warning';
import DoneIcon from '@material-ui/icons/Done';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import { Alert, Pagination, DigitMap, DrawDialog, ResultDrawDialog } from './components';

const styles = theme => ({
    root: {
        paddingTop: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    }
});

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

const columns = [
    {
        id: 'numero',
        label: 'N°',
        minWidth: '30%',
        align: 'left'
    },
    {
        id: 'status',
        label: 'Statut',
        minWidth: '10%',
        align: 'left'
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: '35%',
        align: 'left'
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: '25%',
        align: 'left'
    }
];

const drawerColumns = [
    {
        id: 'order',
        label: 'Ordre',
        minWidth: '10%',
        align: 'left'
    },
    {
        id: 'numero',
        label: 'N°',
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'status',
        label: 'Statut',
        minWidth: '10%',
        align: 'left'
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: '35%',
        align: 'left'
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: '25%',
        align: 'left'
    }
];

class Placement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            openMap: false,
            anchorAlert: null,
            assuranceExpiree: '0',
            assuranceManquante: '0',
            bapRetard: '0',
            bloque: '0',
            siret: '',
            list: [],
            listOfPlaces: [],
            searchValue: '',
            page: 1,
            numberOfPages: 0,
            marchand: false,
            all: true,
            ta: false,
            tna: false,
            p: false,
            etat: 'all',
            regexp: /^[0-9\b]+$/,
            openDialog: false,
            openResultDialog: false,
            drawer: false,
            type: 'ta',
            draw: '',
            draws: []
        };
    }

    componentDidUpdate = (nextProps, nextState) => {
        if (
            this.state.ta != nextState.ta ||
            this.state.tna != nextState.tna ||
            this.state.p != nextState.p ||
            this.state.etat != nextState.etat ||
            this.state.searchValue != nextState.searchValue ||
            this.state.type != nextState.type ||
            this.state.draw != nextState.draw ||
            this.state.page != nextState.page ||
            this.state.marchand != nextState.marchand
        ) {
            this.getData();
        }
    };

    getData = () => {
        const { all, ta, tna, p, etat, page, searchValue, type, draw } = this.state;

        const formData = new FormData();

        formData.append('page', page);
        formData.append('search', searchValue);
        formData.append('etat', etat);
        formData.append('type', type);
        formData.append('draw', draw);

        if (!ta && !tna && !p && !all) {
            this.setState({
                all: true
            });
            formData.append('tna', true);
            formData.append('ta', true);
            formData.append('p', true);
        } else {
            formData.append('tna', all ? true : tna);
            formData.append('ta', all ? true : ta);
            formData.append('p', all ? true : p);
        }

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/get-placement-journalmarket/${this.props.id}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    componentDidMount = () => {
        this.getData();
    };

    handleTypeFilter = ({ target: { name, checked } }) => {
        const { all } = this.state;

        if (name === 'all') {
            !all &&
                this.setState({
                    ta: false,
                    tna: false,
                    p: false
                });
        } else {
            this.setState({
                all: false
            });
        }

        this.setState({
            [name]: checked,
            page: 1
        });
    };

    handleStateFilter = ({ target: { value } }) => {
        this.setState({
            etat: value,
            page: 1
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handleAlert = (
        event = null,
        assuranceExpiree = '0',
        assuranceManquante = '0',
        bapRetard = '0',
        bloque = '0',
        siret
    ) => {
        this.setState({
            anchorAlert: event ? event.currentTarget : null,
            assuranceExpiree: assuranceExpiree,
            assuranceManquante: assuranceManquante,
            bapRetard: bapRetard,
            bloque: bloque,
            siret: siret
        });
    };

    alertMerchant = merchant => {
        let alert = 0;
        merchant.assuranceExpiree != '0' && ++alert;
        merchant.assuranceManquante == '1' && ++alert;
        merchant.bapRetard != '0' && ++alert;
        merchant.bloque != '0' && ++alert;

        return (
            <Grid spacing={1} container direction="row" justify="flex-start" alignItems="center">
                {alert > 0 && (
                    <Grid item>
                        <Button
                            onClick={event =>
                                this.handleAlert(
                                    event,
                                    merchant.assuranceExpiree,
                                    merchant.assuranceManquante,
                                    merchant.bapRetard,
                                    merchant.bloque,
                                    merchant.siret
                                )
                            }
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{
                                background: '#eb5a46',
                                minWidth: 'fit-content',
                                padding: '4px 6px'
                            }}>
                            <WarningIcon fontSize="small" />{' '}
                            <Typography style={{ color: 'white', marginLeft: 5, fontWeight: 500 }}>
                                {alert}
                            </Typography>
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <ListItemText
                        primary={merchant.raisonSociale}
                        secondary={
                            <Typography variant="body2">
                                {merchant.status == 'p'
                                    ? 'Passager'
                                    : merchant.status == 'ta'
                                    ? 'Titulaire abonné'
                                    : 'Titulaire non abonné'}
                            </Typography>
                        }
                    />
                </Grid>
            </Grid>
        );
    };

    makePlacement = marchand => {
        this.setState(prevState => ({
            openMap: !prevState.openMap,
            marchand: marchand
        }));
    };

    removeFromPlace = marchand => {
        let _confirmRemove = window.confirm(
            `Souhaitez-vous enlever ${marchand.raisonSociale} de la place N°${marchand.numero} ?`
        );
        if (_confirmRemove) {
            const formData = new FormData();
            formData.append('journalplace_id', marchand.journalplace_id);

            fetch(
                `https://www.${
                    window.location.hostname.split('.')[1]
                        ? window.location.hostname.split('.')[0] === 'www'
                            ? window.location.hostname.split('.')[1]
                            : window.location.hostname.split('.')[0]
                        : 'dev'
                }.api.digitmarche.fr/api/placement/remove-from-place-journalmarket`,
                {
                    method: 'POST',
                    body: formData
                }
            )
                .then(response => {
                    return response.ok ? response.json() : false;
                })
                .then(data => {
                    alert(
                        `${data.marchand} a été enlevé de la place.\nPlace N°${data.place.numero} disponible.`
                    );
                    this.getData();
                })
                .catch(
                    error => {
                        console.error(error);
                        alert('Echec de la récupération des données');
                    } // Handle the error response object
                );
        }
    };

    updateMeters = (journalplace_id, operator) => {
        const formData = new FormData();
        formData.append('journalplace_id', journalplace_id);
        formData.append('operator', operator);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/update-place-journalmarket/${this.props.id}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.getData();
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleDrawFilter = ({ target: { value } }) => {
        this.setState({
            draw: value,
            page: 1
        });
    };

    render() {
        const {
            classes,
            list,
            searchValue,
            page,
            numberOfPages,
            anchorAlert,
            assuranceExpiree,
            assuranceManquante,
            bloque,
            bapRetard,
            siret,
            ta,
            all,
            tna,
            p,
            etat,
            openMap,
            marchand,
            openDialog,
            openResultDialog,
            drawer,
            type,
            draw,
            draws
        } = this.state;

        return (
            <div className={classes.root}>
                <DigitMap
                    id={this.props.id}
                    marchand={marchand}
                    close={() => this.makePlacement(false)}
                    open={openMap}
                />

                <Alert
                    anchorAlert={anchorAlert}
                    data={{
                        assuranceExpiree: assuranceExpiree,
                        assuranceManquante: assuranceManquante,
                        bloque: bloque,
                        bapRetard: bapRetard
                    }}
                    siret={siret}
                    handleAlert={this.handleAlert}
                />

                {drawer && (
                    <>
                        <DrawDialog
                            open={openDialog}
                            id={this.props.id}
                            close={() => {
                                this.getData();
                                this.setState({ openDialog: false });
                            }}
                        />
                        <ResultDrawDialog
                            open={openResultDialog}
                            id={this.props.id}
                            close={() => this.setState({ openResultDialog: false })}
                        />
                    </>
                )}

                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <Paper className={classes.paperContent}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                spacing={3}>
                                <Grid item>
                                    <TextField
                                        label={'Rechercher un commerçant'}
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={searchValue}
                                        onChange={this.handleSearch}
                                        helperText="Saisir une raison sociale, une enseigne, un nom, un prénom ou numéro de place"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={this.clearSearch}
                                                        edge="end">
                                                        <ClearIcon style={{ color: 'red' }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControl component="fieldset">
                                        <FormLabel
                                            className={classes.labelFilter}
                                            component="label">
                                            Type du commerçant
                                        </FormLabel>
                                        {drawer ? (
                                            <RadioGroup
                                                row
                                                name="type"
                                                value={type}
                                                onChange={event =>
                                                    this.handleTypeFilter({
                                                        target: {
                                                            name: event.target.name,
                                                            checked: event.target.value
                                                        }
                                                    })
                                                }>
                                                <FormControlLabel
                                                    value="ta"
                                                    control={<Radio />}
                                                    label="Titulaire abonné"
                                                />
                                                <FormControlLabel
                                                    value="tna"
                                                    control={<Radio />}
                                                    label="Titulaire non abonné"
                                                />
                                                <FormControlLabel
                                                    value="p"
                                                    control={<Radio />}
                                                    label="Passager"
                                                />
                                            </RadioGroup>
                                        ) : (
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={all}
                                                            onChange={this.handleTypeFilter}
                                                            name="all"
                                                        />
                                                    }
                                                    label="Tous"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={ta}
                                                            onChange={this.handleTypeFilter}
                                                            name="ta"
                                                        />
                                                    }
                                                    label="Titulaire abonné"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={tna}
                                                            onChange={this.handleTypeFilter}
                                                            name="tna"
                                                        />
                                                    }
                                                    label="Titulaire non abonné"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={p}
                                                            onChange={this.handleTypeFilter}
                                                            name="p"
                                                        />
                                                    }
                                                    label="Passager"
                                                />
                                            </FormGroup>
                                        )}
                                    </FormControl>
                                </Grid>

                                {drawer && draws.length > 0 && type === 'p' && (
                                    <Grid item>
                                        <FormControl component="fieldset">
                                            <FormLabel
                                                className={classes.labelFilter}
                                                component="label">
                                                Tirage
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                name="draw"
                                                value={draw}
                                                onChange={this.handleDrawFilter}>
                                                {draws.map(row => (
                                                    <FormControlLabel
                                                        value={row.draw_id}
                                                        control={<Radio />}
                                                        label={row.label}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                )}

                                <Grid item>
                                    <Grid
                                        container
                                        alignItems="flex-end"
                                        justify="space-between"
                                        spacing={2}>
                                        <Grid item>
                                            <FormControl component="fieldset">
                                                <FormLabel
                                                    component="legend"
                                                    className={classes.labelFilter}>
                                                    Etat de placement
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    name="etat"
                                                    onChange={this.handleStateFilter}
                                                    value={etat}>
                                                    <FormControlLabel
                                                        value="all"
                                                        control={<Radio />}
                                                        label="Tous"
                                                    />
                                                    <FormControlLabel
                                                        value="y"
                                                        control={
                                                            <Radio style={{ color: 'green' }} />
                                                        }
                                                        label="Placé"
                                                    />
                                                    <FormControlLabel
                                                        value="n"
                                                        control={<Radio style={{ color: 'red' }} />}
                                                        label="Non placé/Absent"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        {drawer && (
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction="column"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-end"
                                                    spacing={1}>
                                                    <Grid item>
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() =>
                                                                this.setState({
                                                                    openResultDialog: true
                                                                })
                                                            }>
                                                            Résultat
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() =>
                                                                this.setState({ openDialog: true })
                                                            }
                                                            endIcon={<SlowMotionVideoIcon />}>
                                                            Lancer le tirage au sort
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>

                                <Grid item>
                                    <TableContainer>
                                        <Table stickyHeader size="small" className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    {(drawer && type === 'p'
                                                        ? drawerColumns
                                                        : columns
                                                    ).map(column => (
                                                        <StyledTableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{
                                                                minWidth: column.minWidth
                                                            }}>
                                                            {column.label}
                                                        </StyledTableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {list.map((merchant, index) => {
                                                    return (
                                                        <StyledTableRow hover key={index}>
                                                            {drawer && type === 'p' && (
                                                                <TableCell
                                                                    key={`0_${merchant.numero}`}
                                                                    align="left">
                                                                    {merchant.drawOrder}
                                                                </TableCell>
                                                            )}

                                                            <TableCell
                                                                key={`1_${merchant.numero}`}
                                                                align="left">
                                                                <Grid
                                                                    container
                                                                    direction="column"
                                                                    alignItems="flex-start"
                                                                    justify="space-between">
                                                                    <Grid item>
                                                                        {merchant._order !=
                                                                        '999' ? (
                                                                            <ListItemText
                                                                                primary={
                                                                                    'N°' +
                                                                                    merchant.numero
                                                                                }
                                                                                secondary={
                                                                                    merchant.metres +
                                                                                    ' mètres'
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            '-'
                                                                        )}
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell
                                                                key={`4_${merchant.raisonSociale}`}
                                                                align="left">
                                                                {(merchant.present == '0' &&
                                                                    merchant.status == 'ta') ||
                                                                (merchant.present == '0' &&
                                                                    merchant.status == 'tna') ? (
                                                                    <Typography
                                                                        style={{ color: 'red' }}>
                                                                        Absent
                                                                    </Typography>
                                                                ) : merchant.present == '1' &&
                                                                  merchant._order != '999' ? (
                                                                    <Typography
                                                                        style={{ color: 'green' }}>
                                                                        Placé
                                                                    </Typography>
                                                                ) : (
                                                                    <Typography
                                                                        style={{ color: 'red' }}>
                                                                        Non placé
                                                                    </Typography>
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                key={`2_${merchant.raisonSociale}`}
                                                                align="left">
                                                                {this.alertMerchant(merchant)}
                                                            </TableCell>
                                                            <TableCell
                                                                key={`3_${merchant.marchand_id}`}
                                                                align="center">
                                                                {merchant.encaisse == '0' ? (
                                                                    <Grid
                                                                        container
                                                                        direction="row"
                                                                        spacing={1}>
                                                                        <Grid item>
                                                                            {(merchant.present ==
                                                                                '0' &&
                                                                                merchant.status ==
                                                                                    'ta') ||
                                                                            (merchant.present ==
                                                                                '0' &&
                                                                                merchant.status ==
                                                                                    'tna') ? (
                                                                                <Button
                                                                                    onClick={() =>
                                                                                        this.makePlacement(
                                                                                            merchant
                                                                                        )
                                                                                    }
                                                                                    variant="contained"
                                                                                    size="small"
                                                                                    color="primary">
                                                                                    Placer
                                                                                </Button>
                                                                            ) : merchant.present ==
                                                                                  '1' &&
                                                                              merchant._order !=
                                                                                  '999' ? (
                                                                                <Button
                                                                                    onClick={() =>
                                                                                        this.makePlacement(
                                                                                            merchant
                                                                                        )
                                                                                    }
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    size="small"
                                                                                    style={{
                                                                                        background:
                                                                                            'orange'
                                                                                    }}>
                                                                                    Déplacer
                                                                                </Button>
                                                                            ) : (
                                                                                <Button
                                                                                    onClick={() =>
                                                                                        this.makePlacement(
                                                                                            merchant
                                                                                        )
                                                                                    }
                                                                                    size="small"
                                                                                    variant="contained"
                                                                                    color="primary">
                                                                                    Placer
                                                                                </Button>
                                                                            )}
                                                                        </Grid>
                                                                        {merchant.present == '1' &&
                                                                            merchant._order !=
                                                                                '999' && (
                                                                                <Grid item>
                                                                                    <Button
                                                                                        onClick={() =>
                                                                                            this.removeFromPlace(
                                                                                                merchant
                                                                                            )
                                                                                        }
                                                                                        size="small"
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        style={{
                                                                                            background:
                                                                                                'green'
                                                                                        }}>
                                                                                        Libérer
                                                                                    </Button>
                                                                                </Grid>
                                                                            )}
                                                                    </Grid>
                                                                ) : (
                                                                    <React.Fragment>
                                                                        Réglé{' '}
                                                                        <DoneIcon
                                                                            style={{
                                                                                fontSize: 'inherit',
                                                                                color: 'green'
                                                                            }}
                                                                        />
                                                                    </React.Fragment>
                                                                )}
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item>
                                    <Pagination
                                        numberOfPages={numberOfPages}
                                        currentPage={page}
                                        handlePagination={this.handlePagination}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Placement);
