import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    Grid,
    TextField,
    InputAdornment,
    MenuItem,
    FormControlLabel,
    Switch,
    Slider,
    Typography,
    Button,
    Divider
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import { Merchants } from './components';
import sectors from 'common/sectors';

const sliderMarks = [
    {
        value: 0,
        label: '0°'
    },
    {
        value: 45,
        label: '45°'
    },
    {
        value: 90,
        label: '90°'
    },
    {
        value: 135,
        label: '135°'
    },
    {
        value: 180,
        label: '180°'
    }
];

const styles = theme => ({
    root: {
        position: 'absolute',
        height: 'calc(100vh - 226px)'
    },
    content: {
        paddingTop: 6,
        paddingLeft: theme.spacing(2),
        overflow: 'auto',
        overflowX: 'hidden',
        height: 'calc(100vh - 304px)'
    },
    merchantType: {
        paddingLeft: theme.spacing(1)
    },
    placeType: {
        paddingLeft: theme.spacing(1)
    },
    rotationSlider: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2.5)
    },
    button: {
        // borderRadius: 0
    },
    footer: {
        //marginTop: 36,
        position: 'absolute',
        bottom: 0
    }
});

class Build extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            regexp: /^[0-9\b]+$/
        };
    }

    componentDidUpdate = (prevProps, prevState) => {};

    handleSelectedActivitie = ({ target: { value } }) => {
        /*const {
            fnc
        } = this.props.placeData*/

        this.props.updateField('selectedActivitie', value);
        this.handleSelectedMerchant(null);

        /*if (fnc == "edit") {
            this.handleSelectedMerchant(null);
        }*/
    };

    handleSelectedMerchant = value => {
        /*const {
            layer,
            fnc,
        } = this.props.placeData*/

        this.props.updateField('selectedMerchant', value);
        value && this.props.updateField('selectedActivitie', value.activite_id);

        //if (fnc == "edit") {
        //!value && this.props.updateField("isEditable", false);
        //!value && this.props.updateField("fnc", "create");

        //if (value) {
        //value.id != layer.getConfig('merchant').id && this.props.updateField("isEditable", false);
        //value.id != layer.getConfig('merchant').id && this.props.updateField("fnc", "create");
        //}
        //}
    };

    handleRotation = (event, value) => {
        this.props.updateField('rotation', value);
        this.props.fncs.rotate();
    };

    handleBilling = ({ target: { checked } }) => {
        this.props.updateField('isSubscriber', checked);
    };

    handleIsFree = ({ target: { checked } }) => {
        /*const {
            fnc
        } = this.props.placeData*/

        this.props.updateField('isFree', checked);
        /*
                if (fnc == "edit") {
                    this.handleSelectedMerchant(null);
                }*/
    };

    handleSector = ({ target: { value } }) => {
        this.props.updateField('sector', value);
    };

    handleMeters = ({ target: { value } }) => {
        (value === '' || this.state.regexp.test(value)) &&
            value.length < 3 &&
            this.props.updateField('meters', value);
    };

    handleNumero = ({ target: { value } }) => {
        (value === '' || this.state.regexp.test(value)) &&
            value.length < 4 &&
            this.props.updateField('numero', value);
    };

    handleOptions = ({ target: { name, checked } }) => {
        this.props.updateField(name, checked);
    };

    checkUp = fnc => {
        const {
            meters,
            numero,
            isSubscriber,
            isFree,
            selectedMerchant,
            selectedActivitie,
            layer,
            sector
        } = this.props.placeData;

        const { placedMerchants, places } = this.props;

        let isValid = true;

        if (fnc == 'remove') {
            const sector = layer.getConfig('options').sector;
            let _confirm = window.confirm(
                `La place ${sector === '' ? 'N°' : sector}${
                    layer.getConfig('numero').numero
                } va être supprimée et son titulaire si affecté.\nÊtes-vous sûr(e) de vouloir poursuivre cette action ?`
            );
            return _confirm;
        }

        if (numero == '') {
            alert('Veuillez saisir un numéro de place.');
            return false;
        }

        if (parseInt(numero) < 1) {
            alert('Veuillez saisir un numéro de place supérieur ou égale à 1.');
            return false;
        }

        if (meters == '') {
            alert('Veuillez saisir un nombre de métres.');
            return false;
        }

        if (parseInt(meters) < 2) {
            alert('Veuillez saisir un nombre de métres supérieur ou égale à 2 métres.');
            return false;
        }

        if (selectedActivitie == '') {
            alert('Veuillez sélectionner une activité.');
            return false;
        }

        if (!selectedMerchant && !isFree) {
            alert(
                'Veuillez sélectionner un commerçant ou bien cochez la case "Place non occupée".'
            );
            return false;
        }

        if (fnc == 'create') {
            places.some(function(place) {
                // if same sector and place number
                if (place.numero === numero && place.sector === sector) {
                    if (place.merchant.id != '1') {
                        alert(
                            `${place.merchant.raisonSociale} est déjà sur ce numéro de place (N°${place.numero}).`
                        );
                    } else {
                        alert(`Numéro de place déjà saisi (N°${numero}).`);
                    }

                    isValid = false;
                    return true;
                }
            });

            if (!isValid) {
                return false;
            }

            if (selectedMerchant && !isFree) {
                if (selectedMerchant.isPlaced == '1') {
                    alert(
                        `${selectedMerchant.raisonSociale} est déjà placé sur la place N°${selectedMerchant.place.numero}.`
                    );
                    return false;
                } else {
                    placedMerchants.some(function(merchant) {
                        if (merchant.id == selectedMerchant.id) {
                            alert(
                                `${merchant.raisonSociale} est déjà placé sur la place N°${merchant.place.numero}.`
                            );
                            isValid = false;
                            return true;
                        }
                    });
                }
            }
        } else {
            places.some(function(place) {
                if (
                    place.numero == numero &&
                    layer.getId() != place.leaflet_id &&
                    place.sector === sector
                ) {
                    if (place.merchant.id != '1') {
                        alert(
                            `${place.merchant.raisonSociale} est déjà sur ce numéro de place (N°${numero}).`
                        );
                    } else {
                        alert(`Numéro de place déjà saisi (N°${numero}).`);
                    }

                    isValid = false;
                    return true;
                }
            });

            if (!isValid) {
                return false;
            }

            if (
                selectedMerchant &&
                !isFree &&
                layer.getConfig('merchant').id != selectedMerchant.id
            ) {
                if (selectedMerchant.isPlaced == '1') {
                    alert(
                        `${selectedMerchant.raisonSociale} est déjà placé sur la place N°${selectedMerchant.place.numero}.`
                    );
                    return false;
                } else {
                    placedMerchants.some(function(merchant) {
                        if (merchant.id == selectedMerchant.id) {
                            alert(
                                `${merchant.raisonSociale} est déjà placé sur la place N°${merchant.place.numero}.`
                            );
                            isValid = false;
                            return true;
                        }
                    });
                }
            }
        }

        return isValid;
    };

    prepareData = fnc => {
        if (this.checkUp(fnc)) {
            if (fnc == 'create') {
                this.props.fncs.add();
            } else if (fnc == 'edit') {
                this.props.fncs.edit();
            } else {
                this.props.fncs.remove();
            }
        }
    };

    render() {
        const { classes } = this.state;

        const { activities } = this.props;

        const {
            numero,
            meters,
            selectedActivitie,
            selectedMerchant,
            rotation,
            isSubscriber,
            isEditable,
            isFree,
            water,
            electricity,
            car,
            sector
        } = this.props.placeData;

        return (
            <Grid container direction="column" justify="space-between" className={classes.root}>
                <Grid item className={classes.content}>
                    <Grid
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="stretch"
                        spacing={2}>
                        <Grid item>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Numéro"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        onChange={this.handleNumero}
                                        value={numero}
                                        //helperText="Saisir un numéro de place"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">N°</InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Mètre"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        onChange={this.handleMeters}
                                        value={meters}
                                        InputLabelProps={{ shrink: true }}
                                        //helperText="Saisir le nombre de mètre"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">m</InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Secteur"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        onChange={this.handleSector}
                                        value={sector}
                                        select>
                                        {sectors.map(option => (
                                            <MenuItem key={option} value={option}>
                                                {option === '' ? 'Pas de secteur' : option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label="Activité"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        onChange={this.handleSelectedActivitie}
                                        value={selectedActivitie}
                                        select>
                                        <MenuItem
                                            key="empty"
                                            value=""
                                            disabled
                                            style={{ display: 'none' }}>
                                            {' '}
                                        </MenuItem>
                                        {activities.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Merchants
                                        isFree={isFree}
                                        selectedActivitie={selectedActivitie}
                                        handleSelectedMerchant={this.handleSelectedMerchant}
                                        placedMerchants={this.props.placedMerchants}
                                        selectedMerchant={selectedMerchant}
                                    />
                                </Grid>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="column"
                                        className={classes.merchantType}>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        size="small"
                                                        disabled={isFree}
                                                        checked={isSubscriber}
                                                        onChange={this.handleBilling}
                                                    />
                                                }
                                                label="Abonné"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        size="small"
                                                        checked={isFree}
                                                        onChange={this.handleIsFree}
                                                    />
                                                }
                                                label="Place non occupée"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" className={classes.placeType}>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                size="small"
                                                name="water"
                                                checked={water}
                                                onChange={this.handleOptions}
                                            />
                                        }
                                        label={
                                            <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                                spacing={1}>
                                                <Grid item>
                                                    <Typography>Eau</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <InvertColorsIcon
                                                        style={{ color: 'dodgerblue' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                size="small"
                                                name="electricity"
                                                checked={electricity}
                                                onChange={this.handleOptions}
                                            />
                                        }
                                        label={
                                            <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                                spacing={1}>
                                                <Grid item>
                                                    <Typography>Electricité</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <FlashOnOutlinedIcon
                                                        style={{ color: 'gold' }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                size="small"
                                                name="car"
                                                checked={car}
                                                onChange={this.handleOptions}
                                            />
                                        }
                                        label={
                                            <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                                spacing={1}>
                                                <Grid item>
                                                    <Typography>Véhicule</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <LocalShippingIcon />
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Grid container direction="column">
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={1}
                                        alignItems="flex-start">
                                        <Grid item>
                                            <Typography>Rotation</Typography>
                                        </Grid>
                                        <Grid item>
                                            <ScreenRotationIcon fontSize="small" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.rotationSlider}>
                                    <Slider
                                        defaultValue={0}
                                        value={rotation}
                                        valueLabelFormat={value => {
                                            return `${value}°`;
                                        }}
                                        //onMouseUp={this.props.fncs.rebuild}
                                        disabled={!isEditable}
                                        onChange={this.handleRotation}
                                        step={1}
                                        marks={sliderMarks}
                                        min={0}
                                        max={180}
                                        valueLabelDisplay="auto"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.footer} style={{ paddingLeft: 16, paddingBottom: 7 }}>
                    {/* <Grid container> */}
                    <Grid
                        container
                        item
                        justifyContent="space-between"
                        style={{ justifyContent: 'stretch' }}
                        spacing={1}>
                        <Grid item>
                            {isEditable ? (
                                <Button
                                    endIcon={<EditIcon />}
                                    className={classes.button}
                                    fullWidth
                                    style={{ width: 180 }}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => this.prepareData('edit')}>
                                    Valider la modification
                                </Button>
                            ) : (
                                <Button
                                    endIcon={<AddIcon />}
                                    className={classes.button}
                                    //size="small"
                                    fullWidth
                                    //disabled={isEditable}
                                    color="primary"
                                    variant="contained"
                                    style={{ width: 180 }}
                                    onClick={() => this.prepareData('create')}>
                                    Créer une nouvelle place
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.button}
                                fullWidth
                                disabled={!isEditable}
                                color="primary"
                                style={{
                                    height: '100%',
                                    background: !isEditable ? 'rgba(0, 0, 0, 0.12)' : 'red'
                                }}
                                variant="contained"
                                onClick={() => this.prepareData('remove')}>
                                <DeleteIcon />
                            </Button>
                        </Grid>
                    </Grid>

                    {/* <Grid item xs={12} style={{ marginTop: 10 }}>
                            <Button
                                endIcon={<AddIcon />}
                                className={classes.button}
                                //size="small"
                                fullWidth
                                //disabled={isEditable}
                                color="primary"
                                variant="contained"
                                onClick={() => this.prepareData('create')}>
                                Créer{' '}
                                {isEditable ? 'à partir de cette place' : 'une nouvelle place'}
                            </Button>
                        </Grid> 
                      </Grid> */}
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Build);
