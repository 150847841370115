import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    TextField,
    InputAdornment,
    Button,
    Grid
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles(theme => ({
    input: {
        display: "none"
    },
}));

const FileInput = props => {
    const { className, file, setFile, ...rest } = props;

    const classes = useStyles();
    const ref = React.createRef();
    const [src, setSrc] = React.useState("https://" + file);

    const addFile = () => {
        ref.current.click();
    }

    const removeFile = () => {
        setFile(null)
        setSrc(null)
    }

    const handleFile = ({ target: { files } }) => {

        const extension = getExtension(files[0].name);

        if (extension &&
            (extension == "png" || extension == "jpg" || extension == "jpeg")) {
            setFile(files[0])
            setSrc(URL.createObjectURL(files[0]))
        } else {
            alert("Format d'image non valide.\nVeuillez sélectionner une image du type .JPEG .JPG .PNG")
        }
    }

    const getExtension = (filename) => {
        return (/[.]/.exec(filename)) ? (/[^.]+$/.exec(filename))[0].toLowerCase() : undefined
    }

    return (
        <Grid container spacing={1} direction="column">
            <Grid item>
                <TextField
                    type="text"
                    fullWidth
                    value={(file ? file.name : "")}
                    variant="outlined"
                    InputProps={{
                        readOnly: true,
                        startAdornment:
                            <InputAdornment position="start">
                                <Button size="small" onClick={addFile}>
                                    Ajouter un logo <AttachFileIcon />
                                </Button>
                            </InputAdornment>,
                        endAdornment:
                            <InputAdornment position="end">
                                <Button size="small" onClick={removeFile}>
                                    Supprimer <DeleteIcon />
                                </Button>
                            </InputAdornment>,
                    }}
                />
                <input type="file" accept="image/png, image/jpeg, image/jpg" onChange={handleFile} className={classes.input} ref={ref} />
            </Grid>
            {
                file &&
                <Grid item>
                    <div style={{ textAlign: "center", border: "1px solid #c4c4c4", borderRadius: 4, padding: 8 }}>
                        <img src={src} style={{ width: 100 }} />
                    </div>
                </Grid>
            }
        </Grid>
    );
};

export default FileInput;
