import env from 'common/env';

/**
 *
 * @param {*} $merchantId
 * @returns
 */
export async function sendGroupedBills(merchantId) {
    const result = await fetch(`${env}/regie/send-grouped-bills/${merchantId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => {
            console.log(data);
            alert('Factures envoyées avec succès');
        })
        .catch(err => {
            console.error(err);
            throw `Echec de l'envoi des factures.`;
        });

    return result;
}

/**
 *
 * @param {*} merchantId
 * @param {*} start
 * @param {*} end
 * @returns
 */
export async function sendGroupedBillsByDate(merchantId, start, end) {
    const result = await fetch(
        `${env}/regie/send-grouped-bills/${merchantId}?start=${start}&end=${end}`,
        {
            method: 'GET'
        }
    )
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => {
            console.log(data);
            alert('Factures envoyées avec succès');
        })
        .catch(err => {
            console.error(err);
            throw `Echec de l'envoi des factures.`;
        });

    return result;
}

/**
 *
 * @param {*} $billNumber
 * @returns
 */
export async function sendGroupedBillsByBillNumber(billNumber) {
    const result = await fetch(`${env}/regie/send-grouped-bills-by-bill-number/${billNumber}`, {
        method: 'GET'
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => {
            console.log(data);
            alert('Factures envoyées avec succès');
        })
        .catch(err => {
            console.error(err);
            throw `Echec de l'envoi des factures.`;
        });

    return result;
}

/**
 *
 * @param {*} page
 * @param {*} order
 * @param {*} orderBy
 * @param {*} searchValue
 * @param {*} filter
 * @param {*} state
 * @param {*} start
 * @param {*} end
 * @returns
 */
export async function getBills(
    page,
    order,
    orderBy,
    searchValue = '',
    filter = 'all',
    state = 'invoiced',
    start = '',
    end = ''
) {
    const formData = new FormData();
    formData.append('page', page);
    formData.append('order', order);
    formData.append('orderBy', orderBy);
    formData.append('search', searchValue);
    formData.append('state', state);
    formData.append('start', start);
    formData.append('end', end);

    const result = await fetch(`${env}/regie/get-grouped-bills/${filter}`, {
        method: 'POST',
        body: formData
    })
        .then(response => {
            return response.ok ? response.json() : false;
        })
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw `Echec de l'affichage des factures.`;
        });

    return result;
}
