import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import env from 'common/env';
import {
    Grid,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    InputAdornment,
    InputBase,
    Button,
    TextField
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';

import { List, MarketMap } from './components';
import FilterForm from './components/FilterForm';
import { Dialog as DigitDialog } from 'components';

const styles = theme => ({
    appBar: {
        position: 'relative'
    },
    subContent: {
        padding: theme.spacing(2)
    },
    filter: {
        padding: theme.spacing(2)
    },
    input: {
        padding: theme.spacing(2),
        flex: 1
    },
    title: {
        color: 'white',
        marginLeft: theme.spacing(2),
        flex: 1
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class DigitMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            list: [],
            page: 1,
            numberOfPages: 0,
            searchValue: '',
            openFilter: false,
            placesState: [],
            activities: [],
            merchantType: [],
            sectors: [],
            lastPlace: null,
            loading: false,
            currentPlace: {},

            // for placement only
            openPlacement: false,
            forcePlacement: false,
            loadingPlacement: false,
            metersPlacement: 0,
            selectedPlace: null,
            resultPlacement: ''
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        (prevProps.marchand.marchand_id != this.props.marchand.marchand_id ||
            this.state.page != prevState.page ||
            this.state.searchValue != prevState.searchValue) &&
            this.props.marchand.marchand_id &&
            this.getData();

        if (
            prevState.placesState !== this.state.placesState ||
            prevState.activities !== this.state.activities ||
            prevState.merchantType !== this.state.merchantType ||
            prevState.sectors !== this.state.sectors
        ) {
            this.getData();
        }
    };

    componentDidMount = () => {
        //this.getData();
    };

    handlePagination = page => {
        this.setState(prevState => ({
            page: prevState.page + page,
            isGenerating: true
        }));
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            isGenerating: true,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            isGenerating: true,
            page: 1
        });
    };

    getData = () => {
        const { searchValue, page, placesState, merchantType, activities, sectors } = this.state;

        const formData = new FormData();

        //formData.append("page", page);
        formData.append('marchand_id', this.props.marchand.marchand_id);
        formData.append('search', searchValue);
        // formData.append('page', page);
        const filter = {
            placesState,
            merchantType,
            activities,
            sectors
        };
        formData.append('filter', JSON.stringify(filter));

        fetch(`${env}/placement/get-places-journalmarket/${this.props.id}`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    isGenerating: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    //alert("Echec de la récupération des données")
                } // Handle the error response object
            );
    };

    doPlacementv2 = (place, force = false) => {
        this.setState({
            loadingPlacement: true
        });

        const formData = new FormData();

        formData.append('marchand_id', this.props.marchand.marchand_id);
        formData.append('meters', this.state.metersPlacement);
        formData.append('journalplace_id', place.id);
        formData.append('force', force);
        fetch(`${env}/placement/do-placement-journalmarket/${this.props.id}`, {
            method: 'POST',
            body: formData
        })
            .then(res => (res.ok ? res.json() : false))
            .then(data => {
                if (data.splited) {
                    this.setState({
                        resultPlacement: `Nouvelle place ${data.place.sector}${data.splited.numero} (${data.splited.metres} mètres restant) créée.`
                    });
                }

                this.setState({ lastPlace: data.place, loading: false });
                this.closePlacement();
                this.close();
            })
            .catch(error =>
                this.setState({
                    resultPlacement: `Echec lors du placement, veuillez réessayer.`
                })
            );
    };

    closePlacement = () => {
        this.setState({
            openPlacement: false,
            selectedPlace: null,
            forcePlacement: false,
            loadingPlacement: false,
            metersPlacement: 0
        });
    };

    doPlacement = (place, force = false) => {
        this.setState({
            openPlacement: true,
            selectedPlace: place,
            forcePlacement: force,
            metersPlacement: parseInt(place.metres)
        });
    };

    close = () => {
        this.setState({
            list: [],
            searchValue: '',
            numberOfPages: 0,
            page: 1
        });

        this.props.close(false);
    };

    handleFilters = (k, v) => {
        const arr = [...this.state[k]];
        const index = arr.indexOf(v);
        index === -1 ? arr.push(v) : arr.splice(index, 1);

        this.setState({
            [k]: arr
        });
    };

    render() {
        const {
            classes,
            list,
            numberOfPages,
            page,
            searchValue,
            isGenerating,
            openFilter,
            activities,
            placesState,
            merchantType,
            sectors,
            lastPlace,
            currentPlace,
            loading,

            // For placement only
            loadingPlacement,
            openPlacement,
            selectedPlace,
            metersPlacement,
            forcePlacement,
            resultPlacement
        } = this.state;

        const { sectors: sectorsAvailable, marchand } = this.props;

        return (
            <>
                <DigitDialog
                    title="Nouvelle place"
                    open={resultPlacement !== ''}
                    onClose={() => this.setState({ resultPlacement: '' })}>
                    <Typography variant="body1">{resultPlacement}</Typography>
                </DigitDialog>
                <DigitDialog title="Placement" open={loading} loading={loading}>
                    <Typography variant="body1">
                        Placement du commerçant sur la place {currentPlace.numero} en cours...
                    </Typography>
                </DigitDialog>
                <DigitDialog
                    onClose={() => this.closePlacement()}
                    action={
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.doPlacementv2(selectedPlace, forcePlacement)}
                            loading={loadingPlacement}
                            disabled={
                                loadingPlacement ||
                                metersPlacement === '' ||
                                !Number.isInteger(metersPlacement) ||
                                metersPlacement > 50 ||
                                metersPlacement < 1
                            }>
                            Confirmer
                        </Button>
                    }
                    title="Confirmation de placement"
                    open={openPlacement}
                    loading={loadingPlacement}>
                    <Typography variant="body1">
                        <strong>{marchand?.raisonSociale}</strong> va être placé sur la place{' '}
                        <strong>{selectedPlace?.numero}</strong>.
                    </Typography>
                    <br />
                    <TextField
                        label={'Confirmer le nombre de mètres ?'}
                        helperText="Valeur comprise entre 1 et 50 mètres."
                        variant="outlined"
                        size="small"
                        type="number"
                        fullWidth
                        name="confirm"
                        value={metersPlacement}
                        onChange={e => this.setState({ metersPlacement: parseInt(e.target.value) })}
                    />
                </DigitDialog>
                <FilterForm
                    open={openFilter}
                    activities={{
                        set: v => this.handleFilters('activities', v),
                        get: activities
                    }}
                    close={() => this.setState({ openFilter: !openFilter })}
                    placesState={{
                        set: v => this.handleFilters('placesState', v),
                        get: placesState
                    }}
                    merchantType={{
                        set: v => this.handleFilters('merchantType', v),
                        get: merchantType
                    }}
                    sectors={{
                        set: v => this.handleFilters('sectors', v),
                        get: sectors,
                        availables: sectorsAvailable
                    }}
                />
                <Dialog
                    fullScreen
                    open={this.props.open}
                    onClose={this.close}
                    TransitionComponent={Transition}>
                    <AppBar color="secondary" className={classes.appBar}>
                        <Toolbar variant="dense">
                            <IconButton edge="start" color="inherit" onClick={this.close}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                Plan du marché
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid container direction="row" alignItems="stretch">
                        <Grid item xs={12} sm={7}>
                            <Grid item>
                                <InputBase
                                    className={classes.input}
                                    placeholder="Rechercher une place ou un commerçant"
                                    fullWidth
                                    onChange={this.handleSearch}
                                    value={searchValue}
                                    color="primary"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <Button
                                                onClick={() =>
                                                    this.setState({ openFilter: !openFilter })
                                                }
                                                startIcon={<FilterListIcon />}>
                                                Filtrer
                                            </Button>
                                            <IconButton>
                                                {isGenerating ? (
                                                    <CircularProgress size={'1.5rem'} />
                                                ) : (
                                                    <SearchIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={this.clearSearch}>
                                                <ClearIcon style={{ color: 'red' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <MarketMap
                                    args={{
                                        searchValue: searchValue,
                                        page: page,
                                        marchandId: this.props.marchand.marchand_id
                                    }}
                                    doPlacement={this.doPlacement}
                                    id={this.props.id}
                                    filter={{
                                        activities,
                                        placesState,
                                        merchantType,
                                        sectors
                                    }}
                                    lastPlace={lastPlace}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <List
                                list={list}
                                numberOfPages={numberOfPages}
                                page={page}
                                handlePagination={this.handlePagination}
                                doPlacement={this.doPlacement}
                            />
                        </Grid>
                    </Grid>
                </Dialog>
            </>
        );
    }
}

export default withStyles(styles)(DigitMap);
