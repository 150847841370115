import React, { useEffect, useState } from 'react';
import { Grid, Button, Typography, CircularProgress } from '@material-ui/core';
import { getFilters } from './action';

const FilterButton = props => {
    const {
        id,
        filters: { update, get }
    } = props;

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [rows, setRows] = useState([]);
    const selected = selectedId => (get.find(row => row === selectedId) ? 'contained' : 'outlined');

    useEffect(() => {
        getFilters(id)
            .then(res => {
                setRows(res);
                update(res, true, 'id');
                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
            });
    }, []);

    return (
        <div>
            <Grid
                container
                direction="row"
                spacing={2}
                alignItems="center"
                justify={loading || error ? 'center' : 'flex-start'}
                justifyContent={loading || error ? 'center' : 'flex-start'}>
                {loading ? (
                    <Grid item>
                        <CircularProgress size={26} />
                    </Grid>
                ) : error ? (
                    <Typography>{error}</Typography>
                ) : (
                    rows.map(row => (
                        <Grid item key={row.id}>
                            <Button
                                key={row.id}
                                variant={selected(row.id)}
                                color="primary"
                                onClick={() => update(row.id)}>
                                {row.label} ({row.record})
                            </Button>
                        </Grid>
                    ))
                )}
            </Grid>
        </div>
    );
};

export default FilterButton;
