import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import NumberFormat from 'react-number-format';

import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    Button,
    Divider,
    InputAdornment,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    ListItemText
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

import { FileInput } from './components';
import { getUnits } from 'views/Unit/services/http';

const useStyles = makeStyles(theme => ({
    head: {
        background: 'rgb(192,226,251,0.35)',
        borderBottom: '1px solid #c0e2fb'
    },
    cellKabis: {
        borderLeft: '7px solid #c0e2fb'
    }
}));

function CustomTextFieldPrice(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <TextField
            {...other}
            onChange={onChange}
            variant="outlined"
            fullWidth
            label={props.name == 'tarif' ? 'Prix unitaire' : 'Montant total'}
            helperText={props.name == 'tarif' && 'Saisir un prix unitaire'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <EuroSymbolIcon />
                    </InputAdornment>
                )
            }}
        />
    );
}

const Form = props => {
    const { className, sendData, isLoading, ...rest } = props;

    const classes = useStyles();

    const [units, setUnits] = useState([]);
    const [state, setState] = React.useState({
        label: '',
        type: '',
        unit_id: '',
        commentaire: ''
    });

    useEffect(() => {
        getUnits({ page: '*' }).then(res => setUnits(res.units));
    }, []);

    const handleData = ({ target: { name, value } }) => {
        setState({ ...state, [name]: value });
    };

    const prepareData = () => {
        sendData(state);
    };

    return (
        <Grid container direction="column" spacing={5}>
            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography color="primary" variant="h5">
                                    Caractéristiques
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider style={{ width: '80%', color: '#ddd' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3} direction="row">
                            <Grid item sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    label="Libellé"
                                    helperText="Saisir une libellé"
                                    name="label"
                                    type="text"
                                    onChange={handleData}
                                    value={state.label}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="required">Typologie</FormLabel>
                                    <RadioGroup
                                        row
                                        name="type"
                                        value={state.type}
                                        onChange={handleData}>
                                        <FormControlLabel
                                            value="p"
                                            control={<Radio />}
                                            label="Produit"
                                        />
                                        <FormControlLabel
                                            value="s"
                                            control={<Radio />}
                                            label="Prestation de service"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    select
                                    label="Prix unitaire"
                                    helperText="Sélectionner un prix unitaire"
                                    fullWidth
                                    name="unit_id"
                                    variant="outlined"
                                    value={state.unit_id}
                                    onChange={handleData}>
                                    {units.map(unit => (
                                        <MenuItem key={unit.id} value={unit.id}>
                                            <ListItemText
                                                primary={unit.libelle}
                                                secondary={
                                                    unit.amount
                                                        ? `Tarif à ${unit.amount} €`
                                                        : 'Tarid non configuré'
                                                }
                                            />
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Commentaire (facultatif)"
                                    helperText="Saisir un commentaire"
                                    name="commentaire"
                                    type="text"
                                    rows={5}
                                    multiline
                                    onChange={handleData}
                                    value={state.commentaire}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ textAlign: 'center' }}>
                <Grid container spacing={1} direction="row" justify="center" alignItems="center">
                    <Grid item>
                        <Button
                            disabled={isLoading}
                            onClick={prepareData}
                            variant="contained"
                            color="primary">
                            Créer ce produit
                        </Button>
                    </Grid>
                    {isLoading && (
                        <Grid item>
                            <CircularProgress color="primary" size={25} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Form;
