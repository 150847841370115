import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Avatar,
    Button,
    CardActions
} from '@material-ui/core';

import ExposureIcon from '@material-ui/icons/Exposure';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    card: {
        height: '100%'
    },
    title: {
        fontWeight: 700
    },
    icon: {
        height: 32,
        width: 32
    },
    avatar: {
        float: 'right',
        backgroundColor: theme.palette.primary.main,
        height: 56,
        width: 56
    },
    cardFooter: {
        padding: '0 15px 15px 15px',
        position: 'absolute',
        bottom: 0,
        width: '100%'
    },
    content: {
        height: 'calc(100% - 53px)'
    }
}));

const AttendancePoint = props => {
    const { className, data, ...rest } = props;

    const classes = useStyles();

    return (
        <CardActionArea
            className={classes.root}
            component={RouterLink}
            to={'/settings/attendance-point'}>
            <Card {...rest} className={classes.card}>
                <CardContent className={classes.content}>
                    <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        justify="space-between"
                        spacing={2}>
                        <Grid item xs={10}>
                            <Grid
                                container
                                direction="column"
                                justify="flex-start"
                                alignItems="stretch"
                                spacing={1}>
                                <Grid item>
                                    <Typography
                                        className={classes.title}
                                        color="textSecondary"
                                        variant="body1">
                                        Gestion des points d'assiduité
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h3" color="primary">
                                        {data.records}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Avatar className={classes.avatar}>
                                <ExposureIcon className={classes.icon} />
                            </Avatar>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button color="primary" fullWidth>
                        Gérer les points d'assiduité
                    </Button>
                </CardActions>
            </Card>
        </CardActionArea>
    );
};

export default AttendancePoint;
