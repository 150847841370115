import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { isMobile } from 'react-device-detect';
import {
    Grid,
    Paper,
    TextField,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Button,
    ListItemText,
    IconButton,
    ButtonGroup,
    Dialog,
    DialogActions,
    MenuItem,
    Typography,
    DialogContent,
    DialogTitle,
    InputAdornment,
    FormControlLabel,
    RadioGroup,
    Radio,
    Checkbox,
    FormControl,
    FormLabel,
    FormGroup,
    Divider,
    Switch,
    CircularProgress
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import DescriptionIcon from '@material-ui/icons/Description';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ClearIcon from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/Warning';
import PrintIcon from '@material-ui/icons/Print';

import { InfoCard, Encaissement, Detail, Alert, Pagination } from './components';

const styles = theme => ({
    root: {
        paddingTop: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    cell: {
        padding: '10px 10px 10px 24px'
    },
    table: {
        maxHeight: 300,
        borderRight: '1px solid #eee',
        borderLeft: '1px solid #eee'
    },
    avatar: {
        backgroundColor: 'green',
        height: 48,
        width: 48
    },
    title: {
        //flex: '1 1 100%',
    },
    content: {
        padding: theme.spacing(3),
        flexGrow: 1
    },
    subtitle: {
        padding: '32px 0 0 16px'
    },
    info: {
        textAlign: 'center'
    },
    rotate: {
        transform: 'rotate(180deg)'
    }
});

const currencies = [
    {
        value: 'esp',
        label: 'Numéraire',
        id: 1
    },
    {
        value: 'Chèque',
        label: 'Chèque',
        id: 2
    },
    {
        value: 'CB',
        label: 'Carte bancaire',
        id: 3
    },
    {
        value: 'Prélèvement',
        label: 'Prélèvement',
        id: 4
    },
    {
        value: 'Téléphone',
        label: 'Téléphone',
        id: 5
    }
];

const columns = [
    {
        id: 'numero',
        label: 'N°',
        minWidth: '10%',
        align: 'left'
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        minWidth: '40%',
        align: 'left'
    },
    {
        id: 'facture',
        label: 'Facture',
        minWidth: '20%',
        align: 'center'
    },
    {
        id: 'montant',
        label: 'Montant',
        minWidth: '20%',
        align: 'left'
    }
];

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

class Finance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            activeFilter: props.filter == 't' ? 'titulaires' : 'passagers',
            otherFilter: props.filter == 't' ? 'ta' : 'p',
            cashFilter: false,
            cash: false,
            myFacture: [],
            id: 0,
            meters: 0,
            cheque: '',
            banque: '',
            paiementMethod: '',
            openCash: false,
            anchorAlert: null,
            assuranceExpiree: '0',
            assuranceManquante: '0',
            bapRetard: '0',
            bloque: '0',
            siret: '',

            nbrAll: 0,

            nbrTotalNotCashed: 0,
            nbrTotalCashed: 0,

            totalCashed: { raw: 0, total: 0 },
            totalNotCashed: { raw: 0, total: 0 },
            totalEspece: { raw: 0, total: 0 },
            totalCheque: { raw: 0, total: 0 },
            totalCb: { raw: 0, total: 0 },
            totalPrelevement: { raw: 0, total: 0 },
            totalTelephone: { raw: 0, total: 0 },

            list: [],
            listOfPlaces: [],
            searchValue: '',
            formattedPrice: '',
            page: 1,
            numberOfPages: 0,
            all: true,
            ta: false,
            tna: false,
            p: false,
            etat: 'all',
            startNumero: null,
            endNumero: [],
            regexp: /^[0-9\b]+$/,
            isPaying: false
        };
    }

    componentDidUpdate = (nextProps, nextState) => {
        if (this.props.filter != nextProps.filter) {
            this.setState({
                //otherFilter: this.props.filter == "t" ? "ta" : "p",
                //activeFilter: this.props.filter == "t" ? "titulaires" : "passagers",
            });
            //this.update(this.props.filter == "t" ? "ta" : "p", this.state.cashFilter);
        }

        if (
            this.state.ta != nextState.ta ||
            this.state.tna != nextState.tna ||
            this.state.p != nextState.p ||
            this.state.etat != nextState.etat ||
            this.state.searchValue != nextState.searchValue ||
            this.state.page != nextState.page ||
            this.state.startNumero != nextState.startNumero
        ) {
            this.getData();
        }

        if (this.state.isPaying && this.state.isPaying != nextState.isPaying) {
            this.send();
        }
    };

    getData = () => {
        const { all, ta, tna, p, etat, page, searchValue, startNumero } = this.state;

        const formData = new FormData();

        formData.append('page', page);
        formData.append('search', searchValue);
        formData.append('etat', etat);
        startNumero && formData.append('startNumero', startNumero.numero);

        if (!ta && !tna && !p && !all) {
            this.setState({
                all: true
            });
            formData.append('tna', true);
            formData.append('ta', true);
            formData.append('p', true);
        } else {
            formData.append('tna', all ? true : tna);
            formData.append('ta', all ? true : ta);
            formData.append('p', all ? true : p);
        }

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/finance/get-bills-journalmarket/${this.props.id}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    //alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    componentDidMount = () => {
        this.getData();
    };

    getPdf = numFacture => {
        window.open(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/print/A/${numFacture}`,
            '_blank'
        );
    };

    formatPrice = value => {
        let values = parseFloat(value)
            .toString()
            .split('.');

        return values[0] + ',' + (values[1] ? (values[1] + '00')[0] + (values[1] + '00')[1] : '00');
    };

    handleMeters = i => {
        const { myFacture } = this.state;

        let metres = parseInt(myFacture.metres) + i < 3 ? 2 : parseInt(myFacture.metres) + i;

        /*let montants = (parseFloat(myFacture.prixAuMetre) * (((parseInt(myFacture.metres) + i) < 3 ? 2 : (parseInt(myFacture.metres) + i)))).toString().split(".");
        let montantTotal = montants[0] + "," + (montants[1] ? (montants[1] + "00")[0] + (montants[1] + "00")[1] : "00")*/

        let montantTotal = myFacture.prixAuMetre * metres;

        if (myFacture.electricity == '1') {
            montantTotal = parseFloat(myFacture.electricityPrice) + montantTotal;
        }

        if (myFacture.water == '1') {
            montantTotal = parseFloat(myFacture.waterPrice) + montantTotal;
        }

        this.setState(prevState => ({
            myFacture: {
                ...myFacture,
                metres: metres,
                montant: montantTotal
            }
        }));
    };

    handleCash = (value, id, stateOfBill) => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/finance/${value}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data

                this.setState({
                    myFacture: data,
                    id: id,
                    cash: value,
                    paiementMethod: '',
                    banque: '',
                    cheque: '',
                    stateOfBill: stateOfBill
                });
            })
            .catch(console.log);
    };

    handleCloseCash = value => {
        this.setState({
            cash: value
        });
    };

    handlePaiementMethod = event => {
        this.setState({
            paiementMethod: event.target.value,
            banque: '',
            cheque: ''
        });
    };

    handleCheque = (key, { target: { value } }) => {
        this.setState({
            [key]: value
        });
    };

    send = () => {
        const {
            myFacture,
            id,
            stateOfBill,
            paiementMethod,
            cheque,
            banque,
            otherFilter,
            cashFilter,
            searchValue,
            page
        } = this.state;

        let moyenId = 0;

        currencies.map(c => {
            if (c.value == paiementMethod) {
                moyenId = c.id;
            }
        });

        if (paiementMethod == '') {
            alert('Veuillez renseigner un moyen de paiement');
            this.setState({
                isPaying: false
            });
            return;
        }

        if (paiementMethod == 'Chèque') {
            if (cheque == '') {
                alert('Veuillez indiquer un numéro de chèque valide');
                this.setState({
                    isPaying: false
                });
                return;
            }

            if (cheque.length != 7) {
                alert("Le numéro de chèque n'est pas valide (7 caractères obligatoires)");
                this.setState({
                    isPaying: false
                });
                return;
            }

            if (banque == '') {
                alert('Veuillez indiquer une banque');
                this.setState({
                    isPaying: false
                });
                return;
            }
        }

        const formData = new FormData();
        formData.append('cheque', cheque);
        formData.append('banque', banque);
        formData.append('metres', myFacture.metres);
        formData.append('moyen_paiement_id', moyenId);
        formData.append('journalMarketId', this.props.id);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/finance/${id}/update/${stateOfBill}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(() => {
                this.getData();
                this.setState({
                    isPaying: false
                });
                alert('Facture réglée avec succès.');
                this.handleCloseCash(false);
            })
            .catch(err => {
                console.error(err);
                alert('Une erreur est survenue lors du règlement de la facture.');
                this.setState({
                    isPaying: false
                });
            });
    };

    handleTypeFilter = ({ target: { name, checked } }) => {
        const { all } = this.state;

        if (name === 'all') {
            !all &&
                this.setState({
                    ta: false,
                    tna: false,
                    p: false
                });
        } else {
            this.setState({
                all: false
            });
        }

        this.setState({
            [name]: checked,
            page: 1
        });
    };

    handleStateCashFilter = ({ target: { value } }) => {
        this.setState({
            etat: value,
            page: 1
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1
        });
    };

    clearSearch = () => {
        this.setState({
            searchValue: '',
            page: 1
        });
    };

    handleAlert = (
        event = null,
        assuranceExpiree = '0',
        assuranceManquante = '0',
        bapRetard = '0',
        bloque = '0',
        siret
    ) => {
        this.setState({
            anchorAlert: event ? event.currentTarget : null,
            assuranceExpiree: assuranceExpiree,
            assuranceManquante: assuranceManquante,
            bapRetard: bapRetard,
            bloque: bloque,
            siret: siret
        });
    };

    alertMerchant = merchant => {
        let alert = 0;
        merchant.assuranceExpiree != '0' && ++alert;
        merchant.assuranceManquante == '1' && ++alert;
        merchant.bapRetard != '0' && ++alert;
        merchant.bloque != '0' && ++alert;

        return (
            <Grid spacing={1} container direction="row" justify="flex-start" alignItems="center">
                {alert > 0 && (
                    <Grid item>
                        <Button
                            onClick={event =>
                                this.handleAlert(
                                    event,
                                    merchant.assuranceExpiree,
                                    merchant.assuranceManquante,
                                    merchant.bapRetard,
                                    merchant.bloque,
                                    merchant.siret
                                )
                            }
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{
                                background: '#eb5a46',
                                minWidth: 'fit-content',
                                padding: '4px 6px'
                            }}>
                            <WarningIcon fontSize="small" />{' '}
                            <Typography style={{ color: 'white', marginLeft: 5, fontWeight: 500 }}>
                                {alert}
                            </Typography>
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <ListItemText
                        primary={merchant.raisonSociale}
                        secondary={
                            <Typography variant="body2">
                                {merchant.status == 'p'
                                    ? 'Passager'
                                    : merchant.status == 'ta'
                                    ? 'Titulaire abonné'
                                    : 'Titulaire non abonné'}
                            </Typography>
                        }
                    />
                </Grid>
            </Grid>
        );
    };

    handleNumero = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    printBill = numFacture => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/get-bills-for-thermal-printer/${numFacture}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.text() : false;
            })
            .then(data => {
                window.frames['frame-for-print'].document.body.innerHTML = data;
                window.frames['frame-for-print'].window.focus();
                window.frames['frame-for-print'].window.print();
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    updatePlaceBeforeCashed = (id, param) => {
        const formData = new FormData();
        formData.append('param', param);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/finance/update-param-before-cashed/${id}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => (response.ok ? response.json() : false))
            .then(data => this.setState({ myFacture: data }))
            .catch(error => {
                console.error(error);
                alert('Echec de la mis à jour de la facture');
            });
    };

    render() {
        const {
            classes,
            list,
            listOfPlaces,
            cash,
            myFacture,
            paiementMethod,
            nbrAll,
            nbrTotalCashed,
            totalCashed,
            totalNotCashed,
            totalEspece,
            totalCheque,
            totalCb,
            totalPrelevement,
            totalTelephone,
            searchValue,
            page,
            numberOfPages,
            anchorAlert,
            assuranceExpiree,
            assuranceManquante,
            bloque,
            bapRetard,
            siret,
            ta,
            all,
            tna,
            p,
            etat,
            startNumero,
            isPaying
        } = this.state;

        return (
            <div className={classes.root}>
                <iframe
                    name="frame-for-print"
                    width="0"
                    height="0"
                    frameborder="0"
                    src="about:blank"></iframe>

                <Alert
                    anchorAlert={anchorAlert}
                    data={{
                        assuranceExpiree: assuranceExpiree,
                        assuranceManquante: assuranceManquante,
                        bloque: bloque,
                        bapRetard: bapRetard
                    }}
                    siret={siret}
                    handleAlert={this.handleAlert}
                />

                <Dialog
                    open={cash}
                    onClose={() => this.handleCloseCash(false)}
                    fullWidth={true}
                    maxWidth="sm">
                    <DialogTitle>Récapitulatif avant encaissement</DialogTitle>
                    <DialogContent dividers style={{ padding: 0 }}>
                        <Table>
                            <TableBody>
                                <TableRow key={0} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                                    <TableCell padding="none" className={classes.cell} align="left">
                                        <ListItemText
                                            primary="Raison sociale"
                                            secondary="Enseigne"
                                        />
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <ListItemText
                                            primary={
                                                <Typography variant="body1" color="primary">
                                                    {myFacture.raisonSociale}
                                                </Typography>
                                            }
                                            secondary={myFacture.alias}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow key={11}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Métier
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Typography variant="body1" color="primary">
                                            {myFacture.metier}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={1} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Marché
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Typography variant="body1" color="primary">
                                            {myFacture.marche}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={3}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Date
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Typography variant="body1" color="primary">
                                            {myFacture.dateJour}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={5} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Numéro de la place
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Typography variant="body1" color="primary">
                                            {myFacture.numero}
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={85}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Nombre de mètres
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Grid
                                            container
                                            direction="row"
                                            justify="space-between"
                                            alignItems="center">
                                            <Grid item>
                                                <Typography variant="body1" color="primary">
                                                    {myFacture.metres} mètres
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center">
                                                    <Grid item>
                                                        <IconButton
                                                            onClick={() => this.handleMeters(-1)}>
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton
                                                            onClick={() => this.handleMeters(1)}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={56} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Prix du mètre linéaire
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Typography variant="body1" color="primary">
                                            {myFacture.formattedPrice} €
                                        </Typography>
                                    </TableCell>
                                </TableRow>

                                {myFacture.waterPrice && (
                                    <TableRow key={99}>
                                        <TableCell
                                            className={classes.cell}
                                            padding="none"
                                            align="left">
                                            Eau
                                        </TableCell>
                                        <TableCell
                                            className={classes.cell}
                                            padding="none"
                                            align="left">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={myFacture.water == '1'}
                                                        name="active"
                                                        color="primary"
                                                        onChange={() =>
                                                            this.updatePlaceBeforeCashed(
                                                                myFacture.id,
                                                                'water'
                                                            )
                                                        }
                                                    />
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                )}

                                {myFacture.electricityPrice && (
                                    <TableRow
                                        key={56}
                                        style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                                        <TableCell
                                            className={classes.cell}
                                            padding="none"
                                            align="left">
                                            Electricité
                                        </TableCell>
                                        <TableCell
                                            className={classes.cell}
                                            padding="none"
                                            align="left">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={myFacture.electricity == '1'}
                                                        onChange={() =>
                                                            this.updatePlaceBeforeCashed(
                                                                myFacture.id,
                                                                'electricity'
                                                            )
                                                        }
                                                        name="active"
                                                        color="primary"
                                                    />
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                )}

                                <TableRow key={5628}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        Moyen de paiement
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <TextField
                                                    select
                                                    size="small"
                                                    value={paiementMethod}
                                                    onChange={this.handlePaiementMethod}>
                                                    {currencies.map(option => (
                                                        <MenuItem
                                                            key={option.value}
                                                            value={option.value}>
                                                            <Typography
                                                                variant="body1"
                                                                color="primary">
                                                                {option.label}
                                                            </Typography>
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            {paiementMethod == 'Chèque' && (
                                                <React.Fragment>
                                                    <Grid item>
                                                        <TextField
                                                            size="small"
                                                            name="banque"
                                                            onChange={event =>
                                                                this.handleCheque('banque', event)
                                                            }
                                                            placeholder="Banque"
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            size="small"
                                                            name="cheque"
                                                            onChange={event =>
                                                                this.handleCheque('cheque', event)
                                                            }
                                                            placeholder="Chèque"
                                                        />
                                                    </Grid>
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                    </TableCell>
                                </TableRow>

                                <TableRow key={568} style={{ backgroundColor: 'rgb(0,0,0, 0.02)' }}>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <strong>Montant total</strong>
                                    </TableCell>
                                    <TableCell className={classes.cell} padding="none" align="left">
                                        <Typography
                                            variant="body1"
                                            style={{ fontWeight: 'bold' }}
                                            color="primary">
                                            {this.formatPrice(myFacture.montant)} €
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        {isPaying && <CircularProgress size={25} color="primary" />}
                        <Button
                            disabled={isPaying}
                            autoFocus
                            onClick={() => this.handleCloseCash(false)}
                            color="primary">
                            Annuler
                        </Button>
                        <Button
                            disabled={isPaying}
                            variant="contained"
                            onClick={() => {
                                this.setState({
                                    isPaying: true
                                });
                            }}
                            color="primary">
                            Encaisser
                        </Button>
                    </DialogActions>
                </Dialog>

                <Grid container direction="column" spacing={4}>
                    <Grid item>
                        <InfoCard data={{ cashed: nbrTotalCashed, all: nbrAll }} />
                    </Grid>
                    <Grid item>
                        <Encaissement encaisse={totalCashed} nonencaisse={totalNotCashed} />
                    </Grid>
                    <Grid item>
                        <Detail
                            data={{
                                espece: totalEspece,
                                cheque: totalCheque,
                                cb: totalCb,
                                prelevement: totalPrelevement,
                                tel: totalTelephone
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Paper className={classes.paperContent}>
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="stretch"
                                spacing={3}>
                                <Grid item>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        justifyContent="flex-end"
                                        alignItems="flex-end"
                                        spacing={3}>
                                        <Grid item>
                                            <Button
                                                component={RouterLink}
                                                variant="contained"
                                                color="primary"
                                                to={`/bills/grouping/view/`}>
                                                Facturation groupée
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label={'Rechercher un commerçant'}
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        value={searchValue}
                                        onChange={this.handleSearch}
                                        helperText="Saisir une raison sociale, une enseigne, un nom ou un prénom"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={this.clearSearch}
                                                        edge="end">
                                                        <ClearIcon style={{ color: 'red' }} />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControl component="fieldset">
                                        <FormLabel
                                            className={classes.labelFilter}
                                            component="label">
                                            Type du commerçant
                                        </FormLabel>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={all}
                                                        onChange={this.handleTypeFilter}
                                                        name="all"
                                                    />
                                                }
                                                label="Tous"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={ta}
                                                        onChange={this.handleTypeFilter}
                                                        name="ta"
                                                    />
                                                }
                                                label="Titulaire abonné"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={tna}
                                                        onChange={this.handleTypeFilter}
                                                        name="tna"
                                                    />
                                                }
                                                label="Titulaire non abonné"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={p}
                                                        onChange={this.handleTypeFilter}
                                                        name="p"
                                                    />
                                                }
                                                label="Passager"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item>
                                    <FormControl component="fieldset">
                                        <FormLabel
                                            component="legend"
                                            className={classes.labelFilter}>
                                            Etat d'encaissement
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            name="etat"
                                            onChange={this.handleStateCashFilter}
                                            value={etat}>
                                            <FormControlLabel
                                                value="all"
                                                control={<Radio />}
                                                label="Toutes les factures"
                                            />
                                            <FormControlLabel
                                                value="y"
                                                control={<Radio style={{ color: 'green' }} />}
                                                label="Réglé"
                                            />
                                            <FormControlLabel
                                                value="n"
                                                control={<Radio style={{ color: 'red' }} />}
                                                label="Non réglé"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Divider />
                                </Grid>

                                <Grid item>
                                    {/*<TextField
                                        variant="outlined"
                                        fullWidth
                                        onChange={this.handleLimitedNumero}
                                        value={limitedNumero}
                                        color="primary"
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    <Typography color="primary">
                                                        Afficher à partir de la place numéro
                                                    </Typography>
                                                </InputAdornment>,
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => this.handleLimitedNumero({ target: { value: "" } })} edge="end">
                                                        <ClearIcon style={{ color: "red" }} />
                                                    </IconButton>
                                                </InputAdornment>
                                        }}
                                    />*/}
                                    <Autocomplete
                                        options={listOfPlaces}
                                        name="startNumero"
                                        autoHighlight
                                        value={startNumero}
                                        onChange={(event, value) =>
                                            this.handleNumero('startNumero', value)
                                        }
                                        onInputChange={(event, value) => {
                                            //this.handleNumero("startNumero", value);
                                        }}
                                        noOptionsText={"Aucune place n'a été trouvée"}
                                        getOptionLabel={option => option.numero}
                                        getOptionSelected={(option, value) =>
                                            option.numero == value.numero
                                        }
                                        renderInput={params => (
                                            <TextField
                                                fullWidth
                                                {...params}
                                                type="number"
                                                min="1"
                                                max="999"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment position="end">
                                                            Afficher à partir du N°
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item>
                                    <TableContainer>
                                        <Table stickyHeader className={classes.table}>
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map(column => (
                                                        <StyledTableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}>
                                                            {column.label}
                                                        </StyledTableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {list.map((facture, index) => (
                                                    <TableRow key={facture.numFacture}>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="left">
                                                            <ListItemText
                                                                primary={'N°' + facture.numero}
                                                                secondary={
                                                                    facture.metres + ' mètres'
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            key={`rs_${facture.numFacture}`}
                                                            align="left">
                                                            {this.alertMerchant(facture)}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justify="center"
                                                                alignItems="center">
                                                                {facture.encaisse == '1' &&
                                                                isMobile ? (
                                                                    <Grid item>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                this.printBill(
                                                                                    facture.numFacture
                                                                                )
                                                                            }>
                                                                            <PrintIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                ) : null}
                                                                <Grid item>
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            this.getPdf(
                                                                                facture.numFacture
                                                                            )
                                                                        }>
                                                                        <DescriptionIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item>
                                                                    {facture.encaisse == '1' ? (
                                                                        <ListItemText
                                                                            primary={
                                                                                <Typography align="left">
                                                                                    {
                                                                                        facture.numFacture
                                                                                    }
                                                                                </Typography>
                                                                            }
                                                                            secondary={
                                                                                <React.Fragment>
                                                                                    Réglé{' '}
                                                                                    <DoneIcon
                                                                                        style={{
                                                                                            fontSize:
                                                                                                'inherit',
                                                                                            color:
                                                                                                'green'
                                                                                        }}
                                                                                    />
                                                                                </React.Fragment>
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <Button
                                                                            color="primary"
                                                                            variant="contained"
                                                                            onClick={() =>
                                                                                this.handleCash(
                                                                                    facture.journalplace_id,
                                                                                    facture.id,
                                                                                    facture.status
                                                                                )
                                                                            }
                                                                            size="small">
                                                                            Encaisser
                                                                        </Button>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {facture.montant} €
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item>
                                    <Pagination
                                        numberOfPages={numberOfPages}
                                        currentPage={page}
                                        handlePagination={this.handlePagination}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Finance);
