import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import { Grid, Typography, Button, CircularProgress, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        //flexGrow: 1
    }
}));

const Overview = props => {
    const { className, data } = props;

    const classes = useStyles();

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item>
                <Grid container direction="column" alignItems="stretch" spacing={3}>
                    <Grid item>
                        <Grid container spacing={1} direction="column">
                            <Grid item>
                                <Typography variant="button">
                                    Nombre de journée de marché
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                            justify="center"
                            alignItems="flex-end">
                            <Grid item>
                                <Grid container direction="row" spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <Typography variant="h2" align="center" gutterBottom>
                                            {data.market.openingMarket}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="stretch" spacing={3}>
                    <Grid item>
                        <Grid container spacing={1} direction="column">
                            <Grid item>
                                <Typography variant="button">Nombre de présence</Typography>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                            justify="center"
                            alignItems="flex-end">
                            <Grid item>
                                <Grid container direction="row" spacing={3} alignItems="flex-end">
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={2}
                                            alignItems="flex-start">
                                            <Grid item>
                                                <Typography
                                                    variant="h2"
                                                    align="center"
                                                    gutterBottom>
                                                    {data.overview.present.absolute}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Divider
                                            orientation="vertical"
                                            style={{ height: 50 }}
                                            variant="fullWidth"
                                            flexItem
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={2}
                                            alignItems="flex-start">
                                            <Grid item>
                                                <Typography
                                                    variant="h2"
                                                    align="center"
                                                    color="secondary"
                                                    gutterBottom>
                                                    {data.overview.present.percentage} %
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="stretch" spacing={3}>
                    <Grid item>
                        <Grid container spacing={1} direction="column">
                            <Grid item>
                                <Typography variant="button">
                                    Nombre {data.merchant.isHolder ? "d'absence non justifié" : 'de placement'}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={1}
                            justify="center"
                            alignItems="flex-end">
                            <Grid item>
                                <Grid container direction="row" spacing={3} alignItems="flex-end">
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={2}
                                            alignItems="flex-start">
                                            <Grid item>
                                                <Typography
                                                    variant="h2"
                                                    align="center"
                                                    gutterBottom>
                                                    {data.merchant.isHolder
                                                        ? data.overview.absent.absolute
                                                        : data.overview.placement.absolute}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Divider
                                            orientation="vertical"
                                            style={{ height: 50 }}
                                            variant="fullWidth"
                                            flexItem
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={2}
                                            alignItems="flex-start">
                                            <Grid item>
                                                <Typography
                                                    variant="h2"
                                                    align="center"
                                                    color="secondary"
                                                    gutterBottom>
                                                    {data.merchant.isHolder
                                                        ? data.overview.absent.percentage
                                                        : data.overview.placement.percentage}{' '}
                                                    %
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Overview;
