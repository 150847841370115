import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress } from '@material-ui/core';

const styles = theme => ({
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 10
    }
});

const SelectedButton = withStyles(styles)(props => {
    const { children, classes, data, setCount, ...other } = props;
    const [isClicked, setClicked] = React.useState(false);
    const [selected, setSelected] = React.useState(data.isSelected);

    const handleClick = () => {
        setClicked(true);
        setSelected(!selected);
        handleList();
    };

    const handleList = () => {
        const formData = new FormData();
        formData.append('id', data.id);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/email/update-reminder-list/${data.reminder}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                setClicked(false);
                setCount(selected ? -1 : 1);
            })
            .catch(error => {
                alert('Echec de la mise à jour');
                setClicked(false);
            });
    };

    return (
        <Grid container direction="row" alignItems="center" justify="center">
            <Grid item>
                {isClicked ? (
                    <CircularProgress size={25} />
                ) : (
                    <Button
                        disabled={!data.isActive}
                        size="small"
                        onClick={handleClick}
                        color="primary"
                        variant={selected ? 'contained' : 'outlined'}>
                        {selected ? 'Oui' : 'Non'}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
});

export default SelectedButton;
