import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import * as serviceWorker from './serviceWorker';
import App from './App';

serviceWorker.handlingRequest();

ReactDOM.render(
    <Router history={createBrowserHistory({})}>
        <App />
    </Router>
    , document.getElementById('root')
);
//serviceWorker.unregister();
