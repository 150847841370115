// @flow

import L from 'leaflet';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { withLeaflet } from 'react-leaflet';
import transformRotate from '@turf/transform-rotate';
import turf from 'turf';
import Slider from '@material-ui/core/Slider';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableContainer,
    FormControlLabel,
    Switch
} from '@material-ui/core';

import drag from 'leaflet-path-drag';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabControl from './TabControl';

import MarketPlace from './MarketPlace';

const DEFAULT_STATE = {
    sliderRotation: 0,
    abonne: false,
    inputNumero: '',
    inputMeters: '',
    activitieKey: '',
    merchantSiret: '',
    information: 'Aucune place selectionnée',
    layers: {
        selectedLayer: null,
        flyOverLayer: null
        //layersGroup: layersGroup
    }
};

const styles = {
    root: {
        margin: '30px'
    }
};

class Control extends React.Component {
    activities = {
        alim: {
            id: 1,
            key: 'alim',
            label: 'Alimentaire',
            color: 'white'
        },
        prim: {
            id: 2,
            key: 'prim',
            label: 'Primeur',
            color: '#32CD32	'
        },
        manu: {
            id: 3,
            key: 'manu',
            label: 'Manufacturé',
            color: '#87CEFA'
        },
        dempos: {
            id: 4,
            key: 'dempos',
            label: 'Démonstrateur/Posticheur',
            color: '#BD33A4'
        }
    };

    //merchants = {
    //  _0: {
    //    siret: "0",
    //    raisonSociale: "Non affecté",
    //    metier: "Aucun"
    //  },
    //}

    silderMarks = [
        {
            value: 0,
            label: '0°'
        },
        {
            value: 45,
            label: '45°'
        },
        {
            value: 90,
            label: '90°'
        },
        {
            value: 135,
            label: '135°'
        },
        {
            value: 180,
            label: '180°'
        }
    ];

    constructor(props) {
        super(props);

        this.state = {
            ...props,
            ...DEFAULT_STATE,
            layers: {
                ...DEFAULT_STATE.layers,
                layersGroup: props.layersGroup
            },
            tabValue: 0,
            listOfPlaces: []
        };

        let { layersGroup } = this.state.layers;

        layersGroup.addEventListener('click', this._onClick);
        layersGroup.addEventListener('mouseover', this._onMouseOver);
        layersGroup.addEventListener('mouseout', this._onMouseOut);

        layersGroup.addTo(this.props.leaflet.map);
    }

    componentDidMount = () => {
        const { places } = this.props;
        const { layersGroup, adresse } = this.state;
        const { map } = this.props.leaflet;

        //map.setView({
        //  "lat": places[0]["a_lat"],
        //  "lng": places[0]["a_lng"],
        //    },);

        map.setView({
            lat: adresse.lat,
            lng: adresse.lng
        });

        places.map(layer => {
            let marketPlace = new MarketPlace();
            marketPlace.addEventListener('dragstart', this._onDragStart);
            marketPlace.addEventListener('dragend', this._onDragEnd);

            //marketPlace.setActivitie(this.getActivite(layer['activite_id'])); // get activitie
            marketPlace.setActivitie(this.getActivite(1)); // get activitie

            let coordinates = [
                {
                    lat: layer['a_lat'],
                    lng: layer['a_lng']
                },
                {
                    lat: layer['b_lat'],
                    lng: layer['b_lng']
                },
                {
                    lat: layer['c_lat'],
                    lng: layer['c_lng']
                },
                {
                    lat: layer['d_lat'],
                    lng: layer['d_lng']
                }
            ];

            let mymerchant = {
                raisonSociale:
                    layer['marchand_id'] == '1' || layer['marchand_id'] == null
                        ? 'NON AFFECTE'
                        : layer['raisonSociale'],
                siret:
                    layer['marchand_id'] == '1' || layer['marchand_id'] == null
                        ? '1'
                        : layer['marchand_id'],
                abonnement: Boolean(layer['abonnement'] == '1')
            };

            let is_free = Boolean(layer['marchand_id'] == '1' || layer['marchand_id'] == null);

            marketPlace.setMerchant(mymerchant);
            marketPlace.setLatLngs(coordinates);
            marketPlace.setConfig('meters', layer['metres']);
            marketPlace.setConfig('place_id', layer['id']);

            marketPlace.setConfig('rotation', parseInt(layer['rotation']));
            marketPlace.addTo(layersGroup);
            marketPlace.dragging.enable();
            marketPlace.setNumero(layer['numeroPlace'], is_free);
            marketPlace.getConfig('numero').label.addTo(map);
        });
    };

    getMerchant = id => {
        const { marchands } = this.props;
        let mymarchand = {};

        marchands.map((marchand, index) => {
            if (marchand.id == id) {
                mymarchand.raisonSociale = marchand.raisonSociale;
                mymarchand.siret = marchand.id;
                mymarchand.metier = marchand.metier_id;
            }
        });

        return mymarchand;
    };

    getActivite = id => {
        let myact = '';
        Object.keys(this.activities).map(key => {
            if (this.activities[key].id == parseInt(id)) {
                myact = this.activities[key];
            }
        });

        return myact;
    };

    _onClick = e => {
        // Selected layer
        let layer = e.layer;
        let { selectedLayer } = this.state.layers;

        if (selectedLayer) {
            selectedLayer.setActivitie(selectedLayer.getConfig('activitie'));
        }

        layer.setStyle({
            color: 'blue'
        });

        this.setState(prevState => ({
            information: `Place n°${layer.getConfig('numero').numero} selectionnée`,
            layers: {
                ...prevState.layers,
                selectedLayer: layer,
                flyOverLayer: layer
            }
        }));
    };

    _onMouseOver = e => {
        // Fly over layer
        let layer = e.layer;
        let { selectedLayer, layersGroup } = this.state.layers;

        if (selectedLayer) {
            let selectedLayerId = layersGroup.getLayerId(selectedLayer);
            let layerId = layersGroup.getLayerId(layer);

            selectedLayerId != layerId && layer.setStyle({ color: 'cornflowerblue' });
        } else {
            layer.setStyle({ color: 'cornflowerblue' });
        }

        this.setState(prevState => ({
            activitieKey: layer.getConfig('activitie').key,
            sliderRotation: layer.getConfig('rotation'),
            merchantSiret: `${layer.getConfig('merchant').merchant.siret}`,
            inputMeters: layer.getConfig('meters'),
            inputNumero: layer.getConfig('numero').numero,
            abonne: layer.getConfig('merchant').merchant.abonnement,
            layers: {
                ...prevState.layers,
                flyOverLayer: layer
            }
        }));
    };

    _onMouseOut = e => {
        // Fly out layer
        let layer = e.layer;
        let { selectedLayer } = this.state.layers;
        let { layersGroup } = this.state.layers;

        if (selectedLayer) {
            let selectedLayerId = layersGroup.getLayerId(selectedLayer);
            let layerId = layersGroup.getLayerId(layer);

            selectedLayerId != layerId && layer.setActivitie(layer.getConfig('activitie'));
        } else {
            layer.setActivitie(layer.getConfig('activitie'));
        }

        this.setState(prevState => ({
            activitieKey: selectedLayer ? selectedLayer.getConfig('activitie').key : '',
            sliderRotation: selectedLayer ? selectedLayer.getConfig('rotation') : 0,
            inputMeters: selectedLayer ? selectedLayer.getConfig('meters') : '',
            inputNumero: selectedLayer ? selectedLayer.getConfig('numero').numero : '',
            merchantSiret: selectedLayer
                ? `${selectedLayer.getConfig('merchant').merchant.siret}`
                : '',
            abonne: selectedLayer ? selectedLayer.getConfig('merchant').merchant.abonnement : false,
            layers: {
                ...prevState.layers,
                flyOverLayer: null
            }
        }));
    };

    _onDragStart = e => {
        let layer = e.target;
        //let { map } = this.props.leaflet;

        //map.removeLayer(layer.getConfig('numero').label);
        layer.getConfig('numero').label.setLatLng([0, 0]);
        //map.removeLayer(layer.getConfig('merchant').label);
        //if (layer.getConfig('merchant').label) {
        //  layer.getConfig('merchant').label.setLatLng([0, 0]);
        //}
    };

    _onDragEnd = e => {
        let { map } = this.props.leaflet;
        let layer = e.target;
        layer.setNumero(layer.getConfig('numero').numero, layer.getConfig('is_free'));
        //if (layer.getConfig('merchant').label) {
        //  layer.setMerchant(layer.getConfig('merchant').merchant);
        //}
        //layer.getConfig('merchant').label.addTo(map);
        //layer.getConfig('numero').label.addTo(map);

        let coords = layer.getLatLngs()[0];
        /*
        fetch(
          `https://www.${(window.location.hostname).split(".")[1] ? (window.location.hostname).split(".")[1] : "dev"}.api.digitmarche.fr/api/market/update-place/update?place_id=${layer.getConfig('place_id')}&numero=${layer.getConfig('numero').numero}&activite_id=${layer.getConfig('activitie').id}&metres=${layer.getConfig('meters')}&rotation=${layer.getConfig('rotation')}&`
          + `coords[0][0]=${coords[0].lat}&coords[0][1]=${coords[0].lng}&coords[1][0]=${coords[1].lat}&coords[1][1]=${coords[1].lng}&coords[2][0]=${coords[2].lat}&coords[2][1]=${coords[2].lng}&coords[3][0]=${coords[3].lat}&coords[3][1]=${coords[3].lng}&`
          + `marchand_id=${layer.getConfig('merchant').merchant.siret}&abonnement=${layer.getConfig('merchant').merchant.abonnement}`,
          {
            method: 'POST',
          })
          .then(res => res.json()) // Traitement en json
          .catch(console.log)
    */

        this.handleUpdatePlace();
    };

    removePlace = () => {
        // On click context
        let { selectedLayer } = this.state.layers;
        let { layersGroup } = this.state.layers;
        let { map } = this.props.leaflet;

        selectedLayer.getConfig('numero').label.remove(); // del numero's layer
        map.removeLayer(selectedLayer.getConfig('numero').label); // del numero's layer from map
        selectedLayer.remove(); // del layer from group
        layersGroup.removeLayer(selectedLayer.getConfig('numero').label);
        layersGroup.removeLayer(selectedLayer); // del layer from map

        //selectedLayer.getConfig('merchant').label && selectedLayer.getConfig('merchant').label.remove(); // del merchant's layer if exist
        //selectedLayer.getConfig('merchant').label && map.removeLayer(selectedLayer.getConfig('merchant').label); // del merchant's layer from map if exist

        /*
        fetch(`https://www.${(window.location.hostname).split(".")[1] ? (window.location.hostname).split(".")[1] : "dev"}.api.digitmarche.fr/api/market/update-place/remove?place_id=${selectedLayer.getConfig('place_id')}`,
          {
            method: 'POST',
          })
          .then(res => res.json()) // Traitement en json
          .catch(console.log)
    */
        this.setState({
            ...this.props,
            ...DEFAULT_STATE,
            listOfPlaces: this.getPlaces(),
            layers: {
                ...DEFAULT_STATE.layers,
                layersGroup: layersGroup
            }
        });

        this.handleUpdatePlace();
    };

    addPlace = (activitieKey, numero, meters, merchantSiret) => {
        if (numero > 0 && meters > 1 && activitieKey != '') {
            let { layersGroup, selectedLayer } = this.state.layers;
            let { abonne } = this.state;
            let { map } = this.props.leaflet;
            let { marcheid } = this.props;
            let is_valid = true;

            if (selectedLayer) {
                selectedLayer.setActivitie(selectedLayer.getConfig('activitie'));
            }

            layersGroup.eachLayer(layer => {
                if (layer.is_a_place && layer.getConfig('numero').numero == numero) {
                    alert('Numéro de place déjà saisi');
                    is_valid = false;
                }

                if (
                    layer.is_a_place &&
                    layer.getConfig('merchant').merchant.siret == merchantSiret &&
                    merchantSiret != 1
                ) {
                    alert('Commerçant déjà placé à la place N°' + layer.getConfig('numero').numero);
                    is_valid = false;
                }
            });

            if (!is_valid) {
                return;
            }

            let marketPlace = new MarketPlace();
            marketPlace.setActivitie(this.activities[activitieKey]);
            marketPlace.setStyle({
                color: 'blue'
            });
            marketPlace.setMeters(meters, map.getCenter());
            marketPlace.setNumero(numero);

            let mymerchant = this.getMerchant(merchantSiret);
            mymerchant.abonnement = merchantSiret == '1' ? false : abonne;

            marketPlace.setMerchant(mymerchant);

            marketPlace.addTo(layersGroup);

            marketPlace.addEventListener('dragstart', this._onDragStart);
            marketPlace.addEventListener('dragend', this._onDragEnd);

            marketPlace.dragging.enable();

            marketPlace.getConfig('numero').label.addTo(layersGroup);

            //merchantSiret != "_0" && marketPlace.getConfig('merchant').label.addTo(layersGroup);

            let coords = marketPlace.getLatLngs()[0];
            /*
            fetch(
              `https://www.${(window.location.hostname).split(".")[1] ? (window.location.hostname).split(".")[1] : "dev"}.api.digitmarche.fr/api/market/update-place/add?marche_id=${marcheid}&numero=${marketPlace.getConfig('numero').numero}&activite_id=${marketPlace.getConfig('activitie').id}&metres=${marketPlace.getConfig('meters')}&rotation=${marketPlace.getConfig('rotation')}&`
              + `coords[0][0]=${coords[0].lat}&coords[0][1]=${coords[0].lng}&coords[1][0]=${coords[1].lat}&coords[1][1]=${coords[1].lng}&coords[2][0]=${coords[2].lat}&coords[2][1]=${coords[2].lng}&coords[3][0]=${coords[3].lat}&coords[3][1]=${coords[3].lng}&`
              + `marchand_id=${marketPlace.getConfig('merchant').merchant.siret}&abonnement=${marketPlace.getConfig('merchant').merchant.abonnement}`,
              {
                method: 'POST',
              })
              .then(res => res.json()) // Traitement en json
              .then((data) => { // Traitement de l'indo for stating data
                marketPlace.setConfig('place_id', data);
              })
              .catch(console.log)
      */

            this.setState(prevState => ({
                information: `Place n°${numero} selectionnée`,
                sliderRotation: 0,
                merchantSiret: merchantSiret,
                layers: {
                    ...prevState.layers,
                    selectedLayer: marketPlace,
                    flyOverLayer: null
                }
            }));
        }

        this.handleUpdatePlace();
    };

    editPlace = () => {
        let { selectedLayer, layersGroup } = this.state.layers;
        let {
            activitieKey,
            sliderRotation,
            inputMeters,
            inputNumero,
            merchantSiret,
            abonne
        } = this.state;
        let { map } = this.props.leaflet;
        let is_valid = true;

        layersGroup.eachLayer(layer => {
            if (inputNumero != selectedLayer.getConfig('numero').numero) {
                if (layer.is_a_place) {
                    if (
                        layer.getConfig('numero').numero == inputNumero &&
                        layer.getId() != selectedLayer.getId()
                    ) {
                        alert('Numéro de place déjà saisi');
                        is_valid = false;
                    }
                }
            }
        });

        if (!is_valid) {
            this.setState({
                inputNumero: selectedLayer.getConfig('numero').numero
            });
            return;
        }

        if (inputNumero > 0 && inputMeters > 1 && activitieKey != '') {
            selectedLayer.setActivitie(this.activities[activitieKey]);
            selectedLayer.setStyle({
                color: 'blue'
            });
            selectedLayer.setMeters(inputMeters);
            selectedLayer.setNumero(inputNumero);

            let mymerchant = this.getMerchant(merchantSiret);
            mymerchant.abonnement = merchantSiret == '1' ? false : abonne;

            selectedLayer.setMerchant(mymerchant);

            let coords = selectedLayer.getLatLngs()[0];

            /*
            fetch(
              `https://www.${(window.location.hostname).split(".")[1] ? (window.location.hostname).split(".")[1] : "dev"}.api.digitmarche.fr/api/market/update-place/update?place_id=${selectedLayer.getConfig('place_id')}&numero=${selectedLayer.getConfig('numero').numero}&activite_id=${selectedLayer.getConfig('activitie').id}&metres=${selectedLayer.getConfig('meters')}&rotation=${selectedLayer.getConfig('rotation')}&`
              + `coords[0][0]=${coords[0].lat}&coords[0][1]=${coords[0].lng}&coords[1][0]=${coords[1].lat}&coords[1][1]=${coords[1].lng}&coords[2][0]=${coords[2].lat}&coords[2][1]=${coords[2].lng}&coords[3][0]=${coords[3].lat}&coords[3][1]=${coords[3].lng}&`
              + `marchand_id=${selectedLayer.getConfig('merchant').merchant.siret}&abonnement=${selectedLayer.getConfig('merchant').merchant.abonnement}`,
              {
                method: 'POST',
              })
              .then(res => res.json()) // Traitement en json
              .catch(console.log)
      
      */
            //if (merchantSiret == "_0") { // no merchant selected
            //  if (selectedLayer.getConfig('merchant').label) { // merchant to no merchant selected
            //    selectedLayer.getConfig('merchant').label.remove();
            //    map.removeLayer(selectedLayer.getConfig('merchant').label);
            //    selectedLayer.setConfig('merchant', { merchant: this.merchants[merchantSiret], label: null })
            //  }
            //} else if (merchantSiret != "_0") { // merchant selected

            //  if (selectedLayer.getConfig('merchant').label) { // already have a label
            //    selectedLayer.setConfig('merchant',
            //      {
            //        merchant: this.merchants[merchantSiret],
            //        label: selectedLayer.getConfig('merchant').label
            //      }
            //    )

            //    selectedLayer.getConfig('merchant').label.setLatLng(selectedLayer.getLatLngs()[0][2]);

            //  } else { // Does not have a merchant label yet
            //    selectedLayer.setMerchant(this.merchants[merchantSiret]);
            //    selectedLayer.getConfig('merchant').label.addTo(layersGroup);
            //  }

            //}

            //selectedLayer.setConfig('rotation', 0);
            //selectedLayer.getConfig('numero').label.addTo(map);
            //this.rotate(selectedLayer, selectedLayer.getConfig('rotation'))

            //this.setState({
            //  //information: `Place n°${inputNumero} selectionnée`,
            //  //sliderRotation: 0
            //})
        }
        this.handleUpdatePlace();
    };

    handleMerchantPlaced = () => {
        const { marchands } = this.props;

        const { layersGroup, selectedLayer, flyOverLayer } = this.state.layers;

        const { activitieKey } = this.state;

        let data = [];
        let is_placed = false;

        if (selectedLayer) {
            marchands.map((m, index) => {
                selectedLayer.getConfig('merchant').merchant.siret == m.id &&
                    m.id != '1' &&
                    data.push(m);
            });
        }

        if (flyOverLayer) {
            marchands.map((m, index) => {
                flyOverLayer.getConfig('merchant').merchant.siret == m.id &&
                    m.id != '1' &&
                    data.push(m);
            });
        }
        marchands.map((m, index) => {
            is_placed = false;

            layersGroup.eachLayer(layer => {
                if (layer.is_a_place) {
                    if (layer.getConfig('merchant').merchant.siret == m.id && m.id != '1') {
                        is_placed = true;
                    }
                }
            });

            if (!is_placed) {
                data.push(m);
            }
        });

        return data;
    };

    getPlaces() {
        const { layersGroup } = this.state.layers;
        let data = [];

        layersGroup.eachLayer(layer => {
            layer.is_a_place &&
                data.push({
                    id: layersGroup.getLayerId(layer),
                    numero: layer.getConfig('numero').numero,
                    raisonSociale: layer.getConfig('merchant').merchant.raisonSociale,
                    meters: layer.getConfig('meters')
                });
        });

        return data;
    }

    rotate(layer, angle) {
        let latlng = [];
        transformRotate(layer.toGeoJSON(20), angle).geometry.coordinates[0].map(coord => {
            latlng.push(L.latLng(coord[1], coord[0]));
        });
        layer.setLatLngs(latlng);
        //layer.getConfig('merchant').label && layer.getConfig('merchant').label.setLatLng(layer.getLatLngs()[0][2])
    }

    handleRotate = (event, value) => {
        // Rotation
        event.stopPropagation();
        let { selectedLayer } = this.state.layers;
        //let { map } = this.props.leaflet

        if (selectedLayer) {
            this.setState({ sliderRotation: value });

            let originalRotation = selectedLayer.getConfig('rotation');
            let newRotation = value - originalRotation;

            selectedLayer.setConfig('rotation', value);

            if (originalRotation != value) {
                //selectedLayer.getConfig('numero').label.remove();
                this.rotate(selectedLayer, newRotation);
                //selectedLayer.setNumero(selectedLayer.getConfig('numero').numero);
                //selectedLayer.getConfig('numero').label.addTo(map)
            }
        }
    };

    endRotate = () => {
        this.handleUpdatePlace();
        let { selectedLayer } = this.state.layers;

        if (selectedLayer) {
            let coords = selectedLayer.getLatLngs()[0];
            /*
            fetch(
              `https://www.${(window.location.hostname).split(".")[1] ? (window.location.hostname).split(".")[1] : "dev"}.api.digitmarche.fr/api/market/update-place/update?place_id=${selectedLayer.getConfig('place_id')}&numero=${selectedLayer.getConfig('numero').numero}&activite_id=${selectedLayer.getConfig('activitie').id}&metres=${selectedLayer.getConfig('meters')}&rotation=${selectedLayer.getConfig('rotation')}&`
              + `coords[0][0]=${coords[0].lat}&coords[0][1]=${coords[0].lng}&coords[1][0]=${coords[1].lat}&coords[1][1]=${coords[1].lng}&coords[2][0]=${coords[2].lat}&coords[2][1]=${coords[2].lng}&coords[3][0]=${coords[3].lat}&coords[3][1]=${coords[3].lng}&`
              + `marchand_id=${selectedLayer.getConfig('merchant').merchant.siret}&abonnement=${selectedLayer.getConfig('merchant').merchant.abonnement}`,
              {
                method: 'POST',
              })
              .then(res => res.json()) // Traitement en json
              .catch(console.log)
              */
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        const data = new FormData();
        const targets = e.target;
        const meters = targets.meters.value;
        const activitie = 'alim'; //targets.activitie.value;
        const numero = targets.numero.value;
        const merchant = targets.merchant.value ? targets.merchant.value : '1';

        this.addPlace(activitie, numero, meters, merchant);
    };

    handleActivitie = ({ target: { value } }) => {
        this.setState({
            activitieKey: value
        });
    };

    handleMeters = ({ target: { value } }) => {
        this.setState({
            inputMeters: value
        });
    };

    handleNumero = ({ target: { value } }) => {
        this.setState({
            inputNumero: value
        });
    };

    handleTabChange = (e, value) => {
        this.setState({
            tabValue: value
        });

        if (value == 1) {
            this.setState({
                listOfPlaces: this.getPlaces()
            });
        }
    };

    handleHoverRow = e => {
        const { layersGroup } = this.state.layers;
        const layerId = e.target.parentNode.getAttribute('placeid');
        const layer = layersGroup.getLayer(layerId);

        this._onMouseOver({ layer: layer });
    };
    handleLeaveRow = e => {
        const { layersGroup } = this.state.layers;
        const layerId = e.target.parentNode.getAttribute('placeid');
        const layer = layersGroup.getLayer(layerId);

        this._onMouseOut({ layer: layer });
    };

    handleClickRow = e => {
        const { layersGroup } = this.state.layers;
        const layerId = e.target.parentNode.getAttribute('placeid');
        const layer = layersGroup.getLayer(layerId);

        this._onClick({ layer: layer });
    };

    handleMerchant = ({ target: { value } }) => {
        this.setState({
            merchantSiret: value
        });
    };

    handleAbonne = () => {
        this.setState(prevState => ({
            abonne: !prevState.abonne
        }));
    };

    onControlEnterLeave = method => {
        let { map } = this.props.leaflet;

        if (method == 'enter') {
            map.dragging.disable();
        } else if (method == 'leave') {
            map.dragging.enable();
        }
    };

    handleUpdatePlace = () => {
        const { layersGroup } = this.state.layers;
        let data = [];
        let number = 0;
        let numberOccuped = 0;

        layersGroup.eachLayer(layer => {
            layer.is_a_place && number++;

            layer.is_a_place &&
                layer.getConfig('merchant').merchant.siret != '1' &&
                numberOccuped++;

            layer.is_a_place &&
                data.push({
                    id: layersGroup.getLayerId(layer),
                    numeroPlace: layer.getConfig('numero').numero,
                    raisonSociale: layer.getConfig('merchant').merchant.raisonSociale,
                    marchand_id: layer.getConfig('merchant').merchant.siret,
                    metres: layer.getConfig('meters'),
                    abonnement: '0',
                    rotation: layer.getConfig('rotation'),
                    a_lat: layer.getLatLngs()[0][0]['lat'],
                    a_lng: layer.getLatLngs()[0][0]['lng'],
                    b_lat: layer.getLatLngs()[0][1]['lat'],
                    b_lng: layer.getLatLngs()[0][1]['lng'],
                    c_lat: layer.getLatLngs()[0][2]['lat'],
                    c_lng: layer.getLatLngs()[0][2]['lng'],
                    d_lat: layer.getLatLngs()[0][3]['lat'],
                    d_lng: layer.getLatLngs()[0][3]['lng']
                });
        });

        this.props.setPlace(data, number, numberOccuped);
    };

    zoomControl = fnc => {
        let { map } = this.props.leaflet;

        fnc == 'in' ? map.zoomIn() : map.zoomOut();
    };

    render() {
        const {
            sliderRotation,
            inputMeters,
            inputNumero,
            activitieKey,
            information,
            tabValue,
            listOfPlaces,
            merchantSiret,
            abonne
        } = this.state;
        const { selectedLayer } = this.state.layers;
        const editDisabled = !selectedLayer && true;

        const columns = [
            { id: 'numero', label: 'N°', minWidth: 10, align: 'center' },
            { id: 'raisonSociale', label: 'Raison sociale', minWidth: 230, align: 'left' },
            { id: 'meters', label: 'Métres', minWidth: 10, align: 'right' }
        ];

        const { classes } = this.props;

        return (
            <div>
                <div
                    style={{
                        zIndex: 1000,
                        position: 'relative',
                        margin: 20,
                        width: 270,
                        float: 'left'
                    }}>
                    <ButtonGroup
                        orientation="vertical"
                        aria-label="vertical contained primary button group"
                        variant="contained">
                        <Button size="small" onClick={() => this.zoomControl('in')}>
                            <AddIcon />
                        </Button>
                        <Button size="small" onClick={() => this.zoomControl('out')}>
                            <RemoveIcon />
                        </Button>
                    </ButtonGroup>
                </div>
                <div
                    style={{
                        zIndex: 1000,
                        position: 'relative',
                        margin: 20,
                        width: 270,
                        height: 674,
                        overflow: 'auto',
                        float: 'right'
                    }}
                    onMouseEnter={() => this.onControlEnterLeave('enter')}
                    onMouseLeave={() => this.onControlEnterLeave('leave')}>
                    <Card>
                        <CardContent style={{ padding: 0 }}>
                            <form onSubmit={this.handleSubmit}>
                                <IconButton
                                    disabled={editDisabled}
                                    aria-label="Supprimer"
                                    onClick={this.removePlace}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                                {/*
                  <IconButton style={{ float: "right" }} aria-label="Charger" onClick={this.loadData}>
                    <CloudDownloadIcon fontSize="small" />
                  </IconButton>
                  <IconButton style={{ float: "right" }} aria-label="Charger" onClick={this.saveData}>
                    <SaveIcon fontSize="small" />
                  </IconButton>
                  */}
                                <Typography
                                    style={{
                                        padding: 15,
                                        paddingTop: 0,
                                        borderBottom: '1px solid #ccc'
                                    }}
                                    align="center"
                                    color="primary"
                                    variant="h5">
                                    {information}
                                </Typography>
                                <Tabs value={tabValue} onChange={this.handleTabChange}>
                                    <Tab style={{ minWidth: 135 }} label="Configuration" />
                                    <Tab style={{ minWidth: 135 }} label="Liste" />
                                </Tabs>
                                <TabControl value={tabValue} index={0}>
                                    <div style={{ margin: 20 }}>
                                        <TextField
                                            fullWidth
                                            name="numero"
                                            id="numero"
                                            label="Numéro de la place"
                                            type="number"
                                            onChange={this.handleNumero}
                                            value={inputNumero}
                                            helperText="Saisir un numéro de place"
                                            variant="outlined"
                                        />
                                        <TextField
                                            fullWidth
                                            style={{ marginTop: 20 }}
                                            name="meters"
                                            id="meters"
                                            label="Nombre de mètres"
                                            type="number"
                                            onChange={this.handleMeters}
                                            helperText="Saisir un métrage"
                                            value={inputMeters}
                                            variant="outlined"
                                        />
                                        <TextField
                                            fullWidth
                                            //disabled={Boolean(activitieKey == "")}
                                            style={{ marginTop: 20 }}
                                            id="merchant"
                                            name="merchant"
                                            select
                                            label="Commerçant"
                                            value={merchantSiret}
                                            onChange={this.handleMerchant}
                                            helperText="Choisir un commerçant"
                                            variant="outlined">
                                            {this.handleMerchantPlaced().map((marchand, index) => (
                                                //(activitieKey != "" && parseInt(marchand.activite_id) == this.activities[activitieKey].id) &&
                                                <MenuItem key={marchand.id} value={marchand.id}>
                                                    {marchand.raisonSociale}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <Slider
                                            name="rotation"
                                            id="rotation"
                                            style={{ marginTop: 20 }}
                                            defaultValue={0}
                                            onMouseUp={this.endRotate}
                                            value={sliderRotation}
                                            getAriaValueText={this.degreeValue}
                                            onChange={this.handleRotate}
                                            aria-labelledby="discrete-slider-small-steps"
                                            step={1}
                                            marks={this.silderMarks}
                                            track={false}
                                            min={0}
                                            max={180}
                                            valueLabelDisplay="auto"
                                        />
                                    </div>
                                    <ButtonGroup
                                        fullWidth={true}
                                        variant="text"
                                        color="primary"
                                        aria-label="contained primary button group">
                                        <Button
                                            variant="contained"
                                            disabled={editDisabled}
                                            type="button"
                                            size="small"
                                            color="secondary"
                                            onClick={this.editPlace}>
                                            <EditIcon /> Modifier
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            size="small"
                                            color="primary">
                                            <AddIcon /> Créer
                                        </Button>
                                    </ButtonGroup>
                                </TabControl>
                                <TabControl value={tabValue} index={1}>
                                    <TableContainer
                                        style={{ minHeight: 505, height: 505, maxHeight: 505 }}>
                                        <Table>
                                            <TableBody>
                                                {listOfPlaces.map(place => {
                                                    return (
                                                        <TableRow
                                                            onMouseEnter={this.handleHoverRow}
                                                            onMouseLeave={this.handleLeaveRow}
                                                            onClick={this.handleClickRow}
                                                            selected={
                                                                selectedLayer &&
                                                                selectedLayer.getId() == place.id
                                                                    ? true
                                                                    : false
                                                            }
                                                            placeid={place.id}
                                                            hover
                                                            key={place.numero}>
                                                            {columns.map(column => {
                                                                return (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}>
                                                                        {place[column.id]}{' '}
                                                                        {column.id == 'meters' &&
                                                                            ' m'}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabControl>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(withLeaflet(Control));
