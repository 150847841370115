const sectors = [''];

// Utiliser une boucle for pour ajouter les lettres de A à Z
for (var i = 65; i <= 90; i++) {
    sectors.push(
        String.fromCharCode(i)
            .toString()
            .toUpperCase()
    );
}

export default sectors;
