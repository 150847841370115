import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CachedIcon from '@material-ui/icons/Cached';
import PrintIcon from '@material-ui/icons/Print';
import { isMobile } from 'react-device-detect';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    CircularProgress,
    Grid,
    Typography,
    IconButton,
    Tooltip,
    Divider
} from '@material-ui/core';

import {
    EnhancedTableHead,
    EnhancedFilterMenu,
    EnhancedTableFooter,
    EnhancedTablePaginationActions
} from './components';

const mobileStyle = isMobile
    ? {
          //display: 'flow-root',
          // width: '100vw'
          // width: 'calc(100vw - 12.5%)'
      }
    : {};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: -24
        //marginBottom: -24
    },
    pagination: {
        padding: '7px !important'
    },
    table: {
        tableLayout: 'fixed',
        ...mobileStyle
    },
    tableBody: {
        '& tr:nth-child(even)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        },
        '& tr': {
            height: 53
        },
        '& tr td': {
            paddingTop: 2,
            paddingBottom: 2
        }
    }
}));

const MuiTable = props => {
    const {
        headers,
        order,
        orderBy,
        page,
        loading,
        children,
        print,
        refresh,
        filter,
        rowsPerPage
    } = props;

    const classes = useStyles();

    const handleRequestSort = (event, value) => {
        const isAsc = orderBy.data === value && order.data === 'asc';
        order.set(isAsc ? 'desc' : 'asc');
        orderBy.set(value);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, children.length);

    const propsPagination = {
        component: 'div',
        rowsPerPageOptions: [],
        count: page.count,
        rowsPerPage: rowsPerPage,
        page: page.data,
        onChangePage: (event, value) => page.set(value),
        labelDisplayedRows: ({ from, to, count }) => (
            <Typography component="span" variant="body2">
                <strong>{from}</strong>-<strong>{to}</strong> sur{' '}
                <strong>{count !== -1 ? count : `plus de ${to}`}</strong>
            </Typography>
        )
    };

    const propsEnhancedTable = {
        headers: headers,
        order: order.data,
        orderBy: orderBy.data,
        onRequestSort: (event, value) => handleRequestSort(event, value)
    };

    return (
        <TableContainer className={classes.root}>
            <Grid container direction="row" justify="space-between" alignItems="center">
                {filter && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <EnhancedFilterMenu filter={filter} />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </React.Fragment>
                )}
                <Grid item xs={6}>
                    <Grid container direction="row" alignItems="center" justify="flex-start">
                        <Grid item>
                            <Tooltip title="Rafraîchir le tableau">
                                <IconButton onClick={refresh}>
                                    <CachedIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        {print && (
                            <Grid item>
                                <Tooltip title="Imprimer le tableau">
                                    <IconButton onClick={print}>
                                        <PrintIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <TablePagination
                        className={classes.pagination}
                        {...propsPagination}
                        ActionsComponent={EnhancedTablePaginationActions}
                    />
                </Grid>
            </Grid>

            <Table size="small" className={classes.table}>
                <EnhancedTableHead {...propsEnhancedTable} />
                <TableBody className={classes.tableBody}>
                    {loading ? (
                        <TableRow style={{ height: 53 * rowsPerPage, backgroundColor: 'white' }}>
                            <TableCell colSpan={headers.length} align="center">
                                <Grid container direction="column" justify="center" spacing={2}>
                                    <Grid item>
                                        <CircularProgress size={35} color="primary" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">
                                            Chargement en cours...
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    ) : page.count == 0 ? (
                        <TableRow style={{ height: 53 * emptyRows, backgroundColor: 'white' }}>
                            <TableCell colSpan={headers.length} align="center">
                                <Grid container direction="column" justify="center" spacing={2}>
                                    <Grid item>
                                        <ErrorOutlineIcon
                                            fontSize={'large'}
                                            color="primary"
                                            style={{ fontSize: 45 }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">
                                            Aucune donnée n'a été trouvée
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>
                    ) : (
                        <React.Fragment>
                            {children}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{ height: 53 * emptyRows, backgroundColor: 'white' }}>
                                    <TableCell colSpan={headers.length} />
                                </TableRow>
                            )}
                        </React.Fragment>
                    )}
                </TableBody>
                <EnhancedTableFooter {...propsEnhancedTable} />
            </Table>
            <TablePagination
                className={classes.pagination}
                {...propsPagination}
                ActionsComponent={EnhancedTablePaginationActions}
            />
        </TableContainer>
    );
};

MuiTable.defaultProps = {
    rowsPerPage: 10
};

MuiTable.propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            align: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            sortable: PropTypes.bool.isRequired,
            style: PropTypes.object
        }).isRequired
    ).isRequired,
    order: PropTypes.shape({
        set: PropTypes.func.isRequired,
        data: PropTypes.string.isRequired
    }).isRequired,
    orderBy: PropTypes.shape({
        set: PropTypes.func.isRequired,
        data: PropTypes.string.isRequired
    }).isRequired,
    page: PropTypes.shape({
        set: PropTypes.func.isRequired,
        data: PropTypes.number.isRequired,
        count: PropTypes.number.isRequired
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    children: PropTypes.shape.isRequired,
    print: PropTypes.func,
    refresh: PropTypes.func.isRequired,
    filter: PropTypes.arrayOf(
        PropTypes.shape({
            set: PropTypes.func.isRequired,
            sortLabel: PropTypes.string.isRequired,
            default: PropTypes.string.isRequired,
            current: PropTypes.string.isRequired,
            rows: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired,
                    className: PropTypes.string
                })
            )
        })
    ),
    rowsPerPage: PropTypes.number
};

export default MuiTable;
