import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {}
}));

const UploadMerchant = props => {
    const { className, ...rest } = props;

    const inputFile = useRef(null);
    const classes = useStyles();

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const upload = file => {
        const formData = new FormData();
        formData.append('file', file);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/upload-from-client`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                window.open(data, '_blank');
            })
            .catch(
                error => {
                    console.error(error);
                    alert("Echec de l'importation des données.");
                } // Handle the error response object
            );
    };

    const onChangeFile = event => {
        event.stopPropagation();
        event.preventDefault();
        let file = event.target.files[0];

        if (file) {
            let _confirm = window.confirm("Confirmer l'importation des données ?");
            if (_confirm) {
                upload(file);
                inputFile.current.value = null;
            }
        }
    };

    return (
        <div>
            <input
                type="file"
                ref={inputFile}
                accept="text/csv"
                onChange={onChangeFile}
                style={{ display: 'none' }}
            />
            <Button onClick={onButtonClick} variant="contained" color="primary">
                Importer des commerçants
            </Button>
        </div>
    );
};

export default UploadMerchant;
