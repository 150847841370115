import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Typography,
    Button,
    IconButton
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    button: {
        width: '100%'
    },
    cell: {
        borderTop: '1px solid #eeeeee',
        backgroundColor: 'white',
        lineHeight: '35px'
    },
    sortActive: {
        '& *': {
            color: theme.palette.primary.main,
            fontWeight: 500
        }
    },
    sortable: {
        cursor: 'not-allowed',
        '& *': {
            cursor: 'not-allowed'
        }
    },
    labelHeader: {
        textTransform: 'uppercase'
    }
}));

const EnhancedTableHead = props => {
    const { order, orderBy, onRequestSort, headers } = props;
    const classes = useStyles();
    return (
        <TableHead>
            <TableRow>
                {headers.map((row, index) =>
                    row.onClick ? (
                        <TableCell
                            className={clsx(classes.cell)}
                            size="small"
                            key={index}
                            align={row.align}
                            style={row.style ?? {}}
                            padding={row.padding ?? 'normal'}>
                            {typeof row.label === 'string' ? (
                                <Button size="small" onClick={row.onClick} disabled={row.disabled}>
                                    <Typography variant="body2" className={classes.labelHeader}>
                                        {row.label}
                                    </Typography>
                                </Button>
                            ) : (
                                <IconButton onClick={row.onClick} disabled={row.disabled}>
                                    {row.label}
                                </IconButton>
                            )}
                        </TableCell>
                    ) : (
                        <TableCell
                            className={clsx(classes.cell, {
                                [classes.sortActive]: orderBy === row.id,
                                [classes.sortable]: !row.sortable
                            })}
                            size="small"
                            key={index}
                            align={row.align}
                            sortDirection={orderBy === row.id ? order : false}
                            style={row.style ?? {}}
                            padding={row.padding ?? 'normal'}>
                            <TableSortLabel
                                disabled={!row.sortable}
                                active={orderBy === row.id}
                                direction={orderBy === row.id ? order : 'asc'}
                                onClick={event => onRequestSort(event, row.id)}>
                                <Button size="small">
                                    <Typography variant="body2" className={classes.labelHeader}>
                                        {row.label}
                                    </Typography>
                                </Button>
                            </TableSortLabel>
                        </TableCell>
                    )
                )}
            </TableRow>
        </TableHead>
    );
};

export default EnhancedTableHead;
