import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    Grid,
    ClickAwayListener,
    Grow,
    Popper,
    MenuItem,
    MenuList,
    Paper
} from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import EuroIcon from '@material-ui/icons/Euro';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
    popper: {
        zIndex: 9
    },
    button: {
        width: 125
    }
}));

const StatusButton = props => {
    const { className, bill, pay, setBillCanceller, ...rest } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                size="small"
                color="primary"
                ref={anchorRef}
                onClick={handleToggle}
                className={clsx(className, classes.button)}
                endIcon={<ArrowDropDownIcon />}
            >
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item style={{ textAlign: "right" }}>
                        {
                            bill.paid ?
                                "Payée" :
                                bill.canceled ?
                                    "Annulée" :
                                    bill.late ?
                                        "Retard" :
                                        "Facturée"
                        }
                    </Grid>
                </Grid>
            </Button>
            <Popper className={classes.popper} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <MenuItem
                                        key={2}
                                        disabled={bill.paid || bill.canceled}
                                        onClick={(event) => {
                                            pay(bill.number);
                                            handleClose(event);
                                        }}
                                    >
                                        Régler <EuroIcon style={{ color: "green", paddingLeft: 7 }} />
                                    </MenuItem>
                                    <MenuItem
                                        key={3}
                                        component={RouterLink}
                                        size="small"
                                        to={`/bill/edit/${bill.number}`}
                                        disabled={bill.canceled}
                                    >
                                        Modifier <EditIcon style={{ paddingLeft: 7 }} />
                                    </MenuItem>
                                    <MenuItem
                                        key={4}
                                        disabled={bill.canceled || bill.paid}
                                        size="small"
                                        onClick={() => setBillCanceller(bill.number)}
                                    >
                                        Annuler la facture <DeleteForeverIcon style={{ color: "red", paddingLeft: 7 }} />
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
};

export default StatusButton;
