import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';

import { Grid, TextField, InputAdornment, Typography, Divider, Button } from '@material-ui/core';

import { ListHolders, ListPlaces, Indicators } from './components';
import Billing from '../Billing';
import Caracteristic from '../Caracteristic';

import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import BuildIcon from '@material-ui/icons/Build';
import StorefrontIcon from '@material-ui/icons/Storefront';
import PeopleIcon from '@material-ui/icons/People';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles(theme => ({
    head: {
        background: 'rgb(192,226,251,0.35)',
        borderBottom: '1px solid #c0e2fb'
    },
    cellKabis: {
        borderLeft: '7px solid #c0e2fb'
    }
}));

function CustomTextField(props) {
    const { inputRef, label, onChange, name, helperText, startIcon, ...other } = props;

    return (
        <TextField
            {...other}
            onChange={onChange}
            variant="outlined"
            fullWidth
            label={label}
            helperText={helperText}
            InputProps={{
                startAdornment: <InputAdornment position="start">{startIcon}</InputAdornment>,
                endAdornment: (
                    <InputAdornment position="end">
                        <EuroSymbolIcon />
                    </InputAdornment>
                )
            }}
        />
    );
}

const types = [
    {
        id: '1',
        label: 'Sectorisé'
    },
    {
        id: '0',
        label: 'Non sectorisé'
    }
];

const placementsMode = [
    {
        id: '1',
        label: 'Tirage au sort'
    },
    {
        id: '0',
        label: 'Assiduité'
    }
];

const Validation = props => {
    const { className, days, updateData, data, edit, steps, activities, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    const printList = name => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data[name]));
        formData.append('name', name);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/print-list`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                window.open(data.link, '_blank');
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    return (
        <Grid container direction="column" spacing={8} style={{ padding: 48 }}>
            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography
                                    variant="h4"
                                    style={{
                                        color: steps.caracteristic.completed
                                            ? 'green'
                                            : steps.caracteristic.error
                                            ? 'red'
                                            : theme.palette.primary.main
                                    }}>
                                    Caractéristiques du marché <BuildIcon />
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Caracteristic
                            edit={edit}
                            noPadding
                            data={data.caracteristic}
                            node="caracteristic"
                            updateData={updateData}
                            days={days}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <Billing
                    titleColor={
                        steps.billing.completed
                            ? 'green'
                            : steps.billing.error
                            ? 'red'
                            : theme.palette.primary.main
                    }
                    noPadding
                    data={data.billing}
                    node="billing"
                    updateData={updateData}
                />
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container direction="row" justify="space-between">
                                    <Grid item>
                                        <Typography
                                            variant="h4"
                                            style={{
                                                color: steps.plan.completed
                                                    ? 'green'
                                                    : steps.plan.error
                                                    ? 'red'
                                                    : theme.palette.primary.main
                                            }}>
                                            Places du marché <StorefrontIcon />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            endIcon={<PrintIcon />}
                                            onClick={() => printList('places')}
                                            color="primary">
                                            Imprimer
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <ListPlaces activities={activities} places={data.places} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container direction="row" justify="space-between">
                                    <Grid item>
                                        <Typography variant="h4" color="primary">
                                            Titulaires <PeopleIcon />
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            endIcon={<PrintIcon />}
                                            onClick={() => printList('merchants')}
                                            color="primary">
                                            Imprimer
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <ListHolders activities={activities} places={data.places} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography variant="h4" color="primary">
                                    Indicateurs <AssessmentIcon />
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Indicators
                            billing={data.billing}
                            merchants={data.merchants}
                            places={data.places}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Validation;
