import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative'
        //minWidth: 290,
        //margin: "auto",
    }
}));

const Detail = props => {
    const { className, data, ...rest } = props;

    let total =
        parseInt(data.espece.raw) +
        parseInt(data.cheque.raw) +
        parseInt(data.cb.raw) +
        parseInt(data.prelevement.raw) +
        parseInt(data.tel.raw);

    let per_espece = parseInt(data.espece.raw) == 0 ? 0 : (parseInt(data.espece.raw) / total) * 100;
    let per_cheque = parseInt(data.cheque.raw) == 0 ? 0 : (parseInt(data.cheque.raw) / total) * 100;
    let per_cb = parseInt(data.cb.raw) == 0 ? 0 : (parseInt(data.cb.raw) / total) * 100;
    let per_prelevement =
        parseInt(data.prelevement.raw) == 0 ? 0 : (parseInt(data.prelevement.raw) / total) * 100;
    let per_tel = parseInt(data.tel.raw) == 0 ? 0 : (parseInt(data.tel.raw) / total) * 100;

    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.root}>
            <Card {...rest} className={clsx(classes.root, className)}>
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: '80.2%',
                        width: '19.8%',
                        height: per_tel + '%',
                        background: 'green',
                        opacity: 0.18
                    }}></div>
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: '60.2%',
                        width: '19.8%',
                        height: per_prelevement + '%',
                        background: 'green',
                        opacity: 0.1
                    }}></div>
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: '40.2%',
                        width: '19.8%',
                        height: per_cb + '%',
                        background: 'green',
                        opacity: 0.18
                    }}></div>
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: '20.2%',
                        width: '19.8%',
                        height: per_cheque + '%',
                        background: 'green',
                        opacity: 0.1
                    }}></div>
                <div
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: '0%',
                        width: '19.8%',
                        height: per_espece + '%',
                        background: 'green',
                        opacity: 0.18
                    }}></div>
                <CardContent>
                    <Grid container direction="row" justify="space-around" alignItems="stretch">
                        <Grid
                            item
                            style={{ width: '20%' }}
                            //direction="column"
                            //justify="space-between"
                            //alignItems="center"
                        >
                            <Grid item>
                                <Typography align="center" variant="h3">
                                    {data.espece.total} €
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography align="center" variant="body1">
                                    Numéraire
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item style={{ width: '20%' }}>
                            <Grid item>
                                <Typography align="center" variant="h3">
                                    {data.cheque.total} €
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography align="center" variant="body1">
                                    Chèque
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item style={{ width: '20%' }}>
                            <Grid item>
                                <Typography align="center" variant="h3">
                                    {data.cb.total} €
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography align="center" variant="body1">
                                    Carte Bancaire
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item style={{ width: '20%' }}>
                            <Grid item>
                                <Typography align="center" variant="h3">
                                    {data.prelevement.total} €
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography align="center" variant="body1">
                                    Prélèvement
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item style={{ width: '20%' }}>
                            <Grid item>
                                <Typography align="center" variant="h3">
                                    {data.tel.total} €
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography align="center" variant="body1">
                                    Téléphone
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export default Detail;
