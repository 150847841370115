import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Button
} from '@material-ui/core';

import WorkIcon from '@material-ui/icons/Work';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  number: {
    color: theme.palette.primary.main,
  },
  icon: {
    height: 32,
    width: 32
  },
  labelAction: {
    width: '100%',
    textAlign: 'center'
  },
  footer: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    textAlign: "center"
  },
}));

const TotalProfessions = props => {
  const { className, data, ...rest } = props;

  const classes = useStyles();

  return (

    <CardActionArea component={RouterLink} to={"/settings/professions"}>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid
              item
              container
              justify="space-between"
            >
              <Grid item>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body1"
                >
                  Métiers enregistrés
                </Typography>
                <Typography variant="h3" className={classes.number} gutterBottom>{data.number}</Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <WorkIcon className={classes.icon} />
                </Avatar>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                Dernier métier créé <strong>{data.last.label}</strong> le <strong>{data.last.date}</strong>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <div className={classes.footer}>
          <Button
            className={classes.labelAction}
            color="primary"
          >
            Configurer les métiers
          </Button>
        </div>
      </Card>
    </CardActionArea>
  );
};

export default TotalProfessions;
