import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';

import {
    Grid,
    IconButton,
    Tooltip
} from '@material-ui/core';

import StorefrontIcon from '@material-ui/icons/Storefront';
import GroupIcon from '@material-ui/icons/Group';
import BuildIcon from '@material-ui/icons/Build';

const useStyles = makeStyles(theme => ({

}));


const TabControl = props => {

    const { className, data, updateData, ...rest } = props;

    return (
        <Grid container direction="row" justify="space-evenly">
            <Grid item>
                <Tooltip title="Créer/Modifier/Supprimer une place" arrow>
                    <IconButton
                        onClick={() => updateData("build")}
                        color={data == "build" ? "secondary" : "default"}>
                        <BuildIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title="Liste des places" arrow>
                    <IconButton
                        onClick={() => updateData("store")}
                        color={data == "store" ? "secondary" : "default"}>
                        <StorefrontIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title="Liste des titulaires" arrow>
                    <IconButton
                        onClick={() => updateData("holders")}
                        color={data == "holders" ? "secondary" : "default"}>
                        <GroupIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default TabControl;
