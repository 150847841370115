import React from 'react';
import { makeStyles } from '@material-ui/styles';
import EuroIcon from '@material-ui/icons/Euro';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Avatar
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.success.main,
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32
    },
    difference: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    differenceIcon: {
        color: theme.palette.success.dark
    },
    differenceValue: {
        color: theme.palette.success.dark,
        marginRight: theme.spacing(1)
    },
    labelAction: {
        width: '100%',
        textAlign: 'center'
    }
}));

const InfoCard = props => {
    const { className, data, ...rest } = props;

    const classes = useStyles();

    return (

        <Card>
            <CardContent>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            //className={classes.title}
                            gutterBottom
                            variant="body2"
                        >
                            Nombre de commerçants réglés
                        </Typography>
                        <Grid container direction="row"
                            justify="flex-start"
                            alignItems="flex-end" spacing={1}>
                            <Grid item>
                                <Typography variant="h3">{data.cashed}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">/ {data.all}</Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <EuroIcon className={classes.icon} />
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default InfoCard;
