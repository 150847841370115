import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    Grid,
    Typography,
    Button,
    Table,
    TableContainer,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    ButtonGroup
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { LocationPictureDialog, ClientFile } from './components';

const styles = theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    }
});

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    },
    hover: {
        '&$hover:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.08)'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

class MiscellaneousDocument extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            fileId: null,
            openDialog: false,
            rows: [],
            columns: [],
            type: 'miscellaneous_document',
            limit: 0,
            isLoading: true
        };
    }

    componentDidMount = () => {
        this.handleData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.isLoading && this.state.isLoading != prevState.isLoading) {
            this.handleData();
        }
    };

    handleData = (_method = 'GET', args = {}) => {
        const { merchantId, type } = this.state;

        args.method = _method;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/document/${type}/${merchantId}`,
            args
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    isLoading: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleDialog = () => {
        this.setState(prevState => ({
            openDialog: !prevState.openDialog,
            fileId: !prevState.openDialog ? null : prevState.fileId,
            isLoading: true
        }));
    };

    del = (fileId, label) => {
        let _confirm = window.confirm('Êtes-vous sûr de vouloir supprimer "' + label + '" ?');

        if (_confirm) {
            const formData = new FormData();
            formData.append('fileId', fileId);
            formData.append('do', 'del');
            this.handleData('POST', { body: formData });
        }
    };

    edit = fileId => {
        this.setState({
            openDialog: true,
            fileId: fileId
        });
    };

    render() {
        const {
            classes,
            limit,
            rows,
            columns,
            openDialog,
            merchantId,
            fileId,
            type,
            records
        } = this.state;

        return (
            <div>
                {openDialog && (
                    <LocationPictureDialog
                        fileId={fileId}
                        type={type}
                        handleClose={this.handleDialog}
                        merchantId={merchantId}
                    />
                )}

                <Grid container direction="column" spacing={3} justify="flex-start">
                    {limit > records && (
                        <Grid item>
                            <Button
                                onClick={this.handleDialog}
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}>
                                Ajouter un document
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        <TableContainer>
                            <Table stickyHeader size="small" className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        {columns.map(column => (
                                            <StyledTableCell
                                                key={column.id}
                                                align={column.align}
                                                //style={{ width: column.minWidth }}
                                            >
                                                {column.label}
                                            </StyledTableCell>
                                        ))}
                                        <StyledTableCell key="action" align="center">
                                            Action
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => {
                                        return (
                                            <StyledTableRow hover key={index}>
                                                {columns.map((column, subindex) => (
                                                    <TableCell
                                                        key={`${column.id}_${index}`}
                                                        align={column.align}>
                                                        {column.id == 'src' ? (
                                                            <ClientFile
                                                                src={row.src}
                                                                isPicture={row.isPicture}
                                                            />
                                                        ) : (
                                                            <Typography
                                                                key={`row_${subindex}_${
                                                                    row[column.id]
                                                                }`}
                                                                variant="body1">
                                                                {row[column.id]}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                ))}

                                                <TableCell key={`action_${index}`} align="center">
                                                    <ButtonGroup
                                                        size="small"
                                                        key={`row_${index}_action`}
                                                        variant="outlined"
                                                        color="primary">
                                                        <Button onClick={() => this.edit(row.id)}>
                                                            Modifier
                                                        </Button>
                                                        <Button
                                                            endIcon={<ClearIcon />}
                                                            style={{ color: 'red' }}
                                                            onClick={() =>
                                                                this.del(row.id, row.label)
                                                            }>
                                                            Supprimer
                                                        </Button>
                                                    </ButtonGroup>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(MiscellaneousDocument);
