import React, { useState, useEffect } from 'react';
import { Dialog } from 'components';
import {
    Grid,
    TableContainer,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    Table
} from '@material-ui/core';
import env from 'common/env';

async function getMarkets(id) {
    const result = await fetch(`${env}/merchant/get-all-neutral-markets/${id}`, {
        method: 'GET'
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw "Une erreur est survenue lors de l'affichage des marchés.";
        });

    return result;
}

const HistoryDialog = ({ open, onClose, id }) => {
    const [markets, setMarkets] = useState([{ label: '' }]);

    useEffect(() => {
        if (open) {
            getMarkets(id)
                .then(data => setMarkets(data))
                .catch(err => console.error(err));
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} title="Historique des points">
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <TableContainer>
                        <Table size="small" stickyHeader={true}>
                            <TableHead>
                                <TableRow>
                                    <TableCell variant="head" size="small">
                                        Marché
                                    </TableCell>
                                    <TableCell variant="head" size="small">
                                        Date
                                    </TableCell>
                                    <TableCell variant="head" size="small" align="right">
                                        Points
                                    </TableCell>
                                    <TableCell variant="head" size="small">
                                        Commentaire
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {markets.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell size="small" hover component="th" scope="row">
                                            {row.marketLabel}
                                        </TableCell>
                                        <TableCell size="small" hover>
                                            {row.marketDate}
                                        </TableCell>
                                        <TableCell size="small" hover align="right">
                                            {row.points}
                                        </TableCell>
                                        <TableCell size="small" hover>
                                            {row.comment}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default HistoryDialog;
