import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Button,
    TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {

    },
}));

const Field = props => {
    const { className, data, sendData, ...rest } = props;

    const classes = useStyles();
    const [value, setValue] = React.useState("");

    const handleData = () => {
        if (!value || value.length == 0 || !value.replace(/\s/g, '').length) {
            alert("Donnée incorrect");
            return;
        }

        sendData(value, data.marchand_id, data.numero);

    }

    const handleValue = ({ target: { value } }) => {
        setValue(value);
    }

    return (
        <Grid container direction="row" spacing={2} justify="center" alignItems="flex-start">
            <Grid item>
                <TextField
                    error
                    value={value}
                    type={"text"}
                    fullWidth
                    helperText="Adresse mail manquante"
                    onChange={handleValue}
                    size="small"
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <Button onClick={handleData}
                    variant="contained" color="primary"
                    size="small">
                    Mettre à jour
                </Button>
            </Grid>
        </Grid>
    );
};

export default Field;
