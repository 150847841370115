import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';

import { Pie } from 'react-chartjs-2';

import {
    Typography,
    Card,
    CardHeader,
    CardContent,
    Avatar
} from '@material-ui/core';

import EqualizerIcon from '@material-ui/icons/Equalizer';


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    paperContent: {
        padding: theme.spacing(3),
    },
    labelFilter: {
        color: "#3f51b5",
        marginBottom: 15
    },
    labelFilterRadio: {
        color: "#3f51b5",
        marginBottom: 5
    }
}));

const UnfreePlaceOccupation = props => {
    const { className, data, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    const occupation = [];
    const labels = [];
    const colors = []

    labels.push(data.label + " (places non occupées)")
    labels.push(data.label + " (places occupées)")
    
    occupation.push(data.percentNonOccupied)
    occupation.push(data.percent)
    colors.push("green")
    colors.push("red")


    const stat = {
        datasets: [
            {
                barPercentage: 0.5,
                data: occupation,
                backgroundColor: colors,
                borderWidth: 4,
                borderColor: theme.palette.white,
                hoverBorderColor: theme.palette.white
            }
        ],
        labels: labels
    };


    const options = {
        legend: {
            display: true,
            position: "right",
            //fullWidth: true,
        },
        responsive: true,
        //maintainAspectRatio: true,
        animation: {
            duration: 3000,
        },
        //layout: { padding: 0 },
        tooltips: {
            enabled: true,
            callbacks: {
                label: function (tooltipItem) {
                    const index = [tooltipItem.index];
                    return data[(index == 1 ? "numberOfOccupied" : "numberTotal")] + ' (' + data[(index == 1 ? "percent" : "percentNonOccupied")] + '%)';
                },
            }
        }
    };


    return (

        <Card>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        <EqualizerIcon />
                    </Avatar>
                }
                title={
                    <Typography color="primary" variant="subtitle2">
                        Taux d'occupation des places occupées par marché
                    </Typography>
                }
            />
            <CardContent>
                <Pie
                    data={stat}
                    height={250}
                    width={700}
                    options={options}
                />
            </CardContent>
        </Card>
    );
};

export default UnfreePlaceOccupation;
