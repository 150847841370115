import React, { useEffect, useState } from 'react';
import { Button, TextField, Grid, InputAdornment } from '@material-ui/core';
import { Dialog } from 'components';
import { create, update, getUnit } from './services/http';
import NumberFormat from 'react-number-format';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

function CustomTextField(props) {
    const { inputRef, label, onChange, allowedToChangePrice, noIcon, disabled, ...other } = props;

    return (
        <TextField
            {...other}
            onChange={onChange}
            variant="outlined"
            fullWidth
            label={label}
            required
            disabled={disabled}
            helperText={'Saisir un prix unitaire'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <EuroSymbolIcon />
                    </InputAdornment>
                )
            }}
        />
    );
}

const FormDialog = props => {
    const [loading, setLoading] = useState(false);
    const [label, setLabel] = useState('');
    const [amount, setAmount] = useState('');

    const submit = () => {
        setLoading(true);
        if (props.id) {
            update(props.id, { libelle: label, amount })
                .then(() => handleClose())
                .catch(err => alert(err))
                .finally(() => setLoading(false));
        } else {
            create({ libelle: label, amount })
                .then(() => handleClose())
                .catch(err => alert(err))
                .finally(() => setLoading(false));
        }
    };

    const handleClose = () => {
        props.close();
        setLabel('');
        setAmount('');
    };

    useEffect(() => {
        if (props.id) {
            setLoading(true);
            getUnit(props.id)
                .then(res => {
                    setLabel(res.libelle);
                    setAmount(res.amount);
                })
                .catch(err => alert(err))
                .finally(() => setLoading(false));
        }
    }, [props.id]);

    return (
        <Dialog
            disabled={loading}
            title={props.id ? 'Modifier une unité' : 'Enregistrer une unité'}
            onClose={handleClose}
            action={
                <Button disabled={loading} onClick={submit} variant="contained" color="primary">
                    {props.id ? 'Modifier' : 'Enregistrer'}
                </Button>
            }
            open={props.open}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <TextField
                        fullWidth
                        helperText="Saisir un libellé pour l'unite"
                        label="Unité"
                        name="libelle"
                        onChange={event => setLabel(event.target.value)}
                        required
                        value={label}
                        disabled={loading}
                        variant="outlined"
                    />
                </Grid>
                <Grid item>
                    <NumberFormat
                        label="Prix unitaire"
                        value={amount}
                        customInput={CustomTextField}
                        helperText={'Saisir un prix unitaire'}
                        onValueChange={values => setAmount(values.value)}
                        thousandsGroupStyle="fr"
                        thousandSeparator={' '}
                        decimalSeparator={','}
                        type={'text'}
                        allowedDecimalSeparators={[',', '.', '?', ';']}
                        allowNegative={false}
                        decimalScale={2}
                        isNumericString
                        disabled={loading}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default FormDialog;
