import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    popper: {
        zIndex: 9
    },
    root: {
        display: "inline-flex"
    }
}));

const MuiPopper = (props) => {
    const { open, anchorRef, setOpen, setFilter, filter } = props
    const classes = useStyles();

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen();
    };


    return (
        <Popper className={classes.popper} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList>
                                <MenuItem
                                    disabled={filter == 'count'}
                                    key={1}
                                    onClick={() => {
                                        setOpen();
                                        setFilter('count')
                                    }}
                                >
                                    Afficher en fonction du nombre des factures
                                </MenuItem>
                                <MenuItem
                                    disabled={filter == 'amount'}
                                    key={2}
                                    onClick={() => {
                                        setOpen();
                                        setFilter('amount')
                                    }}
                                >
                                    Afficher en fonction du montant des factures
                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}

export default MuiPopper