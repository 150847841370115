import React from 'react'
import { makeStyles } from '@material-ui/styles'
import FilterListIcon from '@material-ui/icons/FilterList';
import {
    Grid,
    Button,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Typography,
    Radio,
    Divider,
    FormGroup,
    Checkbox
} from '@material-ui/core'

/**
 * 
 * @param {*} props 
 * @returns 
 */
const DerivedFormControlLabel = (props) => {
    const {
        className,
        onClick,
        type,
        value,
        current,
        label
    } = props;

    const DerivedComponent = type == 'checkbox' ? Checkbox : Radio
    const checked = (type == 'checkbox' ? current.indexOf(value) > - 1 : current == value)

    return (
        <Button className={className} size="small" onClick={onClick}>
            <FormControlLabel
                onClick={onClick}
                checked={checked}
                value={value}
                control={
                    <DerivedComponent
                        onClick={onClick}
                        style={{ backgroundColor: 'transparent' }}
                        disableRipple
                        disableTouchRipple
                        size="small"
                    />
                }
                label={
                    <Typography variant="button">
                        {label}
                    </Typography>
                }
            />
        </Button >
    )
}

/**
 * 
 * @param {*} props 
 * @returns 
 */
const DerivedFromControl = (props) => {
    const { type, children, onChange, value } = props;
    const DerivedComponent = type == 'checkbox' ? FormGroup : RadioGroup;
    const propsDerivedComponent = type == 'checkox' ? {} : {
        onChange: onChange,
        value: value
    }

    return (
        <DerivedComponent row {...propsDerivedComponent}>
            {children}
        </DerivedComponent>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 7
    },
    default: {
        color: theme.palette.primary.main,
        '& *': {
            color: theme.palette.primary.main,
        }
    }
}));

const EnhancedFilterMenu = (props) => {
    const { filter } = props;
    const [wrapper, setWrapper] = React.useState();
    const classes = useStyles();

    React.useEffect(() => {
        const delayDebounce = setTimeout(() => {
            wrapper && wrapper[0](wrapper[1]);
        }, 250)
        return () => clearTimeout(delayDebounce)
    }, [wrapper])

    return (
        <div className={classes.root}>
            <Grid container direction="column" justify="flex-start" spacing={1}>
                {
                    filter.map((data, index) => (
                        <React.Fragment key={index}>
                            <Grid item>
                                <Grid container alignItems="center" direction="row" justify="flex-start" spacing={2}>
                                    <Grid item>
                                        <div style={{ display: "inline-flex", marginTop: 4 }}>
                                            <FilterListIcon style={{ marginRight: 7 }} />
                                            <Typography variant="button">
                                                Filtrer par {data.sortLabel}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <FormControl component="fieldset">
                                            <DerivedFromControl value={data.current} onChange={(event) => setWrapper([data.set, event.target.value])  /*data.set(event.target.value)*/}>
                                                {
                                                    data.rows.map((row, index) => (
                                                        <DerivedFormControlLabel
                                                            key={`derived-form-control-labek-${index}`}
                                                            value={row.value}
                                                            current={data.current}
                                                            type={data.type}
                                                            label={row.label}
                                                            className={row.className ?? classes.default}
                                                            onClick={() => setWrapper([data.set, row.value]) /*data.set(row.value)*/}
                                                        />
                                                    ))
                                                }
                                            </DerivedFromControl>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                index + 1 < filter.length &&
                                <Grid item>
                                    <Divider />
                                </Grid>
                            }

                        </React.Fragment>
                    ))
                }

            </Grid>
        </div >
    )
}

export default EnhancedFilterMenu;