import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Typography,
    Paper,
    IconButton,
    Link,
    Button,
    CircularProgress,
    TextField
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getExtension } from 'common/validators';
import { getConfig, setConfig } from './http';
import FileInput from './components/FileInput';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5'
    },
    previewContainer: {
        textAlign: 'center',
        border: '1px solid #c4c4c4',
        borderRadius: 4,
        padding: 8
    },
    previewImg: {
        width: '100%'
    }
}));

const BillConfig = () => {
    const classes = useStyles();
    const [bankIdentificationStatement, setBankIdentificationStatement] = useState(null);
    const [additionalInformation, setAdditionalInformation] = useState(null);
    const [signature, setSignature] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [message, setMessage] = useState('');
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        getConfig()
            .then(res => {
                setBankIdentificationStatement(res.bank_identification_statement);
                setAdditionalInformation(res.additional_information);
                setSignature(res.signature);
                if (res.signature) {
                    const output_1 = document.getElementById('output_1');
                    const output_2 = document.getElementById('output_2');
                    output_1.src = res.signature;
                    output_2.href = res.signature;
                }
            })
            .catch(err => {
                console.error(err);
                alert(err);
            })
            .finally(() => setMounted(true));
    }, []);

    useEffect(() => {
        mounted && showPreview();
    }, [signature]);

    const handleChange = (target, value) => {
        target === 'bankIdentificationStatement' && setBankIdentificationStatement(value);
        target === 'additionalInformation' && setAdditionalInformation(value);
    };

    const handleFile = value => {
        if (value) {
            const extension = getExtension(value.name);
            if (extension && (extension == 'png' || extension == 'jpg' || extension == 'jpeg')) {
                setSignature(value);
            } else {
                alert('Vous devez sélectionner une image (png, jpg, jpeg).');
            }
        } else {
            setSignature(null);
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        setMessage('');
        setProcessing(true);

        setConfig({ bankIdentificationStatement, additionalInformation, signature })
            .then(() => setMessage('Mise à jour effectué avec succès'))
            .catch(err => {
                console.error(err);
                setMessage(err);
            })
            .finally(() => setProcessing(false));
    };

    const showPreview = () => {
        if (signature) {
            const reader = new FileReader();
            reader.onload = function() {
                const output_1 = document.getElementById('output_1');
                output_1.src = reader.result;

                const output_2 = document.getElementById('output_2');
                output_2.href = reader.result;
            };

            reader.readAsDataURL(signature);
        }
    };

    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit}>
                <Paper className={classes.paperContent}>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                        spacing={4}>
                        <Grid item>
                            <Grid container direction="row" alignItems="baseline">
                                <Grid item>
                                    <IconButton component={RouterLink} to="/settings">
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>

                                <Grid item>
                                    <Typography>
                                        <Link component={RouterLink} to="/settings">
                                            Les paramètres
                                        </Link>{' '}
                                        / Configuration de la facturation
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Grid
                                container
                                spacing={4}
                                direction="column"
                                alignContent="stretch"
                                alignItems="stretch">
                                <Grid item>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography
                                                variant="h4"
                                                className={classes.labelFilter}>
                                                Relevé d'identité bancaire
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                onChange={e =>
                                                    handleChange(
                                                        'bankIdentificationStatement',
                                                        e.target.value
                                                    )
                                                }
                                                multiline
                                                fullWidth
                                                value={bankIdentificationStatement ?? ''}
                                                rows={3}
                                                minRows={3}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography
                                                variant="h4"
                                                className={classes.labelFilter}>
                                                Informations supplémentaires à faire apparaître sur
                                                la facture
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                onChange={e =>
                                                    handleChange(
                                                        'additionalInformation',
                                                        e.target.value
                                                    )
                                                }
                                                multiline
                                                fullWidth
                                                value={additionalInformation ?? ''}
                                                rows={10}
                                                minRows={10}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography
                                                variant="h4"
                                                className={classes.labelFilter}>
                                                Tampon ou signature de la ville
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item>
                                                    <FileInput
                                                        file={signature}
                                                        acceptedMimeType={'image/*'}
                                                        setFile={handleFile}
                                                    />
                                                </Grid>
                                                <Grid item style={{ margin: 4 }}>
                                                    {signature && (
                                                        <Grid
                                                            className={classes.previewContainer}
                                                            container
                                                            direction="column"
                                                            alignItems="center"
                                                            spacing={1}>
                                                            <Grid item style={{ display: 'block' }}>
                                                                <img
                                                                    className={classes.previewImg}
                                                                    id="output_1"
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Button
                                                                    href=""
                                                                    target="_blank"
                                                                    id="output_2"
                                                                    download="signature"
                                                                    component={Link}
                                                                    color="primary">
                                                                    Ouvrir le fichier
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                alignContent="center"
                                alignItems="center"
                                justifyContent="flex-end"
                                justify="flex-end"
                                spacing={2}>
                                <Grid item>
                                    {processing && <CircularProgress color="primary" size={25} />}
                                </Grid>
                                {message !== '' && (
                                    <Grid item>
                                        <Typography variant="body1">{message}</Typography>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={processing}
                                        variant="contained">
                                        Mettre à jour
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </div>
    );
};

export default BillConfig;
