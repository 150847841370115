import React from 'react';
import { makeStyles } from '@material-ui/styles';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import {
    Button,
    ButtonGroup,
    Grid,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    pagination: {
        paddingTop: theme.spacing(3)
    },
}));

const Pagination = props => {
    const { className, numberOfPages, currentPage, handlePagination, ...rest } = props;

    const classes = useStyles();
    let pages = []
    let min = currentPage - 5;

    const _handlePagination = (page) => {
        //console.log(handlePagination)
        handlePagination(page);
    }

    if (numberOfPages == currentPage) {
        pages.push(numberOfPages - 5)
    }

    [...Array(numberOfPages).keys()].map(number => {
        if (currentPage > 5) {
            if (min < number && number < currentPage) {
                pages.push(number);
            }
        } else {
            if (numberOfPages > 5) {
                pages = [0, 1, 2, 3, 4];
            } else {
                pages = [...Array(numberOfPages).keys()]
            }
        }
    })

    return (
        <Grid container justify="center">
            <Grid item>
                <ButtonGroup color="primary" className={classes.pagination}>
                    {
                        (currentPage > 5 && currentPage != 1) &&
                        <Button key={1} onClick={() => _handlePagination(1)} variant="outlined" color="primary">
                            1
                        </Button>
                    }
                    {
                        currentPage > 5 &&
                        < Button key={"."}>
                            ...
                        </Button>
                    }
                    {
                        pages.map(number => {
                            return (
                                <Button key={number*1} onClick={() => _handlePagination(number + 1)} variant={currentPage == (number + 1) ? "contained" : "outlined"} color="primary">
                                    {number + 1}
                                </Button>
                            )
                        })
                    }
                    {
                        (currentPage + 1 < numberOfPages && currentPage >= 5) &&
                        <Button onClick={() => _handlePagination(currentPage + 1)} variant="outlined" color="primary">
                            {currentPage + 1}
                        </Button>
                    }

                    {
                        (currentPage < numberOfPages - 2 && numberOfPages > 5) &&
                        <Button key={".."}>
                            ...
                        </Button>
                    }
                    {
                        (currentPage != numberOfPages && numberOfPages > 5) &&
                        <Button key={numberOfPages} onClick={() => _handlePagination(numberOfPages)} variant="outlined" color="primary">
                            {numberOfPages}
                        </Button>
                    }

                </ButtonGroup>
            </Grid >
        </Grid >
    );
};

export default Pagination;
