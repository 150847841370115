import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    Dialog,
    DialogContent,
    Button,
    Grid,
    DialogActions,
    MenuItem,
    TextField,
    CircularProgress
} from '@material-ui/core';

import { DialogTitle } from './components';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    tabs: {
        boxShadow: 'none',
        borderTop: '1px solid #eee'
    },
    contentInfo: {
        marginTop: theme.spacing(2)
    },
    contentOverview: {
        marginTop: theme.spacing(3)
    },
    contentList: {
        marginTop: theme.spacing(2)
    }
});

class TemplateDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            comment: '',
            startDate: '',
            endDate: '',
            pointByPresent: 0,
            pointByPlacement: 0,
            isValid: false,
            formValid: false
        };
    }

    componentDidMount = () => {
        this.props.params.method == 'edit' && this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.isValid && this.state.isValid != prevState.isValid) {
            this.sendData();
        }

        if (this.state.formValid && this.state.formValid != prevState.formValid) {
            this.checkDateValidity();
        }
    };

    getData = () => {
        const { id } = this.props.params;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/attendance-point/get/${id}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(error => {
                alert(`·Echec de la récupération des données.`);
            });
    };

    sendData = () => {
        const { comment, pointByPresent, pointByPlacement, startDate, endDate } = this.state;

        const { method, id } = this.props.params;

        const _route = method + (method == 'edit' ? `/${id}` : '');
        const formData = new FormData();
        formData.append('pointByPresent', pointByPresent);
        formData.append('pointByPlacement', pointByPlacement);
        formData.append('comment', comment);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/attendance-point/${_route}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                alert(`Période ${method == 'edit' ? 'modifiée' : 'créée'} avec succès.`);
                this.handleClose();
            })
            .catch(error => {
                this.setState({
                    formValid: false,
                    isValid: false
                });
                console.error(error);
                alert(
                    `·Echec de la ${method == 'edit' ? 'modification' : 'création'} de la période.`
                );
            });
    };

    handleClose = () => {
        this.props.history.goBack();
    };

    handleData = ({ target: { value, name } }) => {
        if (name != 'pointByPresent' && name != 'pointByPlacement') {
            this.setState({
                [name]: value
            });
        } else if (value > -1 && value < 11) {
            this.setState({
                [name]: value
            });
        }
    };

    checkDateValidity = () => {
        const { method, id } = this.props.params;

        var args = '';

        if (method == 'edit') {
            args = `?id=${id}`;
        }

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/attendance-point/check-date-validity/${this.state.startDate}/${
                this.state.endDate
            }${args}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                if (data.isValid.global) {
                    this.setState({
                        isValid: true
                    });
                } else {
                    if (!data.isValid.start) {
                        alert('La date de début appartient déjà à une autre période.');
                    }

                    if (!data.isValid.end) {
                        alert('La date de fin appartient déjà à une autre période.');
                    }

                    this.setState({
                        isValid: false,
                        formValid: false
                    });
                }
            })
            .catch(error => {
                this.setState({
                    formValid: false,
                    isValid: false
                });
                console.error(error);
                alert(`·Echec de la vérification des périodes.`);
            });
    };

    prepareData = () => {
        const { startDate, endDate } = this.state;

        /*if (!label.replace(/\s/g, '').length) {
            alert("Veuillez saisir un libellé.");
            return;
        }*/

        if (startDate == '') {
            alert('Veuillez saisir une date de début.');
            return;
        }

        if (endDate == '') {
            alert('Veuillez saisir une date de fin.');
            return;
        }

        this.setState({
            formValid: true
        });
    };

    render() {
        const {
            classes,
            comment,
            startDate,
            endDate,
            pointByPlacement,
            pointByPresent,
            formValid
        } = this.state;

        const { method } = this.props.params;

        return (
            <Dialog fullWidth maxWidth={'sm'} onClose={this.handleClose} open>
                <DialogTitle onClose={this.handleClose}>
                    {method == 'edit' ? 'Modifier une période' : 'Ajouter une nouvelle période'}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        value={startDate}
                                        name="startDate"
                                        type="date"
                                        fullWidth
                                        helperText="Saisir une date de début"
                                        variant="outlined"
                                        label={'Date de début de la période'}
                                        onChange={this.handleData}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={endDate}
                                        name="endDate"
                                        type="date"
                                        fullWidth
                                        helperText="Saisir une date de fin"
                                        variant="outlined"
                                        label={'Date de fin de la période'}
                                        onChange={this.handleData}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        value={pointByPresent}
                                        name="pointByPresent"
                                        type="number"
                                        fullWidth
                                        helperText="Saisir un nombre de point(s) pour la présence"
                                        variant="outlined"
                                        label={'Nombre de points par présence'}
                                        onChange={this.handleData}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={pointByPlacement}
                                        name="pointByPlacement"
                                        type="number"
                                        fullWidth
                                        helperText="Saisir un nombre de point(s) pour le placement"
                                        variant="outlined"
                                        label={'Nombre de points par placement'}
                                        onChange={this.handleData}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <TextField
                                label={'Commentaire'}
                                type="text"
                                fullWidth
                                multiline
                                rows={3}
                                value={comment}
                                name="comment"
                                variant="outlined"
                                helperText="Saisir un commentaire"
                                onChange={this.handleData}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {formValid && <CircularProgress color="primary" size={25} />}
                    <Button disabled={formValid} onClick={this.handleClose} color="primary">
                        Fermer
                    </Button>
                    <Button
                        disabled={formValid}
                        color="primary"
                        variant="contained"
                        onClick={this.prepareData}>
                        {method == 'edit' ? 'Modifier' : 'Ajouter'} la période d'assiduité
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(TemplateDialog);
