import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';

import { Line } from 'react-chartjs-2';

import { Typography, Card, CardHeader, CardContent, Avatar } from '@material-ui/core';

import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4),
        flexGrow: 1
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main
    },
    paperContent: {
        padding: theme.spacing(3)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 15
    },
    labelFilterRadio: {
        color: '#3f51b5',
        marginBottom: 5
    }
}));

const NewHoldersLine = props => {
    const { className, rawData, date, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();

    const data = {
        labels: rawData.labels,
        datasets: [
            {
                label: `${rawData.count} titulaires suivis par la commune (${
                    rawData.percent[rawData.percent.length - 1]
                }%)`,
                borderWidth: 1,
                borderColor: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.light,
                pointHoverBackgroundColor: theme.palette.primary.semi,
                fill: false,
                spanGaps: true,
                //lineTension: 0,
                data: rawData.data,
                pointRadius: 6,
                pointHoverRadius: 7
            }
        ]
    };

    const options = {
        responsive: true,
        legend: {
            position: 'right',
            align: 'center',
            labels: {
                boxWidth: 12.5
            }
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        min: 0,
                        beginAtZero: true,
                        stepSize: 1
                    }
                }
            ]
        },
        tooltips: {
            caretSize: 0,
            displayColors: false,
            callbacks: {
                label: function(tooltipItem, data) {
                    return `Titulaires enregistrés : ${tooltipItem.yLabel} (${
                        rawData.percent[tooltipItem.index]
                    }%)`;
                }
            }
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0 // animation duration after a resize
    };

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        <TrendingUpIcon />
                    </Avatar>
                }
                title={
                    <Typography color="primary" variant="subtitle2">
                        Nombre de titulaires enregistrés pour la commune
                    </Typography>
                }
            />
            <CardContent>
                <Line data={data} options={options} />
            </CardContent>
        </Card>
    );
};

export default NewHoldersLine;
