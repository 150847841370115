import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import CardButton from '../../components/Card';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import { ViewBills, GenerateBills, ViewGroupingBills, SendBulkBills } from './components';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {
    getLateBillsDashboard,
    getSubscriberDashboard,
    getGroupingDashboard,
    getUnpaidDashboard
} from './actions';
import theme from 'theme';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const hostname = window.location.hostname;
const isVelleron =
    hostname === 'velleron.digitmarche.fr' ||
    hostname === 'www.velleron.digitmarche.fr' ||
    hostname === 'www.velleron-formation.digitmarche.fr' ||
    hostname === 'velleron-formation.digitmarche.fr';

/**
 *
 */
const titleLateCard = {
    subscribers: 'abonnés',
    'non-subscribers': 'non abonnés',
    grouping: '',
    late: '',
    canceled: ''
};

export const Bills = props => {
    const classes = useStyles();
    const { action, type, start, end } = props.match.params;
    const [subscriberDashboard, setSubscriberDashboard] = React.useState(null);
    const [groupingDashboard, setGroupingDashboard] = React.useState(null);
    const [lateDashboard, setLateDashboard] = React.useState(null);
    const [unpaidBill, setUnpaidBill] = React.useState(null);

    useEffect(() => {
        type === 'subscribers' &&
            getSubscriberDashboard()
                .then(res => setSubscriberDashboard(res))
                .catch(err => alert(err));

        (type === 'non-subscribers' || type === 'grouping') &&
            getGroupingDashboard()
                .then(res => setGroupingDashboard(res))
                .catch(err => alert(err));

        (type === 'non-subscribers' || type === 'subscribers' || type === 'grouping') &&
            getUnpaidDashboard()
                .then(res => setUnpaidBill(res))
                .catch(err => alert(err));
    }, [type]);

    useEffect(() => {
        getLateBillsDashboard(type)
            .then(res => setLateDashboard(res))
            .catch(err => alert(err));
    }, [start, end, type]);

    const customSize = useMemo(
        () => (type === 'non-subscribers' || type === 'subscribers' || type === 'grouping' ? 4 : 6),
        [type]
    );

    return (
        <div className={classes.root}>
            <Grid container direction="row" spacing={2} alignItems="stretch">
                <Grid item md={customSize} xs={12} sm={customSize}>
                    <CardButton
                        to={`/reminder/late_bill/${type === 'subscribers' ? 't' : 'p'}`}
                        title={`Nombre de factures ${titleLateCard[type]} en retard`}
                        button="Notifier par mail les retards"
                        color={theme.palette.late.normal}
                        icon={<WatchLaterOutlinedIcon />}
                        count={lateDashboard?.count}
                        text={lateDashboard?.text}
                    />
                </Grid>
                {type === 'subscribers' && (
                    <Grid item md={customSize} xs={12} sm={customSize}>
                        <CardButton
                            raised={type === 'subscribers' && action === 'generate'}
                            to="/bills/subscribers/generate/"
                            title="Nombre d'abonnés"
                            button="Générer les factures des abonnés"
                            icon={<SupervisedUserCircleIcon />}
                            count={subscriberDashboard?.count}
                            text={subscriberDashboard?.text}
                        />
                    </Grid>
                )}
                {(type === 'non-subscribers' || type === 'grouping') && (
                    <Grid item md={customSize} xs={12} sm={customSize}>
                        <CardButton
                            raised={type === 'grouping' && action === 'view'}
                            to="/bills/grouping/view/"
                            title="Nombre de commerçants avec plus d'une facture non réglée"
                            button="Réglement groupé"
                            color={theme.palette.unpaid.normal}
                            icon={<FileCopyIcon />}
                            count={groupingDashboard?.count}
                            text={groupingDashboard?.text}
                        />
                    </Grid>
                )}

                {(type === 'non-subscribers' || type === 'subscribers' || type === 'grouping') && (
                    // isVelleron && (
                    <Grid item md={customSize} xs={12} sm={customSize}>
                        <CardButton
                            to="/bills/grouping/send-bulk/"
                            title="Factures non réglées"
                            raised={type === 'grouping' && action === 'send-bulk'}
                            button="Envoyer les factures en masse"
                            color={theme.palette.secondary.main}
                            icon={<MailOutlineIcon />}
                            //count={unpaidBill?.count}
                            text={unpaidBill?.text}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    {action === 'view' && type !== 'grouping' && (
                        <ViewBills {...props.match.params} history={props.history} />
                    )}
                    {action === 'view' && type === 'grouping' && (
                        <ViewGroupingBills {...props.match.params} history={props.history} />
                    )}
                    {action === 'generate' && type === 'subscribers' && (
                        <GenerateBills history={props.history} />
                    )}
                    {action === 'send-bulk' && type === 'grouping' && (
                        <SendBulkBills {...props.match.params} history={props.history} />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

Bills.propTypes = {
    props: PropTypes.object
};

export default Bills;
