import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
    Grid,
    Typography,
    TextField,
    Paper,
    IconButton,
    InputAdornment,
    Divider,
    Link,
    TableBody,
    TableCell,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    Button
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';

import { Pagination } from './components';

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    },
    hover: {
        '&$hover:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.08)'
        }
    },
    selected: {
        '&$selected, &$selected:hover': {
            backgroundColor: 'rgba(41, 121, 255, 0.15)',
            cursor: 'auto'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paperContent: {
        padding: theme.spacing(3)
    }
});

class EmailTemplateTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            rows: [],
            isLoading: true,
            records: 0,
            page: 1,
            searchValue: '',
            numberOfPages: 0
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    getBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            (this.state.isLoading && prevState.isLoading != this.state.isLoading) ||
            (!this.props.params.method && this.props.params.method != prevProps.params.method)
        ) {
            this.getData();
        }
    }

    getData = () => {
        const { page, searchValue } = this.state;

        const formData = new FormData();
        formData.append('search', searchValue);
        formData.append('page', page);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/email/get-by`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.props.setRecords(data.records);
                this.setState({
                    ...data,
                    isLoading: false
                });
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                console.error(error);
                alert('Echec de la récupération des données');
            });
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value,
            page: 1,
            isLoading: true
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage,
            isLoading: true
        });
    };

    render() {
        const { classes, page, numberOfPages, records, searchValue, rows } = this.state;

        return (
            <Paper className={classes.paperContent}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                    spacing={3}>
                    <Grid item>
                        <Grid container direction="row" alignItems="baseline">
                            <Grid item>
                                <IconButton onClick={this.getBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    <Link component={RouterLink} to="/settings">
                                        Les paramètres
                                    </Link>{' '}
                                    / Gérer les modèles d'email
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <TextField
                            label={'Rechercher'}
                            type="text"
                            fullWidth
                            value={searchValue}
                            onChange={this.handleSearch}
                            variant="outlined"
                            helperText="Saisir un libellé"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                this.handleSearch({ target: { value: '' } })
                                            }
                                            edge="end">
                                            <ClearIcon style={{ color: 'red' }} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" color="primary">
                            Nombre de résultat : {records}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table stickyHeader size="small" className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align={'left'}>Libellé</StyledTableCell>
                                        <StyledTableCell align={'left'}>
                                            Type de relance
                                        </StyledTableCell>
                                        <StyledTableCell align={'center'}>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => {
                                        return (
                                            <StyledTableRow hover key={index}>
                                                <TableCell key={`${index}_label`} align="left">
                                                    {row.label}
                                                </TableCell>
                                                <TableCell key={`${index}_type`} align="left">
                                                    {row.email_type}
                                                </TableCell>
                                                <TableCell key={`${index}_action`} align="center">
                                                    <Button
                                                        component={RouterLink}
                                                        to={`/settings/email-template/edit/${row.id}`}
                                                        color="primary"
                                                        variant="outlined"
                                                        size="small">
                                                        Modifier
                                                    </Button>
                                                </TableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item>
                        <Pagination
                            numberOfPages={numberOfPages}
                            currentPage={page}
                            handlePagination={this.handlePagination}
                        />
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(EmailTemplateTable);
