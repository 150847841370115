import React from 'react';
import env from 'common/env';
import { withStyles, makeStyles } from '@material-ui/styles';
import { combineStyles } from 'common/styles';
import { SearchDateCard, SearchTextField, MuiTable } from 'components';
import { getSendedBill } from './action';
import { SendBulkBillsForm } from './components';
import {
    Grid,
    Paper,
    Divider,
    TableRow,
    TableCell,
    Tooltip,
    IconButton,
    Button,
    Step,
    Stepper,
    StepContent,
    StepLabel,
    StepConnector,
    FormLabel
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SendIcon from '@material-ui/icons/Send';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FindInPageIcon from '@material-ui/icons/FindInPage';

const styles = theme => ({
    root: {
        //padding: theme.spacing(4),
        flexGrow: 1
    },
    paperContent: {
        padding: theme.spacing(3)
    },

    stepper: {
        //display: "grid"
        paddingLeft: theme.spacing(5)
    },
    labelFilter: {
        color: '#3f51b5',
        margin: -10,
        fontWeight: 500
    },
    stepContent: {
        paddingLeft: theme.spacing(5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    }
});

const headers = [
    { id: 'sendingDate', align: 'left', label: 'Date', sortable: true },
    { id: 'counter', align: 'right', label: 'Succès/Total', sortable: false },
    { id: 'summary', align: 'center', label: 'Rapport', sortable: false }
];

/**
 *
 * @param {*} props
 * @returns
 */
const DEFAULT_STATE = props => ({
    ...props,
    type: props.type,
    start: props.start || '',
    end: props.end || '',
    searchValue: '',
    isSubmited: Boolean(props.start),
    disableSearch: !Boolean(props.start),
    order: 'asc',
    orderBy: headers[0].id,
    page: 0,
    count: 0,
    rows: [],
    loading: Boolean(props.start),
    openDialog: ''
});

const ColorlibConnector = withStyles({
    alternativeLabel: {
        left: 10
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        position: 'relative',
        right: 12,
        backgroundColor: '#3f51b5',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        backgroundColor: '#2979ff',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
    },
    notActive: {
        backgroundColor: 'white',
        color: '#2979ff',
        border: '1px solid #2979ff'
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { icon } = props;

    const icons = {
        1: <SendIcon />,
        2: <DateRangeIcon />,
        3: <FindInPageIcon />
    };

    return <div className={classes.root}>{icons[String(icon)]}</div>;
}

class SendBulkBills extends React.Component {
    constructor(props) {
        super(props);
        this.state = DEFAULT_STATE(props);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            this.state.page != prevState.page ||
            this.state.order != prevState.order ||
            this.state.orderBy != prevState.orderBy ||
            this.state.filter != prevState.filter ||
            this.state.searchValue != prevState.searchValue
        ) {
            this.setState({
                loading: true
            });
        }

        if (prevProps.type != this.props.type) {
            this.setState(DEFAULT_STATE(this.props));
            this.setState({
                loading: true
            });
        }

        if (this.props.start != prevProps.start || this.props.end != prevProps.end) {
            this.setState({
                start: this.props.start || '',
                end: this.props.end || '',
                loading: true
            });
        }

        if (this.state.isSubmited && this.state.isSubmited != prevState.isSubmited) {
            this.setState({
                loading: true
            });
        }

        if (this.state.loading && this.state.loading != prevState.loading) {
            this.getData();
        }
    };

    handleForm = () => {
        const { type, action, start, end } = this.state;

        this.setState({
            loading: true
        });

        this.props.history.push(`/bills/${type}/${action}/${start}/${end}`);
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        const { start, end, orderBy, order, page } = this.state;
        getSendedBill(start, end, orderBy, order, page)
            .then(data => this.setState({ loading: false, ...data }))
            .catch(err => {
                alert(err);
                this.setState({
                    loading: false
                });
            });
    };

    printSummary(id) {
        window.open(`${env}/regie/print-sended-bills/${id}`, '_blank');
    }

    render() {
        const {
            openDialog,
            classes,
            searchValue,
            disableSearch,
            order,
            orderBy,
            page,
            count,
            rows,
            loading,
            start,
            end
        } = this.state;

        return (
            <div className={classes.root}>
                {openDialog === 'sendbulkbillsform' && (
                    <SendBulkBillsForm
                        open={true}
                        start={{
                            data: start,
                            set: v => this.setState({ start: v })
                        }}
                        end={{
                            data: end,
                            set: v => this.setState({ end: v })
                        }}
                        close={() => {
                            this.setState({ openDialog: '', loading: true });
                        }}
                    />
                )}
                <Grid
                    spacing={3}
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                    style={{ paddingTop: 10 }}>
                    <Grid item>
                        <Paper className={classes.root}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Stepper
                                        className={classes.stepper}
                                        connector={<ColorlibConnector />}
                                        orientation="vertical">
                                        <Step active expanded key={0}>
                                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                                <FormLabel
                                                    className={classes.labelFilter}
                                                    component="label">
                                                    Effectuer un envoi
                                                </FormLabel>
                                            </StepLabel>
                                            <StepContent className={classes.stepContent}>
                                                <Button
                                                    onClick={() =>
                                                        this.setState({
                                                            openDialog: 'sendbulkbillsform'
                                                        })
                                                    }
                                                    variant="contained"
                                                    color="primary"
                                                    endIcon={<SendIcon />}>
                                                    Envoi en masse
                                                </Button>
                                            </StepContent>
                                        </Step>
                                        <Step active expanded key={1}>
                                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                                <FormLabel
                                                    className={classes.labelFilter}
                                                    component="label">
                                                    Sélectionner les dates de rapports d'envoi
                                                </FormLabel>
                                            </StepLabel>
                                            <StepContent
                                                className={classes.stepContent}
                                                style={{ paddingTop: 0, paddingLeft: 22 }}>
                                                <SearchDateCard
                                                    inline
                                                    noPaper
                                                    title="Recherche par date d'envoi"
                                                    submit={this.handleForm}
                                                    start={{
                                                        set: val => this.setState({ start: val }),
                                                        data: start
                                                    }}
                                                    end={{
                                                        set: val => this.setState({ end: val }),
                                                        data: end
                                                    }}
                                                />
                                            </StepContent>
                                        </Step>
                                        <Step active expanded key={2}>
                                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                                                <FormLabel
                                                    className={classes.labelFilter}
                                                    component="label">
                                                    Historique des envois
                                                </FormLabel>
                                            </StepLabel>
                                            <StepContent className={classes.stepContent}>
                                                <MuiTable
                                                    // disabled
                                                    refresh={() => this.setState({ loading: true })}
                                                    loading={loading}
                                                    headers={headers}
                                                    page={{
                                                        set: val => this.setState({ page: val }),
                                                        data: page,
                                                        count: count
                                                    }}
                                                    order={{
                                                        set: val => this.setState({ order: val }),
                                                        data: order
                                                    }}
                                                    orderBy={{
                                                        set: val => this.setState({ orderBy: val }),
                                                        data: orderBy
                                                    }}>
                                                    {rows.map((row, index) => (
                                                        <TableRow hover key={`${index}`}>
                                                            <TableCell
                                                                align="left"
                                                                key={`sendingDate-${index}`}>
                                                                {row.sendingDate}
                                                            </TableCell>
                                                            <TableCell
                                                                align="right"
                                                                key={`billNumber-${index}`}>
                                                                {row.success} / {row.total}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                key={`status-${index}`}>
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() =>
                                                                        this.printSummary(
                                                                            row.summary_id
                                                                        )
                                                                    }>
                                                                    <Tooltip title="Afficher le rapport">
                                                                        <PictureAsPdfIcon size="small" />
                                                                    </Tooltip>
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </MuiTable>
                                            </StepContent>
                                        </Step>
                                    </Stepper>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(combineStyles(styles))(SendBulkBills);
