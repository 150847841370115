import React from 'react';
import { withStyles } from '@material-ui/styles';

import {
    Grid,
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    Button,
    ListItemText,
    IconButton,
    TextField,
    FormControl,
    FormLabel,
    Typography,
    CircularProgress,
    RadioGroup,
    Radio,
    FormControlLabel,
    Tooltip
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { BillForm } from 'views';

import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import DescriptionIcon from '@material-ui/icons/Description';
import WarningIcon from '@material-ui/icons/Warning';
import PrintIcon from '@material-ui/icons/Print';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { Pagination, Statistics, SendButton } from './components';
import env from 'common/env';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    late: {
        background: 'rgb(255,165,0, 0.1)',
        color: 'red',
        fontWeight: 500,
        '& button': {
            color: 'red',
            fontWeight: 500,
            borderColor: 'red'
        }
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 15
    },
    labelFilterRadio: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

const columns = [
    {
        id: 'date',
        label: 'Date',
        minWidth: '10%',
        align: 'left'
    },
    {
        id: 'numFacture',
        label: 'N°',
        minWidth: '20%',
        align: 'left'
    },
    {
        id: 'marche',
        label: 'Marché (Journée)',
        minWidth: '30%',
        align: 'left'
    },
    {
        id: 'facture',
        label: 'Facture',
        minWidth: '10%',
        align: 'left'
    },
    {
        id: 'mail',
        label: 'Mail',
        minWidth: '10%',
        align: 'center'
    },
    {
        id: 'action',
        label: 'Action',
        minWidth: '20%',
        align: 'center'
    },
    {
        id: 'montant',
        label: 'Montant',
        minWidth: '10%',
        align: 'center'
    }
];

class Facture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            billNumber: null,
            openDialog: false,
            summaryStatementIsGenerating: false,
            numberOfModules: 1,
            marchand_id: this.props.marchandId,
            list: [],
            type: 'all',
            start: '',
            end: '',
            hasMail: false,
            isSubmited: false,
            approvedDate: {
                start: '',
                end: ''
            },
            page: 1,
            numberOfPages: 0,
            numberOfLateBills: 0,
            stats: {
                due: {
                    value: {
                        formatted: '0,00',
                        raw: 0
                    },
                    count: 0
                },
                paid: {
                    value: {
                        formatted: '0,00',
                        raw: 0
                    },
                    count: 0,
                    percentage: '0'
                },
                late: {
                    value: {
                        formatted: '0,00',
                        raw: 0
                    },
                    count: 0
                },
                canceled: {
                    value: {
                        formatted: '0,00',
                        raw: 0
                    },
                    count: 0
                },
                total: {
                    count: 0,
                    value: '0,00'
                },
                datasets: {
                    count: [],
                    value: []
                }
            }
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        (prevState.page != this.state.page || prevState.type != this.state.type) && this.getData();

        this.state.isSubmited && this.state.isSubmited != prevState.isSubmited && this.getData();

        !this.state.openDialog && this.state.openDialog != prevState.openDialog && this.getData();

        this.state.summaryStatementIsGenerating &&
            this.state.summaryStatementIsGenerating != prevState.summaryStatementIsGenerating &&
            this.getSummaryStatement();
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        const { marchand_id, start, end, page, type } = this.state;

        const args = `start=${start}&end=${end}&page=${page}&type=${type}`;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-factures/${marchand_id}?${args}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                !data.numberOfPages && alert('Aucune facture trouvée.');

                this.setState({
                    ...data,
                    isSubmited: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    getSummaryStatement = () => {
        const { marchand_id, start, end, numberOfModules } = this.state;

        const formData = new FormData();
        formData.append('start', start);
        formData.append('end', end);
        formData.append('market', true);
        formData.append('from', 'market');

        let module = false;
        if (numberOfModules > 1) {
            let module = window.confirm(
                'Souhaitez-vous imprimer un état des recettes pour le domaine également ?'
            );
        }

        formData.append('domain', module);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/merchant/get-summary-statement/${marchand_id}`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    summaryStatementIsGenerating: false
                });
                window.open(data, '_blank');
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    getPdf = numFacture => {
        window.open(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/print/A/${numFacture}`,
            '_blank'
        );
    };

    handleDate = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    handlePagination = selectedPage => {
        this.setState({
            page: selectedPage
        });
    };

    handleForm = (summaryStatement = false) => {
        const { start, end } = this.state;

        if (start == '') {
            alert('Veuillez saisir une date de début');
            return;
        }

        if (end == '') {
            alert('Veuillez saisir une date de fin');
            return;
        }

        const _start = new Date(start);
        const _end = new Date(end);

        if (_start.getTime() > _end.getTime()) {
            let inverse = window.confirm(
                'La date de début est supérieur à la date de fin\nInverser les dates ?'
            );
            inverse &&
                this.setState({
                    start: end,
                    end: start
                });
            return;
        }

        this.setState({
            isSubmited: !summaryStatement,
            approvedDate: {
                start: _start,
                end: _end
            },
            summaryStatementIsGenerating: summaryStatement
        });
    };

    handleTypeFilter = ({ target: { value } }) => {
        this.setState({
            type: value
        });
    };

    openDialog = v => {
        this.setState({
            openDialog: v
        });
    };

    printBill = billNumber => {
        window.location.replace(
            `/print-bill?back=${window.location.pathname}&num_facture=${billNumber}&impression_en_cours.png`
        );
        //fetch(`${env}/pax/bill/${billNumber}?impression_en_cours.png`).catch(e => console.error(e));
    };

    render() {
        const {
            classes,
            list,
            start,
            end,
            numberOfPages,
            page,
            numberOfLateBills,
            summaryStatementIsGenerating,
            type,
            stats,
            hasMail,
            openDialog,
            billNumber
        } = this.state;

        return (
            <>
                <BillForm
                    setBillNumber={value => this.openDialog(Boolean(value))}
                    billNumber={billNumber && openDialog ? billNumber : null}
                />

                <Grid container spacing={3} direction="column" alignItems="stretch">
                    <Grid item>
                        <FormControl component="fieldset" required>
                            <FormLabel className={classes.labelFilter} component="label">
                                Rechercher par date
                            </FormLabel>
                        </FormControl>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center"
                            spacing={2}>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    type="date"
                                    fullWidth
                                    name="start"
                                    value={start}
                                    onChange={this.handleDate}
                                    size="small"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    type="date"
                                    fullWidth
                                    size="small"
                                    name="end"
                                    value={end}
                                    onChange={this.handleDate}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    onClick={() => this.handleForm(false)}>
                                    Rechercher
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {numberOfLateBills > 0 && (
                        <Grid item>
                            <Typography style={{ color: 'darkorange' }}>
                                <strong>{numberOfLateBills}</strong> facture(s) en retard de
                                paiement toutes périodes confondues
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Statistics handleFilter={this.handleTypeFilter} rawData={stats} />
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            alignItems="flex-end"
                            justify="space-between">
                            <Grid item>
                                <FormControl component="fieldset">
                                    <FormLabel
                                        component="legend"
                                        className={classes.labelFilterRadio}>
                                        Filtrer par état d'encaissement
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        name="type"
                                        value={type}
                                        onChange={this.handleTypeFilter}>
                                        <FormControlLabel
                                            value="all"
                                            control={<Radio />}
                                            label="Toutes les factures"
                                        />
                                        <FormControlLabel
                                            value="n"
                                            control={<Radio style={{ color: 'red' }} />}
                                            label="Factures non réglées"
                                        />
                                        <FormControlLabel
                                            value="y"
                                            control={<Radio style={{ color: 'green' }} />}
                                            label="Factures réglées"
                                        />
                                        <FormControlLabel
                                            value="canceled"
                                            control={<Radio style={{ color: 'grey' }} />}
                                            label="Factures annulées"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="flex-end" spacing={1}>
                                    {summaryStatementIsGenerating && (
                                        <Grid item>
                                            <CircularProgress color="primary" size={25} />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            onClick={() => this.handleForm(true)}
                                            endIcon={<PrintIcon />}>
                                            Imprimer un état des recettes
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map(column => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list.map(bill => {
                                        return (
                                            <TableRow hover key={bill.numFacture}>
                                                {columns.map(column => {
                                                    if (column.id === 'action') {
                                                        return (
                                                            <TableCell
                                                                align="center"
                                                                key={`${column.id}_${bill.numFacture}_action`}
                                                                padding="none">
                                                                <div style={{ display: 'flex' }}>
                                                                    <Grid item>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                this.printBill(
                                                                                    bill.numFacture
                                                                                )
                                                                            }>
                                                                            <PrintIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Button
                                                                        color="primary"
                                                                        variant="outlined"
                                                                        size="small"
                                                                        disabled={
                                                                            bill.encaisse === '1'
                                                                        }
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                billNumber:
                                                                                    bill.numFacture,
                                                                                openDialog: true
                                                                            })
                                                                        }>
                                                                        Encaisser
                                                                    </Button>
                                                                </div>
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (column.id == 'facture') {
                                                        return (
                                                            <TableCell
                                                                align="left"
                                                                key={`${column.id}_${bill.numFacture}`}
                                                                padding="none">
                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justify="flex-start"
                                                                    alignItems="center">
                                                                    <Grid item>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                this.getPdf(
                                                                                    bill.numFacture
                                                                                )
                                                                            }>
                                                                            <DescriptionIcon />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {
                                                                            <ListItemText
                                                                                primary={
                                                                                    bill.numFacture
                                                                                }
                                                                                secondary={
                                                                                    bill.encaisse ==
                                                                                    '1' ? (
                                                                                        <Grid
                                                                                            container
                                                                                            direction="row"
                                                                                            alignItems="center">
                                                                                            <Grid
                                                                                                item>
                                                                                                Réglé
                                                                                            </Grid>
                                                                                            <Grid
                                                                                                item>
                                                                                                <DoneIcon
                                                                                                    fontSize="small"
                                                                                                    style={{
                                                                                                        color:
                                                                                                            'green'
                                                                                                    }}
                                                                                                />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    ) : (
                                                                                        <Grid
                                                                                            container
                                                                                            direction="row"
                                                                                            alignItems="center">
                                                                                            <Grid
                                                                                                item>
                                                                                                {bill.canceled ===
                                                                                                'yes'
                                                                                                    ? 'Annulée'
                                                                                                    : 'Non réglée'}
                                                                                            </Grid>
                                                                                            <Grid
                                                                                                item>
                                                                                                <ClearIcon
                                                                                                    fontSize="small"
                                                                                                    style={{
                                                                                                        color:
                                                                                                            bill.canceled ===
                                                                                                            'yes'
                                                                                                                ? 'grey'
                                                                                                                : 'red'
                                                                                                    }}
                                                                                                />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    )
                                                                                }
                                                                            />
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (column.id == 'mail') {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${bill.numFacture}_button`}
                                                                align={column.align}>
                                                                <SendButton
                                                                    update={this.getData}
                                                                    marchandId={
                                                                        this.props.marchandId
                                                                    }
                                                                    hasMail={hasMail}
                                                                    numFacture={bill.numFacture}
                                                                />
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (column.id == 'montant') {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${bill.numFacture}_amount`}
                                                                align={column.align}>
                                                                <Grid
                                                                    container
                                                                    alignItems="center"
                                                                    justify="flex-end"
                                                                    direction="row"
                                                                    spacing={1}>
                                                                    <Grid item>
                                                                        {bill[column.id]} €
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <FiberManualRecordIcon
                                                                            fontSize="small"
                                                                            style={{
                                                                                color:
                                                                                    bill.encaisse ==
                                                                                    '1'
                                                                                        ? 'green'
                                                                                        : bill.canceled ===
                                                                                          'yes'
                                                                                        ? 'grey'
                                                                                        : 'red'
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (column.id == 'date') {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${bill.numFacture}_numFacture`}
                                                                align={column.align}>
                                                                <Grid
                                                                    container
                                                                    alignItems="center"
                                                                    direction="row"
                                                                    spacing={1}>
                                                                    {bill._state == 'late' &&
                                                                        bill.canceled === 'no' && (
                                                                            <Grid item>
                                                                                <Tooltip
                                                                                    title="En retard"
                                                                                    arrow>
                                                                                    <WarningIcon
                                                                                        style={{
                                                                                            color:
                                                                                                'orange'
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        )}

                                                                    <Grid item>
                                                                        {bill[column.id]}
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        );
                                                    }

                                                    if (column.id == 'montant') {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}_${bill.numFacture}_numFacture`}
                                                                align={column.align}>
                                                                <Grid
                                                                    container
                                                                    alignItems="center"
                                                                    direction="row"
                                                                    spacing={1}>
                                                                    {bill._state == 'late' && (
                                                                        <Grid item>
                                                                            <Tooltip
                                                                                title="En retard"
                                                                                arrow>
                                                                                <WarningIcon
                                                                                    style={{
                                                                                        color:
                                                                                            'orange'
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                        </Grid>
                                                                    )}

                                                                    <Grid item>
                                                                        {bill[column.id]}
                                                                    </Grid>
                                                                </Grid>
                                                            </TableCell>
                                                        );
                                                    }

                                                    return (
                                                        <TableCell
                                                            key={`${column.id}_${bill.numFacture}`}
                                                            align={column.align}>
                                                            {bill[column.id]}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item>
                        <Pagination
                            numberOfPages={numberOfPages}
                            currentPage={page}
                            handlePagination={this.handlePagination}
                        />
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withStyles(styles)(Facture);
