import React from 'react';
import { withStyles } from '@material-ui/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link as RouterLink } from 'react-router-dom';
import TotalProfession from '../TotalProfession';
import {
    Grid,
    Paper,
    TextField,
    Container,
    Button,
    MenuItem,
    InputAdornment,
    IconButton,
    Typography,
    Divider,
    FormControlLabel,
    Switch
} from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(4)
    },
    paper: {
        padding: '32px 48px 32px 48px'
    },
    button: {
        width: '100%',
        textAlign: 'center',
        marginTop: 40
    },
    paperHeader: {
        padding: 10
    },
    actif: {
        padding: 16
    }
});

const activities = [
    {
        id: 1,
        key: 'alim',
        label: 'Alimentaire',
        color: 'gold'
    },
    {
        id: 2,
        key: 'prim',
        label: 'Primeur',
        color: '#32CD32	'
    },
    {
        id: 3,
        key: 'manu',
        label: 'Manufacturé',
        color: '#87CEFA'
    },
    {
        id: 4,
        key: 'dempos',
        label: 'Démonstrateur/Posticheur',
        color: '#BD33A4'
    }
];

class Edit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            libelleMetier: '',
            activite_id: '',
            actif: true,
            config: {
                nombre: 0,
                last: ''
            }
        };
    }

    componentDidMount = () => {
        const { id } = this.props.match.params;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/profession/get/${id}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                this.setState({
                    ...data
                });
            })
            .catch(console.log);

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/administrateur/settings`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                this.setState({
                    config: {
                        nombre: data.administrateur_settings.les_metiers.nombre_metiers_enregistres,
                        last: data.administrateur_settings.les_metiers.dernier_metier_enregistre
                    }
                });
            })
            .catch(console.log);
    };

    handleMerchantForm = ({ target: { name, value } }) => {
        this.setState({
            [name]: value
        });
    };

    handleCreate = () => {
        const { libelleMetier, activite_id, actif, history } = this.state;

        if (activite_id == '') {
            alert('Veuillez choisir une activité');
            return;
        }

        if (libelleMetier == '') {
            alert('Veuillez saisir un nom de métier');
            return;
        }

        const { id } = this.props.match.params;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/profession/update/${id}?libelle=${libelleMetier}&activite_id=${activite_id}&actif=${actif}`
        )
            .then(res => res.json()) // Traitement en json
            .then(data => {
                // Traitement de l'indo for stating data
                if (data) {
                    history.push('/settings/professions');
                }
            });
    };

    handleActif = value => {
        this.setState({
            actif: value
        });
    };

    render() {
        const { classes, libelleMetier, activite_id, actif } = this.state;

        return (
            <Container className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <TotalProfession data={this.state.config} />
                    </Grid>
                    <Grid item xs={12}>
                        <Paper>
                            <div className={classes.paperHeader}>
                                <Typography variant="h5">
                                    <IconButton
                                        component={RouterLink}
                                        to="/settings/professions"
                                        type="button">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    Mettre à jour un métier
                                </Typography>
                            </div>
                            <Divider />

                            <div className={classes.paper}>
                                <Grid container className={classes.actif} spacing={4}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={actif}
                                                name="actif"
                                                onChange={() => this.handleActif(!actif)}
                                            />
                                        }
                                        label="Actif"
                                    />
                                </Grid>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            name="libelleMetier"
                                            id="libelleMetier"
                                            label="Nom de métier"
                                            type="text"
                                            onChange={this.handleMerchantForm}
                                            helperText="Saisir un nom de métier"
                                            value={libelleMetier}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            name="activite_id"
                                            id="activite_id"
                                            label="Activité"
                                            type="text"
                                            select
                                            onChange={this.handleMerchantForm}
                                            helperText="Choisir une activité"
                                            value={activite_id}
                                            variant="outlined">
                                            {activities.map((act, index) => (
                                                <MenuItem key={act.id} value={act.id}>
                                                    {act.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>

                                <div className={classes.button}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.handleCreate}>
                                        Mettre à jour ce métier
                                    </Button>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withStyles(styles)(Edit);
