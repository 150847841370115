import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Paper, Divider, TableCell, TableRow } from '@material-ui/core';
import { SearchTextField, MuiTable } from 'components';
import { getTerraces, printTerrace } from './services/http';
import { ActionButton } from './components';

const useStyles = makeStyles(theme => ({
    paperContent: {
        padding: theme.spacing(4)
    }
}));

const headers = [
    { id: 'terrace.ref', align: 'left', label: 'Référence', sortable: true },
    { id: 'terrace.enseigne', align: 'left', label: 'Enseigne', sortable: true },
    { id: 'terrace.adresse', align: 'center', label: 'Adresse', sortable: true },
    {
        id: 'marchand.raisonSociale',
        align: 'left',
        label: 'Raison sociale',
        sortable: true,
        style: { width: '25%' }
    },
    { id: 'action', align: 'center', label: 'Actions', sortable: false }
];

const All = () => {
    const [count, setCount] = useState(0);
    const [terraces, setTerraces] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(headers[0].id);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        loading && getData();
    }, [loading]);

    useEffect(() => {
        setLoading(true);
    }, [searchValue, order, orderBy, page]);

    const handleSearchValue = value => {
        setSearchValue(value);
        setPage(0);
        setLoading(true);
    };

    const getData = () => {
        getTerraces({ searchValue, page, order, orderBy })
            .then(res => {
                setCount(res.count);
                setTerraces(res.terraces);
            })
            .catch(err => alert(err))
            .finally(() => setLoading(false));
    };

    return (
        <Paper className={classes.paperContent}>
            <Grid container direction="column" justify="center" alignItems="stretch" spacing={3}>
                <Grid item>
                    <SearchTextField
                        label={`Rechercher une terrasse`}
                        value={searchValue}
                        onChange={value => handleSearchValue(value)}
                        helperText="Saisir une référence, une enseigne, une adresse ou une raison sociale"
                    />
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <MuiTable
                        refresh={() => setLoading(true)}
                        // print={() =>
                        //     printBills(start, end, searchValue, order, orderBy, filter, type)
                        // }
                        loading={loading}
                        headers={headers}
                        page={{
                            set: val => setPage(val),
                            data: page,
                            count: count
                        }}
                        order={{
                            set: val => setOrder(val),
                            data: order
                        }}
                        orderBy={{
                            set: val => setOrderBy(val),
                            data: orderBy
                        }}>
                        {terraces.map((terrace, index) => (
                            <TableRow hover key={`${index}-${terrace.ref}`}>
                                <TableCell align="left" key={`ref-${index}-${terrace.ref}`}>
                                    {terrace.ref}
                                </TableCell>
                                <TableCell align="left" key={`enseigne-${index}-${terrace.ref}`}>
                                    {terrace.enseigne}
                                </TableCell>
                                <TableCell align="left" key={`adresse-${index}-${terrace.ref}`}>
                                    {terrace.adresse}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    key={`raisonSociale-${index}-${terrace.ref}`}>
                                    {terrace.raisonSociale}
                                </TableCell>
                                <TableCell align="center" key={`action-${index}-${terrace.ref}`}>
                                    <ActionButton
                                        terrace={terrace}
                                        view={() => printTerrace(terrace.id)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </MuiTable>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default All;
