import React, { useState } from 'react';
import env from 'common/env';
import { Grid, IconButton, Button, TextField, InputAdornment } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { useEffect } from 'react';

async function setNewPoint(id, points, marketId, com) {
    const formData = new FormData();
    formData.append('points', points);
    formData.append('marketId', marketId);
    formData.append('com', com);

    const result = await fetch(`${env}/merchant/set-points-after-review/${id}`, {
        method: 'POST',
        body: formData
    })
        .then(res => (res.ok ? res.json() : false))
        .then(data => data)
        .catch(err => {
            console.error(err);
            throw 'Une erreur est survenue lors de la mise à jour.';
        });

    return result;
}

const ComInput = ({ text, marketId, id, points, updateRows }) => {
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disableInput, setDisabledInput] = useState(false);
    const [currentText, setCurrentText] = useState(text);

    const closeInput = () => {
        setCurrentText(text);
        setEdit(false);
    };

    useEffect(() => {
        loading && setDisabledInput(true);
    }, [loading]);

    const update = e => {
        e.preventDefault();
        setDisabledInput(true);
        setNewPoint(id, points, marketId, currentText)
            .then(() => setEdit(false))
            .catch(err => console.error(err))
            .finally(() => {
                updateRows();
                setLoading(false);
            });
    };

    return (
        <Grid container direction="row" spacing={1} alignItems="center">
            {!edit ? (
                text === '' ? (
                    <Grid item>
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => setEdit(true)}>
                            Modifier
                        </Button>
                    </Grid>
                ) : (
                    <>
                        <Grid item>
                            <IconButton size="small" onClick={() => setEdit(true)}>
                                <EditIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>{currentText}</Grid>
                    </>
                )
            ) : (
                <Grid item xs={12}>
                    <form onSubmit={update}>
                        <TextField
                            disabled={disableInput}
                            fullWidth
                            value={currentText}
                            onChange={e => setCurrentText(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                type="button"
                                                onClick={closeInput}>
                                                <ClearIcon
                                                    style={{
                                                        color: disableInput ? 'inherit' : 'red'
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                        <InputAdornment position="end">
                                            <IconButton size="small" type="submit" onClick={update}>
                                                <CheckIcon
                                                    style={{
                                                        color: disableInput ? 'inherit' : 'green'
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    </>
                                )
                            }}
                        />
                    </form>
                </Grid>
            )}
        </Grid>
    );
};

export default ComInput;
