import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    Grid,
    Typography,
    TextField,
    Divider,
    ListItemText,
    TableBody,
    TableRow,
    Table,
    TableCell,
    TableContainer,
    FormControlLabel,
    Checkbox,
    IconButton,
    FormGroup,
    FormLabel,
    FormControl
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

import FlashOnOutlinedIcon from '@material-ui/icons/FlashOnOutlined';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import { Pagination } from './components';

const styles = theme => ({
    searchInput: {
        //paddingRight: theme.spacing(2),
        //paddingLeft: theme.spacing(2)
    },
    container: {
        //maxHeight: "calc(100vh - 456px)"
    },
    containerList: {
        //position: "absolute",
        //width: "100%"
    },
    content: {
        //position: "absolute",
        //height: "calc(100vh - 427px)"
    },
    filter: {
        //paddingLeft: theme.spacing(2)
    },
    labelFilter: {
        color: '#3f51b5',
        marginBottom: 5
    }
});

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

class ListPlaces extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            searchValue: '',
            list: [],
            numberOfPages: 0,
            selectedActivities: [],
            page: 1,
            isFree: false,
            isNotFree: false,
            filterHasChanged: false
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        (prevState.searchValue != this.state.searchValue ||
            (this.state.filterHasChanged &&
                prevState.filterHasChanged != this.state.filterHasChanged) ||
            prevState.isFree != this.state.isFree ||
            prevState.isNotFree != this.state.isNotFree ||
            prevState.page != this.state.page) &&
            this.getData();
    };

    componentDidMount = () => {
        this.getData();
    };

    getData = () => {
        const { places } = this.props;

        const { searchValue, page, isFree, isNotFree, selectedActivities } = this.state;

        const formData = new FormData();
        formData.append('search', searchValue);
        formData.append('places', JSON.stringify(places));
        formData.append('page', page);
        formData.append('isNotFree', isNotFree);
        formData.append('isFree', isFree);
        formData.append('from', 'table');
        selectedActivities.length > 0 &&
            formData.append('activities', JSON.stringify(selectedActivities));

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/get-places-by-for-creation`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data,
                    filterHasChanged: false
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    handleSearch = ({ target: { value } }) => {
        this.setState({
            searchValue: value
        });
    };

    handleActivitieFilter = id => {
        const { selectedActivities } = this.state;

        const index = selectedActivities.indexOf(id);

        if (index > -1) {
            selectedActivities.splice(index, 1);
        } else {
            selectedActivities.push(id);
        }

        this.setState({
            selectedActivities: selectedActivities,
            filterHasChanged: true
        });
    };

    handleStateOfPlace = ({ target: { name, checked } }) => {
        this.setState({
            [name]: checked
        });
    };

    handlePagination = page => {
        this.setState({
            page: page
        });
    };

    render() {
        const {
            classes,
            searchValue,
            list,
            selectedActivities,
            isFree,
            isNotFree,
            numberOfPages,
            page
        } = this.state;

        const { activities } = this.props;

        return (
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <div className={classes.searchInput}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            onChange={this.handleSearch}
                            value={searchValue}
                            label="Rechercher une place"
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        onClick={() =>
                                            this.handleSearch({ target: { value: '' } })
                                        }>
                                        <ClearIcon style={{ color: 'red' }} />
                                    </IconButton>
                                )
                            }}
                        />
                    </div>
                </Grid>
                <Grid item>
                    <div className={classes.filter}>
                        <Grid container direction="column" justify="flex-start" spacing={2}>
                            <Grid item>
                                <FormControl component="fieldset">
                                    <FormLabel className={classes.labelFilter} component="label">
                                        Activité de la place
                                    </FormLabel>
                                    <FormGroup row>
                                        {activities.map(({ id, label, color }) => {
                                            return (
                                                <FormControlLabel
                                                    key={label}
                                                    control={
                                                        <Checkbox
                                                            style={{ color: color }}
                                                            checked={Boolean(
                                                                selectedActivities.indexOf(id) > -1
                                                            )}
                                                            onChange={() =>
                                                                this.handleActivitieFilter(id)
                                                            }
                                                        />
                                                    }
                                                    label={<Typography>{label}</Typography>}
                                                />
                                            );
                                        })}
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl component="fieldset">
                                    <FormLabel className={classes.labelFilter} component="label">
                                        Statut de de la place
                                    </FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isNotFree}
                                                    onChange={this.handleStateOfPlace}
                                                    name="isNotFree"
                                                />
                                            }
                                            label={<Typography>Occupée</Typography>}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isFree}
                                                    onChange={this.handleStateOfPlace}
                                                    name="isFree"
                                                />
                                            }
                                            label={<Typography>Disponible</Typography>}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="column"
                        justify="space-between"
                        className={classes.content}>
                        <Grid item className={classes.containerList}>
                            <TableContainer className={classes.container}>
                                <Table size="small">
                                    <TableBody>
                                        {list.length > 0 ? (
                                            list.map(place => {
                                                return (
                                                    <StyledTableRow hover key={place.numero}>
                                                        <StyledTableCell
                                                            align="left"
                                                            key={place.numero + 'numero'}>
                                                            <ListItemText
                                                                primary={`${
                                                                    place.options.sector !== ''
                                                                        ? `(${place.options.sector})`
                                                                        : 'N°'
                                                                }${place.numero}`}
                                                                secondary={
                                                                    <Typography variant="body2">
                                                                        {place.meters} m
                                                                    </Typography>
                                                                }
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            align="left"
                                                            key={
                                                                place.numero +
                                                                place.merchant.raisonSociale
                                                            }>
                                                            <ListItemText
                                                                primary={
                                                                    <Typography
                                                                        noWrap
                                                                        style={{
                                                                            color:
                                                                                place.merchant.id ==
                                                                                '1'
                                                                                    ? 'green'
                                                                                    : 'inherit'
                                                                        }}>
                                                                        {place.merchant.id == '1'
                                                                            ? 'Disponible'
                                                                            : place.merchant
                                                                                  .raisonSociale}
                                                                    </Typography>
                                                                }
                                                                secondary={
                                                                    <Typography
                                                                        variant="body2"
                                                                        noWrap>
                                                                        {place.merchant.id != '1'
                                                                            ? place.merchant
                                                                                  .metierLabel
                                                                            : place.activitie.label}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            align="center"
                                                            key={place.numero + 'options'}>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                direction="row">
                                                                {place.options.water && (
                                                                    <Grid item>
                                                                        <Grid
                                                                            container
                                                                            alignItems="center"
                                                                            spacing={1}>
                                                                            <Grid item>Eau</Grid>
                                                                            <Grid item>
                                                                                <InvertColorsIcon
                                                                                    style={{
                                                                                        color:
                                                                                            'dodgerblue'
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                {place.options.electricity && (
                                                                    <Grid item>
                                                                        <Grid
                                                                            container
                                                                            alignItems="center"
                                                                            spacing={1}>
                                                                            <Grid item>
                                                                                Electricité
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <FlashOnOutlinedIcon
                                                                                    style={{
                                                                                        color:
                                                                                            'gold'
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                {place.options.car && (
                                                                    <Grid item>
                                                                        <Grid
                                                                            container
                                                                            alignItems="center"
                                                                            spacing={1}>
                                                                            <Grid item>
                                                                                Véhicule
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <LocalShippingIcon />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                {!place.options.car &&
                                                                    !place.options.water &&
                                                                    !place.options.electricity && (
                                                                        <Grid item>
                                                                            <Typography>
                                                                                Sans option
                                                                            </Typography>
                                                                        </Grid>
                                                                    )}
                                                            </Grid>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    style={{ border: 'none' }}>
                                                    <Typography align="center">
                                                        Aucune place n'a été trouvée...
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item className={classes.pageButton}>
                            <Pagination
                                numberOfPages={numberOfPages}
                                currentPage={page}
                                handlePagination={this.handlePagination}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(ListPlaces);
