import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    Grid,
    Typography,
    TextField,
    Divider,
    ListItemText,
    TableBody,
    TableRow,
    Table,
    TableCell,
    TableContainer
} from '@material-ui/core';

import { RecipeBar } from './components';

import StorefrontIcon from '@material-ui/icons/Storefront';

const styles = theme => ({
    head: {
        borderBottom: '1px solid',
        borderBottomColor: theme.palette.secondary.main
    },
    cell: {
        borderLeft: '4px solid',
        borderLeftColor: theme.palette.secondary.main
    }
});

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
        }
    }
}))(TableRow);

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#e1f0fb',
        color: '#3f51b5'
    }
}))(TableCell);

class Indicators extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            merchants: {
                byActivitie: [],
                count: 0,
                subscriber: {
                    byActivitie: [],
                    count: 0
                },
                noneSubscriber: {
                    byActivitie: [],
                    count: 0
                }
            },
            places: {
                byActivitie: [],
                count: 0,
                free: {
                    byActivitie: [],
                    count: 0
                },
                notFree: {
                    byActivitie: [],
                    count: 0
                },
                pricePerMonth: false,
                pricePerYear: false
            }
        };
    }

    componentDidMount = () => {
        this.getData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.props.raz && prevProps.raz != this.props.raz) {
            this.getData();
        }
    };

    getData = () => {
        const { merchants, places, billing, caracteristic } = this.props;

        const formData = new FormData();
        formData.append('merchants', JSON.stringify(merchants));
        formData.append('places', JSON.stringify(places));
        formData.append('billing', JSON.stringify(billing));
        formData.append('caracteristic', JSON.stringify(caracteristic));

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/market/get-indicators-by-for-creation`,
            {
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                this.setState({
                    ...data
                });
            })
            .catch(
                error => {
                    console.error(error);
                    alert('Echec de la récupération des données');
                } // Handle the error response object
            );
    };

    render() {
        const { classes, merchants, places } = this.state;

        return (
            <Grid container spacing={4} direction="column">
                <Grid item>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <StyledTableRow hover key={'total'}>
                                    <StyledTableCell className={classes.head}>
                                        <Typography color="primary">
                                            Nombre total de places (occupées et non occupées)
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.head}>
                                        <Typography color="primary">{places.count}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className={classes.head}>
                                        <Typography color="primary">{places.meters} m</Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                                {places.byActivitie.map(({ label, count, color, meters }) => {
                                    return (
                                        <StyledTableRow hover key={'totalbyactivitie' + label}>
                                            <StyledTableCell className={classes.cell}>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    direction="row"
                                                    alignItems="center">
                                                    <Grid item>
                                                        <StorefrontIcon style={{ color: color }} />
                                                    </Grid>
                                                    <Grid item>{label}</Grid>
                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell>{count}</StyledTableCell>
                                            <StyledTableCell align="left">
                                                {meters} m
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <StyledTableRow hover key={'merchantfree'}>
                                    <StyledTableCell className={classes.head}>
                                        <Typography color="primary">
                                            Nombre total de places occupées
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.head}>
                                        <Typography color="primary">
                                            {places.notFree.count}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.head} align="left">
                                        <Typography color="primary">
                                            {places.notFree.meters} m
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                                {places.notFree.byActivitie.map(
                                    ({ label, count, color, meters }) => {
                                        return (
                                            <StyledTableRow
                                                hover
                                                key={'merchantnotfreeplacebyactivitie' + label}>
                                                <StyledTableCell className={classes.cell}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        direction="row"
                                                        alignItems="center">
                                                        <Grid item>
                                                            <StorefrontIcon
                                                                style={{ color: color }}
                                                            />
                                                        </Grid>
                                                        <Grid item>{label}</Grid>
                                                    </Grid>
                                                </StyledTableCell>
                                                <StyledTableCell>{count}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {meters} m
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <StyledTableRow hover key={'merchantabo'}>
                                    <StyledTableCell className={classes.head}>
                                        <Typography color="primary">
                                            Nombre total de places occupées par des titulaires
                                            abonnés
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.head}>
                                        <Typography color="primary">
                                            {merchants.subscriber.count}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.head} align="left">
                                        <Typography color="primary">
                                            {merchants.subscriber.meters} m
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                                {merchants.subscriber.byActivitie.map(
                                    ({ label, count, color, meters }) => {
                                        return (
                                            <StyledTableRow
                                                hover
                                                key={'merchantabobyactivitie' + label}>
                                                <StyledTableCell className={classes.cell}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        direction="row"
                                                        alignItems="center">
                                                        <Grid item>
                                                            <StorefrontIcon
                                                                style={{ color: color }}
                                                            />
                                                        </Grid>
                                                        <Grid item>{label}</Grid>
                                                    </Grid>
                                                </StyledTableCell>
                                                <StyledTableCell>{count}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {meters} m
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <StyledTableRow hover key={'merchantnoneabo'}>
                                    <StyledTableCell className={classes.head}>
                                        <Typography color="primary">
                                            Nombre total de places occupées par des titulaires non
                                            abonnés
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.head}>
                                        <Typography color="primary">
                                            {merchants.noneSubscriber.count}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.head} align="left">
                                        <Typography color="primary">
                                            {merchants.noneSubscriber.meters} m
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                                {merchants.noneSubscriber.byActivitie.map(
                                    ({ label, count, color, meters }) => {
                                        return (
                                            <StyledTableRow
                                                hover
                                                key={'merchantnonabobyactivitie' + label}>
                                                <StyledTableCell className={classes.cell}>
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        direction="row"
                                                        alignItems="center">
                                                        <Grid item>
                                                            <StorefrontIcon
                                                                style={{ color: color }}
                                                            />
                                                        </Grid>
                                                        <Grid item>{label}</Grid>
                                                    </Grid>
                                                </StyledTableCell>
                                                <StyledTableCell>{count}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    {meters} m
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <StyledTableRow hover key={'merchantfree'}>
                                    <StyledTableCell className={classes.head}>
                                        <Typography color="primary">
                                            Nombre total de places libres
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.head}>
                                        <Typography color="primary">{places.free.count}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className={classes.head} align="left">
                                        <Typography color="primary">
                                            {places.free.meters} m
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                                {places.free.byActivitie.map(({ label, count, color, meters }) => {
                                    return (
                                        <StyledTableRow
                                            hover
                                            key={'merchantfreeplacebyactivitie' + label}>
                                            <StyledTableCell className={classes.cell}>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    direction="row"
                                                    alignItems="center">
                                                    <Grid item>
                                                        <StorefrontIcon style={{ color: color }} />
                                                    </Grid>
                                                    <Grid item>{label}</Grid>
                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell>{count}</StyledTableCell>
                                            <StyledTableCell align="left">
                                                {meters} m
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <RecipeBar merchants={merchants} places={places} />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Indicators);
