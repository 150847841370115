import React from 'react';
import {
    Grid,
    CircularProgress,
    Typography,
} from '@material-ui/core'

const sizeIcon = 45;

const MuiIcon = (props) => {

    const Component = props.type;

    return (<Component style={{ ...props.props.style, fontSize: sizeIcon }} {...props.params} />)
}

const BillSenderLoader = (props) => {
    const { describe, icon } = props;

    return (
        <Grid container direction="column" alignItems="center" justify="center" spacing={2}>
            <Grid item>
                {icon ?
                    <MuiIcon {...icon} />
                    :
                    <CircularProgress size={sizeIcon} />
                }

            </Grid>
            <Grid item>
                <Typography variant="body1">
                    {describe}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default BillSenderLoader;