import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { isMobile } from 'react-device-detect';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    Grid,
    Button,
    CircularProgress,
    IconButton
} from '@material-ui/core';

import { DialogTitle, MerchantInput, PlaceInput, CashMethod } from './components';

import PrintIcon from '@material-ui/icons/Print';
import { empty } from 'common/validators';

const styles = theme => ({
    root: {
        flexGrow: 1
    }
});

class FormCashDirectly extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            formData: null,
            isLoading: false,
            numFacture: '',
            lastBill: null,
            typeOfMerchant: 'others',
            selectedMerchant: [],
            selectedPlace: [],
            meters: '',
            moyen: '',
            banque: '',
            cheque: '',
            status: '',
            print: false,
            lastNumFacture: '',
            canSubmit: false
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        this.state.isLoading &&
            this.state.canSubmit &&
            this.state.canSubmit != prevState.canSubmit &&
            this.sendData();
    };

    handleSelectedMerchant = value => {
        let data = {
            selectedMerchant: value ? value : [],
            selectedPlace: [],
            meters: ''
        };

        if (value && value.journalplace_id) {
            data = {
                ...data,
                selectedPlace: {
                    id: value.journalplace_id,
                    numero: value.numero,
                    meters: value.metres,
                    otherIntel: value.otherIntel,
                    water: value.water === '1',
                    electricity: value.electricity === '1'
                },
                meters: value.metres
            };
        }

        this.setState({
            ...data
        });
    };

    handleMeters = value => {
        this.setState({
            meters: value
        });
    };

    handleTypeOfMerchant = value => {
        this.setState(prevState => ({
            typeOfMerchant: prevState.typeOfMerchant == value ? 'others' : value
        }));
    };

    handleWayPayment = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    handleSelectedPlace = value => {
        value &&
            this.setState({
                selectedPlace: {
                    ...value,
                    water: value.water === '1',
                    electricity: value.electricity === '1'
                },
                meters: value.metres
            });
    };

    printThermalBill = numFacture => {
        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/get-bills-for-thermal-printer/${numFacture}`,
            {
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.text() : false;
            })
            .then(data => {
                window.frames['frame-for-print'].document.body.innerHTML = data;
                window.frames['frame-for-print'].window.focus();
                window.frames['frame-for-print'].window.print();
            })
            .catch(
                error => {
                    console.error(error);
                    alert("Echec de l'impression.");
                } // Handle the error response object
            );
    };

    printBill = numFacture => {
        window.open(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/regie/print/A/${numFacture}`,
            '_blank'
        );
    };

    sendData = () => {
        const { selectedMerchant, formData, print } = this.state;

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/do-placement-through-bill`,
            {
                // Your POST endpoint
                method: 'POST',
                body: formData
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                if (data) {
                    alert(
                        `Le ${data.status} ${selectedMerchant.raisonSociale} a été placé et réglé avec succès (facture N°${data.numFacture}).`
                    );
                    if (data.splited) {
                        alert(
                            `Place N°${data.splited.numero} (${data.splited.metres} mètres restant) créée.`
                        );
                    }

                    if (print) {
                        this.printThermalBill(data.numFacture);
                    }

                    this.setState({
                        selectedMerchant: [],
                        numFacture: data.numFacture,
                        selectedPlace: [],
                        meters: '',
                        moyen: '',
                        cheque: '',
                        banque: '',
                        formData: null,
                        isLoading: false,
                        lastBill: `${selectedMerchant.raisonSociale} - N°${data.numFacture}`,
                        lastNumFacture: data.numFacture,
                        print: false,
                        canSubmit: false
                    });
                }
            })
            .then(success => console.log(success))
            .catch(error => {
                console.error(error);
                alert("Echec de l'encaissement.");

                this.setState({
                    isLoading: false
                });
            });
    };

    prepareData = (print = false) => {
        const {
            selectedMerchant,
            selectedPlace,
            typeOfMerchant,
            meters,
            moyen,
            cheque,
            banque
        } = this.state;

        const { id } = this.props;

        if (selectedMerchant.length == 0) {
            alert('Veuillez sélectionner un commerçant.');
            return;
        }
        if (selectedPlace.length == 0) {
            alert('Veuillez sélectionner une place.');
            return;
        }

        if (empty(meters)) {
            alert('Veuillez saisir un métrage.');
            return;
        } else if (parseInt(meters) < 1) {
            alert('Le nombre de mètres ne peut pas être inférieur à 1.');
            return;
        }

        if (moyen == '') {
            alert('Veuillez sélectionner un moyen de paiement.');
            return;
        }

        if (moyen == '2') {
            if (!banque || banque.length == 0 || !banque.replace(/\s/g, '').length) {
                alert('Veuillez saisir une banque.');
                return;
            }

            if (!cheque || cheque.length != 7 || !cheque.replace(/\s/g, '').length) {
                alert('Veuillez saisir un numéro de chèque valide (7 numéros obligatoires).');
                return;
            }
        }

        fetch(
            `https://www.${
                window.location.hostname.split('.')[1]
                    ? window.location.hostname.split('.')[0] === 'www'
                        ? window.location.hostname.split('.')[1]
                        : window.location.hostname.split('.')[0]
                    : 'dev'
            }.api.digitmarche.fr/api/placement/check-merchant-on-place-form/${selectedPlace.id}/${
                selectedMerchant.id
            }`,
            {
                // Your POST endpoint
                method: 'GET'
            }
        )
            .then(response => {
                return response.ok ? response.json() : false;
            })
            .then(data => {
                const merchantOnPlace = data;

                if (merchantOnPlace) {
                    const confirmBill = window.confirm(
                        `${merchantOnPlace.raisonSociale} va être enlever de sa place, souhaitez-vous continuer ?`
                    );
                    if (!confirmBill) {
                        return;
                    }
                }

                const formData = new FormData();
                formData.append('type', typeOfMerchant);
                formData.append('id', id);
                formData.append('marchand_id', selectedMerchant.id);
                formData.append('journalplace_id', selectedPlace.id);
                formData.append('metres', meters);
                formData.append('moyen', moyen);
                formData.append('water', selectedPlace.water);
                formData.append('electricity', selectedPlace.electricity);
                moyen == '2' && formData.append('cheque', cheque);
                moyen == '2' && formData.append('banque', banque);

                this.setState({
                    formData: formData,
                    canSubmit: true
                });
                //this.sendData(formData);
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    canSubmit: false
                });
                alert('Echec de la récupération des données');
            });

        this.setState({
            isLoading: true,
            print: print
        });
    };

    closeForm = () => {
        this.setState({
            selectedMerchant: [],
            selectedPlace: [],
            meters: '',
            moyen: '',
            cheque: '',
            banque: '',
            formData: null,
            isLoading: false,
            print: false,
            lastNumFacture: ''
        });
        this.props.close();
    };

    render() {
        const {
            classes,
            selectedMerchant,
            selectedPlace,
            meters,
            moyen,
            cheque,
            banque,
            typeOfMerchant,
            numFacture,
            lastBill,
            isLoading,
            lastNumFacture
        } = this.state;

        const { open, id } = this.props;

        return (
            <Dialog fullWidth maxWidth={'sm'} onClose={this.closeForm} open={open}>
                <DialogTitle onClose={this.closeForm}>Facturer un commerçant</DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        direction="column"
                        spacing={3}
                        justify="center"
                        alignItems="stretch">
                        <iframe
                            name="frame-for-print"
                            width="0"
                            height="0"
                            frameborder="0"
                            src="about:blank"></iframe>

                        {lastBill && (
                            <Grid item>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justify="flex-end">
                                    <Grid item>
                                        <IconButton
                                            onClick={() =>
                                                isMobile
                                                    ? this.printThermalBill(lastNumFacture)
                                                    : this.printBill(lastNumFacture)
                                            }>
                                            <PrintIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1">
                                            Dernière facture : {lastBill}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item>
                            <MerchantInput
                                id={id}
                                type={typeOfMerchant}
                                setType={this.handleTypeOfMerchant}
                                selectedMerchant={selectedMerchant}
                                numFacture={numFacture}
                                setMerchant={this.handleSelectedMerchant}
                            />
                        </Grid>
                        <Grid item>
                            <PlaceInput
                                id={id}
                                merchant={
                                    !Boolean(!selectedMerchant || selectedMerchant.length == 0)
                                }
                                merchantId={selectedMerchant.id}
                                meters={meters}
                                selectedPlace={selectedPlace}
                                numFacture={numFacture}
                                setPlace={this.handleSelectedPlace}
                                setMeters={this.handleMeters}
                            />
                        </Grid>
                        <Grid item>
                            <CashMethod
                                setMoyen={this.handleWayPayment}
                                moyen={moyen}
                                banque={banque}
                                cheque={cheque}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                        {isLoading && (
                            <Grid item>
                                <CircularProgress color="primary" size={25} />
                            </Grid>
                        )}
                        <Grid item>
                            <Button disabled={isLoading} color="primary" onClick={this.props.close}>
                                Annuler
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={isLoading}
                                variant="contained"
                                color="primary"
                                onClick={() => this.prepareData(false)}>
                                Encaisser
                            </Button>
                        </Grid>
                        {isMobile && (
                            <Grid item>
                                <Button
                                    disabled={isLoading}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.prepareData(true)}>
                                    Encaisser et imprimer
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(FormCashDirectly);
